import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const ResultsOverlayWrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 15, 30, 0.8);
  height: 100%;
  width: 100%;
  pointer-events: none;

  ${media.twoDimMin.baseline`
    padding-bottom: 60px;
  `}
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Heading = styled.h1`
  color: ${colors.yellow.base};
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  padding: 0 60px;
  text-align: center;

  ${media.twoDimMin.baseline`
    margin-bottom: 52px;
    font-size: 2.6rem;
  `}

  ${media.twoDimMin.max`
    font-size: 3rem;
    margin-bottom: 65px;
  `}
`;

export const LoadingWrapper = styled.div`
  width: 185px;
  margin-top: 65px;
  ${media.min.desktop`width: 205px;`}
`;

export const PieChartWrapper = styled.div`
  display: inline-block;
  width: 200px;
  height: 200px;

  ${media.twoDimMin.tablet`
    width: 250px;
    height: 250px;
  `}

  ${media.twoDimMin.baseline`
    width: 325px;
    height: 325px;
  `}

  ${media.twoDimMin.max`
    width: 415px;
    height: 415px;
  `}
`;

export const IncompleteResultsMessage = styled.div`
  color: ${colors.white};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0 14px;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
  ${media.twoDimMin.baseline`font-size: 1.8rem;`}
  ${media.twoDimMin.max`font-size: 2rem;`}
`;

export const PieChartTitle = styled.div`
  font-size: 1.6rem;
  font-weight: ${type.weight.bold};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  color: ${colors.white};
  margin-top: 20px;

  ${media.twoDimMin.baseline`
    font-size: 2rem;
    margin-top: 30px;
  `}
`;
