import styled from 'styled-components';
import { colors } from 'styles';
import { Link } from 'react-router-dom';

const StyledRouterLink = styled(Link)`
  color: ${colors.blue.base};
  text-decoration: none;
  outline: none;

  &.focus-visible {
    border-bottom: 2px solid ${colors.blue.light};
  }
`;

export default StyledRouterLink;
