import { useTranslation } from 'react-i18next';

import PageTitle from 'components/shared/PageTitle';
import AuthenticationLayout from 'components/shared/AuthenticationLayout';
import PrivacyPolicy from 'components/shared/PolicyContent/BiasSyncPrivacyPolicy';

import { Title } from './style';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation('privacyPolicy');

  return (
    <AuthenticationLayout isLegalText>
      <PageTitle subPageTitle={t('privacyPolicy:title')} />
      <Title>{t('privacyPolicy:title')}</Title>
      <div>
        <PrivacyPolicy />
      </div>
    </AuthenticationLayout>
  );
};

export default PrivacyPolicyPage;
