import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import preloadImage from 'lib/preloadImage';
import errorIcon from 'assets/iat-x-icon.svg';

import {
  FETCH_IAT,
  fetchIAT,
} from '../actions';

const debug = Debug('biassync:lms:store:iat:sagas:fetchIAT');

export function* fetchIATSaga(action) {
  debug('Saga called');
  const { payload: chapterId } = action;

  try {
    const { iat } = yield call(API.getIAT, chapterId);

    debug('IAT received', iat);

    try {
      yield Promise.all(
        iat.categories.stimuli
          .map(s => s.stimulusContent.map(preloadImage))
          .flat(),
      );

      yield call(preloadImage, errorIcon);

      yield put(fetchIAT.success(iat));
    } catch (e) {
      debug('Image preloading error', e);
      yield put(fetchIAT.error(new Error('Invalid or missing IAT images')));
    }
  } catch (e) {
    debug('API error', e);
    yield put(fetchIAT.error(e, action));
  }
}

export default function* fetchIATDefaultSaga() {
  yield takeLatest(FETCH_IAT.PENDING, fetchIATSaga);
}
