const getEventId = event => event.id;

// replaces persistent and ephemeral events with just their ids
export const parseVideoMetadataResponse = response => ({
  ...response,
  ephemeralEvents: response.ephemeralEvents.map(getEventId),
  persistentEvents: response.persistentEvents.map(getEventId),
});

export function getEventEntitiesFromVideoMetadataResponse(response) {
  const eventEntities = {};

  response.ephemeralEvents.forEach(event => {
    eventEntities[event.id] = event;
  });

  response.persistentEvents.forEach(event => {
    eventEntities[event.id] = event;
  });

  return eventEntities;
}
