import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const Wrapper = styled.div`
  color: ${colors.sierra.base};
  width: 100%;
  padding: 24px;

  ${media.min.mobileLandscape`
    width: 500px;
    padding: 32px;
  `}

  ${media.min.tablet`width: 585px;`}

  ${media.min.desktop`
    width: 685px;
    padding: 44px;
  `}
`;

export const HeaderText = styled.div`
  font-weight: ${type.weight.bold};
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 28px;

  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.4rem;`}
`;

export const PromptText = styled.p`
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.sierra.light};
  margin-bottom: 0;

  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
`;

export const OptOutButton = styled(TransparentButton)`
  color: ${({ disabled }) => (disabled ? colors.cascade.background : colors.blue.base)};
  margin-right: 30px;
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  outline: none;

  &.focus-visible {
    border-bottom: 2px solid ${colors.blue.light};
  }

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;
