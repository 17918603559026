import styled from 'styled-components';
import rewindIcon from 'assets/vjs-rewind-15s.svg';

export const Button = styled.button`
  cursor: pointer;
`;

export const RewindIcon = styled.div`
  background-image: url(${rewindIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
