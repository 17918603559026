import VideoPlayer from 'lib/VideoPlayer';

const keyValueMap = {
  ' ': 'PLAY_PAUSE',
  ArrowDown: 'VOLUME_DOWN',
  ArrowLeft: 'REWIND',
  ArrowRight: 'FASTFORWARD',
  ArrowUp: 'VOLUME_UP',
  AudioVolumeDown: 'VOLUME_DOWN',
  AudioVolumeMute: 'MUTE',
  AudioVolumeUp: 'VOLUME_UP',
  Down: 'VOLUME_DOWN',
  F: 'FULLSCREEN',
  Left: 'REWIND',
  M: 'MUTE',
  MediaFastForward: 'FASTFORWARD',
  MediaPlayPause: 'PLAY_PAUSE',
  MediaRewind: 'REWIND',
  Right: 'FASTFORWARD',
  Spacebar: 'PLAY_PAUSE',
  Up: 'VOLUME_UP',
  f: 'FULLSCREEN',
  m: 'MUTE',
};

export const handleHotkeys = event => {
  if (keyValueMap[event.key]) event.preventDefault();
  switch (keyValueMap[event.key]) {
    case 'PLAY_PAUSE':
      if (VideoPlayer.isPaused()) {
        VideoPlayer.play();
      } else {
        VideoPlayer.pause();
      }
      break;
    case 'REWIND':
      VideoPlayer.rewind();
      break;
    case 'FASTFORWARD':
      VideoPlayer.fastForward();
      break;
    case 'VOLUME_UP':
      VideoPlayer.volumeUp();
      break;
    case 'VOLUME_DOWN':
      VideoPlayer.volumeDown();
      break;
    case 'MUTE':
      VideoPlayer.toggleMute();
      break;
    case 'FULLSCREEN':
      VideoPlayer.toggleFullscreen();
      break;
    default:
      break;
  }
};
