import styled from 'styled-components';

const ScreenReaderOnly = styled.div`
  position: fixed;
  left: 3%;
  bottom: 5%;
  z-index: 7;
  pointer-events: none;
`;

export default ScreenReaderOnly;
