import styled from 'styled-components';
import { media } from 'styles';

export const DimBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(4, 15, 30, 0.6);

  ${media.twoDimMin.tablet`z-index: 3;`}
`;

export const VideoTaskWrapper = styled.div`
  position: absolute;
  height: auto;
  width: 50%;
  bottom: 10px;
  left: 8px;

  ${media.twoDimMin.tablet`
    width: 400px;
    bottom: 50px;
  `}

  ${media.twoDimMin.baseline`
    width: 450px;
    bottom: 75px;
    left: 15px;
  `}

  ${media.twoDimMin.desktop`
    width: 500px;
    bottom: 90px;
    left: 25px;
  `}
`;

export const SuccessMessageWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 8px;

  ${media.twoDimMin.baseline`
    bottom: 75px;
    left: 15px;
  `}

  ${media.twoDimMin.desktop`
    bottom: 90px;
    left: 25px;
  `}
`;
