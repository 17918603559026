import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  deviceTypeType,
  iatKeysConfigType,
} from 'constants/propTypes';
import { MOBILE, TABLET } from 'constants/deviceTypes';

import IATCategory from '../IATCategory';
import IATCountdown from '../IATCountdown';
import IATErrorView from '../IATErrorView';
import IATSuccessView from '../IATSuccessView';
import {
  AlertWrapper,
  CategoriesWrapper,
  IATTrialWrapper,
  ImageStimulus,
  IATSideButton,
  PromptText,
  StimulusWrapper,
  TextStimulus,
} from './style';

const IATTrial = ({
  deviceType,
  error,
  isCountdownActive,
  isFirstBlock,
  keysConfig,
  leftCategories,
  onCategoryClick,
  onCountdownDone,
  promptText,
  rightCategories,
  stimulusContent,
  stimulusType,
  success,
}) => {
  const { t } = useTranslation('courseViewer');

  const isTouchScreen = deviceType === MOBILE || deviceType === TABLET;
  const isImageStimulus = stimulusType === 'image';
  const stimulus = isImageStimulus ? (
    <ImageStimulus url={stimulusContent} />
  ) : <TextStimulus>{stimulusContent}</TextStimulus>;

  return (
    <IATTrialWrapper>
      {isCountdownActive ? (
        <IATCountdown
          onDone={onCountdownDone}
          shouldShowTip={isFirstBlock}
          totalSeconds={4}
        />
      ) : (
        <StimulusWrapper>
          {error && (
            <AlertWrapper isMobile={deviceType === MOBILE}>
              <IATErrorView
                correctText={error.correctText}
                incorrectText={error.incorrectText}
                isImageStimulus={isImageStimulus}
                isMobile={deviceType === MOBILE}
              />
            </AlertWrapper>
          )}
          {success && (
            <AlertWrapper isMobile={deviceType === MOBILE}>
              <IATSuccessView
                correctText={success.correctText}
                isImageStimulus={isImageStimulus}
                isMobile={deviceType === MOBILE}
              />
            </AlertWrapper>
          )}
          {!error && !success && promptText && (
            <PromptText isImageStimulus={isImageStimulus}>
              {promptText}
            </PromptText>
          )}
          {stimulus}
        </StimulusWrapper>
      )}
      <CategoriesWrapper isHeader={isTouchScreen} isDisabled={isCountdownActive}>
        <IATCategory
          categoryValues={leftCategories}
          isErrored={!!error && error.incorrectKey === 'left'}
          isKeyHidden={isTouchScreen}
          keyValue={keysConfig.left}
          orientation="left"
        />
        <IATCategory
          categoryValues={rightCategories}
          isErrored={!!error && error.incorrectKey === 'right'}
          isKeyHidden={isTouchScreen}
          keyValue={keysConfig.right}
          orientation="right"
        />
      </CategoriesWrapper>
      {isTouchScreen && (
        <>
          <IATSideButton orientation="left" onClick={() => { onCategoryClick(keysConfig.left); }}>
            <span>{t('courseViewer:iat.mobileButtonLabel')}</span>
          </IATSideButton>
          <IATSideButton orientation="right" onClick={() => { onCategoryClick(keysConfig.right); }}>
            <span>{t('courseViewer:iat.mobileButtonLabel')}</span>
          </IATSideButton>
        </>
      )}
    </IATTrialWrapper>
  );
};

IATTrial.propTypes = {
  deviceType: deviceTypeType.isRequired,
  error: PropTypes.shape({
    correctText: PropTypes.string.isRequired,
    incorrectKey: PropTypes.string.isRequired,
    incorrectText: PropTypes.string.isRequired,
  }),
  isCountdownActive: PropTypes.bool,
  isFirstBlock: PropTypes.bool.isRequired,
  keysConfig: iatKeysConfigType.isRequired,
  leftCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCategoryClick: PropTypes.func,
  onCountdownDone: PropTypes.func,
  promptText: PropTypes.string,
  rightCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  stimulusContent: PropTypes.string.isRequired,
  stimulusType: PropTypes.string.isRequired,
  success: PropTypes.shape({
    correctText: PropTypes.string.isRequired,
  }),
};

IATTrial.defaultProps = {
  error: null,
  isCountdownActive: false,
  onCategoryClick: undefined,
  onCountdownDone: undefined,
  promptText: null,
  success: null,
};

export default IATTrial;
