import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_CHILD_CHAPTER,
  fetchChildChapter,
} from '../actions';

const debug = Debug('biassync:lms:store:chapters:sagas:fetchChildChapter');

export function* fetchChildChapterSaga({ payload }) {
  const { chapterId, courseId } = payload;
  debug('Saga called', payload);

  try {
    const { forward: childChapter } = yield call(API.getChildChapter, chapterId);

    debug('Child chapter successfully fetched', childChapter);

    yield put(fetchChildChapter.success({
      childChapter,
      courseId,
      parentChapterId: chapterId,
    }));
  } catch (e) {
    debug('API error', e);
    yield put(fetchChildChapter.error(e));
  }
}

export default function* fetchChildChapterDefaultSaga() {
  yield takeLatest(FETCH_CHILD_CHAPTER.PENDING, fetchChildChapterSaga);
}
