import styled, { css, keyframes } from 'styled-components';
import { colors, media, type } from 'styles';

import TransparentButton from 'styles/components/TransparentButton';

export const CTA_DELAYED_FADE_IN_DURATION_MS = 1500;
export const CHEVRON_ANIMATED_BOUNCE_DURATION_MS = 2000;

const delayedFadeIn = keyframes`
  0% { opacity: 0; }
  70% { opacity: 0; }
  100% { opacity: 1; }
`;

const animatedBounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
`;

export const CTAWrapper = styled.div`
  width: 100%;
  margin-top: 72px;
  text-align: center;
  font-size: 1.6rem;
  animation: ${delayedFadeIn} ${CTA_DELAYED_FADE_IN_DURATION_MS}ms;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
  ${media.twoDimMin.desktop`margin-top: 98px;`}
`;

export const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const ResultsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 40px 80px;
  display: flex;
  flex-direction: column;

  ${media.twoDimMin.tablet`padding: 100px 40px 80px;`}
  ${media.twoDimMin.desktop`padding: 120px 40px 80px;`}
`;

export const BackButton = styled(TransparentButton)`
  position: absolute;
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  outline: none;
  padding: 8px 10px;

  &.focus-visible {
    border-radius: 9999px;
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
  }

  ${media.twoDimMin.tablet`font-size: 1.4rem;`}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
`;

export const ArrowIcon = styled.img`
  position: relative;
  top: 2px;
  height: 12px;
  width: 15px;
  margin-right: 8px;

  ${media.twoDimMin.tablet`
    height: 14px;
    width: 17px;
    margin-right: 12px;
  `}
  ${media.twoDimMin.desktop`
    height: 16px;
    width: 19px;
  `}
`;

export const ResultVisualization = styled.div`
  height: 300px;
  ${({ url }) => `background-image: url(${url});`}
  background-size: contain;
  background-position: center;
  border-radius: 8px;
  margin-top: 36px;

  ${media.twoDimMin.baseline`
    height: 400px;
    margin-top: 44px;
  `}

  ${media.twoDimMin.desktop`
    height: 450px;
    margin-top: 52px;
  `}

  ${media.twoDimMin.max`
    height: 500px;
    margin-top: 60px;
  `}
`;

export const TextWrapper = styled.div`
  margin-top: 44px;
  text-align: center;

  ${media.twoDimMin.desktop`margin-top: 60px;`}
  ${media.twoDimMin.max`margin-top: 72px;`}
`;

export const PromptText = styled.div`
  color: ${colors.yellow.base};
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.6rem;`}
  ${media.twoDimMin.desktop`font-size: 2.8rem;`}
`;

export const ResultTitle = styled.div`
  color: ${colors.yellow.base};
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  text-align: center;
  margin-top: 44px;

  ${media.twoDimMin.desktop`font-size: 2.4rem;`}
`;

export const ResultText = styled.p`
  color: ${colors.yellow.background};
  font-size: 1.6rem;
  text-indent: 3rem;
  width: 80vw;
  margin: 0 auto;
  max-width: 1200px;

  ${media.twoDimMin.baseline`font-size: 1.8rem;`}
  ${media.twoDimMin.desktop`width: 75vw;`}
  ${media.twoDimMin.max`
    font-size: 2rem;
    width: 70vw;
  `}
`;

export const ChevronWrapper = styled.div`
  position: fixed;
  right: 6px;
  bottom: 8px;
  width: 30px;
  height: 30px;
  transition: all 1s;
  visibility: visible;
  animation: ${animatedBounce} ${CHEVRON_ANIMATED_BOUNCE_DURATION_MS}ms infinite;

  ${({ fadeOut }) => fadeOut && css`
    transition: visibility 0s linear 300ms, opacity 300ms;
    opacity: 0;
    visibility: hidden;
  `}
`;

export const KeyText = styled.span`
  display: inline-block;
  color: ${colors.white};
  background-color: ${colors.blue.base};
  font-weight: ${type.weight.bold};
  text-align: center;

  line-height: 30px;
  font-size: 1.6rem;
  border-radius: 4px;
  padding: 0 8px;

  ${media.twoDimMin.desktop`
    line-height: 36px;
    font-size: 1.8rem;
    border-radius: 4px;
    padding: 0 10px;
  `}
`;
