/* eslint-disable no-param-reassign */

import { camelize } from 'humps';
// @ts-ignore
import Config from 'config';

import AppStorage from './appStorage';

const FEATURE_FLAG_PREFIX_REGEX = /^f_/;
const supportedFlagKeys = Config.supportedFeatureFlags || [];

export default (queryParams: Record<string,string>) => (
  supportedFlagKeys.reduce((flags: Record<string,boolean>, flagKey: string) => {
    const flagValue = queryParams[flagKey] || AppStorage.getItem(flagKey);

    if (flagValue) {
      const normalizedKey = camelize(flagKey.replace(FEATURE_FLAG_PREFIX_REGEX, ''));
      flags[normalizedKey] = flagValue === 'true';
    }

    return flags;
  }, {})
);
