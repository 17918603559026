import { AICCLaunchParam } from './types';

type StringObject = { [key: string]: string };

export function getAICCLaunchParams(queryParams: StringObject) {
  const queryParamKeys = Object.keys(queryParams);
  if (!queryParamKeys.length) return null;

  const launchParamKeys: string[] = Object.values(AICCLaunchParam);
  const launchParams = queryParamKeys.reduce((acc: StringObject, key) => {
    const normalizedKey = key.toLowerCase();

    if (launchParamKeys.includes(normalizedKey)) {
      acc[normalizedKey] = queryParams[key];
    }

    return acc;
  }, {});

  return Object.keys(launchParams).length === launchParamKeys.length
    ? launchParams
    : null;
}

export class HACPError extends Error {
  params: { [key: string]: string };
  responseData: { [key: string]: string | number };

  constructor(params: URLSearchParams, responseData: { [key: string]: string | number }) {
    super('HACP error response received from attempted request');
    this.params = Object.fromEntries(params);
    this.responseData = responseData;
  }
}
