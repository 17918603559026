import { schema } from 'normalizr';

const chapterSchema = new schema.Entity('chapters');
const chapterListSchema = [chapterSchema];

const sectionSchema = new schema.Entity('sections', {
  chapters: chapterListSchema,
});
const sectionListSchema = [sectionSchema];

export const courseSchema = new schema.Entity('courses', {
  sections: sectionListSchema,
});
