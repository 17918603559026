import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as PromptType from 'constants/surveyPromptTypes';

import OtherOption from './OtherOption';

const OtherOptionContainer = ({
  isDisabled,
  isSelected,
  keyShortcut,
  onChange,
  option: {
    id,
    optionText,
    subPromptId,
  },
  promptId,
  promptType,
}) => {
  const [textInput, setTextInput] = useState('');
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const handleSubmit = () => {
    const response = textInput.trim();

    onChange({ response, subPromptId });
    setIsEditModeEnabled(false);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (isEditModeEnabled) document.addEventListener('keyup', handleKeyUp);
    return () => isEditModeEnabled && document.removeEventListener('keyup', handleKeyUp);
  }, [isEditModeEnabled, handleKeyUp]);

  useEffect(() => {
    if (isDisabled && (isEditModeEnabled || textInput)) {
      setIsEditModeEnabled(false);
      setTextInput('');
    }
  }, [isDisabled]);

  const handleTextInputChange = ({ target: { value } }) => {
    setTextInput(value);
  };

  return (
    <OtherOption
      defaultOptionText={optionText}
      id={id}
      isCheckboxesPromptType={promptType === PromptType.Checkboxes}
      isDisabled={isDisabled}
      isEditModeEnabled={isEditModeEnabled}
      isSelected={isSelected}
      keyShortcut={keyShortcut}
      name={promptId}
      onEditModeEnabled={() => setIsEditModeEnabled(true)}
      onSubmit={handleSubmit}
      onTextInputChange={handleTextInputChange}
      textInput={textInput.trim() && textInput}
    />
  );
};

OtherOptionContainer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  keyShortcut: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ])).isRequired,
  promptId: PropTypes.string.isRequired,
  promptType: PropTypes.string.isRequired,
};

OtherOptionContainer.defaultProps = {
  keyShortcut: '',
};

export default OtherOptionContainer;
