import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { surveyPromptType } from 'constants/propTypes';
import { normalizeKeyValue } from 'lib/utils';

import YesNoPrompt from './YesNoPrompt';

export const NO_KEY = 'n';
export const YES_KEY = 'y';

const YesNoPromptContainer = ({
  isActive,
  isDisabled,
  onChange,
  prompt,
  response,
}) => {
  const handleKeyUp = event => {
    if (!isDisabled) {
      const key = normalizeKeyValue(event.key);

      if (key === YES_KEY || key === NO_KEY) {
        onChange(prompt.id, key === YES_KEY);
      }
    }
  };

  useEffect(() => {
    if (isActive) window.addEventListener('keyup', handleKeyUp);
    return () => {
      if (isActive) window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isActive]);

  return (
    <YesNoPrompt
      isDisabled={isDisabled}
      onItemChange={value => onChange(prompt.id, value, true)}
      onItemClick={value => onChange(prompt.id, value)}
      promptId={prompt.id}
      response={response}
    />
  );
};

YesNoPromptContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

YesNoPromptContainer.defaultProps = {
  response: null,
};

export default YesNoPromptContainer;
