import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import Modal from 'components/shared/Modal';
import {
  DialogContent,
  Footer,
  Title,
} from './style';

const SkipIATNotificationModal = ({ onAccept }) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Modal isRequired>
      <DialogContent>
        <Title>{t('skipIATNotificationModal.title')}</Title>
        <p>
          {t('skipIATNotificationModal.description')}
        </p>
        <Footer>
          <SimpleButton full type="button" onClick={onAccept} variant="brandedPrimary">
            {t('skipIATNotificationModal.acceptButton')}
          </SimpleButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

SkipIATNotificationModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
};

export default SkipIATNotificationModal;
