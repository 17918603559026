import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';
import { SurveySubTypes } from 'components/shared/Survey/constants';

import { updateChapterUserMetadata } from 'store/entities/chapters/actions';
import { chapterCompleted } from 'store/courses/actions';
import {
  currentChapterIdSelector,
  currentCourseIdSelector,
  currentChapterSubTypeSelector,
  nextChapterIdSelector,
} from 'store/courses/selectors';
import { totalBlockSelector } from 'store/survey/selectors';

import { handleChapterChanged } from 'store/chapters/actions';
import {
  fetchSurveyBlock,
  SEND_SURVEY_BLOCK_RESULTS,
  sendSurveyBlockResults,
} from '../actions';

const debug = Debug('biassync:lms:store:survey:sagas:sendSurveyBlockResults');

export function* sendSurveyBlockResultsSaga({ payload }) {
  debug('Saga called', payload);
  const { blockNumber, blockResults, previousItemsCount } = payload;

  const courseId = yield select(currentCourseIdSelector);
  const chapterId = yield select(currentChapterIdSelector);
  const totalBlocks = yield select(totalBlockSelector);
  const isLastBlock = blockNumber === totalBlocks;

  try {
    const response = yield call(
      API.sendSurveyBlockResults,
      chapterId,
      blockNumber,
      decamelizeKeys(blockResults),
    );

    if (isLastBlock) {
      yield put(chapterCompleted({ chapterId, courseId }));
      const chapterSubType = yield select(currentChapterSubTypeSelector);

      if (
        chapterSubType === SurveySubTypes.Familiarity
        || chapterSubType === SurveySubTypes.Vignette
      ) {
        const nextChapterId = yield select(nextChapterIdSelector);

        yield put(handleChapterChanged({
          chapterId: nextChapterId,
          courseId,
        }));
      }
    } else {
      const block = blockNumber + 1;
      yield put(updateChapterUserMetadata({
        chapterId,
        courseId,
        userMetadata: { block },
      }));

      yield put(fetchSurveyBlock({ blockNumber: block, chapterId }));
    }

    yield put(sendSurveyBlockResults.success({
      answers: response?.answers || null,
      previousItemsCount,
    }));
  } catch (e) {
    debug('API error', e);
    yield put(sendSurveyBlockResults.error(e));
  }
}

export default function* sendSurveyBlockResultsDefaultSaga() {
  yield takeLatest(SEND_SURVEY_BLOCK_RESULTS.PENDING, sendSurveyBlockResultsSaga);
}
