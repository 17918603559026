import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Checkbox from 'components/shared/Checkbox';
import SimpleButton from 'components/shared/SimpleButton';
import colors from 'styles/colors';

import { PromptFooter } from './style';

const StandardFooter = ({
  hasNACheckbox,
  isPromptApplicable,
  onAddCommentClick,
  onChange,
  promptId,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <PromptFooter hasNACheckbox={hasNACheckbox}>
      {hasNACheckbox && (
        <Checkbox
          id={`${promptId}_na`}
          isChecked={!isPromptApplicable}
          onChange={onChange}
        >
          {t('courseViewer:survey.notApplicable')}
        </Checkbox>
      )}
      <SimpleButton
        focusOutlineColor={colors.yellow.base}
        name="add-comment"
        onClick={onAddCommentClick}
        size="sm"
        variant="outline"
      >
        {t('courseViewer:survey.addComment')}
      </SimpleButton>
    </PromptFooter>
  );
};

StandardFooter.propTypes = {
  hasNACheckbox: PropTypes.bool,
  isPromptApplicable: PropTypes.bool.isRequired,
  onAddCommentClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  promptId: PropTypes.string.isRequired,
};

StandardFooter.defaultProps = {
  hasNACheckbox: false,
};

export default StandardFooter;
