import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

import theme from '../../theme';

export const Container = styled.div`
  border-bottom: solid 3px ${theme.accent.web};
  ${media.print`border-bottom: solid 3px ${theme.accent.print};`}
`;

export const Header = styled(TransparentButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0;
  width: 100%;

  ${media.min.tablet`justify-content: flex-start;`}
`;

export const TitleContainer = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled.h4`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  margin: 0;

  color: ${theme.primary.web};
  ${media.print`color: ${theme.primary.print};`}

  ${media.min.tablet`
    font-size: 2rem;
    min-width: 200px;
    text-align: left;
  `}
  ${media.min.baseline`font-size: 2.2rem;`}
  ${media.min.desktop`font-size: 2.4rem;`}
  ${media.min.max`
    font-size: 2.6rem;
    min-width: 230px;
  `}
`;

export const ChevronContainer = styled.div<{ isOpen: boolean }>`
  margin-right: 14px;
  display: inline-block;
  width: 18px;
  height: 18px;

  transition: transform 0.5s;
  transform: ${p => p.isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};

  ${media.min.tablet`margin-right: 16px;`}
  ${media.min.desktop`
    width: 20px;
    height: 20px;
    margin-right: 20px;
  `}
  ${media.min.max`
    width: 24px;
    height: 24px;
  `}

  & path {
    fill: ${theme.primary.web};
    ${media.print`fill: ${theme.primary.print};`}
  }
`;

export const AccordionContainer = styled.div<{ isOpen: boolean }>`
  opacity: ${p => (p.isOpen ? '1' : '0')};
  overflow: hidden;
  max-height: ${p => (p.isOpen ? '5000px' : '0')};
  margin-top: ${p => (p.isOpen ? '25px' : '0')};
  margin-bottom: ${p => (p.isOpen ? '40px' : '0')};
  margin-left: 34px;
  transition: max-height 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    margin-top 0.3s ease-in-out,
    margin-bottom 0.3s ease-in-out;
`;

export const ScoresContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  ${media.min.tablet`margin-left: 24px;`}
  ${media.min.desktop`margin-left: 50px;`}
`;

export const ScoreLabel = styled.span<{ isSelected: boolean }>`
  font-size: 1rem;
  font-weight: ${type.weight.bold};
  border-radius: 6px;
  border: solid 2px transparent;
  color: ${colors.cascade.muted};
  text-transform: uppercase;
  padding: 6px 10px;

  &:nth-child(1),
  &:nth-child(2) {
    margin-right: 8px;
  }

  ${media.min.tablet`
    font-size: 1.2rem;
    padding: 6px 14px;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 12px;
    }
  `}
  ${media.min.desktop`
    font-size: 1.4rem;
    padding: 6px 20px;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 16px;
    }
  `}

  ${({ isSelected }) => isSelected && css`
    border: solid 2px ${theme.accent.web};
    color: ${theme.primary.web};

    ${media.print`
      border: solid 2px ${theme.accent.print};
      color: ${theme.primary.print};
    `}
  `}
`;
