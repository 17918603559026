import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';

import * as ModalTypes from 'constants/modalTypes';
import * as ToastTypes from 'constants/toastTypes';
import { usePrevious } from 'lib/customHooks';
import {
  getEditedFields,
  isFieldFilled,
  requiredEditAccountDetailFields as requiredFields,
} from 'lib/formFields';

import {
  isDownloadingUserSelector,
  isUpdatingUserSelector,
  userAccountDetailsSelector,
} from 'store/user/selectors';

import * as uiActions from 'store/ui/actions';
import {
  downloadUser,
  editUser,
} from 'store/user/actions';
import { modalOpened } from 'store/ui/actions';

import AccountDetails from './AccountDetails';

const mapStateToProps = state => ({
  isDownloading: isDownloadingUserSelector(state),
  isUpdating: isUpdatingUserSelector(state),
  userAccountDetails: userAccountDetailsSelector(state),
});

const mapDispatchToProps = {
  onDownloadUser: downloadUser,
  onEditUser: editUser,
  openModal: modalOpened,
  toggleToast: uiActions.toggleOpenToast,
};

export const AccountDetailsContainer = ({
  isDownloading,
  isUpdating,
  onDownloadUser,
  onEditUser,
  openModal,
  toggleToast,
  tracking: { trackEvent },
  userAccountDetails,
}) => {
  const { t } = useTranslation(['accountDetails', 'errors']);

  const [userFields, setUserFields] = useState(userAccountDetails);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const prevUserAccountDetails = usePrevious(userAccountDetails);
  useEffect(() => {
    if (isEditEnabled && (prevUserAccountDetails !== userAccountDetails)) setIsEditEnabled(false);
  }, [userAccountDetails]);

  const handleChange = event => {
    const { name, value } = event.target;
    setUserFields(prevUserFields => ({
      ...prevUserFields,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditEnabled(prevIsEditEnabled => !prevIsEditEnabled);
  };

  const handleSaveClick = () => {
    const areRequiredFieldsFilled = requiredFields.every(field => isFieldFilled(userFields[field]));

    if (!areRequiredFieldsFilled) toggleToast({ message: t('errors:emptyFields'), type: ToastTypes.TOAST_ERROR });
    else {
      const editedFields = getEditedFields(userFields, userAccountDetails);

      if (!editedFields) toggleToast({ message: t('errors:noChanges'), type: ToastTypes.TOAST_ERROR });
      else {
        trackEvent({ event: 'Save Button Clicked' });
        onEditUser(editedFields);
      }
    }
  };

  const handleCancelClick = () => {
    setUserFields(userAccountDetails);
    setIsEditEnabled(false);
  };

  const handleDownloadClick = () => {
    trackEvent({ event: 'Download User Button Clicked' });
    onDownloadUser();
  };

  const handleDeleteClick = () => {
    openModal({ modalType: ModalTypes.CONFIRM_DELETE_ACCOUNT });
  };

  return (
    <AccountDetails
      fields={userFields}
      isDownloading={isDownloading}
      isEditEnabled={isEditEnabled}
      isUpdating={isUpdating}
      onCancelClick={handleCancelClick}
      onChange={handleChange}
      onDeleteClick={handleDeleteClick}
      onDownloadClick={handleDownloadClick}
      onEditClick={handleEditClick}
      onSaveClick={handleSaveClick}
      t={t}
    />
  );
};

AccountDetailsContainer.propTypes = {
  isDownloading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onDownloadUser: PropTypes.func.isRequired,
  onEditUser: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
  userAccountDetails: PropTypes.objectOf(PropTypes.string),
};

AccountDetailsContainer.defaultProps = {
  userAccountDetails: null,
};

const trackedAccountDetails = track({
  page: 'AccountDetails',
})(AccountDetailsContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedAccountDetails);
