import PropTypes from 'prop-types';

import { refType } from 'constants/propTypes';
import useModalCloseEventListener from 'lib/customHooks/useModalCloseEventListener';

import { Panel } from './style';

const SlideOutPanel = ({
  children,
  direction,
  onClose,
  panelRef,
}) => {
  useModalCloseEventListener(panelRef, onClose, true);

  return (
    <Panel ref={panelRef} direction={direction}>
      {children}
    </Panel>
  );
};

SlideOutPanel.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClose: PropTypes.func.isRequired,
  panelRef: refType,
};

SlideOutPanel.defaultProps = {
  panelRef: null,
};

export default SlideOutPanel;
