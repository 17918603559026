import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const AnnotationWrapper = styled.div`
  position: absolute;
  z-index: 2;
  color: ${colors.white};
  background-color: rgba(4, 15, 30, 0.75);
  box-shadow: 0 0px 4px rgba(255, 255, 255, 0.2), 0 0px 4px rgba(255, 255, 255, 0.2);
  padding: 12px;
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  max-width: 40%;
  border-radius: 8px;
  top: 20%;
  right: 5%;
  pointer-events: none;

  ${media.min.tablet`
    padding: 16px;
    font-size: 1.4rem;
  `}

  ${media.min.baseline`
    max-width: 25%;
  `}

  ${media.min.desktop`
    padding: 20px;
    font-size: 1.6rem;
    top: 15%;
  `}
`;
