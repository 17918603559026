import { useTranslation } from 'react-i18next';

import Portal from './Portal';
import { Button, RewindIcon } from './style';

type RewindButtonProps = {
  onClick: () => void;
};

const RewindButton = ({
  onClick,
}: RewindButtonProps) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Portal>
      <Button
        aria-label={t('courseViewer:video.rewindButton')}
        className="vjs-button"
        onClick={onClick}
      >
        <RewindIcon />
      </Button>
    </Portal>
  );
};

export default RewindButton;
