import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const Description = styled.div`
  max-width: 550px;
  text-align: center;
  color: ${colors.green.base};
  font-style: italic;
  font-weight: ${type.weight.bold};
  font-size: 1.6rem;

  ${media.twoDimMin.tablet`
    font-size: 2rem;
    max-width: 700px;
  `}
  ${media.twoDimMin.desktop`
    font-size: 2.4rem;
    max-width: 900px;
  `}
  ${media.twoDimMin.max`font-size: 3rem;`}
`;

export const Subtitle = styled.div`
  color: ${colors.green.base};
  font-weight: ${type.weight.bold};
  font-size: 2.6rem;

  ${media.twoDimMin.tablet`font-size: 3.6rem;`}
  ${media.twoDimMin.desktop`font-size: 4.2rem;`}
  ${media.twoDimMin.max`font-size: 5rem;`}
`;

export const Title = styled.div`
  font-weight: ${type.weight.bold};
  font-size: 2.6rem;

  ${media.twoDimMin.tablet`font-size: 3.6rem;`}
  ${media.twoDimMin.desktop`font-size: 4.2rem;`}
  ${media.twoDimMin.max`font-size: 5rem;`}
`;
