import Debug from 'debug';
import {
  all,
  call,
  select,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import { currentCourseIdSelector } from 'store/courses/selectors';
import { persistentEventResultsToFetch } from 'store/video/selectors';
import {
  setVideoEventEntityLoading,
  setVideoEventEntityNotLoading,
  updateVideoEventEntity,
} from 'store/entities/videoEvents/actions';

import {
  FETCH_DATA_FOR_VIDEO_OVERLAYS,
  fetchDataForVideoOverlays,
} from '../actions';

const debug = Debug('store:video:sagas:fetchDataForVideoOverlays');

export function* fetchOverlayData(overlayId) {
  const currentCourseId = yield select(currentCourseIdSelector);
  yield put(setVideoEventEntityLoading({
    courseId: currentCourseId,
    videoEventId: overlayId,
  }));

  try {
    const overlayResponse = yield call(API.getVideoOverlayData, overlayId);

    debug('Video overlay received', overlayResponse);

    yield put(updateVideoEventEntity({
      courseId: currentCourseId,
      data: overlayResponse,
      videoEventId: overlayId,
    }));
  } catch (error) {
    debug('Video overlay response error', error);

    yield put(updateVideoEventEntity({
      courseId: currentCourseId,
      data: { error: true },
      videoEventId: overlayId,
    }));
  }
  return yield put(setVideoEventEntityNotLoading({
    courseId: currentCourseId,
    videoEventId: overlayId,
  }));
}

export function* fetchDataForVideoOverlaysSaga() {
  debug('Saga called');

  const persistentEvents = yield select(persistentEventResultsToFetch);

  if (persistentEvents.length) {
    yield all(persistentEvents.map(persistentEvent => call(fetchOverlayData, persistentEvent.id)));
  }
  yield put(fetchDataForVideoOverlays.success());
}

export default function* fetchDataForVideoOverlaysDefaultSaga() {
  yield takeLatest(FETCH_DATA_FOR_VIDEO_OVERLAYS.PENDING, fetchDataForVideoOverlaysSaga);
}
