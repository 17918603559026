import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  LOGIN_WITH_MAGIC_LINK,
  loginWithMagicLink,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:loginWithMagicLink');

export function* loginWithMagicLinkSaga({ payload: magicLinkToken }) {
  debug('Saga called');

  try {
    const loginResponse = yield call(API.loginWithMagicLink, magicLinkToken);

    yield put(loginWithMagicLink.success(loginResponse));
  } catch (e) {
    debug('Saga error', e);
    yield put(loginWithMagicLink.error(e));
  }
}

export default function* loginWithMagicLinkDefaultSaga() {
  yield takeLatest(LOGIN_WITH_MAGIC_LINK.PENDING, loginWithMagicLinkSaga);
}
