import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import track, { TrackingPropType } from 'react-tracking';

import { policyTypeType } from 'constants/propTypes';
import * as policyTypes from 'constants/policyTypes';

import { submitPolicyConsent } from 'store/user/actions';
import {
  isSubmittingPolicyConsentSelector,
  policyTypeSelector,
} from 'store/user/selectors';

import BNPPPolicy from 'components/shared/PolicyContent/BNPPPolicy';
import DefaultPolicy from 'components/shared/PolicyContent/BiasSyncPrivacyPolicy';
import MerckPolicy from 'components/shared/PolicyContent/MerckPolicy';
import Modal from 'components/shared/Modal';

import PolicyModal from './PolicyModal';

const mapStateToProps = state => ({
  isSubmitting: isSubmittingPolicyConsentSelector(state),
  policyType: policyTypeSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitPolicyConsent,
};

const policyContentComponentMapping = {
  [policyTypes.DEFAULT]: DefaultPolicy,
  [policyTypes.BNPP]: BNPPPolicy,
  [policyTypes.MERCK]: MerckPolicy,
};

export const PolicyModalContainer = ({
  isSubmitting,
  onSubmit,
  policyType,
  tracking,
}) => {
  const handleSubmit = isAccepted => {
    onSubmit({ isAccepted });
    tracking.trackEvent({ event: 'Policy Consent Button Clicked' });
  };

  const isPolicyTypeBNPP = policyType === policyTypes.BNPP;
  const PolicyContent = policyContentComponentMapping[policyType];
  return (
    <Modal isRequired>
      <PolicyModal
        hasCancelButton={isPolicyTypeBNPP}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        policyContent={<PolicyContent />}
        titleTranslationKey={isPolicyTypeBNPP ? 'bnppTitle' : 'title'}
      />
    </Modal>
  );
};

PolicyModalContainer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  policyType: policyTypeType,
  tracking: TrackingPropType.isRequired,
};

PolicyModalContainer.defaultProps = {
  policyType: null,
};

const trackedPolicyModal = track({ module: 'PolicyModal' }, {
  dispatchOnMount: () => ({ event: 'Policy Required View Shown' }),
})(PolicyModalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedPolicyModal);
