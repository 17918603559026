/* eslint-disable react/no-array-index-key */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventListener } from 'lib/customHooks';
import { InformationCard } from 'types';

import { CarouselDirection } from './types';
import {
  Button,
  Carousel,
  CarouselItem,
  CarouselTrack,
  DotNavigationContainer,
  DotNavigationItem,
  Heading,
  InformationCardCarouselContainer,
  NavContainer,
  Paragraph,
} from './style';

const LEFT_ARROW_VALUE = 'ArrowLeft';
const RIGHT_ARROW_VALUE = 'ArrowRight';

type InformationCardCarouselProps = {
  informationCard: InformationCard;
  onFinished: () => void;
};

const InformationCardCarousel = ({
  informationCard,
  onFinished,
}: InformationCardCarouselProps) => {
  const { content: items, title } = informationCard;
  const { t } = useTranslation(['common', 'courseViewer']);

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const isOnLastItem = currentItemIndex === items.length - 1;

  const handleButtonClick = (direction: CarouselDirection) => {
    if (direction === CarouselDirection.Previous) setCurrentItemIndex(i => i - 1);
    else if (!isOnLastItem) setCurrentItemIndex(i => i + 1);
    else onFinished();
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === LEFT_ARROW_VALUE) handleButtonClick(CarouselDirection.Previous);
    else if (event.key === RIGHT_ARROW_VALUE && !isOnLastItem) handleButtonClick(CarouselDirection.Next);
  };

  useEventListener('keyup', handleKeyUp);

  return (
    <InformationCardCarouselContainer>
      <Carousel>
        <CarouselTrack xOffset={-currentItemIndex * 100}>
          {items.map((item, index) => (
            <CarouselItem key={index} isCurrent={index === currentItemIndex}>
              {index === 0 && <Heading>{title}</Heading>}
              <Paragraph>{item}</Paragraph>
            </CarouselItem>
          ))}
        </CarouselTrack>
      </Carousel>
      <NavContainer>
        <Button
          direction={CarouselDirection.Previous}
          disabled={currentItemIndex === 0}
          onClick={() => handleButtonClick(CarouselDirection.Previous)}
        >
          {t('back')}
        </Button>
        {items.length > 1 && (
          <DotNavigationContainer aria-label={t('courseViewer:informationCard.dotNavigationAriaLabel')}>
            {items.map((_, index) => (
              <DotNavigationItem
                key={index}
                aria-current={index === currentItemIndex ? 'step' : 'false'}
                onClick={() => setCurrentItemIndex(index)}
              />
            ))}
          </DotNavigationContainer>
        )}
        <Button
          direction={CarouselDirection.Next}
          isCTA={isOnLastItem}
          onClick={() => handleButtonClick(CarouselDirection.Next)}
        >
          {isOnLastItem ? t('continue') : t('next')}
        </Button>
      </NavContainer>
    </InformationCardCarouselContainer>
  );
};

export default InformationCardCarousel;
