import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { chapterType } from 'constants/propTypes';

import Loading from 'components/shared/Loading';
import StyledButton from 'components/shared/StyledButton';

import {
  ButtonsWrapper,
  ChapterWrapper,
  ContentWrapper,
  DeclineButton,
  MessageText,
  TitleText,
} from './style';

const FileDownloadChapter = ({
  chapter,
  isMobileDevice,
  isPosting,
  onChoiceSubmit,
  userChoice,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  let content;
  if (isPosting && userChoice === false) {
    content = <Loading />;
  } else {
    content = (
      <ContentWrapper>
        <TitleText>{chapter.title}</TitleText>
        <MessageText>{chapter.descriptionText}</MessageText>
        <ButtonsWrapper>
          <StyledButton
            loading={isPosting && userChoice === true}
            onClick={() => onChoiceSubmit(true)}
          >
            {t('courseViewer:fileDownload.downloadOption')}
          </StyledButton>
          <DeclineButton onClick={() => onChoiceSubmit(false)}>
            {t('noThanks')}
          </DeclineButton>
        </ButtonsWrapper>
      </ContentWrapper>
    );
  }

  return (
    <ChapterWrapper isMobileDevice={isMobileDevice}>
      {content}
    </ChapterWrapper>
  );
};

FileDownloadChapter.propTypes = {
  chapter: chapterType.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
  onChoiceSubmit: PropTypes.func.isRequired,
  userChoice: PropTypes.bool,
};

FileDownloadChapter.defaultProps = {
  userChoice: null,
};

export default FileDownloadChapter;
