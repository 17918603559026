import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from 'config'; // eslint-disable-line

import useModalCloseEventListener from 'lib/customHooks/useModalCloseEventListener';

import { languageChanged } from 'store/user/actions';
import { isUpdatingUserLanguageSelector } from 'store/user/selectors';

import LanguageMenu from './LanguageMenu';

export const LanguageMenuContainer = ({ tracking: { trackEvent } }) => {
  const { i18n, t } = useTranslation('common');
  const languageMenuRef = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentLanguageCode, setCurrentLanguageCode] = useState(i18n.language);

  const dispatch = useDispatch();
  const isUpdating = useSelector(isUpdatingUserLanguageSelector);

  useModalCloseEventListener(languageMenuRef, () => setIsMenuOpen(false));

  const handleLanguageMenuButtonClick = event => {
    event.preventDefault();
    setIsMenuOpen(prevIsOpen => !prevIsOpen);

    trackEvent({ event: 'Language Selector Menu Clicked' });
  };

  const handleLanguageChange = langCode => {
    if (currentLanguageCode !== langCode) {
      trackEvent({ event: 'Language Changed', language: langCode });
      setCurrentLanguageCode(langCode);

      dispatch(languageChanged(langCode));
    }
  };

  return (
    <LanguageMenu
      currentLanguageCode={currentLanguageCode}
      isMenuOpen={isMenuOpen}
      isUpdating={isUpdating}
      languageMenuRef={languageMenuRef}
      onLanguageChange={handleLanguageChange}
      onLanguageMenuButtonClick={handleLanguageMenuButtonClick}
      supportedLanguages={supportedLanguages}
      t={t}
    />
  );
};

LanguageMenuContainer.propTypes = {
  tracking: TrackingPropType,
};

LanguageMenuContainer.defaultProps = {
  tracking: null,
};

export default track({
  module: 'LanguageMenu',
})(LanguageMenuContainer);
