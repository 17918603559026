import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync
export const RESET_CHAPTERS_STORE_ERRORS = 'chapters/resetChaptersStoreErrors';
export const resetChaptersStoreErrors = createAction(RESET_CHAPTERS_STORE_ERRORS);

// Async
export const SEND_CHAPTER_STARTED_BOOKMARK = createAsyncTypes('chapters/sendChapterStartedBookmark');
export const sendChapterStartedBookmark = createAsyncAction(SEND_CHAPTER_STARTED_BOOKMARK, {
  log: {
    PENDING: true,
    SUCCESS: true,
  },
});

export const SEND_CHAPTER_COMPLETED_BOOKMARK = createAsyncTypes('chapters/sendChapterCompletedBookmark');
export const sendChapterCompletedBookmark = createAsyncAction(SEND_CHAPTER_COMPLETED_BOOKMARK, {
  log: {
    PENDING: true,
    SUCCESS: true,
  },
});

export const SKIP_CHAPTER_FOR_ACCESSIBILITY = createAsyncTypes('chapters/skipChapterForAccessibility');
export const skipChapterForAccessibility = createAsyncAction(SKIP_CHAPTER_FOR_ACCESSIBILITY);

export const HANDLE_CHAPTER_CHANGED = createAsyncTypes('chapters/handleChapterChanged');
export const handleChapterChanged = createAsyncAction(HANDLE_CHAPTER_CHANGED);

export const FETCH_CHILD_CHAPTER = createAsyncTypes('chapters/fetchChildChapter');
export const fetchChildChapter = createAsyncAction(FETCH_CHILD_CHAPTER);
