/* eslint-disable no-underscore-dangle */
import { CLOSED_CAPTIONS_STORAGE_KEY } from 'constants/appStorageKeys';
import AppStorage from 'lib/appStorage';

// disallows play from the play button
export function disablePlayButton(player) {
  if (player && player.controlBar) {
    player.controlBar.playToggle.off('click');
    player.controlBar.playToggle.off('play');
    player.controlBar.playToggle.off('keydown');
    player.controlBar.playToggle.off('touchstart');
  }
}

// allows play from the play button
// needs old event handler to bind to events
export function enablePlayButton(player) {
  if (player && player.controlBar) {
    player.controlBar.playToggle.on('click', player.controlBar.playToggle.handleClick);
    player.controlBar.playToggle.on('play', player.controlBar.playToggle.handlePlay);
    player.controlBar.playToggle.on('keydown', player.controlBar.playToggle.handleKeyDown);
    player.controlBar.playToggle.on('touchstart', player.controlBar.playToggle.enableTouchActivity);
  }
}

export const handleTextTrackChange = (player) => () => {
  if (player) {
    const currentTrack = player
      .remoteTextTracks().tracks_
      .find(track => track.mode === 'showing');

    const currentTrackLang = currentTrack ? currentTrack.language : null;
    AppStorage.setItem(CLOSED_CAPTIONS_STORAGE_KEY, currentTrackLang);
  }
};
