export const accessibilitySkipErrorSelector = state => state.chapters.accessibilitySkipError;
export const chaptersErrorSelector = state => state.chapters.error;
export const isLoadingChaptersSelector = state => state.chapters.isLoading;
export const isSendingCompletedBookmarkSelector = state => (
  state.chapters.isSendingCompletedBookmark
);
export const isRequestingAccessibilitySkipSelector = state => (
  state.chapters.isRequestingAccessibilitySkip
);
export const isUserAutoAnonymizedErrorSelector = state => (
  state.chapters.isUserAutoAnonymizedError
);
