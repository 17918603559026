/* eslint-disable sort-keys */

const STRONGLY_DISAGREE = 'strongly_disagree';
const DISAGREE = 'disagree';
const NEUTRAL = 'neutral';
const AGREE = 'agree';
const STRONGLY_AGREE = 'strongly_agree';

const likertRatings = {
  [STRONGLY_DISAGREE]: {
    id: STRONGLY_DISAGREE,
    value: STRONGLY_DISAGREE,
    score: [2, -2],
  },
  [DISAGREE]: {
    id: DISAGREE,
    value: DISAGREE,
    score: [1, -1],
  },
  [NEUTRAL]: {
    id: NEUTRAL,
    value: NEUTRAL,
    score: [0, 0],
  },
  [AGREE]: {
    id: AGREE,
    value: AGREE,
    score: [-1, 1],
  },
  [STRONGLY_AGREE]: {
    id: STRONGLY_AGREE,
    value: STRONGLY_AGREE,
    score: [-2, 2],
  },
};

export const likertScale = Object.keys(likertRatings).map(
  rating => likertRatings[rating],
);
export default likertRatings;
