/* eslint-disable sort-keys  */

import i18n from 'lib/i18n';

import dogImage1 from 'assets/dog1.jpg';
import dogImage2 from 'assets/dog2.jpg';
import dogImage3 from 'assets/dog3.jpg';
import dogImage4 from 'assets/dog4.jpg';
import catImage1 from 'assets/cat1.jpg';
import catImage2 from 'assets/cat2.jpg';
import catImage3 from 'assets/cat3.jpg';
import catImage4 from 'assets/cat4.jpg';

export const dogImages = [
  dogImage1,
  dogImage2,
  dogImage3,
  dogImage4,
];
export const catImages = [
  catImage1,
  catImage2,
  catImage3,
  catImage4,
];
export const tutorialImages = [...dogImages, ...catImages];

export default () => {
  const positiveTraits = i18n.t(
    'courseViewer:iatTutorial.positiveTraits',
    { returnObjects: true },
  );
  const negativeTraits = i18n.t(
    'courseViewer:iatTutorial.negativeTraits',
    { returnObjects: true },
  );

  return {
    description: i18n.t('courseViewer:iatTutorial.description'),
    keysConfig: {
      left: 'a',
      right: 'l',
    },
    uniqueStimuli: {
      [i18n.t('courseViewer:iatTutorial.dogs')]: dogImages,
      [i18n.t('courseViewer:iatTutorial.cats')]: catImages,
      [i18n.t('courseViewer:iatTutorial.positive')]: positiveTraits,
      [i18n.t('courseViewer:iatTutorial.negative')]: negativeTraits,
    },
    blocks: [
      {
        promptText: i18n.t('courseViewer:iatTutorial.photoMatchPrompt'),
        left: {
          image: i18n.t('courseViewer:iatTutorial.dogs'),
        },
        right: {
          image: i18n.t('courseViewer:iatTutorial.cats'),
        },
        blockIndex: 0,
        trials: [
          {
            promptText: i18n.t('courseViewer:iatTutorial.photoMatchTrialPrompt1'),
            correctKey: 'left',
            stimulusType: 'image',
            stimulusContent: dogImage1,
            id: '9d5a6068-24e2-11e9-82ea-f218985f9d3c',
          },
          {
            promptText: i18n.t('courseViewer:iatTutorial.photoMatchTrialPrompt2'),
            correctKey: 'right',
            stimulusType: 'image',
            stimulusContent: catImage1,
            id: '9d5c04b0-24e2-11e9-8946-f218985f9d3c',
          },
        ],
      },
      {
        promptText: i18n.t('courseViewer:iatTutorial.traitMatchPrompt'),
        left: {
          text: i18n.t('courseViewer:iatTutorial.positive'),
        },
        right: {
          text: i18n.t('courseViewer:iatTutorial.negative'),
        },
        blockIndex: 1,
        trials: [
          {
            correctKey: 'left',
            stimulusType: 'text',
            stimulusContent: positiveTraits[0],
            id: '9d5a6068-24e2-11e9-82ea-f218985f9d3c',
          },
          {
            correctKey: 'right',
            stimulusType: 'text',
            stimulusContent: negativeTraits[0],
            id: '9d5c04b0-24e2-11e9-8946-f218985f9d3c',
          },
        ],
      },
      {
        promptText: i18n.t('courseViewer:iatTutorial.categoryPairMatchPrompt'),
        left: {
          image: i18n.t('courseViewer:iatTutorial.dogs'),
          text: i18n.t('courseViewer:iatTutorial.positive'),
        },
        right: {
          image: i18n.t('courseViewer:iatTutorial.cats'),
          text: i18n.t('courseViewer:iatTutorial.negative'),
        },
        blockIndex: 2,
        trials: [
          {
            correctKey: 'left',
            stimulusType: 'image',
            stimulusContent: dogImage4,
            id: '9d5c13cc-24e2-11e9-a44a-f218985f9d3c',
          },
          {
            correctKey: 'right',
            stimulusType: 'text',
            stimulusContent: negativeTraits[0],
            id: '9d5c12c0-24e2-11e9-9f19-f218985f9d3c',
          },
        ],
      },
      {
        promptText: i18n.t('courseViewer:iatTutorial.categoryPairSwitchMatchPrompt'),
        left: {
          image: i18n.t('courseViewer:iatTutorial.dogs'),
          text: i18n.t('courseViewer:iatTutorial.negative'),
        },
        right: {
          image: i18n.t('courseViewer:iatTutorial.cats'),
          text: i18n.t('courseViewer:iatTutorial.positive'),
        },
        blockIndex: 3,
        trials: [
          {
            correctKey: 'right',
            stimulusType: 'image',
            stimulusContent: catImage4,
            id: '9d5c13cc-24e2-11e9-a44a-f218985f9d3c',
          },
          {
            correctKey: 'right',
            stimulusType: 'text',
            stimulusContent: positiveTraits[0],
            id: '9d5c12c0-24e2-11e9-9f19-f218985f9d3c',
          },
        ],
      },
    ],
  };
};
