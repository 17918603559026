import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';

export const IATKeyMappingContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 2px ${colors.ridge.base};
  border-radius: 20px;

  ${media.twoDimMin.tablet`flex-direction: column;`}

  ${p => p.spacing === 'compact' && css`
    padding: 12px;
    ${media.twoDimMin.tablet`padding: 24px 32px 48px;`}
    ${media.twoDimMin.desktop`padding: 26px 32px 54px;`}
    ${media.twoDimMin.max`padding: 30px 32px 62px;`}
  `}

  ${p => p.spacing === 'moderate' && css`
    padding: 16px;
    ${media.twoDimMin.tablet`padding: 40px 32px 60px;`}
    ${media.twoDimMin.desktop`padding: 44px 32px 66px;`}
    ${media.twoDimMin.max`padding: 48px 32px 72px;`}
  `}
`;

export const KeyLabelText = styled.div`
  display: block;
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  color: ${colors.yellow.base};
  text-align: center;
  letter-spacing: .8px;
  text-transform: uppercase;

  ${media.twoDimMin.tablet`font-size: 1.4rem;`}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}

  ${p => p.spacing === 'compact' && css`
    margin-left: 20px;
    ${media.twoDimMin.tablet`
      margin-left: 0;
      margin-top: 24px;
    `}
    ${media.twoDimMin.desktop`margin-top: 28px;`}
    ${media.twoDimMin.max`margin-top: 36px;`}
  `}

  ${p => p.spacing === 'moderate' && css`
    margin-left: 20px;
    ${media.twoDimMin.tablet`
      margin-left: 0;
      margin-top: 40px;
    `}
    ${media.twoDimMin.desktop`margin-top: 48px;`}
    ${media.twoDimMin.max`margin-top: 60px;`}
  `}
`;

export const KeyWrapper = styled.div`
  margin-left: 20px;

  ${media.twoDimMin.tablet`
    position: absolute;
    margin: 0;
    bottom: -30px;
    transform: translate(-50%);
    left: 50%;
  `}
  ${media.twoDimMin.desktop`bottom: -35px;`}
  ${media.twoDimMin.max`bottom: -40px;`}
`;

export const Key = styled.div`
  font-weight: ${type.weight.bold};
  border-radius: 12.1px;
  text-align: center;
  text-transform: uppercase;
  background-color: ${colors.yellow.background};
  color: ${colors.ridge.base};

  font-size: 3rem;
  line-height: 60px;
  width: 60px;
  height: 60px;

  ${media.twoDimMin.desktop`
    font-size: 3.6rem;
    line-height: 70px;
    width: 70px;
    height: 70px;
  `}

  ${media.twoDimMin.max`
    font-size: 4.2rem;
    line-height: 80px;
    width: 80px;
    height: 80px;
  `}
`;

export const TapSideText = styled.div`
  border-radius: 10px;
  background-color: ${colors.yellow.background};
  color: ${colors.ridge.base};
  font-weight: ${type.weight.bold};
  text-align: center;
  font-size: 2rem;
  line-height: 48px;
  width: 110px;
  height: 48px;

  ${media.twoDimMin.tablet`
    font-size: 2.4rem;
    line-height: 55px;
    width: 140px;
    height: 55px;
  `}
`;
