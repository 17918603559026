import Debug from 'debug';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import { camelizeKeys } from 'humps';

import { isTokenExpired } from 'lib/jwt';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import {
  REFRESH_ACCESS_TOKEN,
  refreshAccessToken,
} from 'store/auth/actions';
import { accessTokenSelector } from 'store/auth/selectors';

const debug = Debug('biassync:lms:services:API:fetchWithAuth');

const camelizationOptions = {
  process: (key, convert, options) => (
    /^[A-Z]$/.test(key) ? key : convert(key, options)
  ),
};

export default function* fetchWithAuth(request, endpointConfig, shouldReturnRawResponse = false) {
  debug('Called with', endpointConfig);

  let accessToken = yield select(accessTokenSelector);
  const isAccessTokenExpired = yield call(isTokenExpired, accessToken);

  if (isAccessTokenExpired) {
    yield put(refreshAccessToken());
    const [success, error] = yield call(awaitAsyncAction, REFRESH_ACCESS_TOKEN);

    if (error) throw error.payload;

    ({ accessToken } = success.payload);
  }

  const response = yield call(request, endpointConfig, accessToken);

  return shouldReturnRawResponse ? response : camelizeKeys(response.data, camelizationOptions);
}
