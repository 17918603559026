/* eslint-disable @typescript-eslint/default-param-last */

import { FETCH_COURSE } from 'store/courses/actions';
import { HANDLE_CHAPTER_CHANGED, FETCH_CHILD_CHAPTER } from 'store/chapters/actions';
import {
  SET_COURSE_COMPLETED,
  SET_USER_DATA_ANONYMIZED,
} from './actions';

export default (state = {}, { payload, type }) => {
  switch (type) {
    case FETCH_COURSE.SUCCESS:
      return { ...state, ...payload.courses };
    case SET_COURSE_COMPLETED:
      return {
        ...state,
        [payload.courseId]: {
          ...state[payload.courseId],
          userMetadata: {
            ...state[payload.courseId].userMetadata,
            currentChapterId: null,
            isCompleted: true,
          },
        },
      };
    case FETCH_CHILD_CHAPTER.PENDING:
    case HANDLE_CHAPTER_CHANGED.SUCCESS:
      return {
        ...state,
        [payload.courseId]: {
          ...state[payload.courseId],
          userMetadata: {
            ...state[payload.courseId].userMetadata,
            currentChapterId: payload.chapterId,
          },
        },
      };
    case SET_USER_DATA_ANONYMIZED:
      return {
        ...state,
        [payload.courseId]: {
          ...state[payload.courseId],
          userMetadata: {
            ...state[payload.courseId].userMetadata,
            areResultsAnonymized: true,
          },
        },
      };
    default:
      return state;
  }
};
