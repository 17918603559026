import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync

export const RESET_SURVEY_STORE = 'survey/resetSurveyStore';
export const resetSurveyStore = createAction(RESET_SURVEY_STORE);

// Async
export const FETCH_SURVEY = createAsyncTypes('survey/fetchSurvey');
export const fetchSurvey = createAsyncAction(FETCH_SURVEY);

export const FETCH_SURVEY_BLOCK = createAsyncTypes('survey/fetchSurveyBlock');
export const fetchSurveyBlock = createAsyncAction(FETCH_SURVEY_BLOCK);

export const SEND_SURVEY_BLOCK_RESULTS = createAsyncTypes('survey/sendSurveyBlockResults');
export const sendSurveyBlockResults = createAsyncAction(SEND_SURVEY_BLOCK_RESULTS);
