import PropTypes from 'prop-types';

import { coursePreviewType, errorType } from 'constants/propTypes';
import { BASELINE_COURSE, MICROLEARNING_COURSE } from 'constants/courseTypes';

import CourseLayout from 'components/shared/CourseLayout';
import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import PageTitle from 'components/shared/PageTitle';

import CoursePreview from './CoursePreview';
import { getCourseTypeSorted } from './utils';
import {
  EmptyContainer,
  EmptyText,
  Grid,
  GridCell,
  GridWrapper,
  Header,
  SubTitle,
  Title,
} from './style';

const CourseSelector = ({
  areCoursePreviewsAvailable,
  coursePreviews,
  error,
  haveCoursePreviewsBeenFetched,
  isFetchingCourses,
  onCourseSelect,
  shouldShowMicrosAsOptional,
  t,
}) => {
  if (error) return <ErrorView hasBackground text={error.message} />;

  const baselineCourses = getCourseTypeSorted(BASELINE_COURSE, coursePreviews);
  const microlearningCourses = getCourseTypeSorted(MICROLEARNING_COURSE, coursePreviews);

  let content;
  if (isFetchingCourses || !haveCoursePreviewsBeenFetched) content = <Loading />;
  else if (!areCoursePreviewsAvailable) content = <ErrorView text={t('errors:noCourses')} />;
  else {
    let baselineContent = null;
    if (!baselineCourses.length) {
      baselineContent = (
        <>
          <SubTitle>{t('courseSelector:modules')}</SubTitle>
          <EmptyContainer>
            <EmptyText>
              {t('courseSelector:filteredState.modulesEmpty')}
            </EmptyText>
          </EmptyContainer>
        </>
      );
    } else if (baselineCourses.length) {
      baselineContent = (
        <>
          <SubTitle>{t('courseSelector:modules')}</SubTitle>
          <GridWrapper>
            <Grid>
              {baselineCourses.map(course => (
                <GridCell key={course.id}>
                  <CoursePreview
                    course={course}
                    onClick={() => onCourseSelect(course)}
                  />
                </GridCell>
              ))}
            </Grid>
          </GridWrapper>
        </>
      );
    }

    let microlearningContent;
    if (!microlearningCourses.length) {
      microlearningContent = (
        <>
          <SubTitle>
            {(shouldShowMicrosAsOptional
              ? t('courseSelector:microlearningsOptional')
              : t('courseSelector:microlearnings')
            )}
          </SubTitle>
          <EmptyContainer>
            <EmptyText>
              {t('courseSelector:filteredState.microlearningsEmpty')}
            </EmptyText>
          </EmptyContainer>
        </>

      );
    } else if (microlearningCourses.length) {
      microlearningContent = (
        <>
          <SubTitle>
            {(shouldShowMicrosAsOptional
              ? t('courseSelector:microlearningsOptional')
              : t('courseSelector:microlearnings')
            )}
          </SubTitle>
          <GridWrapper>
            <Grid>
              {microlearningCourses.map(course => (
                <GridCell numRows={3} key={course.id}>
                  <CoursePreview
                    course={course}
                    onClick={() => onCourseSelect(course)}
                  />
                </GridCell>
              ))}
            </Grid>
          </GridWrapper>
        </>
      );
    }

    content = (
      <>
        <Header>
          <Title>{t('courseSelector:title')}</Title>
        </Header>
        {baselineContent}
        {microlearningContent}
      </>
    );
  }

  return (
    <>
      <PageTitle subPageTitle={t('courseSelector:title')} />
      <CourseLayout>
        {content}
      </CourseLayout>
    </>
  );
};

CourseSelector.propTypes = {
  areCoursePreviewsAvailable: PropTypes.bool.isRequired,
  coursePreviews: PropTypes.arrayOf(coursePreviewType),
  error: errorType,
  haveCoursePreviewsBeenFetched: PropTypes.bool.isRequired,
  isFetchingCourses: PropTypes.bool.isRequired,
  onCourseSelect: PropTypes.func.isRequired,
  shouldShowMicrosAsOptional: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CourseSelector.defaultProps = {
  coursePreviews: null,
  error: null,
};

export default CourseSelector;
