import styled from 'styled-components';
import {
  colors,
  media,
  spacing,
  type,
} from 'styles';

export const IATCategoryWrapper = styled.div``;

export const KeyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing.compact};

  ${({ orientation }) => orientation === 'right' && `
    flex-direction: row-reverse;
  `}

  ${media.twoDimMin.baseline`margin-top: ${spacing.moderate};`}
  ${media.twoDimMin.max`margin-top: ${spacing.normal};`}
`;

export const Key = styled.div`
  font-weight: ${type.weight.bold};
  border-radius: 12.1px;
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ isErrored }) => (isErrored ? colors.red.base : colors.blue.base)};
  font-size: 3rem;
  line-height: 60px;
  width: 60px;
  height: 60px;

  ${media.twoDimMin.baseline`
    font-size: 3.6rem;
    line-height: 70px;
    width: 70px;
    height: 70px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 4.2rem;
    line-height: 80px;
    width: 80px;
    height: 80px;
  `}

  ${media.twoDimMin.max`
    font-size: 4.8rem;
    line-height: 90px;
    width: 90px;
    height: 90px;
  `}
`;

export const Instruction = styled.div`
  width: 175px;
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};

  ${({ orientation }) => (orientation === 'right' ? `
    text-align: right;
    margin-right: ${spacing.normal};
  ` : `
    margin-left: ${spacing.normal};
  `)}

  ${media.twoDimMin.desktop`font-size: 1.4rem;`}
  ${media.twoDimMin.max`font-size: 1.6rem;`}
`;

export const Subtext = styled.span`
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  display: inline-block;

  ${media.twoDimMin.baseline`
    font-size: 2rem;
    margin: 2px 0;
  `}

  ${media.twoDimMin.desktop`font-size: 2.2rem;`}

  ${media.twoDimMin.max`
    font-size: 2.4rem;
    margin: 4px 0;
  `}
`;

export const ValuesWrapper = styled.div`
  ${({ orientation }) => orientation === 'right' && `
    text-align: right;
  `}

  ${({ isErrored }) => isErrored && `
    color: ${colors.red.base};
  `}
`;

export const Value = styled.div`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};

  ${media.twoDimMin.baseline`font-size: 2.8rem;`}
  ${media.twoDimMin.desktop`font-size: 3.2rem;`}
  ${media.twoDimMin.max`font-size: 3.6rem;`}
`;
