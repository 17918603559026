import React from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors';

import { RingSpinner } from './style';

const LoadingSpinner = ({ color, testId }) => (
  <RingSpinner data-testid={testId} color={color}>
    <div />
    <div />
    <div />
    <div />
  </RingSpinner>
);

LoadingSpinner.propTypes = {
  color: PropTypes.string,
  testId: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  color: colors.blue.base,
  testId: null,
};

export default LoadingSpinner;
