import styled from 'styled-components';
import { colors, media } from 'styles';

export const CourseViewerWrapper = styled.div`
  height: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: -58px;

  ${media.min.mobileLandscape`right: -70px;`}
  ${media.min.tablet`right: -80px;`}
`;

export const TOCWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  background-color: ${colors.white};
`;
