import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

const flexRowSpaceBetweenCenter = `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AccountDetailsWrapper = styled.div`
  margin: 35px 28px;

  ${media.min.mobileLandscape`margin: 45px 52px;`}
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${type.weight.bold};
  margin: 0 0 45px;

  ${media.min.mobileLandscape`font-size: 4rem;`}
`;

export const CancelButton = styled(TransparentButton)`
  margin-left: 14px;
  color: ${colors.blue.base};
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  outline: none;

  &.focus-visible { border-bottom: 2px solid ${colors.blue.light}; }

  ${media.min.baseline`font-size: 1.8rem;`}
  ${media.min.mobileLandscape`margin-left: 26px;`}
`;

export const BasicInfoSectionHeader = styled.div`
  ${flexRowSpaceBetweenCenter}
`;

export const BasicInfoButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
  margin: 36px 0 62px 0;

  ${media.min.desktop`column-gap: 24px;`}
`;

export const TextFieldWrapper = styled.div`
  &:last-child {
    margin-top: 36px;
    grid-column: span 2;
    
    -ms-grid-column-span: 3;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  margin: 0;

  ${media.min.mobileLandscape`font-size: 2.2rem;`}
`;

export const SectionItem = styled.div`
  ${flexRowSpaceBetweenCenter}
`;

export const SectionDescription = styled.p`
  font-size: 1.6rem;
  margin-right: 18px;

  ${media.min.mobileLandscape`font-size: 1.8rem;`}
`;

export const SectionWrapper = styled.div`
  margin-top: 45px;
`;
