import Debug from 'debug';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { currentCourseIdSelector } from 'store/courses/selectors';
import { videoUserMetadataSelector } from 'store/video/selectors';
import { updateChapterUserMetadata } from 'store/entities/chapters/actions';

import {
  SAVE_VIDEO_USER_METADATA,
  saveVideoUserMetadata,
} from '../actions';

const debug = Debug('biassync:lms:store:video:sagas:saveVideoUserMetadata');

export function* saveVideoUserMetadataSaga({ payload: chapterId }) {
  debug('Saga called', chapterId);

  const courseId = yield select(currentCourseIdSelector);
  const userMetadata = yield select(videoUserMetadataSelector);

  yield put(updateChapterUserMetadata({
    chapterId,
    courseId,
    userMetadata,
  }));

  yield put(saveVideoUserMetadata.success());
}

export default function* saveVideoUserMetadataDefaultSaga() {
  yield takeLatest(SAVE_VIDEO_USER_METADATA.PENDING, saveVideoUserMetadataSaga);
}
