/* eslint-disable sort-keys */

export const IAT_INTRO = 'IAT_INTRO';
export const TUTORIAL_OUTRO = 'TUTORIAL_OUTRO';
export const CATEGORIES_INTRO = 'CATEGORIES_INTRO';
export const ATTRIBUTES_INTRO = 'ATTRIBUTES_INTRO';
export const BLOCK_INTRO = 'BLOCK_INTRO';
export const TRIAL = 'TRIAL';

const NEXT_STATES = {
  [IAT_INTRO]: CATEGORIES_INTRO,
  [CATEGORIES_INTRO]: ATTRIBUTES_INTRO,
  [ATTRIBUTES_INTRO]: BLOCK_INTRO,
};

export const getNextState = currentState => NEXT_STATES[currentState];
