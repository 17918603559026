export enum ChapterType {
  AnonymizeResults = 'anonymize_results',
  CompositeSurvey = 'composite_survey',
  CCAResults = 'cca_results',
  FileDownload = 'file_download',
  Forward = 'forward',
  IAT = 'implicit_association_test',
  IATTutorial = 'implicit_association_tutorial',
  InformationCard = 'information_card',
  LikertTest = 'likert_test',
  Survey = 'survey',
  Video = 'video',
}

export enum ChapterSubType {
  Familiarity = 'familiarity',
  NoEmail = 'no_email',
  Standard = 'standard',
  Vignette = 'vignette',
}

export interface ChapterUserMetadata {
  block: number | null;
  bookmark: number | null;
  furthestPoint: number | null;
  isCompleted: boolean;
  isStarted: boolean;
}

export interface ChapterFeatureFlags {
  combinedIatResults?: boolean;
}

export interface Chapter {
  absoluteIndex: number;
  descriptionText: string | null;
  duration: number;
  featureFlags?: ChapterFeatureFlags;
  id: string;
  loType: ChapterType;
  orderIndex: number;
  posterImageLink: string | null;
  requiresTestTaker: boolean;
  sectionIndex: number;
  subType: ChapterSubType;
  title: string;
  userMetadata: ChapterUserMetadata | EmptyObject;
}
