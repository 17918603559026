import PropTypes from 'prop-types';

import PageTitle from 'components/shared/PageTitle';
import SlideOutPanel from 'components/shared/SlideOutPanel';

import Chapter from './Chapter';
import Header from './Header';
import TableOfContentsPanel from './TableOfContentsPanel';

import {
  ContentWrapper,
  CourseViewerWrapper,
} from './style';

const CourseViewer = ({
  currentChapterTitle,
  isTableOfContentsPanelOpen,
  onChapterSelect,
  onTableOfContentsToggle,
}) => (
  <>
    <PageTitle subPageTitle={currentChapterTitle} />
    <CourseViewerWrapper>
      <SlideOutPanel
        direction="left"
        isOpen={isTableOfContentsPanelOpen}
        onClose={onTableOfContentsToggle}
      >
        <TableOfContentsPanel
          isOpen={isTableOfContentsPanelOpen}
          onChapterSelect={onChapterSelect}
          onClose={onTableOfContentsToggle}
        />
      </SlideOutPanel>
      <ContentWrapper>
        <Header />
        <Chapter />
      </ContentWrapper>
    </CourseViewerWrapper>
  </>
);

CourseViewer.propTypes = {
  currentChapterTitle: PropTypes.string.isRequired,
  isTableOfContentsPanelOpen: PropTypes.bool.isRequired,
  onChapterSelect: PropTypes.func.isRequired,
  onTableOfContentsToggle: PropTypes.func.isRequired,
};

export default CourseViewer;
