import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isTouchScreenSelector } from 'store/userAgent/selectors';

import { Dimension as DimensionType } from '../types';

import Dimension from './Dimension';
import HeaderAccordion from './HeaderAccordion';

import {
  Description,
  SectionContainer,
  SectionTitle,
} from '../style';
import { Container } from './style';

interface DimensionsProps {
  dimensions: DimensionType[];
  profileName: string;
}

const DimensionsContainer = ({ dimensions, profileName }: DimensionsProps) => {
  const { t } = useTranslation('courseViewer');

  const isTouchScreen = useSelector(isTouchScreenSelector);

  return (
    <SectionContainer>
      <SectionTitle>{t('courseViewer:ccaResults.dimensions.heading')}</SectionTitle>
      <Description dangerouslySetInnerHTML={{ __html: t(`courseViewer:ccaResults.dimensions.description.${profileName}`) }} />
      <Container>
        {dimensions.map(({
          description,
          dimensionName,
          result,
        }) => (
          <HeaderAccordion
            isOpenDefault={!isTouchScreen}
            key={dimensionName}
            name={dimensionName}
            scoreCategory={result.scoreCategory}
          >
            <Dimension
              content={result}
              description={description}
              name={dimensionName}
            />
          </HeaderAccordion>
        ))}
      </Container>
    </SectionContainer>
  );
};

export default DimensionsContainer;
