import PropTypes from 'prop-types';

import { Wrapper, ProgressDot } from './style';

const ProgressBar = ({ currentStepIndex, items }) => {
  const lengthOfSlider = Array(items.length).fill();

  return (
    <Wrapper>
      {lengthOfSlider.map((_, blockIndex) => {
        const blockKey = blockIndex + 1;
        const isCurrentBlock = blockIndex === currentStepIndex;

        if (isCurrentBlock) return <ProgressDot key={blockKey} status="current" />;
        return <ProgressDot key={blockKey} />;
      })}
    </Wrapper>
  );
};

ProgressBar.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  ).isRequired,
};

export default ProgressBar;
