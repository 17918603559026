import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

import TransparentButton from 'styles/components/TransparentButton';
import { colors, media, type } from 'styles';

export const ChevronIcon = styled.img`
  width: 9px;
  height: 15px;
  margin-left: 10px;
  flex-shrink: 0;
`;

const menuItemStyles = css`
  display: block;
  width: 100%;
  outline: none;

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    background-color: ${colors.rocky.background};
  }

  &:hover { background-color: ${colors.rocky.background}; }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const MenuItemButton = styled(TransparentButton)`
  ${menuItemStyles}
`;

export const MenuItemLink = styled(ReactRouterLink)`
  ${menuItemStyles}
`;

export const MenuItemAnchor = styled.a`
  ${menuItemStyles}
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 18px;
  text-align: left;
  color: ${colors.sierra.base};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};

  ${media.min.tablet`
    font-size: 1.6rem;
    padding: 18px 20px;
  `}
`;
