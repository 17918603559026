/* eslint-disable jsx-a11y/control-has-associated-label */

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as PromptType from 'constants/surveyPromptTypes';
import { surveyPromptType } from 'constants/propTypes';

import RadioButton from 'components/shared/RadioButton';
import { NOT_APPLICABLE } from 'components/shared/Survey/constants';

import {
  ColumnHeader,
  ColumnSpacing,
  EmptyTableCell,
  Table,
  TableCell,
  TableRow,
  TableWrapper,
} from './style';

const MatrixPrompt = ({
  isActive,
  onItemChange,
  onItemClick,
  prompt: {
    hasNaOption,
    likertLabels,
    subPrompts,
    subPromptType,
  },
  responses,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);
  const theme = useTheme();

  const isLikertSubPromptType = subPromptType === PromptType.Likert;
  const labels = isLikertSubPromptType
    ? likertLabels ?? t('likertScaleLabels', { returnObjects: true })
    : [t('courseViewer:survey.yesButtonText'), t('courseViewer:survey.noButtonText')];
  const numOfColumns = labels.length + (hasNaOption ? 1 : 0);

  const spacing = (
    <colgroup>
      <ColumnSpacing isSeparateCellPresent={hasNaOption} isLikertScale={isLikertSubPromptType} />
      {labels.map(label => <ColumnSpacing id={label} key={label} />)}
      {hasNaOption && (
        <>
          <ColumnSpacing isEmptyColumn />
          <ColumnSpacing />
        </>
      )}
    </colgroup>
  );

  const header = (
    <tr>
      <ColumnHeader numOfColumns={numOfColumns} />
      {labels.map(label => (
        <ColumnHeader id={label} key={label} numOfColumns={numOfColumns}>
          {label}
        </ColumnHeader>
      ))}
      {hasNaOption && (
        <>
          <th />
          <ColumnHeader id="not-applicable-column-id" numOfColumns={numOfColumns} isNaOption>
            {t('courseViewer:survey.notApplicable')}
          </ColumnHeader>
        </>
      )}
    </tr>
  );

  const cells = subPrompts.map(subPrompt => (
    <TableRow key={subPrompt.id} isActive={isActive}>
      <TableCell data-testid={`table-cell-prompt-text-${subPrompt.id}`} dangerouslySetInnerHTML={{ __html: subPrompt.promptText }} />
      {labels.map((label, index) => {
        const selectedRating = isLikertSubPromptType ? index + 1 : [true, false][index];
        const isSelected = responses[subPrompt.id] === selectedRating;
        const id = `${subPrompt.id}_${label}`;

        return (
          <TableCell key={id} isSeparateCellPresent={hasNaOption}>
            <RadioButton
              ariaLabelledBy={label}
              id={id}
              isChecked={isSelected}
              name={subPrompt.id}
              onChange={() => onItemChange(subPrompt.id, selectedRating)}
              onClick={() => onItemClick(subPrompt.id, selectedRating)}
              theme={theme.radioButton}
            />
          </TableCell>
        );
      })}
      {hasNaOption && (
        <>
          <EmptyTableCell />
          <TableCell key={`${subPrompt.id}_na`} isSeparateCell>
            <RadioButton
              ariaLabelledBy="not-applicable-column-id"
              id={`${subPrompt.id}_na`}
              isChecked={responses[subPrompt.id] === NOT_APPLICABLE}
              name={subPrompt.id}
              onChange={() => onItemChange(subPrompt.id, NOT_APPLICABLE)}
              onClick={() => onItemClick(subPrompt.id, NOT_APPLICABLE)}
              theme={theme.radioButton}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  ));

  return (
    <TableWrapper>
      <Table>
        {spacing}
        <thead>{header}</thead>
        <tbody>{cells}</tbody>
      </Table>
    </TableWrapper>
  );
};

MatrixPrompt.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onItemChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  responses: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ])).isRequired,
};

export default MatrixPrompt;
