/* eslint-disable prefer-destructuring */

import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useConstructor } from 'lib/customHooks';

type PortalProps = {
  children: JSX.Element;
};

const Portal = ({
  children,
}: PortalProps) => {
  const parentDomNode = useRef<HTMLElement>();
  const domNode = useRef<HTMLElement>();

  useConstructor(() => {
    parentDomNode.current = document.getElementsByClassName('vjs-progress-holder')[0] as HTMLElement;
    domNode.current = document.createElement('div');
  });

  useEffect(() => {
    if (parentDomNode.current) {
      parentDomNode.current.insertBefore(domNode.current!, parentDomNode.current.children[1]);
    }

    return () => {
      if (parentDomNode.current) parentDomNode.current.removeChild(domNode.current!);
    };
  }, []);

  return createPortal(children, domNode.current!);
};

export default Portal;
