import styled from 'styled-components';
import { media } from 'styles';

export const ButtonIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 12px;

  ${media.min.desktop`
    width: 20px;
    height: 20px;
    margin-right: 16px;
  `}
`;
