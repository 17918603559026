import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const TextInput = styled.input`
  display: block;
  border: none;
  background-color: transparent;
  outline: 0;
  padding: 10px 0;
  font-size: 1.6rem;
  max-width: 100%;
  width: ${({ maxLength }) => `${maxLength * 20}px`};
  align-self: center;
  text-align: center;

  ${({ hasError }) => (hasError ? `
    border-bottom: 2px solid ${colors.red.base};
  ` : `
    border-bottom: 2px solid ${colors.white};
  `)};

  ${media.min.baseline`font-size: 2rem;`}

  &::placeholder {
    color: ${colors.white};
    opacity: 0.5;
    font-size: 1.6rem;

    ${media.min.baseline`font-size: 2rem;`}
  }

  /* Edge need to be explicit */
  &::-ms-input-placeholder {
    color: ${colors.white} !important;
    opacity: 0.5 !important;
    font-size: 1.6rem !important;
  }
`;

export const ErrorWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
  height: 32px;
`;

export const ErrorMessage = styled.span`
  display: inline-block;
  background-color: ${colors.red.base};
  color: ${colors.white};
  text-align: center;
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: ${type.weight.semiBold};
  font-size: 1.2rem;
`;
