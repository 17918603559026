import fetchChildChapter from './fetchChildChapter';
import handleChapterChanged from './handleChapterChanged';
import sendChapterCompletedBookmark from './sendChapterCompletedBookmark';
import sendChapterStartedBookmark from './sendChapterStartedBookmark';
import skipChapterForAccessibility from './skipChapterForAccessibility';

export default [
  fetchChildChapter,
  handleChapterChanged,
  sendChapterCompletedBookmark,
  sendChapterStartedBookmark,
  skipChapterForAccessibility,
];
