import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  LOGIN_WITH_AUTH_CODE,
  loginWithAuthCode,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:loginWithAuthCode');

export function* loginWithAuthCodeSaga({ payload: authCode }) {
  debug('Saga called with auth code', authCode);

  try {
    const loginResponse = yield call(API.loginWithAuthCode, authCode);

    yield put(loginWithAuthCode.success(loginResponse));
  } catch (e) {
    debug('Saga error', e);
    yield put(loginWithAuthCode.error(e));
  }
}

export default function* loginWithAuthCodeDefaultSaga() {
  yield takeLatest(LOGIN_WITH_AUTH_CODE.PENDING, loginWithAuthCodeSaga);
}
