import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import { currentCourseIdSelector } from 'store/courses/selectors';

import {
  SEND_VIDEO_TASK_ANSWER,
  sendVideoTaskAnswer,
} from '../actions';

const debug = Debug('biassync:lms:store:sagas:video:sendVideoTaskAnswer');

export function* sendVideoTaskAnswerSaga({ payload }) {
  const { response, taskId } = payload;
  debug('Saga called', payload);

  const courseId = yield select(currentCourseIdSelector);

  try {
    const taskResponse = yield call(API.answerVideoTask, courseId, taskId, response);

    debug('Video task response received', taskResponse);

    yield put(sendVideoTaskAnswer.success({ courseId, taskId, taskResponse }));
  } catch (e) {
    debug('Video task response error', e);
    yield put(sendVideoTaskAnswer.error({ courseId, taskId }));
  }
}

export default function* sendVideoTaskAnswerDefaultSaga() {
  yield takeLatest(SEND_VIDEO_TASK_ANSWER.PENDING, sendVideoTaskAnswerSaga);
}
