import Debug from 'debug';

const debug = Debug('biassync:lms:lib:storage');

const hasItem = key => {
  let item = null;

  try {
    item = window.localStorage.getItem(key);
  } catch (error) {
    debug('Error checking item in storage with key', key);
  }

  return item !== null;
};

const getItem = key => {
  let item = null;

  try {
    item = window.localStorage.getItem(key);
  } catch (error) {
    debug('Error getting item from storage with key', key);
  }

  return item;
};

const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    debug('Error setting item from storage with key', key);
  }
};

const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    debug('Error removing item from storage with key', key);
  }
};

export default {
  getItem,
  hasItem,
  removeItem,
  setItem,
};
