import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'styles/components/DotSpinner';

import {
  IATLoadingWrapper,
  LoadingText,
} from './style';

const IATLoading = ({ text }) => (
  <IATLoadingWrapper>
    <LoadingSpinner />
    <LoadingText>
      {text}
    </LoadingText>
  </IATLoadingWrapper>
);

IATLoading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default IATLoading;
