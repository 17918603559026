import { useSelector } from 'react-redux';

import VideoPlayer from 'lib/VideoPlayer';

import {
  isVideoPlayableSelector,
  videoFurthestPointSelector,
} from 'store/video/selectors';
import { isBaselineCourseSelector } from 'store/courses/selectors';

import FurthestPointProgressBar from './FurthestPointProgressBar';
import RewindButton from './RewindButton';
import Watermark from './Watermark';

const CustomVideoPlayerComponents = () => {
  const furthestPoint: number = useSelector(videoFurthestPointSelector);
  const isBaselineCourse: boolean = useSelector(isBaselineCourseSelector);
  const isVideoPlayable: boolean = useSelector(isVideoPlayableSelector);

  const duration = VideoPlayer.getDuration();
  const furthestPointPercentage = isVideoPlayable ? (duration && (furthestPoint / duration)) : 0;

  return (
    <>
      <FurthestPointProgressBar progressPercentage={furthestPointPercentage} />
      <RewindButton onClick={() => VideoPlayer.rewind()} />
      {isBaselineCourse && isVideoPlayable && <Watermark />}
    </>
  );
};

export default CustomVideoPlayerComponents;
