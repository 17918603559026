import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import Modal from 'components/shared/Modal';
import {
  CancelButton,
  DialogContent,
  Footer,
  Title,
} from './style';

const ConfirmAccessibilitySkipModal = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal onClose={onClose}>
      <DialogContent>
        <Title>{t('confirmAccessibilitySkipModal.title')}</Title>
        <Trans i18nKey="common:confirmAccessibilitySkipModal.message">
          <p>
            If you are using an assistive device or otherwise having difficulty completing the
            unconscious bias assessment, you can advance past this chapter without participating
            in the assessment.
          </p>
          <p>Please use this only if it applies to you.</p>
        </Trans>
        <Footer>
          <CancelButton onClick={onClose}>
            {t('confirmAccessibilitySkipModal.cancelButton')}
          </CancelButton>
          <SimpleButton type="button" onClick={onSubmit}>
            {t('confirmAccessibilitySkipModal.submitButton')}
          </SimpleButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

ConfirmAccessibilitySkipModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmAccessibilitySkipModal;
