import PropTypes from 'prop-types';

import {
  Input,
  Label,
  CheckboxesOptionWrapper,
} from './style';

const CheckboxesOption = ({
  children,
  id,
  isDisabled,
  isSelected,
  keyShortcut,
  name,
  onChange,
}) => (
  <CheckboxesOptionWrapper>
    <Input
      checked={isSelected}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      type="checkbox"
    />
    <Label
      isDisabled={isDisabled}
      htmlFor={id}
      keyShortcut={keyShortcut}
    >
      {children}
    </Label>
  </CheckboxesOptionWrapper>
);

CheckboxesOption.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  keyShortcut: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxesOption;
