/* eslint-disable no-underscore-dangle */
import Debug from 'debug';
import videojs from 'video.js';
import { setVideoPlayerInitialized } from 'store/video/actions';
import VideoResolutions from 'constants/videoResolutions';
import { CLOSED_CAPTIONS_STORAGE_KEY, VIDEO_RESOLUTION_STORAGE_KEY } from 'constants/appStorageKeys';
import closedCaptionLabels from 'constants/closedCaptionLabels';

import AppStorage from 'lib/appStorage';
import bindEventHandlers from './bindEventHandlers';
import vjsOptions from './vjsOptions';

import {
  enablePlayButton,
  disablePlayButton,
} from './eventHandlers';

import {
  hideVideoButton,
  displayVideoButton,
  blurVideo,
  disableBlurVideo,
} from './cssControls';

import 'video.js/dist/video-js.css';

const debug = Debug('biassync:lms:lib:VideoPlayer');
const SEEK_INTERVAL = 15;
const VOLUME_INTERVAL = 0.1;

class VideoPlayer {
  constructor() {
    this.player = null;
  }

  getCurrentTime() {
    return this.player ? this.player.currentTime() : 0;
  }

  getDuration() {
    return this.player ? this.player.duration() : 0;
  }

  hide() {
    if (this.player) {
      this.player.hide();
    }
  }

  initialize(node, dispatch, { isIOS, isMobileDevice }) {
    const options = vjsOptions({ isMobileDevice });

    this.player = videojs(node, options, () => {
      dispatch(setVideoPlayerInitialized());
      this.player.hide(); // Hiding player until video is playable
    });

    bindEventHandlers(this.player, dispatch, isIOS);
  }

  isFullscreen() {
    return this.player ? this.player.isFullscreen() : false;
  }

  isSeeking() {
    return this.player ? this.player.seeking() : false;
  }

  isPaused() {
    return this.player ? this.player.paused() : true;
  }

  isControlsEnabled() {
    return this.player ? this.player.controls() : true;
  }

  play() {
    if (this.player) {
      this.player.play();
    }
  }

  pause() {
    if (this.player) {
      this.player.pause();
    }
  }

  show() {
    if (this.player) {
      this.player.show();
    }
  }

  disableClick = () => {
    if (this.player) this.player.addClass('video-player-disable');
  };

  enableClick = () => {
    if (this.player) this.player.removeClass('video-player-disable');
  };

  disablePlay = () => {
    if (this.player) {
      // hide big play button
      hideVideoButton();
      // disable clicking play button to play
      disablePlayButton(this.player);
    }
  };

  enablePlay = () => {
    if (this.player) {
      // display big play button
      displayVideoButton();
      // enable clicking play button to play
      enablePlayButton(this.player);
    }
  };

  blurVideo = () => {
    if (this.player) {
      blurVideo(this.player.el());
    }
  };

  disableBlurVideo = () => {
    if (this.player) {
      disableBlurVideo(this.player.el());
    }
  };

  queueWhenVideoIsLoaded(callback) {
    if (this.player) {
      this.player.one('loadedmetadata', callback);
    }
  }

  rewind() {
    if (this.player) {
      debug(`Rewinding video ${SEEK_INTERVAL} seconds`);
      let newTime = this.player.currentTime() - SEEK_INTERVAL;
      newTime = newTime < 0 ? 0 : newTime;
      this.player.currentTime(newTime);
      this.play();
    }
  }

  fastForward() {
    if (this.player) {
      debug(`Forwarding video ${SEEK_INTERVAL} seconds`);
      const duration = this.getDuration();
      const newTime = this.player.currentTime() + SEEK_INTERVAL;
      if (newTime >= duration) {
        this.player.currentTime(duration - 0.001);
        this.play();
      } else {
        this.player.currentTime(newTime);
      }
    }
  }

  setCurrentTime(seconds) {
    if (this.player) {
      debug('Setting current time to', seconds);
      this.player.currentTime(seconds);
    }
  }

  setupVideo(source, captionUrls, isIOS) {
    return new Promise((resolve, reject) => {
      if (!this.player) reject();

      this.player.src({
        src: source,
        type: 'application/x-mpegURL',
      });
      this.player.one('loadedmetadata', () => {
        if (!isIOS) {
          const videoResolution = this.getResolution();
          this.updateResolution(videoResolution);
        }

        this.setClosedCaptions(captionUrls);

        resolve();
      });
    });
  }

  setClosedCaptions(captionUrls) {
    if (this.player) {
      const closedCaptionsLanguage = this.getClosedCaptionsLanguage();
      debug('Setting closed captions', closedCaptionsLanguage);

      Object.keys(captionUrls).forEach(captionKey => {
        const textTrack = this.player.addRemoteTextTrack({
          label: closedCaptionLabels[captionKey],
          src: captionUrls[captionKey],
          srclang: captionKey,
        }, false);

        if (captionKey === closedCaptionsLanguage) textTrack.track.mode = 'showing';
      });
    }
  }

  getClosedCaptionsLanguage() {
    return AppStorage.getItem(CLOSED_CAPTIONS_STORAGE_KEY);
  }

  setResolution(videoResolution) {
    AppStorage.setItem(VIDEO_RESOLUTION_STORAGE_KEY, videoResolution);
    this.updateResolution(videoResolution);
  }

  updateResolution(videoResolution) {
    if (this.player) {
      debug('Setting video resolution', videoResolution);

      const representations = this.player.tech().vhs?.representations();
      representations.forEach((representation) => {
        const is4K = /_4k\.m3u8$/i.test(representation.id);
        let shouldEnable;

        if (is4K) shouldEnable = false;
        else if (videoResolution === VideoResolutions.V_AUTO) shouldEnable = true;
        else {
          const resolutionPattern = new RegExp(`_${videoResolution}.m3u8$`, 'i');
          shouldEnable = resolutionPattern.test(representation.id);
        }

        try {
          representation.enabled(shouldEnable);
        } catch (error) {
          debug('Video player representation error:', error);
        }
      });
    }
  }

  getResolution() {
    return AppStorage.getItem(VIDEO_RESOLUTION_STORAGE_KEY)
      || VideoResolutions.V_AUTO;
  }

  reloadPlaylist() {
    if (this.player) {
      const vhsTech = this.player.tech().vhs;
      vhsTech.playlists.load();
    }
  }

  volumeUp() {
    if (this.player) {
      const volume = this.player.volume();
      this.player.volume(volume + VOLUME_INTERVAL);
      debug('Turning volume up to', volume);
    }
  }

  volumeDown() {
    if (this.player) {
      const volume = this.player.volume();
      this.player.volume(volume - VOLUME_INTERVAL);
      debug('Turning volume down to', volume);
    }
  }

  toggleMute() {
    if (this.player) {
      this.player.muted(!this.player.muted());
      debug('Muting video player');
    }
  }

  toggleFullscreen() {
    if (this.player) {
      if (this.isFullscreen()) {
        this.player.exitFullscreen();
        debug('Exiting fullscreen');
      } else {
        this.player.requestFullscreen();
        debug('Requesting fullscreen');
      }
    }
  }

  teardown() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  }
}

const instance = new VideoPlayer();

export default instance;
