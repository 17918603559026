import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import errorIcon from 'assets/iat-x-icon.svg';

import {
  ErrorIconWrapper,
  ErrorText,
  RedText,
} from './style';

export const IATErrorView = ({
  correctText,
  incorrectText,
  isImageStimulus,
  isMobile,
}) => {
  const { t } = useTranslation('courseViewer');
  const stimulusText = isImageStimulus
    ? t('courseViewer:iat.error.image')
    : t('courseViewer:iat.error.text');

  return (
    <>
      <ErrorIconWrapper>
        <img src={errorIcon} alt="" />
      </ErrorIconWrapper>
      <ErrorText isImageStimulus={isImageStimulus} isMobile={isMobile}>
        <Trans i18nKey="courseViewer:iat.error.message">
          This {{ stimulusText }}
          <RedText> matches with </RedText>
          {{ correctText }}
          <RedText>, not </RedText>
          {{ incorrectText }}
        </Trans>
      </ErrorText>
    </>
  );
};

IATErrorView.propTypes = {
  correctText: PropTypes.string.isRequired,
  incorrectText: PropTypes.string.isRequired,
  isImageStimulus: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default IATErrorView;
