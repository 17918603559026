import styled from 'styled-components';

import checkmarkIcon from 'assets/checkmark-icon.svg';
import { colors, media } from 'styles';

export const CheckboxWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  padding-left: 30px;
  font-size: 1.4rem;
  user-select: none;

  ${media.min.baseline`
    padding-left: 36px;
    font-size: 1.6rem;
  `}

  &:before {
    -webkit-box-sizing: border-box;
    border: 1px solid ${colors.white};
    border-radius: 4px;
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;

    ${media.min.baseline`
      width: 24px;
      height: 24px;
    `}
  }

  ${Input}.focus-visible + &:before {
    box-shadow: ${colors.yellow.base} 0px 0px 0px 3px;
  }

  ${Input}:checked ~ &:after {
    -webkit-box-sizing: border-box;
    background-image: url(${checkmarkIcon});
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    left: 10px;
    margin-left: -6px;
    margin-top: -3px;
    width: 12px;
    height: 12px;

    ${media.min.baseline`
      margin-left: -5px;
      margin-top: -4px;
      width: 14px;
      height: 14px;
    `}
  }
`;
