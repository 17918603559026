import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import { preloadImages } from '../utils';
import {
  FETCH_SURVEY_BLOCK,
  fetchSurveyBlock,
} from '../actions';

const debug = Debug('biassync:lms:store:survey:sagas:fetchSurveyBlock');

export function* fetchSurveyBlockSaga(action) {
  const { payload: { blockNumber, chapterId } } = action;
  debug('Saga called', blockNumber);

  try {
    const { prompts } = yield call(API.getSurveyBlock, chapterId, blockNumber);

    debug('Prompts received', prompts);

    yield call(preloadImages, prompts);

    yield put(fetchSurveyBlock.success(prompts));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchSurveyBlock.error(e, action));
  }
}

export default function* fetchSurveyBlockDefaultSaga() {
  yield takeLatest(FETCH_SURVEY_BLOCK.PENDING, fetchSurveyBlockSaga);
}
