import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync
export const SET_CURRENT_COURSE_ID = 'courses/setCurrentCourseId';
export const setCurrentCourseId = createAction(SET_CURRENT_COURSE_ID);

export const CHAPTER_COMPLETED = 'courses/chapterCompleted';
export const chapterCompleted = createAction(CHAPTER_COMPLETED);

// Async
export const FETCH_COURSE = createAsyncTypes('courses/fetchCourse');
export const fetchCourse = createAsyncAction(FETCH_COURSE);

export const FETCH_COURSES = createAsyncTypes('courses/fetchCourses');
export const fetchCourses = createAsyncAction(FETCH_COURSES);

export const HANDLE_COURSE_END = createAsyncTypes('courses/handleCourseEnd');
export const handleCourseEnd = createAsyncAction(HANDLE_COURSE_END);
