/* eslint-disable sort-keys  */

const videoResolutions = {
  V_2K: '2k',
  V_1080P: '1080p',
  V_720P: '720p',
  V_480P: '480p',
  V_360P: '360p',
  V_240P: '240p',
  V_AUTO: 'Auto',
};

export const videoResolutionList = Object.values(videoResolutions);

export default videoResolutions;
