import { fork, all } from 'redux-saga/effects';

import loggerSaga from 'lib/loggerSaga';

import authSagas from './auth/sagas';
import ccaResultsSagas from './ccaResults/sagas';
import chaptersSagas from './chapters/sagas';
import coursesSagas from './courses/sagas';
import fileDownloadSagas from './fileDownload/sagas';
import iatSagas from './iat/sagas';
import informationCardSagas from './informationCard/sagas';
import likertTestSagas from './likertTest/sagas';
import surveySagas from './survey/sagas';
import userDataAnonymizationSagas from './userDataAnonymization/sagas';
import userSagas from './user/sagas';
import videoSagas from './video/sagas';

const sagas = [
  loggerSaga,
  ...authSagas,
  ...ccaResultsSagas,
  ...chaptersSagas,
  ...coursesSagas,
  ...fileDownloadSagas,
  ...iatSagas,
  ...informationCardSagas,
  ...likertTestSagas,
  ...surveySagas,
  ...userDataAnonymizationSagas,
  ...userSagas,
  ...videoSagas,
];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}
