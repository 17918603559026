import Debug from 'debug';
import {
  delay,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import {
  currentCourseIdSelector,
  doesCourseRepeatEphemeralEventsSelector,
} from 'store/courses/selectors';

import { currentChapterEphemeralEventIdsSelector } from '../selectors';
import {
  resetVideoTask,
  videoTaskCompleted,
  VIDEO_TASK_COMPLETED,
} from '../actions';

const debug = Debug('biassync:lms:store:video:sagas:videoTaskCompleted');

export const VIDEO_TASK_RESET_DELAY_MS = 2000;

export function* videoTaskCompletedSaga({ payload }) {
  debug('Saga called', payload);

  const doesCourseRepeatEphemeralEvents = yield select(doesCourseRepeatEphemeralEventsSelector);

  if (!doesCourseRepeatEphemeralEvents) yield put(videoTaskCompleted.success(payload));
  else {
    const savedEphemeralEventIds = yield select(currentChapterEphemeralEventIdsSelector);
    const currentCourseId = yield select(currentCourseIdSelector);

    yield put(videoTaskCompleted.success(payload));
    yield delay(VIDEO_TASK_RESET_DELAY_MS);
    yield put(resetVideoTask({
      chapterId: payload.chapterId,
      courseId: currentCourseId,
      prevEphemeralEventIds: savedEphemeralEventIds,
      taskId: payload.taskId,
    }));
  }
}

export default function* videoTaskCompletedDefaultSaga() {
  yield takeLatest(VIDEO_TASK_COMPLETED.PENDING, videoTaskCompletedSaga);
}
