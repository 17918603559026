import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  chapterType,
  courseSectionType,
  courseType,
} from 'constants/propTypes';

import SectionItem from './SectionItem';
import ChapterItem from './ChapterItem';
import {
  CourseTitle,
  SectionList,
  TableOfContentsWrapper,
} from './style';

export const TableOfContents = ({
  chapters,
  course,
  currentChapterId,
  isChapterLockEnabled,
  isUserDataAnonymized,
  numOfSections,
  onChapterClick,
  sections,
}) => (
  <TableOfContentsWrapper>
    <CourseTitle>{course.title}</CourseTitle>
    <SectionList>
      {course.sections.map((sectionId, index) => {
        const section = sections[sectionId];
        const isLastSection = numOfSections === index;

        return (
          <SectionItem
            key={sectionId}
            sectionNumber={section.orderIndex + 1}
            sectionTitle={section.title}
          >
            {section.chapters.map(chapterId => (
              <li key={chapterId}>
                <ChapterItem
                  chapter={chapters[chapterId]}
                  isChapterDisabled={isChapterLockEnabled}
                  isCurrent={currentChapterId === chapterId}
                  isLastSection={isLastSection}
                  isUserDataAnonymized={isUserDataAnonymized}
                  onClick={() => onChapterClick(chapterId)}
                />
              </li>
            ))}
          </SectionItem>
        );
      })}
    </SectionList>
  </TableOfContentsWrapper>
);

TableOfContents.propTypes = {
  chapters: PropTypes.objectOf(chapterType).isRequired,
  course: courseType.isRequired,
  currentChapterId: PropTypes.string,
  isChapterLockEnabled: PropTypes.bool.isRequired,
  isUserDataAnonymized: PropTypes.bool.isRequired,
  numOfSections: PropTypes.number.isRequired,
  onChapterClick: PropTypes.func.isRequired,
  sections: PropTypes.objectOf(courseSectionType).isRequired,
};

TableOfContents.defaultProps = {
  currentChapterId: null,
};

export default memo(TableOfContents);
