import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { refType, surveyCommentType } from 'constants/propTypes';

import SurveyItemCTA from '../SurveyItemCTA';
import TextInputPrompt from '../PromptItem/TextInputPrompt';
import {
  HintText,
  PromptText,
  Wrapper,
} from './style';

const CommentItem = ({
  comment,
  innerRef,
  isActive,
  isTouchScreen,
  onChange,
  onContinue,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Wrapper>
      <fieldset ref={innerRef}>
        <PromptText>
          {t('courseViewer:survey.commentPrompt')}
          <HintText>{comment.hintText}</HintText>
        </PromptText>
        <TextInputPrompt
          isActive={isActive}
          isTouchScreen={isTouchScreen}
          onChange={onChange}
          prompt={comment}
        />
      </fieldset>
      <SurveyItemCTA
        hasKeystroke={!isTouchScreen}
        isActive={isActive}
        onClick={onContinue}
      />
    </Wrapper>
  );
};

CommentItem.propTypes = {
  comment: surveyCommentType.isRequired,
  innerRef: refType,
  isActive: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CommentItem.defaultProps = {
  innerRef: null,
};

export default CommentItem;
