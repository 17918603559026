import styled from 'styled-components';
import { media } from 'styles';

export const MessageWrapper = styled.div`
  width: 70%;
  max-width: 800px;
  margin: 0 auto;
`;

export const MultiPageMessageWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  ${media.min.baseline`width: 80%;`}
`;
