import Debug from 'debug';
import {
  all,
  call,
  delay,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_CCA_RESULTS,
  fetchCCAResults,
} from '../actions';

const debug = Debug('biassync:lms:store:ccaResults:sagas:fetchCCAResults');

export function* fetchCCAResultsSaga({ payload }) {
  const { chapterId, isFirstTimeView } = payload;
  debug('Saga called', chapterId);

  try {
    const { apiResponse } = yield all({
      apiResponse: call(API.getCCAResults, chapterId),
      delay: delay(isFirstTimeView ? 8000 : 0),
    });

    debug('Successfully fetched CCA results data', apiResponse.results);

    yield put(fetchCCAResults.success(apiResponse.results));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchCCAResults.error(e));
  }
}

export default function* fetchCCAResultsDefaultSaga() {
  yield takeLatest(FETCH_CCA_RESULTS.PENDING, fetchCCAResultsSaga);
}
