import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import Plane from 'types/Plane';
import Logo, { LogoVariant } from 'components/svg/Logo';
import StyledButton from 'components/shared/StyledButton';

import Dimensions from './Dimensions';
import InterpersonalAction from './InterpersonalAction';
import ProfileDescription from './ProfileDescription';
import ProfileHero from './ProfileHero';

import theme from './theme';
import { ResultsData } from './types';
import {
  ButtonContainer,
  Container,
  Description,
  Footer,
  LogoContainer,
  SectionContainer,
  SectionTitle,
} from './style';

type CCAResultsProps = {
  onContinue: () => void;
  results: ResultsData;
  userFullName: string;
};

const CCAResults = ({
  onContinue,
  results,
  userFullName,
}: CCAResultsProps) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  return (
    <ThemeProvider theme={theme}>
      <ProfileHero
        badgeUrl={results.profile.badgeUrl}
        profileName={results.profile.profileName}
        userFullName={userFullName}
      />
      <Container>
        <ProfileDescription
          challenge={results.profile.challenge}
          description={results.profile.description}
          growth={results.profile.growth}
        />
        <Dimensions
          dimensions={results.dimensions}
          profileName={results.profile.profileName}
        />
        <InterpersonalAction content={results.interpersonalAction} />
        <SectionContainer>
          <SectionTitle>{t('courseViewer:ccaResults.keyTakeawaySection.heading')}</SectionTitle>
          <Description>{t('courseViewer:ccaResults.keyTakeawaySection.description')}</Description>
        </SectionContainer>
        <Footer>
          <ButtonContainer>
            <StyledButton variant="brandedBlue" onClick={onContinue}>
              {t('continue')}
            </StyledButton>
          </ButtonContainer>
          <LogoContainer>
            <Logo variant={LogoVariant.Light} orientation={Plane.Horizontal} />
          </LogoContainer>
        </Footer>
      </Container>
    </ThemeProvider>
  );
};

export default CCAResults;
