import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import { skipChapterForAccessibility } from 'store/chapters/actions';

import ConfirmAccessibilitySkipModal from './ConfirmAccessibilitySkipModal';

const mapDispatchToProps = {
  onConfirmSkip: skipChapterForAccessibility,
};

export const ConfirmAccessibilitySkipModalContainer = ({
  onClose,
  onConfirmSkip,
  tracking,
}) => {
  const handleSubmit = () => {
    tracking.trackEvent({ event: 'Confirm Accessibility Skip Button Clicked' });
    onConfirmSkip();
  };

  return (
    <ConfirmAccessibilitySkipModal
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

ConfirmAccessibilitySkipModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirmSkip: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
};

const trackedConfirmAccessibilitySkipModal = track({ module: 'ConfirmAccessibilitySkipModal' }, {
  dispatchOnMount: () => ({ event: 'Confirm Accessibility Skip Modal Shown' }),
})(ConfirmAccessibilitySkipModalContainer);

export default connect(
  null,
  mapDispatchToProps,
)(trackedConfirmAccessibilitySkipModal);
