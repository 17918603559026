export const AnonymizeResults = 'anonymize_results';
export const CompositeSurvey = 'composite_survey';
export const CCAResults = 'cca_results';
export const FileDownload = 'file_download';
export const Forward = 'forward';
export const IAT = 'implicit_association_test';
export const IATTutorial = 'implicit_association_tutorial';
export const InformationCard = 'information_card';
export const LikertTest = 'likert_test';
export const Survey = 'survey';
export const Video = 'video';
