import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  loginWithAuthCodeAnonymous,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:loginWithAuthCodeAnonymous');

export function* loginWithAuthCodeAnonymousSaga({ payload: authCode }) {
  debug('Saga called with auth code anonymous', authCode);

  try {
    const loginResponse = yield call(API.loginWithAuthCodeAnonymous, authCode);

    yield put(loginWithAuthCodeAnonymous.success(loginResponse));
  } catch (e) {
    debug('Saga error', e);
    yield put(loginWithAuthCodeAnonymous.error(e));
  }
}

export default function* loginWithAuthCodeAnonymousDefaultSaga() {
  yield takeLatest(LOGIN_WITH_AUTH_CODE_ANONYMOUS.PENDING, loginWithAuthCodeAnonymousSaga);
}
