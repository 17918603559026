import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Circle,
  SectionItem,
  SectionList,
  SectionStatus,
  Wrapper,
} from './style';

const SectionTracker = ({
  currentBlock,
  totalBlocks,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  return (
    <Wrapper>
      <SectionList>
        {Array.from({ length: totalBlocks }, (_, index) => {
          const sectionNumber = index + 1;
          let status = SectionStatus.DEFAULT;

          if (sectionNumber < currentBlock) status = SectionStatus.COMPLETED;
          else if (sectionNumber === currentBlock) status = SectionStatus.CURRENT;

          return (
            <SectionItem key={sectionNumber}>
              <Circle
                aria-current={status === SectionStatus.CURRENT ? 'step' : undefined}
                aria-label={`${t('courseViewer:survey.section')} ${sectionNumber}`}
                sectionNumber={sectionNumber}
                status={status}
              />
            </SectionItem>
          );
        })}
      </SectionList>
    </Wrapper>
  );
};

SectionTracker.propTypes = {
  currentBlock: PropTypes.number.isRequired,
  totalBlocks: PropTypes.number.isRequired,
};

export default SectionTracker;
