import styled, { css, keyframes } from 'styled-components';

import * as ChapterType from 'constants/chapterTypes';
import { SurveySubTypes } from 'components/shared/Survey/constants';

import TransparentButton from 'styles/components/TransparentButton';
import typeScale from 'styles/functions/typeScale';
import {
  colors,
  media,
  spacing,
  type,
} from 'styles';

export const TOC_TOOLTIP_FADE_OUT_DURATION_MS = 500;
const TOC_ICON_WIDTH = '20px';
const VIDEO_BG_GRADIENT = `
  linear-gradient(
    to bottom,
    rgba(20,23,28,0.9) 0,
    rgba(20,23,28,0.738) 19%,
    rgba(20,23,28,0.541) 34%,
    rgba(20,23,28,0.382) 47%,
    rgba(20,23,28,0.278) 56.5%,
    rgba(20,23,28,0.194) 65%,
    rgba(20,23,28,0.126) 73%,
    rgba(20,23,28,0.075) 80.2%,
    rgba(20,23,28,0.042) 86.1%,
    rgba(20,23,28,0.021) 91%,
    rgba(20,23,28,0.008) 95.2%,
    rgba(20,23,28,0.002) 98.2%,
    rgba(20,23,28,0) 100%
  )
`;
const DARK_BG_GRADIENT = `
  linear-gradient(
    to bottom,
    rgba(33,36,44,1) 0,
    rgba(33,36,44,0.85) 70%,
    rgba(33,36,44,0.45) 85%,
    rgba(33,36,44,0) 100%
  )
`;
const BLUE_BG_GRADIENT = `
  linear-gradient(
    to bottom,
    rgba(35,123,192,1) 0,
    rgba(35,123,192,0.85) 70%,
    rgba(35,123,192,0.45) 85%,
    rgba(35,123,192,0) 100%
  )
`;

const getBackgroundByChapterType = (chapterType, chapterSubType) => {
  switch (chapterType) {
    case ChapterType.LikertTest:
      return BLUE_BG_GRADIENT;
    case ChapterType.Video:
      return VIDEO_BG_GRADIENT;
    case ChapterType.CompositeSurvey:
    case ChapterType.Survey:
      if (chapterSubType === SurveySubTypes.Familiarity
        || chapterSubType === SurveySubTypes.Vignette) return DARK_BG_GRADIENT;
      return BLUE_BG_GRADIENT;
    default:
      return DARK_BG_GRADIENT;
  }
};

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 6;
  padding: 17px;
  transition: all 1s;
  opacity: 0;
  pointer-events: none;
  ${media.print`display: none;`}

  ${({
    chapterSubType,
    chapterType,
    hasBackground,
  }) => hasBackground && css`
    background: ${getBackgroundByChapterType(chapterType, chapterSubType)};
  `}

  ${({ isVisible }) => isVisible && `
    opacity: 1;
    transition: all .1s;
    pointer-events: auto;
  `}
`;

export const TappableArea = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  width: 100%;
  height: 50px;
`;

export const TableOfContentsButtonWrapper = styled.div`
  position: relative;
  float: left;
  display: ${p => (p.isVisible ? 'block' : 'none')};
`;

export const TableOfContentsButton = styled(TransparentButton)`
  margin-top: 6px;
  outline: none;
  padding: 8px;

  &:hover {
    background-color: rgba(35, 147, 215, 0.3);
    border-radius: 9999px;
  }

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 9999px;
    background-color: rgba(35, 147, 215, 0.1);
  }
`;

export const TableOfContentsIcon = styled.img`
  display: block;
  width: ${TOC_ICON_WIDTH};
  height: 15px;
`;

const pointing = keyframes`
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(-5px);
  }
  100% {
    transform: translatex(0px);
  }
`;

export const InfoWrapper = styled.div`
  display: none;
  float: left;
  padding: 8px;
  max-width: calc(100% - 160px);

  ${media.min.mobileLandscape`display: block;`}
`;

export const TOCTooltip = styled.div`
  position: absolute;
  z-index: 1;
  width: 300px;
  top: -50px;
  left: 57px;
  padding-top: 44px;
  transition: opacity ${TOC_TOOLTIP_FADE_OUT_DURATION_MS}ms;
  animation: ${pointing} 1s linear infinite forwards;

  &.toc-tooltip-exit { opacity: 1; }
  &.toc-tooltip-exit-active { opacity: 0; }

  ${media.min.baseline`
    width: 500px;
    top: -44px;
  `} 

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    left: -14px;
    top: 52px;
    width: 37px;
    height: 37px;
    border-radius: 3px;
    background-color: ${colors.yellow.base};
    transform: rotate(45deg);

    ${media.min.baseline`
      left: -9px;
      top: 49px;
      width: 29px;
      height: 29px;
    `}
  }
`;

export const TOCTooltipText = styled.span`
  display: block;
  font-size: 1.2rem;
  padding: 10px 14px;
  border-radius: 8px;
  background-color: ${colors.yellow.base};
  color: ${colors.sierra.base};

  ${media.min.baseline`font-size: 1.4rem;`}
`;

export const ContentWrapper = styled.div`
  margin-left: calc(${TOC_ICON_WIDTH} + 18px);
`;

export const ChapterTitle = styled.div`
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.min.baseline`font-size: 2rem;`}
`;

export const ChapterInfo = styled.div`
  ${typeScale('centi')}
  display: inline-block;
  margin-top: 6px;
  color: ${({ lightText }) => (lightText ? colors.white : colors.cascade.muted)};
  font-weight: ${type.weight.semiBold};

  ${media.min.baseline`${typeScale('uno')}`}
`;

export const ChevronIconWrapper = styled.div`
  display: inline-block;
  width: 7px;
  height: 11px;
  margin: 0 ${spacing.compact};
`;

export const SettingsMenuButton = styled(TransparentButton)`
  outline: none;
  padding: 8px;

  &:hover {
    background-color: rgba(35, 147, 215, 0.3);
    border-radius: 9999px;
  }

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 9999px;
    background-color: rgba(35, 147, 215, 0.1);
  }
`;

export const SettingsIcon = styled.img`
  display: block;
  width: 20px;
  height: 20px;

  ${media.min.baseline`
    width: 23px;
    height: 23px;
  `}
`;

export const SettingsMenuWrapper = styled.div`
  position: absolute;
  top: 42px;
  right: -36.5px;
  width: 300px;
  z-index: 3;

  ${media.min.baseline`
    top: 50px;
    right: -35px;
    width: 335px;
  `}
`;

export const SettingsWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const HomeIcon = styled.img`
  display: block;
  width: 18px;
  height: 20px;

  ${media.min.baseline`
    width: 22px;
    height: 22px;
  `}
`;

export const OverviewPageWrapper = styled.div`
  display: inline-block;
`;

export const OverviewPageButton = styled(TransparentButton)`
  padding: 8px;
  outline: none;

  &:hover {
    background-color: rgba(35, 147, 215, 0.3);
    border-radius: 9999px;
  }

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 9999px;
    background-color: rgba(35, 147, 215, 0.1);
  }

  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const LanguageMenuWrapper = styled.div`
  display: inline-block;
`;

export const MenuWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;
  flex-direction: row;

  & > * { margin-left: 12px; }

  & > *:first-child { margin: 0; }
`;
