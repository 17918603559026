import styled from 'styled-components';

import colors from 'styles/colors';

interface ProgressBarProps {
  readonly percentage: number;
}

// SC recommends using attrs:style to set highly dynamic rules
export const ProgressBar = styled.div.attrs<ProgressBarProps>(({ percentage }) => ({
  style: {
    width: `${percentage * 100}%`,
  },
}))`
  position: absolute;
  height: 100%;
  background-color: ${colors.green.base};
  opacity: 0.5;
  max-width: 100%;
`;
