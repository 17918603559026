import React, { useState } from 'react';
import PropTypes from 'prop-types';

import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';

import * as ToastTypes from 'constants/toastTypes';

import RecoveryEmail from './RecoveryEmail';

export const RecoveryEmailContainer = ({
  didSubmit,
  isLoading,
  isUserRegistrationConflictError,
  onSubmit,
  toggleToast,
  tracking: { trackEvent },
}) => {
  const { t } = useTranslation(['common', 'auth', 'errors']);
  const [loginId, setLoginId] = useState('');

  const handleChange = event => {
    setLoginId(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    trackEvent({ event: 'Send Email Button Clicked' });

    if (loginId) onSubmit(loginId.trim());
    else toggleToast({ message: t('errors:invalidLoginId'), type: ToastTypes.TOAST_ERROR });
  };

  return (
    <RecoveryEmail
      didSubmit={didSubmit}
      isLoading={isLoading}
      isUserRegistrationConflictError={isUserRegistrationConflictError}
      loginId={loginId}
      onChange={handleChange}
      onSubmit={handleSubmit}
      t={t}
    />
  );
};

RecoveryEmailContainer.propTypes = {
  didSubmit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

RecoveryEmailContainer.defaultProps = {
  tracking: null,
};

export default track({
  page: 'Recovery Email',
})(RecoveryEmailContainer);
