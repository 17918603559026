import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as Analytics from 'lib/analytics';
import API from 'services/API';
import preloadImage from 'lib/preloadImage';
import { getImageEventSrc } from 'lib/utils';
import { courseSchema } from 'constants/schemaDefinitions';

import {
  FETCH_COURSE,
  fetchCourse,
} from '../actions';

const debug = Debug('biassync:lms:store:courses:sagas:fetchCourse');

export function* fetchCourseSaga(action) {
  const { payload: courseId } = action;
  debug('Saga called', courseId);

  try {
    const response = yield call(API.getCourse, courseId);

    const { course } = response;

    debug('Course received', course);

    const normalizedCourseData = yield call(normalize, course, courseSchema);
    const { posterImageLink } = course.userMetadata.currentChapterId
      ? normalizedCourseData.entities.chapters[course.userMetadata.currentChapterId]
      : course.sections[0].chapters[0];

    try {
      yield call(preloadImage, posterImageLink);
    } catch (e) {
      const imageSrc = getImageEventSrc(e);
      debug('Image preloading error', imageSrc);
      Analytics.trackEvent('Image Preloading Error', { imageSrc });
    }

    yield put(fetchCourse.success({
      chapters: normalizedCourseData.entities.chapters,
      courseId: normalizedCourseData.result,
      courses: normalizedCourseData.entities.courses,
      sections: normalizedCourseData.entities.sections,
    }));
  } catch (e) {
    debug('API error', e);
    yield put(fetchCourse.error(e));
  }
}

export default function* fetchCourseDefaultSaga() {
  yield takeLatest(FETCH_COURSE.PENDING, fetchCourseSaga);
}
