import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const radioButtonTheme = {
  focus: {
    shadow: colors.yellow.base,
  },
  selected: {
    background: colors.brandedBlue.base,
    borderColor: colors.brandedBlue.base,
  },
  unselected: {
    background: colors.white,
    borderColor: colors.shasta.light,
  },
};

export const DialogContent = styled.div`
  width: 100%;
  max-width: 500px;
  color: ${colors.ridge.base};
  padding: 36px 24px 24px;

  ${media.min.tablet`
    width: 550px;
    max-width: none;
    padding: 48px 32px 32px;
  `}
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  margin: 0;
  text-align: center;

  ${media.min.tablet`font-size: 2.4rem;`}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  ${media.min.tablet`margin-top: 32px;`}
`;

export const OptionsWrapper = styled.div`
  font-size: 1.4rem;
  margin-top: 24px;

  ${media.min.tablet`
    margin-top: 36px;
    font-size: 1.8rem;
  `}
`;

export const OptionWrapper = styled.div`
  &:last-child {
    margin-top: 18px;
  }

  & strong {
    font-weight: ${type.weight.bold};
  }

  ${media.min.tablet`
    &:last-child {
      margin-top: 24px;
    }
  `}
`;

export const DisclaimerContainer = styled.div`
  color: ${colors.cascade.base};
  font-style: italic;
  font-size: 1.0rem;
  margin-top: 24px;

  & > p, & > p > ul {
    margin: 4px 0;
    ${media.min.tablet`margin: 6px 0;`}
  }

  & > p > ul {
    padding-left: 28px;
    ${media.min.tablet`padding-left: 32px;`}
  }

  ${media.min.tablet`
    font-size: 1.2rem;
    margin-top: 32px;
  `}
`;
