import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_LIKERT_TEST,
  fetchLikertTest,
} from '../actions';

const debug = Debug('biassync:lms:store:likertTest:sagas:fetchLikertTest');

export function* fetchLikertTestSaga(action) {
  debug('Saga called');
  const { payload: chapterId } = action;

  try {
    const { test } = yield call(API.getLikertTest, chapterId);

    debug('Likert test received', test);

    yield put(fetchLikertTest.success(test));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchLikertTest.error(e, action));
  }
}

export default function* fetchLikertTestDefaultSaga() {
  yield takeLatest(FETCH_LIKERT_TEST.PENDING, fetchLikertTestSaga);
}
