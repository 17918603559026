import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import * as Analytics from 'lib/analytics';
import API from 'services/API';
import preloadImage from 'lib/preloadImage';
import { getImageEventSrc } from 'lib/utils';

import {
  FETCH_COURSES,
  fetchCourses,
} from '../actions';

const debug = Debug('biassync:lms:store:courses:sagas:fetchCourses');

export function* fetchCoursesSaga(action) {
  debug('Saga called');

  try {
    const { courses } = yield call(API.getCourses);

    debug('Courses received', courses);
    const posterImages = courses.map(course => course.posterImageLink);

    try {
      yield Promise.all(posterImages.map(preloadImage));
    } catch (e) {
      const imageSrc = getImageEventSrc(e);
      debug('Image preloading error', imageSrc);
      Analytics.trackEvent('Image Preloading Error', { imageSrc });
    }

    yield put(fetchCourses.success(courses));
  } catch (e) {
    debug('API error', e);
    yield put(fetchCourses.error(e, action));
  }
}

export default function* fetchCoursesDefaultSaga() {
  yield takeLatest(FETCH_COURSES.PENDING, fetchCoursesSaga);
}
