import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  currentCourseIdSelector,
  currentChapterIdSelector,
} from 'store/courses/selectors';

import { handleChapterChanged } from 'store/chapters/actions';
import {
  SKIP_IAT,
  skipIAT,
} from '../actions';

const debug = Debug('biassync:lms:store:iat:sagas:skipIAT');

export function* skipIATSaga() {
  debug('Saga called');

  try {
    const currentChapterId = yield select(currentChapterIdSelector);

    const { nextChapterId } = yield call(
      API.skipIAT,
      { loId: currentChapterId },
    );

    debug('Successfully skipped chapter', nextChapterId);

    const currentCourseId = yield select(currentCourseIdSelector);
    yield put(handleChapterChanged({ chapterId: nextChapterId, courseId: currentCourseId }));

    yield put(skipIAT.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(skipIAT.error(e));
  }
}

export default function* skipIATDefaultSaga() {
  yield takeLatest(SKIP_IAT.PENDING, skipIATSaga);
}
