import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Label,
  CheckboxWrapper,
} from './style';

const Checkbox = ({
  children,
  id,
  isChecked,
  onChange,
}) => (
  <CheckboxWrapper>
    <Input
      checked={isChecked}
      id={id}
      onChange={onChange}
      type="checkbox"
    />
    <Label htmlFor={id}>
      {children}
    </Label>
  </CheckboxWrapper>
);

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
