import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const FlowFormTextItemWrapper = styled.div`
  margin: 0 auto;
  color: ${colors.white};

  opacity: ${({ isActive }) => (isActive ? '1' : '0.2')};
  transition: opacity 0.5s ease;
`;

export const HeaderText = styled.div`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  ${({ centerAlign }) => centerAlign && 'text-align: center;'}

  ${media.min.tablet`font-size: 2.4rem;`}
  ${media.min.desktop`font-size: 2.8rem;`}
`;

export const BodyText = styled.p`
  font-size: 1.6rem;

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const CTAWrapper = styled.div`
  text-align: center;
  margin-top: 44px;
`;

export const CTAKeystrokeText = styled.span`
  margin-left: 15px;
  font-size: 1.6rem;
  ${media.min.desktop`font-size: 2rem;`}
`;

export const CTAKeystroke = styled.span`
  font-weight: ${type.weight.bold};
  text-transform: uppercase;
`;
