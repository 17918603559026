import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const KeyMappingSummaryWrapper = styled.div`
  & + & { margin-top: 14px; }

  ${media.twoDimMin.tablet`
    display: inline-block;
    & + & { margin-left: 32px; }
    width: 290px;
  `}

  ${media.twoDimMin.desktop`
    & + & { margin-left: 75px; }
    width: 340px;
  `}

  ${media.twoDimMin.max`
    & + & { margin-left: 100px; }
    width: 400px;
  `}
`;

export const KeyMappingSummaries = styled.div`
  ${media.twoDimMin.desktop`position: relative;`}
`;

export const ProgressIndicatorWrapper = styled.div`
  position: absolute;
  right: 50%;
  transform: translate(50%, 50%);
  bottom: 36px;

  ${media.twoDimMin.tablet`
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50px;
    bottom: 120px;
  `}
  ${media.twoDimMin.baseline`bottom: auto;`}
  ${media.twoDimMin.desktop`left: -100px;`}
  ${media.twoDimMin.max`left: -130px;`}
`;

// IATTutorialKeyMappingItem

export const OptionsWrapper = styled.div`
  ${media.twoDimMin.tablet`
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '75%')};
  `}
`;

export const Subtext = styled.span`
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  text-transform: uppercase;
  color: ${colors.yellow.base};
  margin: 0 16px;
  letter-spacing: .8px;

  ${media.twoDimMin.tablet`
    margin-top: 16px;
    font-size: 1.4rem;
  `}
  ${media.twoDimMin.desktop`
    margin-top: 20px;
    font-size: 1.6rem;
  `}
  ${media.twoDimMin.max`margin-top: 24px;`}
`;

export const CategoryName = styled.span`
  font-size: 2rem;
  font-weight: ${type.weight.bold};

  ${media.twoDimMin.tablet`
    margin-top: 16px;
    font-size: 2.6rem;
  `};
  ${media.twoDimMin.desktop`
    margin-top: 20px;
    font-size: 2.8rem;
  `}

  ${media.twoDimMin.max`
    margin-top: 24px;
    font-size: 3.2rem;
  `}
`;
