export enum Profile {
  Adapter = 'adapter',
  Collaborator = 'collaborator',
  Explorer = 'explorer',
  Guard = 'guard',
  Maintainer = 'maintainer',
}

export enum ScoreCategory {
  Low = 'low',
  Average = 'average',
  High = 'high',
}

export enum DimensionName {
  SelfReflection = 'self-reflection',
  SelfAwareness = 'self-awareness',
  Egolessness = 'egolessness',
}

export interface Recommendation {
  title: string;
  body: string;
}

export interface DimensionResult {
  scoreCategory: ScoreCategory;
  resultText: string;
  recommendations: Recommendation[];
}

export interface Dimension {
  dimensionName: DimensionName;
  description: string;
  result: DimensionResult;
}

export interface InterpersonalAction {
  description: string;
  result: {
    scoreCategory: ScoreCategory;
    resultText: string;
  };
}

interface ProfileData {
  badgeUrl: string;
  challenge: string[];
  description: string;
  growth: string[];
  profileName: Profile;
}

export interface ResultsData {
  profile: ProfileData;
  dimensions: Dimension[];
  interpersonalAction: InterpersonalAction;
}
