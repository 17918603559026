import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const VideoIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg id="videoIcon" aria-labelledby="videoIconTitle" width="100%" height="100%" viewBox="0 0 27 17" role="img" focusable="false">
      <title id="videoIconTitle">{t('tableOfContents.chapterType.video')}</title>
      <path
        fill={fill}
        fillRule="nonzero"
        d="M26.029 3.526v9.964a1.627 1.627 0 0 1-2.25 1.505l-4.79-1.96v2.44c0 .842-.683 1.525-1.525 1.525H1.525A1.525 1.525 0 0 1 0 15.491V1.525C0 .683.683 0 1.525 0h15.939c.842 0 1.525.683 1.525 1.525v2.44l4.778-1.964a1.627 1.627 0 0 1 2.262 1.525z"
      />
    </svg>
  );
};

VideoIcon.propTypes = {
  fill: PropTypes.string,
};

VideoIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default VideoIcon;
