import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import checkmarkIcon from 'assets/green-check.svg';

import {
  CheckmarkIcon,
  EmailText,
  HeaderText,
  MagicLinkGeneratedWrapper,
  MessageText,
} from './style';

const MagicLinkGenerated = ({ email }) => {
  const { t } = useTranslation('auth');

  return (
    <MagicLinkGeneratedWrapper>
      <CheckmarkIcon alt="" src={checkmarkIcon} />
      <HeaderText>{t('auth:magicLinkLogin.generated.title')}</HeaderText>
      <Trans i18nKey="auth:magicLinkLogin.generated.body">
        <MessageText>
          We sent an email to <EmailText>{{ email }}</EmailText>.
        </MessageText>
        <MessageText>
          If this email address has an account, you&apos;ll find a magic link
          that will sign you into that workspace.
        </MessageText>
      </Trans>
    </MagicLinkGeneratedWrapper>
  );
};

MagicLinkGenerated.propTypes = {
  email: PropTypes.string.isRequired,
};

export default MagicLinkGenerated;
