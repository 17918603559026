// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-toast-notifications__toast__content {
  width: 100% !important;
}

.react-toast-notifications__toast {
  border-radius: 8px !important;
  width: 400px !important;
}

.react-toast-notifications__toast
.react-toast-notifications__toast__icon-wrapper {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 40px;
}

.react-toast-notifications__toast__dismiss-button {
  color:#fff !important;
  opacity: 1 !important;
}

.react-toast-notifications__toast__icon-wrapper {
  display: none;
}

/** Error **/
.react-toast-notifications__toast--error {
  color:#fff !important;
  background-color:#f92b57 !important;
}

/** Warning **/
.react-toast-notifications__toast--warning {
  color: #21242c !important;
  background-color: #ffd285 !important;
}

.react-toast-notifications__toast--warning .react-toast-notifications__toast__dismiss-button {
  color:#21242c !important;
  opacity: 1 !important;
}

/** Success **/
.react-toast-notifications__toast--success {
  color: #fff !important;
  background-color: #5dca9c !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/ToastManager/react-toast-style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,uBAAuB;AACzB;;AAEA;;EAEE,8BAA8B;EAC9B,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA,YAAY;AACZ;EACE,qBAAqB;EACrB,mCAAmC;AACrC;;AAEA,cAAc;AACd;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA,cAAc;AACd;EACE,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".react-toast-notifications__toast__content {\n  width: 100% !important;\n}\n\n.react-toast-notifications__toast {\n  border-radius: 8px !important;\n  width: 400px !important;\n}\n\n.react-toast-notifications__toast\n.react-toast-notifications__toast__icon-wrapper {\n  border-bottom-left-radius: 8px;\n  border-top-left-radius: 8px;\n  width: 40px;\n}\n\n.react-toast-notifications__toast__dismiss-button {\n  color:#fff !important;\n  opacity: 1 !important;\n}\n\n.react-toast-notifications__toast__icon-wrapper {\n  display: none;\n}\n\n/** Error **/\n.react-toast-notifications__toast--error {\n  color:#fff !important;\n  background-color:#f92b57 !important;\n}\n\n/** Warning **/\n.react-toast-notifications__toast--warning {\n  color: #21242c !important;\n  background-color: #ffd285 !important;\n}\n\n.react-toast-notifications__toast--warning .react-toast-notifications__toast__dismiss-button {\n  color:#21242c !important;\n  opacity: 1 !important;\n}\n\n/** Success **/\n.react-toast-notifications__toast--success {\n  color: #fff !important;\n  background-color: #5dca9c !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
