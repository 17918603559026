import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import PageTitle from 'components/shared/PageTitle';

import {
  CTAWrapper,
  Form,
  PromptText,
  TextFieldWrapper,
  Title,
} from '../style';

const ResetPassword = ({
  didResetPassword,
  doPasswordsMatch,
  isLoading,
  onChange,
  onPasswordFieldBlur,
  onSubmit,
  t,
  values,
}) => (
  <>
    <PageTitle subPageTitle={t('auth:resetPassword.title')} />
    {didResetPassword ? (
      <>
        <Title>{t('auth:resetPassword.submitted.title')}</Title>
        <PromptText>{t('auth:resetPassword.submitted.body')}</PromptText>
      </>
    ) : (
      <>
        <Title>{t('auth:resetPassword.title')}</Title>
        <Form onSubmit={onSubmit}>
          <TextFieldWrapper>
            <TextField
              labelText={t('auth:newPasswordLabel')}
              name="password"
              onBlur={onPasswordFieldBlur}
              onChange={onChange}
              tid="passwordFieldId"
              tooltipText={t('auth:passwordTooltip')}
              type="password"
              value={values.password || ''}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              hasExternalError={!doPasswordsMatch}
              labelText={t('auth:confirmPasswordLabel')}
              name="confirmPassword"
              onBlur={onPasswordFieldBlur}
              onChange={onChange}
              tid="confirmPasswordFieldId"
              type="password"
              value={values.confirmPassword || ''}
            />
          </TextFieldWrapper>
          <CTAWrapper>
            <StyledButton tid="submitButtonId" full loading={isLoading} onClick={onSubmit}>
              {t('auth:resetPassword.cta')}
            </StyledButton>
          </CTAWrapper>
        </Form>
      </>
    )}
  </>
);

ResetPassword.propTypes = {
  didResetPassword: PropTypes.bool.isRequired,
  doPasswordsMatch: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onPasswordFieldBlur: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ResetPassword;
