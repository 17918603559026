import styled from 'styled-components';

import { media, type } from 'styles';

import theme from '../theme';

export const SubSectionContainer = styled.div`
  margin-top: 60px;
  padding-bottom: 40px;
  display: flex;
  width: 100%;
`;

export const GraphContainer = styled.div`
  margin: 40px 0 0 32px;
  flex: 0 0 auto;

  ${media.min.tablet`margin: 40px 0 0 38px;`}
  ${media.min.baseline`margin: 40px 0 0 42px;`}
  ${media.min.desktop`margin: 40px 0 0 46px;`}
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 65px;

  ${media.min.tablet`margin-left: 75px;`}
  ${media.min.baseline`margin-left: 85px;`}
  ${media.min.desktop`margin-left: 100px;`}
`;

export const ScoreContainer = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.min.tablet`margin: 60px 0;`}
  ${media.min.baseline`margin: 70px 0;`}
  ${media.min.desktop`margin: 80px 0;`}
`;

export const Divider = styled.div`
  width: 75px;

  border: solid 2px ${theme.accent.web};
  ${media.print`border: solid 2px ${theme.accent.print};`}
`;

export const ScoreLabel = styled.h4`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  font-style: italic;
  margin: 30px 0;
  text-align: center;

  color: ${theme.primary.web};
  ${media.print`color: ${theme.primary.print};`}

  ${media.min.tablet`font-size: 2.4rem;`}
  ${media.min.baseline`
    font-size: 2.4rem;
    margin: 40px 0;
  `}
  ${media.min.desktop`
    font-size: 2.8rem;
    margin: 50px 60px;
  `}
  ${media.min.max`
    font-size: 2.8rem;
    margin: 50px 100px;
  `}
`;

export const ScoreLabelColored = styled.span`
  display: inline-block;

  color: ${theme.accent.web};
  ${media.print`color: ${theme.accent.print};`}
`;
