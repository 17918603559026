import { combineReducers } from 'redux';

import chapters from './chapters/reducer';
import courses from './courses/reducer';
import sections from './sections/reducer';
import videoEvents from './videoEvents/reducer';

export default combineReducers({
  chapters,
  courses,
  sections,
  videoEvents,
});
