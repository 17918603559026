const CCAChallengeIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 100 125">
    <g>
      <path fill="#5aa99e" d="M5.6,55.9c2.5,1.3,5.5,0.5,7.1-1.6c0,3.2,1.7,6.3,4.6,7.9L11,87.9h3.2l6.4-26.2c0.2-0.8-0.2-1.6-1-1.8     c-2.7-1-4.3-3.9-3.6-6.8l1.8-7.9c0.2-0.7-0.1-1.4-0.7-1.7c-0.6-0.3-1.3-0.3-1.8,0.2c-0.4,0.4-0.8,0.8-1.1,1.4l-3.7,7     c-0.7,1.2-2.2,1.7-3.5,1.1c-0.6-0.3-1.1-0.9-1.3-1.5c-0.2-0.7-0.1-1.4,0.2-2l3.7-7c0.9-1.7,2.3-3.1,3.9-4.1     c8.3-4.9,9.3-4.8,10.1-4.8l5.7,0.4c1.4,0.1,2.5,0.5,8.2,8.8c0.4,0.5,1,0.9,1.6,0.9l7,1c1.4,0.2,2.4,1.6,2.2,3     c-0.1,0.7-0.4,1.3-1,1.7C46.6,50,46,50.2,45.3,50l-7-1c-1.9-0.3-3.7-1.4-4.8-2.9c-0.4-0.5-1-0.7-1.6-0.6     c-0.6,0.1-1.1,0.6-1.2,1.1l-1.9,7.6c-0.2,0.8,0.3,1.7,1.1,1.9l3.4,0.9c2.9,0.8,5,3.2,5.5,6.2L41,80.1h3.1l-2.3-17.2     c-0.6-4.2-3.6-7.6-7.7-8.7l-1.9-0.5l0.9-3.5c1.4,1,3,1.7,4.8,2l7,1.1c1.5,0.2,3-0.2,4.2-1c1.2-0.9,2-2.2,2.3-3.7     c0.5-3.1-1.7-6-4.8-6.5l-6.7-1c-3.5-5.1-5.5-7.6-7.2-8.8c0.3-0.2,0.7-0.4,1-0.7c2.1-1.7,3.4-4,3.6-6.7c0.6-5.4-3.4-10.3-8.8-10.9     c-5.4-0.6-10.3,3.4-10.9,8.8c-0.4,3.3,1,6.5,3.3,8.5c-1.7,0.6-4.2,1.9-9,4.7C9.7,37.2,8,39,6.8,41.2l-3.7,7     c-0.7,1.4-0.8,2.9-0.4,4.3C3.2,54,4.2,55.2,5.6,55.9z M20.6,23c0.4-3.5,3.4-6.1,6.8-6.1c0.2,0,0.5,0,0.7,0c3.7,0.4,6.4,3.8,6,7.5     c-0.2,1.8-1.1,3.4-2.5,4.6c-1.4,1.1-3.2,1.7-5,1.5C22.9,30.1,20.2,26.7,20.6,23z" />
      <path fill="#5aa99e" d="M31.5,63.1l-3-0.8c-0.4-0.1-0.8,0-1.2,0.2c-0.4,0.2-0.6,0.6-0.7,1l-6,24.5h3.2l5.5-22.2l0.5,0.1l1.9,14.3     c0.1,0,0.1,0,0.2,0h2.9l-2.1-15.7C32.5,63.8,32.1,63.3,31.5,63.1z" />
      <path fill="#5aa99e" d="M91.3,20.1l6-8.1c0.6-0.8,0-1.9-1-1.9h-18V7.4c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v60.1h-7.4     c-0.9,0-1.6,0.7-1.6,1.6v6.3H49.8c-0.9,0-1.6,0.7-1.6,1.6v6.3H31.9c-0.9,0-1.6,0.7-1.6,1.6V91H4.8c-0.9,0-1.6,0.7-1.6,1.6     c0,0.9,0.7,1.6,1.6,1.6h27.1c0.9,0,1.6-0.7,1.6-1.6v-6.3h16.4c0.9,0,1.6-0.7,1.6-1.6v-6.3h16.4c0.9,0,1.6-0.7,1.6-1.6v-6.3h16.4     c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-7.4V30.1h18c1,0,1.6-1.1,1-1.9L91.3,20.1z M78.3,27V13.2h14.3L88,19.4     c-0.3,0.4-0.3,1,0,1.5l4.6,6.2H78.3z" />
    </g>
  </svg>
);

export default CCAChallengeIcon;
