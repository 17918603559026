import { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import FocusTrap from 'components/shared/FocusTrap';

import SlideOutPanel from './SlideOutPanel';
import { Overlay } from './style';

const PANEL_TRANSITION_DURATION_MS = 400;

const SlideOutPanelContainer = ({
  children,
  direction,
  hasOverlay,
  isOpen,
  onClose,
}) => {
  const panelRef = useRef();

  return (
    <>
      <FocusTrap disabled={!isOpen}>
        <CSSTransition
          classNames="slide-out-panel"
          in={isOpen}
          timeout={PANEL_TRANSITION_DURATION_MS}
          unmountOnExit
        >
          <SlideOutPanel panelRef={panelRef} direction={direction} onClose={onClose}>
            {children}
          </SlideOutPanel>
        </CSSTransition>
      </FocusTrap>
      {hasOverlay && isOpen && <Overlay data-testid="slide-out-overlay" />}
    </>
  );
};

SlideOutPanelContainer.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  hasOverlay: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SlideOutPanelContainer.defaultProps = {
  hasOverlay: false,
};

export default SlideOutPanelContainer;
