import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  DELETE_USER,
  deleteUser,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:deleteUser');

export function* deleteUserSaga() {
  debug('Saga called');

  try {
    yield call(API.deleteUser);

    debug('User successfully deleted');

    yield put(deleteUser.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(deleteUser.error(e));
  }
}

export default function* deleteUserDefaultSaga() {
  yield takeLatest(DELETE_USER.PENDING, deleteUserSaga);
}
