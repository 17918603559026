import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import checkmarkIcon from 'assets/big-green-check.svg';

import { SuccessIconWrapper, SuccessText } from './style';

export const IATSuccessView = ({
  correctText,
  isImageStimulus,
  isMobile,
}) => {
  const { t } = useTranslation('courseViewer');
  const stimulusText = isImageStimulus
    ? t('courseViewer:iat.success.image')
    : t('courseViewer:iat.success.text');

  return (
    <>
      <SuccessIconWrapper>
        <img src={checkmarkIcon} alt="" />
      </SuccessIconWrapper>
      <SuccessText isImageStimulus={isImageStimulus} isMobile={isMobile}>
        {t('courseViewer:iat.success.message', { correctText, stimulusText })}
      </SuccessText>
    </>
  );
};

IATSuccessView.propTypes = {
  correctText: PropTypes.string.isRequired,
  isImageStimulus: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default IATSuccessView;
