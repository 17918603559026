import { colors } from 'styles';

export default {
  control: provided => ({
    ...provided,
    border: 'none',
  }),
  option: provided => ({
    ...provided,
    color: colors.sierra.base,
  }),
  placeholder: provided => ({
    ...provided,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  }),
  singleValue: provided => ({
    ...provided,
    padding: '0px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  }),
  valueContainer: provided => ({
    ...provided,
    background: colors.white,
    border: 'none',
    borderRadius: '8px',
    color: colors.sierra.base,
    height: '60px',
    padding: '16px 20px',
    width: '100%',
  }),
};
