import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 15, 30, 0.6);
  height: 100%;
  width: 100%;
  ${({ shouldHideOnPrint }) => shouldHideOnPrint && media.print`display: none;`}
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  background-color: ${colors.white};
  border-radius: 20px;
  box-shadow: 0 3px 12px rgba(0,0,0,0.1), 0 3px 12px rgba(0,0,0,0.1);
  margin: 16px 8px;
  max-height: calc(100% - 32px);
  overflow-y: auto;

  ${media.min.tablet`
    margin: 16px 14px;
  `}
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 18px;
  height: 25px;
  font-size: 3.2rem;
  font-weight: ${type.weight.bold};
  line-height: .8;
  color: ${colors.ridge.light};
  outline: none;

  &.focus-visible {
    color: ${colors.blue.light};
    border-bottom: 2px solid ${colors.blue.light};
  }
`;
