import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';

import {
  chapterType,
  deviceTypeType,
  errorType,
  surveyPromptType,
} from 'constants/propTypes';
import { MOBILE } from 'constants/deviceTypes';

import { usePrevious } from 'lib/customHooks';
import * as surveyActions from 'store/survey/actions';

import { isUserDataAnonymizedSelector } from 'store/courses/selectors';
import { uaDeviceTypeSelector } from 'store/userAgent/selectors';
import {
  isLoadingSurveySelector,
  isPostingSurveyResponsesSelector,
  surveyErrorSelector,
  surveyPromptsSelector,
  totalBlockSelector,
  totalItemsSelector,
  totalPrevItemsSelector,
} from 'store/survey/selectors';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';

import SurveyChapter from './SurveyChapter';

const mapStateToProps = state => ({
  deviceType: uaDeviceTypeSelector(state),
  error: surveyErrorSelector(state),
  isLoading: isLoadingSurveySelector(state),
  isPostingResponses: isPostingSurveyResponsesSelector(state),
  isUserDataAnonymized: isUserDataAnonymizedSelector(state),
  surveyPrompts: surveyPromptsSelector(state),
  totalBlocks: totalBlockSelector(state),
  totalItems: totalItemsSelector(state),
  totalPrevItems: totalPrevItemsSelector(state),
});

const mapDispatchToProps = {
  fetchSurvey: surveyActions.fetchSurvey,
  resetSurveyStore: surveyActions.resetSurveyStore,
  retryError: action => action,
  sendBlockResults: surveyActions.sendSurveyBlockResults,
};

export const SurveyChapterContainer = ({
  chapter,
  deviceType,
  error,
  fetchSurvey,
  goToNextChapter,
  isLoading,
  isPostingResponses,
  isUserDataAnonymized,
  resetSurveyStore,
  retryError,
  sendBlockResults,
  surveyPrompts,
  totalBlocks,
  totalItems,
  totalPrevItems,
}) => {
  const { block, isCompleted = false } = chapter.userMetadata;
  const chapterWrapperRef = useRef(null);

  const initializeChapter = () => {
    resetSurveyStore();

    if (!isUserDataAnonymized && !isCompleted) {
      fetchSurvey({ blockNumber: block || 1, chapterId: chapter.id });
    }
  };

  useEffect(() => {
    initializeChapter();
  }, []);

  const prevIsCompleted = usePrevious(isCompleted);
  useEffect(() => {
    if (!prevIsCompleted && isCompleted) {
      if (deviceType === MOBILE) window.scrollTo(0, 0);
      else chapterWrapperRef.current.scrollTo(0, 0);
    }
  }, [isCompleted]);

  const prevChapterId = usePrevious(chapter.id);
  useEffect(() => {
    if (prevChapterId && chapter.id !== prevChapterId) {
      initializeChapter();
    }
  }, [chapter]);

  const handleRetryError = () => {
    retryError(error.retryAction);
  };

  const handleBlockCompleted = ({ blockResponses, totalItemsCount }) => {
    sendBlockResults({
      blockNumber: block || 1,
      blockResults: blockResponses,
      previousItemsCount: totalItemsCount,
    });
  };

  return (
    <DeviceOrientationObserver>
      <SurveyChapter
        chapterTitle={chapter.title}
        chapterWrapperRef={chapterWrapperRef}
        currentBlock={block || 1}
        deviceType={deviceType}
        error={error}
        isCompleted={isCompleted}
        isLoading={isLoading}
        isPostingResponses={isPostingResponses}
        isUserDataAnonymized={isUserDataAnonymized}
        onBlockCompleted={handleBlockCompleted}
        onContinue={goToNextChapter}
        onRetryError={handleRetryError}
        surveyPrompts={surveyPrompts}
        surveySubType={chapter.subType}
        totalBlocks={totalBlocks}
        totalItems={totalItems}
        totalPrevItems={totalPrevItems}
      />
    </DeviceOrientationObserver>
  );
};

SurveyChapterContainer.propTypes = {
  chapter: chapterType.isRequired,
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  fetchSurvey: PropTypes.func.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  isUserDataAnonymized: PropTypes.bool.isRequired,
  resetSurveyStore: PropTypes.func.isRequired,
  retryError: PropTypes.func.isRequired,
  sendBlockResults: PropTypes.func.isRequired,
  surveyPrompts: PropTypes.arrayOf(surveyPromptType),
  totalBlocks: PropTypes.number,
  totalItems: PropTypes.number,
  totalPrevItems: PropTypes.number,
};

SurveyChapterContainer.defaultProps = {
  error: null,
  surveyPrompts: null,
  totalBlocks: null,
  totalItems: null,
  totalPrevItems: null,
};

const trackedSurveyContainer = track({})(SurveyChapterContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedSurveyContainer);
