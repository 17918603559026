import { useSelector } from 'react-redux';

import { surveyAnswersSelector } from 'store/survey/selectors';

import Completion from './Completion';

type CompletionContainerProps = {
  onContinue: () => void;
  surveyTitle: string;
};

const CompletionContainer = ({
  onContinue,
  surveyTitle,
}: CompletionContainerProps) => {
  const answers = useSelector(surveyAnswersSelector);

  return (
    <Completion
      answers={answers}
      onContinue={onContinue}
      surveyTitle={surveyTitle}
    />
  );
};

export default CompletionContainer;
