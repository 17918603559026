import styled from 'styled-components';

import { media } from 'styles';

import theme from '../theme';

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  ${media.min.baseline`
    margin-top: 80px;
    flex-direction: row;
  `}
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  &:first-child { margin-bottom: 50px; }

  ${media.min.baseline`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;  
    flex: 1;
    padding: 0 16px;

    &:first-child { margin-bottom: 0; }
  `}
  ${media.min.desktop`padding: 0 24px;`}
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 40px;

  ${media.min.baseline`
    margin-left: 0;
    align-items: center;
  `}
`;

export const IconContainer = styled.div`
  width: 120px;
  height: 165px;

  ${media.min.baseline`
    width: 140px;
    height: 185px;
  `}
  ${media.min.desktop`
    width: 160px;
    height: 205px;
  `}
  ${media.min.max`
    width: 180px;
    height: 225px;
  `}

  & path {
    fill: ${theme.accent.web};
    ${media.print`fill: ${theme.accent.print};`}
  }
`;

export const BulletList = styled.ul`
  list-style: none;
  padding: 0 0 0 26px;
  max-width: 500px;
  margin: 0;

  ${media.min.baseline`padding: 0 16px;`}
  ${media.min.desktop`padding: 0 20px;`}
  ${media.min.max`padding: 0 18px;`}
`;

export const BulletPoint = styled.li`
  font-size: 1.4rem;
  margin-bottom: 10px;
  line-height: 1.6;

  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}

  &::before {
    content: '•';
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    color: ${theme.accent.web};
    ${media.print`color: ${theme.accent.print};`}
  }
`;

