import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const InformationCardIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg id="informationCardIcon" aria-labelledby="informationCardIconTitle" width="100%" height="100%" role="img" focusable="false" viewBox="0 0 30 30">
      <title id="informationCardIconTitle">{t('tableOfContents.chapterType.informationCard')}</title>
      <g fill={fill} data-name="Layer 19">
        <path d="M27,3H5A3,3,0,0,0,2,6V8A1,1,0,0,0,3,9V20a3,3,0,0,0,3,3h8.38l-3.27,6.55a1,1,0,0,0,.44,1.34A.93.93,0,0,0,12,31a1,1,0,0,0,.89-.55L16,24.24l3.11,6.21A1,1,0,0,0,20,31a.93.93,0,0,0,.45-.11,1,1,0,0,0,.44-1.34L17.62,23H26a3,3,0,0,0,3-3V9a1,1,0,0,0,1-1V6A3,3,0,0,0,27,3Zm0,17a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9H27ZM28,7H4V6A1,1,0,0,1,5,5H27a1,1,0,0,1,1,1ZM10,13a1,1,0,0,1,1-1H21a1,1,0,0,1,0,2H11A1,1,0,0,1,10,13ZM8,17a1,1,0,0,1,1-1H23a1,1,0,0,1,0,2H9A1,1,0,0,1,8,17Z" />
      </g>
    </svg>
  );
};

InformationCardIcon.propTypes = {
  fill: PropTypes.string,
};

InformationCardIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default InformationCardIcon;
