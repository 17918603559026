import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import authLockSVG from 'assets/auth-lock.svg';
import StyledButton from 'components/shared/StyledButton';
import StyledRouterLink from 'styles/components/StyledRouterLink';
import {
  LockIcon,
  PromptText,
} from '../style';
import {
  CTAWrapper,
  LegalText,
  HeaderText,
} from './style';

const SSOLogin = ({
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation('auth');

  return (
    <>
      <LockIcon src={authLockSVG} alt="" />
      <HeaderText>{t('auth:ssoLogin.title')}</HeaderText>
      <PromptText>{t('auth:ssoLogin.prompt')}</PromptText>
      <CTAWrapper>
        <StyledButton tid="submitButtonId" full onClick={onSubmit} loading={isLoading}>
          {t('auth:ssoLogin.cta')}
        </StyledButton>
      </CTAWrapper>
      <LegalText>
        <Trans i18nKey="auth:legalText">
          By continuing, you agree to BiasSync&apos;s <StyledRouterLink data-testid="privacyPolicyLinkId" target="_blank" to="/privacy">Privacy Policy</StyledRouterLink>
        </Trans>
      </LegalText>
    </>
  );
};

SSOLogin.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SSOLogin;
