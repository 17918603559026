import colors from 'styles/colors';

export const textFieldTheme = {
  container: {
    background: colors.ridge.base,
    border: {
      default: 'transparent',
    },
  },
  input: {
    color: {
      default: colors.yellow.background,
    },
  },
  label: {
    color: {
      default: colors.cascade.muted,
    },
  },
};

export const radioButtonTheme = {
  focus: {
    shadow: colors.blue.base,
  },
  selected: {
    background: colors.white,
    borderColor: colors.white,
  },
  unselected: {
    background: 'transparent',
    borderColor: colors.yellow.background,
  },
};
