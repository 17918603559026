import React, { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import AuthCodeLogin from './AuthCodeLogin';

@track({ page: 'Auth Code Login' })
class AuthCodeLoginContainer extends Component {
  state = {
    authCode: '',
  };

  handleChange = event => {
    const { value } = event.target;

    this.setState(state => ({
      ...state,
      authCode: value,
    }));
  };

  @track({ event: 'Login Button Clicked' })
  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.authCode);
  };

  render() {
    return (
      <AuthCodeLogin
        authCode={this.state.authCode}
        isLoading={this.props.isLoading}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

AuthCodeLoginContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AuthCodeLoginContainer;
