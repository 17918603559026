import styled, { css } from 'styled-components';
import { colors, media, type } from 'styles';
import checkmarkIcon from 'assets/green-check.svg';
import TransparentButton from 'styles/components/TransparentButton';

export const TextInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  position: relative;
  min-width: 300px;
`;

export const TextInput = styled.input`
  width: calc(100% - 65px);
  color: ${p => p.theme.chooseOne.otherOption.textfield.color};
  font-size: 1.4rem;
  border: none;
  outline: none;
  min-height: 40px;
  z-index:  ${p => (p.isValid ? '2' : '1')};

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const TextInputPlaceholder = styled.span`
  color: ${p => p.theme.chooseOne.otherOption.textfield.placeholder.color};
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  pointer-events: none;
  left: 0;
  z-index: 1;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1.4rem;
  font-weight: ${type.weight.regular};

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const SubmitButton = styled(TransparentButton)`
  position: absolute;
  right: 8px;
  width: 54px;
  height: 44px;
  background-color: ${p => p.theme.chooseOne.otherOption.textfield.button.background};
  border-radius: 6px;

  ${media.min.tablet`
    width: 58px;
    height: 48px;
    border-radius: 8px;
  `}

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 4.5px solid ${p => p.theme.chooseOne.otherOption.textfield.button.checkmark.color};
    border-right: 4.5px solid ${p => p.theme.chooseOne.otherOption.textfield.button.checkmark.color};
  }

  &.focus-visible {
    box-shadow: inset ${p => p.theme.chooseOne.otherOption.textfield.button.checkmark.focused.color} 0px 0px 0px 2px;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const ChooseOneLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  padding: 14px 44px 14px 70px;
  min-height: 60px;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
  user-select: ${p => (p.isDisabled ? 'none' : 'auto')};
  border-radius: 8px;

  ${Input}.focus-visible + & {
    box-shadow: ${p => p.theme.chooseOne.focused.color} 0 0 0 3px;
  }

  ${p => !p.isTextInputEnabled && css`
    &:before {
      content: '';
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: ${p.isSelected ? p.theme.chooseOne.selected.outerCircle.background : p.theme.chooseOne.unselected.outerCircle.background};
    
      margin-right: 14px;
      ${media.min.mobileLandscape`margin-right: 16px;`}
      ${media.min.baseline`margin-right: 22px;`}
    }

    ${p.isSelected && `
      background: ${p.theme.chooseOne.selected.background};
      color: ${p.theme.chooseOne.selected.color};

      &:after {
        position: absolute;
        content: '';
        left: 24px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: ${p.theme.chooseOne.selected.innerCircle.background};
      }
    `}
  `}
`;

export const CheckboxesLabel = styled.label`
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
  user-select: ${p => (p.isDisabled ? 'none' : 'auto')};
  display: flex;
  align-items: center;
  padding: 10px 44px 10px 10px;

  ${Input}.focus-visible + & {
    box-shadow: ${p => p.theme.chooseOne.focused.color} 0 0 0 3px;
    border-radius: 8px;
  }

  ${p => !p.isTextInputEnabled && css`
    &:before {
      content: ${`'${p.keyShortcut}'`};
      width: 40px;
      height: 40px;
      border-radius: 6px;
      font-size: 1.8rem;
      font-weight: ${type.weight.bold};
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.white};
      background-color: ${p.isSelected ? p.theme.chooseOne.selected.innerCircle.background : p.theme.chooseOne.unselected.outerCircle.background};

      margin-right: 14px;
      ${media.min.mobileLandscape`margin-right: 16px;`}
      ${media.min.baseline`margin-right: 22px;`}
    }

    ${p.isSelected && css`
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        background: url(${checkmarkIcon}) transparent center;
        width: 38px;
        height: 38px;
        top: 10px;
        right: -10px;

        ${media.min.mobileLandscape`
          width: 45px;
          height: 45px;
          top: 7px;
          right: -12px;
        `}
      }
    `}
  `}
`;

export const OtherChoiceOptionWrapper = styled.div`
  outline: none;
  position: relative;
  width: 100%;
  background: ${p => p.theme.chooseOne.otherOption.outline.background};
  color: ${p => p.theme.chooseOne.otherOption.outline.color};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  border-radius: 8px;   

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;
