/* eslint-disable @typescript-eslint/default-param-last */

import { getErrorMessage } from 'lib/errorHandler';

import { transformSurveyAnswers } from './utils';
import {
  FETCH_SURVEY_BLOCK,
  FETCH_SURVEY,
  RESET_SURVEY_STORE,
  SEND_SURVEY_BLOCK_RESULTS,
} from './actions';

export const INITIAL_STATE = {
  answers: null,
  error: null,
  isLoading: false,
  isPostingResponses: false,
  prompts: null,
  surveyMetadata: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_SURVEY_STORE:
      return INITIAL_STATE;
    case FETCH_SURVEY_BLOCK.PENDING:
    case FETCH_SURVEY.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_SURVEY_BLOCK.ERROR:
    case FETCH_SURVEY.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
        isLoading: false,
      };
    case FETCH_SURVEY.SUCCESS:
      return {
        ...state,
        isLoading: false,
        prompts: payload.prompts,
        surveyMetadata: payload.metadata,
      };
    case FETCH_SURVEY_BLOCK.SUCCESS:
      return {
        ...state,
        isLoading: false,
        prompts: payload,
      };
    case SEND_SURVEY_BLOCK_RESULTS.PENDING:
      return {
        ...state,
        isPostingResponses: true,
      };
    case SEND_SURVEY_BLOCK_RESULTS.ERROR:
      return {
        ...state,
        isPostingResponses: false,
      };
    case SEND_SURVEY_BLOCK_RESULTS.SUCCESS: {
      let answers = null;

      if (payload.answers) {
        const transformedAnswers = transformSurveyAnswers(payload.answers, state.prompts);
        if (transformedAnswers.length) answers = transformedAnswers;
      }

      return {
        ...state,
        answers,
        isPostingResponses: false,
        surveyMetadata: {
          ...state.surveyMetadata,
          previousItems: payload.previousItemsCount,
        },
      };
    }
    default:
      return state;
  }
};
