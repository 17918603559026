import {
  takeEvery,
} from 'redux-saga/effects';

import { asyncSuffixes } from 'lib/reduxUtils';
import * as Analytics from 'lib/analytics';

const ERROR_REGEX = new RegExp(`${asyncSuffixes.ERROR}$`);

const parseNetworkError = error => {
  try {
    const parsed = {
      baseURL: error.config.baseURL,
      headers: error.config.headers,
      isConnectivityError: error.isConnectivityError,
      method: error.config.method,
      url: error.config.url,
    };

    if (error.response) {
      parsed.data = error.response.data;
      parsed.status = error.response.status;
    } else if (error.request) {
      parsed.status = 0;
    }

    return parsed;
  } catch (e) {
    return error;
  }
};

export function logger(action) {
  const {
    descriptor,
    log,
    payload,
    type,
  } = action;

  if (!log) return;

  const isError = ERROR_REGEX.test(type);

  if (isError) {
    if (payload) {
      Analytics.trackEvent(descriptor, {
        error: payload.config
          ? parseNetworkError(payload)
          : JSON.stringify(payload),
      });
    } else {
      Analytics.trackEvent('Undefined error caught', {
        descriptor,
        payload,
      });
    }
  } else {
    Analytics.trackEvent(descriptor, {
      payload,
    });
  }
}

export default function* loggerDefaultSaga() {
  yield takeEvery('*', logger);
}
