/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as ModalTypes from 'constants/modalTypes';

import { currentOpenModalsSelector } from 'store/ui/selectors';
import { modalClosed } from 'store/ui/actions';

import IATAssessmentTimerExceededModal from 'components/LMS/CourseViewer/Chapter/IATChapter/IATAssessmentTimerExceededModal';
import SkipIATNotificationModal from 'components/LMS/CourseViewer/Chapter/IATTutorialChapter/SkipIATNotificationModal';
import SkipIATOptionModal from 'components/LMS/CourseViewer/Chapter/IATChapter/SkipIATOptionModal';

import AccessibilityCheckModal from './shared/AccessibilityCheckModal';
import CCAResultsDownloadModal from './LMS/CourseViewer/Chapter/CCAResultsChapter/CCAResultsDownloadModal';
import ConfirmAccessibilitySkipModal from './shared/ConfirmAccessibilitySkipModal';
import ConfirmDeleteModal from './LMS/AccountDetails/ConfirmDeleteModal';
import LanguageChangeErrorModal from './shared/LanguageMenu/LanguageChangeErrorModal';
import PolicyModal from './shared/PolicyModal';

const mapStateToProps = state => ({
  modals: currentOpenModalsSelector(state),
});

const mapDispatchToProps = {
  closeModal: modalClosed,
};

const modalTypeComponentMap = {
  [ModalTypes.ACCESSIBILITY_CHECK]: AccessibilityCheckModal,
  [ModalTypes.CONFIRM_ACCESSIBILITY_SKIP]: ConfirmAccessibilitySkipModal,
  [ModalTypes.CONFIRM_DELETE_ACCOUNT]: ConfirmDeleteModal,
  [ModalTypes.DOWNLOAD_CCA_RESULTS]: CCAResultsDownloadModal,
  [ModalTypes.IAT_ASSESSMENT_TIMER_EXCEEDED]: IATAssessmentTimerExceededModal,
  [ModalTypes.LANGUAGE_CHANGE_ERROR]: LanguageChangeErrorModal,
  [ModalTypes.POLICY_CONSENT]: PolicyModal,
  [ModalTypes.SKIP_IAT_NOTIFICATION]: SkipIATNotificationModal,
  [ModalTypes.SKIP_IAT_OPTION]: SkipIATOptionModal,
};

export const ModalManager = ({
  closeModal,
  modals,
}) => {
  if (!modals.length) return null;

  const { modalProps = {}, modalType } = modals[0];
  const ModalComponent = modalTypeComponentMap[modalType];

  return <ModalComponent onClose={closeModal} {...modalProps} />;
};

ModalManager.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modals: PropTypes.arrayOf(PropTypes.shape({
    modalProps: PropTypes.objectOf(PropTypes.any),
    modalType: PropTypes.string.isRequired,
  })).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalManager);
