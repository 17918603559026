import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import VideoPlayer from 'lib/VideoPlayer';

import {
  currentChapterIdSelector,
} from 'store/courses/selectors';
import {
  fetchVideoCredentials,
  FETCH_VIDEO_CREDENTIALS,
} from '../actions';

const debug = Debug('biassync:lms:store:video:sagas:fetchVideoCredentials');

export function* fetchVideoCredentialsSaga(action) {
  const { shouldReloadPlaylist } = action.payload;

  debug('Saga called');

  const currentChapterId = yield select(currentChapterIdSelector);

  try {
    yield call(API.getVideoCredentials, currentChapterId);

    debug('Received video credentials for ', currentChapterId);

    // We should only "reload" the video with a fresh set of credentials when all playlists
    // have been blacklisted (i.e. 403'd)
    if (shouldReloadPlaylist) yield call([VideoPlayer, 'reloadPlaylist']);

    yield put(fetchVideoCredentials.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchVideoCredentials.error(e));
  }
}

export default function* fetchVideoCredentialsDefaultSaga() {
  yield takeLatest(FETCH_VIDEO_CREDENTIALS.PENDING, fetchVideoCredentialsSaga);
}
