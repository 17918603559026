import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const HelpText = styled.div`
  text-align: center;
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.cascade.dark};
  margin-top: 14px;
  line-height: normal;
  padding: 0 28px;

  ${media.min.baseline`font-size: ${type.scale.uno.size};`}
`;

export const CTAWrapper = styled.div`
  margin-top: 14px;

  ${media.min.baseline`font-size: ${type.scale.uno.size};`}
`;

export const LegalText = styled.div`
  text-align: center;
  font-size: ${type.scale.centi.size};
  font-weight: ${type.weight.regular};
  color: ${colors.cascade.dark};
  margin-top: 14px;
  line-height: normal;
  padding: 0 28px;
`;
