import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import { chapterCompleted } from 'store/courses/actions';
import { currentCourseIdSelector } from 'store/courses/selectors';

import {
  SEND_CHAPTER_COMPLETED_BOOKMARK,
  sendChapterCompletedBookmark,
} from '../actions';

const debug = Debug('biassync:lms:store:chapters:sagas:sendChapterCompletedBookmark');

export function* sendChapterCompletedBookmarkSaga(action) {
  const { payload } = action;
  debug('Saga called', payload);

  const { chapterId } = payload;

  const courseId = yield select(currentCourseIdSelector);

  try {
    yield call(API.sendBookmark, chapterId, { is_completed: true });

    yield put(chapterCompleted({ chapterId, courseId }));

    yield put(sendChapterCompletedBookmark.success());
  } catch (e) {
    debug('Saga error', e);

    yield put(sendChapterCompletedBookmark.error(e, action));
  }
}

export default function* sendChapterCompletedBookmarkDefaultSaga() {
  yield takeLatest(SEND_CHAPTER_COMPLETED_BOOKMARK.PENDING, sendChapterCompletedBookmarkSaga);
}
