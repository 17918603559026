import styled from 'styled-components';

import Popup from 'styles/components/Popup';

const MenuPopup = styled(Popup)`
  padding: 12px 0;
  max-height: 70vh;
  overflow-y: auto;
`;

export default MenuPopup;
