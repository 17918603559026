import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import StyledRouterLink from 'styles/components/StyledRouterLink';
import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import validator from 'lib/validation/validator';

import {
  Form,
  TextFieldWrapper,
  Title,
} from '../style';
import {
  CTAWrapper,
  HelpText,
  LegalText,
} from './style';

const PasswordLogin = ({
  isLoading,
  onChange,
  onRegisterLinkClick,
  onSubmit,
  values,
}) => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <>
      <Title>{t('auth:passwordLogin.title')}</Title>
      <Form onSubmit={onSubmit}>
        <TextFieldWrapper>
          <TextField
            labelText={t('auth:loginIdLabel')}
            name="loginId"
            onChange={onChange}
            tid="loginFieldId"
            validate={validator('requiredField')}
            value={values.loginId}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            labelText={t('auth:passwordLabel')}
            name="password"
            onChange={onChange}
            tid="passwordFieldId"
            type="password"
            validate={validator('password')}
            value={values.password}
          />
        </TextFieldWrapper>
        <LegalText>
          <Trans i18nKey="auth:legalText">
            By continuing, you agree to BiasSync&apos;s <StyledRouterLink data-testid="privacyPolicyLinkId" target="_blank" to="/privacy">Privacy Policy</StyledRouterLink>
          </Trans>
        </LegalText>
        <CTAWrapper>
          <StyledButton tid="submitButtonId" full onClick={onSubmit} loading={isLoading}>
            {t('continue')}
          </StyledButton>
        </CTAWrapper>
        <HelpText>
          <Trans i18nKey="auth:passwordLogin.helpText">
            <StyledRouterLink data-testid="passwordResetLinkId" to="/password_reset">Forgot your password</StyledRouterLink>
            or
            <StyledRouterLink data-testid="registerLinkId" to="/register" onClick={onRegisterLinkClick}>need to sign up?</StyledRouterLink>
          </Trans>
        </HelpText>
      </Form>
    </>
  );
};

PasswordLogin.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRegisterLinkClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default PasswordLogin;
