import { createAction } from 'lib/reduxUtils';

// Sync
export const MODAL_OPENED = 'ui/modalOpened';
export const modalOpened = createAction(MODAL_OPENED);

export const MODAL_CLOSED = 'ui/modalClosed';
export const modalClosed = createAction(MODAL_CLOSED);

export const REQUEST_HEADER_VISIBILITY = 'ui/requestHeaderVisibility';
export const requestHeaderVisibility = createAction(REQUEST_HEADER_VISIBILITY);

export const TOGGLE_OPEN_PANEL = 'ui/toggleOpenPanel';
export const toggleOpenPanel = createAction(TOGGLE_OPEN_PANEL);

export const TOGGLE_OPEN_MENU = 'ui/toggleOpenMenu';
export const toggleOpenMenu = createAction(TOGGLE_OPEN_MENU);

export const TOGGLE_OPEN_TOAST = 'ui/toggleOpenToast';
export const toggleOpenToast = createAction(TOGGLE_OPEN_TOAST);
