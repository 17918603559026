export const ChooseOne = 'choose_one';
export const Dropdown = 'dropdown';
export const Likert = 'likert';
export const Matrix = 'matrix';
export const Checkboxes = 'checkboxes';
export const Scale = 'scale';
export const TextArea = 'text_area';
export const Number = 'number';
export const YesNo = 'yes_no';
export const Message = 'message';
export const Conclusion = 'conclusion';
export const Text = 'text';
