import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import track, { TrackingPropType } from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import { chapterType, errorType } from 'constants/propTypes';
import * as ChapterType from 'constants/chapterTypes';

import * as coursesActions from 'store/courses/actions';
import * as chapterActions from 'store/chapters/actions';
import {
  chaptersErrorSelector,
  isLoadingChaptersSelector,
  isUserAutoAnonymizedErrorSelector,
} from 'store/chapters/selectors';
import {
  currentChapterSelector,
  currentCourseIdSelector,
  firstChapterIdSelector,
  nextChapterIdSelector,
} from 'store/courses/selectors';

import Loading from 'components/shared/Loading';
import ErrorView from 'components/shared/ErrorView';
import SimpleMessageButtonView from 'components/shared/SimpleMessageButtonView';

import AnonymizeResultsChapter from './AnonymizeResultsChapter';
import CCAResultsChapter from './CCAResultsChapter';
import FileDownloadChapter from './FileDownloadChapter';
import IATChapter from './IATChapter';
import IATTutorialChapter from './IATTutorialChapter';
import InformationCardChapter from './InformationCardChapter';
import LikertChapter from './LikertChapter';
import SurveyChapter from './SurveyChapter';
import VideoChapter from './VideoChapter';

const mapStateToProps = state => ({
  chapter: currentChapterSelector(state),
  courseId: currentCourseIdSelector(state),
  error: chaptersErrorSelector(state),
  firstChapterId: firstChapterIdSelector(state),
  isLoading: isLoadingChaptersSelector(state),
  isUserAutoAnonymizedError: isUserAutoAnonymizedErrorSelector(state),
  nextChapterId: nextChapterIdSelector(state),
});

const mapDispatchToProps = {
  finishCourse: coursesActions.handleCourseEnd,
  handleChapterChanged: chapterActions.handleChapterChanged,
  onRetryError: action => action,
  resetChaptersStoreErrors: chapterActions.resetChaptersStoreErrors,
  sendChapterStartedBookmark: chapterActions.sendChapterStartedBookmark,
};

const chapterComponentMap = {
  [ChapterType.AnonymizeResults]: AnonymizeResultsChapter,
  [ChapterType.CompositeSurvey]: SurveyChapter,
  [ChapterType.CCAResults]: CCAResultsChapter,
  [ChapterType.FileDownload]: FileDownloadChapter,
  [ChapterType.IAT]: IATChapter,
  [ChapterType.IATTutorial]: IATTutorialChapter,
  [ChapterType.InformationCard]: InformationCardChapter,
  [ChapterType.LikertTest]: LikertChapter,
  [ChapterType.Survey]: SurveyChapter,
  [ChapterType.Video]: VideoChapter,
};

export const Chapter = ({
  chapter,
  courseId,
  error,
  finishCourse,
  firstChapterId,
  handleChapterChanged,
  isLoading,
  isUserAutoAnonymizedError,
  nextChapterId,
  onRetryError,
  resetChaptersStoreErrors,
  sendChapterStartedBookmark,
  tracking: { trackEvent },
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'courseViewer']);

  const initializeChapter = () => {
    const { id, userMetadata } = chapter;
    const isFirstChapter = firstChapterId === id;

    if (!('isStarted' in userMetadata) || !userMetadata.isStarted) {
      sendChapterStartedBookmark({
        chapterId: id,
        isFirstChapter,
      });
    }
  };

  useEffect(() => {
    if (chapter.id) initializeChapter();
    else navigate('..', { relative: 'path' });

    return () => error && resetChaptersStoreErrors();
  }, [chapter.id]);

  const handleGoToNextChapter = () => {
    trackEvent({
      event: nextChapterId
        ? 'Next Chapter Button Clicked'
        : 'Finish Course Button Clicked',
    });

    if (!nextChapterId) finishCourse();
    else {
      handleChapterChanged({
        chapterId: nextChapterId,
        courseId,
      });
    }
  };

  const handleRetryError = () => {
    onRetryError(error.retryAction);
  };

  if (isLoading) return <Loading />;
  if (isUserAutoAnonymizedError) {
    return (
      <SimpleMessageButtonView
        buttonText={t('continue')}
        message={t('courseViewer:autoAnonymizedUserError.message')}
        title={t('courseViewer:autoAnonymizedUserError.title')}
        onContinue={handleGoToNextChapter}
      />
    );
  }
  if (error) {
    return (
      <ErrorView
        hasBackground
        onRetryClick={error.retryAction ? handleRetryError : null}
        text={error.message}
      />
    );
  }

  const ChapterComponent = chapterComponentMap[chapter.loType];
  return <ChapterComponent chapter={chapter} goToNextChapter={handleGoToNextChapter} />;
};

Chapter.propTypes = {
  chapter: chapterType.isRequired,
  courseId: PropTypes.string.isRequired,
  error: errorType,
  finishCourse: PropTypes.func.isRequired,
  firstChapterId: PropTypes.string.isRequired,
  handleChapterChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserAutoAnonymizedError: PropTypes.bool.isRequired,
  nextChapterId: PropTypes.string,
  onRetryError: PropTypes.func,
  resetChaptersStoreErrors: PropTypes.func.isRequired,
  sendChapterStartedBookmark: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

Chapter.defaultProps = {
  error: null,
  nextChapterId: null,
  onRetryError: null,
  tracking: null,
};

const withTrackingChapter = track({})(Chapter);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTrackingChapter);
