import styled, { css } from 'styled-components';
import { colors, media, type } from 'styles';
import checkmarkIcon from 'assets/green-check.svg';
import TransparentButton from 'styles/components/TransparentButton';

export const Wrapper = styled.div`
  outline: 0; /* to remove blue outline when we focus on the multiselect wrapper */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 12px;
  min-width: 324px;

  ${media.min.baseline`width: max-content;`}
`;

export const CheckboxesOptionWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: ${colors.white};
  color: ${colors.sierra.base};
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  user-select: ${p => (p.isDisabled ? 'none' : 'auto')};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  outline: none;
  padding-right: 44px;

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}

  &:before {
    content: ${p => `'${p.keyShortcut}'`};
    width: 40px;
    height: 40px;
    border-radius: 6px;
    font-size: 1.8rem;
    font-weight: ${type.weight.bold};
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.white};
    background-color: ${colors.blue.base};

    margin-right: 14px;
    ${media.min.mobileLandscape`margin-right: 16px;`}
    ${media.min.baseline`margin-right: 22px;`}

    ${Input}:checked + & { background-color: ${colors.green.base}; }
  }

  ${Input}.focus-visible + & {
    box-shadow: ${colors.yellow.base} 0px 0px 0px 3px;
  }

  ${Input}:checked + & {
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      background: url(${checkmarkIcon}) transparent center;
      width: 38px;
      height: 38px;
      top: 10px;
      right: -10px;

      ${media.min.mobileLandscape`
        width: 45px;
        height: 45px;
        top: 7px;
        right: -12px;
      `}
    }
  }
`;

export const EditWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 4px;
`;

export const TextInput = styled.input`
  width: calc(100% - 65px);
  color: ${colors.black};
  font-size: 1.4rem;
  border: none;
  outline: none;

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const SubmitButton = styled(TransparentButton)`
  position: absolute;
  right: 8px;
  width: 58px;
  height: 48px;
  background-color: ${colors.yellow.base};
  border-radius: 8px;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 4.5px solid ${colors.blue.base};
    border-right: 4.5px solid ${colors.blue.base};
  }

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
  }
`;

export const OtherChoiceInput = styled.input`
  opacity: 0;
  position: absolute;
`;

export const OtherChoiceLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${p => !p.isEditing && css`
    &:before {
      content: ${`'${p.keyShortcut}'`};
      width: 40px;
      height: 40px;
      border-radius: 6px;
      font-size: 1.8rem;
      font-weight: ${type.weight.bold};
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: ${colors.white};
      background-color: ${p.isSelected ? `${colors.green.base}` : `${colors.blue.base}`};

      margin-right: 14px;
      ${media.min.mobileLandscape`margin-right: 16px;`}
      ${media.min.baseline`margin-right: 22px;`}
    }

    ${p.isSelected && css`
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        background: url(${checkmarkIcon}) transparent center;
        width: 38px;
        height: 38px;
        top: 10px;
        right: -10px;

        ${media.min.mobileLandscape`
          width: 45px;
          height: 45px;
          top: 7px;
          right: -12px;
        `}
      }
    `}
  `}
`;

export const OtherChoiceOptionWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  background: ${colors.white};
  color: ${colors.sierra.base};
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;
