import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RatingItem from '../../RatingItem';
import {
  YesNoPromptWrapper,
  RatingItemWrapper,
  RatingWrapper,
} from './style';

const YesNoPrompt = ({
  isDisabled,
  onItemChange,
  onItemClick,
  promptId,
  response,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <YesNoPromptWrapper>
      <RatingItemWrapper>
        <RatingItem
          id={`${promptId}_yes`}
          isDisabled={isDisabled}
          isSelected={response === true}
          name={promptId}
          onChange={() => onItemChange(true)}
          onClick={() => onItemClick(true)}
        >
          <RatingWrapper>
            {t('courseViewer:survey.yesButtonText')}
          </RatingWrapper>
        </RatingItem>
      </RatingItemWrapper>
      <RatingItemWrapper>
        <RatingItem
          id={`${promptId}_no`}
          isDisabled={isDisabled}
          isSelected={response === false}
          name={promptId}
          onChange={() => onItemChange(false)}
          onClick={() => onItemClick(false)}
        >
          <RatingWrapper>
            {t('courseViewer:survey.noButtonText')}
          </RatingWrapper>
        </RatingItem>
      </RatingItemWrapper>
    </YesNoPromptWrapper>
  );
};

YesNoPrompt.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onItemChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  promptId: PropTypes.string.isRequired,
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

YesNoPrompt.defaultProps = {
  response: null,
};

export default YesNoPrompt;
