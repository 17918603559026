import StyledExternalLink from 'styles/components/StyledExternalLink';

import { SectionContent } from './style';

const BNPPPolicy = () => (
  <>
    <SectionContent>
      To enable the full use of this tool and its associated services, BNPP Paribas SA and its
      affiliates (collectively, “BNPP”) need to collect certain personal information which may be
      used for employment verification, regulatory reporting, and other legitimate processing
      purposes. BNPP may communicate your personal data to other BNPP entities or affiliates,
      onboarded third parties, or prudential regulators, as applicable. Your personal data will be
      retained in accordance with applicable regulatory requirements or firm policies. In accordance
      with all applicable rules, you are entitled to exercise any of the rights listed on the
      personal data protection notice available for consultation at the following link: {' '}
      <StyledExternalLink
        target="_blank"
        href="https://cib.bnpparibas.com/about/privacy-policy_a-38-60.html"
      >
        Data Protection Notice
      </StyledExternalLink>
    </SectionContent>
    <SectionContent>
      Please note that this tool is hosted and managed by a third party onboarded by BNPP. Use of
      this tool is voluntary, however, BNPP may receive lists of participants and individuals who
      opt-out. Results and answers will be shared with BNPP in aggregated and anonymized fashion.
      However, individual results will remain confidential and will not be shared with BNPP.
    </SectionContent>
  </>
);

export default BNPPPolicy;
