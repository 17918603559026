import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const PageTitle = ({ subPageTitle }) => (
  <Helmet>
    <title>
      BiasSync
      {subPageTitle ? ` | ${subPageTitle}` : ''}
    </title>
  </Helmet>
);

PageTitle.propTypes = {
  subPageTitle: PropTypes.string,
};

PageTitle.defaultProps = {
  subPageTitle: null,
};

export default PageTitle;
