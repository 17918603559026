import { MatrixSurveyAnswer } from 'types';

import SimpleAnswerItem from './SimpleAnswerItem';
import {
  AnswerList,
  MatrixAnswerListItem,
  PromptText,
} from './style';

type MatrixAnswerItemProps = {
  answer: MatrixSurveyAnswer;
};

const MatrixAnswerItem = ({
  answer,
}: MatrixAnswerItemProps) => (
  <MatrixAnswerListItem>
    <PromptText>{answer.promptText}</PromptText>
    <AnswerList>
      {answer.subPrompts.map(subPromptAnswer => (
        <SimpleAnswerItem key={subPromptAnswer.id} answer={subPromptAnswer} />
      ))}
    </AnswerList>
  </MatrixAnswerListItem>
);

export default MatrixAnswerItem;
