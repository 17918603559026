import PropTypes from 'prop-types';

import {
  Input,
  Label,
  RatingItemWrapper,
} from './style';

const RatingItem = ({
  children,
  id,
  isDisabled,
  isSelected,
  name,
  onChange,
  onClick,
}) => (
  <RatingItemWrapper>
    <Input
      checked={isSelected}
      data-testid={id}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      type="radio"
    />
    <Label
      htmlFor={id}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </Label>
  </RatingItemWrapper>
);

RatingItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RatingItem;
