import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  IATKeyMappingContainerWrapper,
  Key,
  KeyLabelText,
  KeyWrapper,
  TapSideText,
} from './style';

const IATKeyMappingContainer = ({
  children,
  isTouchScreen,
  keyValue,
  spacing,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <IATKeyMappingContainerWrapper spacing={spacing}>
      {children}
      <KeyLabelText spacing={spacing}>
        {isTouchScreen ? t('courseViewer:iat.touchScreenKeyLabel') : t('courseViewer:iat.desktopKeyLabel')}
      </KeyLabelText>
      <KeyWrapper>
        {isTouchScreen ? (
          <TapSideText>{keyValue}</TapSideText>
        ) : (
          <Key>{keyValue}</Key>
        )}
      </KeyWrapper>
    </IATKeyMappingContainerWrapper>
  );
};

IATKeyMappingContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  keyValue: PropTypes.string.isRequired,
  spacing: PropTypes.oneOf(['compact', 'moderate']),
};

IATKeyMappingContainer.defaultProps = {
  spacing: 'moderate',
};

export default IATKeyMappingContainer;
