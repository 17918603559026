import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { persistentEventType } from 'constants/propTypes';
import colors from 'styles/colors';

import PieChart from 'components/shared/PieChart';
import LoadingIndicator from 'components/svg/LoadingIndicator';

import {
  Heading,
  IncompleteResultsMessage,
  PieChartWrapper,
  LoadingWrapper,
  PieChartTitle,
  ResultsOverlayWrapper,
  ResultsWrapper,
} from './style';

const PIE_CHART_COLORS = [colors.blue.base, colors.red.base];

const getDataSegments = (responses) => (
  responses.map(({
    optionText,
    value,
  }, idx) => ({
    color: PIE_CHART_COLORS[idx],
    title: optionText,
    value,
  }))
);

const ResultsOverlay = ({
  overlayEvent: {
    isLoading,
    prompt,
    results,
  },
  showAnimation,
}) => {
  const { t } = useTranslation(['courseViewer', 'errors']);

  let content;
  if (isLoading) {
    content = (
      <>
        <Heading>{prompt}</Heading>
        <LoadingWrapper>
          <LoadingIndicator variant="light" />
        </LoadingWrapper>
      </>
    );
  } else if (results) {
    content = (
      <>
        <Heading>{prompt}</Heading>
        <ResultsWrapper>
          {results.map(({
            aggregateResponses,
            displayTitle,
          }) => (
            <PieChartWrapper key={displayTitle}>
              {aggregateResponses.length <= 1 ? (
                <IncompleteResultsMessage>
                  {t('courseViewer:resultsOverlay.incompleteResults')}
                </IncompleteResultsMessage>
              ) : (
                <PieChart
                  dataSegments={getDataSegments(aggregateResponses)}
                  showAnimation={showAnimation}
                />
              )}
              <PieChartTitle>{displayTitle}</PieChartTitle>
            </PieChartWrapper>
          ))}
        </ResultsWrapper>
      </>
    );
  } else {
    content = <Heading>{t('errors:resultsOverlayError')}</Heading>;
  }

  return (
    <ResultsOverlayWrapper>
      {content}
    </ResultsOverlayWrapper>
  );
};

ResultsOverlay.propTypes = {
  overlayEvent: persistentEventType.isRequired,
  showAnimation: PropTypes.bool.isRequired,
};

export default ResultsOverlay;
