// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

import 'focus-visible';

import setupStore from 'store';

import i18n from './lib/i18n';
import App from './components/App';
import AppErrorBoundary from './components/AppErrorBoundary';
import ModalManager from './components/ModalManager';
import ToastManager from './components/shared/ToastManager';

import { GlobalStyle } from './styles/global';

const store = setupStore();
i18n.initialize(store);

const container = document.getElementById('root');
if (container) {
  render(
    <Suspense fallback={null}>
      <Provider store={store}>
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <AppErrorBoundary>
            <ToastProvider placement="top-right">
              <ToastManager />
              <BrowserRouter>
                <App />
                <ModalManager />
              </BrowserRouter>
            </ToastProvider>
          </AppErrorBoundary>
          <GlobalStyle />
        </StyleSheetManager>
      </Provider>
    </Suspense>,
    container,
  );
}
