import React from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType } from 'constants/propTypes';

import IATPromptLayout from '../IATPromptLayout';
import {
  PromptText,
  SummaryWrapper,
} from './style';

const IATSummaryLayout = ({
  children,
  deviceType,
  onCTATap,
  promptText,
  shouldCTAFlash,
}) => (
  <IATPromptLayout
    deviceType={deviceType}
    onCTATap={onCTATap}
    shouldCTAFlash={shouldCTAFlash}
  >
    <PromptText>{promptText}</PromptText>
    <SummaryWrapper>
      {children}
    </SummaryWrapper>
  </IATPromptLayout>
);

IATSummaryLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  deviceType: deviceTypeType.isRequired,
  onCTATap: PropTypes.func.isRequired,
  promptText: PropTypes.string.isRequired,
  shouldCTAFlash: PropTypes.bool,
};

IATSummaryLayout.defaultProps = {
  shouldCTAFlash: false,
};

export default IATSummaryLayout;
