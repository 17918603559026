import Cookies from 'cookies-js';

const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

export const setCookie = (key, value) => {
  Cookies.set(key, value, { expires: ONE_YEAR });
};

export const deleteCookie = key => Cookies.expire(key);

export const getCookies = (...keys) => keys.reduce((acc, key) => {
  acc[key] = Cookies.get(key);
  return acc;
}, {});
