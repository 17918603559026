import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import {
  deviceTypeType,
  iatTestResultType,
} from 'constants/propTypes';
import { DESKTOP, MOBILE } from 'constants/deviceTypes';

import backArrowIcon from 'assets/simple-left-arrow.svg';
import ChevronDown, { ChevronDownVariant } from 'components/svg/ChevronDown';
import SimpleButton from 'components/shared/SimpleButton';

import LearnMore from './IATLearnMore';
import {
  ArrowIcon,
  BackButton,
  CTAWrapper,
  ChevronWrapper,
  IntroWrapper,
  KeyText,
  PromptText,
  ResultText,
  ResultTitle,
  ResultVisualization,
  ResultsWrapper,
  TextWrapper,
} from './style';

export const IATTestResult = ({
  deviceType,
  isScrolledToBottom,
  onBackButton,
  onContinue,
  showIndividualResult,
  showIntro,
  testResult: {
    aggregate,
    individual,
  },
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  if (showIntro) {
    return (
      <IntroWrapper>
        <PromptText>{t('courseViewer:iat.testResultIntro')}</PromptText>
      </IntroWrapper>
    );
  }

  let content;

  if (showIndividualResult) {
    content = {
      ...individual,
      title: t('courseViewer:iat.individualResultsTitle'),
    };
  } else {
    content = {
      ...aggregate,
      title: t('courseViewer:iat.aggregateResultsTitle'),
    };
  }

  const ctaAction = deviceType === DESKTOP ? (
    <Trans i18nKey="courseViewer:iat.desktopCTA">
      Press <KeyText>spacebar</KeyText> to continue
    </Trans>
  ) : (
    <SimpleButton onClick={onContinue} variant="primary">
      {t('continue')}
    </SimpleButton>
  );

  return (
    <ResultsWrapper>
      {showIndividualResult && (
        <BackButton onClick={onBackButton}>
          <ArrowIcon alt="" src={backArrowIcon} />
          {t('courseViewer:iat.backToSummary')}
        </BackButton>
      )}
      <ResultTitle>{content.title}</ResultTitle>
      <ResultVisualization url={content.graphUrl} />
      <TextWrapper>
        <ResultText>{content.resultText}</ResultText>
      </TextWrapper>
      {showIndividualResult && <LearnMore />}
      <CTAWrapper>
        {ctaAction}
      </CTAWrapper>
      {deviceType === MOBILE && (
        <ChevronWrapper fadeOut={isScrolledToBottom}>
          <ChevronDown titleText={t('courseViewer:iat.scrollDown')} variant={ChevronDownVariant.Yellow} />
        </ChevronWrapper>
      )}
    </ResultsWrapper>
  );
};

IATTestResult.propTypes = {
  deviceType: deviceTypeType.isRequired,
  isScrolledToBottom: PropTypes.bool.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  showIndividualResult: PropTypes.bool.isRequired,
  showIntro: PropTypes.bool.isRequired,
  testResult: iatTestResultType.isRequired,
};

export default IATTestResult;
