import React from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';

import SkipIATNotificationModal from './SkipIATNotificationModal';

export const SkipIATNotificationModalContainer = ({
  onClose,
  tracking,
}) => {
  const handleAccept = () => {
    tracking.trackEvent({ event: 'Skip IAT Warning Accept Button Clicked' });
    onClose();
  };

  return <SkipIATNotificationModal onAccept={handleAccept} />;
};

SkipIATNotificationModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
};

const trackedSkipIATNotificationModal = track({ module: 'SkipIATNotificationModal' }, {
  dispatchOnMount: () => ({ event: 'Skip IAT Warning Modal Shown' }),
})(SkipIATNotificationModalContainer);

export default trackedSkipIATNotificationModal;
