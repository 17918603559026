import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 120px 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ResultVisualization = styled.div`
  height: 300px;
  ${({ url }) => `background-image: url(${url});`}
  background-size: contain;
  background-position: center;
  border-radius: 8px;
  margin-top: 30px;

  ${media.min.baseline`
    height: 400px;
    margin-top: 35px;
  `}

  ${media.min.desktop`
    height: 500px;
    margin-top: 40px;
  `}
`;

export const TextWrapper = styled.div`
  margin-top: 30px;

  ${media.min.desktop`margin-top 44px;`}
`;

export const ResultTitle = styled.div`
  color: ${colors.yellow.base};
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  text-align: center;

  ${media.min.desktop`font-size: 2.4rem;`}
`;

export const ResultText = styled.p`
  color: ${colors.yellow.background};
  font-size: 1.6rem;
  padding: 0 22px;
  text-indent: 3rem;

  ${media.min.desktop`
    font-size: 2rem;
    padding: 0 44px;
  `}
`;

export const CTAWrapper = styled.div`
  margin: 40px auto 0;
`;
