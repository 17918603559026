import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import Modal from 'components/shared/Modal';
import {
  DialogContent,
  Footer,
  Message,
  RetryButton,
  Title,
} from './style';

const LanguageChangeErrorModal = ({
  onProceed,
  onRetry,
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal isRequired>
      <DialogContent>
        <Title>
          {t('languageChangeErrorModal.title')}
        </Title>
        <Message>
          {t('languageChangeErrorModal.message')}
        </Message>
        <Footer>
          <RetryButton onClick={onRetry}>
            {t('languageChangeErrorModal.retryButton')}
          </RetryButton>
          <SimpleButton
            type="button"
            onClick={onProceed}
          >
            {t('languageChangeErrorModal.proceedButton')}
          </SimpleButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

LanguageChangeErrorModal.propTypes = {
  onProceed: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default LanguageChangeErrorModal;
