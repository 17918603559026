import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from 'components/shared/LoadingSpinner';

import {
  Button,
  ButtonText,
  LoadingWrapper,
  variantStyles,
} from './style';

const SimpleButton = ({
  children,
  disabled,
  focusOutlineColor,
  full,
  isFadedWhenLoading,
  loading,
  name,
  onClick,
  pulsing,
  responsivenessType,
  size,
  type,
  variant,
}) => {
  const { t } = useTranslation('common');

  return (
    <Button
      aria-label={loading ? t('loading') : ''}
      disabled={disabled || loading}
      faded={disabled || (loading && isFadedWhenLoading)}
      focusOutlineColor={focusOutlineColor}
      pulsing={pulsing}
      full={full}
      name={name}
      onClick={onClick}
      responsivenessType={responsivenessType}
      size={size}
      type={type}
      variant={variant}
    >
      <ButtonText isVisible={!loading}>{children}</ButtonText>
      {loading && (
        <LoadingWrapper>
          <LoadingSpinner color={variantStyles[variant].color} />
        </LoadingWrapper>
      )}
    </Button>
  );
};

SimpleButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  focusOutlineColor: PropTypes.string,
  full: PropTypes.bool,
  isFadedWhenLoading: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  pulsing: PropTypes.bool,
  responsivenessType: PropTypes.oneOf(['oneDim', 'twoDim']),
  size: PropTypes.oneOf(['sm', 'md']),
  type: PropTypes.string,
  variant: PropTypes.oneOf(['brandedPrimary', 'primary', 'red', 'white', 'outline']),
};

SimpleButton.defaultProps = {
  disabled: false,
  focusOutlineColor: null,
  full: false,
  isFadedWhenLoading: true,
  loading: false,
  name: undefined,
  pulsing: false,
  responsivenessType: 'oneDim',
  size: 'md',
  type: 'button',
  variant: 'primary',
};

export default SimpleButton;
