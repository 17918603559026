import React from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType } from 'constants/propTypes';
import { MOBILE, TABLET } from 'constants/deviceTypes';

import IATKeyMappingContainer from 'components/shared/IATKeyMappingContainer';
import ImageOptions from 'components/shared/IATCategoryOptions/ImageOptions';
import TextOptions from 'components/shared/IATCategoryOptions/TextOptions';

import {
  CategoryName,
  OptionsWrapper,
  Subtext,
} from './style';

const IATTutorialKeyMappingItem = ({
  categoryName,
  categoryType,
  deviceType,
  keyValue,
  options,
  t,
}) => (
  <IATKeyMappingContainer
    isTouchScreen={deviceType === MOBILE || deviceType === TABLET}
    keyValue={keyValue}
    spacing="compact"
  >
    <OptionsWrapper isFullWidth={categoryType === 'text'}>
      {categoryType === 'image' ? (
        <ImageOptions
          columnSpan="small"
          options={options}
        />
      ) : (
        <TextOptions
          isScattered={deviceType !== MOBILE}
          options={options}
          rowSpan="small"
        />
      )}
    </OptionsWrapper>
    <Subtext>{t('courseViewer:iat.to')}</Subtext>
    <CategoryName>{categoryName}</CategoryName>
  </IATKeyMappingContainer>
);

IATTutorialKeyMappingItem.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  deviceType: deviceTypeType.isRequired,
  keyValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
};

export default IATTutorialKeyMappingItem;
