import React from 'react';
import PropTypes from 'prop-types';

import IATKeyMappingContainer from '../IATKeyMappingContainer';
import {
  CategoryDescription,
  CategoryName,
} from './style';

const IATKeyMappingItem = ({
  categories,
  isTouchScreen,
  keyValue,
}) => (
  <IATKeyMappingContainer
    isTouchScreen={isTouchScreen}
    keyValue={keyValue}
  >
    {Object.entries(categories).map(([type, name]) => (
      <CategoryDescription key={name}>
        <CategoryName isImageType={type === 'image'}>
          {name}
        </CategoryName>
      </CategoryDescription>
    ))}
  </IATKeyMappingContainer>
);

IATKeyMappingItem.propTypes = {
  categories: PropTypes.objectOf(PropTypes.string).isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  keyValue: PropTypes.string.isRequired,
};

export default IATKeyMappingItem;
