import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { refType, surveyMessageType } from 'constants/propTypes';

import MultiPageMessageItem from './MultiPageMessageItem';

const MultiPageMessageItemContainer = ({
  innerRef,
  message: {
    promptText,
    subPrompts,
  },
  onContinue,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [hasReachedLastStep, setHasReachedLastStep] = useState(false);

  const isLastStep = currentStepIndex + 1 === subPrompts.length;

  useEffect(() => {
    if (isLastStep) setHasReachedLastStep(true);
  }, [currentStepIndex]);

  const handleStepChange = indexChange => {
    setCurrentStepIndex(prevInfoIndex => prevInfoIndex + indexChange);
  };

  return (
    <MultiPageMessageItem
      currentStepIndex={currentStepIndex}
      innerRef={innerRef}
      isContinueButtonVisible={hasReachedLastStep}
      isLastStep={isLastStep}
      items={subPrompts}
      onContinue={onContinue}
      onStepChange={handleStepChange}
      title={promptText}
    />
  );
};

MultiPageMessageItemContainer.propTypes = {
  innerRef: refType,
  message: surveyMessageType.isRequired,
  onContinue: PropTypes.func.isRequired,
};

MultiPageMessageItemContainer.defaultProps = {
  innerRef: null,
};

export default MultiPageMessageItemContainer;
