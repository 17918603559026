import { createSelector } from 'reselect';

export const isLoadingSurveySelector = state => state.survey.isLoading;
export const isPostingSurveyResponsesSelector = state => state.survey.isPostingResponses;
export const surveyAnswersSelector = state => state.survey.answers;
export const surveyErrorSelector = state => state.survey.error;
export const surveyMetadataSelector = state => state.survey.surveyMetadata;
export const surveyPromptsSelector = state => state.survey.prompts;

export const totalBlockSelector = createSelector(
  surveyMetadataSelector,
  survey => survey?.totalBlocks ?? null,
);

export const totalItemsSelector = createSelector(
  surveyMetadataSelector,
  survey => survey?.totalItems ?? null,
);

export const totalPrevItemsSelector = createSelector(
  surveyMetadataSelector,
  survey => survey?.previousItems ?? null,
);

