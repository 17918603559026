import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';

import { chapterType } from 'constants/propTypes';

import { isMobileDeviceSelector } from 'store/userAgent/selectors';
import {
  fileDownloadErrorSelector,
  isFileDownloadChoiceSubmittedSelector,
  isFileDownloadPostingSelector,
  userFileDownloadChoiceSelector,
} from 'store/fileDownload/selectors';

import { submitFileDownloadChoice } from 'store/fileDownload/actions';

import FileDownloadChapter from './FileDownloadChapter';

const mapStateToProps = state => ({
  error: fileDownloadErrorSelector(state),
  isMobileDevice: isMobileDeviceSelector(state),
  isPosting: isFileDownloadPostingSelector(state),
  isSubmitted: isFileDownloadChoiceSubmittedSelector(state),
  userChoice: userFileDownloadChoiceSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onChoiceSubmit: payload => dispatch(submitFileDownloadChoice(payload)),
});

export const SUCCESSFUL_DOWNLOAD_REDIRECT_DELAY_MS = 1000;

@track({})
export class FileDownloadChapterContainer extends Component {
  componentDidUpdate(prevProps) {
    const { goToNextChapter, isSubmitted, userChoice } = this.props;

    if (!prevProps.isSubmitted && isSubmitted) {
      if (userChoice === true) {
        // Delaying the redirect so that there's a moment between the browser downloading
        // the file and moving the user away from the page
        setTimeout(() => {
          goToNextChapter();
        }, SUCCESSFUL_DOWNLOAD_REDIRECT_DELAY_MS);
      } else {
        goToNextChapter();
      }
    }
  }

  @track((_, __, [shouldDownload]) => ({
    event: shouldDownload
      ? 'Accept File Download Button Clicked'
      : 'Decline File Download Button Clicked',
  }))
  handleChoiceSubmit = shouldDownload => {
    this.props.onChoiceSubmit(shouldDownload);
  };

  render() {
    const {
      chapter,
      isMobileDevice,
      isPosting,
      userChoice,
    } = this.props;

    return (
      <FileDownloadChapter
        chapter={chapter}
        isMobileDevice={isMobileDevice}
        isPosting={isPosting}
        onChoiceSubmit={this.handleChoiceSubmit}
        userChoice={userChoice}
      />
    );
  }
}

FileDownloadChapterContainer.propTypes = {
  chapter: chapterType.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  onChoiceSubmit: PropTypes.func.isRequired,
  userChoice: PropTypes.bool,
};

FileDownloadChapterContainer.defaultProps = {
  userChoice: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileDownloadChapterContainer);
