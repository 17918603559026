import { useEffect } from 'react';

const ON_CONTINUE_DELAY_MS = 1500;

export const useTaskCompletionTimeout = (isCompleted: boolean, callback: () => void) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isCompleted) timeout = setTimeout(() => callback(), ON_CONTINUE_DELAY_MS);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isCompleted]);
};
