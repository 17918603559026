import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  LOGIN_WITH_PASSWORD,
  loginWithPassword,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:loginWithPassword');

export function* loginWithPasswordSaga({ payload: loginData }) {
  debug('Saga called');

  try {
    const loginResponse = yield call(API.login, decamelizeKeys(loginData));

    yield put(loginWithPassword.success(loginResponse));
  } catch (e) {
    debug('Saga error', e);
    yield put(loginWithPassword.error(e));
  }
}

export default function* loginWithPasswordDefaultSaga() {
  yield takeLatest(LOGIN_WITH_PASSWORD.PENDING, loginWithPasswordSaga);
}
