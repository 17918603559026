/* eslint-disable @typescript-eslint/default-param-last */

import { ANONYMIZE_USER_COURSE_DATA } from './actions';

export const INITIAL_STATE = {
  isAnonymizing: false,
};

export default (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case ANONYMIZE_USER_COURSE_DATA.PENDING:
      return {
        ...state,
        isAnonymizing: true,
      };
    case ANONYMIZE_USER_COURSE_DATA.SUCCESS:
    case ANONYMIZE_USER_COURSE_DATA.ERROR:
      return {
        ...state,
        isAnonymizing: false,
      };
    default:
      return state;
  }
};
