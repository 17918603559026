import React from 'react';
import PropTypes from 'prop-types';

import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';

import { surveyOptionType } from 'constants/propTypes';
import customDropdownStyles from './customDropdownStyles';

export const CREATE_OPTION_ACTION = 'create-option';

const CreateDropdown = React.forwardRef(({
  customCreateLabel,
  isDisabled,
  onChange,
  onCreateChange,
  options,
  placeholder,
}, ref) => {
  const handleChange = (option, { action }) => {
    if (!option) return;

    if (action === CREATE_OPTION_ACTION) onCreateChange(option.value);
    else onChange(option.value);
  };

  return (
    <CreatableSelect
      filterOption={createFilter({ matchFrom: 'start' })}
      formatCreateLabel={(value) => `${customCreateLabel}: ${value}`}
      isClearable
      isDisabled={isDisabled}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      ref={ref}
      styles={customDropdownStyles}
    />
  );
});

CreateDropdown.propTypes = {
  customCreateLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(surveyOptionType).isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CreateDropdown;
