import PropTypes from 'prop-types';

import {
  Input,
  Label,
  ChooseOneOptionWrapper,
} from './style';

const ChooseOneOption = ({
  children,
  id,
  isDisabled,
  isSelected,
  name,
  onChange,
  onClick,
}) => (
  <ChooseOneOptionWrapper>
    <Input
      aria-checked={isSelected}
      checked={isSelected}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      type="radio"
    />
    <Label isDisabled={isDisabled} htmlFor={id} onClick={onClick}>
      {children}
    </Label>
  </ChooseOneOptionWrapper>
);

ChooseOneOption.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChooseOneOption;
