import styled from 'styled-components';
import { media } from 'styles';

export const KeyMappingSummaryWrapper = styled.div`
  & + & { margin-top: 14px; }

  ${media.twoDimMin.tablet`
    display: inline-block;
    & + & { margin-left: 32px; }
    width: 290px;
  `}

  ${media.twoDimMin.desktop`
    & + & { margin-left: 75px; }
    width: 340px;
  `}

  ${media.twoDimMin.max`
    & + & { margin-left: 100px; }
    width: 400px;
  `}
`;

export const KeyMappingSummaries = styled.div`
  ${media.twoDimMin.desktop`position: relative;`}
`;

export const ProgressIndicatorWrapper = styled.div`
  position: absolute;
  right: 50%;
  transform: translate(50%, 50%);
  bottom: 36px;

  ${media.twoDimMin.tablet`
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50px;
    bottom: 120px;
  `}
  ${media.twoDimMin.baseline`bottom: auto;`}
  ${media.twoDimMin.desktop`left: -100px;`}
  ${media.twoDimMin.max`left: -130px;`}
`;
