import { MultipleChoiceFactTaskEvent } from 'types/VideoTaskEvent';

import MultipleChoiceTaskForm from './MultipleChoiceTaskForm';

type MultipleChoiceFactTaskProps = {
  isSubmitting: boolean;
  onContinue: () => void;
  onSubmit: (optionKey: string) => void;
  taskEvent: MultipleChoiceFactTaskEvent;
};

const MultipleChoiceFactTask = ({
  isSubmitting,
  onContinue,
  onSubmit,
  taskEvent,
}: MultipleChoiceFactTaskProps) => (
  <MultipleChoiceTaskForm
    correctAnswer={taskEvent.taskResponse?.correctAnswer}
    isSubmitted={taskEvent.isSubmitted}
    isSubmitting={isSubmitting}
    onContinue={onContinue}
    onSubmit={onSubmit}
    options={taskEvent.options}
    prompt={taskEvent.prompt}
  />
);

export default MultipleChoiceFactTask;
