import { useState } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { userType } from 'constants/propTypes';

import { isAccountDetailsPermittedSelector } from 'store/auth/selectors';
import { isTouchScreenSelector } from 'store/userAgent/selectors';
import { userSelector } from 'store/user/selectors';
import { isAICCSessionSelector } from 'store/aicc/selectors';

import { logoutUser } from 'store/auth/actions';

import CourseLayout from './CourseLayout';

const mapStateToProps = state => ({
  isAICCSession: isAICCSessionSelector(state),
  isAccountDetailsPermitted: isAccountDetailsPermittedSelector(state),
  isTouchScreen: isTouchScreenSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  onLogout: logoutUser,
};

export const CourseLayoutContainer = ({
  children,
  hasLogoFooter,
  isAccountDetailsPermitted,
  isAICCSession,
  onLogout,
  tracking: { trackEvent },
  user,
}) => {
  const location = useLocation();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const handleAccountMenuClick = () => {
    setIsAccountMenuOpen(prevIsAccountMenuOpen => !prevIsAccountMenuOpen);
  };

  const handleAccountDetailsLinkClick = () => {
    trackEvent({ event: 'Account Details Link Clicked' });

    if (location.pathname === '/account') handleAccountMenuClick();
  };

  const handleCoursesLinkClick = () => {
    trackEvent({ event: 'My Courses Link Clicked' });

    if (location.pathname === '/courses') handleAccountMenuClick();
  };

  const handleLogout = () => {
    trackEvent({ event: 'Logout Button Clicked' });
    onLogout();
  };

  return (
    <CourseLayout
      hasLogoFooter={hasLogoFooter}
      isAccountDetailsPermitted={isAccountDetailsPermitted}
      isAccountMenuOpen={isAccountMenuOpen}
      isCoursesPageOptionAvailable={!isAICCSession}
      onAccountDetailsLinkClick={handleAccountDetailsLinkClick}
      onAccountMenuClick={handleAccountMenuClick}
      onAdminPortalLinkClick={() => trackEvent({ event: 'Admin Portal Link Clicked' })}
      onCoursesLinkClick={handleCoursesLinkClick}
      onLogoLinkClick={() => trackEvent({ event: 'Logo Link Clicked' })}
      onLogout={handleLogout}
      onPrivacyPolicyLinkClick={() => trackEvent({ event: 'Privacy Policy Link Clicked' })}
      onTechIssueLinkClick={() => trackEvent({ event: 'Tech Issue Link Clicked' })}
      user={user}
    >
      {children}
    </CourseLayout>
  );
};

CourseLayoutContainer.propTypes = {
  children: PropTypes.node,
  hasLogoFooter: PropTypes.bool,
  isAICCSession: PropTypes.bool.isRequired,
  isAccountDetailsPermitted: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  tracking: TrackingPropType,
  user: userType,
};

CourseLayoutContainer.defaultProps = {
  children: null,
  hasLogoFooter: true,
  tracking: null,
  user: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(track({})(CourseLayoutContainer));
