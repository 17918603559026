import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType, likertTestType, refType } from 'constants/propTypes';
import likertRatings, { likertScale } from 'constants/likertRatings';
import { MOBILE, TABLET } from 'constants/deviceTypes';
import { isButtonTarget } from 'lib/utils';
import withListScroller from 'lib/withListScroller';

import LikertTest from './LikertTest';

export class LikertTestContainer extends Component {
  state = {
    activeItemId: 'intro',
    furthestItemIndex: 0,
    selectedRatings: {},
  };

  constructor(props) {
    super(props);

    this.itemIds = props.likertTest.items.map(item => item.id).concat('outro');
    props.initializeScrollItems(this.itemIds.length);
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  // We are using keyup events for the enter key to work on IE 11
  handleKeyUp = event => {
    const { key } = event;
    const { isPostingResponses } = this.props;
    const { activeItemId } = this.state;

    // No need to handle Enter key if it was used to trigger a button
    if (key === 'Enter' && !isButtonTarget(event)) {
      if (activeItemId === 'intro') this.props.scrollIntoView(0);
      else if (activeItemId === 'outro' && !isPostingResponses) this.handleTestSubmit();
    } else if (key >= 1 && key <= 5 && activeItemId !== 'intro' && activeItemId !== 'outro') {
      this.handleItemRatingChange(activeItemId, likertScale[key - 1].value);
    }
  };

  handleStartButtonClick = () => {
    // This is a temporary fix to handle start button focus issue
    if (this.state.activeItemId === 'intro') {
      this.props.scrollIntoView(0);
    }
  };

  handleItemRatingChange = (itemId, selectedRating, shouldScroll = true) => {
    const itemIndex = this.itemIds.indexOf(itemId);

    const setStateCallback = () => {
      const nextActiveItemIndex = itemIndex + 1;
      if (nextActiveItemIndex !== this.itemIds.length + 1) {
        this.props.scrollIntoView(nextActiveItemIndex);
      }
    };

    this.setState(state => {
      const nextState = {
        selectedRatings: {
          ...state.selectedRatings,
          [itemId]: selectedRating,
        },
      };

      if (itemIndex === state.furthestItemIndex) {
        nextState.furthestItemIndex = state.furthestItemIndex + 1;
      }

      return nextState;
    }, shouldScroll ? setStateCallback : undefined);
  };

  handleInactiveItemClick = itemId => {
    const itemIndex = this.itemIds.indexOf(itemId);
    this.props.scrollIntoView(itemIndex);
  };

  handleItemFocus = itemId => {
    if (itemId !== this.state.activeItemId) {
      this.setState({ activeItemId: itemId });
    }
  };

  handleTestSubmit = event => {
    if (event) event.preventDefault();

    const { selectedRatings } = this.state;
    const { items } = this.props.likertTest;

    const testResponses = items.map(item => {
      const selectedRating = selectedRatings[item.id];

      return {
        itemId: item.id,
        ordinalScore: likertRatings[selectedRating].score[item.polarity],
      };
    });

    this.props.onTestSubmit(testResponses);
  };

  render() {
    const {
      activeItemId,
      furthestItemIndex,
      selectedRatings,
    } = this.state;
    const {
      chapterTitle,
      deviceType,
      isPostingResponses,
      itemRefs,
      likertTest,
    } = this.props;

    return (
      <LikertTest
        activeItemId={activeItemId}
        chapterTitle={chapterTitle}
        furthestItemIndex={furthestItemIndex}
        isPostingResponses={isPostingResponses}
        isTouchScreen={deviceType === MOBILE || deviceType === TABLET}
        itemRefs={itemRefs}
        likertTest={likertTest}
        onInactiveItemClick={this.handleInactiveItemClick}
        onItemFocus={this.handleItemFocus}
        onItemRatingChange={this.handleItemRatingChange}
        onStartButtonClick={this.handleStartButtonClick}
        onTestSubmit={this.handleTestSubmit}
        selectedRatings={selectedRatings}
      />
    );
  }
}

LikertTestContainer.propTypes = {
  chapterTitle: PropTypes.string.isRequired,
  deviceType: deviceTypeType.isRequired,
  initializeScrollItems: PropTypes.func.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  itemRefs: PropTypes.arrayOf(refType).isRequired,
  likertTest: likertTestType.isRequired,
  onTestSubmit: PropTypes.func.isRequired,
  scrollIntoView: PropTypes.func.isRequired,
};

export default withListScroller(LikertTestContainer);
