/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import VideoPlayer from 'lib/VideoPlayer';
import { handleHotkeys } from 'lib/VideoPlayer/hotkeyHandler';
import { chapterType, errorType, ephemeralEventType, persistentEventType } from 'constants/propTypes';
import * as ToastTypes from 'constants/toastTypes';

import { isDemoCourseSelector, nextChapterTitleSelector } from 'store/courses/selectors';
import { isAnyModalOpenSelector, isSettingsMenuOpenSelector } from 'store/ui/selectors';
import { isEdgeSelector, isIOSSelector, isMobileDeviceSelector } from 'store/userAgent/selectors';
import {
  currentVideoEphemeralEventSelector,
  currentVideoPersistentEventSelector,
  hasVideoEndedSelector,
  isSendingVideoEndBookmarkSelector,
  isVideoPlayableSelector,
  isVideoPlayerInitializedSelector,
  videoErrorSelector,
} from 'store/video/selectors';
import {
  saveVideoUserMetadata,
  handleVideoPlayerTeardown,
  loadVideoUserMetadata,
  sendVideoEndBookmark,
  setVideoSource,
} from 'store/video/actions';
import { toggleOpenToast } from 'store/ui/actions';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';

import VideoChapter from './VideoChapter';

const mapStateToProps = (state) => ({
  ephemeralEvent: currentVideoEphemeralEventSelector(state),
  error: videoErrorSelector(state),
  hasVideoEnded: hasVideoEndedSelector(state),
  isDemoCourse: isDemoCourseSelector(state),
  isEdgeBrowser: isEdgeSelector(state),
  isIOS: isIOSSelector(state),
  isLastChapter: !nextChapterTitleSelector(state),
  isMobileDevice: isMobileDeviceSelector(state),
  isModalDisplayed: isAnyModalOpenSelector(state),
  isSendingEndBookmark: isSendingVideoEndBookmarkSelector(state),
  isSettingsMenuOpen: isSettingsMenuOpenSelector(state),
  isVideoPlayable: isVideoPlayableSelector(state),
  isVideoPlayerInitialized: isVideoPlayerInitializedSelector(state),
  nextChapterTitle: nextChapterTitleSelector(state),
  persistentEvent: currentVideoPersistentEventSelector(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  loadVideoUserMetadata: userMetadata => dispatch(loadVideoUserMetadata(userMetadata)),
  onPlayerTeardown: () => dispatch(handleVideoPlayerTeardown()),
  onRetryError: action => dispatch(action),
  saveUserMetadata: chapterId => dispatch(saveVideoUserMetadata(chapterId)),
  sendEndBookmark: payload => dispatch(sendVideoEndBookmark(payload)),
  setVideoSource: chapterId => dispatch(setVideoSource(chapterId)),
  toggleToast: toast => dispatch(toggleOpenToast(toast)),
});

export class VideoChapterContainer extends Component {
  state = {
    prevEnabledIOSNativePlayer: false,
  };

  constructor(props) {
    super(props);

    this.videoNode = React.createRef();
  }

  componentDidMount() {
    const { dispatch, isIOS, isMobileDevice } = this.props;

    VideoPlayer.initialize(this.videoNode.current, dispatch, { isIOS, isMobileDevice });
    this.initializeChapter();

    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps) {
    const {
      chapter,
      ephemeralEvent,
      error,
      goToNextChapter,
      hasVideoEnded,
      isIOS,
      isLastChapter,
      isModalDisplayed,
      isSendingEndBookmark,
      isSettingsMenuOpen,
      isVideoPlayable,
      persistentEvent,
      saveUserMetadata,
      sendEndBookmark,
    } = this.props;

    if (chapter.id !== prevProps.chapter.id) {
      VideoPlayer.pause();
      VideoPlayer.hide(); // Hide the video player until the video is playable

      saveUserMetadata(prevProps.chapter.id);
      this.initializeChapter();
    }

    if (!prevProps.isVideoPlayable && isVideoPlayable) {
      VideoPlayer.show();
    }

    if (!prevProps.isSettingsMenuOpen && isSettingsMenuOpen) {
      VideoPlayer.disableClick();
    }

    if (prevProps.isSettingsMenuOpen && !isSettingsMenuOpen) {
      // Using timeout to avoid the click event that closed the settings menu
      // from being caught by the video player click handler
      setTimeout(() => VideoPlayer.enableClick(), 250);
    }

    if (!prevProps.error && error) {
      VideoPlayer.hide();
    }

    if (!prevProps.hasVideoEnded && hasVideoEnded) {
      sendEndBookmark({
        chapterId: chapter.id,
        isFirstView: !chapter.userMetadata.isCompleted,
      });

      if (isLastChapter) {
        // If chapter has been previously completed, we can move on
        if (chapter.userMetadata.isCompleted) {
          goToNextChapter();
        } else {
          // Otherwise we need to hide the video player while we wait for the end
          // bookmark request to be done
          VideoPlayer.hide();
        }
      }
    }

    // If end bookmark was successfully sent and chapter is the last chapter
    if (prevProps.isSendingEndBookmark && !isSendingEndBookmark && !error && isLastChapter) {
      goToNextChapter();
    }

    if (!prevProps.isModalDisplayed && isModalDisplayed) {
      VideoPlayer.pause();
    }

    // If modal was closed and the video has not ended, resume video
    if (prevProps.isModalDisplayed && !isModalDisplayed && !hasVideoEnded) {
      VideoPlayer.play();
    }

    // Exit iOS native player (i.e. fullscreen mode) during ephemeral and persistent events,
    // and end of chapters.
    if (isIOS && VideoPlayer.isFullscreen()) {
      if (!prevProps.ephemeralEvent && ephemeralEvent) {
        this.setState({ prevEnabledIOSNativePlayer: true });
        VideoPlayer.toggleFullscreen();
      } else if (!prevProps.persistentEvent && persistentEvent) {
        VideoPlayer.toggleFullscreen();

        // Play video because iOS native player pauses it by default, and set a timeout
        // to avoid a race condition where videoJS does not receive the play request
        setTimeout(() => { VideoPlayer.play(); }, 500);
      } else if (!prevProps.hasVideoEnded && hasVideoEnded && !prevProps.persistentEvent) {
        VideoPlayer.toggleFullscreen();
      }
    }

    // Return to iOS native player if the user was previously using it before an ephemeral event
    if (isIOS && prevProps.ephemeralEvent && !ephemeralEvent) {
      if (this.state.prevEnabledIOSNativePlayer) VideoPlayer.toggleFullscreen();

      this.setState({ prevEnabledIOSNativePlayer: false });
    }
  }

  componentWillUnmount() {
    this.props.saveUserMetadata(this.props.chapter.id);
    VideoPlayer.teardown();
    this.props.onPlayerTeardown();
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleRetryError = () => {
    this.props.onRetryError(this.props.error.retryAction);
  };

  initializeChapter = () => {
    const { chapter, isDemoCourse, t, toggleToast } = this.props;
    const { id, requiresTestTaker, userMetadata } = chapter;

    this.props.loadVideoUserMetadata(userMetadata);
    this.props.setVideoSource(id);

    if (isDemoCourse && requiresTestTaker) toggleToast({ message: t('courseViewer:iat.demoNotification'), type: ToastTypes.TOAST_NOTIFICATION });
  };

  handleKeyUp = (event) => {
    const { ephemeralEvent, isModalDisplayed } = this.props;

    // Hotkeys are disabled if video player controls are disabled, a video task
    // (ephemeral event) is shown, or a modal are being displayed
    if (!ephemeralEvent && !isModalDisplayed && VideoPlayer.isControlsEnabled()) {
      handleHotkeys(event);
    }
  };

  render() {
    const {
      chapter,
      ephemeralEvent,
      error,
      goToNextChapter,
      hasVideoEnded,
      isEdgeBrowser,
      isLastChapter,
      isMobileDevice,
      isSendingEndBookmark,
      isVideoPlayable,
      isVideoPlayerInitialized,
      nextChapterTitle,
      persistentEvent,
    } = this.props;

    // Chapter is loading when the video is not yet playable (but not because of an error)
    // or when the end bookmark is being sent for the last chapter
    const isLoading = (!isVideoPlayable && !error) || (isLastChapter && isSendingEndBookmark);

    return (
      <DeviceOrientationObserver>
        <VideoChapter
          currentChapterId={chapter.id}
          ephemeralEvent={ephemeralEvent}
          error={error}
          hasVideoEnded={hasVideoEnded}
          isLoading={isLoading}
          isMobileDevice={isMobileDevice}
          isSendingEndBookmark={isSendingEndBookmark}
          isVideoPlayerInitialized={isVideoPlayerInitialized}
          nextChapterTitle={nextChapterTitle}
          onChapterEnd={goToNextChapter}
          onRetryError={this.handleRetryError}
          persistentEvent={persistentEvent}
          shouldShowOverlayAnimation={!isEdgeBrowser}
          videoRef={this.videoNode}
        />
      </DeviceOrientationObserver>
    );
  }
}

VideoChapterContainer.propTypes = {
  chapter: chapterType.isRequired,
  dispatch: PropTypes.func.isRequired,
  ephemeralEvent: ephemeralEventType,
  error: errorType,
  goToNextChapter: PropTypes.func.isRequired,
  hasVideoEnded: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isEdgeBrowser: PropTypes.bool.isRequired,
  isIOS: PropTypes.bool.isRequired,
  isLastChapter: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isModalDisplayed: PropTypes.bool.isRequired,
  isSendingEndBookmark: PropTypes.bool.isRequired,
  isSettingsMenuOpen: PropTypes.bool.isRequired,
  isVideoPlayable: PropTypes.bool.isRequired,
  isVideoPlayerInitialized: PropTypes.bool.isRequired,
  loadVideoUserMetadata: PropTypes.func.isRequired,
  nextChapterTitle: PropTypes.string,
  onPlayerTeardown: PropTypes.func.isRequired,
  onRetryError: PropTypes.func.isRequired,
  persistentEvent: persistentEventType,
  saveUserMetadata: PropTypes.func.isRequired,
  sendEndBookmark: PropTypes.func.isRequired,
  setVideoSource: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
};

VideoChapterContainer.defaultProps = {
  ephemeralEvent: null,
  error: null,
  nextChapterTitle: null,
  persistentEvent: null,
};

const withTranslationVideoChapterContainer = withTranslation('courseViewer')(VideoChapterContainer);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslationVideoChapterContainer);
