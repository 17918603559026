/* eslint-disable @typescript-eslint/default-param-last */

import { combineReducers } from 'redux';

import {
  CHAPTER_COMPLETED,
  FETCH_COURSE,
} from 'store/courses/actions';
import { FETCH_CHILD_CHAPTER } from 'store/chapters/actions';

import { UPDATE_CHAPTER_USER_METADATA } from './actions';

const byCourseId = (state = {}, { payload, type }) => {
  switch (type) {
    case FETCH_COURSE.SUCCESS: {
      const { chapters, courseId } = payload;
      return {
        ...state,
        [courseId]: {
          allIds: Object.keys(chapters),
          byId: { ...chapters },
        },
      };
    }
    case UPDATE_CHAPTER_USER_METADATA: {
      const { chapterId, courseId, userMetadata } = payload;

      return (courseId in state) ? {
        ...state,
        [courseId]: {
          ...state[courseId],
          byId: {
            ...state[courseId].byId,
            [chapterId]: {
              ...state[courseId].byId[chapterId],
              userMetadata: {
                ...state[courseId].byId[chapterId].userMetadata,
                ...userMetadata,
              },
            },
          },
        },
      } : state;
    }
    case CHAPTER_COMPLETED: {
      const { chapterId, courseId } = payload;
      if (!(courseId in state)) return state;

      const chapterIndex = state[courseId].allIds.indexOf(chapterId);
      const nextChapterId = state[courseId].allIds[chapterIndex + 1];

      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          byId: {
            ...state[courseId].byId,
            [chapterId]: {
              ...state[courseId].byId[chapterId],
              userMetadata: {
                ...state[courseId].byId[chapterId].userMetadata,
                isCompleted: true,
              },
            },
            ...(nextChapterId && {
              [nextChapterId]: {
                ...state[courseId].byId[nextChapterId],
                userMetadata: {
                  ...state[courseId].byId[nextChapterId].userMetadata,
                  isStarted: false,
                },
              },
            }),
          },
        },
      };
    }
    case FETCH_CHILD_CHAPTER.SUCCESS: {
      const {
        childChapter,
        courseId,
        parentChapterId,
      } = payload;

      const parentChapterIdIndex = state[courseId].allIds.indexOf(parentChapterId);
      const childChapterId = childChapter.id;

      const allIds = [...state[courseId].allIds];
      allIds.splice(parentChapterIdIndex, 1, childChapterId);

      return {
        ...state,
        [courseId]: {
          allIds,
          byId: {
            ...state[courseId].byId,
            [childChapterId]: childChapter,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byCourseId,
});
