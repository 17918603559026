import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  BottomHelperLabelWrapper,
  TextArea,
} from './style';

const MAX_TEXT_AREA_SCROLL_HEIGHT = '108'; // Roughly 4 lines

const StyledTextArea = React.forwardRef(({
  colorVariant,
  isDisabled,
  isTouchScreen,
  maxLength,
  onChange,
  placeholder,
  shouldShowHelperLabels,
  value,
}, ref) => {
  const { t } = useTranslation('courseViewer');
  const [characterCount, setCharacterCount] = useState(0);

  const handleChange = event => {
    const { target } = event;

    onChange(event);

    if (shouldShowHelperLabels) setCharacterCount(target.value.length);

    // Adjust the textArea height when less than 300px for longer text and
    // set height to auto to act as a reset for scrollHeight when a row is deleted
    if (target.scrollHeight < MAX_TEXT_AREA_SCROLL_HEIGHT) {
      target.style.height = 'auto';
      target.style.height = `${target.scrollHeight}px`;
    } else {
      target.style.overflow = 'auto';
    }
  };

  // Prevent Enter key press from inputting new line
  // and only allow Shift + Enter for multiline text
  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey) event.preventDefault();
  };

  const keystrokeText = isTouchScreen
    ? t('courseViewer:styledTextArea.mobileNewLineKeystroke')
    : t('courseViewer:styledTextArea.desktopNewLineKeystroke');

  return (
    <div>
      <TextArea
        disabled={isDisabled}
        maxLength={maxLength}
        name="content"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        ref={ref}
        rows="1"
        value={value}
        variant={colorVariant}
      />
      {shouldShowHelperLabels && (
        <BottomHelperLabelWrapper variant={colorVariant}>
          <span>{t('courseViewer:styledTextArea.helperText', { keystrokeText })}</span>
          {maxLength && (<span>{characterCount}/{maxLength}</span>)}
        </BottomHelperLabelWrapper>
      )}
    </div>
  );
});

StyledTextArea.propTypes = {
  colorVariant: PropTypes.oneOf(['light', 'dark']),
  isDisabled: PropTypes.bool,
  isTouchScreen: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  shouldShowHelperLabels: PropTypes.bool,
  value: PropTypes.string,
};

StyledTextArea.defaultProps = {
  colorVariant: 'light',
  isDisabled: false,
  isTouchScreen: false,
  maxLength: null,
  shouldShowHelperLabels: false,
  value: '',
};

export default StyledTextArea;
