import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import { useEventListener } from 'lib/customHooks';

import Modal from 'components/shared/Modal';
import SimpleButton from 'components/shared/SimpleButton';

import {
  ContinueButton,
  DialogContent,
  Footer,
  Title,
} from './style';

type CCAResultsDownloadModalProps = {
  onClose: () => void;
};

const CCAResultsDownloadModal = ({
  onClose,
}: CCAResultsDownloadModalProps) => {
  const { t } = useTranslation('courseViewer');
  const { trackEvent } = useTracking({}, {
    dispatchOnMount: () => ({ event: 'CCA Results Download Modal Shown' }),
  });

  useEventListener('afterprint', onClose);

  const handleDownloadClick = () => {
    trackEvent({ event: 'CCA Results Download Button Clicked' });
    window.print();
  };

  return (
    <Modal isRequired shouldHideOnPrint>
      <DialogContent>
        <Title>{t('ccaResults.downloadModal.title')}</Title>
        <p>{t('ccaResults.downloadModal.body')}</p>
        <Footer>
          <SimpleButton full type="button" onClick={handleDownloadClick} variant="brandedPrimary">
            {t('ccaResults.downloadModal.downloadButtonText')}
          </SimpleButton>
          <ContinueButton type="button" onClick={onClose}>
            {t('ccaResults.downloadModal.continueButtonText')}
          </ContinueButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

export default CCAResultsDownloadModal;
