import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;

  ${media.min.tablet`justify-content: center;`}
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${colors.white};
`;

export const Title = styled.h1`
  font-weight: ${type.weight.bold};
  font-size: 1.8rem;
  text-align: center;
  margin: 0;
  color: ${colors.yellow.base};

  ${media.min.tablet`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export const Content = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: ${type.weight.bold};
  font-size: 2rem;
  padding: 0;
  margin: 0 12px;
  user-select: none;
  min-height: 250px;

  ${media.min.tablet`font-size: 2.2rem;`}
  ${media.min.desktop`font-size: 2.4rem;`}
`;

export const ButtonWrapper = styled.div`
  width: 190px;
  opacity: 0;
  transition: opacity 0.5s;
  margin-top: 40px;
  ${p => p.isVisible && 'opacity: 1;'};

  ${media.min.tablet`margin-top: 60px;`}
`;
