/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { withTranslation } from 'react-i18next';

import { normalizeKeyValue } from 'lib/utils';
import {
  deviceTypeType,
  iatBlockType,
  iatKeysConfigType,
} from 'constants/propTypes';
import { MOBILE } from 'constants/deviceTypes';
import {
  BLOCK_INTRO,
  IAT_INTRO,
  TRIAL,
  TUTORIAL_OUTRO,
} from 'constants/iatStates';

import IATIntroView from 'components/shared/IATIntroView';
import IATBlockSummary from 'components/shared/IATBlockSummary';
import IATTrial from 'components/shared/IATTrial';
import IATMobileContainer from 'components/shared/IATMobileContainer';

import IATTutorialBlockSummary from './IATTutorialBlockSummary';
import IATTutorialOutro from './IATTutorialOutro';

export const SUCCESS_VIEW_DELAY_MS = 2000;
const SPACEBAR = ' ';

@track({})
export class IATTutorial extends Component {
  state = {
    currentBlockIndex: 0,
    currentTrialIndex: 0,
    iatError: null,
    iatState: IAT_INTRO,
    success: null,
  };

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.success && this.state.success) {
      setTimeout(() => {
        this.advance();
      }, SUCCESS_VIEW_DELAY_MS);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleIATStart = () => {
    this.setState({ iatState: BLOCK_INTRO });
  };

  @track((_, state) => ({
    blockNumber: state.currentBlockIndex + 1,
    event: 'IAT Tutorial Block Started',
  }))
  handleBlockStart = () => {
    this.props.onBlockStarted();
    this.setState({ iatState: TRIAL });
  };

  handleKeyUp = event => {
    const { iatState } = this.state;
    const { iat, isAnyModalOpen } = this.props;
    const { left, right } = iat.keysConfig;
    if (isAnyModalOpen) return;

    const keyValue = normalizeKeyValue(event.key);

    if (iatState === IAT_INTRO && keyValue === SPACEBAR) {
      event.preventDefault();
      this.handleIATStart();
    } else if (iatState === BLOCK_INTRO && keyValue === SPACEBAR) {
      event.preventDefault();
      this.handleBlockStart();
    } else if (iatState === TRIAL && (keyValue === left || keyValue === right)) {
      event.preventDefault();
      this.handleUserChoice(keyValue);
    } else if (iatState === TUTORIAL_OUTRO && keyValue === SPACEBAR) {
      event.preventDefault();
      this.props.onFinished();
    }
  };

  handleUserChoice = keyPressed => {
    const { currentBlockIndex, currentTrialIndex, success } = this.state;
    const { blocks, keysConfig } = this.props.iat;

    if (success) return;

    const currentBlock = blocks[currentBlockIndex];
    const { correctKey, stimulusType } = currentBlock.trials[currentTrialIndex];

    if (keyPressed === keysConfig[correctKey]) {
      this.setState({
        iatError: null,
        success: {
          correctText: currentBlock[correctKey][stimulusType],
        },
      });
    } else {
      const incorrectKey = correctKey === 'left' ? 'right' : 'left';
      this.setState({
        iatError: {
          correctText: currentBlock[correctKey][stimulusType],
          incorrectKey,
          incorrectText: currentBlock[incorrectKey][stimulusType],
        },
      });
    }
  };

  advance = () => {
    const { currentBlockIndex, currentTrialIndex } = this.state;
    const { blocks } = this.props.iat;
    const currentBlock = blocks[currentBlockIndex];

    // Not the last trial in the current block
    if (currentTrialIndex < currentBlock.trials.length - 1) {
      this.setState(state => ({
        currentTrialIndex: state.currentTrialIndex + 1,
        success: null,
      }));
    } else {
      this.handleBlockCompleted();
    }
  };

  handleBlockCompleted = () => {
    const { currentBlockIndex } = this.state;
    const { iat, onBlockCompleted } = this.props;

    onBlockCompleted();

    // Not the last block in the IAT
    if (currentBlockIndex < iat.blocks.length - 1) {
      this.setState(state => ({
        currentBlockIndex: state.currentBlockIndex + 1,
        currentTrialIndex: 0,
        iatState: BLOCK_INTRO,
        success: null,
      }));
    } else {
      this.setState({ iatState: TUTORIAL_OUTRO });
    }
  };

  render() {
    const {
      deviceType,
      iat,
      onFinished,
      t,
    } = this.props;
    const {
      currentBlockIndex,
      currentTrialIndex,
      iatError,
      iatState,
      success,
    } = this.state;
    const {
      blockIndex,
      left,
      promptText: blockPrompt,
      right,
      trials,
    } = iat.blocks[currentBlockIndex];
    const {
      correctKey,
      promptText: trialPrompt,
      stimulusContent,
      stimulusType,
    } = trials[currentTrialIndex];
    let content;

    if (iatState === TUTORIAL_OUTRO) {
      content = (
        <IATTutorialOutro
          deviceType={deviceType}
          onContinue={onFinished}
        />
      );
    } else if (iatState === IAT_INTRO) {
      content = (
        <IATIntroView
          description={iat.description}
          deviceType={deviceType}
          onContinue={this.handleIATStart}
          shouldCTAFlash
          title={t('courseViewer:iatTutorial.introTitle')}
        />
      );
    } else if (iatState === BLOCK_INTRO) {
      const blockSummaryProps = {
        currentBlock: currentBlockIndex + 1,
        deviceType,
        keysConfig: iat.keysConfig,
        leftCategories: left,
        numOfBlocks: iat.blocks.length,
        onCTATap: this.handleBlockStart,
        promptText: blockPrompt,
        rightCategories: right,
        shouldCTAFlash: true,
      };

      content = blockIndex < 2 ? (
        <IATTutorialBlockSummary
          {...blockSummaryProps}
          leftOptions={iat.uniqueStimuli[Object.values(left)[0]]}
          rightOptions={iat.uniqueStimuli[Object.values(right)[0]]}
        />
      ) : (
        <IATBlockSummary {...blockSummaryProps} />
      );
    } else {
      content = (
        <IATTrial
          correctKey={correctKey}
          deviceType={deviceType}
          error={iatError}
          isFirstBlock={currentBlockIndex === 0}
          keysConfig={iat.keysConfig}
          leftCategories={Object.values(left)}
          onCategoryClick={this.handleUserChoice}
          promptText={trialPrompt}
          rightCategories={Object.values(right)}
          stimulusContent={stimulusContent}
          stimulusType={stimulusType}
          success={success}
        />
      );
    }

    return deviceType === MOBILE ? (
      <IATMobileContainer>
        {content}
      </IATMobileContainer>
    ) : content;
  }
}

IATTutorial.propTypes = {
  deviceType: deviceTypeType.isRequired,
  iat: PropTypes.shape({
    blocks: PropTypes.arrayOf(iatBlockType).isRequired,
    description: PropTypes.string.isRequired,
    keysConfig: iatKeysConfigType.isRequired,
    uniqueStimuli: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string),
    ).isRequired,
  }).isRequired,
  isAnyModalOpen: PropTypes.bool.isRequired,
  onBlockCompleted: PropTypes.func.isRequired,
  onBlockStarted: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('courseViewer')(IATTutorial);
