import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/shared/CloseButton';

import TableOfContents from '../TableOfContents';
import {
  CloseButtonWrapper,
  TOCWrapper,
} from './style';

const TableOfContentsPanel = ({
  isOpen,
  onChapterSelect,
  onClose,
}) => {
  const { t } = useTranslation('common');

  return (
    <>
      {isOpen && (
        <CloseButtonWrapper>
          <CloseButton
            aria-label={t('tableOfContents.close')}
            onClick={onClose}
          />
        </CloseButtonWrapper>
      )}
      <TOCWrapper>
        <TableOfContents onChapterSelect={onChapterSelect} />
      </TOCWrapper>
    </>
  );
};

TableOfContentsPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChapterSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TableOfContentsPanel;
