import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  text-align: center;
  padding: 0 40px;

  ${media.min.baseline`
    max-width: 800px;
    padding: 0;
  `}
`;

export const Message = styled.div`
  font-size: 1.6rem;
  color: ${colors.white};

  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  padding-bottom: 20px;

  ${media.min.baseline`font-size: 2.6rem;`}
`;

export const CTAWrapper = styled.div`
  margin: 50px auto 0;
`;
