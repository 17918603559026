import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { MOBILE } from 'constants/deviceTypes';
import {
  deviceTypeType,
  errorType,
  refType,
  surveyPromptType,
} from 'constants/propTypes';

import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import SimpleMessageButtonView from 'components/shared/SimpleMessageButtonView';

import Survey from 'components/shared/Survey';
import { getTheme } from 'components/shared/Survey/theme';

import Completion from './Completion';
import { ChapterWrapper } from './style';

const SurveyChapter = ({
  chapterTitle,
  chapterWrapperRef,
  currentBlock,
  deviceType,
  error,
  isCompleted,
  isLoading,
  isPostingResponses,
  isUserDataAnonymized,
  onBlockCompleted,
  onContinue,
  onRetryError,
  surveyPrompts,
  surveySubType,
  totalBlocks,
  totalItems,
  totalPrevItems,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  let content = null;

  if (isLoading) {
    content = <Loading />;
  } else if (isUserDataAnonymized) {
    content = (
      <SimpleMessageButtonView
        buttonText={t('continue')}
        title={t('courseViewer:continueToNextChapter.anonymizedResults')}
        onContinue={onContinue}
      />
    );
  } else if (error && !error.isUserInitiated) {
    content = (
      <ErrorView
        onRetryClick={error.retryAction ? onRetryError : null}
        text={error.message}
      />
    );
  } else if (isCompleted) {
    content = (
      <Completion
        onContinue={onContinue}
        surveyTitle={chapterTitle}
      />
    );
  } else if (surveyPrompts) {
    content = (
      <Survey
        currentBlock={currentBlock}
        deviceType={deviceType}
        isPostingResponses={isPostingResponses}
        onBlockCompleted={onBlockCompleted}
        prompts={surveyPrompts}
        surveySubType={surveySubType}
        totalBlocks={totalBlocks}
        totalItems={totalItems}
        totalPrevItems={totalPrevItems}
      />
    );
  }

  return (
    <ThemeProvider theme={getTheme(surveySubType)}>
      <ChapterWrapper ref={chapterWrapperRef} isMobileDevice={deviceType === MOBILE}>
        {content}
      </ChapterWrapper>
    </ThemeProvider>
  );
};

SurveyChapter.propTypes = {
  chapterTitle: PropTypes.string.isRequired,
  chapterWrapperRef: refType,
  currentBlock: PropTypes.number,
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  isCompleted: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  isUserDataAnonymized: PropTypes.bool.isRequired,
  onBlockCompleted: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onRetryError: PropTypes.func.isRequired,
  surveyPrompts: PropTypes.arrayOf(surveyPromptType),
  surveySubType: PropTypes.string.isRequired,
  totalBlocks: PropTypes.number,
  totalItems: PropTypes.number,
  totalPrevItems: PropTypes.number,
};

SurveyChapter.defaultProps = {
  chapterWrapperRef: null,
  currentBlock: null,
  error: null,
  surveyPrompts: null,
  totalBlocks: null,
  totalItems: null,
  totalPrevItems: null,
};

export default SurveyChapter;
