import { createAction } from 'lib/reduxUtils';

// Sync
export const AICC_SESSION_DETECTED = 'aicc/aiccSessionDetected';
export const aiccSessionDetected = createAction(AICC_SESSION_DETECTED);

export const AICC_SESSION_INIT_ERROR = 'aicc/aiccSessionInitError';
export const aiccSessionInitError = createAction(AICC_SESSION_INIT_ERROR, {
  descriptor: 'AICC Session Init Error',
  log: true,
});

export const AICC_SESSION_TERMINATE_ERROR = 'aicc/aiccSessionTerminateError';
export const aiccSessionTerminateError = createAction(AICC_SESSION_TERMINATE_ERROR, {
  descriptor: 'AICC Session Terminate Error',
  log: true,
});

export const AICC_COURSE_STARTED_UPDATE_ERROR = 'aicc/aiccCourseStartedUpdateError';
export const aiccCourseStartedUpdateError = createAction(AICC_COURSE_STARTED_UPDATE_ERROR, {
  descriptor: 'AICC Course Started Update Error',
  log: true,
});

export const AICC_COURSE_COMPLETED_UPDATE_ERROR = 'aicc/aiccCourseCompletedUpdateError';
export const aiccCourseCompletedUpdateError = createAction(AICC_COURSE_COMPLETED_UPDATE_ERROR, {
  descriptor: 'AICC Course Completed Update Error',
  log: true,
});

export const AICC_COURSE_STATUS_SYNC_ERROR = 'aicc/aiccCourseStatusSyncError';
export const aiccCourseStatusSyncError = createAction(AICC_COURSE_STATUS_SYNC_ERROR, {
  descriptor: 'AICC Course Status Sync Error',
  log: true,
});
