import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as PromptType from 'constants/surveyPromptTypes';
import {
  surveyCommentType,
  surveyPromptType,
} from 'constants/propTypes';

import StyledTextArea from 'components/shared/StyledTextArea';
import StyledNumberField from 'components/shared/StyledNumberField';

import { TextInputPromptWrapper } from './style';

const TextInputPrompt = ({
  isActive,
  isDisabled,
  isTouchScreen,
  onChange,
  prompt: {
    characterLimit,
    id,
    promptType,
  },
}) => {
  const { t } = useTranslation('courseViewer');
  const [content, setContent] = useState('');
  const focusRef = React.useRef();

  useLayoutEffect(() => {
    if (!isDisabled && isActive && focusRef.current) focusRef.current.focus();
  }, [isActive]);

  const handleChange = event => {
    const { value } = event.target;

    setContent(value);
    onChange(id, value);
  };

  const isMultiLineInputField = promptType === PromptType.TextArea;

  return (
    <TextInputPromptWrapper>
      {isMultiLineInputField ? (
        <StyledTextArea
          isDisabled={isDisabled}
          isTouchScreen={isTouchScreen}
          maxLength={characterLimit}
          onChange={handleChange}
          placeholder={t('courseViewer:survey.textAreaPlaceholder')}
          ref={focusRef}
          shouldShowHelperLabels
          value={content}
        />
      ) : (
        <StyledNumberField
          isDisabled={isDisabled}
          maxLength={characterLimit}
          name="content"
          onChange={handleChange}
          ref={focusRef}
          value={content}
        />
      )}
    </TextInputPromptWrapper>
  );
};

TextInputPrompt.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isTouchScreen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: PropTypes.oneOfType([surveyPromptType, surveyCommentType]).isRequired,
};

TextInputPrompt.defaultProps = {
  isDisabled: false,
};

export default TextInputPrompt;
