import { refType, surveyMessageType } from 'constants/propTypes';

import {
  BackgroundImage,
  Body,
  Title,
  Wrapper,
} from './style';

const SimpleMessageItem = ({
  innerRef,
  message: {
    imageUrl,
    promptText,
    subPrompts,
    subType,
  },
}) => (
  <>
    {imageUrl && <BackgroundImage imageUrl={imageUrl} />}
    <Wrapper ref={innerRef}>
      <Title subType={subType}>{promptText}</Title>
      {subPrompts?.map(subPrompt => (
        <Body
          key={subPrompt.id}
          subType={subType}
          dangerouslySetInnerHTML={{ __html: subPrompt.promptText }}
        />
      ))}
    </Wrapper>
  </>
);

SimpleMessageItem.propTypes = {
  innerRef: refType,
  message: surveyMessageType.isRequired,
};

SimpleMessageItem.defaultProps = {
  innerRef: null,
};

export default SimpleMessageItem;
