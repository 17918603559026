import styled, { css } from 'styled-components';
import { media, type } from 'styles';

const WIDTH_SCALING_FACTOR = 200;

const getColumnMinWidth = (numOfColumns) => {
  const minWidth = WIDTH_SCALING_FACTOR / Math.sqrt(numOfColumns);
  return `${minWidth.toFixed(0)}px`;
};

export const TableWrapper = styled.div`
  padding: 0 8px;
  width: auto;
  max-width: 1100px;
  margin: 0 auto;

  ${media.min.desktop`width: max-content;`}
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 8px;
  table-layout: fixed;
  margin: 0 auto;
`;

export const ColumnSpacing = styled.col`
  &:first-child {
    ${p => (!p.isLikertScale ? `
      width: auto;
      min-width: 350px;
    ` : css`
      width: 30%;
      ${media.min.tablet`width: 40%;`}
      ${media.min.desktop`width: 50%;`}
    `)}
  }

  ${p => p.isEmptyColumn && 'width: .75%;'}
`;

export const ColumnHeader = styled.th`
  padding: 0 4px 16px 4px;
  text-align: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 400;
  vertical-align: middle;
  min-width: 60px;

  ${media.min.tablet`
    &:not(:first-child) {
      min-width: ${p => getColumnMinWidth(p.numOfColumns)};
    }
  `}

  ${p => p.isNaOption && 'width: 80px;'}
`;

export const EmptyTableCell = styled.td`
  background-color: ${p => p.theme.matrix.empty.background};
`;

export const TableRow = styled.tr`
  background-color: ${p => p.theme.matrix.background};
  transition: background-color 0.2s;

  ${p => p.isActive && `
    &:hover {
      background-color: ${p.theme.matrix.hover.background};
    }
  `}
`;

export const TableCell = styled.td`
  padding: 14px 16px;
  color: ${p => p.theme.matrix.text.color};
  text-align: center;
  vertical-align: middle;
  font-size: 1.4rem;
  ${media.min.desktop`font-size: 1.6rem;`}

  b, strong {
    font-weight: ${type.weight.extraBold};
  }

  &:first-child {
    user-select: none;
    text-align: left;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    ${p => p.isSeparateCell && `
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    `}
  }

  ${p => p.isSeparateCellPresent && `
    &:nth-last-child(3) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `}
`;
