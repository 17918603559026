import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import * as authActions from 'store/auth/actions';
import {
  isAuthLoadingSelector,
  isUserRegistrationConflictErrorSelector,
} from 'store/auth/selectors';
import { isUserLoggedInSelector } from 'store/user/selectors';

import Register from './Register';

const mapStateToProps = state => ({
  isLoading: isAuthLoadingSelector(state),
  isUserLoggedIn: isUserLoggedInSelector(state),
  isUserRegistrationConflictError: isUserRegistrationConflictErrorSelector(state),
});

const mapDispatchToProps = {
  registerUser: authActions.registerUser,
};

export const RegisterContainer = ({
  isLoading,
  isUserLoggedIn,
  isUserRegistrationConflictError,
  location,
  registerUser,
  tracking: { trackEvent },
}) => {
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const [values, setValues] = useState({
    confirmPassword: '',
    loginId: '',
    password: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (queryParams.email) {
      setValues(state => ({
        ...state,
        loginId: queryParams.email,
      }));
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) navigate('/');
  }, [isUserLoggedIn]);

  const checkPasswordsMatch = () => {
    const { confirmPassword, password } = values;

    if (!!confirmPassword && !!password) {
      if (doPasswordsMatch && confirmPassword !== password) {
        setDoPasswordsMatch(false);
      } else if (!doPasswordsMatch && confirmPassword === password) {
        setDoPasswordsMatch(true);
      }
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setValues(state => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { loginId, password } = values;
    trackEvent({ event: 'Register Button Clicked' });

    registerUser({
      loginId: loginId.trim(),
      password,
    });
  };

  return (
    <Register
      doPasswordsMatch={doPasswordsMatch}
      isLoading={isLoading}
      isUserRegistrationConflictError={isUserRegistrationConflictError}
      onChange={handleChange}
      onLoginRedirectClick={() => trackEvent({ event: 'Already Have Account Link Clicked' })}
      onPasswordFieldBlur={checkPasswordsMatch}
      onSubmit={handleSubmit}
      values={values}
    />
  );
};

RegisterContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  registerUser: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

RegisterContainer.defaultProps = {
  location: { search: '' },
  tracking: null,
};

const trackedRegisterContainer = track({
  page: 'Register',
})(RegisterContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedRegisterContainer);
