/* eslint-disable no-param-reassign,sort-keys */

import {
  ChooseOnePrompt,
  isMatrixPrompt,
  MatrixPrompt,
  RawSurveyAnswer,
  SimpleSurveyAnswer,
  SurveyAnswer,
  SurveyPrompt,
  SurveyPromptType,
  SurveySubPrompt,
} from 'types';

import preloadImage from 'lib/preloadImage';
import i18n from 'lib/i18n';

const getAnswerText = (prompt: SurveyPrompt | SurveySubPrompt, answer: boolean | string | null) => {
  let answerText = null;

  if (typeof answer === 'boolean') answerText = i18n.t(`courseViewer:survey.${answer ? 'yesButtonText' : 'noButtonText'}`);
  else {
    const matchingOption = (prompt as ChooseOnePrompt).promptOptions
      .find(option => option.id === answer);
    if (matchingOption) answerText = matchingOption.optionText;
  }

  return answerText;
};

const createSimpleAnswer = (prompt: SurveyPrompt | SurveySubPrompt, answer: RawSurveyAnswer) => {
  const responseText = getAnswerText(prompt, answer.response);
  const correctAnswerText = getAnswerText(prompt, answer.correctAnswer);

  return (responseText !== null && correctAnswerText !== null) ? {
    id: prompt.id,
    promptText: prompt.promptText,
    responseText,
    correctAnswerText,
  } : null;
};

const createMatrixAnswer = (prompt: MatrixPrompt, subPromptAnswers: SimpleSurveyAnswer[]) => ({
  id: prompt.id,
  promptText: prompt.promptText,
  subPrompts: subPromptAnswers,
});

const isSupportedPromptType = (prompt: SurveyPrompt) => (
  prompt.promptType === SurveyPromptType.YesNo
  || prompt.promptType === SurveyPromptType.ChooseOne
  || (isMatrixPrompt(prompt) && prompt.subPromptType === SurveyPromptType.YesNo)
);

const transformSubPromptAnswers = (answers: RawSurveyAnswer[], prompts: SurveySubPrompt[]) => (
  prompts.reduce((acc: SimpleSurveyAnswer[], prompt) => {
    const matchingAnswer = answers.find(answer => answer.id === prompt.id);
    if (matchingAnswer) {
      const transformedAnswer = createSimpleAnswer(prompt, matchingAnswer);
      if (transformedAnswer) acc.push(transformedAnswer);
    }

    return acc;
  }, [])
);

export const transformSurveyAnswers = (answers: RawSurveyAnswer[], prompts: SurveyPrompt[]) => (
  prompts.reduce((acc: SurveyAnswer[], prompt) => {
    if (!isSupportedPromptType(prompt)) return acc;

    if (isMatrixPrompt(prompt)) {
      const subPromptAnswers = transformSubPromptAnswers(
        answers,
        prompt.subPrompts,
      );

      if (subPromptAnswers.length) acc.push(createMatrixAnswer(prompt, subPromptAnswers));
    } else {
      const matchingAnswer = answers.find(answer => answer.id === prompt.id);
      if (matchingAnswer) {
        const transformedAnswer = createSimpleAnswer(prompt, matchingAnswer);
        if (transformedAnswer) acc.push(transformedAnswer);
      }
    }

    return acc;
  }, [])
);

export function preloadImages(prompts: SurveyPrompt[]): Promise<any[]> {
  const imagePromises = prompts.reduce((promises: Promise<any>[], prompt) => {
    if ('imageUrl' in prompt) promises.push(preloadImage(prompt.imageUrl));
    return promises;
  }, []);

  return Promise.all(imagePromises);
}
