/* eslint-disable @typescript-eslint/default-param-last */

import { isUserAutoAnonymizedError, isHttpRequestError, getErrorMessage } from 'lib/errorHandler';

import {
  FETCH_LIKERT_TEST,
  FETCH_LIKERT_TEST_RESULT,
  RESET_LIKERT_STORE,
  SUBMIT_LIKERT_TEST,
} from './actions';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  isPostingResponses: false,
  likertTestData: null,
  likertTestResult: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_LIKERT_STORE:
      return INITIAL_STATE;
    case FETCH_LIKERT_TEST.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: isHttpRequestError(payload) ? action.originalAction : undefined,
        },
        isFetching: false,
      };
    case FETCH_LIKERT_TEST_RESULT.ERROR: {
      const nextState = { ...state, isFetching: false };
      if (isUserAutoAnonymizedError(payload)) return nextState;

      return {
        ...nextState,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: isHttpRequestError(payload) ? action.originalAction : undefined,
        },
      };
    }
    case SUBMIT_LIKERT_TEST.ERROR:
      return {
        ...state,
        isPostingResponses: false,
      };
    case FETCH_LIKERT_TEST.PENDING:
    case FETCH_LIKERT_TEST_RESULT.PENDING:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case SUBMIT_LIKERT_TEST.PENDING:
      return {
        ...state,
        isPostingResponses: true,
      };
    case FETCH_LIKERT_TEST.SUCCESS:
      return {
        ...state,
        isFetching: false,
        likertTestData: payload,
      };
    case FETCH_LIKERT_TEST_RESULT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        likertTestResult: payload,
      };
    case SUBMIT_LIKERT_TEST.SUCCESS:
      return {
        ...state,
        isPostingResponses: false,
        likertTestResult: payload,
      };
    default:
      return state;
  }
};
