import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const Label = styled.label`
  display: block;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  padding: 18px;

  ${media.twoDimMin.baseline`
    font-size: 2rem;
    padding: 20px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 2.2rem;
    padding: 22px;
  `}
`;

export const TextArea = styled.textarea`
  resize: none;
  border: none;
  outline: 0;
  color: ${colors.sierra.base};
  padding: 20px;
  height: 180px;
  font-size: 1.6rem;
  line-height: 2.8rem;
  width: 100%;
  border-top: 1px solid rgba(111, 125, 141, 0.2);

  &::placeholder {
    color: ${colors.cascade.base};
    opacity: 0.5;
  }

  ${media.twoDimMin.desktop`
    font-size: 1.8rem;
    height: 200px;
  `}
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px;
  border-top: 1px solid rgba(111, 125, 141, 0.2);

  ${media.twoDimMin.baseline`padding: 20px;`}
  ${media.twoDimMin.desktop`padding: 22px;`}
`;

export const CharacterCount = styled.div`
  color: ${colors.cascade.base};
  font-size: 1.4rem;
  font-family: PT Mono;
  font-weight: ${type.weight.bold};
  margin-left: 10px;

  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
`;
