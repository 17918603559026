import styled, { keyframes } from 'styled-components';

import { colors, media, type } from 'styles';

export const IATCountdownWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`;

export const CountdownLabel = styled.span`
  font-size: 1.4rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  letter-spacing: 2px;
  text-transform: uppercase;

  ${media.twoDimMin.baseline`font-size: 1.4rem;`}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
`;

const float = keyframes`
  0% {
    transform: translate(-50%,0px);
  }
  50% {
    transform: translate(-50%,-5px);
  }
  100% {
    transform: translate(-50%,0px);
  }
`;

export const TipTextWrapper = styled.div`
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 12px;
  white-space: nowrap;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  padding: 10px 16px;
  animation: ${float} 1.25s linear infinite forwards;

  ${media.twoDimMin.tablet`
    top: -150px;
    padding: 14px 22px;
    border-radius: 16px;
  `}

  ${media.twoDimMin.max`
    top: -150px;
    padding: 16px 24px;
  `}
`;

export const TimerIcon = styled.img`
  width: 18px;
  height: 22.5px;

  ${media.twoDimMin.tablet`
    width: 24px;
    height: 30px;
  `}

  ${media.twoDimMin.max`
    width: 29px;
    height: 35px;
  `}
`;

export const TipText = styled.span`
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.sierra.base};
  margin-left: 10px;

  ${media.twoDimMin.tablet`
    font-size: 1.4rem;
    margin-left: 14px;
  `}

  ${media.twoDimMin.max`font-size: 1.6rem;`}
`;

export const Countdown = styled.span`
  display: block;
  margin-top: 18px;

  position: relative;
  overflow: hidden;
  height: 90px;

  ${media.twoDimMin.tablet`height: 105px;`}
  ${media.twoDimMin.desktop`height: 115px;`}
  ${media.twoDimMin.max`height: 125px;`}
`;

export const Numbers = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  transition: .3s ease transform;
`;

export const NumberText = styled.span`
  display: block;
  font-weight: ${type.weight.bold};
  text-shadow: 10px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 9rem;
  line-height: 90px;

  ${media.twoDimMin.tablet`
    font-size: 10.5rem;
    line-height: 105px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 11.5rem;
    line-height: 115px;
  `}

  ${media.twoDimMin.max`
    font-size: 12.5rem;
    line-height: 125px;
  `}
`;
