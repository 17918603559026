/* eslint-disable react/static-property-placement,react/jsx-props-no-spreading */

import React, { Component } from 'react';
import scrollIntoView from 'scroll-into-view';

import { deviceTypeType } from 'constants/propTypes';
import { DESKTOP, MOBILE } from 'constants/deviceTypes';

const SCROLL_DURATION = 750;
const VERTICAL_PADDING = 100;

const withListScroller = WrappedComponent => (
  class ListScroller extends Component {
    static propTypes = {
      deviceType: deviceTypeType,
    };

    static defaultProps = {
      deviceType: DESKTOP,
    };

    constructor(props) {
      super(props);
      this.itemRefs = [];
    }

    initializeScrollItems = numOfItems => {
      [...Array(numOfItems)].forEach(() => {
        this.itemRefs.push(React.createRef());
      });
    };

    insertScrollItem = index => {
      this.itemRefs.splice(index, 0, React.createRef());
    };

    scrollIntoView = itemIndex => {
      const { deviceType } = this.props;

      const itemHeight = this.itemRefs[itemIndex].current.clientHeight;
      const isItemTallerThanWindow = itemHeight + VERTICAL_PADDING > window.innerHeight;
      let topOffset = 0;

      if (isItemTallerThanWindow) {
        topOffset = itemHeight / 3;
        // We increase the offset below for mobile because due to parent elements in mobile
        // not having overflow set like they do in non-mobile, scrolling behavior is off
        if (deviceType === MOBILE) topOffset -= VERTICAL_PADDING;
      }

      scrollIntoView(this.itemRefs[itemIndex].current, {
        align: {
          top: 0.5,
          topOffset,
        },
        time: SCROLL_DURATION,
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          initializeScrollItems={this.initializeScrollItems}
          insertScrollItem={this.insertScrollItem}
          itemRefs={this.itemRefs}
          scrollIntoView={this.scrollIntoView}
        />
      );
    }
  }
);

export default withListScroller;
