import styled, { css } from 'styled-components';
import forceImportant from 'styles/functions/forceImportant';
import pulsingAnimation from 'styles/animations/pulsing';
import { colors, media, type } from 'styles';

export const variantStyles = {
  brandedPrimary: {
    bgColor: colors.brandedBlue.base,
    bgColorFocus: colors.brandedBlue.focus,
    border: 0,
    color: colors.white,
  },
  outline: {
    bgColor: 'transparent',
    bgColorFocus: 'rgba(255, 255, 255, 0.05)',
    border: `1px solid ${colors.white}`,
    color: colors.white,
  },
  primary: {
    bgColor: colors.blue.base,
    bgColorFocus: colors.blue.focus,
    border: 0,
    color: colors.white,
  },
  red: {
    bgColor: colors.red.base,
    bgColorFocus: colors.red.focus,
    border: 0,
    color: colors.white,
    spinnerColor: colors.white,
  },
  white: {
    bgColor: colors.white,
    bgColorFocus: colors.rocky.muted,
    border: 0,
    color: colors.sierra.base,
  },
};

const oneDimMdStyles = css`
  ${media.min.tablet`
    font-size: 1.6rem;
    padding: 16px 36px;
  `}

  ${media.min.desktop`
    font-size: 1.8rem;
    padding: 18px 40px;
  `}
`;

const twoDimMdStyles = css`
  padding: 12px 36px;
  border-radius: 6px;

  ${media.twoDimMin.tablet`
    font-size: 1.6rem;
    padding: 16px 36px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 1.8rem;
    padding: 18px 40px;
  `}
`;

// Forcing specificity so video-js does not override with their default style,
// see documentation for more details.
export const Button = styled.button`
  ${forceImportant`
    position: relative;
    background-color: ${p => variantStyles[p.variant].bgColor};
    color: ${p => variantStyles[p.variant].color};
    width: ${p => (p.full ? '100%' : 'auto')};
    opacity: ${p => (p.faded ? '0.4' : '1')};
    cursor: ${p => (p.disabled ? 'default' : 'pointer')};
    border: ${p => variantStyles[p.variant].border};
    border-radius: 8px;
    font-weight: ${type.weight.bold};
    outline: none;
    transition: background-color 0.2s;
    ${p => p.pulsing && pulsingAnimation}

    &.focus-visible {
      box-shadow: ${p => p.focusOutlineColor || colors.blue.light} 0px 0px 0px 3px;
      background-color: ${p => variantStyles[p.variant].bgColorFocus};
    }

    &:hover:enabled {
      background-color: ${p => variantStyles[p.variant].bgColorFocus};
    }

    ${p => p.size === 'sm' && `
      font-size: 1.2rem;
      padding: 10px 18px;
    `}

    ${p => p.size === 'md' && css`
      font-size: 1.4rem;
      padding: 14px 28px;

      ${p.responsivenessType === 'twoDim' ? twoDimMdStyles : oneDimMdStyles}
    `}
  `}
`;

export const ButtonText = styled.span`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
