import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import { withTranslation } from 'react-i18next';

import { courseType } from 'constants/propTypes';
import { TABLE_OF_CONTENTS_PANEL } from 'constants/panels';

import {
  currentChapterIdSelector,
  currentChapterNumberSelector,
  currentChapterTitleSelector,
  currentChapterTypeSelector,
  currentCourseSelector,
} from 'store/courses/selectors';
import { isTableOfContentsPanelOpenSelector } from 'store/ui/selectors';
import { isMobileDeviceSelector, isTouchScreenSelector } from 'store/userAgent/selectors';
import { requestHeaderVisibility, toggleOpenPanel } from 'store/ui/actions';
import { handleChapterChanged } from 'store/chapters/actions';

import CourseViewer from './CourseViewer';

const mapStateToProps = state => ({
  course: currentCourseSelector(state),
  currentChapterId: currentChapterIdSelector(state),
  currentChapterNumber: currentChapterNumberSelector(state),
  currentChapterTitle: currentChapterTitleSelector(state),
  currentChapterType: currentChapterTypeSelector(state),
  isMobileDevice: isMobileDeviceSelector(state),
  isTableOfContentsPanelOpen: isTableOfContentsPanelOpenSelector(state),
  isTouchScreen: isTouchScreenSelector(state),
});

const mapDispatchToProps = dispatch => ({
  chapterChanged: payload => dispatch(handleChapterChanged(payload)),
  onTableOfContentsToggle: () => dispatch(toggleOpenPanel(TABLE_OF_CONTENTS_PANEL)),
  setHeaderVisible: isVisible => dispatch(requestHeaderVisibility(isVisible)),
});

const trackChapterPageView = props => ({
  chapterId: props.currentChapterId,
  chapterType: props.currentChapterType,
  event: 'page-view',
  page: `Chapter ${props.currentChapterNumber}: ${props.currentChapterTitle}`,
});

@track(trackChapterPageView)
export class CourseViewerContainer extends Component {
  componentDidMount() {
    if (this.props.isMobileDevice) this.triggerHeader();
  }

  componentDidUpdate(prevProps) {
    const {
      currentChapterId,
      isMobileDevice,
      tracking: { trackEvent },
    } = this.props;

    if (prevProps.currentChapterId !== currentChapterId) {
      if (isMobileDevice) this.triggerHeader();
      trackEvent(trackChapterPageView(this.props));
    }
  }

  triggerHeader = () => {
    this.props.setHeaderVisible(true);
    setTimeout(() => {
      this.props.setHeaderVisible(false);
    }, 2000);
  };

  handleChapterSelect = chapterId => {
    const { chapterChanged, course, currentChapterId } = this.props;

    if (chapterId !== currentChapterId) {
      chapterChanged({
        chapterId,
        courseId: course.id,
      });
    }
  };

  render() {
    const {
      currentChapterTitle,
      isTableOfContentsPanelOpen,
      isTouchScreen,
      onTableOfContentsToggle,
    } = this.props;

    return (
      <CourseViewer
        currentChapterTitle={currentChapterTitle}
        isTableOfContentsPanelOpen={isTableOfContentsPanelOpen}
        isTouchScreen={isTouchScreen}
        onChapterSelect={this.handleChapterSelect}
        onTableOfContentsToggle={onTableOfContentsToggle}
      />
    );
  }
}

CourseViewerContainer.propTypes = {
  chapterChanged: PropTypes.func.isRequired,
  course: courseType.isRequired,
  currentChapterId: PropTypes.string.isRequired,
  currentChapterTitle: PropTypes.string.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isTableOfContentsPanelOpen: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  onTableOfContentsToggle: PropTypes.func.isRequired,
  setHeaderVisible: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

CourseViewerContainer.defaultProps = {
  tracking: null,
};

const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseViewerContainer);

/**
 * Please refer to comment #1 here:
 * https://www.notion.so/biassync/Codebase-Comments-130f502b2e264e1592548d05e64330ca
 */
export default withTranslation('courseViewer')(wrappedComponent);
