import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';
import backgroundSVG from 'assets/bgtexture.svg';

export const CourseLayoutWrapper = styled.div`
  position: relative;
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  margin: 0 auto;

  ${({ hasLogoFooter }) => hasLogoFooter && 'padding-bottom: 150px;'}
`;

export const Header = styled.div`
  position: relative;
  margin: 0 28px;
  padding-top: 40px;
  padding-bottom: 14px;
  border-bottom: 4px solid rgba(75, 80, 100, 0.5);

  ${media.min.tablet`padding-bottom: 17px;`}

  ${media.min.mobileLandscape`margin: 0 52px;`}
  ${media.min.tablet`padding-top: 40px;`}
`;

export const HomeLogoWrapper = styled.div`
  float: left;
  padding: 4px 10px;

  ${media.min.mobileLandscape`padding: 4px 16px;`}
`;

export const HomeLogoLink = styled(Link)`
  outline: none;

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 9999px;
    background-color: rgba(35, 147, 215, 0.1);
  }
`;

export const BiasSyncLogoWrapper = styled.div`
  width: 60px;
  height: 40px;

  ${media.min.tablet`width: 70px;`}
`;

export const HeaderMenuOptions = styled.div`
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

// ACCOUNT MENU

export const AccountMenuButton = styled(TransparentButton)`
  font-size: 1.6rem;
  font-weight: ${type.weight.bold};
  padding: 12px;
  outline: none;

  &:hover {
    background-color: rgba(35, 147, 215, 0.3);
    border-radius: 9999px;
  }

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 9999px;
    background-color: rgba(35, 147, 215, 0.1);
  }

  &:disabled {
    pointer-events: none;
  }

  ${media.min.tablet`font-size: 1.8rem;`}
`;

export const AccountMenuChevronContainer = styled.div`
  display: inline-block;
  width: 14px;
  height: 10px;
  margin-left: 14px;

  ${media.min.tablet`width: 16px;`}
`;

export const AccountMenuWrapper = styled.div`
  position: absolute;
  width: 250px;
  top: 92px;
  right: -6px;
  z-index: 1;

  ${media.min.tablet`width: 300px;`}
  ${media.min.baseline`width: 320px;`}
`;

export const LogoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 24px auto 44px;
  height: 23px;
  width: 100px;

  ${media.min.mobileLandscape`
    margin: 35px auto 55px;
    height: 26px;
    width: 115px;
  `}

  ${media.min.baseline`
    height: 28px;
    width: 133px;
  `}
`;

export const LanguageMenuWrapper = styled.div`
  margin-right: 6px;

  ${media.min.baseline`margin-right: 12px;`}
`;
