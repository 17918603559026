import styled, { css } from 'styled-components';
import { colors, media } from 'styles';

const themeColors = {
  dark: {
    border: colors.sierra.background,
    text: colors.sierra.base,
  },
  light: {
    border: colors.white,
    text: colors.white,
  },
};

export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  border: none;
  background-color: transparent;
  outline: 0;
  padding: 10px 0;
  font-size: 1.4rem;

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}

  ${({ variant }) => css`
    color: ${themeColors[variant].text};
    border-bottom: 2px solid ${themeColors[variant].border};
  `};

  &::placeholder {
    color: ${p => themeColors[p.variant].text};
    opacity: 0.5;
  }

  /* Edge need to be explicit */
  &::-ms-input-placeholder {
    color: ${p => themeColors[p.variant].text};
    opacity: 0.5 !important;
  }
`;

export const BottomHelperLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ variant }) => themeColors[variant].text};
  font-size: 1.1rem;
  margin-top: 8px;

  ${media.min.baseline`
    font-size: 1.2rem
    margin-top: 10px;
  `}
`;
