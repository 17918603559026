import styled from 'styled-components';
import { media } from 'styles';

const PanelDirection = {
  left: {
    side: 'left: 0',
    transform: 'translate(-100%)',
  },
  right: {
    side: 'right: 0',
    transform: 'translate(100%)',
  },
};

export const Panel = styled.aside`
  position: fixed;
  z-index: 10;
  width: 305px;
  height: 100%;
  transition: transform 400ms ease;
  transform: ${p => PanelDirection[p.direction].transform};
  top: 0;
  ${p => PanelDirection[p.direction].side};

  &.slide-out-panel-enter-active,
  &.slide-out-panel-enter-done {
    transform: translate(0);
    box-shadow: 18px 3px 75px rgba(0,0,0,0.3), 18px 3px 75px rgba(0,0,0,0.3);
  }

  ${media.min.mobileLandscape`width: 340px;`}
  ${media.min.tablet`width: 380px;`}
  ${media.min.desktop`width: 440px;`}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 15, 30, 0.4);
  z-index: 9;  
`;
