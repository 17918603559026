import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*
 * This component is used in App.js within the <Router> so that any route change
 * causes the page to automatically scroll to the top.
*/
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
