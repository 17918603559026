import PropTypes from 'prop-types';

import { refType, surveyMessageType } from 'constants/propTypes';
import * as PromptType from 'constants/surveyPromptTypes';

import SurveyItemCTA from '../SurveyItemCTA';
import { MessageSubTypes, SurveySubTypes } from '../constants';

import MultiPageMessageItem from './MultiPageMessageItem';
import SimpleMessageItem from './SimpleMessageItem';

import { MessageWrapper, MultiPageMessageWrapper } from './style';

const MessageItemContainer = ({
  hasKeystroke,
  innerRef,
  isActive,
  isLoading,
  message,
  onContinue,
  surveySubType,
}) => {
  const { buttonLabel, promptType, subType } = message;

  if (subType === MessageSubTypes.MultiPage) {
    return (
      <MultiPageMessageWrapper>
        <MultiPageMessageItem
          innerRef={innerRef}
          message={message}
          onContinue={onContinue}
        />
      </MultiPageMessageWrapper>
    );
  }

  return (
    <MessageWrapper>
      <SimpleMessageItem
        innerRef={innerRef}
        isActive={isActive}
        message={message}
        onContinue={onContinue}
      />
      <SurveyItemCTA
        buttonLabel={buttonLabel}
        hasKeystroke={hasKeystroke}
        isActive={isActive}
        isStyledButton={subType === MessageSubTypes.Title
          && surveySubType === SurveySubTypes.Vignette}
        isLoading={promptType === PromptType.Conclusion
          && isLoading}
        onClick={onContinue}
      />
    </MessageWrapper>
  );
};

MessageItemContainer.propTypes = {
  hasKeystroke: PropTypes.bool.isRequired,
  innerRef: refType,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: surveyMessageType.isRequired,
  onContinue: PropTypes.func.isRequired,
  surveySubType: PropTypes.string.isRequired,
};

MessageItemContainer.defaultProps = {
  innerRef: null,
};

export default MessageItemContainer;
