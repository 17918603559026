import { useTranslation, Trans } from 'react-i18next';

import {
  isMatrixSurveyAnswer,
  SurveyAnswer,
} from 'types';
import SimpleButton from 'components/shared/SimpleButton';

import MatrixAnswerItem from './MatrixAnswerItem';
import SimpleAnswerItem from './SimpleAnswerItem';
import {
  AnswerSection,
  AnswerSectionMessage,
  AnswerList,
  CompletionWrapper,
  CTAWrapper,
  ThankYouMessage,
} from './style';

type CompletionProps = {
  answers: SurveyAnswer[] | null;
  onContinue: () => void;
  surveyTitle: string;
};

const Completion = ({
  answers,
  onContinue,
  surveyTitle,
}: CompletionProps) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  return (
    <CompletionWrapper hasAnswers={!!answers}>
      <ThankYouMessage>
        <Trans i18nKey="courseViewer:survey.completion.thankYouMessage">
          Thank you for completing the <br />{{ surveyTitle }}!
        </Trans>
      </ThankYouMessage>
      {answers && (
        <AnswerSection>
          <AnswerSectionMessage>
            {t('courseViewer:survey.completion.answerSectionMessage')}
          </AnswerSectionMessage>
          <AnswerList>
            {answers.map(answer => (isMatrixSurveyAnswer(answer) ? (
              <MatrixAnswerItem key={answer.id} answer={answer} />
            ) : (
              <SimpleAnswerItem key={answer.id} answer={answer} />
            )))}
          </AnswerList>
        </AnswerSection>
      )}
      <CTAWrapper>
        <SimpleButton onClick={onContinue} variant="white">
          {t('continue')}
        </SimpleButton>
      </CTAWrapper>
    </CompletionWrapper>
  );
};

export default Completion;
