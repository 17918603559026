import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const Paragraph = styled.p`
  text-align: center;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  font-size: 1.6rem;
  max-width: 500px;
  margin: 0;

  &:first-child {
    margin-top: 44px;
    ${media.twoDimMin.tablet`margin-top: 0;`}
  }

  ${media.twoDimMin.tablet`
    font-size: 2.2rem;
    max-width: 700px;
  `}
  ${media.twoDimMin.desktop`
    font-size: 2.4rem;
    max-width: 900px;
  `}
`;

export const Image = styled.img`
  width: 242px;
  height: 125px;
  margin: 24px 0;

  ${media.twoDimMin.tablet`
    width: 485px;
    height: 250px;
    margin: 44px 0;
  `}

  ${media.twoDimMin.desktop`
    width: 581px;
    height: 300px;
  `}
`;
