import styled from 'styled-components';

import { colors } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const ProgressDot = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 1px solid ${colors.ridge.muted};
  color: ${colors.ridge.muted};

  &:not(:first-child) { margin-left: 10px; }

  ${({ status }) => status === 'current' && `background-color: ${colors.ridge.muted};`}
`;
