import styled from 'styled-components';

import backgroundSVG from 'assets/bgtexture.svg';
import colors from 'styles/colors';

export const ChapterWrapper = styled.div`
  background: url(${backgroundSVG}) ${colors.blue.base} center;
  
  /*
    For desktop and tablet, we set the height and overflow so that the background stays fixed.
    For mobile, we don't because we want the page to scroll so that the address and nav bars hide.
  */
  ${({ isMobileDevice }) => !isMobileDevice && `
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch; /* Needed for momentum-based scrolling behavior on tablet */
  `}
`;
