import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ChapterList,
  SectionInfo,
  SectionTitle,
  Wrapper,
} from './style';

const SectionItem = ({
  children,
  sectionNumber,
  sectionTitle,
}) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <SectionInfo>
        {t('tableOfContents.sectionLabel')} {sectionNumber}:
        <SectionTitle>{sectionTitle}</SectionTitle>
      </SectionInfo>
      <ChapterList>
        {children}
      </ChapterList>
    </Wrapper>
  );
};

SectionItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  sectionNumber: PropTypes.number.isRequired,
  sectionTitle: PropTypes.string.isRequired,
};

export default SectionItem;
