/* eslint-disable prefer-destructuring */

import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useConstructor } from 'lib/customHooks';

type VideoPortalProps = {
  children: JSX.Element;
};

const VideoPlayerPortal = ({
  children,
}: VideoPortalProps) => {
  const parentDomNode = useRef<HTMLElement>();
  const domNode = useRef<HTMLElement>();

  useConstructor(() => {
    parentDomNode.current = document.getElementsByClassName('video-js')[0] as HTMLElement;
    domNode.current = document.createElement('div');
    domNode.current.className = 'video-player-portal';
  });

  useEffect(() => {
    if (parentDomNode.current) {
      parentDomNode.current.appendChild(domNode.current!);
    }

    return () => {
      if (parentDomNode.current) parentDomNode.current.removeChild(domNode.current!);
    };
  }, []);

  return createPortal(children, domNode.current!);
};

export default VideoPlayerPortal;
