/* eslint-disable brace-style */

import Debug from 'debug';
import {
  cancel,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import AICC from 'services/AICC';
import { LessonStatus } from 'services/AICC/types';
import PageUnloadChannel from 'lib/PageUnloadChannel';
import i18n from 'lib/i18n';
import * as ToastTypes from 'constants/toastTypes';

import { isUserLoggedInSelector } from 'store/user/selectors';
import { currentCourseSelector } from 'store/courses/selectors';
import {
  aiccCourseCompletedUpdateError,
  aiccCourseStartedUpdateError,
  aiccCourseStatusSyncError,
  aiccSessionDetected,
  aiccSessionInitError,
  aiccSessionTerminateError,
} from 'store/aicc/actions';
import { SEND_CHAPTER_STARTED_BOOKMARK } from 'store/chapters/actions';
import { FETCH_COURSE } from 'store/courses/actions';
import { SET_COURSE_COMPLETED } from 'store/entities/courses/actions';
import {
  LOGIN_WITH_AUTH_CODE,
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  LOGIN_WITH_MAGIC_LINK,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_SSO,
  REGISTER_USER,
  TERMINATE_SESSION,
} from 'store/auth/actions';
import { toggleOpenToast } from 'store/ui/actions';

const debug = Debug('biassync:lms:store:aicc:sagas:aiccSessionManager');

const successfulAuthActions = [
  LOGIN_WITH_AUTH_CODE.SUCCESS,
  LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS,
  LOGIN_WITH_MAGIC_LINK.SUCCESS,
  LOGIN_WITH_PASSWORD.SUCCESS,
  LOGIN_WITH_SSO,
  REGISTER_USER.SUCCESS,
];

export function* handleChapterStarted(aiccClient, { payload }) {
  if (payload.isFirstChapter) {
    try {
      yield aiccClient.updateLessonStatus(LessonStatus.Incomplete);
    } catch (e) {
      debug('Update lesson status to "incomplete" failed', e);
      yield put(aiccCourseStartedUpdateError(e));
    }
  }
}

export function* handleCourseCompleted(aiccClient) {
  try {
    yield aiccClient.updateLessonStatus(LessonStatus.Complete);
  } catch (e) {
    debug('Update lesson status to "complete" failed', e);
    yield put(aiccCourseCompletedUpdateError(e));
  }
}

function getLessonStatusFromCourse({ userMetadata }) {
  let lessonStatus = LessonStatus.NotAttempted;

  if (userMetadata.isCompleted) lessonStatus = LessonStatus.Complete;
  else if (userMetadata.isStarted) lessonStatus = LessonStatus.Incomplete;

  return lessonStatus;
}

export default function* aiccSessionManager(launchParams) {
  debug('AICC session detected with launch params', launchParams);
  yield put(aiccSessionDetected());

  const isUserLoggedIn = yield select(isUserLoggedInSelector);
  if (!isUserLoggedIn) {
    debug('Waiting for user to authenticate themselves before initializing AICC session');
    yield take(successfulAuthActions);
  }

  let currentCourse = yield select(currentCourseSelector);
  if (!currentCourse) {
    debug('Waiting to receive course data before initializing AICC session');

    yield take(FETCH_COURSE.SUCCESS);
    currentCourse = yield select(currentCourseSelector);
  }

  const aiccClient = new AICC(launchParams);

  try {
    yield aiccClient.initSession();
  } catch (e) {
    debug('AICC session initialization failed', e);
    yield put(aiccSessionInitError(e));
    return;
  }

  // Ensure we abide by the rule of always sending at least one PutParam request in a session
  // Also ensure the CMI's lesson status is in sync with ours
  const lessonStatus = getLessonStatusFromCourse(currentCourse);
  try {
    debug('Reconciling course progress status', lessonStatus);
    yield aiccClient.updateLessonStatus(lessonStatus);
  } catch (e) {
    debug('Updating lesson status from user course metadata failed', e);
    yield put(aiccCourseStatusSyncError(e));
  }

  yield takeLatest(
    SEND_CHAPTER_STARTED_BOOKMARK.PENDING,
    handleChapterStarted,
    aiccClient,
  );

  yield takeLatest(
    SET_COURSE_COMPLETED,
    handleCourseCompleted,
    aiccClient,
  );

  const pageUnloadChannel = PageUnloadChannel();
  const { logout } = yield race({
    logout: take(TERMINATE_SESSION),
    pageUnload: take(pageUnloadChannel),
  });

  try {
    yield aiccClient.terminateSession();
  } catch (e) {
    debug('AICC session termination failed', e);
    yield put(aiccSessionTerminateError(e));
  }

  if (logout) {
    yield put(toggleOpenToast({
      message: i18n.t('common:aiccLogoutMessage'),
      type: ToastTypes.TOAST_NOTIFICATION,
    }));
    yield cancel();
  }
}
