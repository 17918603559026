/* eslint-disable jsx-a11y/media-has-caption */

import PropTypes from 'prop-types';

import {
  errorType,
  ephemeralEventType,
  persistentEventType,
  refType,
} from 'constants/propTypes';
import {
  VIDEO_EVENT_TYPE_TASK,
  VIDEO_EVENT_TYPE_OVERLAY,
} from 'constants/videoEvents';

import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import VideoPlayerPortal from 'components/shared/VideoPlayerPortal';

import CustomVideoPlayerComponents from './CustomVideoPlayerComponents';
import EndCard from './EndCard';
import VideoOverlay from './VideoOverlay';
import VideoTask from './VideoTask';
import { ChapterWrapper } from './style';

const VideoChapter = ({
  currentChapterId,
  ephemeralEvent,
  error,
  hasVideoEnded,
  isLoading,
  isMobileDevice,
  isSendingEndBookmark,
  isVideoPlayerInitialized,
  nextChapterTitle,
  onChapterEnd,
  onRetryError,
  persistentEvent,
  shouldShowOverlayAnimation,
  videoRef,
}) => (
  <ChapterWrapper isMobileDevice={isMobileDevice}>
    {isLoading && <Loading />}
    {!!error && (
      <ErrorView
        onRetryClick={error.retryAction ? onRetryError : null}
        text={error.message}
      />
    )}
    {(isVideoPlayerInitialized && ephemeralEvent
      && ephemeralEvent.eventType === VIDEO_EVENT_TYPE_TASK) && (
      <VideoPlayerPortal>
        <VideoTask
          chapterId={currentChapterId}
          taskEvent={ephemeralEvent}
        />
      </VideoPlayerPortal>
    )}
    {(isVideoPlayerInitialized && persistentEvent
      && persistentEvent.eventType === VIDEO_EVENT_TYPE_OVERLAY) && (
      <VideoPlayerPortal>
        <VideoOverlay
          overlayEvent={persistentEvent}
          showAnimation={shouldShowOverlayAnimation}
        />
      </VideoPlayerPortal>
    )}
    {(isVideoPlayerInitialized && hasVideoEnded
      && nextChapterTitle && !persistentEvent) && (
      <VideoPlayerPortal>
        <EndCard
          isLoading={isSendingEndBookmark}
          nextChapterTitle={nextChapterTitle}
          onContinue={onChapterEnd}
        />
      </VideoPlayerPortal>
    )}
    <div data-vjs-player>
      <video ref={videoRef} className="video-js" disablePictureInPicture playsInline />
      {isVideoPlayerInitialized && <CustomVideoPlayerComponents />}
    </div>
  </ChapterWrapper>
);

VideoChapter.propTypes = {
  currentChapterId: PropTypes.string.isRequired,
  ephemeralEvent: ephemeralEventType,
  error: errorType,
  hasVideoEnded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isSendingEndBookmark: PropTypes.bool.isRequired,
  isVideoPlayerInitialized: PropTypes.bool.isRequired,
  nextChapterTitle: PropTypes.string,
  onChapterEnd: PropTypes.func.isRequired,
  onRetryError: PropTypes.func,
  persistentEvent: persistentEventType,
  shouldShowOverlayAnimation: PropTypes.bool.isRequired,
  videoRef: refType.isRequired,
};

VideoChapter.defaultProps = {
  ephemeralEvent: null,
  error: null,
  nextChapterTitle: null,
  onRetryError: null,
  persistentEvent: null,
};

export default VideoChapter;
