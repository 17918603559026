import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AppStorage from 'lib/appStorage';

import { hasUserStartedCourseSelector } from 'store/courses/selectors';

export const TOC_HAS_SHOWN_TOOLTIP_STORAGE_KEY = 'TOC_HAS_SHOWN_TOOLTIP_STORAGE_KEY';
const TOC_TOOLTIP_VISIBILITY_DURATION_MS = 5000;

export const useTOCTooltip = () => {
  const [shouldDisplayTOCTooltip, setShouldDisplayTOCTooltip] = useState(false);
  const hasUserStartedCourse = useSelector(hasUserStartedCourseSelector);

  let toolTipTimeout = null;

  useEffect(() => {
    const hasShownTOCTooltip = AppStorage.getItem(TOC_HAS_SHOWN_TOOLTIP_STORAGE_KEY);

    if (!hasUserStartedCourse && !hasShownTOCTooltip) {
      setShouldDisplayTOCTooltip(true);

      toolTipTimeout = setTimeout(() => {
        setShouldDisplayTOCTooltip(false);

        AppStorage.setItem(TOC_HAS_SHOWN_TOOLTIP_STORAGE_KEY, true);
      }, TOC_TOOLTIP_VISIBILITY_DURATION_MS);
    }

    return () => {
      if (toolTipTimeout) {
        clearTimeout(toolTipTimeout);
        toolTipTimeout = null;
      }
    };
  }, []);

  return shouldDisplayTOCTooltip;
};
