import styled from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';

export const LoadingWrapper = styled.div`
  background: url(${backgroundSVG}) ${colors.rocky.base} center;
  height: 100vh;
`;

export const Title = styled.span`
  display: block;
  font-size: 2.8rem;
  font-weight: ${type.weight.bold};
  margin-bottom: 20px;

  ${media.min.desktop`
    font-size: 3.2rem;
    margin-bottom: 30px;
  `}
`;
