/* eslint-disable @typescript-eslint/default-param-last */

import { combineReducers } from 'redux';

import {
  RESET_VIDEO_TASK,
  SEND_VIDEO_TASK_ANSWER,
} from 'store/video/actions';

import {
  CREATE_VIDEO_EVENT_ENTITIES,
  SET_VIDEO_EVENT_ENTITY_LOADING,
  SET_VIDEO_EVENT_ENTITY_NOT_LOADING,
  UPDATE_VIDEO_EVENT_ENTITY,
} from './actions';
import {
  getEventEntitiesFromVideoMetadataResponse,
} from './logic';

const byCourseId = (state = {}, { payload, type }) => {
  switch (type) {
    case CREATE_VIDEO_EVENT_ENTITIES: {
      const { courseId, data } = payload;
      return {
        ...state,
        [courseId]: {
          ...(state[courseId] ? state[courseId] : {}),
          ...getEventEntitiesFromVideoMetadataResponse(data),
        },
      };
    }
    case SET_VIDEO_EVENT_ENTITY_LOADING: {
      const { courseId, videoEventId } = payload;
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          [videoEventId]: {
            ...state[courseId][videoEventId],
            isLoading: true,
          },
        },
      };
    }
    case SET_VIDEO_EVENT_ENTITY_NOT_LOADING: {
      const { courseId, videoEventId } = payload;
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          [videoEventId]: {
            ...state[courseId][videoEventId],
            isLoading: false,
          },
        },
      };
    }
    case UPDATE_VIDEO_EVENT_ENTITY: {
      const { courseId, data, videoEventId } = payload;
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          [videoEventId]: {
            ...state[courseId][videoEventId],
            ...data,
          },
        },
      };
    }
    case SEND_VIDEO_TASK_ANSWER.SUCCESS:
    case SEND_VIDEO_TASK_ANSWER.ERROR: {
      const { courseId, taskId, taskResponse = null } = payload;
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          [taskId]: {
            ...state[courseId][taskId],
            isSubmitted: true,
            taskResponse,
          },
        },
      };
    }
    case RESET_VIDEO_TASK: {
      const { courseId, taskId } = payload;
      const { taskResponse, ...task } = state[courseId][taskId];
      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          [taskId]: {
            ...task,
            isSubmitted: false,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byCourseId,
});
