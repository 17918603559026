import Debug from 'debug';
import ini from 'ini';
import { AxiosInstance } from 'axios';

import Request from 'lib/request';

import {
  AICCLaunchParam,
  AICCLaunchParams,
  HACPCommand,
  HACPErrorCode,
  HACPRequestKey,
  HACPResponseKey,
  LessonStatus,
  PutParamData,
} from './types';
import { HACPError } from './utils';

const debug = Debug('biassync:lms:services:AICC');

class AICC {
  static readonly CMI_VERSION: string = '4.0';

  private readonly requestClient: AxiosInstance;
  private readonly sessionId: string;

  constructor(launchParams: AICCLaunchParams) {
    this.sessionId = launchParams[AICCLaunchParam.AICCSid];
    this.requestClient = Request.createInstance({
      baseURL: launchParams[AICCLaunchParam.AICCUrl],
    });
  }

  private static parseHACPResponseData(responseData: string) {
    const lines = responseData.split('\n').filter(Boolean);

    return lines.reduce((acc: { [key: string]: string | number }, line) => {
      const [key, val] = line.split(/\s*=\s*(.+)/);
      acc[key] = key === HACPResponseKey.Error ? Number(val) : val;
      return acc;
    }, {});
  }

  private async makeHACPRequest(command: HACPCommand, data: PutParamData | null = null) {
    debug(`Making a ${command} request`, data);

    const params = new URLSearchParams();
    params.append(HACPRequestKey.Command, command);
    params.append(HACPRequestKey.Version, AICC.CMI_VERSION);
    params.append(HACPRequestKey.SessionID, this.sessionId);

    if (data) {
      const encodedData = ini.encode({ Core: data });
      params.append(HACPRequestKey.AICCData, encodedData);
    }

    const response = await this.requestClient.post('', params);
    debug(`${command} response:`, response);

    const parsedResponseData = AICC.parseHACPResponseData(response.data);
    if (parsedResponseData.error !== HACPErrorCode.Successful) {
      throw new HACPError(params, parsedResponseData);
    }

    return parsedResponseData;
  }

  async initSession() {
    debug('Initializing AICC session');
    await this.makeHACPRequest(HACPCommand.GetParam);
  }

  async terminateSession() {
    debug('Terminating AICC session');
    await this.makeHACPRequest(HACPCommand.ExitAU);
  }

  async updateLessonStatus(lessonStatus: LessonStatus) {
    debug(`Updating lesson status to ${lessonStatus}`);
    const data: PutParamData = {
      Lesson_Location: '', // Mandatory to send, optional to use (we track user bookmarking ourselves)
      Lesson_Status: lessonStatus,
      Score: '', // Mandatory to send, optional to use (we don't score users at a course level)
      Time: '', // Mandatory to send, optional to use (we'd prefer to let the CMI track time for itself)
    };

    await this.makeHACPRequest(HACPCommand.PutParam, data);
  }
}

export default AICC;
