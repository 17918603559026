import styled from 'styled-components';
import {
  colors,
  media,
  type,
} from 'styles';

export const Wrapper = styled.li`
  border-top: 1px solid ${colors.rocky.base};
`;

export const SectionInfo = styled.div`
  color: ${colors.blue.base};
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  padding: 12px 24px;
  text-transform: uppercase;

  ${media.min.tablet`padding: 12px 28px;`}
  ${media.min.desktop`font-size: 1.4rem;`}
`;

export const SectionTitle = styled.span`
  text-transform: uppercase;
  margin-left: 4px;
`;

export const ChapterList = styled.ol`
  list-style-type: none;
  padding: 0;
`;
