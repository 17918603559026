import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import * as authActions from 'store/auth/actions';

import LoadingSpinner from 'styles/components/DotSpinner';
import { RedirectWrapper, Message } from './style';

const mapDispatchToProps = {
  resetUserRegistrationConflictError: authActions.resetUserRegistrationConflictError,
};

const REDIRECT_DELAY_MS = 4000;

export const RegistrationConflictRedirect = ({
  message,
  redirectPath,
  resetUserRegistrationConflictError,
}) => {
  const navigate = useNavigate();

  setTimeout(() => {
    resetUserRegistrationConflictError();
    navigate(redirectPath);
  }, REDIRECT_DELAY_MS);

  return (
    <RedirectWrapper>
      <LoadingSpinner />
      <Message>{message}</Message>
    </RedirectWrapper>
  );
};

RegistrationConflictRedirect.propTypes = {
  message: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  resetUserRegistrationConflictError: PropTypes.func.isRequired,
};

const trackedRegistrationConflictRedirect = track({ module: 'RegistrationConflictRedirect' }, {
  dispatchOnMount: () => ({ event: 'Registration Conflict Redirect Shown' }),
})(RegistrationConflictRedirect);

export default connect(
  null,
  mapDispatchToProps,
)(trackedRegistrationConflictRedirect);
