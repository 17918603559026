export enum SurveyPromptType {
  Checkboxes = 'checkboxes',
  ChooseOne = 'choose_one',
  Conclusion = 'conclusion',
  Dropdown = 'dropdown',
  Likert = 'likert',
  Matrix = 'matrix',
  Message = 'message',
  Number = 'number',
  Scale = 'scale',
  Text = 'text',
  TextArea = 'text_area',
  YesNo = 'yes_no',
}
