import PropTypes from 'prop-types';

import { surveyPromptType } from 'constants/propTypes';

import MatrixPrompt from './MatrixPrompt';

const MatrixPromptContainer = ({
  isActive,
  onChange,
  prompt,
  response,
}) => {
  const handleChange = (subItemId, subItemResponse, isKeyboardEvent = false) => {
    const nextResponse = {
      ...(response || false),
      [subItemId]: subItemResponse,
    };

    onChange(prompt.id, nextResponse, isKeyboardEvent);
  };

  return (
    <MatrixPrompt
      isActive={isActive}
      onItemChange={(subPromptId, value) => handleChange(subPromptId, value, true)}
      onItemClick={handleChange}
      prompt={prompt}
      responses={response || {}}
    />
  );
};

MatrixPromptContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ])),
};

MatrixPromptContainer.defaultProps = {
  response: null,
};

export default MatrixPromptContainer;
