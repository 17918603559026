import PropTypes from 'prop-types';

import colors from 'styles/colors';

const ChevronRightIcon = ({ fill }) => (
  <svg width="100%" height="100%" viewBox="0 0 7 11" focusable="false">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M4.442 5.5L0 1.058 1.058 0 6.56 5.5 1.058 11 0 9.942z"
    />
  </svg>
);

ChevronRightIcon.propTypes = {
  fill: PropTypes.string,
};

ChevronRightIcon.defaultProps = {
  fill: colors.cascade.muted,
};

export default ChevronRightIcon;
