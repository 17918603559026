import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const IATCategoryOptionsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: solid 2px ${colors.ridge.base};
  border-radius: 20px;
  padding: 14px 12px;

  ${media.twoDimMin.tablet`padding: 24px 12px;`}
  ${media.twoDimMin.baseline`padding: 28px 18px;`}
  ${media.twoDimMin.desktop`padding: 36px 32px;`}
  ${media.twoDimMin.max`padding: 40px 32px;`}
`;

export const OptionsWrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Subtext = styled.span`
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  color: ${colors.yellow.base};
  margin-top: 14px;
  text-transform: uppercase;

  ${media.twoDimMin.tablet`
    font-size: 1.4rem;
    margin-top: 32px;
  `}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
  ${media.twoDimMin.max`margin-top: 44px;`}
`;

export const CategoryName = styled.span`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  margin-top: 10px;

  ${media.twoDimMin.tablet`
    font-size: 2.6rem;
    margin-top: 32px;
  `}
  ${media.twoDimMin.desktop`font-size: 2.8rem;`}

  ${media.twoDimMin.max`
    margin-top: 44px;
    font-size: 3.2rem;
  `}
`;

// Image Options

export const ImageOptionsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

// Using a div to easily preserve the 1:1 aspect ratio
export const ImageOption = styled.div`
  height: 50px;
  border-radius: 5%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  margin: 4px;

  ${({ columnSpan }) => (columnSpan === 'small' ? `
    width: 50px;
  ` : `
    width: calc((100% / 4) - 8px);
    padding-top: calc((100% / 4) - 8px);
  `)}

  ${media.twoDimMin.tablet`
    ${({ columnSpan }) => (columnSpan === 'small' ? `
      width: calc((100% / 2) - 10px);
      padding-top: calc((100% / 2) - 10px);
    ` : `
      width: calc((100% / 4) - 10px);
      padding-top: calc((100% / 4) - 10px);
    `)}
  `}

  ${media.twoDimMin.baseline`
    margin: 6px;

    ${({ columnSpan }) => (columnSpan === 'small' ? `
      width: calc((100% / 2) - 12px);
      padding-top: calc((100% / 2) - 12px);
    ` : `
      width: calc((100% / 4) - 12px);
      padding-top: calc((100% / 4) - 12px);
    `)}
  `}

  ${media.twoDimMin.desktop`
    margin: 8px;

    ${({ columnSpan }) => (columnSpan === 'small' ? `
      width: calc((100% / 2) - 16px);
      padding-top: calc((100% / 2) - 16px);
    ` : `
      width: calc((100% / 4) - 16px);
      padding-top: calc((100% / 4) - 16px);
    `)}
  `}
`;

// Text Options

export const TextOption = styled.span`
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};

  flex-grow: 1;
  margin: 4px;

  ${media.twoDimMin.tablet`
    font-size: 1.6rem;
    margin: 6px;
  `}
  ${media.twoDimMin.desktop`
    font-size: 1.8rem;
    margin: 8px;
  `}
  ${media.twoDimMin.max`
    font-size: 2rem;
    margin: 10px;
  `}
`;

export const TextOptionsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
`;
