/* eslint-disable sort-keys */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Config from 'config'; // eslint-disable-line

import { i18nInitialized } from 'store/auth/actions';

export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';

const initialize = store => {
  if (store) i18n.on('initialized', () => store.dispatch(i18nInitialized()));

  i18n
    .use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        caches: [],
        lookupLocalStorage: SELECTED_LANGUAGE,
        lookupQuerystring: 'lang',
        order: ['querystring', 'localStorage'],
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for React as it escapes by default
        format: (value, format) => {
          if (format === 'uppercase') return value.toUpperCase();
          return value;
        },
      },
      ns: ['errors'],
      supportedLngs: Config.supportedLanguages,
    });
};

const getCurrentLanguage = () => i18n.language;

const t = (key, options) => i18n.t(key, options);

export default {
  getCurrentLanguage,
  initialize,
  t,
};
