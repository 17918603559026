import PropTypes from 'prop-types';
import { surveyOptionType } from 'constants/propTypes';
import { useTranslation } from 'react-i18next';

import SelectDropdown from 'components/shared/Dropdown/Select';
import CreateDropdown from 'components/shared/Dropdown/Create';

import { Wrapper } from './style';

const DropdownPrompt = ({
  focusRef,
  isCreatableDropdown,
  isDisabled,
  onChange,
  onCreateChange,
  options,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Wrapper>
      {isCreatableDropdown ? (
        <CreateDropdown
          customCreateLabel={t('courseViewer:survey.dropdownPlaceholder.add')}
          isDisabled={isDisabled}
          onChange={onChange}
          onCreateChange={onCreateChange}
          options={options}
          placeholder={t('courseViewer:survey.dropdownPlaceholder.create')}
          ref={focusRef}
        />
      ) : (
        <SelectDropdown
          isDisabled={isDisabled}
          onChange={onChange}
          options={options}
          placeholder={t('courseViewer:survey.dropdownPlaceholder.select')}
          ref={focusRef}
        />
      )}
    </Wrapper>
  );
};

DropdownPrompt.propTypes = {
  focusRef: PropTypes.object,
  isCreatableDropdown: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreateChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(surveyOptionType).isRequired,
};

DropdownPrompt.defaultProps = {
  focusRef: null,
};

export default DropdownPrompt;
