import { createSelector } from 'reselect';

import { VIDEO_EVENT_SUBTYPE_RESULTS } from 'constants/videoEvents';
import { videoEventEntitiesSelector } from 'store/entities/videoEvents/selectors';
import {
  currentChapterIdSelector,
  currentChapterSelector,
} from 'store/courses/selectors';

export const hasVideoEndedSelector = state => state.video.hasEnded;
export const isUserActiveSelector = state => state.video.isUserActive;
export const isVideoPlayableSelector = state => state.video.isPlayable;
export const isVideoPlayerInitializedSelector = state => state.video.isPlayerInitialized;
export const isVideoPlayingSelector = state => state.video.isPlaying;
export const videoMetadataCacheSelector = state => state.video.videoMetadataCache;
export const videoUserMetadataSelector = state => state.video.userMetadata;
export const videoErrorSelector = state => state.video.error;
export const isSendingVideoEndBookmarkSelector = state => state.video.isSendingEndBookmark;
export const isSendingVideoTaskAnswerSelector = state => state.video.isSendingVideoTaskAnswer;

export const currentVideoMetadataSelector = createSelector(
  currentChapterSelector,
  videoMetadataCacheSelector,
  (currentChapter, videoMetadataCache) => (
    currentChapter && videoMetadataCache[currentChapter.id]
  ),
);

export const videoBookmarkSelector = createSelector(
  videoUserMetadataSelector,
  userMetadata => userMetadata && userMetadata.bookmark,
);

export const videoFurthestPointSelector = createSelector(
  videoUserMetadataSelector,
  userMetadata => userMetadata && userMetadata.furthestPoint,
);

// Ephemeral events (video tasks, etc.)

export const currentChapterEphemeralEventIdsSelector = createSelector(
  currentChapterIdSelector,
  videoMetadataCacheSelector,
  (currentChapterId, videoMetadataCache) => (
    currentChapterId
    && (videoMetadataCache[currentChapterId]?.ephemeralEvents || [])
  ),
);

export const currentChapterEphemeralEventsSelector = createSelector(
  currentChapterEphemeralEventIdsSelector,
  videoEventEntitiesSelector,
  (ephemeralEventIds, eventEntities) => (
    ephemeralEventIds.map(eventId => eventEntities[eventId])
  ),
);

const EPHEMERAL_EVENT_STOP_POINT_MARGIN = 1;
export const currentVideoEphemeralEventSelector = createSelector(
  videoBookmarkSelector,
  currentChapterEphemeralEventsSelector,
  isVideoPlayableSelector,
  (videoBookmark, ephemeralEvents, isVideoPlayable) => {
    if (!isVideoPlayable) return null;

    return ephemeralEvents.find(event => (
      videoBookmark >= event.stopPoint
      && videoBookmark < event.stopPoint + EPHEMERAL_EVENT_STOP_POINT_MARGIN
    )) || null;
  },
);

// Persistent events (video overlays, etc.)

export const currentChapterPersistentEventIdsSelector = createSelector(
  currentChapterIdSelector,
  videoMetadataCacheSelector,
  (currentChapterId, videoMetadataCache) => (
    currentChapterId
    && (videoMetadataCache[currentChapterId]?.persistentEvents || [])
  ),
);

export const currentChapterPersistentEventsSelector = createSelector(
  currentChapterPersistentEventIdsSelector,
  videoEventEntitiesSelector,
  (persistentEventIds, eventEntities) => (
    persistentEventIds.map(eventId => eventEntities[eventId])
  ),
);

export const persistentEventResultsToFetch = createSelector(
  currentChapterPersistentEventsSelector,
  persistentEvents => (
    persistentEvents.filter(persistentEvent => (
      persistentEvent.eventSubtype === VIDEO_EVENT_SUBTYPE_RESULTS
      && !persistentEvent.results
    ))
  ),
);

// Only what is "currently" the persistent event based on the bookmark
export const currentVideoPersistentEventSelector = createSelector(
  videoBookmarkSelector,
  currentChapterPersistentEventsSelector,
  (videoBookmark, persistentEvents) => (
    persistentEvents.find(event => {
      const roundedBookmark = Math.ceil(videoBookmark);
      return roundedBookmark >= event.startStopPoint && roundedBookmark < event.endStopPoint
    }) || null
  ),
);
