import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync actions
export const LOGOUT_USER = 'auth/logoutUser';
export const logoutUser = createAction(LOGOUT_USER);

export const RESTORE_SESSION = 'auth/restoreSession';
export const restoreSession = createAction(RESTORE_SESSION);

export const LOGIN_WITH_SSO = 'auth/loginWithSSO';
export const loginWithSSO = createAction(LOGIN_WITH_SSO);

export const TERMINATE_SESSION = 'auth/terminateSession';
export const terminateSession = createAction(TERMINATE_SESSION);

export const RESET_USER_REGISTRATION_CONFLICT_ERROR = 'auth/resetUserRegistrationConflictError';
export const resetUserRegistrationConflictError = createAction(
  RESET_USER_REGISTRATION_CONFLICT_ERROR,
);
export const I18N_INITIALIZED = 'auth/i18nInitialized';
export const i18nInitialized = createAction(I18N_INITIALIZED);

// Async actions
export const INITIALIZE_APP = createAsyncTypes('auth/initializeApp');
export const initializeApp = createAsyncAction(INITIALIZE_APP);

export const FETCH_LOGIN_FLOW_TYPE = createAsyncTypes('auth/fetchLoginFlowType');
export const fetchLoginFlowType = createAsyncAction(FETCH_LOGIN_FLOW_TYPE);

export const FETCH_SSO_URL = createAsyncTypes('auth/fetchSSOUrl');
export const fetchSSOUrl = createAsyncAction(FETCH_SSO_URL);

export const GENERATE_MAGIC_LINK = createAsyncTypes('auth/generateMagicLink');
export const generateMagicLink = createAsyncAction(GENERATE_MAGIC_LINK, {
  log: { PENDING: true },
});

export const LOGIN_WITH_AUTH_CODE = createAsyncTypes('auth/loginWithAuthCode');
export const loginWithAuthCode = createAsyncAction(LOGIN_WITH_AUTH_CODE);

export const LOGIN_WITH_AUTH_CODE_ANONYMOUS = createAsyncTypes('auth/loginWithAuthCodeAnonymous');
export const loginWithAuthCodeAnonymous = createAsyncAction(LOGIN_WITH_AUTH_CODE_ANONYMOUS);

export const LOGIN_WITH_MAGIC_LINK = createAsyncTypes('auth/loginWithMagicLink');
export const loginWithMagicLink = createAsyncAction(LOGIN_WITH_MAGIC_LINK, {
  log: { PENDING: true },
});

export const LOGIN_WITH_PASSWORD = createAsyncTypes('auth/loginWithPassword');
export const loginWithPassword = createAsyncAction(LOGIN_WITH_PASSWORD);

export const REGISTER_USER = createAsyncTypes('auth/registerUser');
export const registerUser = createAsyncAction(REGISTER_USER);

export const SUBMIT_PASSWORD_RECOVERY = createAsyncTypes('auth/submitPasswordRecovery');
export const submitPasswordRecovery = createAsyncAction(SUBMIT_PASSWORD_RECOVERY);

export const RESET_PASSWORD = createAsyncTypes('auth/resetPassword');
export const resetPassword = createAsyncAction(RESET_PASSWORD);

export const REFRESH_ACCESS_TOKEN = createAsyncTypes('auth/refreshAccessToken');
export const refreshAccessToken = createAsyncAction(REFRESH_ACCESS_TOKEN);
