/* eslint-disable @typescript-eslint/default-param-last */

import i18n from 'lib/i18n';

import {
  GENERIC_ERROR_MESSAGE_KEY,
  getErrorMessage,
  getHttpStatusCode,
  isUserRegistrationConflictError,
} from 'lib/errorHandler';

import {
  FETCH_LOGIN_FLOW_TYPE,
  FETCH_SSO_URL,
  GENERATE_MAGIC_LINK,
  INITIALIZE_APP,
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  LOGIN_WITH_AUTH_CODE,
  LOGIN_WITH_MAGIC_LINK,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_SSO,
  REFRESH_ACCESS_TOKEN,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_USER_REGISTRATION_CONFLICT_ERROR,
  RESTORE_SESSION,
  SUBMIT_PASSWORD_RECOVERY,
  TERMINATE_SESSION,
} from './actions';

export const INITIAL_STATE = {
  accessToken: null,
  didGenerateMagicLink: false,
  didResetPassword: false,
  didSubmitPasswordRecovery: false,
  error: null,
  initAppError: null,
  isAppInitializing: false,
  isLoading: false,
  isUserRegistrationConflictError: false,
  loginFlowType: null,
  refreshToken: null,
  ssoUrl: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case TERMINATE_SESSION: {
      const { loginFlowType } = state;

      return loginFlowType ? {
        ...INITIAL_STATE,
        loginFlowType,
      } : {
        ...INITIAL_STATE,
        initAppError: i18n.t(GENERIC_ERROR_MESSAGE_KEY),
      };
    }
    case RESET_USER_REGISTRATION_CONFLICT_ERROR: {
      return {
        ...state,
        isUserRegistrationConflictError: false,
      };
    }
    case FETCH_SSO_URL.PENDING:
    case GENERATE_MAGIC_LINK.PENDING:
    case LOGIN_WITH_AUTH_CODE.PENDING:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.PENDING:
    case LOGIN_WITH_MAGIC_LINK.PENDING:
    case LOGIN_WITH_PASSWORD.PENDING:
    case REGISTER_USER.PENDING:
    case RESET_PASSWORD.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_WITH_AUTH_CODE.SUCCESS:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS:
    case LOGIN_WITH_MAGIC_LINK.SUCCESS:
    case LOGIN_WITH_PASSWORD.SUCCESS:
    case LOGIN_WITH_SSO:
    case REGISTER_USER.SUCCESS:
      return {
        ...state,
        accessToken: payload.accessToken,
        isLoading: false,
        refreshToken: payload.refreshToken,
      };
    case RESTORE_SESSION:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      };
    case REFRESH_ACCESS_TOKEN.SUCCESS:
      return {
        ...state,
        accessToken: payload.accessToken,
      };
    case RESET_PASSWORD.ERROR:
    case FETCH_SSO_URL.ERROR:
    case GENERATE_MAGIC_LINK.ERROR:
    case LOGIN_WITH_AUTH_CODE.ERROR:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.ERROR:
    case LOGIN_WITH_MAGIC_LINK.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_WITH_PASSWORD.ERROR:
    case REGISTER_USER.ERROR:
      return {
        ...state,
        isLoading: false,
        isUserRegistrationConflictError: isUserRegistrationConflictError(payload),
      };
    case INITIALIZE_APP.PENDING:
      return {
        ...state,
        initAppError: null,
        isAppInitializing: true,
      };
    case INITIALIZE_APP.ERROR:
      return {
        ...state,
        initAppError: getErrorMessage(payload),
        isAppInitializing: false,
      };
    case INITIALIZE_APP.SUCCESS:
      return {
        ...state,
        isAppInitializing: false,
      };
    case FETCH_LOGIN_FLOW_TYPE.SUCCESS:
      return {
        ...state,
        loginFlowType: payload,
      };
    case GENERATE_MAGIC_LINK.SUCCESS:
      return {
        ...state,
        didGenerateMagicLink: true,
        isLoading: false,
      };
    case FETCH_SSO_URL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        ssoUrl: payload,
      };
    case RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        didResetPassword: true,
        isLoading: false,
      };
    case SUBMIT_PASSWORD_RECOVERY.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SUBMIT_PASSWORD_RECOVERY.SUCCESS:
      return {
        ...state,
        didSubmitPasswordRecovery: true,
        isLoading: false,
      };
    case SUBMIT_PASSWORD_RECOVERY.ERROR: {
      const statusCode = getHttpStatusCode(payload);
      const nextState = { ...state, isLoading: false };

      if (statusCode === 500 || statusCode === 412) return nextState;

      if (statusCode === 409) nextState.isUserRegistrationConflictError = true;
      else nextState.didSubmitPasswordRecovery = true;

      return nextState;
    }
    default:
      return state;
  }
};
