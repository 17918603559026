import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  COMPLETED_STATUS,
  STARTED_STATUS,
  UNSTARTED_STATUS,
} from 'constants/courseStatusTypes';

import {
  CompanyName,
  CourseContentDivider,
  CourseShortDescription,
  CourseStatus,
  CourseTitle,
  PlaceholderBar,
  PlaceholderWrapper,
} from './style';

const getStatusText = (status, t) => {
  switch (status) {
    case COMPLETED_STATUS:
      return t('courseOverview:status.completed');
    case STARTED_STATUS:
      return t('courseOverview:status.started');
    case UNSTARTED_STATUS:
    default:
      return t('courseOverview:status.unstarted');
  }
};

const LoadingPlaceholder = () => (
  <PlaceholderWrapper>
    <PlaceholderBar />
    <PlaceholderBar />
    <PlaceholderBar />
    <PlaceholderBar />
    <PlaceholderBar />
    <PlaceholderBar />
  </PlaceholderWrapper>
);

const CourseContent = ({
  companyName,
  courseStatus,
  isLoading,
  progressPercentage,
  shortDescription,
  shouldShowCompanyInfo,
  title,
}) => {
  const { t } = useTranslation('courseOverview');

  return isLoading ? (
    <LoadingPlaceholder />
  ) : (
    <>
      <CourseTitle>{title}</CourseTitle>
      <CourseStatus isCompleted={courseStatus === COMPLETED_STATUS}>
        {getStatusText(courseStatus, t)}
        {progressPercentage !== null && (
          <>
            {' '}
            - {progressPercentage}% {t('courseOverview:status.completed')}
          </>
        )}
      </CourseStatus>
      <CourseShortDescription>
        {shortDescription}
      </CourseShortDescription>
      {shouldShowCompanyInfo && (
        <>
          <CourseContentDivider />
          <CompanyName>
            {t('courseOverview:courseFor')} {companyName}
          </CompanyName>
        </>
      )}
    </>
  );
};

CourseContent.propTypes = {
  companyName: PropTypes.string,
  courseStatus: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  progressPercentage: PropTypes.number,
  shortDescription: PropTypes.string,
  shouldShowCompanyInfo: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

CourseContent.defaultProps = {
  companyName: null,
  courseStatus: null,
  progressPercentage: null,
  shortDescription: null,
  title: null,
};

export default CourseContent;
