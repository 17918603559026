import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'styles';

const variantFillColor = {
  black: colors.sierra.base,
  white: colors.white,
  yellow: colors.yellow.base,
};

const AlertIcon = ({ variant }) => (
  <svg width="100%" height="100%" viewBox="0 0 50 50" focusable="false">
    <path
      fill={variantFillColor[variant]}
      fillRule="evenodd"
      d="M25 0C11.238 0 0 11.235 0 25c0 13.762 11.237 25 25 25s25-11.237 25-25S38.763 0 25 0zm3.125 35.938h-5.469v-3.126h5.469v3.126zm0-6.25h-5.469V14.063h5.469v15.624z"
    />
  </svg>
);

AlertIcon.propTypes = {
  variant: PropTypes.oneOf(['white', 'black', 'yellow']),
};

AlertIcon.defaultProps = {
  variant: 'white',
};

export default AlertIcon;
