/* eslint-disable @typescript-eslint/default-param-last */

import { combineReducers } from 'redux';

import { FETCH_COURSE } from 'store/courses/actions';
import { FETCH_CHILD_CHAPTER } from 'store/chapters/actions';

const byCourseId = (state = {}, { payload, type }) => {
  switch (type) {
    case FETCH_COURSE.SUCCESS: {
      const { courseId, sections } = payload;
      return {
        ...state,
        [courseId]: {
          allIds: Object.keys(sections),
          byId: { ...sections },
        },
      };
    }
    case FETCH_CHILD_CHAPTER.SUCCESS: {
      const {
        childChapter,
        courseId,
        parentChapterId,
      } = payload;

      const sectionId = state[courseId].allIds[childChapter.sectionIndex];
      const sectionChapters = [...state[courseId].byId[sectionId].chapters];

      const newChapterId = childChapter.id;
      const parentChapterIdIndex = sectionChapters.indexOf(parentChapterId);
      sectionChapters.splice(parentChapterIdIndex, 1, newChapterId);

      return {
        ...state,
        [courseId]: {
          ...state[courseId],
          byId: {
            ...state[courseId].byId,
            [sectionId]: {
              ...state[courseId].byId[sectionId],
              chapters: sectionChapters,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byCourseId,
});
