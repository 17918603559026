import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import preloadImage from 'lib/preloadImage';

import {
  FETCH_LIKERT_TEST_RESULT,
  fetchLikertTestResult,
} from '../actions';

const debug = Debug('biassync:lms:store:likertTest:sagas:fetchLikertTestResult');

export function* fetchLikertTestResultSaga(action) {
  debug('Saga called');
  const { payload: chapterId } = action;

  try {
    const likertTestResult = yield call(API.getLikertTestResult, chapterId);

    debug('Likert test result received', likertTestResult);

    try {
      yield call(preloadImage, likertTestResult.graphUrl);

      yield put(fetchLikertTestResult.success(likertTestResult));
    } catch (e) {
      debug('Image preloading error', e);
      yield put(fetchLikertTestResult.error(new Error('Invalid or missing likert test result graph url')));
    }
  } catch (e) {
    debug('API error', e);
    yield put(fetchLikertTestResult.error(e, action));
  }
}

export default function* fetchLikertTestResultDefaultSaga() {
  yield takeLatest(FETCH_LIKERT_TEST_RESULT.PENDING, fetchLikertTestResultSaga);
}
