/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import timerIcon from 'assets/timer-icon.svg';

import {
  CountdownLabel,
  IATCountdownWrapper,
  TipTextWrapper,
  TipText,
  TimerIcon,
  Countdown,
  NumberText,
  Numbers,
} from './style';

const IATCountdown = ({
  onDone,
  shouldShowTip,
  totalSeconds,
}) => {
  const { t } = useTranslation('courseViewer');

  const [secondsLeft, setSecondsLeft] = useState(totalSeconds);
  const countdownRef = React.useRef();
  const numbersRef = React.useRef();

  useEffect(() => {
    const animateNumbers = () => {
      const { height } = countdownRef.current.getBoundingClientRect();
      const offset = height * (totalSeconds - secondsLeft);

      numbersRef.current.style.transform = `translateY(-${offset}px)`;
    };

    if (secondsLeft > 0) {
      if (secondsLeft < totalSeconds) animateNumbers();

      setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
    } else {
      onDone();
    }
  }, [secondsLeft]);

  return (
    <IATCountdownWrapper>
      {shouldShowTip && (
        <TipTextWrapper>
          <TimerIcon src={timerIcon} alt="" />
          <TipText>{t('courseViewer:iat.countdownTip')}</TipText>
        </TipTextWrapper>
      )}
      <CountdownLabel>{t('courseViewer:iat.countdownLabel')}</CountdownLabel>
      <Countdown ref={countdownRef}>
        <Numbers ref={numbersRef}>
          {Array(totalSeconds).fill().map((_, index) => (
            <NumberText key={index}>{totalSeconds - index}</NumberText>
          ))}
        </Numbers>
      </Countdown>
    </IATCountdownWrapper>
  );
};

IATCountdown.propTypes = {
  onDone: PropTypes.func.isRequired,
  shouldShowTip: PropTypes.bool,
  totalSeconds: PropTypes.number.isRequired,
};

IATCountdown.defaultProps = {
  shouldShowTip: false,
};

export default IATCountdown;
