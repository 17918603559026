import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { COMPLETED_STATUS, REPLAY_STATUS } from 'constants/courseStatusTypes';

import CourseStatusButton from 'components/shared/CourseStatusButton';

import {
  PreviewCTAButtonWrapper,
  PreviewImage,
  PreviewWrapper,
} from './style';

const CoursePreview = ({
  imageUrl,
  isAccessDisabled,
  isLoading,
  onClick,
  status,
}) => {
  const { t } = useTranslation('courseOverview');

  return (
    <PreviewWrapper
      aria-label={t('courseOverview:previewButtonAriaLabel')}
      isLoading={isLoading}
      onClick={!isAccessDisabled && !isLoading ? onClick : undefined}
      role="button"
    >
      {!isLoading && (
        <>
          <PreviewImage
            isEnabled={!isAccessDisabled}
            role="img"
            url={imageUrl}
          />
          {!isAccessDisabled && (
            <PreviewCTAButtonWrapper>
              <CourseStatusButton
                onClick={onClick}
                status={status === COMPLETED_STATUS ? REPLAY_STATUS : status}
              />
            </PreviewCTAButtonWrapper>
          )}
        </>
      )}
    </PreviewWrapper>
  );
};

CoursePreview.propTypes = {
  imageUrl: PropTypes.string,
  isAccessDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

CoursePreview.defaultProps = {
  imageUrl: '',
};

export default CoursePreview;
