import styled from 'styled-components';
import { media } from 'styles';

export const Wrapper = styled.div`
  position: absolute;
  width: 100px;
  height: 62px;
  bottom: 7rem;
  right: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1.1rem 1.4rem;
  border-radius: 1rem;
  pointer-events: none;

  ${media.twoDimMin.baseline`
    width: 125px;
    height: 77.5px;
    bottom: 10rem;
  `}

  ${media.min.desktop`
    width: 140px;
    height: 86.8px;
  `}

  ${media.min.max`
    width: 161px;
    height: 100px;
  `}
`;
