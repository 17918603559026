import styled from 'styled-components';

import backgroundSVG from 'assets/bgtexture.svg';
import { colors, media } from 'styles';

type ChapterWrapperProps = {
  isMobileDevice?: boolean;
};

export const ChapterWrapper = styled.div<ChapterWrapperProps>`
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 120px 0;
  ${media.print`padding: 0;`}
`;
