/* eslint-disable react/require-default-props */

import ReactFocusLock from 'react-focus-lock';

type FocusTrapProps = {
  children: JSX.Element;
  disabled?: boolean;
};

const FocusTrap = ({
  children,
  disabled = false,
}: FocusTrapProps) => (
  <ReactFocusLock disabled={disabled}>
    {children}
  </ReactFocusLock>
);

export default FocusTrap;
