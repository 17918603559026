import React from 'react';
import PropTypes from 'prop-types';

import colors from 'styles/colors';

const XIcon = ({ fill }) => (
  <svg width="100%" viewBox="0 0 24 24" focusable="false">
    <path
      fill={fill}
      d="M19.363 0l-6.617 6.624a1.08 1.08 0 0 1-1.546 0L4.61 0 0 4.615l6.617 6.625a1.083 1.083 0 0 1 0 1.547L0 19.385 4.61 24l6.617-6.624a1.08 1.08 0 0 1 1.546 0L19.39 24 24 19.385l-6.644-6.625a1.083 1.083 0 0 1 0-1.547l6.617-6.625L19.363 0z"
    />
  </svg>
);

XIcon.propTypes = {
  fill: PropTypes.string,
};

XIcon.defaultProps = {
  fill: colors.yellow.base,
};

export default XIcon;
