import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { likertTestType, refType } from 'constants/propTypes';
import IntersectionObserver from 'components/shared/IntersectionObserver';
import FlowFormTextItem from 'components/shared/FlowFormTextItem';

import LikertItem from './LikertItem';
import {
  IntroItemWrapper,
  ItemList,
  ItemWrapper,
  OutroItemWrapper,
} from './style';

const LikertTest = ({
  activeItemId,
  chapterTitle,
  furthestItemIndex,
  isPostingResponses,
  isTouchScreen,
  itemRefs,
  likertTest: { items },
  onInactiveItemClick,
  onItemFocus,
  onItemRatingChange,
  onStartButtonClick,
  onTestSubmit,
  selectedRatings,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  const introItem = (
    <IntroItemWrapper>
      <IntersectionObserver onEnterViewport={() => onItemFocus('intro')}>
        <FlowFormTextItem
          bodyText={t('courseViewer:likertTest.introBody')}
          buttonText={t('startNow')}
          headerText={chapterTitle}
          isActive={activeItemId === 'intro'}
          isHeaderTextCentered
          keystrokeText={isTouchScreen ? null : t('enter')}
          onButtonClick={onStartButtonClick}
        />
      </IntersectionObserver>
    </IntroItemWrapper>
  );
  const outroItem = (
    <OutroItemWrapper ref={itemRefs[itemRefs.length - 1]}>
      <IntersectionObserver onEnterViewport={() => onItemFocus('outro')}>
        <FlowFormTextItem
          buttonText={t('finish')}
          buttonType="submit"
          headerText={t('courseViewer:likertTest.outroBody')}
          isActive={activeItemId === 'outro'}
          isCTAButtonLoading={isPostingResponses}
          isHeaderTextCentered
          keystrokeText={isTouchScreen ? null : t('enter')}
          onButtonClick={onTestSubmit}
        />
      </IntersectionObserver>
    </OutroItemWrapper>
  );
  const likertItems = items.map((item, idx) => (idx <= furthestItemIndex ? (
    <ItemWrapper key={item.id} ref={itemRefs[idx]}>
      <IntersectionObserver onEnterViewport={() => onItemFocus(item.id)}>
        <LikertItem
          id={item.id}
          isActive={item.id === activeItemId}
          onRatingChange={onItemRatingChange}
          onInactiveClick={onInactiveItemClick}
          selectedRating={selectedRatings[item.id] || ''}
          statement={item.statement}
        />
      </IntersectionObserver>
    </ItemWrapper>
  ) : null))
    .filter(item => !!item);

  return (
    <ItemList>
      <form>
        {introItem}
        {likertItems}
        {(furthestItemIndex === itemRefs.length - 1) && outroItem}
      </form>
    </ItemList>
  );
};

LikertTest.propTypes = {
  activeItemId: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
  furthestItemIndex: PropTypes.number.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  itemRefs: PropTypes.arrayOf(refType).isRequired,
  likertTest: likertTestType.isRequired,
  onInactiveItemClick: PropTypes.func.isRequired,
  onItemFocus: PropTypes.func.isRequired,
  onItemRatingChange: PropTypes.func.isRequired,
  onStartButtonClick: PropTypes.func.isRequired,
  onTestSubmit: PropTypes.func.isRequired,
  selectedRatings: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default LikertTest;
