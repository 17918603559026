import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { likertScale } from 'constants/likertRatings';
import RatingItem from 'components/shared/Survey/RatingItem';

import { colors } from 'styles';
import {
  KeyWrapper,
  LikertScaleWrapper,
  RatingText,
  RatingItemWrapper,
  RatingWrapper,
  Statement,
  LikertItemWrapper,
} from './style';

const theme = {
  ratingItem: {
    checked: {
      background: colors.white,
      color: colors.sierra.base,
    },
    focus: {
      background: 'rgba(255, 255, 255, 0.3)',
      shadow: colors.yellow.base,
    },
    hover: 'rgba(255, 255, 255, 0.3)',
    unchecked: {
      background: 'rgba(255, 255, 255, 0.2)',
      color: colors.white,
    },
  },
};

const LikertItem = ({
  id,
  isActive,
  onInactiveClick,
  onRatingChange,
  selectedRating,
  statement,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);
  const scaleLabels = t('likertScaleLabels', { returnObjects: true });

  return (
    <LikertItemWrapper
      isActive={isActive}
      onClick={!isActive ? () => onInactiveClick(id) : undefined}
    >
      <fieldset>
        <Statement>{statement}</Statement>
        <ThemeProvider theme={theme}>
          <LikertScaleWrapper>
            {likertScale.map((rating, index) => {
              const isSelected = rating.value === selectedRating;

              return (
                <RatingItemWrapper key={rating.value}>
                  <RatingItem
                    id={`${id}_${index + 1}`}
                    isDisabled={!isActive}
                    isSelected={isSelected}
                    name={id}
                    onChange={() => onRatingChange(id, rating.value, false)}
                    onClick={() => onRatingChange(id, rating.value)}
                  >
                    <RatingWrapper>
                      <KeyWrapper isSelected={isSelected}>{`${index + 1}`}</KeyWrapper>
                      <RatingText isSelected={isSelected}>
                        {scaleLabels[index]}
                      </RatingText>
                    </RatingWrapper>
                  </RatingItem>
                </RatingItemWrapper>
              );
            })}
          </LikertScaleWrapper>
        </ThemeProvider>
      </fieldset>
    </LikertItemWrapper>
  );
};

LikertItem.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onInactiveClick: PropTypes.func.isRequired,
  onRatingChange: PropTypes.func.isRequired,
  selectedRating: PropTypes.string.isRequired,
  statement: PropTypes.string.isRequired,
};

export default LikertItem;
