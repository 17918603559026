import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  deviceTypeType,
  iatKeysConfigType,
} from 'constants/propTypes';
import { MOBILE, TABLET } from 'constants/deviceTypes';

import IATProgressIndicator from 'components/shared/IATProgressIndicator';
import IATKeyMappingItem from 'components/shared/IATKeyMappingItem';
import IATSummaryLayout from 'components/shared/IATSummaryLayout';

import {
  KeyMappingSummaries,
  KeyMappingSummaryWrapper,
  ProgressIndicatorWrapper,
} from './style';

const IATBlockSummary = ({
  currentBlock,
  deviceType,
  keysConfig,
  leftCategories,
  numOfBlocks,
  onCTATap,
  promptText,
  rightCategories,
  shouldCTAFlash,
}) => {
  const { t } = useTranslation('courseViewer');
  const isTouchScreen = deviceType === MOBILE || deviceType === TABLET;

  return (
    <IATSummaryLayout
      deviceType={deviceType}
      onCTATap={onCTATap}
      promptText={promptText || t('courseViewer:iat.blockSummaryPrompt')}
      shouldCTAFlash={shouldCTAFlash}
    >
      <KeyMappingSummaries>
        <ProgressIndicatorWrapper>
          <IATProgressIndicator
            currentBlock={currentBlock}
            numOfBlocks={numOfBlocks}
          />
        </ProgressIndicatorWrapper>
        <KeyMappingSummaryWrapper>
          <IATKeyMappingItem
            categories={leftCategories}
            isTouchScreen={isTouchScreen}
            keyValue={isTouchScreen ? t('courseViewer:iat.leftLabel') : keysConfig.left}
          />
        </KeyMappingSummaryWrapper>
        <KeyMappingSummaryWrapper>
          <IATKeyMappingItem
            categories={rightCategories}
            isTouchScreen={isTouchScreen}
            keyValue={isTouchScreen ? t('courseViewer:iat.rightLabel') : keysConfig.right}
          />
        </KeyMappingSummaryWrapper>
      </KeyMappingSummaries>
    </IATSummaryLayout>
  );
};

IATBlockSummary.propTypes = {
  currentBlock: PropTypes.number.isRequired,
  deviceType: deviceTypeType.isRequired,
  keysConfig: iatKeysConfigType.isRequired,
  leftCategories: PropTypes.objectOf(PropTypes.string).isRequired,
  numOfBlocks: PropTypes.number.isRequired,
  onCTATap: PropTypes.func.isRequired,
  promptText: PropTypes.string,
  rightCategories: PropTypes.objectOf(PropTypes.string).isRequired,
  shouldCTAFlash: PropTypes.bool,
};

IATBlockSummary.defaultProps = {
  promptText: null,
  shouldCTAFlash: false,
};

export default IATBlockSummary;
