import { useEventListener } from 'lib/customHooks';

const useModalCloseEventListener = (ref, onClose, isCapture = false) => {
  const handleMouseDown = event => {
    if (ref.current && !ref.current.contains(event.target)) onClose();
  };

  useEventListener('mousedown', handleMouseDown);

  const handleKeyUp = event => {
    event.stopPropagation();

    if (event.key === 'Escape' || event.key === 'Esc') onClose();
  };

  useEventListener('keyup', handleKeyUp, { ...isCapture && { capture: true } });
};

export default useModalCloseEventListener;
