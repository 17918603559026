import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import * as authActions from 'store/auth/actions';

import {
  didResetPasswordSelector,
  didSubmitPasswordRecoverySelector,
  isAuthLoadingSelector,
  isUserRegistrationConflictErrorSelector,
} from 'store/auth/selectors';
import * as uiActions from 'store/ui/actions';

import AuthenticationLayout from 'components/shared/AuthenticationLayout';
import RecoveryEmail from './RecoveryEmail';
import ResetPassword from './ResetPassword';

const mapStateToProps = state => ({
  didResetPassword: didResetPasswordSelector(state),
  didSubmitRecovery: didSubmitPasswordRecoverySelector(state),
  isLoading: isAuthLoadingSelector(state),
  isUserRegistrationConflictError: isUserRegistrationConflictErrorSelector(state),
});

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
  submitPasswordRecovery: authActions.submitPasswordRecovery,
  toggleToast: uiActions.toggleOpenToast,
};

const REDIRECT_DELAY_MS = 5000;

export const ForgotPasswordContainer = ({
  didResetPassword,
  didSubmitRecovery,
  isLoading,
  isUserRegistrationConflictError,
  resetPassword,
  submitPasswordRecovery,
  toggleToast,
}) => {
  const [token, setToken] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const { resetToken } = queryParams;

    if (resetToken) {
      setToken(resetToken);
    }
  }, []);

  useEffect(() => {
    if (didResetPassword) {
      setTimeout(() => {
        navigate('/login');
      }, REDIRECT_DELAY_MS);
    }
  }, [didResetPassword]);

  const allowPasswordReset = !!token;
  return (
    <AuthenticationLayout>
      {allowPasswordReset ? (
        <ResetPassword
          didResetPassword={didResetPassword}
          isLoading={isLoading}
          onSubmit={resetPassword}
          toggleToast={toggleToast}
          token={token}
        />
      ) : (
        <RecoveryEmail
          didSubmit={didSubmitRecovery}
          isLoading={isLoading}
          isUserRegistrationConflictError={isUserRegistrationConflictError}
          onSubmit={submitPasswordRecovery}
          toggleToast={toggleToast}
        />
      )}
    </AuthenticationLayout>
  );
};

ForgotPasswordContainer.propTypes = {
  didResetPassword: PropTypes.bool.isRequired,
  didSubmitRecovery: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  submitPasswordRecovery: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
