import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';

import { tutorialImages } from 'constants/iatTutorial';
import {
  chapterType,
  deviceTypeType,
  errorType,
} from 'constants/propTypes';
import { DESKTOP, TABLET } from 'constants/deviceTypes';
import * as ModalTypes from 'constants/modalTypes';
import preloadImage from 'lib/preloadImage';
import greenCheckIcon from 'assets/big-green-check.svg';
import errorIcon from 'assets/iat-x-icon.svg';

import { sendChapterCompletedBookmark } from 'store/chapters/actions';
import { modalOpened, requestHeaderVisibility } from 'store/ui/actions';
import { uaDeviceTypeSelector } from 'store/userAgent/selectors';
import { isAnyModalOpenSelector } from 'store/ui/selectors';
import {
  isCurrentChapterCompletedSelector,
  isDemoCourseSelector,
} from 'store/courses/selectors';
import {
  accessibilitySkipErrorSelector,
  chaptersErrorSelector,
  isRequestingAccessibilitySkipSelector,
  isSendingCompletedBookmarkSelector,
} from 'store/chapters/selectors';
import { doesUserCompanyAllowSkippingIATSelector } from 'store/user/selectors';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';
import WindowSizeObserver from 'components/shared/WindowSizeObserver';

import IATTutorialChapter from './IATTutorialChapter';

const mapStateToProps = state => ({
  accessibilitySkipError: accessibilitySkipErrorSelector(state),
  bookmarkError: chaptersErrorSelector(state),
  deviceType: uaDeviceTypeSelector(state),
  doesUserCompanyAllowSkippingIAT: doesUserCompanyAllowSkippingIATSelector(state),
  isAnyModalOpen: isAnyModalOpenSelector(state),
  isDemoCourse: isDemoCourseSelector(state),
  isRequestingAccessibilitySkip: isRequestingAccessibilitySkipSelector(state),
  isSendingChapterCompletedBookmark: isSendingCompletedBookmarkSelector(state),
  wasPreviouslyCompleted: isCurrentChapterCompletedSelector(state),
});

const mapDispatchToProps = {
  onRetryError: action => action,
  openModal: modalOpened,
  sendChapterCompletedBookmark,
  setHeaderVisible: requestHeaderVisibility,
};

const preloadImageAssets = () => {
  const imageAssets = [
    greenCheckIcon,
    errorIcon,
    ...tutorialImages,
  ];

  imageAssets.forEach(preloadImage);
};

@track({})
export class IATTutorialChapterContainer extends Component {
  componentDidMount() {
    const {
      chapter,
      deviceType,
      doesUserCompanyAllowSkippingIAT,
      openModal,
      setHeaderVisible,
    } = this.props;

    if (deviceType === TABLET) setHeaderVisible(true);
    if (doesUserCompanyAllowSkippingIAT && !chapter.userMetadata.isCompleted) {
      openModal({ modalType: ModalTypes.SKIP_IAT_NOTIFICATION });
    }

    // Preloading SVG assets used in tutorial (and subsequent IATs) for quicker render later
    preloadImageAssets();
  }

  componentDidUpdate(prevProps) {
    const { bookmarkError, isSendingChapterCompletedBookmark } = this.props;

    if (prevProps.isSendingChapterCompletedBookmark
      && !isSendingChapterCompletedBookmark
      && !bookmarkError
    ) this.props.goToNextChapter();
  }

  componentWillUnmount() {
    const { deviceType, setHeaderVisible } = this.props;
    if (deviceType === TABLET) setHeaderVisible(false);
  }

  handleTutorialBlockStarted = () => {
    const { deviceType, setHeaderVisible } = this.props;
    if (deviceType === TABLET) setHeaderVisible(false);
  };

  handleTutorialBlockCompleted = () => {
    const { deviceType, setHeaderVisible } = this.props;
    if (deviceType === TABLET) setHeaderVisible(true);
  };

  handleTutorialFinished = () => {
    const {
      chapter,
      goToNextChapter,
      wasPreviouslyCompleted,
    } = this.props;

    if (!wasPreviouslyCompleted) {
      this.props.sendChapterCompletedBookmark({
        chapterId: chapter.id,
      });
    } else goToNextChapter();
  };

  @track({ event: 'Skip IAT Button Clicked' })
  handleAccessibilitySkipButtonClick = () => {
    this.props.openModal({
      modalType: ModalTypes.CONFIRM_ACCESSIBILITY_SKIP,
    });
  };

  render() {
    const {
      accessibilitySkipError,
      deviceType,
      isAnyModalOpen,
      isDemoCourse,
      isRequestingAccessibilitySkip,
      isSendingChapterCompletedBookmark,
      onRetryError,
    } = this.props;

    const content = (
      <DeviceOrientationObserver>
        <IATTutorialChapter
          deviceType={deviceType}
          error={accessibilitySkipError}
          isAnyModalOpen={isAnyModalOpen}
          isDemoCourse={isDemoCourse}
          isLoading={isSendingChapterCompletedBookmark || isRequestingAccessibilitySkip}
          onBlockCompleted={this.handleTutorialBlockCompleted}
          onBlockStarted={this.handleTutorialBlockStarted}
          onFinished={this.handleTutorialFinished}
          onRetryError={onRetryError}
          onSkipChapter={this.handleAccessibilitySkipButtonClick}
        />
      </DeviceOrientationObserver>
    );

    return deviceType === DESKTOP ? (
      <WindowSizeObserver>
        {content}
      </WindowSizeObserver>
    ) : content;
  }
}

IATTutorialChapterContainer.propTypes = {
  accessibilitySkipError: errorType,
  bookmarkError: errorType,
  chapter: chapterType.isRequired,
  deviceType: deviceTypeType.isRequired,
  doesUserCompanyAllowSkippingIAT: PropTypes.bool.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  isAnyModalOpen: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isRequestingAccessibilitySkip: PropTypes.bool.isRequired,
  isSendingChapterCompletedBookmark: PropTypes.bool.isRequired,
  onRetryError: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  sendChapterCompletedBookmark: PropTypes.func.isRequired,
  setHeaderVisible: PropTypes.func.isRequired,
  wasPreviouslyCompleted: PropTypes.bool.isRequired,
};

IATTutorialChapterContainer.defaultProps = {
  accessibilitySkipError: null,
  bookmarkError: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IATTutorialChapterContainer);
