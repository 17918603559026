import styled from 'styled-components';

import typeScale from 'styles/functions/typeScale';
import TransparentButton from 'styles/components/TransparentButton';
import { colors, type, media } from 'styles';

export const Wrapper = styled.div`
  position: relative;
`;

export const LanguageMenuButton = styled(TransparentButton)`
  outline: none;
  padding: 3px;
  width: 58px;
  height: 34px;

  ${media.min.baseline`
    width: 64px;
    height: 38px;
  `}

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 10px;
  }
`;

export const IconWrapper = styled.div`
  height: 22px;
  width: 22px;

  ${media.min.baseline`
    width: 24px;
    height: 24px;
  `}
`;

export const LanguageText = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${typeScale('centi')}
  font-weight: ${type.weight.bold};
  user-select: none;
  text-transform: uppercase;
  border-radius: 8px;
  background-color: ${colors.white};
  color: ${colors.sierra.base};

  &:hover { opacity: 0.8; }

  ${media.min.baseline`${typeScale('uno')};`}
`;

export const LanguageMenuWrapper = styled.div`
  position: absolute;
  width: 185px;
  top: 110%;
  right: 0;
  z-index: 3;

  ${media.min.baseline`width: 280px;`}
`;

export const SectionTitle = styled.div`
  ${typeScale('milli')}
  color: ${colors.blue.base};
  font-weight: ${type.weight.extraBold};
  margin: 10px 20px;
  text-transform: uppercase;

  ${media.min.baseline`
    ${typeScale('centi')}
    margin: 12px 20px;
  `}
`;

export const LanguageOptionsWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const CheckmarkIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;

  ${media.min.tablet`
    left: 18%;
    width: 24px;
    height: 24px;
  `}
  ${media.min.baseline`left: 12%;`}
`;

export const OptionWrapper = styled.div`
  position: relative;
  width: 100%;

  &:hover { background-color: ${colors.rocky.background}; }
  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    background-color: ${colors.rocky.background};
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  position: relative;
  font-weight: ${type.weight.semiBold};
  color: ${colors.sierra.base};
  display: flex;
  align-items: center;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
  user-select: ${p => (p.isDisabled ? 'none' : 'auto')};
  outline: none;
  margin-left: 42px;
  padding: 4px 20px;

  ${media.min.tablet`padding: 10px 20px;`}
  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);

  ${media.min.tablet`left: 18%;`}
  ${media.min.baseline`left: 12%;`}
`;
