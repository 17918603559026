import React from 'react';
import PropTypes from 'prop-types';

import { radioButtonThemeType } from 'constants/propTypes';

import {
  Input,
  Label,
  RadioButtonWrapper,
} from './style';

const RadioButton = ({
  ariaLabelledBy,
  children,
  id,
  isChecked,
  isDisabled,
  name,
  onChange,
  onClick,
  theme,
}) => (
  <RadioButtonWrapper hasLabel={!!children}>
    <Input
      aria-labelledby={ariaLabelledBy || undefined}
      checked={isChecked}
      data-testid={id}
      disabled={isDisabled}
      id={id}
      name={name}
      onChange={onChange}
      type="radio"
    />
    <Label
      htmlFor={id}
      isDisabled={isDisabled}
      labelTheme={theme}
      onClick={onClick || onChange}
    >
      {children}
    </Label>
  </RadioButtonWrapper>
);

RadioButton.propTypes = {
  ariaLabelledBy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  theme: radioButtonThemeType.isRequired,
};

RadioButton.defaultProps = {
  ariaLabelledBy: null,
  children: null,
  isDisabled: false,
  onClick: null,
};

export default RadioButton;
