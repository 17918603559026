import React, { useEffect, useState } from 'react';
import track, { TrackingPropType } from 'react-tracking';

import { LEARN_MORE_TRANSITION_MS } from './style';
import IATLearnMore from './IATLearnMore';

export const IATLearnMoreContainer = ({ tracking }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const scrollRef = React.useRef();

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }, LEARN_MORE_TRANSITION_MS);
    }
  }, [isExpanded]);

  const handleLearnMoreToggle = () => {
    if (!isExpanded) tracking.trackEvent({ event: 'Learn More Button Clicked' });
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  return (
    <IATLearnMore
      isExpanded={isExpanded}
      onLearnMoreToggle={handleLearnMoreToggle}
      scrollRef={scrollRef}
    />
  );
};

IATLearnMoreContainer.propTypes = {
  tracking: TrackingPropType,
};

IATLearnMoreContainer.defaultProps = {
  tracking: null,
};

export default track({})(IATLearnMoreContainer);
