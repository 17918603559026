import React, { useState } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';

import PasswordLogin from './PasswordLogin';

export const PasswordLoginContainer = ({
  isLoading,
  onSubmit,
  tracking: { trackEvent },
}) => {
  const [values, setValues] = useState({
    loginId: '',
    password: '',
  });

  const handleChange = event => {
    const { name, value } = event.target;

    setValues(state => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { loginId, password } = values;
    trackEvent({ event: 'Login Button Clicked' });

    onSubmit({
      loginId: loginId.trim(),
      password,
    });
  };

  return (
    <PasswordLogin
      isLoading={isLoading}
      onChange={handleChange}
      onRegisterLinkClick={() => trackEvent({ event: 'Register Link Clicked' })}
      onSubmit={handleSubmit}
      values={values}
    />
  );
};

PasswordLoginContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

PasswordLoginContainer.defaultProps = {
  tracking: null,
};

const trackedPasswordLoginContainer = track({
  page: 'Password Login',
})(PasswordLoginContainer);

export default trackedPasswordLoginContainer;
