import styled from 'styled-components';

import backgroundSVG from 'assets/bgtexture.svg';
import colors from 'styles/colors';

export const ChapterWrapper = styled.div`
  background: url(${backgroundSVG}) ${colors.sierra.base} center;

  /* Overflow is needed to hide any whitespace in desktop/tablet but in mobile will prevent browser address bar from hiding */
  ${({ isMobileDevice }) => !isMobileDevice && `
    overflow: hidden;
  `}
`;
