import { combineReducers } from '@reduxjs/toolkit';

import { TERMINATE_SESSION } from 'store/auth/actions';

import aicc from './aicc/reducer';
import auth from './auth/reducer';
import ccaResults from './ccaResults/reducer';
import chapters from './chapters/reducer';
import courses from './courses/reducer';
import entities from './entities/reducer';
import featureFlags from './featureFlags/reducer';
import fileDownload from './fileDownload/reducer';
import iat from './iat/reducer';
import informationCard from './informationCard/reducer';
import likertTest from './likertTest/reducer';
import survey from './survey/reducer';
import ui from './ui/reducer';
import userDataAnonymization from './userDataAnonymization/reducer';
import user from './user/reducer';
import userAgent from './userAgent/reducer';
import video from './video/reducer';

export const appReducer = combineReducers({
  aicc,
  auth,
  ccaResults,
  chapters,
  courses,
  entities,
  featureFlags,
  fileDownload,
  iat,
  informationCard,
  likertTest,
  survey,
  ui,
  user,
  userAgent,
  userDataAnonymization,
  video,
});

const rootReducer = (state, action) => {
  let updatedState = state;

  if (action.type === TERMINATE_SESSION) {
    updatedState = { auth: state.auth, userAgent: state.userAgent };
  }

  return appReducer(updatedState, action);
};

export default rootReducer;
