import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors, type } from 'styles';
import media, { widthSizes } from 'styles/media';
import truncate from 'styles/mixins/truncate';
import TransparentButton from 'styles/components/TransparentButton';
import fallbackImage from 'assets/biassync-fallback.png';

const shimmer = keyframes`
  0% {background-position: -468px 0;}
  100% {background-position: 468px 0;}
`;

export const CourseOverviewWrapper = styled.div`
  margin-top: 26px;

  ${media.min.desktop`margin-top: 30px;`}
`;

export const Breadcrumbs = styled.div`
  color: ${colors.yellow.background};
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  margin: 0 28px 26px;

  ${media.min.mobileLandscape`margin: 0 52px 30px;`}
  ${media.min.desktop`font-size: 1.6rem;`}

  @media (max-width: ${widthSizes.mobileLandscape / 16}em) {
    ${truncate}
  }
`;

export const BreadcrumbLink = styled(Link)`
  color: ${colors.yellow.background};
  outline: none;

  &.focus-visible {
    border-bottom: 2px solid ${colors.blue.light};
    color: ${colors.yellow.light};
  }

  &:hover {
    color: ${colors.yellow.light};
  }
`;

export const BreadcrumbsChevron = styled.img`
  width: 12px;
  height: 20px;
  margin: 0 8px;
  vertical-align: middle;

  ${media.min.desktop`width: 14px;`}
`;

export const TopContent = styled.div`
  display: flex;
  padding: 0 28px;
  flex-shrink: 0;
  flex-direction: column;

  ${media.min.mobileLandscape`
    flex-direction: row;
    padding: 0 52px;
  `}
`;

export const CoursePreviewWrapper = styled.div`
  ${media.min.mobileLandscape`
    flex-basis: 55%;
    flex-shrink: 0;
  `}
`;

export const BottomContent = styled.div`
  margin-top: 44px;
  padding-bottom: 150px;
  background-color: ${colors.white};
  border-radius: 40px 40px 0 0;

  ${media.min.baseline`margin-top: 64px;`}
  ${media.min.desktop`margin-top: 86px;`}
`;

// COURSE CONTENT

export const CourseContentWrapper = styled.div`
  margin-top: 24px;
  text-align: center;

  ${media.min.mobileLandscape`
    flex-basis: 45%;
    flex-shrink: 0;
  `}

  ${media.min.mobileLandscape`
    margin-left: 30px;
    margin-top: 0;
    text-align: left;
  `}

  ${media.min.baseline`margin-left: 36px;`}

  ${media.min.desktop`
    padding-top: 20px;
    margin-left: 52px;
  `}
`;

export const CourseTitle = styled.div`
  font-weight: ${type.weight.bold};
  font-size: 2.2rem;

  ${media.min.baseline`font-size: 2.6rem;`}
  ${media.min.desktop`font-size: 3rem;`}
  ${media.min.max`font-size: 3.6rem;`}
`;

export const CourseStatus = styled.div`
  margin-top: 14px;
  font-weight: ${type.weight.bold};
  font-size: 1.2rem;
  letter-spacing: 1.17px;
  color: ${({ isCompleted }) => (isCompleted ? colors.green.base : colors.blue.base)};

  ${media.min.baseline`
    font-size: 1.4rem;
    margin-top: 20px;
  `}
  ${media.min.desktop`font-size: 1.6rem;`}
`;

export const CourseShortDescription = styled.div`
  margin-top: 20px;
  font-size: 1.4rem;

  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.9rem;`}
  ${media.min.max`font-size: 2.1rem;`}
`;

export const CourseContentDivider = styled.div`
  width: 41px;
  border: 1px solid ${colors.green.base};
  margin: 22px auto;

  ${media.min.mobileLandscape`
    margin: 22px 0 0;
  `}
  ${media.min.baseline`margin-top: 30px;`}
  ${media.min.desktop`margin-top: 39px;`}
`;

export const CompanyName = styled.div`
  text-transform: uppercase;
  font-weight: ${type.weight.extraBold};
  letter-spacing: 1px;
  margin-top: 24px;
  font-size: 1.1rem;

  ${media.min.baseline`
    margin-top: 30px;
    font-size: 1.3rem;
  `}

  ${media.min.desktop`
    margin-top: 40px;
    font-size: 1.5rem;
  `}

  ${media.min.max`font-size: 1.5rem;`}
`;

export const PlaceholderWrapper = styled.div`
  padding-top: 8px;

  ${media.min.desktop`padding-top: 16px;`}
`;

export const PlaceholderBar = styled.div`
  height: 13px;
  border-radius: 12px;
  background-color: ${colors.ridge.base};
  background-image: linear-gradient(to right, ${colors.ridge.base} 0%, ${colors.cascade.dark} 40%, ${colors.ridge.base} 100%);
  margin: 0 auto;

  &:nth-child(1) {
    width: 70%;
    background-color: ${colors.rocky.base};
    background-image: linear-gradient(to right, ${colors.rocky.base} 0%, ${colors.rocky.background} 40%, ${colors.rocky.base} 100%);
  }
  &:nth-child(2) {
    width: 28%;
    background-color: ${colors.blue.base};
    background-image: linear-gradient(to right, ${colors.blue.base} 0%, ${colors.blue.light} 40%, ${colors.blue.base} 100%);
    margin-top: 24px;
  }
  &:nth-child(3) {
    width: 90%;
    margin-top: 26px;
  }
  &:nth-child(n + 4) {
    width: 90%;
    margin-top: 5px;
  }
  &:last-child { width: 70%; }

  ${media.min.mobileLandscape`margin: 0;`}

  ${media.min.baseline`
    height: 15px;
    &:nth-child(2) {
      width: 22%;
      margin-top: 34px;
    }
    &:nth-child(3) { margin-top: 26px; }
    &:nth-child(n + 4) { margin-top: 7px; }
  `}

  ${media.min.desktop`
    height: 17px;
    &:nth-child(2) { margin-top: 32px; }
    &:nth-child(n + 4) { margin-top: 10px; }
  `}

  ${media.min.max`
    height: 19px;
    &:nth-child(2) { margin-top: 36px; }
    &:nth-child(3) { margin-top: 28px; }
    &:nth-child(n + 4) { margin-top: 11px; }
  `}

  background-repeat: no-repeat;
  background-size: 200px 100%;
  background-position: 0 0;

  animation: ${shimmer} 1s linear infinite forwards;
`;

// COURSE MENU

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: solid 1px ${colors.rocky.base};
  text-align: center;
`;

export const TabButton = styled(TransparentButton)`
  display: inline-block;
  color: ${colors.sierra.base};
  font-size: 1.3rem;
  font-weight: ${type.weight.extraBold};
  letter-spacing: 1px;
  line-height: 60px;
  text-transform: uppercase;
  outline: none;
  padding: 0 30px;

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    background-color: rgba(35, 147, 215, 0.1);
  }

  &:not(:first-child) { margin-left: 30px; }

  ${({ isSelected }) => isSelected && `
    color: ${colors.blue.base};
    border-bottom: 3px solid ${colors.blue.base};
  `}

  ${media.min.mobileLandscape`
    padding: 0 40px;
    font-size: 1.5rem;
    line-height: 73px;
    &:not(:first-child) { margin-left: 86px; }
  `}

  ${media.min.desktop`padding: 0 75px;`}
`;

export const TabContentWrapper = styled.div`
  margin: 0 auto;
  padding: 60px 28px;

  ${media.min.mobileLandscape`padding: 60px;`}

  ${media.min.baseline`
    width: 700px;
    padding: 80px 60px;
  `}
`;

export const TOCWrapper = styled.div`
  border: 1px solid ${colors.rocky.base};
  border-radius: 8px;
  background-color: #fcfcfe;
`;

export const CourseLongDescription = styled.div`
  color: ${colors.sierra.base};
`;

export const CTAButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

// COURSE PREVIEW

export const PreviewWrapper = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  ${({ isLoading }) => isLoading && css`
    background-color: ${colors.black};
    cursor: default;
    background-image: linear-gradient(to right, ${colors.black} 0%, ${colors.sierra.dark} 40%, ${colors.black} 100%);
    animation: ${shimmer} 1s linear infinite forwards;
  `}
`;

export const PreviewImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: opacity .25s;
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'default')};
  opacity: ${({ isEnabled }) => (isEnabled ? '0.8;' : '1')};

  background-position: center;
  background-image: ${p => `url(${p.url}), url(${fallbackImage})`};
  background-size: 112%;

  &:hover { opacity: 1; }
`;

export const PreviewCTAButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: calc(50% - 37px);
  pointer-events: none;
`;
