import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { colors } from 'styles';

const pulse = keyframes`
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  35% {
    opacity: 1;
    transform: scale(1.3);
  }
`;

const AnimatedSVG = styled.svg`
  animation: ${pulse} 1.2s ease-in-out infinite;
`;

const variantFillColor = {
  dark: '#30383c',
  light: colors.white,
};

const LoadingIndicator = ({ variant }) => (
  <AnimatedSVG width="100%" height="100%" viewBox="0 0 120 66" focusable="false">
    <g
      fill="none"
      fillRule="nonzero"
      transform="translate(0 -3)"
    >
      <circle cx="74.229" cy="12.979" r="9.858" fill={variantFillColor[variant]} transform="rotate(-67.5 74.23 12.979)" />
      <path
        fill={variantFillColor[variant]}
        d="M43.923 23.47l5.382 1.408a6.791 6.791 0 0 0-.928-3.68 6.84 6.84 0 1 0-2.445 9.36 6.811 6.811 0 0 0 2.61-2.756l-5.382-1.407c-.59-.153-.896-.933-.683-1.741.207-.806.856-1.337 1.446-1.183zM68.431 43.502a6.84 6.84 0 0 0-1.129-13.634 6.74 6.74 0 0 0-1.6.334 7.714 7.714 0 0 1-1.055 2.508c.102-.159.207-.317.298-.485l3.058 2.59c.011.009.02.023.031.031.315.278.516.681.516 1.132a1.518 1.518 0 0 1-2.601 1.061l-2.945-2.496c.091-.074.176-.156.264-.233a7.85 7.85 0 0 1-2.159 1.368c-.076.51-.1 1.032-.057 1.568a6.837 6.837 0 0 0 7.38 6.256z"
      />
      <path
        fill="#96C93D"
        d="M68.817 21.215a9.929 9.929 0 0 1-2.826-2.825L62.381 22a7.727 7.727 0 0 0-4.256-1.274 7.774 7.774 0 0 0 0 15.546 7.774 7.774 0 0 0 7.773-7.773 7.72 7.72 0 0 0-.848-3.517l3.767-3.768z"
      />
    </g>
  </AnimatedSVG>
);

LoadingIndicator.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

LoadingIndicator.defaultProps = {
  variant: 'dark',
};

export default LoadingIndicator;
