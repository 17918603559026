/* eslint-disable @typescript-eslint/default-param-last */

import {
  getErrorMessage,
  getSendIATBlockErrorMessage,
  isUserAutoAnonymizedError,
} from 'lib/errorHandler';

import {
  FETCH_IAT_RESULT,
  FETCH_IAT,
  RESET_IAT_STORE,
  SEND_IAT_BLOCK_RESULTS,
  SKIP_IAT,
} from './actions';

export const INITIAL_STATE = {
  error: null,
  iatData: null,
  iatResult: null,
  isFetching: false,
  isPostingBlockResponses: false,
  isSubmittingSkipIAT: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_IAT_STORE:
      return INITIAL_STATE;
    case FETCH_IAT.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
        isFetching: false,
      };
    case FETCH_IAT_RESULT.ERROR: {
      const nextState = { ...state, isFetching: false };
      if (isUserAutoAnonymizedError(payload)) return nextState;

      return {
        ...nextState,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
      };
    }
    case FETCH_IAT.PENDING:
    case FETCH_IAT_RESULT.PENDING:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_IAT.SUCCESS:
      return {
        ...state,
        iatData: payload,
        isFetching: false,
      };
    case FETCH_IAT_RESULT.SUCCESS:
      return {
        ...state,
        iatResult: payload,
        isFetching: false,
      };
    case SEND_IAT_BLOCK_RESULTS.PENDING:
      return {
        ...state,
        isPostingBlockResponses: true,
      };
    case SEND_IAT_BLOCK_RESULTS.SUCCESS:
      return {
        ...state,
        isPostingBlockResponses: false,
      };
    case SEND_IAT_BLOCK_RESULTS.ERROR:
      return {
        ...state,
        error: { message: getSendIATBlockErrorMessage(payload) },
        isPostingBlockResponses: false,
      };
    case SKIP_IAT.PENDING:
      return {
        ...state,
        isSubmittingSkipIAT: true,
      };
    case SKIP_IAT.ERROR:
    case SKIP_IAT.SUCCESS:
      return {
        ...state,
        isSubmittingSkipIAT: false,
      };
    default:
      return state;
  }
};
