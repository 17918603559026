/* eslint-disable react/require-default-props */

import LoadingIndicator from 'components/svg/LoadingIndicator';

import {
  Body,
  LoadingIndicatorWrapper,
  Heading,
  LoadingWrapper,
} from './style';

export enum LoadingVariant {
  Dark = 'dark',
  Light = 'light',
}

type LoadingProps = {
  bodyText?: string;
  fillVariant?: LoadingVariant;
  headingText?: string;
};

const Loading = ({
  bodyText,
  fillVariant = LoadingVariant.Light,
  headingText,
}: LoadingProps) => (
  <LoadingWrapper>
    <LoadingIndicatorWrapper role="alert" aria-busy="true">
      <LoadingIndicator variant={fillVariant} />
    </LoadingIndicatorWrapper>
    {!!headingText && <Heading>{headingText}</Heading>}
    {!!bodyText && <Body>{bodyText}</Body>}
  </LoadingWrapper>
);

export default Loading;
