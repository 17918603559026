import styled, { css } from 'styled-components';
import fallbackImage from 'assets/biassync-fallback.png';

import { colors, media, type } from 'styles';

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: ${type.weight.bold};
  margin: 0 0 16px 0;

  ${media.min.baseline`
    margin: 0;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: none;
  justify-content: none;
  margin: 35px 28px 0;

  ${media.min.mobileLandscape`margin: 45px 52px 0;`}
  ${media.min.baseline`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  `}
`;

export const SubTitle = styled.div`
  font-size: 2rem;
  color: ${colors.yellow.base};
  font-weight: ${type.weight.bold};
  margin: 35px 28px 0;

  ${media.min.mobileLandscape`
    font-size: 3rem;
    margin: 45px 52px 0;
  `}
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 375px;
  margin: 0 28px;

  ${media.min.mobileLandscape`margin: 0 52px;`}
`;

export const EmptyText = styled.p`
  font-size: 1.6rem;
  font-weight: ${type.weight.semiBold};
  font-style: italic;
  text-align: center;
  margin: 0;
  max-width: 725px;
  padding: 0 20px;

  ${media.min.mobileLandscape`font-size: 1.8rem;`}
`;

export const GridWrapper = styled.div`
  overflow: hidden;
`;

export const Grid = styled.div`
  /* force equal cell heights */
  display: flex;
  flex-wrap: wrap;

  /* create an equal outer gap */
  padding: 28px;

  /* pull grid cells hard against edges */
  margin: 0 -28px -28px 0;

  ${media.min.mobileLandscape`
    padding: 45px 52px;
    margin: 0 -45px -52px 0;
  `}
`;

export const GridCell = styled.div`
  /* set column count */
  width: calc(100% / 1);

  /* create gaps */
  border: 0 solid transparent;
  border-width: 0 28px 28px 0;

  /* prevent background bleed */
  background-clip: padding-box;

  ${media.min.mobileLandscape`border-width: 0 35px 35px 0;`}
  ${media.min.baseline`width: calc(100% / 2);`}
  ${media.min.desktop`
    ${p => p.numRows === 3 && 'width: calc(100% / 3);'}
    border-width: 0 45px 45px 0;
  `}
`;

// COURSE PREVIEW

export const CoursePreviewWrapper = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const CourseImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: opacity .25s;
  opacity: 0.8;
  cursor: pointer;

  background-position: center;
  background-image: ${p => `url(${p.url}), url(${fallbackImage})`};
  background-size: 112%;

  ${({ isFaded }) => isFaded && 'opacity: 0.4;'}

  &:hover { ${({ isFaded }) => !isFaded && 'opacity: 1;'} }
`;

export const CourseTitle = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 12px;
  font-weight: ${type.weight.bold};
  font-size: 1.6rem;
  color: ${colors.white};

  ${media.min.mobileLandscape`
    padding: 20px;
    font-size: 2.2rem;
  `}

  ${p => css`
    ${media.min.baseline`font-size: ${p.isSmall ? '1.6rem' : '1.8rem'};`}
    ${media.min.desktop`font-size: ${p.isSmall ? '1.6rem' : '2.2rem'};`}
    ${media.min.max`font-size: ${p.isSmall ? '1.8rem' : '2.4rem'};`}
  `}
`;

export const CTAButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: calc(50% - 37px);
  pointer-events: none;
`;
