import { createSelector, createStructuredSelector } from 'reselect';

export const policyTypeSelector = state => (
  state.user.userData.privacyPolicyType
);
export const isDeletingUserSelector = state => state.user.isDeleting;
export const isDownloadingUserSelector = state => state.user.isDownloading;
export const isUpdatingUserLanguageSelector = state => state.user.isUpdatingLanguage;
export const isUpdatingUserSelector = state => state.user.isUpdating;
export const isUserLoggedInSelector = state => !!state.user.userData;
export const userEmailSelector = state => state.user.userData.email;
export const userFirstNameSelector = state => state.user.userData.firstName;
export const userIdSelector = state => state.user.userData.id;
export const userLanguageErrorSelector = state => state.user.languageError;
export const userLanguageCodeSelector = state => state.user.userData?.languageCode ?? null;
export const userLastNameSelector = state => state.user.userData.lastName;
export const userSelector = state => state.user.userData;
export const isSubmittingAccessibilityCheckSelector = state => (
  state.user.isSubmittingAccessibilityCheck
);
export const isSubmittingPolicyConsentSelector = state => (
  state.user.isSubmittingPolicyConsent
);
export const shouldShowMicrosAsOptionalSelector = state => (
  state.user.userData.company.featureFlags.microlearningHeaderOptional || false
);
export const doesUserCompanyAllowSkippingIATSelector = state => (
  state.user.userData.company.featureFlags.allowSkipIat || false
);
export const isDemoCompanySelector = state => (
  state.user.userData?.company.isDemo ?? false
);

export const userFullNameSelector = state => (
  `${state.user.userData.firstName} ${state.user.userData.lastName}`
);

export const userAccountDetailsSelector = createStructuredSelector({
  email: userEmailSelector,
  firstName: userFirstNameSelector,
  lastName: userLastNameSelector,
});

export const isPolicyModalDisplayedSelector = createSelector(
  policyTypeSelector,
  policyType => !!policyType,
);
