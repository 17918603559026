import styled from 'styled-components';
import TransparentButton from 'styles/components/TransparentButton';
import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 15, 30, 0.8);
  height: 100%;
  width: 100%;
  color: ${colors.white};
`;

export const NextChapterTitle = styled.div`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  margin-top: 16px;
  padding: 0 8px;
  text-align: center;

  ${media.twoDimMin.baseline`font-size: 2.6rem;`}
  ${media.min.desktop`font-size: 2.8rem;`}
`;

export const PlayButton = styled(TransparentButton)`
  margin-top: 12px;
  width: 120px;
  height: 120px;
  outline: none;

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 3px;
    border-radius: 9999px;
  }

  ${media.twoDimMin.baseline`
    margin-top: 45px;
    width: 180px;
    height: 180px;
  `}

  ${media.min.desktop`
    margin-top: 62px;
    width: 200px;
    height: 200px;
  `}
`;

export const PressPlayText = styled.div`
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  text-transform: uppercase;

  ${media.twoDimMin.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;
