import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  COMPLETED_STATUS,
  LOCKED_STATUS,
  REPLAY_STATUS,
  STARTED_STATUS,
  UNSTARTED_STATUS,
} from 'constants/courseStatusTypes';

import checkmarkIcon from 'assets/checkmark-icon.svg';
import lockIcon from 'assets/lock-icon.svg';
import playIcon from 'assets/play-icon.svg';
import replayIcon from 'assets/replay-icon.svg';
import StyledButton from 'components/shared/StyledButton';

import { ButtonIcon } from './style';

const getStatusText = (status, t) => {
  switch (status) {
    case COMPLETED_STATUS:
      return t('courseStatusButton.completed');
    case LOCKED_STATUS:
      return t('courseStatusButton.locked');
    case STARTED_STATUS:
      return t('courseStatusButton.started');
    case UNSTARTED_STATUS:
      return t('courseStatusButton.unstarted');
    case REPLAY_STATUS:
      return t('courseStatusButton.replay');
    default:
      return null;
  }
};

const getButtonVariant = status => {
  switch (status) {
    case COMPLETED_STATUS:
      return 'success';
    case LOCKED_STATUS:
      return 'default';
    case STARTED_STATUS:
    case UNSTARTED_STATUS:
    case REPLAY_STATUS:
    default:
      return 'primary';
  }
};

const getButtonIcon = status => {
  switch (status) {
    case COMPLETED_STATUS:
      return <ButtonIcon src={checkmarkIcon} alt="" />;
    case LOCKED_STATUS:
      return <ButtonIcon src={lockIcon} alt="" />;
    case REPLAY_STATUS:
      return <ButtonIcon src={replayIcon} alt="" />;
    case STARTED_STATUS:
    case UNSTARTED_STATUS:
    default:
      return <ButtonIcon src={playIcon} alt="" />;
  }
};

const CourseStatusButton = ({
  isDisabled,
  onClick,
  status,
}) => {
  const { t } = useTranslation('common');

  return (
    <StyledButton
      disabled={isDisabled}
      isFadedWhenDisabled={false}
      onClick={onClick}
      variant={getButtonVariant(status)}
    >
      {getButtonIcon(status)}
      {getStatusText(status, t)}
    </StyledButton>
  );
};

CourseStatusButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

CourseStatusButton.defaultProps = {
  isDisabled: false,
};

export default CourseStatusButton;
