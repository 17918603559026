import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const SurveyIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg id="surveyIcon" aria-labelledby="surveyIconTitle" width="100%" height="100%" viewBox="0 0 26 26" role="img" focusable="false">
      <title id="surveyIconTitle">{t('tableOfContents.chapterType.survey')}</title>
      <g fill={fill} fillRule="nonzero">
        <path
          d="M23.136 0H2.096C.94 0 0 .94 0 2.097v21.525a2.1 2.1 0 0 0 2.097 2.097h21.039a2.1 2.1 0 0 0 2.097-2.097V2.097C25.233.939 24.293 0 23.136 0zm-.42 23.203h-20.2V2.516h20.2v20.687z"
        />
        <path
          d="M20.83 7.335c0 .696-.563 1.258-1.259 1.258H5.661a1.26 1.26 0 0 1-1.258-1.258c0-.692.566-1.259 1.258-1.259h13.91a1.26 1.26 0 0 1 1.258 1.259zM10.417 12.857a1.26 1.26 0 0 1-1.258 1.258H5.66a1.26 1.26 0 0 1 0-2.516H9.16a1.26 1.26 0 0 1 1.258 1.258zM10.417 18.447a1.26 1.26 0 0 1-1.258 1.258H5.66a1.26 1.26 0 0 1-1.258-1.258c0-.692.566-1.258 1.258-1.258H9.16c.692 0 1.258.566 1.258 1.258zM20.486 14.053l-4.366 4.37c-.247.242-.57.369-.89.369-.322 0-.645-.127-.892-.37l-1.678-1.677a1.263 1.263 0 0 1 0-1.782c.491-.491 1.292-.491 1.783 0l.788.788 3.476-3.476a1.257 1.257 0 0 1 1.779 1.778z"
        />
      </g>
    </svg>
  );
};

SurveyIcon.propTypes = {
  fill: PropTypes.string,
};

SurveyIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default SurveyIcon;
