import React from 'react';

import {
  Content,
  SectionContent,
  SectionTitle,
} from './style';

const LearnMoreContent = () => (
  <Content>
    <SectionTitle>What is unconscious bias?</SectionTitle>
    <SectionContent>
      Unconscious biases, also known as hidden biases or implicit biases, are stereotypes about
      certain groups of people that form outside of an individual’s conscious awareness. While
      unconscious biases are universal, they can be especially harmful in the workplace, impacting
      how you engage with colleagues, customers, and even job performance.
    </SectionContent>
    <SectionTitle>
      How is BiasSync able to measure unconscious bias?
    </SectionTitle>
    <SectionContent>
      The Unconscious Bias Assessment uses scientifically-validated research about the ways that
      individuals make unconscious associations. The science and research behind these tests is
      decades-old. The original research was based on the insight that when people associate two
      concepts, they can perform a task more quickly compared to when they don’t hold that
      association. Researchers then showed that the assessment was reliably able to measure
      known biases in a number of areas--for example, that most people see flowers as beautiful
      and insects as icky.
    </SectionContent>
    <SectionContent>
      BiasSync has further developed these tools to improve their quality and workplace relevance
      by measuring how often people automatically associate specific demographics or certain groups
      with common workplace stereotypes. The assessment is based on established and widely
      recognized societal stereotypes. Its purpose is to measure the strength of the association
      in our brains with these existing stereotypes in order to bring these biases to our attention.
    </SectionContent>
    <SectionContent>
      Another way to look at it is that these assessments make us conscious of what’s happening in
      our brains unconsciously. It’s important to know that bias assessments do not measure
      prejudice nor do they create or reinforce stereotypes. They also do not predict someone’s
      behavior. It’s simply an indicator of how your brain may be defaulting to stereotypical
      thinking under certain circumstances which could be influencing how you interact with others,
      assumptions you make about others, and even influence certain types of decisions you make.
    </SectionContent>
    <SectionTitle>Range of emotions related to results.</SectionTitle>
    <SectionContent>
      Uncovering our unconscious biases can be eye-opening. It can also be uncomfortable and even
      confusing. Social psychologists say having a range of emotions - everything from feeling
      guilt, distress, or even anger - is a normal reaction. So if that’s your experience, take a
      deep breath.
    </SectionContent>
    <SectionContent>
      These assessments are <b>not</b> a judgment of who you are as a person. As our experts
      reinforce, your feelings are a reaction and they do not indicate that you’re a bad person. A
      result showing a moderate to strong unconscious bias can be in conflict with how we see
      ourselves or our values and that’s what can lead to a negative reaction.
    </SectionContent>
    <SectionContent>
      First, it’s important to know that science shows everyone has some unconscious biases. And
      some may have one type of bias that is stronger than another type of bias. For example, a
      person may have a stronger gender bias than race bias.
    </SectionContent>
    <SectionContent>
      Secondly, the research also shows that some people of color, women and other groups can have
      unconscious biases towards their own group. Additionally, our social psychologists point out
      that people tend to more easily accept a result that shows they have a gender bias, but can
      have a stronger visceral reaction to a result that shows they have a race bias.
    </SectionContent>
    <SectionContent>
      When the assessment results show that what’s happening unconsciously in our brains is in
      conflict with how we consciously see ourselves, it can lead to strong negative emotions and
      even wanting to question the science and the validity of the assessments. Social psychologists
      say what’s most important is to be open-minded to better understand yourself and have a
      desire to learn how you may inadvertently be defaulting to stereotyping, to interrupt biased
      thinking, and to develop skills to strengthen your cognitive empathy and open mindedness to
      lead to more inclusiveness and better outcomes for everyone in the workplace. BiasSync’s
      micro-learnings are designed to help everyone with these skills.
    </SectionContent>
    <SectionTitle>Who else will know what my results were?</SectionTitle>
    <SectionContent>
      No one else! All assessments are anonymous- only you can see your own results. No
      identifiable, individual-level employee data is reported back to your organization leaders.
    </SectionContent>
    <SectionContent>
      At the end of the designated rollout period, if your company has chosen to understand the
      levels of bias, cognitive empathy and openness in your organization, your company leaders
      will receive a summary of the company-wide aggregate measures of those of unconscious biases
      and related factors like cognitive empathy, openness, by different divisions, roles, or
      locations as long as those groups are large enough to maintain individual anonymity.
    </SectionContent>
  </Content>
);

export default LearnMoreContent;
