import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';
import preloadImage from 'lib/preloadImage';

import { chapterCompleted } from 'store/courses/actions';
import {
  currentChapterIdSelector,
  currentCourseIdSelector,
} from 'store/courses/selectors';

import {
  SUBMIT_LIKERT_TEST,
  submitLikertTest,
} from '../actions';

const debug = Debug('biassync:lms:store:likertTest:sagas:submitLikertTest');

export function* submitLikertTestSaga(action) {
  const { payload: testResponses } = action;
  debug('Saga called', testResponses);

  const courseId = yield select(currentCourseIdSelector);
  const chapterId = yield select(currentChapterIdSelector);

  try {
    const resultsData = yield call(
      API.sendLikertTestResponses,
      chapterId,
      decamelizeKeys(testResponses),
    );

    try {
      yield call(preloadImage, resultsData.graphUrl);

      yield put(chapterCompleted({ chapterId, courseId }));

      yield put(submitLikertTest.success(resultsData));
    } catch (e) {
      debug('Image preloading error', e);
      yield put(submitLikertTest.error(new Error('Invalid or missing likert test graph url')));
    }
  } catch (e) {
    debug('API error', e);
    yield put(submitLikertTest.error(e, action));
  }
}

export default function* submitLikertTestDefaultSaga() {
  yield takeLatest(SUBMIT_LIKERT_TEST.PENDING, submitLikertTestSaga);
}
