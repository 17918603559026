import PropTypes from 'prop-types';

import { chapterType } from 'constants/propTypes';
import * as ChapterStatus from 'constants/chapterStatuses';
import { doesChapterShowAssessmentData } from 'lib/utils';

import ChapterItem from './ChapterItem';

const ChapterItemContainer = ({
  chapter,
  isChapterDisabled,
  isCurrent,
  isLastSection,
  isUserDataAnonymized,
  onClick,
}) => {
  const hasStarted = Object.keys(chapter.userMetadata).length > 0 && chapter.userMetadata.isStarted;

  let status = ChapterStatus.Default;
  if (isCurrent) status = ChapterStatus.Current;
  else if (isUserDataAnonymized && doesChapterShowAssessmentData(chapter)) {
    status = ChapterStatus.Anonymized;
  } else if (isChapterDisabled && !hasStarted) status = ChapterStatus.Disabled;

  return (
    <ChapterItem
      duration={chapter.duration}
      isCompleted={hasStarted && !!chapter.userMetadata.isCompleted}
      isLastSection={isLastSection}
      onClick={onClick}
      status={status}
      title={chapter.title}
      type={chapter.loType}
    />
  );
};

ChapterItemContainer.propTypes = {
  chapter: chapterType.isRequired,
  isChapterDisabled: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isLastSection: PropTypes.bool.isRequired,
  isUserDataAnonymized: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChapterItemContainer;
