const vjsOptions = ({ isMobileDevice }) => ({
  autoplay: true,
  controlBar: {
    pictureInPictureToggle: false,
    volumePanel: {
      inline: false,
    },
  },
  controls: true,
  crossorigin: 'use-credentials',
  fill: true,
  html5: {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    vhs: {
      limitRenditionByPlayerDimensions: isMobileDevice,
      overrideNative: true,
      withCredentials: true,
    },
  },
  playbackRates: [0.5, 0.75, 1, 1.25, 1.5],
  preload: 'auto',
  textTrackSettings: false,
});

export default vjsOptions;
