import Config from 'config'; // eslint-disable-line
import Debug from 'debug';

const debug = Debug('biassync:lms:lib:analytics');

export const trackPageView = data => {
  if (!Config.isAnalyticsEnabled) return;
  try {
    const { event, page, ...properties } = data;
    window.analytics.page(page, properties);
  } catch (e) {
    debug('Error received', e);
  }
};

export const trackEvent = (event, properties) => {
  if (!Config.isAnalyticsEnabled) return;
  try {
    window.analytics.track(event, properties);
  } catch (e) {
    debug('Error received', e);
  }
};

export const trackUser = (user = null) => {
  if (!Config.isAnalyticsEnabled) return;
  try {
    window.analytics.identify(user.id, {
      company: user.company.id,
      company_name: user.company.name,
    });
  } catch (e) {
    debug('Error received', e);
  }
};

export const untrackUser = () => {
  if (!Config.isAnalyticsEnabled) return;
  window.analytics.reset();
};

const dispatch = data => {
  if (data.event === 'page-view') trackPageView(data);
  else {
    const { event, ...otherData } = data;
    trackEvent(event, otherData);
  }
};

export const appTrackingOptions = {
  dispatch,
  process: data => (data.page ? { event: 'page-view' } : null),
};
