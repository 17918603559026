import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import downloadPDF from 'lib/downloadPDF';

import { isIOSChromeSelector } from 'store/userAgent/selectors';
import {
  DOWNLOAD_USER,
  downloadUser,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:downloadUser');

export function* downloadUserSaga(action) {
  debug('Saga called');

  try {
    const response = yield call(API.downloadUser);

    try {
      const isIOSChrome = yield select(isIOSChromeSelector);
      yield call(downloadPDF, response, isIOSChrome);

      yield put(downloadUser.success());
    } catch (e) {
      debug('Saga download error', e);
      yield put(downloadUser.error(e, action));
    }
  } catch (e) {
    debug('Saga request error', e);
    yield put(downloadUser.error(e, action));
  }
}

export default function* downloadUserDefaultSaga() {
  yield takeLatest(DOWNLOAD_USER.PENDING, downloadUserSaga);
}
