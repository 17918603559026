import PropTypes from 'prop-types';
import { css } from 'styled-components';
import type from 'styles/type';

const typeScale = name => css`
  font-size: ${type.scale[name].size};
  line-height: ${type.scale[name].lineHeight.toFixed(3)};
`;

typeScale.propTypes = {
  name: PropTypes.string.isRequired,
};

export default typeScale;
