import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';

export const ChapterWrapper = styled.div`
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  color: ${colors.yellow.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 40px 100px;

  ${media.min.mobileLandscape`padding: 120px 120px 100px;`}
  ${media.min.tablet`padding: 160px 120px 140px;`}
  ${media.min.baseline`padding: 180px 160px 140px;`}

  ${p => !p.isAndroidTablet && css`
    ${media.min.tablet`
      min-height: 100%;
    `}
  `}
`;

export const ContentWrapper = styled.div`
  max-width: 800px;

  ${({ isDimmed }) => isDimmed && 'opacity: 0.1;'}
`;

export const TitleText = styled.div`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};

  ${media.min.tablet`font-size: 2.4rem;`}
  ${media.min.baseline`font-size: 2.6rem;`}
  ${media.min.desktop`font-size: 2.8rem;`}
  ${media.min.max`font-size: 3rem;`}
`;

export const BodyText = styled.p`
  font-size: 1.6rem;

  & > strong {
    font-weight: ${type.weight.bold};
  }

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const OptionsWrapper = styled.div`
  font-size: 1.6rem;
  line-height: 20px;
  margin-top: 48px;

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`
    font-size: 2rem;
    line-height: 24px;
    margin-top: 58px;
  `}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const OptionWrapper = styled.div`
  &:last-child {
    margin-top: 30px;
  }
`;

export const TextFieldWrapper = styled.div`
  margin-top: 24px;
  margin-left: 44px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;

  ${media.min.baseline`margin-top: 70px;`}
`;

export const LoadingWrapper = styled.div`
  position: ${({ isPositionFixed }) => (isPositionFixed ? 'fixed' : 'relative')};
  width: 100%;
  z-index: 1;
`;
