import xIcon from 'assets/x-icon.svg';
import checkmarkIcon from 'assets/checkmark-icon.svg';

import { OptionStatus } from './types';
import {
  Input,
  OptionKeyIcon,
  OptionKey,
  OptionText,
  OptionLabel,
} from './style';

type MultipleChoiceOptionProps = {
  onClick: () => void;
  optionKey: string;
  optionText: string;
  status: OptionStatus;
};

const MultipleChoiceOption = ({
  onClick,
  optionKey,
  optionText,
  status,
}: MultipleChoiceOptionProps) => {
  let optionKeyContent: string | JSX.Element = optionKey;
  if (status === OptionStatus.Correct || status === OptionStatus.Incorrect) {
    optionKeyContent = (
      <OptionKeyIcon src={status === OptionStatus.Correct ? checkmarkIcon : xIcon} alt="" />
    );
  }
  return (
    <OptionLabel>
      <Input
        checked={status === OptionStatus.Selected}
        disabled={
          status === OptionStatus.Disabled
          || status === OptionStatus.Correct
          || status === OptionStatus.Incorrect
        }
        id={optionKey}
        name={optionKey}
        onChange={onClick}
        type="radio"
      />
      <OptionKey status={status}>{optionKeyContent}</OptionKey>
      <OptionText status={status}>{optionText}</OptionText>
    </OptionLabel>
  );
};

export default MultipleChoiceOption;
