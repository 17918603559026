import styled from 'styled-components';
import { media, type } from 'styles';

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`;

export const LoadingIndicatorWrapper = styled.div`
  margin-bottom: 24px;
  width: 120px;
  height: 66px;

  ${media.min.desktop`
    width: 175px;
    height: 96.25px;
  `}

  ${media.min.max`
    width: 230px;
    height: 126.5px;
  `}
`;

export const Heading = styled.h3`
  font-size: 2.4rem;
  font-weight: ${type.weight.bold};
`;

export const Body = styled.p`
  margin: 0;
  font-size: 1.8rem;
  width: 60%
`;
