import styled from 'styled-components';
import { type, media, colors } from 'styles';

export const PanelWrapper = styled.div`
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: rgb(51, 53, 59);
`;

export const ChevronIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 8px;
  width: 12px;
  height: 12px;
  
  ${p => p.isLeft && 'transform: rotate(180deg);'}
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  margin: 0 0 16px 0;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};

  ${media.min.tablet`font-size: 2rem;`}
`;

export const PanelButtonWrapper = styled.div`
  margin: 32px 0;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.shasta.light};
  margin-bottom: 32px;
`;

export const Content = styled.div`
  font-size: 1.4rem;
  color: ${colors.white};
  margin-bottom: 24px;

  ${media.min.tablet`font-size: 1.6rem;`}
`;
