import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import {
  currentChapterIdSelector,
  currentCourseIdSelector,
} from 'store/courses/selectors';
import {
  FETCH_COURSE,
  fetchCourse,
  fetchCourses,
} from 'store/courses/actions';
import {
  SKIP_CHAPTER_FOR_ACCESSIBILITY,
  skipChapterForAccessibility,
} from '../actions';

const debug = Debug('biassync:lms:store:chapters:sagas:skipChapterForAccessibility');

export function* skipChapterForAccessibilitySaga(action) {
  debug('Saga called');

  const currentCourseId = yield select(currentCourseIdSelector);
  let currentChapterId = yield select(currentChapterIdSelector);

  try {
    yield call(API.skipChapterForAccessibility, currentChapterId);

    debug('Successfully skipped chapter');

    yield put(fetchCourse(currentCourseId));
    yield call(awaitAsyncAction, FETCH_COURSE);

    (currentChapterId = yield select(currentChapterIdSelector));

    // If there is no current chapter after re-fetching the course, then we know
    // the course was completed and the user was redirected to the overview page
    if (!currentChapterId) {
      yield put(fetchCourses()); // Fetch courses to get fresh course statuses
    }

    yield put(skipChapterForAccessibility.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(skipChapterForAccessibility.error(e, action));
  }
}

export default function* skipChapterForAccessibilityDefaultSaga() {
  yield takeLatest(SKIP_CHAPTER_FOR_ACCESSIBILITY.PENDING, skipChapterForAccessibilitySaga);
}
