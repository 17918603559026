import styled from 'styled-components';
import { colors, media } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const Button = styled(TransparentButton)`
  outline: none;
  border-radius: 9999px;

  &.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
    background-color: rgba(35, 147, 215, 0.1);
  }
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  padding: 14px;
  background-color: rgba(250, 238, 222, 0.1);
  border-radius: 30.5px;

  ${media.min.mobileLandscape`
    width: 52px;
    height: 52px;
    padding: 16px;
  `}

  ${media.min.tablet`
    width: 60px;
    height: 60px;
    padding: 18px;
  `}
`;
