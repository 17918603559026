import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import colors from 'styles/colors';

import {
  BodyText,
  CTAKeystroke,
  CTAKeystrokeText,
  CTAWrapper,
  FlowFormTextItemWrapper,
  HeaderText,
} from './style';

const FlowFormTextItem = ({
  bodyText,
  buttonText,
  buttonType,
  headerText,
  isActive,
  isCTAButtonLoading,
  isHeaderTextCentered,
  keystrokeText,
  onButtonClick,
}) => (
  <FlowFormTextItemWrapper isActive={isActive}>
    <HeaderText centerAlign={isHeaderTextCentered}>{headerText}</HeaderText>
    {bodyText && <BodyText>{bodyText}</BodyText>}
    <CTAWrapper>
      <SimpleButton
        focusOutlineColor={colors.yellow.base}
        isFadedWhenLoading={false}
        loading={isCTAButtonLoading}
        onClick={onButtonClick}
        type={buttonType}
        variant="white"
      >
        {buttonText}
      </SimpleButton>
      {keystrokeText && (
        <CTAKeystrokeText>
          <Trans i18nKey="courseViewer:survey.keystrokeText">
            or press <CTAKeystroke>{{ keystrokeText }}</CTAKeystroke>
          </Trans>
        </CTAKeystrokeText>
      )}
    </CTAWrapper>
  </FlowFormTextItemWrapper>
);

FlowFormTextItem.propTypes = {
  bodyText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isCTAButtonLoading: PropTypes.bool,
  isHeaderTextCentered: PropTypes.bool,
  keystrokeText: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

FlowFormTextItem.defaultProps = {
  bodyText: null,
  buttonType: 'button',
  isActive: true,
  isCTAButtonLoading: false,
  isHeaderTextCentered: false,
  keystrokeText: null,
};

export default FlowFormTextItem;
