import styled from 'styled-components';

export const SurveyItemList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;

  &:before, &:after {
    content: '';
    display: block;
    height: 35vh;
  }
`;

export const ProgressTrackerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const SurveyItemWrapper = styled.li`
  opacity: ${({ isActive }) => (isActive ? '1' : '0.2')};
  transition: opacity 0.5s ease;
  margin-bottom: ${p => p.theme.surveyItem.marginBottom};
`;
