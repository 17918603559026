import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckmarkIcon from 'components/svg/CheckmarkIcon';

import {
  BlockDot,
  IATProgressIndicatorWrapper,
  LabelText,
} from './style';

export const IATProgressIndicator = ({
  currentBlock,
  numOfBlocks,
}) => {
  const { t } = useTranslation('courseViewer');
  const blockLabels = Array(numOfBlocks).fill();

  return (
    <IATProgressIndicatorWrapper>
      <LabelText>{t('courseViewer:iat.progressIndicatorLabel')}</LabelText>
      {blockLabels.map((_, index) => {
        const blockLabel = index + 1;
        const isBlockCompleted = blockLabel < currentBlock;

        if (isBlockCompleted) {
          return (
            <BlockDot key={blockLabel}>
              <CheckmarkIcon titleText={t('courseViewer:iat.blocks.completed')} variant="yellow" />
            </BlockDot>
          );
        }

        const isCurrentBlock = blockLabel === currentBlock;
        return (
          <BlockDot
            status={isCurrentBlock ? 'current' : 'default'}
            key={blockLabel}
          >
            {blockLabel}
          </BlockDot>
        );
      })}
    </IATProgressIndicatorWrapper>
  );
};

IATProgressIndicator.propTypes = {
  currentBlock: PropTypes.number.isRequired,
  numOfBlocks: PropTypes.number.isRequired,
};

export default IATProgressIndicator;
