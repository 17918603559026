/* eslint-disable @typescript-eslint/default-param-last */

import { getErrorMessage } from 'lib/errorHandler';

import { HANDLE_COURSE_END } from '../courses/actions';
import {
  CACHE_VIDEO_METADATA,
  HANDLE_VIDEO_PLAYER_TEARDOWN,
  HANDLE_VIDEO_SEEKING,
  VIDEO_TASK_COMPLETED,
  LOAD_VIDEO_USER_METADATA,
  SEND_VIDEO_END_BOOKMARK,
  SEND_VIDEO_TASK_ANSWER,
  SET_VIDEO_BOOKMARK,
  SET_VIDEO_COMPLETED,
  SET_VIDEO_ENDED,
  SET_VIDEO_NOT_PLAYING,
  SET_VIDEO_PLAYABLE,
  SET_VIDEO_PLAYER_INITIALIZED,
  SET_VIDEO_PLAYER_ERROR,
  SET_VIDEO_PLAYING,
  SET_VIDEO_SOURCE,
  RESET_VIDEO_TASK,
} from './actions';

export const INITIAL_STATE = {
  error: null,
  hasEnded: false,
  isPlayable: false,
  isPlayerInitialized: false,
  isPlaying: false,
  isSendingEndBookmark: false,
  isSendingVideoTaskAnswer: false,
  userMetadata: {
    bookmark: 0,
    furthestPoint: 0,
    isCompleted: false,
  },
  videoMetadataCache: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_VIDEO_SOURCE.PENDING:
      // Resetting these as they are only relevant for the video during which they were set
      return {
        ...state,
        error: null,
        hasEnded: false,
        isPlayable: false,
        isSendingEndBookmark: false,
      };
    case SET_VIDEO_SOURCE.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: payload ? getErrorMessage(payload) : undefined,
          retryAction: payload && payload.config ? action.originalAction : undefined,
        },
      };
    case SEND_VIDEO_TASK_ANSWER.PENDING:
      return {
        ...state,
        isSendingVideoTaskAnswer: true,
      };
    case SEND_VIDEO_TASK_ANSWER.SUCCESS:
    case SEND_VIDEO_TASK_ANSWER.ERROR:
      return {
        ...state,
        isSendingVideoTaskAnswer: false,
      };
    case CACHE_VIDEO_METADATA:
      return {
        ...state,
        videoMetadataCache: {
          ...state.videoMetadataCache,
          ...payload,
        },
      };
    case VIDEO_TASK_COMPLETED.SUCCESS: {
      const videoMetadataCache = { ...state.videoMetadataCache };
      const videoMetadata = { ...videoMetadataCache[payload.chapterId] };

      videoMetadata.ephemeralEvents = videoMetadata.ephemeralEvents.filter(eventId => (
        eventId !== payload.taskId
      ));
      videoMetadataCache[payload.chapterId] = videoMetadata;

      return { ...state, videoMetadataCache };
    }
    case RESET_VIDEO_TASK:
      return {
        ...state,
        videoMetadataCache: {
          ...state.videoMetadataCache,
          [payload.chapterId]: {
            ...state.videoMetadataCache[payload.chapterId],
            ephemeralEvents: payload.prevEphemeralEventIds,
          },
        },
      };
    case SET_VIDEO_PLAYER_INITIALIZED:
      return {
        ...state,
        isPlayerInitialized: true,
      };
    case SET_VIDEO_PLAYER_ERROR:
      return {
        ...state,
        error: { isUserInitiated: false },
      };
    case HANDLE_VIDEO_PLAYER_TEARDOWN:
      return {
        ...state,
        isPlayable: false,
        isPlayerInitialized: false,
      };
    case HANDLE_COURSE_END.PENDING:
    case HANDLE_VIDEO_SEEKING.PENDING:
      return {
        ...state,
        hasEnded: false,
      };
    case SET_VIDEO_ENDED:
      return {
        ...state,
        hasEnded: true,
      };
    case SET_VIDEO_BOOKMARK:
      return {
        ...state,
        userMetadata: {
          ...state.userMetadata,
          bookmark: payload,
          furthestPoint: payload > state.userMetadata.furthestPoint
            ? payload
            : state.userMetadata.furthestPoint,
        },
      };
    case SET_VIDEO_COMPLETED:
      return {
        ...state,
        userMetadata: {
          ...state.userMetadata,
          isCompleted: true,
        },
      };
    case SET_VIDEO_PLAYABLE:
      return {
        ...state,
        isPlayable: true,
      };
    case SET_VIDEO_PLAYING:
      return {
        ...state,
        isPlaying: true,
      };
    case SET_VIDEO_NOT_PLAYING:
      return {
        ...state,
        isPlaying: false,
      };
    case SEND_VIDEO_END_BOOKMARK.PENDING:
      return {
        ...state,
        error: null,
        isSendingEndBookmark: true,
      };
    case SEND_VIDEO_END_BOOKMARK.ERROR:
      return {
        ...state,
        error: { message: getErrorMessage(payload) },
        isSendingEndBookmark: false,
      };
    case SEND_VIDEO_END_BOOKMARK.SUCCESS:
      return {
        ...state,
        isSendingEndBookmark: false,
      };
    case LOAD_VIDEO_USER_METADATA:
      return {
        ...state,
        userMetadata: { ...INITIAL_STATE.userMetadata, ...payload },
      };
    default:
      return state;
  }
};
