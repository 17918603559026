import PropTypes from 'prop-types';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import {
  Button,
  ButtonText,
  LoadingWrapper,
} from './style';

const StyledButton = ({
  children,
  disabled,
  full,
  isFadedWhenDisabled,
  isFadedWhenLoading,
  loading,
  onClick,
  tid,
  variant,
}) => (
  <Button
    data-testid={tid}
    disabled={disabled || loading}
    faded={(disabled && isFadedWhenDisabled) || (loading && isFadedWhenLoading)}
    full={full}
    onClick={onClick}
    variant={variant}
  >
    <ButtonText isVisible={!loading}>{children}</ButtonText>
    {loading && (
      <LoadingWrapper>
        <LoadingSpinner color="white" />
      </LoadingWrapper>
    )}
  </Button>
);

StyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  isFadedWhenDisabled: PropTypes.bool,
  isFadedWhenLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tid: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'success', 'default', 'brandedBlue']),
};

StyledButton.defaultProps = {
  disabled: false,
  full: false,
  isFadedWhenDisabled: true,
  isFadedWhenLoading: true,
  loading: false,
  tid: '',
  variant: 'primary',
};

export default StyledButton;
