import styled from 'styled-components';

import { colors, media, type } from 'styles';
import forceImportant from 'styles/functions/forceImportant';

import { OptionStatus } from './types';

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.legend`
  font-size: 1.6rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  padding: 14px 14px 10px;

  ${media.twoDimMin.tablet`
    font-size: 1.8rem;
    padding: 18px;
  `}

  ${media.twoDimMin.baseline`
    font-size: 2rem;
    padding: 20px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 2.2rem;
    padding: 22px;
  `}
`;

export const InstructionText = styled.span`
  font-size: 1.1rem;
  font-weight: ${type.weight.extraBold};
  letter-spacing: 0.035em;
  color: ${colors.blue.base};
  text-transform: uppercase;
  padding: 8px 14px;
  border-top: 1px solid rgba(111, 125, 141, 0.2);

  ${media.twoDimMin.tablet`
    font-size: 1.2rem;
    padding: 10px 18px;
  `}

  ${media.twoDimMin.baseline`
    font-size: 1.4rem;
    padding: 10px 20px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 1.4rem;
    padding: 12px 22px;
  `}
`;

export const OptionsContainer = styled.div`
  padding: 14px 14px 0;
  border-top: 1px solid rgba(111, 125, 141, 0.2);

  ${media.twoDimMin.tablet`padding: 18px 18px 0;`}
  ${media.twoDimMin.baseline`padding: 20px 20px 0;`}
  ${media.twoDimMin.desktop`padding: 22px 22px 0;`}
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

// Forcing specificity so video-js does not override with their default style,
// see documentation for more details.
export const OptionLabel = styled.label`
  ${forceImportant`
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 4px;
      ${media.twoDimMin.tablet`margin-top: 8px;`}
      ${media.twoDimMin.baseline`margin-top: 10px;`}
      ${media.twoDimMin.desktop`margin-top: 12px;`}
    }
  `}
`;

type OptionKeyProps = {
  status: OptionStatus;
};

const optionKeyColorByStatus = {
  [OptionStatus.Correct]: colors.green.base,
  [OptionStatus.Default]: colors.blue.base,
  [OptionStatus.Disabled]: colors.shasta.background,
  [OptionStatus.Incorrect]: colors.red.base,
  [OptionStatus.Selected]: colors.blue.dark,
  [OptionStatus.Unselected]: colors.blue.muted,
};

export const OptionKey = styled.span<OptionKeyProps>`
  flex: 0 0 auto;
  background-color: ${p => optionKeyColorByStatus[p.status]};
  color: ${colors.white};
  font-weight: ${type.weight.bold};
  text-transform: uppercase;
  text-align: center;
  border-radius: 6px;
  font-size: 1.6rem;
  line-height: 30px;
  width: 30px;
  height: 30px;
  margin-right: 12px;

  ${media.twoDimMin.tablet`
    font-size: 1.8rem;
    line-height: 40px;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 8px;
  `}

  ${media.twoDimMin.baseline`
    font-size: 2rem;
    line-height: 45px;
    width: 45px;
    height: 45px;
    margin-right: 18px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 2.2rem;
    line-height: 50px;
    width: 50px;
    height: 50px;
    margin-right: 20px;
  `}

  ${Input}.focus-visible ~ & {
    box-shadow: ${colors.yellow.base} 0px 0px 0px 2px;
  }
`;

export const OptionKeyIcon = styled.img`
  padding: 8px;
  ${media.twoDimMin.tablet`padding: 12px;`}
  ${media.twoDimMin.baseline`padding: 13px;`}
  ${media.twoDimMin.desktop`padding: 14px;`}
`;

type OptionTextProps = {
  status: OptionStatus;
};

const getOptionTextColor = (status: OptionStatus) => {
  switch (status) {
    case OptionStatus.Unselected:
    case OptionStatus.Disabled:
      return colors.sierra.muted;
    case OptionStatus.Incorrect:
      return colors.red.base;
    default:
      return colors.sierra.base;
  }
};

export const OptionText = styled.span<OptionTextProps>`
  color: ${p => getOptionTextColor(p.status)};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  word-break: break-word;

  ${media.twoDimMin.tablet`font-size: 1.4rem;`}
  ${media.twoDimMin.baseline`font-size: 1.6rem;`}
  ${media.twoDimMin.desktop`font-size: 1.8rem;`}
`;

export const ButtonContainer = styled.div`
  padding: 14px;
  ${media.twoDimMin.tablet`padding: 20px 18px 18px;`}
  ${media.twoDimMin.baseline`padding: 24px 20px 20px;`}
  ${media.twoDimMin.desktop`padding: 28px 22px 22px;`}
`;
