/* eslint-disable sort-keys */

import colorVariant from 'styles/functions/colorVariant';

const baseColors = {
  black: '#000',
  blue: '#237bc0',
  brandedBlue: '#528fb4',
  brandedGreen: '#2a504b',
  cascade: '#6f7d8d',
  green: '#2ea87a',
  red: '#e61e4d',
  ridge: '#4b5064',
  rocky: '#dbe1e8',
  shasta: '#727780',
  sierra: '#21242c',
  white: '#fff',
  yellow: '#ffd285',
};

const colors = {
  black: baseColors.black,
  cascade: {
    dark: colorVariant('dark', baseColors.cascade),
    focus: colorVariant('focus', baseColors.cascade),
    base: baseColors.cascade,
    light: colorVariant('light', baseColors.cascade),
    muted: colorVariant('muted', baseColors.cascade),
    background: colorVariant('background', baseColors.cascade),
  },
  yellow: {
    dark: colorVariant('dark', baseColors.yellow),
    focus: colorVariant('focus', baseColors.yellow),
    base: baseColors.yellow,
    light: colorVariant('light', baseColors.yellow),
    muted: colorVariant('muted', baseColors.yellow),
    background: colorVariant('background', baseColors.yellow),
  },
  red: {
    dark: colorVariant('dark', baseColors.red),
    focus: colorVariant('focus', baseColors.red),
    base: baseColors.red,
    light: colorVariant('light', baseColors.red),
    muted: colorVariant('muted', baseColors.red),
    background: colorVariant('background', baseColors.red),
  },
  blue: {
    dark: colorVariant('dark', baseColors.blue),
    focus: colorVariant('focus', baseColors.blue),
    base: baseColors.blue,
    light: colorVariant('light', baseColors.blue),
    muted: colorVariant('muted', baseColors.blue),
    background: colorVariant('background', baseColors.blue),
  },
  ridge: {
    dark: colorVariant('dark', baseColors.ridge),
    focus: colorVariant('focus', baseColors.ridge),
    base: baseColors.ridge,
    light: colorVariant('light', baseColors.ridge),
    muted: colorVariant('muted', baseColors.ridge),
    background: colorVariant('background', baseColors.ridge),
  },
  rocky: {
    dark: colorVariant('dark', baseColors.rocky),
    focus: colorVariant('focus', baseColors.rocky),
    base: baseColors.rocky,
    light: colorVariant('light', baseColors.rocky),
    muted: colorVariant('muted', baseColors.rocky),
    background: colorVariant('background', baseColors.rocky),
  },
  shasta: {
    dark: colorVariant('dark', baseColors.shasta),
    focus: colorVariant('focus', baseColors.shasta),
    base: baseColors.shasta,
    light: colorVariant('light', baseColors.shasta),
    muted: colorVariant('muted', baseColors.shasta),
    background: colorVariant('background', baseColors.shasta),
  },
  sierra: {
    dark: colorVariant('dark', baseColors.sierra),
    focus: colorVariant('focus', baseColors.sierra),
    base: baseColors.sierra,
    light: colorVariant('light', baseColors.sierra),
    muted: colorVariant('muted', baseColors.sierra),
    background: colorVariant('background', baseColors.sierra),
  },
  white: baseColors.white,
  green: {
    dark: colorVariant('dark', baseColors.green),
    focus: colorVariant('focus', baseColors.green),
    base: baseColors.green,
    light: colorVariant('light', baseColors.green),
    muted: colorVariant('muted', baseColors.green),
    background: colorVariant('background', baseColors.green),
  },
  brandedBlue: {
    dark: colorVariant('dark', baseColors.brandedBlue),
    focus: colorVariant('focus', baseColors.brandedBlue),
    base: baseColors.brandedBlue,
    light: colorVariant('light', baseColors.brandedBlue),
    muted: colorVariant('muted', baseColors.brandedBlue),
    background: colorVariant('background', baseColors.brandedBlue),
  },
  brandedGreen: {
    dark: colorVariant('dark', baseColors.brandedGreen),
    focus: colorVariant('focus', baseColors.brandedGreen),
    base: baseColors.brandedGreen,
    light: colorVariant('light', baseColors.brandedGreen),
    muted: colorVariant('muted', baseColors.brandedGreen),
    background: colorVariant('background', baseColors.brandedGreen),
  },
};

export default colors;
