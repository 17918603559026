import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const Wrapper = styled.div`
  color: ${colors.sierra.base};
  width: 100%;
  padding: 24px;

  ${media.min.mobileLandscape`
    width: 500px;
    padding: 32px;
  `}

  ${media.min.tablet`
    width: 585px;
  `}

  ${media.min.desktop`
    width: 685px;
    padding: 44px;
  `}
`;

export const Title = styled.h2`
  font-weight: ${type.weight.bold};
  font-size: 1.8rem;
  text-align: center;
  margin: 0;
  outline: 0;

  ${media.min.baseline`
    font-size: 2rem;
  `}

  ${media.min.desktop`font-size: 2.4rem;`}
`;

export const PolicyWrapper = styled.div`
  overflow-y: scroll;
  border-radius: 20px;
  border: solid 2px ${colors.rocky.base};
  padding: 20px;
  max-height: 300px;
  margin-top: 18px;

  ${media.min.baseline`
    margin-top: 24px;
  `};
  ${media.min.desktop`
    padding: 24px;
    margin-top: 32px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;     
  margin-top: 36px;
`;

export const CancelButton = styled(TransparentButton)`
  color: ${({ disabled }) => (disabled ? colors.cascade.background : colors.blue.base)};
  margin-right: 24px;
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  outline: none;

  &.focus-visible {
    border-bottom: 2px solid ${colors.blue.light};
  }

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const HelpText = styled.div`
  font-size: 1.4rem;
  display: block;
  text-align: center;
  margin-top: 36px;

  ${media.min.tablet`
    font-size: 1.6rem;
  `}

  ${media.min.desktop`font-size: 1.7rem;`}
`;
