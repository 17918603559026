import { Recommendation } from '../../../types';

import {
  Body,
  Box,
  Content,
  Number,
  Title,
} from './style';

interface RecommendationProps {
  item: Recommendation;
  number: number;
}

const RecommendationContainer = ({ item, number }: RecommendationProps) => (
  <Box key={item.title}>
    <Number>{number}</Number>
    <Content>
      <Title>{item.title}</Title>
      <Body>{item.body}</Body>
    </Content>
  </Box>
);

export default RecommendationContainer;
