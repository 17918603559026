// Types
export const VIDEO_EVENT_TYPE_TASK = 'task';
export const VIDEO_EVENT_TYPE_OVERLAY = 'overlay';

// Subtypes
export const VIDEO_EVENT_SUBTYPE_MULTIPLE_CHOICE_OPINION = 'multiple_choice_opinion';
export const VIDEO_EVENT_SUBTYPE_MULTIPLE_CHOICE_FACT = 'multiple_choice_fact';
export const VIDEO_EVENT_SUBTYPE_TEXT = 'text';
export const VIDEO_EVENT_SUBTYPE_RESULTS = 'results';
export const VIDEO_EVENT_SUBTYPE_ANNOTATION = 'annotation';
