import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  deviceTypeType,
  iatKeysConfigType,
} from 'constants/propTypes';
import { MOBILE, TABLET } from 'constants/deviceTypes';

import IATProgressIndicator from 'components/shared/IATProgressIndicator';
import IATSummaryLayout from 'components/shared/IATSummaryLayout';

import IATTutorialKeyMappingItem from './IATTutorialKeyMappingItem';
import {
  KeyMappingSummaries,
  KeyMappingSummaryWrapper,
  ProgressIndicatorWrapper,
} from './style';

const IATTutorialBlockSummary = ({
  currentBlock,
  deviceType,
  keysConfig,
  leftCategories,
  leftOptions,
  numOfBlocks,
  onCTATap,
  promptText,
  rightCategories,
  rightOptions,
  shouldCTAFlash,
}) => {
  const { t } = useTranslation('courseViewer');

  const isTouchScreen = deviceType === MOBILE || deviceType === TABLET;
  const [leftCategoryType, leftCategoryName] = Object.entries(leftCategories)[0];
  const [rightCategoryType, rightCategoryName] = Object.entries(rightCategories)[0];

  return (
    <IATSummaryLayout
      deviceType={deviceType}
      onCTATap={onCTATap}
      promptText={promptText}
      shouldCTAFlash={shouldCTAFlash}
    >
      <KeyMappingSummaries>
        <KeyMappingSummaryWrapper>
          <ProgressIndicatorWrapper>
            <IATProgressIndicator
              currentBlock={currentBlock}
              numOfBlocks={numOfBlocks}
            />
          </ProgressIndicatorWrapper>
          <IATTutorialKeyMappingItem
            categoryName={leftCategoryName}
            categoryType={leftCategoryType}
            deviceType={deviceType}
            keyValue={isTouchScreen ? t('courseViewer:iat.leftLabel') : keysConfig.left}
            options={leftOptions}
            t={t}
          />
        </KeyMappingSummaryWrapper>
        <KeyMappingSummaryWrapper>
          <IATTutorialKeyMappingItem
            categoryName={rightCategoryName}
            categoryType={rightCategoryType}
            deviceType={deviceType}
            keyValue={isTouchScreen ? t('courseViewer:iat.rightLabel') : keysConfig.right}
            options={rightOptions}
            t={t}
          />
        </KeyMappingSummaryWrapper>
      </KeyMappingSummaries>
    </IATSummaryLayout>
  );
};

IATTutorialBlockSummary.propTypes = {
  currentBlock: PropTypes.number.isRequired,
  deviceType: deviceTypeType.isRequired,
  keysConfig: iatKeysConfigType.isRequired,
  leftCategories: PropTypes.objectOf(PropTypes.string).isRequired,
  leftOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  numOfBlocks: PropTypes.number.isRequired,
  onCTATap: PropTypes.func,
  promptText: PropTypes.string.isRequired,
  rightCategories: PropTypes.objectOf(PropTypes.string).isRequired,
  rightOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  shouldCTAFlash: PropTypes.bool,
};

IATTutorialBlockSummary.defaultProps = {
  onCTATap: undefined,
  shouldCTAFlash: false,
};

export default IATTutorialBlockSummary;
