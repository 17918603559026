import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_INFORMATION_CARD,
  fetchInformationCard,
} from '../actions';

const debug = Debug('biassync:lms:store:informationCard:sagas:fetchInformationCard');

export function* fetchInformationCardSaga(action) {
  const { payload: chapterId } = action;
  debug('Saga called', chapterId);

  try {
    const { informationCard } = yield call(
      API.getInformationCard,
      chapterId,
    );

    debug('Information card received', informationCard);

    yield put(fetchInformationCard.success(informationCard));
  } catch (e) {
    debug('Saga request error', e);
    yield put(fetchInformationCard.error(e, action));
  }
}

export default function* fetchInformationCardDefaultSaga() {
  yield takeLatest(FETCH_INFORMATION_CARD.PENDING, fetchInformationCardSaga);
}
