/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';

const ConditionalRoute = ({
  children,
  redirectOptions,
  redirectPath,
  shouldRender,
}) => {
  const location = useLocation();

  if (!shouldRender) {
    return (
      <Navigate
        to={redirectPath}
        state={{ from: location }}
        replace
        {...redirectOptions}
      />
    );
  }

  return children;
};

ConditionalRoute.propTypes = {
  children: PropTypes.element,
  redirectOptions: PropTypes.object,
  redirectPath: PropTypes.string.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};

ConditionalRoute.defaultProps = {
  children: null,
  redirectOptions: null,
};

export default ConditionalRoute;
