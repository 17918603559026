import styled, { css } from 'styled-components';
import media from 'styles/media';

const smIconSize = 20;
const mdIconSize = 24;
const circleBorderRadius = '50%';

export const RadioButtonWrapper = styled.div`
  position: relative;
  font-size: ${p => (p.hasLabel ? 'inherit' : '0')};
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;

  ${p => (p.isDisabled && `
    cursor: default;
    pointer-events: none;
    user-select: none;
  `)}

  ${p => (p.children && css`
    padding-left: ${(11 * smIconSize) / 6}px;
    
    ${media.min.baseline`
      padding-left: ${(11 * mdIconSize) / 6}px;
    `}
  `)}

  &:before {
    display: inline-block;
    position: relative;
    content: '';
    width: ${smIconSize}px;
    height: ${smIconSize}px;

    ${media.min.baseline`
      width: ${mdIconSize}px;
      height: ${mdIconSize}px;
    `}
    
    border-radius: ${circleBorderRadius};
    border: 1px solid ${p => p.labelTheme.unselected.borderColor};
    background-color: ${p => p.labelTheme.unselected.background};

    ${Input}:checked ~ & {
      border-color: ${p => p.labelTheme.selected.borderColor};
    }

    ${p => (p.children && `
      position: absolute;
      top: 0;
      left: 0;
    `)}
  }

  ${Input}:checked ~ &:after {
    display: block;
    position: absolute;
    content: '';
    background-color: ${p => p.labelTheme.selected.background};
    border-radius: ${circleBorderRadius};

    width: ${smIconSize / 2}px;
    height: ${smIconSize / 2}px;
    top: ${smIconSize / 4}px;
    left: ${smIconSize / 4}px;

    ${media.min.baseline`
      width: ${mdIconSize / 2}px;
      height: ${mdIconSize / 2}px;
      top: ${mdIconSize / 4}px;
      left: ${mdIconSize / 4}px;
    `}
  }

  ${Input}:focus-visible ~ &:before {
    box-shadow: ${p => p.labelTheme.focus.shadow} 0px 0px 0px 2px;
    outline: none;
  }
`;
