import React from 'react';
import PropTypes from 'prop-types';
import handleViewport from 'react-in-viewport';
import 'intersection-observer';

import { refType } from 'constants/propTypes';

const HANDLE_VIEWPORT_OPTIONS = {
  rootMargin: '-45% 0%',
};

const IntersectionObserver = ({ children, forwardedRef }) => (
  <div ref={forwardedRef}>
    {children}
  </div>
);

IntersectionObserver.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  forwardedRef: refType,
};

IntersectionObserver.defaultProps = {
  forwardedRef: null,
};

export default handleViewport(IntersectionObserver, HANDLE_VIEWPORT_OPTIONS);
