import styled from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';

export const ChapterWrapper = styled.div`
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
`;

export const IATLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoadingText = styled.span`
  display: block;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  margin-top: 44px;

  ${media.twoDimMin.tablet`
    font-size: 2.2rem;
    margin-top: 64px;
  `}
`;
