import { Chapter, ChapterType } from 'types';

export const normalizeKeyValue = (keyValue: string) => {
  if (!keyValue) return keyValue;

  switch (keyValue) {
    case 'Spacebar':
      return ' ';
    default:
      return keyValue.toLowerCase();
  }
};

export const isButtonTarget = (event: React.KeyboardEvent) => (
  !!event && !!event.target && (event.target as HTMLElement).tagName === 'BUTTON'
);

const CHAR_CODE_A = 97;
const ALPHABET_LENGTH = 26;

export const getAlphabetArrayWithLength = (length = 0) => {
  const a = [];

  for (let i = 0; i < length; i += 1) {
    if (i < ALPHABET_LENGTH) a.push(String.fromCharCode(CHAR_CODE_A + i));
    else a.push('');
  }

  return a;
};

export const getNumberArrayWithLength = (length: number) => (
  Array(length + 1).fill(0, 1).map((_, index) => index)
);

export const getImageEventSrc = (event: Event) => {
  if (!event) return null;

  let imageSrc = null;

  const path = event.composedPath && event.composedPath();

  if (path && path.length) imageSrc = (path[0] as HTMLImageElement).src;

  return imageSrc;
};

export const calculateProgressPercentage = (completed: number, total: number) => {
  let progress = (completed / total) * 100;
  progress = Math.round(progress);

  return Math.min(progress, 100);
};

export const doesChapterShowAssessmentData = (chapter: Chapter) => {
  const isIATChapterWithCombinedResults = (
    chapter.loType === ChapterType.IAT && chapter.featureFlags!.combinedIatResults
  );
  const isIATResultsChapter = (
    (chapter.loType === ChapterType.Forward || chapter.loType === ChapterType.Video)
      && chapter.requiresTestTaker
  );

  return (
    isIATChapterWithCombinedResults
    || isIATResultsChapter
    || chapter.loType === ChapterType.LikertTest
    || chapter.loType === ChapterType.CCAResults
  );
};
