import PropTypes from 'prop-types';

import { coursePreviewType } from 'constants/propTypes';

import { LOCKED_STATUS } from 'constants/courseStatusTypes';
import { MICROLEARNING_COURSE } from 'constants/courseTypes';

import CourseStatusButton from 'components/shared/CourseStatusButton';

import {
  CourseImage,
  CoursePreviewWrapper,
  CourseTitle,
  CTAButtonWrapper,
} from './style';

const CoursePreview = ({
  course: {
    courseType,
    posterImageLink,
    status,
    title,
  },
  onClick,
}) => (
  <CoursePreviewWrapper
    aria-label={title}
    onClick={onClick}
    role="button"
  >
    <CourseImage
      isFaded={status === LOCKED_STATUS}
      role="img"
      url={posterImageLink}
    />
    <CourseTitle isSmall={courseType === MICROLEARNING_COURSE}>{title}</CourseTitle>
    <CTAButtonWrapper>
      <CourseStatusButton
        onClick={onClick}
        status={status}
      />
    </CTAButtonWrapper>
  </CoursePreviewWrapper>
);

CoursePreview.propTypes = {
  course: coursePreviewType.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CoursePreview;
