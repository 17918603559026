import styled, { keyframes } from 'styled-components';
import { media } from 'styles';

export const IATPromptLayoutWrapper = styled.div`
  position: relative;
  height: 100vh;
  padding: 36px 18px 36px;
  display: flex;
  flex-direction: column;

  ${media.twoDimMin.tablet`padding: 18px 18px 44px;`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const delayedFadeIn = keyframes`
  0% {opacity: 0}
  70% {opacity: 0}
  100% {opacity: 1}
`;

export const CTAWrapper = styled.div`
  position: absolute;
  right: 16px;

  /* Setting this to 5 to be above the header tappable area but below the header
   * itself in mobile so user can still tap the CTA
   */
  z-index: 5;

  animation: ${delayedFadeIn} 3000ms;

  ${media.twoDimMin.tablet`
    position: static;
    text-align: center;
  `}
`;
