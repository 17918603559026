import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_SSO_URL,
  fetchSSOUrl,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:fetchSSOUrl');

export function* fetchSSOUrlSaga({ payload }) {
  const { returnUrl } = payload;
  debug('Saga called', returnUrl);

  try {
    const redirectUrl = yield call(API.getSSOUrl, returnUrl);

    debug('Successfully fetched SSO redirect URL', redirectUrl);

    yield put(fetchSSOUrl.success(redirectUrl));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchSSOUrl.error(e));
  }
}

export default function* fetchSSOUrlDefaultSaga() {
  yield takeLatest(FETCH_SSO_URL.PENDING, fetchSSOUrlSaga);
}
