import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const DialogContent = styled.div`
  width: 100%;
  max-width: 700px;
  color: ${colors.sierra.base};
  padding: 48px 24px 24px;

  ${media.min.baseline`
    width: 500px;
    padding: 48px 36px 36px;
  `}
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: ${type.weight.bold};
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 44px;
`;

export const CancelButton = styled(TransparentButton)`
  color: ${colors.blue.base};
  margin-right: 44px;
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  outline: none;

  &.focus-visible { border-bottom: 2px solid ${colors.blue.light}; }
  ${media.min.baseline`font-size: 1.8rem;`}
`;
