/* eslint-disable no-param-reassign */
import i18n from 'lib/i18n';

import * as PromptType from 'constants/surveyPromptTypes';
import {
  MessageSubTypes,
  NOT_APPLICABLE,
  SurveyItemTypes,
} from './constants';

export const createCommentItem = parentItem => ({
  characterLimit: 250,
  hintText: `${i18n.t('courseViewer:survey.commentHintText')} "${parentItem.promptText}"`,
  id: `${parentItem.id}_comment`,
  parentPromptId: parentItem.id,
  promptType: PromptType.TextArea,
  type: SurveyItemTypes.Comment,
});

export const getItemsById = items => items.reduce((acc, item) => {
  acc[item.id] = item;
  return acc;
}, {});

export const getNextViewedItemIds = ({ id, promptType, subPrompts }, viewedItemIds) => {
  const nextViewedItemIds = [...viewedItemIds];

  if (promptType === PromptType.Matrix) {
    subPrompts.forEach((subPrompt) => !nextViewedItemIds.includes(subPrompt.id)
      && nextViewedItemIds.push(subPrompt.id));
  } else if (!nextViewedItemIds.includes(id)) {
    nextViewedItemIds.push(id);
  }

  return nextViewedItemIds;
};

export const createSurveyItems = survey => survey.prompts.map(prompt => {
  if (prompt.promptType === PromptType.Message || prompt.promptType === PromptType.Conclusion) {
    return ({
      ...prompt,
      subType: prompt.subType || MessageSubTypes.BasicMessage,
      type: SurveyItemTypes.Message,
    });
  }

  return ({
    ...prompt,
    type: SurveyItemTypes.Prompt,
  });
});

export const isPromptAnswered = (prompt, response) => {
  let isAnswered = true;

  if (response === undefined) isAnswered = false;
  else if (Array.isArray(response) && !response.length) isAnswered = false;
  else if (
    prompt.promptType === PromptType.Matrix
    && Object.keys(response).length !== prompt.subPrompts.length
  ) isAnswered = false;

  return isAnswered;
};

export const getNextRequiredItemIndex = (currentItemIndex, itemIds, itemsById) => {
  let nextIndex = itemIds.length - 1;

  for (let i = currentItemIndex + 1; i < itemIds.length; i += 1) {
    const item = itemsById[itemIds[i]];
    if (item.type === SurveyItemTypes.Prompt && !item.isOptional) {
      nextIndex = i;
      break;
    }
  }

  return nextIndex;
};

export const shouldPromptAutoAdvance = (item, response) => {
  let shouldAutoAdvance = true;
  const promptType = item?.promptType;

  if (promptType === PromptType.Matrix) {
    shouldAutoAdvance = isPromptAnswered(item, response);
  } else if (
    promptType === PromptType.TextArea
    || promptType === PromptType.Number
    || promptType === PromptType.Checkboxes
  ) {
    shouldAutoAdvance = false;
  }

  return shouldAutoAdvance;
};

const isValidSurveyResponse = surveyResponse => (
  !!surveyResponse
  && surveyResponse.response !== ''
  && (!Array.isArray(surveyResponse.response) || surveyResponse.response.length !== 0)
);

const handleCommentResponses = (item, responses) => (
  item.commentId
  && responses[item.commentId] !== ''
  && { comment: responses[item.commentId] });

export const getSurveyResponses = (responses, itemsById) => (
  Object.keys(responses).map(itemId => {
    let surveyResponse = null;
    const item = itemsById[itemId];

    if (item?.promptType === PromptType.Matrix) {
      const subPromptIds = Object.keys(responses[itemId]);

      surveyResponse = subPromptIds.map((subPromptId, idx) => {
        const response = responses[itemId][subPromptId];
        const subPromptResponse = {
          id: subPromptId,
          response: response === NOT_APPLICABLE ? null : response,
        };

        if (idx === subPromptIds.length - 1 && item.commentId && responses[item.commentId] !== '') {
          subPromptResponse.comment = responses[item.commentId];
        }

        return subPromptResponse;
      });
    } else if (item?.promptType === PromptType.Checkboxes) {
      const responseIds = responses[itemId] === NOT_APPLICABLE ? null : responses[itemId];
      const otherChoiceId = responseIds?.find(responseId => !!responses[responseId]);

      if (otherChoiceId) {
        surveyResponse = [
          { id: responses[otherChoiceId].subPromptId, response: responses[otherChoiceId].response },
          {
            id: itemId,
            response: responseIds,
            ...(handleCommentResponses(item, responses)),
          },
        ];
      } else {
        surveyResponse = {
          id: itemId,
          response: responseIds,
          ...(handleCommentResponses(item, responses)),
        };
      }
    } else if (item?.type === SurveyItemTypes.Prompt) {
      const responseId = responses[itemId] === NOT_APPLICABLE ? null : responses[itemId];
      const otherChoiceId = responses[responseId];

      if (otherChoiceId) {
        surveyResponse = [
          { id: otherChoiceId.subPromptId, response: otherChoiceId.response },
          {
            id: itemId,
            response: responseId,
            ...(handleCommentResponses(item, responses)),
          },
        ];
      } else {
        surveyResponse = {
          id: itemId,
          response: responseId,
          ...(handleCommentResponses(item, responses)),
        };
      }
    }

    return surveyResponse;
  })
    .flat()
    .filter(isValidSurveyResponse)
);
