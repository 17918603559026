import PropTypes from 'prop-types';

import {
  deviceTypeType,
  errorType,
  likertTestType,
  testResultType,
} from 'constants/propTypes';
import { MOBILE } from 'constants/deviceTypes';

import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import TestResult from 'components/LMS/CourseViewer/TestResult';

import LikertTest from './LikertTest';
import { ChapterWrapper } from './style';

const LikertChapter = ({
  chapterTitle,
  deviceType,
  error,
  isFetching,
  isPostingResponses,
  likertTest,
  likertTestResult,
  onContinue,
  onRetryError,
  onTestSubmit,
}) => {
  let content = null;

  if (isFetching) {
    content = <Loading />;
  } else if (error && !error.isUserInitiated) {
    content = (
      <ErrorView
        onRetryClick={error.retryAction ? onRetryError : null}
        text={error.message}
      />
    );
  } else if (likertTestResult) {
    content = (
      <TestResult
        result={likertTestResult}
        onContinue={onContinue}
      />
    );
  } else if (likertTest) {
    content = (
      <LikertTest
        chapterTitle={chapterTitle}
        deviceType={deviceType}
        isPostingResponses={isPostingResponses}
        likertTest={likertTest}
        onTestSubmit={onTestSubmit}
      />
    );
  }

  return (
    <ChapterWrapper isMobileDevice={deviceType === MOBILE}>
      {content}
    </ChapterWrapper>
  );
};

LikertChapter.propTypes = {
  chapterTitle: PropTypes.string.isRequired,
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  isFetching: PropTypes.bool.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  likertTest: likertTestType,
  likertTestResult: testResultType,
  onContinue: PropTypes.func.isRequired,
  onRetryError: PropTypes.func.isRequired,
  onTestSubmit: PropTypes.func.isRequired,
};

LikertChapter.defaultProps = {
  error: null,
  likertTest: null,
  likertTestResult: null,
};

export default LikertChapter;
