const createAction = type => payload => ({ payload, type });

export const ADD_COMMENT_CLICKED = 'addCommentClicked';
export const addCommentClicked = createAction(ADD_COMMENT_CLICKED);

export const ITEM_FOCUSED = 'itemFocused';
export const itemFocused = createAction(ITEM_FOCUSED);

export const ITEM_SUBMITTED = 'itemSubmitted';
export const itemSubmitted = createAction(ITEM_SUBMITTED);

export const RESPONSE_CHANGED = 'responseChanged';
export const responseChanged = createAction(RESPONSE_CHANGED);

export const SCROLLED = 'scrolled';
export const scrolled = createAction(SCROLLED);
