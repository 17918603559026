import {
  any,
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import { MessageSubTypes } from 'components/shared/Survey/constants';

import toastTypes from './toastTypes';
import * as PromptType from './surveyPromptTypes';
import * as ChapterType from './chapterTypes';
import { DESKTOP, MOBILE, TABLET } from './deviceTypes';
import { DEFAULT, BNPP, MERCK } from './policyTypes';

const chapterTypesList = Object.keys(ChapterType).map(type => ChapterType[type]);
const surveyPromptTypesList = Object.keys(PromptType).map(type => PromptType[type]);
const emptyUserMetadataType = shape({});

export const toastPropType = shape({
  autoDismiss: bool,
  message: string.isRequired,
  retryAction: shape({}),
  type: oneOf(toastTypes).isRequired,
});

export const routerMatchType = shape({
  params: shape({
    id: string,
  }).isRequired,
});

export const routerHistoryType = shape({
  push: func.isRequired,
});

export const deviceTypeType = oneOf([DESKTOP, MOBILE, TABLET]);

export const policyTypeType = oneOf([DEFAULT, BNPP, MERCK]);

export const companyType = shape({
  id: string.isRequired,
  name: string.isRequired,
});

export const userType = shape({
  company: companyType.isRequired,
  firstName: string.isRequired,
  id: string.isRequired,
  lastName: string,
  privacyPolicyType: policyTypeType,
});

export const videoUserMetadataType = shape({
  bookmark: number.isRequired,
  furthestPoint: number.isRequired,
  isCompleted: bool.isRequired,
});

export const iatUserMetadataType = shape({
  block: number.isRequired,
  isCompleted: bool.isRequired,
});

export const chapterType = shape({
  descriptionText: string,
  duration: number.isRequired,
  id: string.isRequired,
  loType: oneOf(chapterTypesList).isRequired,
  orderIndex: number.isRequired,
  posterImageLink: string.isRequired,
  requiresTestTaker: bool.isRequired,
  subType: string,
  title: string.isRequired,
  userMetadata: oneOfType([
    videoUserMetadataType,
    iatUserMetadataType,
    emptyUserMetadataType,
  ]).isRequired,
});

export const courseSectionType = shape({
  chapters: arrayOf(string).isRequired,
  id: string.isRequired,
  orderIndex: number.isRequired,
  title: string.isRequired,
});

export const courseType = shape({
  courseType: string.isRequired,
  id: string.isRequired,
  longDescription: string,
  sections: arrayOf(string).isRequired,
  shortDescription: string,
  title: string.isRequired,
});

export const coursePreviewType = shape({
  courseType: string.isRequired,
  id: string.isRequired,
  posterImageLink: string.isRequired,
  status: string.isRequired,
  title: string.isRequired,
});

export const eventAggregateResponsesType = objectOf(number);

export const eventResultsType = shape({
  aggregateResponses: arrayOf(shape({
    optionText: string.isRequired,
    value: number.isRequired,
  })).isRequired,
  displayTitle: string.isRequired,
});

export const ephemeralEventType = shape({
  aggregateResponses: eventAggregateResponsesType,
  eventSubtype: string.isRequired,
  eventType: string.isRequired,
  id: string.isRequired,
  isLoading: bool,
  options: objectOf(string),
  prompt: string.isRequired,
  stopPoint: number.isRequired,
});

export const persistentEventType = shape({
  endStopPoint: number.isRequired,
  eventSubtype: string.isRequired,
  eventType: string.isRequired,
  id: string.isRequired,
  isLoading: bool,
  prompt: string.isRequired,
  results: arrayOf(eventResultsType),
  startStopPoint: number.isRequired,
});

// IAT prop types

export const iatTrialType = shape({
  correctKey: string.isRequired,
  id: string.isRequired,
  stimulusContent: string.isRequired,
  stimulusType: string.isRequired,
});

export const iatBlockType = shape({
  blockIndex: number.isRequired,
  left: objectOf(string).isRequired,
  right: objectOf(string).isRequired,
  trials: arrayOf(iatTrialType).isRequired,
});

export const iatKeysConfigType = shape({
  left: string.isRequired,
  right: string.isRequired,
});

export const iatType = shape({
  blocks: arrayOf(iatBlockType).isRequired,
  description: string.isRequired,
  intertrialPause: number.isRequired,
  keysConfig: iatKeysConfigType.isRequired,
  loId: string.isRequired,
  title: string.isRequired,
  trialTimeout: number.isRequired,
});

// Likert test prop types

export const likertTestItemType = shape({
  id: string.isRequired,
  polarity: number.isRequired,
  statement: string.isRequired,
});

export const likertTestType = shape({
  items: arrayOf(likertTestItemType).isRequired,
  loId: string.isRequired,
  testType: string.isRequired,
});

// Survey prop types

export const surveyResponseType = oneOfType([
  string,
  bool,
  number,
  arrayOf(string),
  objectOf(string),
  objectOf(bool),
  objectOf(number),
  objectOf(oneOfType([string, bool])),
  objectOf(oneOfType([string, number])),
]);

export const surveySubPromptType = shape({
  graphicLink: string,
  id: string.isRequired,
  isOptional: bool,
  promptText: string.isRequired,
});

export const surveyPromptType = shape({
  characterLimit: number,
  hasNaOption: bool,
  id: string.isRequired,
  likertLabels: arrayOf(string),
  promptOptions: arrayOf(shape({
    id: string.isRequired,
    optionText: string.isRequired,
  })),
  promptText: string.isRequired,
  promptType: oneOf(surveyPromptTypesList).isRequired,
  scaleMax: number,
  scaleMaxLabel: string,
  scaleMinLabel: string,
  subPromptType: string,
  subPrompts: arrayOf(surveySubPromptType),
  type: string,
});

export const surveyCommentType = shape({
  characterLimit: number.isRequired,
  hintText: string.isRequired,
  id: string.isRequired,
  parentPromptId: string.isRequired,
  promptType: string.isRequired,
  type: string.isRequired,
});

export const surveyMessageType = shape({
  buttonLabel: string,
  id: string.isRequired,
  imageUrl: string,
  promptText: string,
  subPrompts: arrayOf(shape({
    id: string.isRequired,
    promptText: string.isRequired,
    promptType: string.isRequired,
  })),
  subType: oneOf([
    MessageSubTypes.BasicMessage,
    MessageSubTypes.MultiPage,
    MessageSubTypes.Title,
  ]).isRequired,
  type: string.isRequired,
});

export const surveyType = shape({
  descriptionText: string.isRequired,
  prompts: arrayOf(surveyPromptType).isRequired,
});

// Test result prop types (Likert)

export const testResultType = shape({
  graphUrl: string.isRequired,
  resultBucket: string.isRequired,
  resultText: string.isRequired,
});

export const resultType = shape({
  graphUrl: string.isRequired,
  resultText: string.isRequired,
});

export const surveyOptionType = shape({
  keyShortcut: string,
  label: string.isRequired,
  value: string.isRequired,
});

// Information prop types

export const informationCardType = shape({
  content: arrayOf(string),
  title: string,
});

// React ref prop type

export const refType = oneOfType([
  func,
  shape({ current: any }),
]);

// Store error prop type (errors that are set in the store when an async error occurs)

export const errorType = shape({
  isUserInitiated: bool,
  message: string,
  retryAction: shape({}),
  shouldRedirect: bool,
});

// Theme prop types

export const textFieldThemeType = shape({
  container: shape({
    background: string,
    border: shape({
      default: string,
      error: string,
    }),
  }),
  input: shape({
    color: shape({
      default: string,
    }),
  }),
  label: shape({
    color: shape({
      default: string,
      error: string,
    }),
  }),
});

export const radioButtonThemeType = shape({
  focus: shape({
    shadow: string.isRequired,
  }),
  selected: shape({
    background: string.isRequired,
    borderColor: string.isRequired,
  }),
  unselected: shape({
    background: string.isRequired,
    borderColor: string.isRequired,
  }),
});

// IAT old prop types to be removed

export const iatTestResultType = shape({
  aggregate: shape({
    graphUrl: string.isRequired,
    resultText: string.isRequired,
  }),
  individual: shape({
    graphUrl: string.isRequired,
    resultBucket: string.isRequired,
    resultText: string.isRequired,
  }),
});
