import { useTranslation } from 'react-i18next';

import { SimpleSurveyAnswer } from 'types';
import errorIcon from 'assets/iat-x-icon.svg';
import greenCheckIcon from 'assets/big-green-check.svg';

import {
  AnswerListItem,
  AnswerText,
  IconWrapper,
  PromptText,
} from './style';

type SimpleAnswerItemProps = {
  answer: SimpleSurveyAnswer;
};

const SimpleAnswerItem = ({
  answer,
}: SimpleAnswerItemProps) => {
  const { t } = useTranslation('courseViewer');
  const isCorrect = answer.responseText === answer.correctAnswerText;

  return (
    <AnswerListItem isCorrect={isCorrect}>
      <PromptText>{answer.promptText}</PromptText>
      <AnswerText>
        <strong>{t('courseViewer:survey.completion.responseLabel')}:</strong> {answer.responseText}
        <IconWrapper><img src={isCorrect ? greenCheckIcon : errorIcon} alt="" /></IconWrapper>
      </AnswerText>
      {!isCorrect && (
        <AnswerText>
          <strong>{t('courseViewer:survey.completion.correctAnswerLabel')}:</strong> {answer.correctAnswerText}
        </AnswerText>
      )}
    </AnswerListItem>
  );
};

export default SimpleAnswerItem;
