import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';
import { withTranslation } from 'react-i18next';

import {
  chapterType,
  deviceTypeType,
  errorType,
  likertTestType,
  testResultType,
} from 'constants/propTypes';
import * as ToastTypes from 'constants/toastTypes';

import { isDemoCourseSelector } from 'store/courses/selectors';
import { uaDeviceTypeSelector } from 'store/userAgent/selectors';
import {
  isLikertFetchingSelector,
  isPostingLikertResponsesSelector,
  likertTestDataSelector,
  likertTestErrorSelector,
  likertTestResultSelector,
} from 'store/likertTest/selectors';

import * as uiActions from 'store/ui/actions';
import {
  fetchLikertTest,
  fetchLikertTestResult,
  submitLikertTest,
  resetLikertStore,
} from 'store/likertTest/actions';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';

import LikertChapter from './LikertChapter';

const mapStateToProps = state => ({
  deviceType: uaDeviceTypeSelector(state),
  error: likertTestErrorSelector(state),
  isDemoCourse: isDemoCourseSelector(state),
  isFetching: isLikertFetchingSelector(state),
  isPostingResponses: isPostingLikertResponsesSelector(state),
  likertTest: likertTestDataSelector(state),
  likertTestResult: likertTestResultSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchLikertTest: chapterId => dispatch(fetchLikertTest(chapterId)),
  fetchLikertTestResult: chapterId => dispatch(fetchLikertTestResult(chapterId)),
  onRetryError: action => dispatch(action),
  resetLikertStore: () => dispatch(resetLikertStore()),
  submitLikertTest: payload => dispatch(submitLikertTest(payload)),
  toggleToast: uiActions.toggleOpenToast,
});

@track({})
export class LikertChapterContainer extends Component {
  componentDidMount() {
    this.initializeChapter();
  }

  componentDidUpdate(prevProps) {
    const {
      chapter,
      isDemoCourse,
      likertTestResult,
      t,
      toggleToast,
    } = this.props;

    if (chapter.id !== prevProps.chapter.id) this.initializeChapter();

    if (isDemoCourse && likertTestResult !== prevProps.likertTestResult) {
      toggleToast({ message: t('courseViewer:likertTest.demoNotification'), type: ToastTypes.TOAST_NOTIFICATION });
    }
  }

  handleRetryError = () => {
    this.props.onRetryError(this.props.error.retryAction);
  };

  initializeChapter = () => {
    const { chapter } = this.props;
    this.props.resetLikertStore();

    if (!chapter.userMetadata.isCompleted) {
      this.props.fetchLikertTest(chapter.id);
    } else {
      this.props.fetchLikertTestResult(chapter.id);
    }
  };

  @track({ event: 'Likert Test Submit Button Clicked' })
  handleTestSubmit = testResponses => {
    this.props.submitLikertTest(testResponses);
  };

  render() {
    const {
      chapter,
      deviceType,
      error,
      goToNextChapter,
      isFetching,
      isPostingResponses,
      likertTest,
      likertTestResult,
    } = this.props;

    return (
      <DeviceOrientationObserver>
        <LikertChapter
          chapterTitle={chapter.title}
          deviceType={deviceType}
          error={error}
          isFetching={isFetching}
          isPostingResponses={isPostingResponses}
          likertTest={likertTest}
          likertTestResult={likertTestResult}
          onContinue={goToNextChapter}
          onRetryError={this.handleRetryError}
          onTestSubmit={this.handleTestSubmit}
        />
      </DeviceOrientationObserver>
    );
  }
}

LikertChapterContainer.propTypes = {
  chapter: chapterType.isRequired,
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  fetchLikertTest: PropTypes.func.isRequired,
  fetchLikertTestResult: PropTypes.func.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  likertTest: likertTestType,
  likertTestResult: testResultType,
  onRetryError: PropTypes.func.isRequired,
  resetLikertStore: PropTypes.func.isRequired,
  submitLikertTest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
};

LikertChapterContainer.defaultProps = {
  error: null,
  likertTest: null,
  likertTestResult: null,
};

const withTranslationLikertChapterContainer = withTranslation('courseViewer')(LikertChapterContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslationLikertChapterContainer);
