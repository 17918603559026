export const iatErrorSelector = state => state.iat.error;
export const iatDataSelector = state => state.iat.iatData;
export const iatTotalNumOfBlocksSelector = state => (
  state.iat.iatData && state.iat.iatData.blocks.length
);
export const isIATFetchingSelector = state => state.iat.isFetching;
export const isPostingIATBlockResponsesSelector = state => state.iat.isPostingBlockResponses;
export const isSubmittingSkipIATSelector = state => state.iat.isSubmittingSkipIAT;

// Temporary IAT old
export const iatResultSelector = state => state.iat.iatResult;
