import React from 'react';
import PropTypes from 'prop-types';

import SimpleButton from 'components/shared/SimpleButton';
import {
  Content,
  CTAWrapper,
  Message,
  Title,
  Wrapper,
} from './style';

const SimpleMessageButtonView = ({
  buttonText,
  message,
  onContinue,
  title,
}) => (
  <Wrapper>
    <Content>
      <Title>{title}</Title>
      {message && <Message>{message}</Message>}
    </Content>
    <CTAWrapper>
      <SimpleButton onClick={onContinue} variant="white">
        {buttonText}
      </SimpleButton>
    </CTAWrapper>
  </Wrapper>
);

SimpleMessageButtonView.propTypes = {
  buttonText: PropTypes.string.isRequired,
  message: PropTypes.string,
  onContinue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

SimpleMessageButtonView.defaultProps = {
  message: null,
};

export default SimpleMessageButtonView;
