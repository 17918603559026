const BasicMessage = 'basic_message';
const MultiPage = 'multi_page';
const Title = 'title';

const Comment = 'comment';
const Prompt = 'prompt';
const Message = 'message';

const Standard = 'standard';
const Vignette = 'vignette';
const Familiarity = 'familiarity';

export const SurveyItemTypes = {
  Comment,
  Message,
  Prompt,
};

export const MessageSubTypes = {
  BasicMessage,
  MultiPage,
  Title,
};

export const SurveySubTypes = {
  Familiarity,
  Standard,
  Vignette,
};

export const NOT_APPLICABLE = 'not_applicable';
