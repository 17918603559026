import styled from 'styled-components';

import TransparentButton from 'styles/components/TransparentButton';
import flashingAnimation from 'styles/animations/flashing';
import noSelectionCallout from 'styles/mixins/noSelectionCallout';
import { colors, media, type } from 'styles';

export const IATTrialWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const CategoriesWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  right: 0;
  z-index: 1;
  pointer-events: none;
  padding: 32px;

  ${({ isHeader }) => (isHeader ? `
    top: 0;
    padding: 28px 20px;
  ` : `
    bottom: 0;
  `)}

  ${({ isDisabled }) => isDisabled && 'opacity: .5;'}
`;

export const IATSideButton = styled(TransparentButton).attrs({
  // Retroactive comment: this was necessary so that tapping could work properly
  onTouchStart: '',
})`
  position: absolute;
  background-color: rgba(190, 207, 217, 0.22);
  width: 50%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  touch-action: manipulation; /* Prevents double-tap on touch devices from zooming element */

  &:active { background-color: rgba(190, 207, 217, 0.32); }

  ${({ flashing }) => flashing && flashingAnimation}

  ${noSelectionCallout}

  ${({ orientation }) => (orientation === 'left' ? `
    left: -25%;
  ` : `
    right: -25%;
  `)}

  & > span {
    display: inline-block;
    position: relative;
    letter-spacing: 2px;
    color: ${colors.sierra.background};
    font-size: 1rem;
    top: 95px;
    text-transform: uppercase;

    ${p => (p.orientation === 'left' ? 'left: 68px;' : 'right: 68px;')};

    ${media.twoDimMin.tablet`top: 225px;`}
  }
`;

export const StimulusWrapper = styled.div`
  position: relative;
  pointer-events: none;
`;

export const ImageStimulus = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 5%;
  ${({ url }) => `background-image: url(${url});`}
  background-size: cover;
  background-position: center;

  ${media.twoDimMin.baseline`
    width: 260px;
    height: 260px;
  `}

  ${media.twoDimMin.desktop`
    width: 300px;
    height: 300px;
  `}

  ${media.twoDimMin.max`
    width: 330px;
    height: 330px;
  `}
`;

export const TextStimulus = styled.div`
  font-size: 5rem;

  ${media.twoDimMin.baseline`font-size: 7.2rem;`}
  ${media.twoDimMin.desktop`font-size: 9rem;`}
  ${media.twoDimMin.max`font-size: 10rem;`}
`;

export const PromptText = styled.div`
  position: absolute;
  top: ${({ isImageStimulus }) => (isImageStimulus ? '-40px' : '-80px')};
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
  text-align: center;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  font-size: 1.8rem;

  ${media.twoDimMin.baseline`
    top: -80px;
    font-size: 2.6rem;
  `}
  ${media.twoDimMin.desktop`
    top: -95px;
    font-size: 3rem;
  `}
  ${media.twoDimMin.max`
    top: -110px;
    font-size: 3.4rem;
  `}
`;

export const AlertWrapper = styled.div`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '-60px' : '-75px')};
  left: 50%;
  transform: translateX(-50%);
  width: 50vw;

  ${media.twoDimMin.baseline`top: -120px;`}
  ${media.twoDimMin.desktop`top: -140px;`}
  ${media.twoDimMin.max`top: -165px;`}
`;
