module.exports = {
  hubspotKey: '21031833',
  isAnalyticsEnabled: true,
  isMonitoringEnabled: true,
  segmentKey: '9wAnhgBtnGAcd4GfUZ2XYZthfsO57cj2',
  sentryDSNUrl: 'https://137959a3415946af8bb1545650eb4556@sentry.io/1449059',
  supportedFeatureFlags: [
    'f_override_lock',
  ],
  supportedLanguages: [
    'en',
    'es',
    'wd', // Test language used only in dev
  ],
  techIssueUrl: 'https://go.biassync.com/biassync-technical-support-form-page',
};
