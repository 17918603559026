import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTracking } from 'react-tracking';

import { usePrevious } from 'lib/customHooks';
import { Chapter, InformationCard } from 'types';

import { fetchInformationCard } from 'store/informationCard/actions';
import { sendChapterCompletedBookmark } from 'store/chapters/actions';
import {
  informationCardDataSelector,
  informationCardErrorSelector,
  isInformationCardLoadingSelector,
} from 'store/informationCard/selectors';
import {
  chaptersErrorSelector,
  isSendingCompletedBookmarkSelector,
} from 'store/chapters/selectors';

import InformationCardChapter from './InformationCardChapter';

type InformationCardChapterContainerProps = {
  chapter: Chapter;
  goToNextChapter: () => void;
};

const InformationCardChapterContainer = ({
  chapter,
  goToNextChapter,
}: InformationCardChapterContainerProps) => {
  const { id: chapterId, userMetadata } = chapter;
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();

  const bookmarkError = useSelector(chaptersErrorSelector);
  const fetchError = useSelector(informationCardErrorSelector);
  const informationCard: InformationCard = useSelector(informationCardDataSelector);
  const isLoading = useSelector(isInformationCardLoadingSelector);
  const isSendingCompletedBookmark = useSelector(isSendingCompletedBookmarkSelector);

  useEffect(() => {
    dispatch(fetchInformationCard(chapter.id));
  }, []);

  const prevIsSendingCompletedBookmark = usePrevious(isSendingCompletedBookmark);
  useEffect(() => {
    if (prevIsSendingCompletedBookmark && !isSendingCompletedBookmark && !bookmarkError) goToNextChapter();
  }, [prevIsSendingCompletedBookmark, isSendingCompletedBookmark, bookmarkError]);

  const handleFinished = () => {
    trackEvent({ event: 'Finished Button Clicked' });

    if (!userMetadata.isCompleted) dispatch(sendChapterCompletedBookmark({ chapterId }));
    else goToNextChapter();
  };

  return (
    <InformationCardChapter
      error={fetchError || bookmarkError}
      informationCard={informationCard}
      isLoading={isLoading || isSendingCompletedBookmark}
      onFinished={handleFinished}
      onRetryError={action => dispatch(action)}
    />
  );
};

export default InformationCardChapterContainer;
