import styled from 'styled-components';
import { media, type } from 'styles';

export const LikertScaleWrapper = styled.ul`
  display: flex;
  padding: 0 6px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  ${media.min.baseline`
    width: 85%;
    padding: 0;
  `}
`;

export const RatingItemWrapper = styled.li`
  list-style-type: none;
  display: inline-block;
  flex: 1;

  & + & { margin-left: 8px; }

  ${media.min.baseline`& + & { margin-left: 12px; }`}
`;

export const RatingWrapper = styled.div`
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  height: 100%;
  padding: 10px 10px 18px;

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;

export const KeyWrapper = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 6px;
  color: ${p => p.theme.likert.text.color};
  background-color: ${p => (p.isSelected ? p.theme.likert.selected.background : p.theme.likert.unselected.background)};

  ${media.min.tablet`
    width: 35px;
    height: 35px;
    line-height: 35px;
  `}

  ${media.min.desktop`
    width: 40px;
    height: 40px;
    line-height: 40px;
  `}
`;

export const RatingText = styled.div`
  margin-top: 12px;
  color: ${p => (p.isSelected ? p.theme.likert.selected.color : p.theme.likert.unselected.color)};

  ${media.min.tablet`margin-top: 18px;`}
`;
