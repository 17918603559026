import styled from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';
import StyledExternalLink from 'styles/components/StyledExternalLink';

export const ErrorViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 20%;

  ${({ hasBackground }) => hasBackground && `
    background: url(${backgroundSVG}) ${colors.sierra.base} center;
  `}
`;

export const AlertIconWrapper = styled.div`
  width: 40px;
  height: 40px;

  ${media.twoDimMin.baseline`
    width: 50px;
    height: 50px;
  `}
`;

export const ErrorText = styled.span`
  margin-top: 44px;
  font-size: 1.6rem;
  font-weight: ${type.weight.semiBold};
  white-space: pre-wrap;
  text-align: center;

  ${media.twoDimMin.baseline`font-size: 2rem;`}
`;

export const TechIssueLinkText = styled.span`
  position: absolute;
  bottom: 0;
  font-size: 1.2rem;
  text-align: center;
  padding: 32px;

  ${media.twoDimMin.baseline`
    font-size: 1.4rem;
    padding: 54px;
  `}
`;

export const ExternalLink = styled(StyledExternalLink)`
  color: ${colors.yellow.base};
  font-weight: ${type.weight.semiBold};
`;

export const CTAWrapper = styled.div`
  margin-top: 32px;
  ${media.twoDimMin.baseline`margin-top: 54px;`}
`;
