import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import downloadPDF from 'lib/downloadPDF';

import {
  currentChapterIdSelector,
  currentCourseIdSelector,
} from 'store/courses/selectors';
import { isIOSChromeSelector } from 'store/userAgent/selectors';
import { chapterCompleted } from 'store/courses/actions';

import {
  SUBMIT_FILE_DOWNLOAD_CHOICE,
  submitFileDownloadChoice,
} from '../actions';

const debug = Debug('biassync:lms:store:fileDownload:sagas:submitFileDownloadChoice');

export function* submitFileDownloadChoiceSaga(action) {
  const { payload: shouldDownload } = action;
  debug('Saga called', shouldDownload);

  const chapterId = yield select(currentChapterIdSelector);

  try {
    const response = yield call(
      API.sendFileDownloadChoice,
      chapterId,
      { did_download: shouldDownload },
      (shouldDownload ? { responseType: 'blob' } : undefined),
    );

    const courseId = yield select(currentCourseIdSelector);
    yield put(chapterCompleted({ chapterId, courseId }));

    try {
      if (shouldDownload) {
        const isIOSChrome = yield select(isIOSChromeSelector);
        yield call(downloadPDF, response, isIOSChrome);
      }

      yield put(submitFileDownloadChoice.success());
    } catch (e) {
      debug('Saga download error', e);
      yield put(submitFileDownloadChoice.error(e, action));
    }
  } catch (e) {
    debug('Saga request error', e);
    yield put(submitFileDownloadChoice.error(e, action));
  }
}

export default function* submitFileDownloadChoiceDefaultSaga() {
  yield takeLatest(SUBMIT_FILE_DOWNLOAD_CHOICE.PENDING, submitFileDownloadChoiceSaga);
}
