import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/shared/PageTitle';
import {
  AUTH_CODE_ANONYMOUS_LOGIN,
  AUTH_CODE_LOGIN,
  MAGIC_LINK_LOGIN,
  PASSWORD_LOGIN,
  SSO_LOGIN,
} from 'constants/loginFlowTypes';
import {
  didGenerateMagicLinkSelector,
  isAuthLoadingSelector,
  isUserRegistrationConflictErrorSelector,
  loginFlowTypeSelector,
  ssoUrlSelector,
} from 'store/auth/selectors';
import * as authActions from 'store/auth/actions';

import AuthenticationLayout from 'components/shared/AuthenticationLayout';
import RegistrationConflictRedirect from 'components/shared/RegistrationConflictRedirect';

import AuthCodeLogin from './AuthCodeLogin';
import MagicLinkLogin from './MagicLinkLogin';
import PasswordLogin from './PasswordLogin';
import SSOLogin from './SSOLogin';

const mapStateToProps = state => ({
  didGenerateMagicLink: didGenerateMagicLinkSelector(state),
  isLoading: isAuthLoadingSelector(state),
  isUserRegistrationConflictError: isUserRegistrationConflictErrorSelector(state),
  loginFlowType: loginFlowTypeSelector(state),
  ssoUrl: ssoUrlSelector(state),
});

const mapDispatchToProps = {
  fetchSSOUrl: authActions.fetchSSOUrl,
  generateMagicLink: authActions.generateMagicLink,
  loginWithAuthCode: authActions.loginWithAuthCode,
  loginWithAuthCodeAnonymous: authActions.loginWithAuthCodeAnonymous,
  loginWithPassword: authActions.loginWithPassword,
};

export const Login = ({
  didGenerateMagicLink,
  fetchSSOUrl,
  generateMagicLink,
  isLoading,
  isUserRegistrationConflictError,
  loginFlowType,
  loginWithAuthCode,
  loginWithAuthCodeAnonymous,
  loginWithPassword,
  ssoUrl,
}) => {
  const { t } = useTranslation('auth');

  let content;
  if (isUserRegistrationConflictError) {
    content = (
      <RegistrationConflictRedirect
        message={t('auth:passwordLogin.redirectToRegisterMessage')}
        redirectPath="/register"
      />
    );
  } else {
    content = (
      <>
        {(loginFlowType === MAGIC_LINK_LOGIN) && (
          <MagicLinkLogin
            didGenerateMagicLink={didGenerateMagicLink}
            isLoading={isLoading}
            onSubmit={generateMagicLink}
          />
        )}
        {(loginFlowType === PASSWORD_LOGIN) && (
          <PasswordLogin
            isLoading={isLoading}
            onSubmit={loginWithPassword}
          />
        )}
        {(loginFlowType === SSO_LOGIN) && (
          <SSOLogin
            isLoading={isLoading}
            onSubmit={fetchSSOUrl}
            ssoUrl={ssoUrl}
          />
        )}
        {(loginFlowType === AUTH_CODE_LOGIN) && (
          <AuthCodeLogin
            isLoading={isLoading}
            onSubmit={loginWithAuthCode}
          />
        )}
        {(loginFlowType === AUTH_CODE_ANONYMOUS_LOGIN) && (
          <AuthCodeLogin
            isLoading={isLoading}
            onSubmit={loginWithAuthCodeAnonymous}
          />
        )}
      </>
    );
  }

  return (
    <>
      <PageTitle subPageTitle={t('auth:pageTitle')} />
      <AuthenticationLayout>
        {content}
      </AuthenticationLayout>
    </>
  );
};

Login.propTypes = {
  didGenerateMagicLink: PropTypes.bool.isRequired,
  fetchSSOUrl: PropTypes.func.isRequired,
  generateMagicLink: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  loginFlowType: PropTypes.string,
  loginWithAuthCode: PropTypes.func.isRequired,
  loginWithAuthCodeAnonymous: PropTypes.func.isRequired,
  loginWithPassword: PropTypes.func.isRequired,
  ssoUrl: PropTypes.string,
};

Login.defaultProps = {
  loginFlowType: null,
  ssoUrl: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
