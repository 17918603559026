import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import track, { TrackingPropType } from 'react-tracking';

import AppStorage from 'lib/appStorage';
import { SELECTED_LANGUAGE } from 'lib/i18n';

import { userLanguageErrorSelector } from 'store/user/selectors';

import LanguageChangeErrorModal from './LanguageChangeErrorModal';

export const LanguageChangeErrorModalContainer = ({
  languageCode,
  onClose,
  tracking,
}) => {
  const dispatch = useDispatch();
  const { retryAction } = useSelector(userLanguageErrorSelector);

  const handleProceed = () => {
    tracking.trackEvent({ event: 'Proceed Button Clicked' });

    AppStorage.setItem(SELECTED_LANGUAGE, languageCode);
    window.location.reload();
  };

  const handleRetry = () => {
    tracking.trackEvent({ event: 'Retry Button Clicked' });

    dispatch(retryAction);
    onClose();
  };

  return (
    <LanguageChangeErrorModal
      onProceed={handleProceed}
      onRetry={handleRetry}
    />
  );
};

LanguageChangeErrorModalContainer.propTypes = {
  languageCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

LanguageChangeErrorModalContainer.defaultProps = {
  tracking: null,
};

const trackedLanguageChangeErrorModal = track({ module: 'LanguageChangeErrorModal' }, {
  dispatchOnMount: () => ({ event: 'Language Selector Retry Modal Shown' }),
})(LanguageChangeErrorModalContainer);

export default trackedLanguageChangeErrorModal;
