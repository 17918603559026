import styled from 'styled-components';

import { media, type } from 'styles';

import theme from '../../../theme';

export const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;;
  border-radius: 6px;
  padding: 30px 20px;

  border: solid 2px ${theme.accent.web};
  ${media.print`border: solid 2px ${theme.accent.print};`}

  &:nth-child(1),
  &:nth-child(2) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  ${media.min.baseline`
    min-height: 450px;
    padding: 28px 24px;
    flex-direction: column;
    justify-content: flex-start;

    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  `}
  ${media.min.desktop`padding: 28px 32px;`}
`;

export const Content = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  ${media.min.baseline`margin-left: 0;`}
`;

export const Number = styled.div`
  font-size: 4rem;
  color: transparent;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  min-width: 25px;

  -webkit-text-stroke: 2px ${theme.accent.web};
  ${media.print`-webkit-text-stroke: 2px ${theme.accent.print};`}

  ${media.min.tablet`
    font-size: 4.8rem;
    min-width: 30px;
  `}
  ${media.min.baseline`
    font-size: 5.8rem;
    margin-bottom: 28px;
  `}
  ${media.min.desktop`
    font-size: 6.8rem;
    margin-bottom: 34px;
  `}
`;

export const Title = styled.h6`
  font-size: 1.4rem;
  font-weight: ${type.weight.bold};
  margin: 0 0 16px 0;

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}
  ${media.min.desktop`font-size: 2rem;`}
`;

export const Body = styled.p`
  font-size: 1.2rem;
  margin: 0;

  ${media.min.tablet`font-size: 1.4rem;`}
  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;
