import { createSelector } from 'reselect';

import { SETTINGS_MENU } from 'constants/menus';
import { TABLE_OF_CONTENTS_PANEL, STORY_PANEL } from 'constants/panels';
import * as ChapterType from 'constants/chapterTypes';
import * as ModalTypes from 'constants/modalTypes';

import { currentChapterTypeSelector } from 'store/courses/selectors';
import { isVideoPlayingSelector } from 'store/video/selectors';
import {
  isMobileDeviceSelector,
  isTouchScreenSelector,
} from 'store/userAgent/selectors';

export const currentOpenMenuSelector = state => state.ui.currentOpenMenu;
export const currentOpenModalsSelector = state => state.ui.currentOpenModals;
export const currentOpenPanelSelector = state => state.ui.currentOpenPanel;
export const currentOpenToastSelector = state => state.ui.currentOpenToast;
export const isHeaderVisibilityRequestedSelector = state => (
  state.ui.isHeaderVisibilityRequested
);

export const isAnyModalOpenSelector = createSelector(
  currentOpenModalsSelector,
  currentOpenModals => !!currentOpenModals.length,
);

export const isBlockingModalOpenSelector = createSelector(
  currentOpenModalsSelector,
  currentOpenModals => currentOpenModals.some(modal => (
    modal.modalType === ModalTypes.ACCESSIBILITY_CHECK
    || modal.modalType === ModalTypes.POLICY_CONSENT
  )),
);

export const isSettingsMenuOpenSelector = createSelector(
  currentOpenMenuSelector,
  currentOpenMenu => currentOpenMenu === SETTINGS_MENU,
);

export const isTableOfContentsPanelOpenSelector = createSelector(
  currentOpenPanelSelector,
  currentOpenPanel => currentOpenPanel === TABLE_OF_CONTENTS_PANEL,
);

export const isStoryPanelOpenSelector = createSelector(
  currentOpenPanelSelector,
  currentOpenPanel => currentOpenPanel === STORY_PANEL,
);

export const isHeaderVisibleSelector = createSelector(
  currentChapterTypeSelector,
  isVideoPlayingSelector,
  isSettingsMenuOpenSelector,
  isTableOfContentsPanelOpenSelector,
  isHeaderVisibilityRequestedSelector,
  isTouchScreenSelector,
  isMobileDeviceSelector,
  (
    currentChapterType,
    isVideoPlaying,
    isSettingsMenuOpen,
    isTableOfContentsOpen,
    isHeaderVisibilityRequested,
    isTouchScreen,
    isMobileDevice,
  ) => {
    switch (currentChapterType) {
      case ChapterType.IAT:
      case ChapterType.IATTutorial:
        return !isTouchScreen
          || isTableOfContentsOpen
          || isSettingsMenuOpen
          || isHeaderVisibilityRequested;
      case ChapterType.Video:
        return !isVideoPlaying
          || isTableOfContentsOpen
          || isSettingsMenuOpen
          || isHeaderVisibilityRequested;
      default:
        return !isMobileDevice
          || isTableOfContentsOpen
          || isSettingsMenuOpen
          || isHeaderVisibilityRequested;
    }
  },
);
