import styled from 'styled-components';
import { colors, media } from 'styles';

import TransparentButton from 'styles/components/TransparentButton';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  min-width: 100px;
  ${p => p.isHidden && 'visibility: hidden;'}

  ${media.min.tablet`
    padding: 15px 0;
    min-width: 125px;
  `}
`;

export const Button = styled(TransparentButton)`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  outline: none;
  
  &:active { background-color: rgba(190, 207, 217, 0.32); }
  ${media.min.tablet`padding: 28px 0;`}

  & > span {
    letter-spacing: 2px;
    color: ${colors.white};
    font-size: 1rem;
    text-transform: uppercase;
    user-select: none;
    margin-top: 30px;

    ${media.min.tablet`font-size: 1.2rem;`}
    ${media.min.desktop`font-size: 1.4rem;`}
  }

  &.focus-visible {
    border-radius: 9999px;
    box-shadow: ${colors.blue.light} 0px 0px 0px 3px;
  }
`;

export const ButtonArrow = styled.div`
  border: solid ${colors.white};
  border-width: 0 3px 3px 0;
  height: 22px;
  width: 22px;
  transform: ${p => (p.direction === 'left' ? 'rotate(135deg)' : 'rotate(-45deg)')};
  margin-right: ${p => (p.direction === 'left' ? '-6px' : '6px')};

  ${media.min.tablet`
    border-width: 0 4px 4px 0;
    height: 24px;
    width: 24px;
  `}

  ${media.min.desktop`
    height: 26px;
    width: 26px;
  `}
`;
