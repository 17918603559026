import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { coursePreviewType, errorType } from 'constants/propTypes';
import { TOAST_NOTIFICATION } from 'constants/toastTypes';
import { LOCKED_STATUS } from 'constants/courseStatusTypes';

import {
  coursePreviewsSelector,
  coursesErrorSelector,
  currentCourseIdSelector,
  isCoursesLoadingSelector,
} from 'store/courses/selectors';
import { shouldShowMicrosAsOptionalSelector } from 'store/user/selectors';
import * as courseActions from 'store/courses/actions';
import * as uiActions from 'store/ui/actions';

import CourseSelector from './CourseSelector';

const mapStateToProps = (state) => ({
  coursePreviews: coursePreviewsSelector(state),
  currentCourseId: currentCourseIdSelector(state),
  error: coursesErrorSelector(state),
  isFetchingCourses: isCoursesLoadingSelector(state),
  shouldShowMicrosAsOptional: shouldShowMicrosAsOptionalSelector(state),
});

const mapDispatchToProps = {
  fetchCourses: courseActions.fetchCourses,
  toggleToast: uiActions.toggleOpenToast,
};

export const CourseSelectorContainer = ({
  coursePreviews,
  currentCourseId,
  error,
  fetchCourses,
  isFetchingCourses,
  shouldShowMicrosAsOptional,
  toggleToast,
  tracking: { trackEvent },
}) => {
  const { t } = useTranslation(['courseSelector', 'errors']);
  const navigate = useNavigate();

  useEffect(() => {
    if (!coursePreviews) fetchCourses();
  }, []);

  useEffect(() => {
    if (coursePreviews?.length === 1 && !currentCourseId) {
      navigate(`/courses/${coursePreviews[0].id}`);
    }
  }, [coursePreviews]);

  const handleCourseSelect = (course) => {
    if (course.status !== LOCKED_STATUS) {
      trackEvent({ courseName: course.title, event: 'Course Clicked' });
      navigate(`/courses/${course.id}`);
    } else {
      toggleToast({
        message: t('errors:lockedCourse'),
        type: TOAST_NOTIFICATION,
      });
      trackEvent({ courseName: course.title, event: 'Locked Course Clicked' });
    }
  };

  return (
    <CourseSelector
      areCoursePreviewsAvailable={!!coursePreviews?.length}
      coursePreviews={coursePreviews}
      error={error}
      haveCoursePreviewsBeenFetched={!!coursePreviews}
      isFetchingCourses={isFetchingCourses}
      onCourseSelect={handleCourseSelect}
      shouldShowMicrosAsOptional={shouldShowMicrosAsOptional}
      t={t}
    />
  );
};

CourseSelectorContainer.propTypes = {
  coursePreviews: PropTypes.arrayOf(coursePreviewType),
  currentCourseId: PropTypes.string,
  error: errorType,
  fetchCourses: PropTypes.func.isRequired,
  isFetchingCourses: PropTypes.bool.isRequired,
  shouldShowMicrosAsOptional: PropTypes.bool.isRequired,
  toggleToast: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

CourseSelectorContainer.defaultProps = {
  coursePreviews: null,
  currentCourseId: null,
  error: null,
  tracking: null,
};

const trackedCourseSelectorContainer = track({
  page: 'Course Selector',
})(CourseSelectorContainer);

export default connect(mapStateToProps, mapDispatchToProps)(trackedCourseSelectorContainer);
