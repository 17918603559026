import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';
import { getViewportSize, getInputInterface } from 'lib/deviceInformation';

import { updateChapterUserMetadata } from 'store/entities/chapters/actions';
import { chapterCompleted } from 'store/courses/actions';
import { handleChapterChanged } from 'store/chapters/actions';
import { uaDeviceTypeSelector } from 'store/userAgent/selectors';
import { iatTotalNumOfBlocksSelector } from 'store/iat/selectors';
import {
  currentChapterIdSelector,
  currentCourseIdSelector,
  isIATChapterCombinedResultsSelector,
  nextChapterIdSelector,
} from 'store/courses/selectors';

import {
  fetchIATResult,
  SEND_IAT_BLOCK_RESULTS,
  sendIATBlockResults,
} from '../actions';

const debug = Debug('biassync:lms:store:iat:sagas:sendIATBlockResults');

export function* sendIATBlockResultsSaga({ payload }) {
  debug('Saga called', payload);
  const { blockNumber, blockResults } = payload;

  const userDeviceType = yield select(uaDeviceTypeSelector);
  const courseId = yield select(currentCourseIdSelector);
  const chapterId = yield select(currentChapterIdSelector);
  const totalNumOfBlocks = yield select(iatTotalNumOfBlocksSelector);
  const isLastBlock = blockNumber === totalNumOfBlocks;

  try {
    yield call(
      API.sendIATBlockResults,
      chapterId,
      blockNumber,
      decamelizeKeys({
        blockResults,
        deviceInputInterface: getInputInterface(userDeviceType),
        deviceType: userDeviceType,
        deviceViewportSize: getViewportSize(),
      }),
    );

    if (!isLastBlock) {
      yield put(updateChapterUserMetadata({
        chapterId,
        courseId,
        userMetadata: { block: blockNumber + 1 },
      }));
    } else {
      yield put(chapterCompleted({ chapterId, courseId }));
      const isCombinedResults = yield select(isIATChapterCombinedResultsSelector);

      if (isCombinedResults) yield put(fetchIATResult(chapterId));
      else {
        const nextChapterId = yield select(nextChapterIdSelector);
        yield put(handleChapterChanged({ chapterId: nextChapterId, courseId }));
      }
    }

    yield put(sendIATBlockResults.success());
  } catch (e) {
    debug('API error', e);
    yield put(sendIATBlockResults.error(e));
  }
}

export default function* sendIATBlockResultsDefaultSaga() {
  yield takeLatest(SEND_IAT_BLOCK_RESULTS.PENDING, sendIATBlockResultsSaga);
}
