import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';

import API from 'services/API';

import { isDemoCompanySelector } from 'store/user/selectors';

import {
  EDIT_USER_LANGUAGE,
  editUserLanguage,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:editUserLanguage');

export function* editUserLanguageSaga({ payload: languageCode }) {
  debug('Saga called', languageCode);

  const isDemoCompany = yield select(isDemoCompanySelector);

  try {
    if (!isDemoCompany) {
      yield call(API.editUser, { language_code: languageCode });
    }

    debug('User language successfully updated');

    yield put(editUserLanguage.success({ languageCode }));
  } catch (e) {
    debug('Saga error', e);

    yield put(editUserLanguage.error(e));
  }
}

export default function* editUserLanguageDefaultSaga() {
  yield takeLatest(EDIT_USER_LANGUAGE.PENDING, editUserLanguageSaga);
}
