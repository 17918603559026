import styled from 'styled-components';
import { media } from 'styles';

export const CategoryOptionsWrapper = styled.div`
  display: inline-block;
  width: 280px;
  & + & { margin-left: 28px; }

  ${media.twoDimMin.tablet`
    width: 315px;
  `}

  ${media.twoDimMin.baseline`
    & + & { margin-left: 32px; }
    width: 350px;
  `}

  ${media.twoDimMin.desktop`
    & + & { margin-left: 75px; }
    width: 450px;
  `}

  ${media.twoDimMin.max`
    & + & { margin-left: 100px; }
    width: 475px;
  `}
`;
