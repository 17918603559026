import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
`;

export const ScaleItemOptionsWrapper = styled.ul`
  display: flex;
  margin: 0;
  padding: 0 8px;

  ${media.min.baseline`padding: 0 14px;`}
`;

export const RatingItemWrapper = styled.li`
  list-style-type: none;
  display: inline-block;
  flex: 1 1 0%;
  font-size: 1.4rem;
  font-weight: ${type.weight.bold};

  & + & { margin-left: 8px; }

  ${media.min.baseline`
    font-size: 1.6rem;
    & + & { margin-left: 12px; }
  `}
`;

export const BottomLegend = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 30px;
  border-radius: 15px;
  margin-top: 36px;
  background-image: linear-gradient(
    to left,
    rgba(93, 202, 156, 0.8),
    rgba(255, 220, 158, 0.89),
    rgba(249, 43, 87, 0.8)
  );
`;

export const BottomLegendLabel = styled.span`
  color: ${colors.white};
  font-size: 1rem;
  font-weight: ${type.weight.bold};
  letter-spacing: 0.67px;
  text-transform: uppercase;

  ${media.min.baseline`font-size: 1.2rem;`}
`;

export const ScaleRatingText = styled.span`
  display: block;
  padding: 20px;
  text-align: center;
`;

export const RatingWrapper = styled.div`
  padding: 20px;
  text-align: center;
`;
