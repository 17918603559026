import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CloseButton,
  CTAText,
  Message,
} from './style';

const SPACEBAR = ' ';

const AccessibilitySkipButton = ({ onClick }) => {
  const { t } = useTranslation('common');
  const buttonRef = React.useRef();

  const handleKeyDown = event => {
    if (event.key === SPACEBAR) event.preventDefault();
    else if (event.key === 'Escape' || event.key === 'Esc') buttonRef.current.blur();
  };

  const onClose = () => {
    buttonRef.current.blur();
  };

  return (
    <div>
      <Button ref={buttonRef} onClick={onClick} onKeyDown={handleKeyDown}>
        <Message>{t('accessibilitySkipPopup.message')}</Message>
        <CTAText>{t('accessibilitySkipPopup.cta')}</CTAText>
      </Button>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </div>
  );
};

AccessibilitySkipButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AccessibilitySkipButton;
