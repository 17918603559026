import React from 'react';
import PropTypes from 'prop-types';
import { refType } from 'constants/propTypes';

import { useTranslation } from 'react-i18next';

import IATLearnMoreContent from './IATLearnMoreContent';
import {
  Button,
  CircleArrow,
  ContentWrapper,
  ListDivider,
  Wrapper,
} from './style';

const IATLearnMore = ({
  isExpanded,
  onLearnMoreToggle,
  scrollRef,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <>
      <Wrapper>
        {t('courseViewer:iat.learnMoreDescription')}
        <Button aria-expanded={isExpanded} onClick={onLearnMoreToggle}>
          {t('courseViewer:iat.learnMoreButtonText')}
          <CircleArrow isArrowDown={isExpanded} />
        </Button>
      </Wrapper>
      <ListDivider ref={scrollRef} isVisible={isExpanded} />
      <ContentWrapper isVisible={isExpanded}>
        <IATLearnMoreContent />
      </ContentWrapper>
      <ListDivider isVisible={isExpanded} />
    </>
  );
};

IATLearnMore.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onLearnMoreToggle: PropTypes.func.isRequired,
  scrollRef: refType.isRequired,
};

export default IATLearnMore;
