import { useTranslation } from 'react-i18next';

import selfReflectionImage from 'assets/cca-self-reflection.jpg';
import selfAwarenessImage from 'assets/cca-self-awareness.jpg';
import egolessnessImage from 'assets/cca-egolessness.jpg';
import Plane from 'types/Plane';

import ScoreGraph from '../../ScoreGraph';
import Recommendation from './Recommendation';

import { DimensionResult, DimensionName } from '../../types';

import {
  ContentContainer,
  Description,
  DimensionLabel,
  GraphContainer,
  Image,
  ImageContainer,
  OverviewSectionContentContainer,
  RecommendationsContainer,
  ScoreCategoryLabel,
  ScoreLabels,
  Title,
} from './style';

interface DimensionProps {
  content: DimensionResult;
  description: string;
  name: DimensionName;
}

const getImage = (name: DimensionName, imageAttribute: string) => {
  switch (name) {
    case DimensionName.SelfReflection:
      return <Image src={selfReflectionImage} alt={imageAttribute} />;
    case DimensionName.SelfAwareness:
      return <Image src={selfAwarenessImage} alt={imageAttribute} />;
    case DimensionName.Egolessness:
      return <Image src={egolessnessImage} alt={imageAttribute} />;
    default:
      return <Image src={selfReflectionImage} alt={imageAttribute} />;
  }
};

const DimensionContainer = ({
  content,
  description,
  name,
}: DimensionProps) => {
  const { t } = useTranslation('courseViewer');

  return (
    <>
      <ContentContainer>
        <OverviewSectionContentContainer>
          <Title>{t('courseViewer:ccaResults.dimensions.dimension.overviewSection.heading')}</Title>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </OverviewSectionContentContainer>
        <ImageContainer>
          {getImage(name, t(`courseViewer:ccaResults.dimensions.dimension.imageAttribute.${name}`))}
        </ImageContainer>
      </ContentContainer>
      <Title>{t('courseViewer:ccaResults.dimensions.dimension.scoreSection.heading')}</Title>
      <GraphContainer>
        <ScoreGraph
          scoreCategory={content.scoreCategory}
          orientation={Plane.Horizontal}
          labels={t(`courseViewer:ccaResults.graph.labels.${name}`, { returnObjects: true })}
        />
        <ScoreLabels>
          <ScoreCategoryLabel>{t(`courseViewer:ccaResults.dimensions.dimension.scoreLabels.${content.scoreCategory}`)}</ScoreCategoryLabel>
          <DimensionLabel>{t(`courseViewer:ccaResults.dimensions.dimension.heading.${name}`)}</DimensionLabel>
        </ScoreLabels>
      </GraphContainer>
      <Title>{t('courseViewer:ccaResults.dimensions.dimension.meaningSection.heading')}</Title>
      <ContentContainer>
        <Description dangerouslySetInnerHTML={{ __html: content.resultText }} />
      </ContentContainer>
      <Title>{t('courseViewer:ccaResults.dimensions.dimension.actionQuestionSection.heading')}</Title>
      <ContentContainer>
        <Description>
          {t(`courseViewer:ccaResults.dimensions.dimension.actionQuestionSection.description.${name}`)}
        </Description>
      </ContentContainer>
      <RecommendationsContainer>
        {content.recommendations.map((recommendation, index) => (
          <Recommendation
            item={recommendation}
            key={recommendation.title}
            number={index + 1}
          />
        ))}
      </RecommendationsContainer>
    </>
  );
};

export default DimensionContainer;
