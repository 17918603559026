import styled, { keyframes } from 'styled-components';
import { media, type } from 'styles';

import { MessageSubTypes } from '../../constants';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${p => p.imageUrl}) center/cover no-repeat;
    animation: 1s ${fade} forwards;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const Title = styled.div`
  font-size: ${p => (p.subType === MessageSubTypes.Title ? '3rem' : p.theme.message.standard.fontSize.base)};
  font-weight: ${p => (p.subType === MessageSubTypes.Title ? type.weight.bold : p.theme.message.standard.weight)};
  text-align: ${p => (p.subType === MessageSubTypes.Title ? 'center' : p.theme.message.standard.align)};
  color: ${p => (p.subType === MessageSubTypes.Title ? p.theme.message.title.color : p.theme.message.standard.color)};

  ${media.min.tablet`font-size: ${p => (p.subType === MessageSubTypes.Title ? '3.2rem' : p.theme.message.standard.fontSize.tablet)};`}
  ${media.min.desktop`font-size: ${p => (p.subType === MessageSubTypes.Title ? '3.6rem' : p.theme.message.standard.fontSize.desktop)};`}
`;

export const Body = styled.div`
  margin: 22px 0;
  font-size: 1.6rem;
  ${p => p.subType === MessageSubTypes.Title && 'text-align: center;'}

  a {
    color: ${p => p.theme.anchorTag.color};
    font-weight: 600;
    outline: none;
  }

  a:hover, a.focus-visible { text-decoration: underline; }

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.desktop`font-size: 2rem;`}
`;
