import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';
import i18n from 'lib/i18n';

export const PromptWrapper = styled.div`
  opacity: ${({ isPromptApplicable }) => (isPromptApplicable ? '1' : '0.2')};
  transition: opacity 0.5s ease;
`;

export const PromptText = styled.legend`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  text-align: center;
  padding: 0 22px;
  width: 80%;
  max-width: 850px;
  margin: 0 auto 32px auto;

  ${media.min.tablet`font-size: 2.2rem;`}
  ${media.min.baseline`
    font-size: 2.4rem;
    margin-bottom: 44px;
  `}

  ${p => p.isOptional && css`
    &:before {
      display: block;
      content: "${i18n.t('courseViewer:survey.optional')}";
      text-transform: uppercase;
      color: ${colors.yellow.base};
      font-size: 1.2rem;
      font-weight: ${type.weight.bold};
      margin-bottom: 36px;

      ${media.min.baseline`
        font-size: 1.4rem;
        margin-bottom: 44px;
      `}
    }
  `}

  & > .blank {
    font-family: Arial, sans-serif;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 44px;

  ${media.min.baseline`margin-bottom: 54px;`}

  ${media.min.desktop`margin-bottom: 64px;`}
`;

export const Image = styled.div`
  width: 500px;
  height: 200px;
  border-radius: 12px;
  ${({ url }) => `background-image: url(${url});`}
  background-size: cover;
  background-position: center;


  ${media.min.baseline`
    width: 600px;
    height: 260px;
  `}

  ${media.min.desktop`
    width: 645px;
    height: 300px;
  `}

  ${media.min.max`
    width: 625px;
    height: 280px;
  `}
`;

export const PromptFooter = styled.div`
  display: flex;
  justify-content: ${p => (p.hasNACheckbox ? 'space-between' : 'flex-end')};
  align-items: center;
  margin-top: 42px;
  max-width: 900px;
  width: 85%;
  margin: 46px auto 0 auto;

  ${media.min.baseline`margin-top: 56px;`}
`;
