import throttle from 'lib/throttle';
import * as Analytics from 'lib/analytics';
import { requestHeaderVisibility } from 'store/ui/actions';
import {
  fetchVideoCredentials,
  handleVideoBookmarkInterval,
  handleVideoSeeking,
  handleVideoTimeUpdate,
  setVideoEnded,
  setVideoPlayable,
  setVideoNotPlaying,
  setVideoPlaying,
  setVideoPlayerError,
} from 'store/video/actions';
import { handleTextTrackChange } from './eventHandlers';
import { displayVideoButton } from './cssControls';

const BOOKMARK_INTERVAL_MILLIS = 2000;
const THROTTLE_FETCH_CRED_ACTIONS_MS = 2000;

export default (player, dispatch, isIOS) => {
  player.on('ready', () => {
    const promise = player.play();

    if (promise !== undefined) promise.catch(() => displayVideoButton());
    else displayVideoButton();
  });
  player.on('ended', () => dispatch(setVideoEnded()));
  player.on('error', () => {
    Analytics.trackEvent('Video Player Error', {
      error: player.error(),
      videoSource: player.src(),
    });
    dispatch(setVideoPlayerError());
  });
  player.on('pause', () => dispatch(setVideoNotPlaying()));
  player.on('play', () => dispatch(setVideoPlaying()));
  player.on('seeking', () => dispatch(handleVideoSeeking()));
  player.on('useractive', () => dispatch(requestHeaderVisibility(true)));
  player.on('userinactive', () => dispatch(requestHeaderVisibility(false)));
  player.on('loadeddata', () => dispatch(setVideoPlayable()));
  player.on('timeupdate', throttle(250, () => dispatch(handleVideoTimeUpdate())));
  player.on('timeupdate', throttle(BOOKMARK_INTERVAL_MILLIS, () => dispatch(handleVideoBookmarkInterval())));
  player.textTracks().on('change', handleTextTrackChange(player));

  // Handling pause for mobile/tablet when controls are visible
  player.on('touchstart', e => {
    if (e.target.nodeName === 'VIDEO' && !player.paused() && player.userActive()) {
      player.pause();
    }
  });

  if (!isIOS) {
    // The blacklistplaylist error event will fire for each playlist (i.e. 1080p, 2k, etc.),
    // so we want to throttle and ignore all actions after the first action starts processing
    // as we only want to dispatch fetchVideoCredentials once.
    player.tech({ IWillNotUseThisInPlugins: true }).on(
      'blacklistplaylist',
      throttle(
        THROTTLE_FETCH_CRED_ACTIONS_MS,
        () => dispatch(fetchVideoCredentials({ shouldReloadPlaylist: true })),
      ),
    );
  }
};
