import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import VideoPlayer from 'lib/VideoPlayer';

import { currentChapterIdSelector } from 'store/courses/selectors';

import {
  HANDLE_VIDEO_BOOKMARK_INTERVAL,
  handleVideoBookmarkInterval,
} from '../actions';
import {
  isVideoPlayableSelector,
  videoBookmarkSelector,
} from '../selectors';

const debug = Debug('biassync:lms:store:video:sagas:handleVideoBookmarkInterval');

export function* handleVideoBookmarkIntervalSaga() {
  debug('Saga called');

  try {
    const isSeeking = yield call([VideoPlayer, 'isSeeking']);
    const isPlayable = yield select(isVideoPlayableSelector);

    if (!isSeeking && isPlayable) {
      const currentChapterId = yield select(currentChapterIdSelector);
      const bookmark = yield select(videoBookmarkSelector);
      yield call(API.sendBookmark, currentChapterId, { bookmark });
    }

    yield put(handleVideoBookmarkInterval.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(handleVideoBookmarkInterval.error(e));
  }
}

export default function* handleVideoBookmarkIntervalDefaultSaga() {
  yield takeLatest(HANDLE_VIDEO_BOOKMARK_INTERVAL.PENDING, handleVideoBookmarkIntervalSaga);
}
