import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { chapterType, refType } from 'constants/propTypes';
import * as ChapterType from 'constants/chapterTypes';
import colors from 'styles/colors';

import ChevronRightIcon from 'components/svg/ChevronRightIcon';
import homeIcon from 'assets/home-icon.svg';
import settingsIcon from 'assets/settings-icon.svg';
import tableOfContentsIcon from 'assets/table-of-contents.svg';

import LanguageMenu from 'components/shared/LanguageMenu';
import SettingsMenu from './SettingsMenu';
import {
  ChapterInfo,
  ChapterTitle,
  ChevronIconWrapper,
  ContentWrapper,
  HeaderWrapper,
  HomeIcon,
  InfoWrapper,
  LanguageMenuWrapper,
  MenuWrapper,
  OverviewPageButton,
  OverviewPageWrapper,
  SettingsIcon,
  SettingsMenuButton,
  SettingsMenuWrapper,
  SettingsWrapper,
  TableOfContentsButton,
  TableOfContentsButtonWrapper,
  TableOfContentsIcon,
  TOCTooltip,
  TOC_TOOLTIP_FADE_OUT_DURATION_MS,
  TOCTooltipText,
  TappableArea,
} from './style';

const Header = ({
  chapter,
  isMobileDevice,
  isSettingsMenuOpen,
  isTableOfContentsOpen,
  isVisible,
  onBlur,
  onFocus,
  onHomeButtonClick,
  onSettingsMenuButtonClick,
  onTableOfContentsButtonClick,
  onTap,
  settingsMenuRef,
  shouldDisplayTOCTooltip,
  shouldDisplayVideoResolutionPicker,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  const needsOpaqueBackground = chapter.loType === ChapterType.Video || isMobileDevice;
  // The background of these chapter types requires the higher contrast of white text
  const needsWhiteTextColor = (
    chapter.loType === ChapterType.LikertTest
    || chapter.loType === ChapterType.Survey
    || chapter.loType === ChapterType.Video
  );

  return (
    <>
      {!isVisible && chapter.loType !== ChapterType.Video && <TappableArea data-testid="header-tappable" onClick={onTap} />}
      <HeaderWrapper
        chapterSubType={chapter.subType}
        chapterType={chapter.loType}
        data-testid="header"
        hasBackground={needsOpaqueBackground}
        isVisible={isVisible}
        onBlur={onBlur}
        onClick={isVisible ? onTap : undefined}
        onFocus={onFocus}
      >
        <TableOfContentsButtonWrapper isVisible={!isTableOfContentsOpen}>
          <TableOfContentsButton
            aria-expanded={isTableOfContentsOpen}
            aria-label={t('courseViewer:header.tableOfContentsButtonAriaLabel')}
            data-testid="toc-button"
            onClick={onTableOfContentsButtonClick}
          >
            <TableOfContentsIcon src={tableOfContentsIcon} alt="" />
          </TableOfContentsButton>
          <CSSTransition
            classNames="toc-tooltip"
            in={shouldDisplayTOCTooltip}
            timeout={TOC_TOOLTIP_FADE_OUT_DURATION_MS}
            unmountOnExit
          >
            <TOCTooltip>
              <TOCTooltipText>
                {t('courseViewer:header.tableOfContentsTooltip')}
              </TOCTooltipText>
            </TOCTooltip>
          </CSSTransition>
        </TableOfContentsButtonWrapper>
        <ContentWrapper isPanelOpen={isTableOfContentsOpen}>
          {!isTableOfContentsOpen && (
            <InfoWrapper>
              <ChapterTitle>{chapter.title}</ChapterTitle>
              <ChapterInfo lightText={needsWhiteTextColor}>
                {t('courseViewer:header.sectionLabel')} {chapter.sectionIndex + 1}
                <ChevronIconWrapper>
                  <ChevronRightIcon fill={needsWhiteTextColor ? colors.white : undefined} />
                </ChevronIconWrapper>
                {t('courseViewer:header.chapterLabel')} {chapter.absoluteIndex + 1}
              </ChapterInfo>
            </InfoWrapper>
          )}
          <MenuWrapper>
            <LanguageMenuWrapper>
              <LanguageMenu />
            </LanguageMenuWrapper>
            <SettingsWrapper ref={settingsMenuRef}>
              <SettingsMenuButton
                aria-expanded={isSettingsMenuOpen}
                aria-label={t('courseViewer:settingsMenu.buttonAriaLabel')}
                onClick={onSettingsMenuButtonClick}
              >
                <SettingsIcon src={settingsIcon} alt="" />
              </SettingsMenuButton>
              {isSettingsMenuOpen && (
                <SettingsMenuWrapper>
                  <SettingsMenu
                    settingsMenuRef={settingsMenuRef}
                    shouldDisplayVideoResolutionPicker={shouldDisplayVideoResolutionPicker}
                  />
                </SettingsMenuWrapper>
              )}
            </SettingsWrapper>
            <OverviewPageWrapper>
              <OverviewPageButton
                onClick={onHomeButtonClick}
                aria-label={t('courseViewer:header.homeButtonAriaLabel')}
                data-testid="home-button"
              >
                <HomeIcon src={homeIcon} alt="" />
              </OverviewPageButton>
            </OverviewPageWrapper>
          </MenuWrapper>
        </ContentWrapper>
      </HeaderWrapper>
    </>
  );
};

Header.propTypes = {
  chapter: chapterType.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isSettingsMenuOpen: PropTypes.bool.isRequired,
  isTableOfContentsOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onHomeButtonClick: PropTypes.func.isRequired,
  onSettingsMenuButtonClick: PropTypes.func.isRequired,
  onTableOfContentsButtonClick: PropTypes.func.isRequired,
  onTap: PropTypes.func,
  settingsMenuRef: refType,
  shouldDisplayTOCTooltip: PropTypes.bool.isRequired,
  shouldDisplayVideoResolutionPicker: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  onTap: undefined,
  settingsMenuRef: null,
};

export default Header;
