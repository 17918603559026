import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { surveyPromptType } from 'constants/propTypes';
import { STORY_PANEL } from 'constants/panels';

import ChevronRightIcon from 'components/svg/ChevronRightIcon';
import SlideOutPanel from 'components/shared/SlideOutPanel';
import SimpleButton from 'components/shared/SimpleButton';

import { isStoryPanelOpenSelector } from 'store/ui/selectors';
import { toggleOpenPanel } from 'store/ui/actions';

import colors from 'styles/colors';
import StoryPanel from './StoryPanel';
import { PromptFooter } from '../style';
import {
  ButtonContent,
  ChevronIconWrapper,
} from './style';

const StoryPanelFooter = ({ content, isActive }) => {
  const { t } = useTranslation('courseViewer');

  const dispatch = useDispatch();

  const isStoryPanelOpen = useSelector(isStoryPanelOpenSelector);

  const handleToggle = () => dispatch(toggleOpenPanel(STORY_PANEL));

  return (
    <>
      <PromptFooter>
        <SimpleButton
          focusOutlineColor={colors.yellow.base}
          name="show-story"
          onClick={handleToggle}
          size="sm"
          variant="outline"
          data-testid="show-story-button"
        >
          <ButtonContent>
            <ChevronIconWrapper isLeft>
              <ChevronRightIcon fill={colors.white} />
              <ChevronRightIcon fill={colors.white} />
            </ChevronIconWrapper>
            {t('courseViewer:survey.showStory')}
          </ButtonContent>
        </SimpleButton>
      </PromptFooter>
      {isActive && (
        <SlideOutPanel
          direction="right"
          hasOverlay
          isOpen={isStoryPanelOpen}
          onClose={handleToggle}
        >
          <StoryPanel onToggle={handleToggle} content={content} />
        </SlideOutPanel>
      )}
    </>
  );
};

StoryPanelFooter.propTypes = {
  content: surveyPromptType.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default StoryPanelFooter;

