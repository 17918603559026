/* eslint-disable @typescript-eslint/default-param-last */

import { getErrorMessage, isUserAutoAnonymizedError } from 'lib/errorHandler';

import { FETCH_IAT_RESULT } from 'store/iat/actions';
import { FETCH_LIKERT_TEST_RESULT } from 'store/likertTest/actions';
import {
  FETCH_CHILD_CHAPTER,
  HANDLE_CHAPTER_CHANGED,
  RESET_CHAPTERS_STORE_ERRORS,
  SEND_CHAPTER_COMPLETED_BOOKMARK,
  SEND_CHAPTER_STARTED_BOOKMARK,
  SKIP_CHAPTER_FOR_ACCESSIBILITY,
} from './actions';

export const INITIAL_STATE = {
  accessibilitySkipError: null,
  error: null,
  isLoading: false,
  isRequestingAccessibilitySkip: false,
  isSendingCompletedBookmark: false,
  isUserAutoAnonymizedError: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case SEND_CHAPTER_STARTED_BOOKMARK.ERROR: {
      const { isFirstChapter } = action.originalAction.payload;

      return {
        ...state,
        error: isFirstChapter ? { message: getErrorMessage(payload) } : null,
      };
    }
    case SEND_CHAPTER_COMPLETED_BOOKMARK.PENDING:
      return {
        ...state,
        isSendingCompletedBookmark: true,
      };
    case SEND_CHAPTER_COMPLETED_BOOKMARK.SUCCESS:
      return {
        ...state,
        isSendingCompletedBookmark: false,
      };
    case SEND_CHAPTER_COMPLETED_BOOKMARK.ERROR:
      return {
        ...state,
        error: { message: getErrorMessage(payload) },
        isSendingCompletedBookmark: false,
      };
    case SKIP_CHAPTER_FOR_ACCESSIBILITY.PENDING:
      return {
        ...state,
        accessibilitySkipError: null,
        isRequestingAccessibilitySkip: true,
      };
    case SKIP_CHAPTER_FOR_ACCESSIBILITY.SUCCESS:
      return {
        ...state,
        isRequestingAccessibilitySkip: false,
      };
    case SKIP_CHAPTER_FOR_ACCESSIBILITY.ERROR:
      return {
        ...state,
        accessibilitySkipError: {
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
        isRequestingAccessibilitySkip: false,
      };
    case FETCH_CHILD_CHAPTER.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case HANDLE_CHAPTER_CHANGED.PENDING:
      return INITIAL_STATE;
    case HANDLE_CHAPTER_CHANGED.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_IAT_RESULT.ERROR:
    case FETCH_LIKERT_TEST_RESULT.ERROR: {
      if (!isUserAutoAnonymizedError(payload)) return state;

      return {
        ...state,
        isUserAutoAnonymizedError: true,
      };
    }
    case HANDLE_CHAPTER_CHANGED.ERROR: {
      const nextState = { ...state, isLoading: false };

      if (isUserAutoAnonymizedError(payload)) {
        nextState.isUserAutoAnonymizedError = true;
        return nextState;
      }

      return {
        ...nextState,
        error: {
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
      };
    }
    case RESET_CHAPTERS_STORE_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
