import styled from 'styled-components';

import backgroundSVG from 'assets/bgtexture.svg';
import colors from 'styles/colors';

export const ChapterWrapper = styled.div`
  height: 100%;
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  overflow: hidden;
`;
