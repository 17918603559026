import { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import { appTrackingOptions } from 'lib/analytics';
import * as Monitoring from 'lib/monitoring';

import ErrorView from './shared/ErrorView';

@track({}, appTrackingOptions)
class AppErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Monitoring.captureException(error, errorInfo);
  }

  render() {
    return this.state.hasError
      ? <ErrorView hasBackground />
      : this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppErrorBoundary;
