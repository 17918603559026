import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isTouchScreenSelector } from 'store/userAgent/selectors';

import {
  CircleWrapper,
  DeviceOrientationObserverWrapper,
  NotSupportedMessage,
  PhoneIcon,
} from './style';

const mapStateToProps = state => ({
  isTouchScreen: isTouchScreenSelector(state),
});

/*
  On touch screen devices, this component will use a media query to display
  a message to the user when the device is in portrait mode to alert them
  that they need to rotate their device to landscape mode to continue.
*/
export const DeviceOrientationObserver = ({
  children,
  isTouchScreen,
}) => {
  const { t } = useTranslation('courseViewer');

  return (isTouchScreen ? (
    <DeviceOrientationObserverWrapper>
      {children}
      <NotSupportedMessage>
        <CircleWrapper>
          <PhoneIcon />
        </CircleWrapper>
        <span>{t('courseViewer:deviceOrientation.message')}</span>
      </NotSupportedMessage>
    </DeviceOrientationObserverWrapper>
  ) : children);
};

DeviceOrientationObserver.propTypes = {
  children: PropTypes.node.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(DeviceOrientationObserver);
