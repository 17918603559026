/* eslint-disable react/no-did-update-set-state */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { useTracking } from 'react-tracking';
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import {
  courseType,
  errorType,
  userType,
} from 'constants/propTypes';

import {
  courseOverviewPosterImageLinkSelector,
  courseOverviewProgressPercentageSelector,
  coursesErrorSelector,
  currentChapterIdSelector,
  currentCourseIdSelector,
  currentCourseSelector,
  currentCourseStatusSelector,
  firstChapterIdSelector,
  isCourseRepeatableSelector,
  isCoursesLoadingSelector,
} from 'store/courses/selectors';
import { userSelector } from 'store/user/selectors';
import { isAICCSessionSelector } from 'store/aicc/selectors';

import * as coursesActions from 'store/courses/actions';
import * as chapterActions from 'store/chapters/actions';

import CourseOverview from './CourseOverview';

const mapStateToProps = state => ({
  course: currentCourseSelector(state),
  courseStatus: currentCourseStatusSelector(state),
  currentChapterId: currentChapterIdSelector(state),
  currentCourseId: currentCourseIdSelector(state),
  error: coursesErrorSelector(state),
  firstChapterId: firstChapterIdSelector(state),
  isAICCSession: isAICCSessionSelector(state),
  isCourseRepeatable: isCourseRepeatableSelector(state),
  isLoading: isCoursesLoadingSelector(state),
  posterImageLink: courseOverviewPosterImageLinkSelector(state),
  progressPercentage: courseOverviewProgressPercentageSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = {
  fetchCourse: coursesActions.fetchCourse,
  handleChapterChanged: chapterActions.handleChapterChanged,
  setCurrentCourseId: coursesActions.setCurrentCourseId,
};

export const ERROR_REDIRECT_DELAY_MS = 5000;

export const CourseOverviewContainer = ({
  course,
  courseStatus,
  currentChapterId,
  currentCourseId,
  error,
  fetchCourse,
  firstChapterId,
  handleChapterChanged,
  isAICCSession,
  isCourseRepeatable,
  isLoading,
  posterImageLink,
  progressPercentage,
  setCurrentCourseId,
  user,
}) => {
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useTracking(
    { page: 'Course Overview' },
    {
      dispatchOnMount: () => ({
        courseId,
        ...(course ? { courseName: course.title } : {}),
      }),
    },
  );

  useEffect(() => {
    setCurrentCourseId(courseId);
  }, []);

  useEffect(() => {
    if (currentCourseId && !course) {
      fetchCourse(currentCourseId);
    }
  }, [course, currentCourseId]);

  useEffect(() => {
    if (error && error.shouldRedirect) {
      setTimeout(() => {
        navigate('/courses');
      }, ERROR_REDIRECT_DELAY_MS);
    }
  }, [error]);

  const goToCourseViewer = chapterId => {
    handleChapterChanged({ chapterId, courseId: currentCourseId });
    navigate(`/courses/${currentCourseId}/viewer`);
  };

  const handleChapterPosterActions = () => {
    goToCourseViewer(currentChapterId || firstChapterId);
  };

  const handleChapterPosterClick = () => {
    trackEvent({ event: 'Chapter Poster Clicked' });
    handleChapterPosterActions();
  };

  const handleChapterPosterStatusButtonClick = () => {
    trackEvent({ event: 'CTA Button Clicked' });
    handleChapterPosterActions();
  };

  return (
    <CourseOverview
      course={course}
      courseStatus={courseStatus}
      error={error}
      isBreadcrumbVisible={!!course && !isAICCSession}
      isCourseRepeatable={isCourseRepeatable}
      isLoading={isLoading}
      onBreadcrumbClick={() => trackEvent({ event: 'Breadcrumb Clicked' })}
      onChapterPosterClick={handleChapterPosterClick}
      onChapterPosterStatusButtonClick={handleChapterPosterStatusButtonClick}
      onChapterSelect={goToCourseViewer}
      posterImageLink={posterImageLink}
      progressPercentage={progressPercentage}
      user={user}
    />
  );
};

CourseOverviewContainer.propTypes = {
  course: courseType,
  courseStatus: PropTypes.string.isRequired,
  currentChapterId: PropTypes.string,
  currentCourseId: PropTypes.string,
  error: errorType,
  fetchCourse: PropTypes.func.isRequired,
  firstChapterId: PropTypes.string,
  handleChapterChanged: PropTypes.func.isRequired,
  isAICCSession: PropTypes.bool.isRequired,
  isCourseRepeatable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  posterImageLink: PropTypes.string,
  progressPercentage: PropTypes.number,
  setCurrentCourseId: PropTypes.func.isRequired,
  user: userType,
};

CourseOverviewContainer.defaultProps = {
  course: null,
  currentChapterId: null,
  currentCourseId: null,
  error: null,
  firstChapterId: null,
  posterImageLink: null,
  progressPercentage: null,
  user: null,
};

const trackedCourseOverviewContainer = track()(CourseOverviewContainer);

const wrappedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedCourseOverviewContainer);

/**
 * Please refer to comment #1 here:
 * https://www.notion.so/biassync/Codebase-Comments-130f502b2e264e1592548d05e64330ca
 */
export default withTranslation('courseOverview')(wrappedComponent);
