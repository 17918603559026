import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import * as ChapterType from 'constants/chapterTypes';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import { chaptersByIdSelector } from 'store/courses/selectors';

import {
  FETCH_CHILD_CHAPTER,
  fetchChildChapter,
  HANDLE_CHAPTER_CHANGED,
  handleChapterChanged,
} from '../actions';

const debug = Debug('biassync:lms:store:chapters:sagas:handleChapterChanged');

export function* handleChapterChangedSaga(action) {
  const { chapterId, courseId } = action.payload;
  debug('Saga called', chapterId);

  let nextChapterId = chapterId;
  const chaptersById = yield select(chaptersByIdSelector);

  try {
    if (chaptersById[chapterId].loType === ChapterType.Forward) {
      yield put(fetchChildChapter({ chapterId, courseId }));
      const [success, error] = yield call(awaitAsyncAction, FETCH_CHILD_CHAPTER);

      if (error) throw error.payload;

      ({ childChapter: { id: nextChapterId } } = success.payload);
    }

    debug('Setting next chapter id with', nextChapterId);

    yield put(handleChapterChanged.success({
      chapterId: nextChapterId,
      courseId,
    }));
  } catch (e) {
    debug('Failed to fetch child chapter', e);
    yield put(handleChapterChanged.error(e, action));
  }
}

export default function* handleChapterChangedDefaultSaga() {
  yield takeLatest(HANDLE_CHAPTER_CHANGED.PENDING, handleChapterChangedSaga);
}
