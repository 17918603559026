import { useLayoutEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType, refType, surveyPromptType } from 'constants/propTypes';
import { MOBILE, TABLET } from 'constants/deviceTypes';
import * as PromptType from 'constants/surveyPromptTypes';

import { useConstructor } from 'lib/customHooks';
import withListScroller from 'lib/withListScroller';

import Survey from './Survey';
import {
  getSurveyResponses,
  shouldPromptAutoAdvance,
} from './utils';
import SurveyContext from './context';
import {
  addCommentClicked,
  itemFocused,
  itemSubmitted,
  responseChanged,
  scrolled,
} from './actions';
import reducer, { getInitialState } from './reducer';

export const SurveyContainer = ({
  currentBlock,
  deviceType,
  initializeScrollItems,
  insertScrollItem,
  isPostingResponses,
  itemRefs,
  onBlockCompleted,
  prompts,
  scrollIntoView,
  surveySubType,
  totalBlocks,
  totalItems,
  totalPrevItems,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      prompts,
      totalPrevItems,
    },
    getInitialState,
  );
  const {
    activeItemId,
    furthestItemIndex,
    itemIds,
    itemsById,
    itemToScrollTo,
    responses,
    viewedItemIds,
  } = state;

  useConstructor(() => initializeScrollItems(itemIds.length));

  useLayoutEffect(() => {
    if (itemToScrollTo) {
      scrollIntoView(itemIds.indexOf(itemToScrollTo));
      dispatch(scrolled());
    }
  }, [itemToScrollTo]);

  const handleResponseChange = (itemId, response, isKeyboardEvent) => {
    dispatch(responseChanged({ itemId, response }));

    if (!isKeyboardEvent && shouldPromptAutoAdvance(itemsById[itemId], response)) {
      dispatch(itemSubmitted());
    }
  };

  const handleAddCommentClick = itemId => {
    if (!itemsById[itemId].commentId) {
      insertScrollItem(itemIds.indexOf(itemId) + 1);
    }
    dispatch(addCommentClicked(itemId));
  };

  const handleItemCTAClick = itemId => {
    if (itemsById[itemId].promptType !== PromptType.Conclusion) dispatch(itemSubmitted());
    else {
      const blockResponses = getSurveyResponses(responses, itemsById);
      onBlockCompleted({ blockResponses, totalItemsCount: viewedItemIds.length });
    }
  };

  const handleItemFocus = itemId => {
    if (itemId !== activeItemId) dispatch(itemFocused(itemId));
  };

  return (
    <SurveyContext.Provider value={state}>
      <Survey
        activeItemId={activeItemId}
        currentBlock={currentBlock}
        furthestItemIndex={furthestItemIndex}
        isPostingResponses={isPostingResponses}
        isTouchScreen={deviceType === MOBILE || deviceType === TABLET}
        itemRefs={itemRefs}
        items={itemIds.map(itemId => itemsById[itemId])}
        onAddCommentClick={handleAddCommentClick}
        onItemCTAClick={handleItemCTAClick}
        onItemFocus={handleItemFocus}
        onResponseChange={handleResponseChange}
        responses={responses}
        surveySubType={surveySubType}
        totalBlocks={totalBlocks}
        totalItems={totalItems}
        viewedItemIds={viewedItemIds}
      />
    </SurveyContext.Provider>
  );
};

SurveyContainer.propTypes = {
  currentBlock: PropTypes.number.isRequired,
  deviceType: deviceTypeType.isRequired,
  initializeScrollItems: PropTypes.func.isRequired,
  insertScrollItem: PropTypes.func.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  itemRefs: PropTypes.arrayOf(refType).isRequired,
  onBlockCompleted: PropTypes.func.isRequired,
  prompts: PropTypes.arrayOf(surveyPromptType).isRequired,
  scrollIntoView: PropTypes.func.isRequired,
  surveySubType: PropTypes.string.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPrevItems: PropTypes.number.isRequired,
};

export default withListScroller(SurveyContainer);
