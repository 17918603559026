import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import { refType } from 'constants/propTypes';
import { videoResolutionList as videoResolutions } from 'constants/videoResolutions';
import { SETTINGS_MENU } from 'constants/menus';

import VideoPlayer from 'lib/VideoPlayer';
import useModalCloseEventListener from 'lib/customHooks/useModalCloseEventListener';

import { toggleOpenMenu } from 'store/ui/actions';

import SettingsMenu from './SettingsMenu';

export const SettingsMenuContainer = ({
  settingsMenuRef,
  shouldDisplayVideoResolutionPicker,
  tracking: { trackEvent },
}) => {
  const [
    selectedVideoResolution,
    setSelectedVideoResolution,
  ] = useState(VideoPlayer.getResolution());

  const dispatch = useDispatch();

  useModalCloseEventListener(settingsMenuRef, () => dispatch(toggleOpenMenu(SETTINGS_MENU)));

  const handleVideoResolutionChange = resolution => {
    if (resolution !== selectedVideoResolution) {
      trackEvent({ event: 'Video Resolution Selected', resolution });

      VideoPlayer.setResolution(resolution);
      setSelectedVideoResolution(resolution);
    }

    setTimeout(() => dispatch(toggleOpenMenu(SETTINGS_MENU)), 400);
  };

  return (
    <SettingsMenu
      onTechIssueLinkClick={() => trackEvent({ event: 'Tech Issue Link Clicked' })}
      onVideoResolutionChange={handleVideoResolutionChange}
      selectedVideoResolution={selectedVideoResolution}
      shouldDisplayVideoResolutionPicker={shouldDisplayVideoResolutionPicker}
      videoResolutions={videoResolutions}
    />
  );
};

SettingsMenuContainer.propTypes = {
  settingsMenuRef: refType,
  shouldDisplayVideoResolutionPicker: PropTypes.bool.isRequired,
  tracking: TrackingPropType,
};

SettingsMenuContainer.defaultProps = {
  settingsMenuRef: null,
  tracking: null,
};

const trackedSettingsMenuContainer = track({
  module: 'SettingsMenu',
})(SettingsMenuContainer);

export default trackedSettingsMenuContainer;
