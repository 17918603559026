import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import { useNavigate } from 'react-router-dom';

import { chapterType } from 'constants/propTypes';
import { TABLE_OF_CONTENTS_PANEL } from 'constants/panels';
import * as ChapterType from 'constants/chapterTypes';
import { SETTINGS_MENU } from 'constants/menus';

import { currentChapterSelector } from 'store/courses/selectors';
import {
  isIOSSelector,
  isMobileDeviceSelector,
  isTouchScreenSelector,
} from 'store/userAgent/selectors';
import {
  currentOpenPanelSelector,
  currentOpenMenuSelector,
  isHeaderVisibleSelector,
} from 'store/ui/selectors';
import {
  requestHeaderVisibility,
  toggleOpenPanel,
  toggleOpenMenu,
} from 'store/ui/actions';

import { useTOCTooltip } from './hooks';
import Header from './Header';

const mapStateToProps = state => ({
  chapter: currentChapterSelector(state),
  isIOS: isIOSSelector(state),
  isMobileDevice: isMobileDeviceSelector(state),
  isSettingsMenuOpen: currentOpenMenuSelector(state) === SETTINGS_MENU,
  isTableOfContentsOpen: currentOpenPanelSelector(state) === TABLE_OF_CONTENTS_PANEL,
  isTouchScreen: isTouchScreenSelector(state),
  isVisible: isHeaderVisibleSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onSettingsMenuButtonClick: () => dispatch(toggleOpenMenu(SETTINGS_MENU)),
  onTableOfContentsButtonClick: () => dispatch(toggleOpenPanel(TABLE_OF_CONTENTS_PANEL)),
  setHeaderVisible: isVisible => dispatch(requestHeaderVisibility(isVisible)),
});

export const HEADER_VISIBILITY_DURATION_MS = 3000;

export const HeaderContainer = ({
  chapter,
  isIOS,
  isMobileDevice,
  isSettingsMenuOpen,
  isTableOfContentsOpen,
  isTouchScreen,
  isVisible,
  onSettingsMenuButtonClick,
  onTableOfContentsButtonClick,
  setHeaderVisible,
  tracking: { trackEvent },
}) => {
  const navigate = useNavigate();
  const shouldDisplayTOCTooltip = useTOCTooltip();
  const settingsMenuRef = React.createRef();
  const [isFocused, setIsFocused] = useState(false);

  let tapTimeout = null;
  useEffect(() => () => {
    if (tapTimeout) {
      clearTimeout(tapTimeout);
      tapTimeout = null;
    }
  }, []);

  const handleSettingsMenuButtonClick = e => {
    e.stopPropagation();
    onSettingsMenuButtonClick();

    // Unfocusing the header manually because if a user closes the settings menu via the settings
    // menu button, that button is now in focus leaving the header in focus.
    if (isSettingsMenuOpen) setIsFocused(false);
  };

  const handleTableOfContentsButtonClick = e => {
    e.stopPropagation();
    onTableOfContentsButtonClick();
  };

  const handleTap = () => {
    if (!isVisible && !tapTimeout) {
      setHeaderVisible(true);
      tapTimeout = setTimeout(() => {
        setHeaderVisible(false);
        tapTimeout = null;
      }, HEADER_VISIBILITY_DURATION_MS);
    } else if (tapTimeout) {
      setHeaderVisible(false);
      clearTimeout(tapTimeout);
      tapTimeout = null;
    }
  };

  const handleHomeButtonClick = () => {
    trackEvent({ event: 'Home Button Clicked' });
    navigate('..', { relative: 'path' });
  };

  const isVideoChapter = chapter.loType === ChapterType.Video;
  return (
    <Header
      chapter={chapter}
      isMobileDevice={isMobileDevice}
      isSettingsMenuOpen={isSettingsMenuOpen}
      isTableOfContentsOpen={isTableOfContentsOpen}
      isVisible={isVisible || isFocused || shouldDisplayTOCTooltip}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      onHomeButtonClick={handleHomeButtonClick}
      onSettingsMenuButtonClick={handleSettingsMenuButtonClick}
      onTableOfContentsButtonClick={handleTableOfContentsButtonClick}
      onTap={isTouchScreen && !isVideoChapter ? handleTap : undefined}
      settingsMenuRef={settingsMenuRef}
      shouldDisplayTOCTooltip={shouldDisplayTOCTooltip}
      shouldDisplayVideoResolutionPicker={!isIOS}
    />
  );
};

HeaderContainer.propTypes = {
  chapter: chapterType,
  isIOS: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isSettingsMenuOpen: PropTypes.bool.isRequired,
  isTableOfContentsOpen: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSettingsMenuButtonClick: PropTypes.func.isRequired,
  onTableOfContentsButtonClick: PropTypes.func.isRequired,
  setHeaderVisible: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

HeaderContainer.defaultProps = {
  chapter: null,
  tracking: null,
};

const trackedHeader = track({})(HeaderContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedHeader);
