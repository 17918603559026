import styled from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';
import StyledExternalLink from 'styles/components/StyledExternalLink';

export const AuthenticationLayoutWrapper = styled.div`
  position: relative;
  background: url(${backgroundSVG}) ${colors.rocky.base} center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: 24px 14px;
  min-height: 100%;

  ${media.min.tablet`padding: 40px;`}
`;

export const LogoWrapper = styled.div`
  width: 70px;
  height: 40px;

  ${media.min.tablet`
    width: 82px;
    height: 46px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${colors.sierra.base};
  background-color: ${colors.white};
  border-radius: 8px;
  width: 100%;
  padding: 28px 14px;
  margin-top: ${({ isLegal }) => (isLegal ? '48px' : '24px')};
  margin-bottom: 88px;

  ${media.min.tablet`padding: 38px;`}
  ${media.min.baseline`width: 650px;`}
  ${media.min.desktop`
    width: 800px;
    padding: 50px;
  `}
`;

export const LanguageMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
`;

export const TechIssueLinkText = styled.span`
  color: ${colors.cascade.dark};
  position: absolute;
  bottom: 54px;
  font-size: 1.2rem;

  ${media.min.baseline`font-size: 1.4rem;`}
`;

export const ExternalLink = styled(StyledExternalLink)`
  font-weight: ${type.weight.semiBold};
`;
