import { useState } from 'react';

import { TextTaskEvent } from 'types/VideoTaskEvent';

import { useTaskCompletionTimeout } from '../hooks';
import TextTask from './TextTask';

const CHARACTER_LIMIT = 250;

type TextTaskContainerProps = {
  isSubmitting: boolean;
  onContinue: () => void;
  onSubmit: (optionKey: string) => void;
  taskEvent: TextTaskEvent;
};

const TextTaskContainer = ({
  isSubmitting,
  onContinue,
  onSubmit,
  taskEvent,
}: TextTaskContainerProps) => {
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(event.target.value.slice(0, CHARACTER_LIMIT));
  };

  useTaskCompletionTimeout(!!taskEvent.isSubmitted, onContinue);

  return (
    <TextTask
      isSubmitted={!!taskEvent.isSubmitted}
      isSubmitting={isSubmitting}
      onInputChange={handleInputChange}
      onSubmit={() => onSubmit(userInput)}
      prompt={taskEvent.prompt}
      userInput={userInput}
    />
  );
};

export default TextTaskContainer;
