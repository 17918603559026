/* eslint-disable sort-keys  */

const spacing = {
  slim: '4px',
  cozy: '8px',
  compact: '12px',
  moderate: '16px',
  normal: '24px',
  expanded: '28px',
  spacious: '32px',
  giant: '44px',
  colossal: '64px',
};

export default spacing;
