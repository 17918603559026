import React from 'react';
import PropTypes from 'prop-types';
import ReactMinimalPieChart from 'react-minimal-pie-chart';

import colors from 'styles/colors';

const dataSegmentType = PropTypes.shape({
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const labelStyle = {
  fill: colors.white,
  fontSize: '1rem',
  fontWeight: '600',
};

const titleStyle = {
  fill: colors.white,
  fontSize: '.4rem',
  fontWeight: '500',
};

const renderLabel = ({ data, dataIndex }) => (
  `${Math.round(data[dataIndex].percentage)}%`
);

const PieChart = ({
  dataSegments,
  showAnimation,
}) => (
  <ReactMinimalPieChart
    animate={showAnimation}
    data={dataSegments}
    label={renderLabel}
    labelStyle={labelStyle}
    title
    titleStyle={titleStyle}
    radius={40}
    verticalSegmentPositioning
  />
);

PieChart.propTypes = {
  dataSegments: PropTypes.arrayOf(dataSegmentType).isRequired,
  showAnimation: PropTypes.bool.isRequired,
};

export default PieChart;
