import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { deviceTypeType } from 'constants/propTypes';

import IATCategoryOptions from '../IATCategoryOptions';
import IATSummaryLayout from '../IATSummaryLayout';
import { CategoryOptionsWrapper } from './style';

const IATCategorySummary = ({
  deviceType,
  onContinue,
  stimuli,
  stimulusType,
}) => {
  const { t } = useTranslation('courseViewer');
  const promptText = stimulusType === 'image' ? t('courseViewer:iat.matchImages') : t('courseViewer:iat.matchText');

  return (
    <IATSummaryLayout
      deviceType={deviceType}
      onCTATap={onContinue}
      promptText={promptText}
    >
      {stimuli.map(stimulus => (
        <CategoryOptionsWrapper key={stimulus.stimulusTitle}>
          <IATCategoryOptions
            categoryName={stimulus.stimulusTitle}
            categoryType={stimulusType}
            options={stimulus.stimulusContent}
          />
        </CategoryOptionsWrapper>
      ))}
    </IATSummaryLayout>
  );
};

IATCategorySummary.propTypes = {
  deviceType: deviceTypeType.isRequired,
  onContinue: PropTypes.func.isRequired,
  stimuli: PropTypes.arrayOf(PropTypes.shape({
    stimulusContent: PropTypes.arrayOf(PropTypes.string).isRequired,
    stimulusTitle: PropTypes.string.isRequired,
  })).isRequired,
  stimulusType: PropTypes.string.isRequired,
};

export default IATCategorySummary;
