import styled from 'styled-components';

import TransparentButton from 'styles/components/TransparentButton';
import { colors, media, type } from 'styles';

export const Wrapper = styled.div`
  position: relative;
  padding: 20px 16px 7px;
  border-radius: 8px;
  border: ${({ hasError, theme }) => (hasError
    ? `2px solid ${theme.container.border.error}`
    : `2px solid ${theme.container.border.default}`
  )};
  background: ${({ theme }) => theme.container.background};

  ${media.min.desktop`padding: 25px 16px 7px;`}
`;

export const Input = styled.input`
  width: 100%;
  height: 32px;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.input.color.default};
  font-weight: ${type.weight.semiBold};
  outline: 0;
  box-shadow: none;
  &:disabled {
    font-style: italic;
  }

  /* Remove X button (clear field) from IE and Edge */
  &::-ms-clear {
    display: none;
  }

  ${media.min.baseline`font-size: 1.8rem;`}
  ${media.min.desktop`font-size: 2rem;`}
`;

export const Label = styled.label`
  position: absolute;
  left: 18px;
  font-weight: ${type.weight.semiBold};
  transition: all .1s ease;
  top: 18px;
  color: ${({ hasError, theme }) => (hasError
    ? theme.label.color.error
    : theme.label.color.default
  )};

  ${media.min.baseline`
    left: 32px;
    font-size: 1.8rem;
  `}

  ${Input}:disabled + &,
  ${Input}:focus + &,
  ${Input}:valid + & {
    font-size: 1.2rem;
    left: 16px;
    top: 7px;

    ${media.min.baseline`font-size: 1.4rem;`}
  }
`;

export const TooltipButton = styled(TransparentButton)`
  display: inline-block;
  position: absolute;
  height: 24px;
  width: 24px;
  right: 12px;
  bottom: 18px;
  border-radius: 50%;
  border: solid 3px ${colors.ridge.background};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${colors.ridge.background};
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  bottom: -58px;
  right: -22px;
  max-width: 360px;
  height: 100%;
  z-index: 3;
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none;

  ${media.min.tablet`bottom: -60px;`}
  ${media.min.desktop`bottom: -62px;`}

  ${TooltipButton}.focus-visible + &,
  ${TooltipButton}:hover + & { opacity: 1; }
`;
