import { colors } from 'styles';

const ACCENT_COLOR_PRINT = '#45827a';
const ACCENT_COLOR_WEB = '#5aa99e';

export default {
  accent: {
    print: ACCENT_COLOR_PRINT,
    web: ACCENT_COLOR_WEB,
  },
  graph: {
    background: {
      print: colors.cascade.muted,
      web: colors.shasta.dark,
    },
    foreground: {
      print: ACCENT_COLOR_PRINT,
      web: ACCENT_COLOR_WEB,
    },
    marker: {
      print: colors.white,
      web: colors.sierra.base,
    },
  },
  primary: {
    print: colors.sierra.base,
    web: colors.white,
  },
};
