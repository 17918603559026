import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';

import { chapterType } from 'constants/propTypes';
import * as ToastTypes from 'constants/toastTypes';
import { INVALID_EMAIL } from 'lib/validation/errorTypes';
import { regexes } from 'lib/validation/validator';
import { usePrevious } from 'lib/customHooks';

import * as uiActions from 'store/ui/actions';
import * as anonymizationActions from 'store/userDataAnonymization/actions';
import { isAndroidTabletSelector, isMobileDeviceSelector } from 'store/userAgent/selectors';
import {
  isCurrentChapterCompletedSelector,
  isDemoCourseSelector,
} from 'store/courses/selectors';
import { isUserDataAnonymizingSelector } from 'store/userDataAnonymization/selectors';

import { ChapterSubType } from './types';
import AnonymizeResultsChapter from './AnonymizeResultsChapter';

const mapStateToProps = state => ({
  isAndroidTablet: isAndroidTabletSelector(state),
  isAnonymizing: isUserDataAnonymizingSelector(state),
  isCompleted: isCurrentChapterCompletedSelector(state),
  isDemoCourse: isDemoCourseSelector(state),
  isMobileDevice: isMobileDeviceSelector(state),
});

const mapDispatchToProps = {
  anonymizeUserCourseData: anonymizationActions.anonymizeUserCourseData,
  toggleToast: uiActions.toggleOpenToast,
};

export const AnonymizeResultsChapterContainer = ({
  anonymizeUserCourseData,
  chapter,
  goToNextChapter,
  isAndroidTablet,
  isAnonymizing,
  isCompleted,
  isDemoCourse,
  isMobileDevice,
  toggleToast,
  tracking: { trackEvent },
}) => {
  const { t } = useTranslation(['common', 'courseViewer', 'errors']);
  const shouldShowEmailOption = chapter.subType === ChapterSubType.Standard;

  const [email, setEmail] = useState(null);
  const [willSendResults, setWillSendResults] = useState(shouldShowEmailOption);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  useEffect(() => {
    if (isDemoCourse) {
      toggleToast({ message: t('courseViewer:anonymizeResults.demoNotification'), type: ToastTypes.TOAST_NOTIFICATION });
    }
  }, []);

  const prevIsCompleted = usePrevious(isCompleted);
  useEffect(() => {
    if (prevIsCompleted === false && isCompleted === true) goToNextChapter();
  }, [prevIsCompleted, isCompleted]);

  const handleAnonymizeResults = () => {
    trackEvent({
      didSendResults: willSendResults,
      event: 'Anonymize Results Button Clicked',
    });

    if (!willSendResults) anonymizeUserCourseData();
    else if (email && regexes[INVALID_EMAIL].test(email)) anonymizeUserCourseData({ email });
    else {
      setIsEmailInvalid(true);
      toggleToast({ message: t('errors:invalidEmail'), type: ToastTypes.TOAST_ERROR });
    }
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (isEmailInvalid) setIsEmailInvalid(false);
  };

  const handleOptionChange = option => {
    if (willSendResults !== option) {
      setWillSendResults(option);
      if (isEmailInvalid) setIsEmailInvalid(false);
    }
  };

  return (
    <AnonymizeResultsChapter
      email={email}
      isAndroidTablet={isAndroidTablet}
      isAnonymizing={isAnonymizing}
      isCompleted={isCompleted}
      isEmailInvalid={isEmailInvalid}
      isMobileDevice={isMobileDevice}
      onAnonymizeResults={handleAnonymizeResults}
      onContinue={goToNextChapter}
      onEmailChange={handleEmailChange}
      onOptionChange={handleOptionChange}
      shouldShowEmailOption={shouldShowEmailOption}
      subType={chapter.subType}
      t={t}
      willSendResults={willSendResults}
    />
  );
};

AnonymizeResultsChapterContainer.propTypes = {
  anonymizeUserCourseData: PropTypes.func.isRequired,
  chapter: chapterType.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  isAndroidTablet: PropTypes.bool.isRequired,
  isAnonymizing: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  toggleToast: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

AnonymizeResultsChapterContainer.defaultProps = {
  tracking: null,
};

const trackedAnonymizeResultsChapterContainer = track({})(AnonymizeResultsChapterContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedAnonymizeResultsChapterContainer);
