import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImageOptions from './ImageOptions';
import TextOptions from './TextOptions';
import {
  CategoryName,
  ContentWrapper,
  IATCategoryOptionsWrapper,
  OptionsWrapper,
  Subtext,
} from './style';

const IATCategoryOptions = ({
  categoryName,
  categoryType,
  options,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <IATCategoryOptionsWrapper>
      <OptionsWrapper>
        {categoryType === 'image' ? (
          <ImageOptions options={options} />
        ) : (
          <TextOptions options={options} />
        )}
      </OptionsWrapper>
      <ContentWrapper>
        <Subtext>{t('courseViewer:iat.to')}</Subtext>
        <CategoryName>{categoryName}</CategoryName>
      </ContentWrapper>
    </IATCategoryOptionsWrapper>
  );
};

IATCategoryOptions.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default IATCategoryOptions;
