import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isAccountDetailsPermittedSelector } from 'store/auth/selectors';
import { currentChapterIdSelector } from 'store/courses/selectors';
import { isAICCSessionSelector } from 'store/aicc/selectors';
import { isBlockingModalOpenSelector } from 'store/ui/selectors';

import ConditionalRoute from '../shared/ConditionalRoute';
import ErrorView from '../shared/ErrorView';
import CourseLayout from '../shared/CourseLayout';
import AccountDetails from './AccountDetails';
import CourseOverview from './CourseOverview';
import CourseSelector from './CourseSelector';
import CourseViewer from './CourseViewer';

const mapStateToProps = state => ({
  currentChapterId: currentChapterIdSelector(state),
  isAICCSession: isAICCSessionSelector(state),
  isAccountDetailsPermitted: isAccountDetailsPermittedSelector(state),
  isBlockingModalOpen: isBlockingModalOpenSelector(state),
});

export const LMS = ({
  currentChapterId,
  isAccountDetailsPermitted,
  isAICCSession,
  isBlockingModalOpen,
}) => {
  const { t } = useTranslation('common');

  if (isBlockingModalOpen) return <CourseLayout />;
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/courses" replace />} />
      <Route path="/course/:id" element={<Navigate to="/courses/:id" replace />} />
      <Route path="/courses/:id" element={<CourseOverview />} />
      <Route
        path="/error"
        render={() => (
          <ErrorView
            hasBackground
            text={t('errors:faultyPage')}
          />
        )}
      />
      <Route
        path="/courses/:id/viewer"
        element={(
          <ConditionalRoute
            shouldRender={!!currentChapterId}
            redirectPath=".."
            redirectOptions={{ relative: 'path' }}
          >
            <CourseViewer />
          </ConditionalRoute>
        )}
      />
      <Route
        path="account"
        element={(
          <ConditionalRoute
            shouldRender={isAccountDetailsPermitted}
            redirectPath="/"
          >
            <AccountDetails />
          </ConditionalRoute>
        )}
      />
      <Route
        path="/courses"
        element={(
          <ConditionalRoute
            shouldRender={!isAICCSession}
            redirectPath="/error"
          >
            <CourseSelector />
          </ConditionalRoute>
        )}
      />
    </Routes>
  );
};

LMS.propTypes = {
  currentChapterId: PropTypes.string,
  isAICCSession: PropTypes.bool.isRequired,
  isAccountDetailsPermitted: PropTypes.bool.isRequired,
  isBlockingModalOpen: PropTypes.bool.isRequired,
};

LMS.defaultProps = {
  currentChapterId: null,
};

export default connect(mapStateToProps)(LMS);
