import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const SuccessIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;

  ${media.twoDimMin.baseline`
    width: 60px;
    height: 60px;
  `}

  ${media.twoDimMin.desktop`
    width: 70px;
    height: 70px;
  `}

  ${media.twoDimMin.max`
    width: 80px;
    height: 80px;
  `}
`;

export const SuccessText = styled.div`
  font-weight: ${type.weight.semiBold};
  color: ${colors.green.base};
  text-align: center;
  font-size: 1.2rem;
  margin-top: 14px;

  ${({ isImageStimulus, isMobile }) => isMobile && `
    margin-top: ${isImageStimulus ? '240px' : '120px'};
  `}

  ${media.twoDimMin.baseline`font-size: 1.4rem;`}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
  ${media.twoDimMin.max`
    font-size: 1.8rem;
    margin-top: 18px;
  `}
`;
