import jwtDecode from 'jwt-decode';

export const isTokenExpired = token => {
  const decodedToken = jwtDecode(token);
  const now = new Date().getTime() / 1000;

  return now >= decodedToken.exp;
};

export const isJWTToken = token => {
  try {
    jwtDecode(token);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidTokens = (accessToken, refreshToken) => {
  const isJWTTokens = isJWTToken(accessToken) && isJWTToken(refreshToken);

  return isJWTTokens && !isTokenExpired(refreshToken);
};
