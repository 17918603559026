import styled from 'styled-components';
import forceImportant from 'styles/functions/forceImportant';

export const RatingItemWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  ${forceImportant`
    display: block;
    border-radius: 8px;
    height: 100%;
    color: ${p => p.theme.ratingItem.unchecked.color};
    background-color: ${p => p.theme.ratingItem.unchecked.background};
    cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
    pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
    user-select: ${p => (p.isDisabled ? 'none' : 'auto')};

    ${p => !p.isDisabled && `
      &:hover {
        background-color: ${p.theme.ratingItem.hover.background};
        transition: background-color 0.2s;
      }
    `}

    ${Input}.focus-visible + & {
      box-shadow: ${p => p.theme.ratingItem.focus.shadow} 0px 0px 0px 3px;
      background-color: ${p => p.theme.ratingItem.focus.background};
    }
    ${Input}:checked + & {
      color: ${p => p.theme.ratingItem.checked.color};
      background-color: ${p => p.theme.ratingItem.checked.background};
    }
  `}
`;
