import styled from 'styled-components';

import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';
import backgroundSVG from 'assets/bgtexture.svg';

export const ChapterWrapper = styled.div`
  height: 100%;
  overflow: auto;
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  color: ${colors.yellow.background};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 40px;

  ${media.min.mobileLandscape`padding: 120px 120px`}
  ${media.min.tablet`padding: 160px 120px`}
  ${media.min.baseline`padding: 180px 160px`}

  ${({ isMobileDevice }) => !isMobileDevice && `
      -webkit-overflow-scrolling: touch; /* Needed for momentum-based scrolling behavior on tablet */
  `}
`;

export const ContentWrapper = styled.div`
  max-width: 900px;
`;

export const TitleText = styled.div`
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  text-align: center;

  ${media.min.tablet`font-size: 2.4rem;`}
  ${media.min.baseline`font-size: 2.6rem;`}
  ${media.min.desktop`font-size: 2.8rem;`}
  ${media.min.max`font-size: 3rem;`}
`;

export const MessageText = styled.p`
  font-size: 1.6rem;

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const ButtonsWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  ${media.min.mobileLandscape`
    flex-direction: row;
    justify-content: center;
  `}
  ${media.min.baseline`margin-top: 70px;`}
`;

export const DeclineButton = styled(TransparentButton)`
  margin-left: 0;
  margin-top: 36px;
  font-weight: ${type.weight.bold};
  font-size: 1.8rem;

  ${media.min.mobileLandscape`
    margin-left: 44px;
    margin-top: 0;
  `}
`;
