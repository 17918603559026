import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronDown from 'components/svg/ChevronDown';

import {
  DimensionName,
  ScoreCategory,
} from '../../types';

import {
  AccordionContainer,
  ChevronContainer,
  Container,
  Header,
  ScoreLabel,
  ScoresContainer,
  SectionTitle,
  TitleContainer,
} from './style';

type HeaderAccordionProps = {
  children: JSX.Element;
  isOpenDefault: boolean;
  name: DimensionName;
  scoreCategory: string;
};

const scoreCategories: string[] = Object.values(ScoreCategory);

const HeaderAccordionContainer = ({
  children,
  isOpenDefault,
  name,
  scoreCategory,
}: HeaderAccordionProps) => {
  const { t } = useTranslation('courseViewer');

  const [isSectionOpen, setIsSectionOpen] = useState(isOpenDefault);

  return (
    <Container>
      <Header role="button" onClick={() => setIsSectionOpen(isOpen => !isOpen)}>
        <TitleContainer>
          <ChevronContainer isOpen={isSectionOpen}>
            <ChevronDown />
          </ChevronContainer>
          <SectionTitle>{t(`courseViewer:ccaResults.dimensions.dimension.heading.${name}`)}</SectionTitle>
        </TitleContainer>
        <ScoresContainer>
          {scoreCategories.map(score => (
            <ScoreLabel key={score} isSelected={score === scoreCategory}>
              {t(`courseViewer:ccaResults.dimensions.dimension.scoreLabels.${score}`)}
            </ScoreLabel>
          ))}
        </ScoresContainer>
      </Header>
      <AccordionContainer isOpen={isSectionOpen}>
        {children}
      </AccordionContainer>
    </Container>
  );
};

export default HeaderAccordionContainer;
