import * as PromptType from 'constants/surveyPromptTypes';

import { SurveyItemTypes } from './constants';
import {
  createCommentItem,
  createSurveyItems,
  getNextViewedItemIds,
  getItemsById,
  getNextRequiredItemIndex,
  isPromptAnswered,
} from './utils';
import {
  ADD_COMMENT_CLICKED,
  ITEM_FOCUSED,
  ITEM_SUBMITTED,
  RESPONSE_CHANGED,
  SCROLLED,
} from './actions';

// Action handlers

const itemFocusedHandler = (state, { payload: nextItemId }) => {
  const {
    activeItemId,
    itemIds,
    itemsById,
    viewedItemIds,
  } = state;

  const nextState = {
    ...state,
    activeItemId: nextItemId,
  };

  const activeItemIndex = itemIds.indexOf(activeItemId);
  if (
    nextItemId === itemIds[activeItemIndex + 1]
    && itemsById[nextItemId].type !== SurveyItemTypes.Comment
  ) {
    const activeItem = itemsById[activeItemId];
    const previousItem = activeItem.type === SurveyItemTypes.Comment
      ? itemsById[activeItem.parentPromptId]
      : activeItem;
    nextState.viewedItemIds = getNextViewedItemIds(previousItem, viewedItemIds);
  }

  return nextState;
};

const itemSubmittedHandler = state => {
  const {
    activeItemId,
    furthestItemIndex,
    itemIds,
    itemsById,
    responses,
  } = state;

  const item = itemsById[activeItemId];
  const {
    commentId,
    id,
    isOptional,
    parentPromptId,
    type,
  } = item;

  if (type === SurveyItemTypes.Prompt && !isOptional && !isPromptAnswered(item, responses[id])) {
    return state;
  }

  const itemIndex = itemIds.indexOf(activeItemId);
  const nextState = { ...state };

  if (type === SurveyItemTypes.Comment) {
    const parentItem = itemsById[parentPromptId];
    const isParentAnswered = isPromptAnswered(itemsById[parentPromptId], responses[parentPromptId]);

    if (!isParentAnswered && !parentItem.isOptional) {
      nextState.itemToScrollTo = itemIds[itemIndex - 1];
      return nextState;
    }
  }

  const doesItemHaveComment = !!commentId;
  const nextItemIndexDelta = doesItemHaveComment ? 2 : 1;

  nextState.itemToScrollTo = itemIds[itemIndex + nextItemIndexDelta];

  const isAtFurthestPromptItem = doesItemHaveComment
    ? itemIndex === furthestItemIndex - 1
    : itemIndex === furthestItemIndex;
  if (isAtFurthestPromptItem) {
    nextState.furthestItemIndex = getNextRequiredItemIndex(itemIndex, itemIds, itemsById);
  }

  return nextState;
};

const addCommentClickedHandler = (state, { payload: itemId }) => {
  const {
    furthestItemIndex,
    itemIds,
    itemsById,
  } = state;

  const parentItem = itemsById[itemId];
  if (parentItem.commentId) {
    return {
      ...state,
      itemToScrollTo: parentItem.commentId,
    };
  }

  const parentItemIndex = itemIds.indexOf(itemId);
  const commentItem = createCommentItem(parentItem);
  const commentItemIndex = parentItemIndex + 1;

  const newItemIds = [...itemIds];
  newItemIds.splice(commentItemIndex, 0, commentItem.id);

  const newItemsById = {
    ...itemsById,
    [itemId]: {
      ...itemsById[itemId],
      commentId: commentItem.id,
    },
    [commentItem.id]: commentItem,
  };

  return {
    ...state,
    furthestItemIndex: furthestItemIndex + 1,
    itemIds: newItemIds,
    itemToScrollTo: commentItem.id,
    itemsById: newItemsById,
  };
};

const responseChangedHandler = (state, { payload }) => {
  const { itemsById, viewedItemIds } = state;
  const { itemId, response } = payload;

  const nextState = {
    ...state,
    responses: {
      ...state.responses,
      [itemId]: response,
    },
  };

  if (itemsById[itemId]?.promptType === PromptType.Matrix) {
    const nextViewedItemIds = [...viewedItemIds];
    const subPromptIds = Object.keys(response);

    subPromptIds.forEach(id => !nextViewedItemIds.includes(id) && nextViewedItemIds.push(id));
    nextState.viewedItemIds = nextViewedItemIds;
  }

  return nextState;
};

// Reducer

export const getInitialState = survey => {
  const items = createSurveyItems(survey);
  const itemIds = items.map(item => item.id);
  const itemsById = getItemsById(items);
  const nextRequiredItemIndex = getNextRequiredItemIndex(-1, itemIds, itemsById);
  const viewedItemIds = [...Array(survey.totalPrevItems).keys()];

  return {
    activeItemId: items[0].id,
    furthestItemIndex: nextRequiredItemIndex,
    itemIds,
    itemToScrollTo: null,
    itemsById,
    responses: {},
    viewedItemIds,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ITEM_SUBMITTED:
      return itemSubmittedHandler(state);
    case ADD_COMMENT_CLICKED:
      return addCommentClickedHandler(state, action);
    case ITEM_FOCUSED:
      return itemFocusedHandler(state, action);
    case RESPONSE_CHANGED:
      return responseChangedHandler(state, action);
    case SCROLLED:
      return { ...state, itemToScrollTo: null };
    default:
      return state;
  }
};

export default reducer;
