import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import { refreshTokenSelector } from 'store/auth/selectors';
import {
  REFRESH_ACCESS_TOKEN,
  refreshAccessToken,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:refreshAccessToken');

export function* refreshAccessTokenSaga() {
  debug('Saga called');

  const refreshToken = yield select(refreshTokenSelector);

  try {
    const { accessToken } = yield call(API.refreshToken, refreshToken);

    debug('Token successfully refreshed');

    yield put(refreshAccessToken.success({
      accessToken,
      refreshToken,
    }));
  } catch (e) {
    debug('Saga error', e);
    yield put(refreshAccessToken.error(e));
  }
}

export default function* refreshAccessTokenDefaultSaga() {
  yield takeLatest(REFRESH_ACCESS_TOKEN.PENDING, refreshAccessTokenSaga);
}
