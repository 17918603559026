import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import { persistentEventType } from 'constants/propTypes';
import {
  VIDEO_EVENT_SUBTYPE_ANNOTATION,
  VIDEO_EVENT_SUBTYPE_RESULTS,
} from 'constants/videoEvents';

import Annotation from './Annotation';
import ResultsOverlay from './ResultsOverlay';

const overlayComponentMap = {
  [VIDEO_EVENT_SUBTYPE_ANNOTATION]: Annotation,
  [VIDEO_EVENT_SUBTYPE_RESULTS]: ResultsOverlay,
};

export const VideoOverlay = ({
  overlayEvent,
  showAnimation,
}) => React.createElement(
  overlayComponentMap[overlayEvent.eventSubtype],
  { overlayEvent, showAnimation },
);

VideoOverlay.propTypes = {
  overlayEvent: persistentEventType.isRequired,
  showAnimation: PropTypes.bool.isRequired,
};

export default track(({ overlayEvent }) => ({
  prompt: overlayEvent.prompt,
}), {
  dispatchOnMount: () => ({ event: 'Video Overlay Shown' }),
})(VideoOverlay);
