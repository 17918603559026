import styled, { keyframes } from 'styled-components';

import { colors } from 'styles';

/*
 * iOS Chrome requires a minimum of 125% screen height to start hiding address bar on
 * scroll (for some reason 125-126vh renders a slim white bottom border in Safari).
 */
export const IATMobileContainerWrapper = styled.div`
  position: relative;
  height: 127vh;

  & > div:last-child {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0px);
  }
`;

export const ScrollPrompt = styled.div`
  text-align: center;
  padding-top: 44px;
  animation: ${float} 1.5s linear infinite forwards;
`;

export const ScrollText = styled.span`
  font-size: 1.2rem;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(0,0,0,0.1), 0 3px 12px rgba(0,0,0,0.1);
  background-color: ${colors.yellow.base};
  color: ${colors.sierra.base};
`;
