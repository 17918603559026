import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync actions
export const CACHE_VIDEO_METADATA = 'video/cacheVideoMetadata';
export const cacheVideoMetadata = createAction(CACHE_VIDEO_METADATA);

export const LOAD_VIDEO_USER_METADATA = 'video/loadVideoUserMetadata';
export const loadVideoUserMetadata = createAction(LOAD_VIDEO_USER_METADATA);

export const RESET_VIDEO_TASK = 'video/resetVideoTask';
export const resetVideoTask = createAction(RESET_VIDEO_TASK);

export const SET_VIDEO_BOOKMARK = 'video/setVideoBookmark';
export const setVideoBookmark = createAction(SET_VIDEO_BOOKMARK);

// Dispatched only once per video when the user has finished watching a video
// for the first time
export const SET_VIDEO_COMPLETED = 'video/setVideoCompleted';
export const setVideoCompleted = createAction(SET_VIDEO_COMPLETED);

// Dispatched any time a user has reached the end of a video (can
// be many times if they rewatch a video)
export const SET_VIDEO_ENDED = 'video/setVideoEnded';
export const setVideoEnded = createAction(SET_VIDEO_ENDED);

export const SET_VIDEO_PLAYER_INITIALIZED = 'video/setVideoPlayerInitialized';
export const setVideoPlayerInitialized = createAction(SET_VIDEO_PLAYER_INITIALIZED);

export const SET_VIDEO_PLAYER_ERROR = 'video/setVideoPlayerError';
export const setVideoPlayerError = createAction(SET_VIDEO_PLAYER_ERROR);

export const HANDLE_VIDEO_PLAYER_TEARDOWN = 'video/handleVideoPlayerTeardown';
export const handleVideoPlayerTeardown = createAction(HANDLE_VIDEO_PLAYER_TEARDOWN);

export const SET_VIDEO_PLAYABLE = 'video/setVideoPlayable';
export const setVideoPlayable = createAction(SET_VIDEO_PLAYABLE);

export const SET_VIDEO_PLAYING = 'video/setVideoPlaying';
export const setVideoPlaying = createAction(SET_VIDEO_PLAYING);

export const SET_VIDEO_NOT_PLAYING = 'video/setVideoNotPlaying';
export const setVideoNotPlaying = createAction(SET_VIDEO_NOT_PLAYING);

// Async actions
export const FETCH_DATA_FOR_VIDEO_OVERLAYS = createAsyncTypes('video/fetchDataForVideoOverlays');
export const fetchDataForVideoOverlays = createAsyncAction(FETCH_DATA_FOR_VIDEO_OVERLAYS);

export const FETCH_VIDEO_CREDENTIALS = createAsyncTypes('video/fetchVideoCredentials');
export const fetchVideoCredentials = createAsyncAction(FETCH_VIDEO_CREDENTIALS);

export const HANDLE_VIDEO_BOOKMARK_INTERVAL = createAsyncTypes('video/handleVideoBookmarkInterval');
export const handleVideoBookmarkInterval = createAsyncAction(HANDLE_VIDEO_BOOKMARK_INTERVAL);

export const HANDLE_VIDEO_SEEKING = createAsyncTypes('video/handleVideoSeeking');
export const handleVideoSeeking = createAsyncAction(HANDLE_VIDEO_SEEKING);

export const HANDLE_VIDEO_TIME_UPDATE = createAsyncTypes('video/handleVideoTimeUpdate');
export const handleVideoTimeUpdate = createAsyncAction(HANDLE_VIDEO_TIME_UPDATE);

export const SEND_VIDEO_END_BOOKMARK = createAsyncTypes('video/sendVideoEndBookmark');
export const sendVideoEndBookmark = createAsyncAction(SEND_VIDEO_END_BOOKMARK, {
  log: { // Temporarily logging to determine issue with VCSO
    PENDING: true,
    SUCCESS: true,
  },
});

export const SEND_VIDEO_TASK_ANSWER = createAsyncTypes('video/sendVideoTaskAnswer');
export const sendVideoTaskAnswer = createAsyncAction(SEND_VIDEO_TASK_ANSWER);

export const SET_VIDEO_SOURCE = createAsyncTypes('video/setVideoSource');
export const setVideoSource = createAsyncAction(SET_VIDEO_SOURCE);

export const VIDEO_TASK_COMPLETED = createAsyncTypes('video/videoTaskCompleted');
export const videoTaskCompleted = createAsyncAction(VIDEO_TASK_COMPLETED);

export const SAVE_VIDEO_USER_METADATA = createAsyncTypes('video/saveVideoUserMetadata');
export const saveVideoUserMetadata = createAsyncAction(SAVE_VIDEO_USER_METADATA);
