import React from 'react';
import PropTypes from 'prop-types';

import Select, { createFilter } from 'react-select';

import { surveyOptionType } from 'constants/propTypes';
import customDropdownStyles from './customDropdownStyles';

const SelectDropdown = React.forwardRef(({
  isDisabled,
  onChange,
  options,
  placeholder,
}, ref) => (
  <Select
    filterOption={createFilter({ matchFrom: 'start' })}
    isDisabled={isDisabled}
    onChange={(option) => onChange(option.value)}
    options={options}
    placeholder={placeholder}
    ref={ref}
    styles={customDropdownStyles}
  />
));

SelectDropdown.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(surveyOptionType).isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SelectDropdown;
