import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  IATMobileContainerWrapper,
  ScrollPrompt,
  ScrollText,
} from './style';

const IATMobileContainer = ({ children }) => {
  const { t } = useTranslation('courseViewer');

  return (
    <IATMobileContainerWrapper>
      <ScrollPrompt>
        <ScrollText>{t('courseViewer:iat.mobileMessage')}</ScrollText>
      </ScrollPrompt>
      {children}
    </IATMobileContainerWrapper>
  );
};

IATMobileContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IATMobileContainer;
