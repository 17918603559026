import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as ChapterStatus from 'constants/chapterStatuses';

import Tooltip from 'styles/components/Tooltip';

import {
  getChapterItemIcon,
  getInfoText,
} from './utils';
import {
  ChapterInfoText,
  ChapterItemButton,
  IconContainer,
  InfoContainer,
  Title,
  TooltipWrapper,
} from './style';

const ChapterItem = ({
  duration,
  isCompleted,
  isLastSection,
  onClick,
  status,
  title,
  type,
}) => {
  const { t } = useTranslation(['common', 'courseOverview']);

  const isItemDisabled = status === ChapterStatus.Disabled || status === ChapterStatus.Anonymized;
  const icon = getChapterItemIcon(status, type, isCompleted, t);
  const infoText = status === ChapterStatus.Anonymized
    ? t('courseOverview:status.chapterAnonymized')
    : getInfoText(type, duration, t);

  return (
    <ChapterItemButton
      aria-disabled={isItemDisabled}
      aria-label={title}
      disabled={isItemDisabled}
      onClick={!isItemDisabled ? onClick : undefined}
      status={status}
    >
      <IconContainer>{icon}</IconContainer>
      <InfoContainer>
        <Title status={status}>{title}</Title>
        {infoText && <ChapterInfoText status={status}>{infoText}</ChapterInfoText>}
        {status === ChapterStatus.Anonymized && (
          <TooltipWrapper isArrowReversed={isLastSection}>
            <Tooltip
              arrowHorizontalLocation="left"
              arrowVerticalLocation={isLastSection ? 'bottom' : 'top'}
              showArrow
              variantColor="yellow"
            >
              {t('courseOverview:status.chapterAnonymizedTooltip')}
            </Tooltip>
          </TooltipWrapper>
        )}
      </InfoContainer>
    </ChapterItemButton>
  );
};

ChapterItem.propTypes = {
  duration: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isLastSection: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    ChapterStatus.Anonymized,
    ChapterStatus.Current,
    ChapterStatus.Default,
    ChapterStatus.Disabled,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ChapterItem;
