import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const Title = styled.span`
  display: block;
  font-size: 2.8rem;
  font-weight: ${type.weight.bold};
  margin-bottom: 20px;

  ${media.min.desktop`
    font-size: 3.2rem;
    margin-bottom: 30px;
  `}
`;

export const SectionTitle = styled.span`
  display: block;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  margin-top: ${p => (p.firstChild ? '0' : '24px')};

  ${media.min.desktop`
    margin-top: ${p => (p.firstChild ? '0' : '36px')};
    font-size: 2rem;
  `}
`;

export const SubSectionTitle = styled.span`
  font-weight: 600;
`;

export const SectionList = styled.ul`
  margin: 0;
  padding: 0 20px 0;
`;

export const SectionListItem = styled.li`
  margin-bottom: 12px;

  &:last-child { margin-bottom: 0; }
`;

export const SectionContent = styled.p`
  margin: 12px 0;
`;

export const StyledRedContent = styled.span`
  display: inline-block;
  color: ${colors.red.base};
  margin: 0;
`;
