/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';

import {
  ImageOption,
  ImageOptionsGrid,
} from './style';

const ImageOptions = ({
  columnSpan,
  options,
}) => (
  <ImageOptionsGrid>
    {options.map((option, i) => (
      <ImageOption
        columnSpan={columnSpan}
        key={i}
        url={option}
        role="img"
      />
    ))}
  </ImageOptionsGrid>
);

ImageOptions.propTypes = {
  columnSpan: PropTypes.oneOf(['small', 'medium']),
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ImageOptions.defaultProps = {
  columnSpan: 'medium',
};

export default ImageOptions;
