export enum AICCLaunchParam {
  AICCSid = 'aicc_sid',
  AICCUrl = 'aicc_url',
}

export enum HACPRequestKey {
  AICCData = 'AICC_data',
  Command = 'command',
  SessionID = 'session_id',
  Version = 'version',
}

export enum HACPResponseKey {
  Error = 'error',
  ErrorText = 'error_text',
}

export enum HACPErrorCode {
  Successful = 0,
  InvalidCommand = 1,
  InvalidAUPassword = 2,
  InvalidSessionID = 3,
}

export enum HACPCommand {
  ExitAU = 'ExitAU',
  GetParam = 'GetParam',
  PutParam = 'PutParam',
}

export enum LessonStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
  NotAttempted = 'not attempted',
}

export type PutParamData = {
  Lesson_Location: string;
  Lesson_Status: LessonStatus;
  Score: string;
  Time: string;
};

export type AICCLaunchParams = {
  [AICCLaunchParam.AICCSid]: string;
  [AICCLaunchParam.AICCUrl]: string;
};
