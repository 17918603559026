import React from 'react';
import PropTypes from 'prop-types';

import {
  TextOption,
  TextOptionsGrid,
} from './style';

const TextOptions = ({
  options,
}) => (
  <TextOptionsGrid>
    {options.map(option => (
      <TextOption key={option}>{option}</TextOption>
    ))}
  </TextOptionsGrid>
);

TextOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TextOptions;
