import { createSelector } from 'reselect';

import { isDemoCompanySelector } from 'store/user/selectors';

import { PASSWORD_LOGIN, AUTH_CODE_ANONYMOUS_LOGIN, AUTH_CODE_LOGIN } from 'constants/loginFlowTypes';

export const didGenerateMagicLinkSelector = state => state.auth.didGenerateMagicLink;
export const didResetPasswordSelector = state => state.auth.didResetPassword;
export const didSubmitPasswordRecoverySelector = state => (
  state.auth.didSubmitPasswordRecovery);
export const isAuthLoadingSelector = state => state.auth.isLoading;
export const isAuthenticatingUserSelector = state => state.auth.isAuthenticating;
export const authErrorSelector = state => state.auth.error;
export const isFetchingSSOUrlSelector = state => state.auth.isFetchingSSOUrl;
export const isGeneratingMagicLinkSelector = state => state.auth.isGeneratingMagicLink;
export const isUserRegistrationConflictErrorSelector = state => (
  state.auth.isUserRegistrationConflictError);
export const loginFlowTypeSelector = state => state.auth.loginFlowType;
export const ssoUrlSelector = state => state.auth.ssoUrl;
export const appErrorSelector = state => state.auth.initAppError;
export const accessTokenSelector = state => state.auth.accessToken;
export const isAppInitializingSelector = state => state.auth.isAppInitializing;
export const refreshTokenSelector = state => state.auth.refreshToken;

export const isPasswordLoginTypeSelector = createSelector(
  loginFlowTypeSelector,
  loginFlowType => (loginFlowType ? loginFlowType === PASSWORD_LOGIN : false),
);

const isAuthCodeLoginTypeSelector = createSelector(
  loginFlowTypeSelector,
  loginFlowType => (loginFlowType ? loginFlowType === AUTH_CODE_LOGIN : false),
);

const isAuthCodeAnonymousLoginTypeSelector = createSelector(
  loginFlowTypeSelector,
  loginFlowType => (loginFlowType ? loginFlowType === AUTH_CODE_ANONYMOUS_LOGIN : false),
);

export const isAccountDetailsPermittedSelector = createSelector(
  isAuthCodeLoginTypeSelector,
  isAuthCodeAnonymousLoginTypeSelector,
  isDemoCompanySelector,
  (isAuthCodeLoginType, isAuthCodeAnonymousLogin, isDemoCompany) => (
    !isAuthCodeLoginType && !isAuthCodeAnonymousLogin && !isDemoCompany
  ),
);
