import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  RESET_PASSWORD,
  resetPassword,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:resetPassword');

export function* resetPasswordSaga({ payload: data }) {
  debug('Saga called', data);

  try {
    yield call(API.resetPassword, decamelizeKeys(data));

    yield put(resetPassword.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(resetPassword.error(e));
  }
}

export default function* resetPasswordDefaultSaga() {
  yield takeLatest(RESET_PASSWORD.PENDING, resetPasswordSaga);
}
