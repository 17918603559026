import * as t from './errorTypes';

const validationsByType = {
  confirmPassword: [t.EMPTY_FIELD],
  email: [t.EMPTY_FIELD, t.INVALID_EMAIL],
  password: [t.EMPTY_FIELD, t.INVALID_PASSWORD],
  requiredField: [t.EMPTY_FIELD],
};

export const regexes = {
  [t.EMPTY_FIELD]: /\S/,
  [t.INVALID_EMAIL]: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  [t.INVALID_PASSWORD]: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*)(+=._-]{6,}$/,
};

const validator = type => value => {
  const validations = validationsByType[type];

  return validations.find(validation => !regexes[validation].test(value));
};

export default validator;
