import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Loading from 'components/shared/Loading';
import RadioButton from 'components/shared/RadioButton';
import StyledButton from 'components/shared/StyledButton';
import TextField from 'components/shared/TextField';

import { ChapterSubType } from './types';
import { radioButtonTheme, textFieldTheme } from './theme';
import {
  BodyText,
  ButtonWrapper,
  ChapterWrapper,
  ContentWrapper,
  LoadingWrapper,
  OptionsWrapper,
  OptionWrapper,
  TextFieldWrapper,
  TitleText,
} from './style';

const AnonymizeResultsChapter = ({
  email,
  isAndroidTablet,
  isAnonymizing,
  isCompleted,
  isEmailInvalid,
  isMobileDevice,
  onAnonymizeResults,
  onContinue,
  onEmailChange,
  onOptionChange,
  shouldShowEmailOption,
  subType,
  t,
  willSendResults,
}) => {
  const titleText = subType === ChapterSubType.CompleteCourse
    ? t('courseViewer:anonymizeResults.title.completeCourse')
    : t('courseViewer:anonymizeResults.title.default');

  let bodyText = t('courseViewer:anonymizeResults.body.default');
  if (subType === ChapterSubType.Standard) bodyText += ` ${t('courseViewer:anonymizeResults.body.emailText')}`;
  else if (subType === ChapterSubType.CompleteCourse) bodyText = t('courseViewer:anonymizeResults.body.completeCourse');

  let buttonText = willSendResults
    ? t('courseViewer:anonymizeResults.button.sendEmail')
    : t('courseViewer:anonymizeResults.button.anonymize');
  if (subType === ChapterSubType.CompleteCourse) buttonText = t('courseViewer:anonymizeResults.button.completeCourse');

  return (
    <>
      {isAnonymizing && subType !== ChapterSubType.CompleteCourse && (
        <LoadingWrapper isPositionFixed={isMobileDevice}>
          <Loading bodyText={t('courseViewer:anonymizeResults.anonymizingMessage')} />
        </LoadingWrapper>
      )}
      <ChapterWrapper isAndroidTablet={isAndroidTablet}>
        <ContentWrapper isDimmed={isAnonymizing && subType !== ChapterSubType.CompleteCourse}>
          {!isCompleted ? (
            <>
              <TitleText>{titleText}</TitleText>
              <BodyText>{bodyText}</BodyText>
              {shouldShowEmailOption && (
                <OptionsWrapper>
                  <OptionWrapper>
                    <RadioButton
                      id="send-results"
                      isChecked={willSendResults}
                      name="should-send-results"
                      onChange={() => onOptionChange(true)}
                      theme={radioButtonTheme}
                    >
                      {t('courseViewer:anonymizeResults.sendResultsOption')}
                    </RadioButton>
                  </OptionWrapper>
                  {willSendResults && (
                    <TextFieldWrapper>
                      <TextField
                        hasExternalError={isEmailInvalid}
                        labelText={t('courseViewer:anonymizeResults.emailLabel')}
                        name="email"
                        onChange={onEmailChange}
                        theme={textFieldTheme}
                        value={email || ''}
                      />
                    </TextFieldWrapper>
                  )}
                  <OptionWrapper>
                    <RadioButton
                      id="continue"
                      isChecked={!willSendResults}
                      name="should-send-results"
                      onChange={() => onOptionChange(false)}
                      theme={radioButtonTheme}
                    >
                      {t('courseViewer:anonymizeResults.doNotSendResultsOption')}
                    </RadioButton>
                  </OptionWrapper>
                </OptionsWrapper>
              )}
              <ButtonWrapper>
                <StyledButton
                  disabled={isAnonymizing}
                  loading={isAnonymizing && subType === ChapterSubType.CompleteCourse}
                  onClick={onAnonymizeResults}
                >
                  {buttonText}
                </StyledButton>
              </ButtonWrapper>
            </>
          ) : (
            <>
              <TitleText>{t('courseViewer:anonymizeResults.completedTitle')}</TitleText>
              {!!email && (
                <BodyText>
                  <Trans i18nKey="courseViewer:anonymizeResults.resultsEmailMessage">
                    An email will be sent to
                    <strong>{{ email }}</strong>
                    with a copy of your results.
                    Please allow some time for your results to be sent.
                  </Trans>
                </BodyText>
              )}
              <ButtonWrapper>
                <StyledButton onClick={onContinue}>{t('continue')}</StyledButton>
              </ButtonWrapper>
            </>
          )}
        </ContentWrapper>
      </ChapterWrapper>
    </>
  );
};

AnonymizeResultsChapter.propTypes = {
  email: PropTypes.string,
  isAndroidTablet: PropTypes.bool.isRequired,
  isAnonymizing: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isEmailInvalid: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  onAnonymizeResults: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  shouldShowEmailOption: PropTypes.bool.isRequired,
  subType: PropTypes.oneOf(Object.values(ChapterSubType)).isRequired,
  t: PropTypes.func.isRequired,
  willSendResults: PropTypes.bool.isRequired,
};

AnonymizeResultsChapter.defaultProps = {
  email: null,
};

export default AnonymizeResultsChapter;
