import styled from 'styled-components';
import { media, type } from 'styles';

export const RedirectWrapper = styled.div`
  padding: 32px 16px;
  width: 100%;
`;

export const Message = styled.span`
  display: block;
  text-align: center;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  margin-top: 44px;

  ${media.min.tablet`
    font-size: 2.2rem;
    margin-top: 64px;
  `}
`;
