import styled from 'styled-components';

import { colors, media, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';
import StyledExternalLink from 'styles/components/StyledExternalLink';

export const WindowSizeObserverWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const NotSupportedMessage = styled.div`
  position: fixed;
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 64px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  color: ${colors.white};
  opacity: 1;

  ${media.twoDimMin.tablet`
    visibility: hidden;
    opacity: 0;
  `}
`;

export const ExternalLink = styled(StyledExternalLink)`
  color: ${colors.yellow.base};
  font-weight: ${type.weight.semiBold};
`;

export const MessageHeader = styled.h3`
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  font-size: 1.6rem;
`;
