import Debug from 'debug';
import {
  call,
  delay,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  currentChapterIdSelector,
  currentCourseIdSelector,
} from 'store/courses/selectors';
import { chapterCompleted } from 'store/courses/actions';
import { setUserDataAnonymized } from 'store/entities/courses/actions';

import {
  ANONYMIZE_USER_COURSE_DATA,
  anonymizeUserCourseData,
} from '../actions';

export const DATA_ANONYMIZATION_DELAY_MS = 3000;

const debug = Debug('biassync:lms:store:userDataAnonymization:sagas:anonymizeUserCourseData');

export function* anonymizeUserCourseDataSaga({ payload }) {
  debug('Saga called', payload);

  const courseId = yield select(currentCourseIdSelector);

  try {
    yield call(
      API.anonymizeUserCourseData,
      courseId,
      payload && payload.email,
    );

    debug('Delaying %d millis...', DATA_ANONYMIZATION_DELAY_MS);
    yield delay(DATA_ANONYMIZATION_DELAY_MS);

    const chapterId = yield select(currentChapterIdSelector);
    yield put(chapterCompleted({ chapterId, courseId }));
    yield put(setUserDataAnonymized({ courseId }));

    yield put(anonymizeUserCourseData.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(anonymizeUserCourseData.error(e));
  }
}

export default function* anonymizeUserCourseDataDefaultSaga() {
  yield takeLatest(ANONYMIZE_USER_COURSE_DATA.PENDING, anonymizeUserCourseDataSaga);
}
