import styled from 'styled-components';
import { colors } from 'styles';

const StyledExternalLink = styled.a`
  color: ${colors.blue.base};
  text-decoration: none;
  outline: none;

  &.focus-visible {
    border-bottom: 2px solid ${colors.blue.light};
  }
`;

export default StyledExternalLink;
