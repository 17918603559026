import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';
import i18n from 'lib/i18n';

const labelTextStyle = css`
  font-size: 1.2rem;
  font-weight: ${type.weight.extraBold};
  color: ${colors.yellow.base};
  letter-spacing: .8px;

  ${media.twoDimMin.tablet`font-size: 1.4rem;`}
  ${media.twoDimMin.desktop`font-size: 1.6rem;`}
`;

export const CategoryDescription = styled.div`
  text-align: center;
  &:before {
    ${labelTextStyle}
  }

  & + & {
    &:before {
      content: "${() => i18n.t('courseViewer:iat.or')}";
      text-transform: uppercase;
      margin: 0 20px;

      ${media.twoDimMin.tablet`
        display: block;
        margin: 24px 0;
      `}
      ${media.twoDimMin.desktop`margin: 40px 0;`}
      ${media.twoDimMin.max`margin: 48px 0;`}
    }
  }
`;

export const CategoryName = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: ${type.weight.bold};
  font-size: 2rem;
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.6rem;`}
  ${media.twoDimMin.desktop`font-size: 2.8rem;`}
  ${media.twoDimMin.max`font-size: 3.2rem;`}

  &:before {
    display: block;
    content: "${p => (p.isImageType ? i18n.t('courseViewer:iat.photosOf') : i18n.t('courseViewer:iat.termsLike'))}";
    text-transform: uppercase;
    ${labelTextStyle}
  }
`;
