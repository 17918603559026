/* eslint-disable sort-keys */
import { colors, type } from 'styles';
import backgroundSVG from 'assets/bgtexture.svg';

import { SurveySubTypes } from './constants';

const defaultRatingItemTheme = {
  unchecked: {
    background: 'rgba(255, 255, 255, 0.2)',
    color: colors.white,
  },
  hover: {
    background: 'rgba(255, 255, 255, 0.3)',
  },
};

const defaultRadioButtonTheme = {
  selected: {
    background: colors.white,
    borderColor: colors.white,
  },
  borderColor: colors.white,
};

export const darkTheme = {
  anchorTag: {
    color: colors.yellow.base,
  },
  background: `url(${backgroundSVG}) ${colors.sierra.base} center`,
  surveyItem: { marginBottom: '325px' },
  radioButton: {
    ...defaultRadioButtonTheme,
    unselected: {
      background: colors.sierra.base,
      borderColor: colors.white,
    },
    focus: {
      shadow: colors.blue.base,
    },
  },
  ratingItem: {
    ...defaultRatingItemTheme,
    checked: {
      background: '#3c6c8c',
      color: colors.white,
    },
    focus: {
      shadow: colors.white,
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
  likert: {
    text: { color: colors.white },
    selected: {
      background: colors.sierra.base,
      color: colors.white,
    },
    unselected: {
      background: colors.sierra.base,
      color: colors.white,
    },
  },
  matrix: {
    text: { color: colors.white },
    hover: {
      background: 'rgba(69, 120, 151, 0.6)',
    },
    empty: {
      background: colors.sierra.base,
    },
    background: 'rgba(69, 120, 151, 0.4)',
  },
  message: {
    title: {
      color: '#a8bd5d',
    },
    standard: {
      color: '#a8bd5d',
      fontSize: {
        base: '1.8rem',
        tablet: '2rem',
        desktop: '2.2rem',
      },
      weight: type.weight.bold,
      align: 'left',
    },
  },
};

const vignetteAssessmentTheme = {
  ...darkTheme,
  background: colors.sierra.base,
  surveyItem: { marginBottom: '250px' },
  message: {
    title: {
      color: colors.white,
    },
    standard: {
      color: colors.white,
      fontSize: {
        base: '2rem',
        tablet: '2.2rem',
        desktop: '2.4rem',
      },
      weight: type.weight.bold,
      align: 'center',
    },
  },
  chooseOne: {
    selected: {
      background: colors.brandedBlue.base,
      color: colors.white,
      outerCircle: {
        background: colors.white,
      },
      innerCircle: {
        background: colors.brandedBlue.base,
      },
    },
    unselected: {
      background: colors.white,
      color: colors.sierra.base,
      outerCircle: {
        background: '#3B4B5B',
      },
    },
    focused: {
      color: colors.yellow.base,
    },
    otherOption: {
      textfield: {
        color: colors.black,
        placeholder: { color: '#7D838A' },
        button: {
          background: colors.brandedBlue.base,
          checkmark: {
            color: colors.white,
            focused: { color: colors.yellow.base },
          },
        },
      },
      outline: {
        background: colors.white,
        color: colors.sierra.base,
      },
    },
  },
};

export const lightTheme = {
  anchorTag: { color: colors.yellow.base },
  background: `url(${backgroundSVG}) ${colors.blue.base} center`,
  surveyItem: { marginBottom: '250px' },
  radioButton: {
    ...defaultRadioButtonTheme,
    unselected: {
      background: colors.blue.base,
      borderColor: colors.white,
    },
    focus: {
      shadow: colors.yellow.base,
    },
  },
  ratingItem: {
    ...defaultRatingItemTheme,
    checked: {
      background: colors.white,
      color: colors.sierra.base,
    },
    focus: {
      shadow: colors.yellow.base,
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
  likert: {
    text: { color: colors.white },
    selected: {
      background: colors.blue.base,
      color: colors.sierra.base,
    },
    unselected: {
      background: colors.blue.base,
      color: colors.white,
    },
  },
  matrix: {
    text: { color: colors.white },
    hover: {
      background: 'rgba(255, 255, 255, 0.25)',
    },
    empty: {
      background: colors.blue.base,
    },
    background: 'rgba(255, 255, 255, 0.2)',
  },
  message: {
    title: {
      color: colors.white,
    },
    standard: {
      color: colors.white,
      fontSize: {
        base: '2.2rem',
        tablet: '2.4rem',
        desktop: '2.8rem',
      },
      weight: type.weight.bold,
      align: 'center',
    },
  },
  chooseOne: {
    selected: {
      background: colors.green.base,
      color: colors.white,
      outerCircle: {
        background: colors.white,
      },
      innerCircle: {
        background: colors.green.base,
      },
    },
    unselected: {
      background: colors.white,
      color: colors.sierra.base,
      outerCircle: {
        background: colors.blue.base,
      },
    },
    focused: {
      color: colors.yellow.base,
    },
    otherOption: {
      textfield: {
        color: colors.black,
        placeholder: { color: '#7D838A' },
        button: {
          background: colors.yellow.base,
          checkmark: {
            color: colors.blue.base,
            focused: { color: colors.blue.light },
          },
        },
      },
      outline: {
        background: colors.white,
        color: colors.sierra.base,
      },
    },
  },
};

export const getTheme = (surveySubType) => {
  switch (surveySubType) {
    case SurveySubTypes.Familiarity:
      return darkTheme;
    case SurveySubTypes.Vignette:
      return vignetteAssessmentTheme;
    case SurveySubTypes.Standard:
    default:
      return lightTheme;
  }
};
