import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { useDispatch, useSelector } from 'react-redux';

import VideoPlayer from 'lib/VideoPlayer';
import {
  isMultipleChoiceFactTaskEvent,
  isMultipleChoiceOpinionTaskEvent,
  isTextTaskEvent,
  VideoTaskEvent,
} from 'types/VideoTaskEvent';

import {
  videoTaskCompleted,
  sendVideoTaskAnswer,
} from 'store/video/actions';
import { isSendingVideoTaskAnswerSelector } from 'store/video/selectors';

import MultipleChoiceFactTask from './MultipleChoiceTask/MultipleChoiceFactTask';
import MultipleChoiceOpinionTask from './MultipleChoiceTask/MultipleChoiceOpinionTask';
import TextTask from './TextTask';
import { DimBackground } from './style';

type VideoTaskProps = {
  chapterId: string;
  taskEvent: VideoTaskEvent;
};

const VideoTask = ({
  chapterId,
  taskEvent,
}: VideoTaskProps) => {
  const { trackEvent } = useTracking({}, {
    dispatchOnMount: () => ({ event: 'Video Task Shown', prompt: taskEvent.prompt }),
  });
  const dispatch = useDispatch();
  const isSendingVideoTaskAnswer = useSelector(isSendingVideoTaskAnswerSelector);

  useEffect(() => {
    VideoPlayer.pause();
    VideoPlayer.blurVideo();
    VideoPlayer.disablePlay();

    return () => {
      VideoPlayer.enablePlay();
      VideoPlayer.disableBlurVideo();
      VideoPlayer.play();
    };
  }, []);

  const handleSubmit = (response: string) => {
    trackEvent({ event: 'Video Task Response Submitted' });
    dispatch(sendVideoTaskAnswer({ response, taskId: taskEvent.id }));
  };

  const handleContinue = () => dispatch(videoTaskCompleted({ chapterId, taskId: taskEvent.id }));

  let content = null;
  if (isMultipleChoiceFactTaskEvent(taskEvent)) {
    content = (
      <MultipleChoiceFactTask
        isSubmitting={isSendingVideoTaskAnswer}
        onContinue={handleContinue}
        onSubmit={handleSubmit}
        taskEvent={taskEvent}
      />
    );
  } else if (isMultipleChoiceOpinionTaskEvent(taskEvent)) {
    content = (
      <MultipleChoiceOpinionTask
        isSubmitting={isSendingVideoTaskAnswer}
        onContinue={handleContinue}
        onSubmit={handleSubmit}
        taskEvent={taskEvent}
      />
    );
  } else if (isTextTaskEvent(taskEvent)) {
    content = (
      <TextTask
        isSubmitting={isSendingVideoTaskAnswer}
        onContinue={handleContinue}
        onSubmit={handleSubmit}
        taskEvent={taskEvent}
      />
    );
  } else handleContinue();

  return (
    <DimBackground>
      {content}
    </DimBackground>
  );
};

export default VideoTask;
