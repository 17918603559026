import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  EDIT_USER,
  editUser,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:editUser');

export function* editUserSaga({ payload: editedFields }) {
  debug('Saga called with edited user fields', editedFields);

  try {
    yield call(API.editUser, decamelizeKeys(editedFields));

    debug('User successfully edited');

    yield put(editUser.success(editedFields));
  } catch (e) {
    debug('Saga error', e);
    yield put(editUser.error(e));
  }
}

export default function* editUserDefaultSaga() {
  yield takeLatest(EDIT_USER.PENDING, editUserSaga);
}
