import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import VideoPlayer from 'lib/VideoPlayer';

import { shouldOverrideLockSelector } from 'store/featureFlags/selectors';
import { videoFurthestPointSelector } from '../selectors';
import {
  HANDLE_VIDEO_SEEKING,
  handleVideoSeeking,
  setVideoBookmark,
} from '../actions';

const debug = Debug('biassync:lms:store:video:sagas:handleVideoSeeking');

export function* handleVideoSeekingSaga() {
  debug('Saga called');

  try {
    const currentTime = yield call([VideoPlayer, 'getCurrentTime']);
    const furthestPoint = yield select(videoFurthestPointSelector);
    const isOverrideLock = yield select(shouldOverrideLockSelector);

    if (currentTime > furthestPoint && !isOverrideLock) {
      debug('Setting time to furthest point', furthestPoint);
      yield call([VideoPlayer, 'setCurrentTime'], furthestPoint);
      yield put(setVideoBookmark(furthestPoint));
    }

    yield put(handleVideoSeeking.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(handleVideoSeeking.error(e));
  }
}

export default function* handleVideoSeekingDefaultSaga() {
  yield takeLatest(HANDLE_VIDEO_SEEKING.PENDING, handleVideoSeekingSaga);
}
