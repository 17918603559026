/* eslint-disable brace-style */

import Debug from 'debug';
import {
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { camelizeKeys } from 'humps';
import UAParser from 'ua-parser-js';

import {
  getHttpStatusCode,
  is4XXError,
  is5XXError,
} from 'lib/errorHandler';
import 'lib/monitoring';
import awaitAsyncAction from 'lib/awaitAsyncAction';
import SessionActions from 'lib/sessionActions';
import { getQueryParams } from 'lib/queryParams';
import { getSubdomain } from 'lib/subDomain';
import AppStorage from 'lib/appStorage';
import { SELECTED_LANGUAGE } from 'lib/i18n';
import getFeatureFlags from 'lib/getFeatureFlags';
import { MAGIC_LINK_TOKEN_PARAM, SSO_DATA_PARAM } from 'constants/auth';
import { getAICCLaunchParams } from 'services/AICC/utils';

import aiccSessionManager from 'store/aicc/sagas/aiccSessionManager';
import {
  DELETE_USER,
  editUserLanguage,
  FETCH_USER,
  fetchUser,
  SUBMIT_POLICY_CONSENT,
} from 'store/user/actions';
import { setFeatureFlags } from 'store/featureFlags/actions';
import { setUserAgent } from 'store/userAgent/actions';
import { userLanguageCodeSelector } from 'store/user/selectors';

import {
  FETCH_LOGIN_FLOW_TYPE,
  I18N_INITIALIZED,
  LOGIN_WITH_AUTH_CODE,
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  LOGIN_WITH_MAGIC_LINK,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_SSO,
  LOGOUT_USER,
  REFRESH_ACCESS_TOKEN,
  REGISTER_USER,
  fetchLoginFlowType,
  initializeApp,
  loginWithMagicLink,
  loginWithSSO,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:sessionManager');

const EXPIRED_COMPANY_STATUS_CODE = 401;

function* setUserAgentAction(queryParams) {
  const userAgent = UAParser();
  yield put(setUserAgent({
    deviceTypeOverride: queryParams.d,
    userAgent,
  }));
}

export function* manageUserLanguage() {
  const userLanguageCode = yield select(userLanguageCodeSelector);
  const storedLanguageCode = yield call(AppStorage.getItem, SELECTED_LANGUAGE);

  if (storedLanguageCode !== userLanguageCode) {
    if (storedLanguageCode) {
      debug('Updating backend with users stored language code');

      yield put(editUserLanguage(storedLanguageCode));
    } else if (userLanguageCode) {
      debug('Updating frontend with backend language code');

      yield call(AppStorage.setItem, SELECTED_LANGUAGE, userLanguageCode);
      window.location.reload();
    }
  }
}

function* setTakeLatestActions() {
  yield takeLatest([
    LOGIN_WITH_AUTH_CODE.SUCCESS,
    LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS,
    LOGIN_WITH_MAGIC_LINK.SUCCESS,
    LOGIN_WITH_PASSWORD.SUCCESS,
    LOGIN_WITH_SSO,
    REFRESH_ACCESS_TOKEN.SUCCESS,
    REGISTER_USER.SUCCESS,
  ], SessionActions.persistSession);

  yield takeLatest([
    DELETE_USER.SUCCESS,
    LOGOUT_USER,
    REFRESH_ACCESS_TOKEN.ERROR,
    SUBMIT_POLICY_CONSENT.SUCCESS,
  ], SessionActions.terminateSession);

  yield takeLatest([
    LOGIN_WITH_AUTH_CODE.SUCCESS,
    LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS,
    LOGIN_WITH_MAGIC_LINK.SUCCESS,
    LOGIN_WITH_PASSWORD.SUCCESS,
    LOGIN_WITH_SSO,
    REGISTER_USER.SUCCESS,
    FETCH_USER.SUCCESS,
  ], manageUserLanguage);
}

function* validateSubdomain(hasLoginCredentials) {
  const [, errorAction] = yield call(awaitAsyncAction, FETCH_LOGIN_FLOW_TYPE);

  if (errorAction) {
    const { payload: error } = errorAction;

    if (error.isConnectivityError
      || (getHttpStatusCode(error) === EXPIRED_COMPANY_STATUS_CODE)) throw error;
    if (is4XXError(error)) throw new Error('Invalid subdomain');
    if (is5XXError(error) && !hasLoginCredentials) throw new Error('No login flow to display');
  }
}

export default function* initSessionManager() {
  yield put(initializeApp());

  const queryParams = yield call(getQueryParams);
  const subdomain = yield call(getSubdomain);

  yield* (setUserAgentAction(queryParams));
  yield* (setTakeLatestActions());

  try {
    if (!subdomain) throw new Error('No subdomain found');

    const persistedSessionTokens = yield call(SessionActions.checkPersistedSession);
    const magicLinkToken = queryParams[MAGIC_LINK_TOKEN_PARAM];
    const ssoLoginData = queryParams[SSO_DATA_PARAM];

    yield take(I18N_INITIALIZED);
    if (queryParams.lang) yield call(AppStorage.setItem, SELECTED_LANGUAGE, queryParams.lang);

    yield put(fetchLoginFlowType(subdomain));

    yield* validateSubdomain(!!persistedSessionTokens || !!ssoLoginData || !!magicLinkToken);

    const featureFlags = yield call(getFeatureFlags, queryParams);
    yield put(setFeatureFlags(featureFlags));

    if (magicLinkToken) {
      debug('Logging in with magic link token');
      yield put(loginWithMagicLink(magicLinkToken));
      yield call(awaitAsyncAction, LOGIN_WITH_MAGIC_LINK);
    } else if (ssoLoginData) {
      debug('Logging in with SSO data');
      const queryParamData = camelizeKeys(JSON.parse(ssoLoginData));
      yield put(loginWithSSO(queryParamData));
    } else if (persistedSessionTokens) {
      debug('Restoring persisted session');
      yield put(fetchUser());
      yield call(awaitAsyncAction, FETCH_USER);
    }

    const aiccLaunchParams = yield call(getAICCLaunchParams, queryParams);
    if (aiccLaunchParams) yield fork(aiccSessionManager, aiccLaunchParams);

    yield put(initializeApp.success());
  } catch (e) {
    debug('Saga error', e);

    yield put(initializeApp.error(e));
  }
}
