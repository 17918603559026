import styled from 'styled-components';
import { colors } from 'styles';

import TransparentButton from 'styles/components/TransparentButton';

export const ToastWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  pointer-events: auto;
  padding: 10px;
`;

export const ToastContent = styled.div`
  font-size: 1.6rem;
  margin-left: 18px;
`;

export const TryAgainButton = styled(TransparentButton)`
  font-size: 1.6rem;
  color: ${colors.sierra.base};
  outline: none;
  border-bottom: 1px solid #fff;
  color: #fff;

  &:hover,
  &.focus-visible {
    border-bottom: 1px solid ${colors.blue.light};
  }
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 25px;
  height: 25px;
`;
