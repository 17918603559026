import React from 'react';
import PropTypes from 'prop-types';

import { surveyPromptType } from 'constants/propTypes';

import ChooseOneOption from './ChooseOneOption';
import OtherOption from '../OtherOption';

import { Wrapper } from './style';

const ChooseOnePrompt = ({
  isDisabled,
  onChange,
  prompt: {
    id,
    promptOptions,
    promptType,
  },
  response,
}) => {
  const handleOtherChoiceChange = (optionId, otherChoice) => {
    let otherOptionId = optionId;
    let isKeyboardEvent = false;

    if (otherChoice.response) onChange(optionId, otherChoice, isKeyboardEvent);
    else {
      otherOptionId = null;
      isKeyboardEvent = true;
    }

    onChange(id, otherOptionId, isKeyboardEvent);
  };

  return (
    <Wrapper>
      {promptOptions.map(option => (
        <React.Fragment key={option.id}>
          {option.isOther ? (
            <OtherOption
              isDisabled={isDisabled}
              isSelected={response === option.id}
              onChange={(otherChoice) => handleOtherChoiceChange(option.id, otherChoice)}
              option={option}
              promptId={id}
              promptType={promptType}
            />
          ) : (
            <ChooseOneOption
              id={option.id}
              isDisabled={isDisabled}
              isSelected={response === option.id}
              name={id}
              onChange={() => onChange(id, option.id, true)}
              onClick={() => onChange(id, option.id)}
            >
              {option.optionText}
            </ChooseOneOption>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

ChooseOnePrompt.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.string,
};

ChooseOnePrompt.defaultProps = {
  response: null,
};

export default ChooseOnePrompt;
