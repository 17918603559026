/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import {
  colors,
  media,
  spacing,
  type,
} from 'styles';
import * as ChapterStatus from 'constants/chapterStatuses';

import TransparentButton from 'styles/components/TransparentButton';

const colorSwitcher = defaultColor => ({ status }) => {
  const theme = {
    [ChapterStatus.Anonymized]: colors.shasta.base,
    [ChapterStatus.Current]: colors.white,
    [ChapterStatus.Default]: defaultColor,
    [ChapterStatus.Disabled]: colors.shasta.base,
  };

  return theme[status];
};

export const ChapterItemButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${colors.rocky.base};
  padding: 22px 24px;
  width: 100%;
  outline: none;

  ${media.min.tablet`padding: 22px 28px;`}

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    background: ${p => (p.status === ChapterStatus.Current ? colors.blue.focus : colors.rocky.background)};
  }

  ${p => p.status === ChapterStatus.Current && `
    background: ${colors.blue.base};
    &:hover {
      background: ${colors.blue.focus};
    }
  `}

  ${p => p.status === ChapterStatus.Default && `
    &:hover {
      background: ${colors.rocky.background};
    }
  `}
  ${p => (p.status === ChapterStatus.Anonymized || p.status === ChapterStatus.Disabled) && 'cursor: not-allowed;'}
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  width: 285px;
  height: 100%;
  z-index: 3;
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none;
  bottom: -30px;
  left: -42px;
  ${p => (p.isArrowReversed ? 'top: -125px' : 'bottom: -32px')};

  ${media.min.mobileLandscape`
    width: 300px;
    ${p => (p.isArrowReversed ? 'top: -115px' : 'bottom: -32px')};
  `}

  ${ChapterItemButton}:disabled.focus-visible &,
  ${ChapterItemButton}:disabled:hover & { opacity: 1; }
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  width: 22px;
  height: 22px;

  ${media.min.tablet`
    width: 24px;
    height: 24px;
  `}

  ${media.min.desktop`
    width: 26px;
    height: 26px;
  `}
`;

export const InfoContainer = styled.div`
  position: relative;
  margin-left: 16px;

  ${media.min.tablet`margin-left: 24px;`}
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  color: ${colorSwitcher(colors.sierra.base)};
  text-align: left;

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;

export const ChapterInfoText = styled.div`
  font-size: 1.1rem;
  font-weight: ${type.weight.bold};
  color: ${p => (p.status === ChapterStatus.Anonymized ? colors.yellow.dark : colorSwitcher(colors.shasta.base))};
  text-transform: uppercase;
  text-align: left;
  margin-top: ${spacing.slim};

  ${media.min.mobileLandscape`font-size: 1.2rem;`}
`;
