import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FocusTrap from 'components/shared/FocusTrap';

import {
  CloseButton,
  ModalContentWrapper,
  ModalWrapper,
} from './style';

class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.originalHtmlOverflow = document.documentElement.style.overflow;
    document.documentElement.style.overflow = 'hidden';

    window.addEventListener('mousedown', this.handleMousedown, true);
    window.addEventListener('keyup', this.handleKeyUp, true);
  }

  componentWillUnmount() {
    document.documentElement.style.overflow = this.originalHtmlOverflow;

    window.removeEventListener('mousedown', this.handleMousedown, true);
    window.removeEventListener('keyup', this.handleKeyUp, true);
  }

  handleMousedown = event => {
    const { isRequired, onClose } = this.props;
    event.stopPropagation();

    if (this.modalRef.current.contains(event.target) || isRequired) {
      return;
    }

    onClose();
  };

  handleKeyUp = event => {
    const { isRequired, onClose } = this.props;
    event.stopPropagation();

    if ((event.key === 'Escape' || event.key === 'Esc') && !isRequired) {
      onClose();
    }
  };

  render() {
    const { isRequired, onClose, shouldHideOnPrint } = this.props;
    return (
      <FocusTrap>
        <ModalWrapper tabIndex="-1" role="dialog" shouldHideOnPrint={shouldHideOnPrint}>
          <ModalContentWrapper ref={this.modalRef}>
            {!isRequired && (
              <CloseButton onClick={onClose}>&times;</CloseButton>
            )}
            {this.props.children}
          </ModalContentWrapper>
        </ModalWrapper>
      </FocusTrap>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  onClose: PropTypes.func,
  shouldHideOnPrint: PropTypes.bool,
};

Modal.defaultProps = {
  isRequired: false,
  onClose: null,
  shouldHideOnPrint: false,
};

export default Modal;
