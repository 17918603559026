import styled from 'styled-components';

import forceImportant from 'styles/functions/forceImportant';
import {
  colors,
  media,
  spacing,
  type,
} from 'styles';

export const variantStyles = {
  brandedBlue: {
    bgColor: colors.brandedBlue.base,
    bgColorFocus: colors.brandedBlue.focus,
    border: 'solid 10px rgba(35, 147, 215, 0.2)',
  },
  default: {
    bgColor: colors.ridge.base,
    bgColorFocus: colors.ridge.focus,
    border: 'solid 10px rgba(75, 80, 100, 0.2)',
  },
  primary: {
    bgColor: colors.blue.base,
    bgColorFocus: colors.blue.focus,
    border: 'solid 10px rgba(35, 147, 215, 0.2)',
  },
  success: {
    bgColor: colors.green.base,
    bgColorFocus: colors.green.focus,
    border: 'solid 10px rgba(93, 202, 156, 0.2)',
  },
};

// Forcing specificity so video-js does not override with their default style,
// see documentation for more details.
export const Button = styled.button`
  ${forceImportant`
    position: relative;
    font-size: 1.6rem;
    font-weight: ${type.weight.bold};
    border-radius: 45px;
    padding: 12px ${spacing.normal};
    background-clip: padding-box;

    color: ${colors.white};
    width: ${({ full }) => (full ? '100%' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ faded }) => (faded ? '0.4' : '1')};
    background-color: ${({ variant }) => variantStyles[variant].bgColor};
    border: ${({ variant }) => variantStyles[variant].border};
    outline: none;
    transition: background-color 0.2s;

    &.focus-visible {
      box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
      background-color: ${({ variant }) => variantStyles[variant].bgColorFocus};
    }

    &:hover:enabled {
      background-color: ${({ variant }) => variantStyles[variant].bgColorFocus};
    }

    ${media.min.tablet`
      padding: 12px ${spacing.spacious};
    `}

    ${media.min.baseline`
      font-size: 1.7rem;
    `}

    ${media.min.desktop`
      font-size: 1.9rem;
      padding: 14px ${spacing.spacious};
    `}
  `}
`;

export const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
