import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync
export const RESET_IAT_STORE = 'iat/resetIATStore';
export const resetIATStore = createAction(RESET_IAT_STORE);

// Async
export const FETCH_IAT = createAsyncTypes('iat/fetchIAT');
export const fetchIAT = createAsyncAction(FETCH_IAT);

export const SEND_IAT_BLOCK_RESULTS = createAsyncTypes('iat/sendIATBlockResults');
export const sendIATBlockResults = createAsyncAction(SEND_IAT_BLOCK_RESULTS);

export const SKIP_IAT = createAsyncTypes('iat/skipIAT');
export const skipIAT = createAsyncAction(SKIP_IAT);

// Temporary IAT old
export const FETCH_IAT_RESULT = createAsyncTypes('iat/fetchIATResult');
export const fetchIATResult = createAsyncAction(FETCH_IAT_RESULT);
