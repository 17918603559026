import styled from 'styled-components';
import {
  colors,
  media,
  spacing,
  type,
} from 'styles';
import typeScale from 'styles/functions/typeScale';

export const TableOfContentsWrapper = styled.div`
  padding-top: ${spacing.normal};
  color: ${colors.sierra.base};
  height: 100%;
  -webkit-overflow-scrolling: touch; /* Needed for momentum-based scrolling behavior on tablet/mobile */
`;

export const CourseTitle = styled.div`
  ${typeScale('hecto')}
  font-weight: ${type.weight.bold};
  margin-bottom: ${spacing.normal};
  padding: 0 24px;

  ${media.min.tablet`padding: 0 28px;`}
`;

export const SectionList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
