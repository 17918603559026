import { useEffect } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation, Trans } from 'react-i18next';
import Config from 'config'; // eslint-disable-line

import { heightSizes, widthSizes } from 'styles/media';

import {
  ExternalLink,
  MessageHeader,
  NotSupportedMessage,
  WindowSizeObserverWrapper,
} from './style';

const MEDIA_QUERY_STRING = `(min-width: ${widthSizes.tablet / 16}em) and (min-height: ${heightSizes.tablet / 16}em)`;

const WindowSizeObserver = ({
  children,
  tracking: { trackEvent },
}) => {
  const { t } = useTranslation('courseViewer');

  const trackMessageShown = () => {
    trackEvent({
      event: 'Window Size Message Shown',
      screenHeight: window.screen && window.screen.height,
      screenWidth: window.screen && window.screen.width,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  const handleMediaChange = event => {
    if (!event.matches) trackMessageShown();
    else trackEvent({ event: 'Window Size Message Hidden' });
  };

  useEffect(() => {
    const mediaQueryList = matchMedia(MEDIA_QUERY_STRING);

    if (!mediaQueryList.matches) trackMessageShown();

    mediaQueryList.addListener(handleMediaChange);

    return () => mediaQueryList.removeListener(handleMediaChange);
  }, []);

  const handleTechIssueLinkClick = () => trackEvent({ event: 'Tech Issue Link Clicked' });

  return (
    <WindowSizeObserverWrapper>
      {children}
      <NotSupportedMessage>
        <div>
          <MessageHeader>
            {t('courseViewer:windowSizeObserver.title')}
          </MessageHeader>
          <Trans i18nKey="courseViewer:windowSizeObserver.body">
            Please try the following:
            <ul>
              <li>Expand your browser&apos;s window size</li>
              <li>Zoom out using the keyboard shortcut Ctrl (⌘ for Mac) and the – key</li>
            </ul>
            Otherwise, you may
            <ExternalLink href={Config.techIssueUrl} target="_blank" onClick={handleTechIssueLinkClick}>
              report the issue
            </ExternalLink>
            .
          </Trans>
        </div>
      </NotSupportedMessage>
    </WindowSizeObserverWrapper>
  );
};

WindowSizeObserver.propTypes = {
  children: PropTypes.node.isRequired,
  tracking: TrackingPropType,
};

WindowSizeObserver.defaultProps = {
  tracking: null,
};

export default track({
  module: 'WindowSizeObserver',
})(WindowSizeObserver);
