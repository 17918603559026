import styled from 'styled-components';
import { colors, type, media } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  background-color: ${colors.white};

  ${media.min.desktop`padding: 16px 0;`}
  ${media.min.max`padding: 18px 0;`}
`;

export const ProgressBar = styled.progress`
  width: 224px;
  overflow: hidden;
  appearance: none;
  border-radius: 20px;
  border: 0;
  color: ${colors.blue.base};
  background-color: #c8deef;
  transition: width 1s;
  height: 12px;

  &::-webkit-progress-bar {
    background-color: #c8deef;
    transition: width 1s;
    border-radius: 20px;
  }

  &::-webkit-progress-value {
    transition: width 1s;
    background-color: ${colors.blue.base};
    border-radius: 20px;
  }

  &::-ms-fill {
    background-color: ${colors.blue.base};
    border: none;
  }

  &::-moz-progress-bar {
    transition: padding-bottom 1s;
    padding-left: 60px;
    padding-bottom: ${(p) => (p.value / p.max) * 100}%;
    background-color: ${colors.blue.base};
    height: 0;
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-60px);
  }

  ${media.min.tablet`
    width: 324px;
    height: 16px;
  `}
`;

export const ProgressBarLabel = styled.label`
  display: block;
  min-width: 100px;
  text-align: right;
  margin: 0 10px;
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.shasta.light};

  ${media.min.tablet`font-size: 1.4rem;`}
`;

export const PercentageLabel = styled.span`
  display: block;
  min-width: 100px;
  text-align: left;
  margin: 0 10px;
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.shasta.light};

  ${media.min.tablet`font-size: 1.4rem;`}
`;
