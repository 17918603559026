import styled from 'styled-components';
import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const Button = styled.button`
  display: block;
  width: 260px;
  font-size: 1.4rem;
  padding: 10px;
  background-color: ${colors.ridge.dark};
  text-align: left;
  border: 0;
  border-radius: 10px;
  outline: 0;
  opacity: 0;
  pointer-events: none;

  ${media.min.desktop`
    width: 300px;
    font-size: 1.6rem;
    padding: 14px;
  `}
  
  &:active,
  &:focus {
    opacity: 1;
    pointer-events: auto;
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
  }
`;

export const CloseButton = styled(TransparentButton)`
  position: absolute;
  top: 10px;
  right: 13px;
  width: 18px;
  height: 25px;
  font-size: 3.2rem;
  line-height: .8;
  color: ${colors.ridge.light};
  outline: none;
  opacity: 0;

  ${Button}:active ~ &,
  ${Button}:focus ~ & {
    opacity: 1;
  }
`;

export const Message = styled.p`
  margin-top: 0;
`;

export const CTAText = styled.span`
  font-weight: ${type.weight.semiBold};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
