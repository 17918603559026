import React from 'react';

import * as ChapterType from 'constants/chapterTypes';
import * as ChapterStatus from 'constants/chapterStatuses';

import checkmarkIcon from 'assets/green-check.svg';
import FileDownloadIcon from 'components/svg/FileDownloadIcon';
import ForwardIcon from 'components/svg/ForwardIcon';
import IATIcon from 'components/svg/IATIcon';
import InformationCardIcon from 'components/svg/InformationCardIcon';
import ResultsIcon from 'components/svg/ResultsIcon';
import SurveyIcon from 'components/svg/SurveyIcon';
import PlayIcon from 'components/svg/PlayIcon';
import VideoIcon from 'components/svg/VideoIcon';

const chapterTypeIconMap = {
  [ChapterType.AnonymizeResults]: IATIcon,
  [ChapterType.CompositeSurvey]: SurveyIcon,
  [ChapterType.CCAResults]: ResultsIcon,
  [ChapterType.FileDownload]: FileDownloadIcon,
  [ChapterType.Forward]: ForwardIcon,
  [ChapterType.IAT]: IATIcon,
  [ChapterType.IATTutorial]: IATIcon,
  [ChapterType.InformationCard]: InformationCardIcon,
  [ChapterType.LikertTest]: SurveyIcon,
  [ChapterType.Survey]: SurveyIcon,
  [ChapterType.Video]: VideoIcon,
};

export const getChapterItemIcon = (status, type, isCompleted, t) => {
  if (status === ChapterStatus.Current) return <PlayIcon />;
  if (isCompleted) {
    return <img src={checkmarkIcon} alt={t('courseOverview:status.completedChapter')} />;
  }

  const IconSVG = chapterTypeIconMap[type];
  return <IconSVG />;
};

const getChapterTypeLabel = (type, t) => {
  switch (type) {
    case ChapterType.IAT:
      return t('tableOfContents.chapterTypeLabel.iat');
    case ChapterType.IATTutorial:
      return t('tableOfContents.chapterTypeLabel.tutorial');
    case ChapterType.LikertTest:
      return t('tableOfContents.chapterTypeLabel.likertTest');
    default:
      return null;
  }
};

const getDurationText = duration => {
  if (duration < 0) return null;

  const minutes = duration <= 60 ? 1 : Math.round(duration / 60);

  return `${minutes} min`;
};

export const getInfoText = (type, duration, t) => {
  let infoText = getDurationText(duration);

  const chapterTypeLabel = getChapterTypeLabel(type, t);
  if (chapterTypeLabel) {
    infoText = infoText
      ? `${infoText} • ${chapterTypeLabel}`
      : chapterTypeLabel;
  }

  return infoText;
};
