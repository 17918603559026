import PropTypes from 'prop-types';

import * as fieldNames from 'constants/accountDetailFields';
import validator from 'lib/validation/validator';
import colors from 'styles/colors';

import TextField from 'components/shared/TextField';
import SimpleButton from 'components/shared/SimpleButton';
import CourseLayout from 'components/shared/CourseLayout';

import {
  AccountDetailsWrapper,
  BasicInfoButtonWrapper,
  BasicInfoSectionHeader,
  CancelButton,
  GridWrapper,
  SectionDescription,
  SectionItem,
  SectionTitle,
  SectionWrapper,
  TextFieldWrapper,
  Title,
} from './style';

const textFieldTheme = {
  container: {
    background: colors.ridge.base,
    border: {
      default: 'transparent',
    },
  },
  input: {
    color: {
      default: colors.yellow.background,
    },
  },
  label: {
    color: {
      default: colors.cascade.muted,
    },
  },
};

const AccountDetails = ({
  fields,
  isDownloading,
  isEditEnabled,
  isUpdating,
  onCancelClick,
  onChange,
  onDeleteClick,
  onDownloadClick,
  onEditClick,
  onSaveClick,
  t,
}) => {
  const isUserPendingChanges = isDownloading || isUpdating;
  const validateTextField = validator('requiredField');
  const validateEmailField = validator('email');

  const basicInfoButtons = isEditEnabled ? (
    <>
      <SimpleButton
        disabled={isUserPendingChanges}
        onClick={onSaveClick}
        loading={isUpdating}
      >
        {t('accountDetails:basicInformation.saveButton')}
      </SimpleButton>
      <CancelButton
        disabled={isUpdating}
        onClick={onCancelClick}
      >
        {t('accountDetails:basicInformation.cancelButton')}
      </CancelButton>
    </>
  ) : (
    <SimpleButton
      disabled={isUserPendingChanges}
      onClick={onEditClick}
      loading={isUpdating}
    >
      {t('accountDetails:basicInformation.editButton')}
    </SimpleButton>
  );

  const basicInfoSection = (
    <>
      <BasicInfoSectionHeader>
        <SectionTitle>{t('accountDetails:basicInformation.title')}</SectionTitle>
        <BasicInfoButtonWrapper>{basicInfoButtons}</BasicInfoButtonWrapper>
      </BasicInfoSectionHeader>
      <GridWrapper>
        <TextFieldWrapper>
          <TextField
            disabled={!isEditEnabled}
            hasExternalError={!!validateTextField(fields.firstName)}
            labelText={t('accountDetails:basicInformation.firstNameLabel')}
            name={fieldNames.FIRST_NAME}
            onChange={onChange}
            theme={textFieldTheme}
            value={fields.firstName || ''}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            disabled={!isEditEnabled}
            hasExternalError={!!validateTextField(fields.lastName)}
            labelText={t('accountDetails:basicInformation.lastNameLabel')}
            name={fieldNames.LAST_NAME}
            onChange={onChange}
            theme={textFieldTheme}
            value={fields.lastName || ''}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextField
            disabled={!isEditEnabled}
            hasExternalError={!!validateEmailField(fields.email)}
            labelText={t('accountDetails:basicInformation.emailLabel')}
            name={fieldNames.EMAIL}
            onChange={onChange}
            theme={textFieldTheme}
            value={fields.email || ''}
          />
        </TextFieldWrapper>
      </GridWrapper>
    </>
  );

  const privacyAndDataSection = (
    <SectionWrapper>
      <SectionTitle>{t('accountDetails:privacyAndData.title')}</SectionTitle>
      <SectionItem>
        <SectionDescription>
          {t('accountDetails:privacyAndData.description')}
        </SectionDescription>
        <SimpleButton
          disabled={isUserPendingChanges}
          loading={isDownloading}
          onClick={onDownloadClick}
        >
          {t('accountDetails:privacyAndData.downloadButton')}
        </SimpleButton>
      </SectionItem>
    </SectionWrapper>
  );

  const accountChangesSection = (
    <SectionWrapper>
      <SectionTitle>{t('accountDetails:accountChanges.title')}</SectionTitle>
      <SectionItem>
        <SectionDescription>
          {t('accountDetails:accountChanges.description')}
        </SectionDescription>
        <SimpleButton
          disabled={isUserPendingChanges}
          onClick={onDeleteClick}
          variant="red"
        >
          {t('accountDetails:accountChanges.deleteButton')}
        </SimpleButton>
      </SectionItem>
    </SectionWrapper>
  );

  return (
    <CourseLayout>
      <AccountDetailsWrapper>
        <Title>{t('accountDetails:title')}</Title>
        {basicInfoSection}
        {privacyAndDataSection}
        {accountChangesSection}
      </AccountDetailsWrapper>
    </CourseLayout>
  );
};

AccountDetails.propTypes = {
  fields: PropTypes.objectOf(PropTypes.string).isRequired,
  isDownloading: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AccountDetails;
