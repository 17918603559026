import { useTranslation } from 'react-i18next';

import { MultipleChoiceOpinionTaskEvent } from 'types/VideoTaskEvent';

import { useTaskCompletionTimeout } from '../hooks';
import SuccessMessage from '../SuccessMessage';
import { SuccessMessageWrapper } from '../style';
import MultipleChoiceTaskForm from './MultipleChoiceTaskForm';

type MultipleChoiceOpinionTaskProps = {
  isSubmitting: boolean;
  onContinue: () => void;
  onSubmit: (optionKey: string) => void;
  taskEvent: MultipleChoiceOpinionTaskEvent;
};

const MultipleChoiceOpinionTask = ({
  isSubmitting,
  onContinue,
  onSubmit,
  taskEvent,
}: MultipleChoiceOpinionTaskProps) => {
  const { t } = useTranslation('courseViewer');

  useTaskCompletionTimeout(!!taskEvent.isSubmitted, onContinue);

  return !taskEvent.isSubmitted ? (
    <MultipleChoiceTaskForm
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      options={taskEvent.options}
      prompt={taskEvent.prompt}
    />
  ) : (
    <SuccessMessageWrapper>
      <SuccessMessage>
        {t('courseViewer:video.task.successMessage')}
      </SuccessMessage>
    </SuccessMessageWrapper>
  );
};

export default MultipleChoiceOpinionTask;
