import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import awaitAsyncAction from 'lib/awaitAsyncAction';
import VideoPlayer from 'lib/VideoPlayer';

import { currentCourseIdSelector } from 'store/courses/selectors';
import { isIOSSelector } from 'store/userAgent/selectors';
import { createVideoEventEntities } from 'store/entities/videoEvents/actions';
import { parseVideoMetadataResponse } from 'store/entities/videoEvents/logic';
import {
  cacheVideoMetadata,
  fetchDataForVideoOverlays,
  FETCH_VIDEO_CREDENTIALS,
  fetchVideoCredentials,
  SET_VIDEO_SOURCE,
  setVideoBookmark,
  setVideoSource,
} from '../actions';
import {
  currentVideoMetadataSelector,
  videoBookmarkSelector,
} from '../selectors';

const debug = Debug('biassync:lms:store:video:sagas:setVideoSource');

const END_OF_VIDEO_MARGIN_SECONDS = 2;

function* fetchVideoMetadata(chapterId) {
  const videoMetadataResponse = yield call(API.getVideoMetadata, chapterId);

  debug('Video metadata received', videoMetadataResponse);

  const currentCourseId = yield select(currentCourseIdSelector);
  yield put(createVideoEventEntities({
    courseId: currentCourseId,
    data: videoMetadataResponse,
  }));
  return parseVideoMetadataResponse(videoMetadataResponse);
}

function* setVideoPlayerCurrentTime() {
  const videoBookmark = yield select(videoBookmarkSelector);
  const videoDuration = yield call([VideoPlayer, 'getDuration']);
  let currentTime = Math.floor(videoBookmark);

  if (currentTime >= videoDuration - END_OF_VIDEO_MARGIN_SECONDS) {
    debug('Bookmark is at end of video. Setting video bookmark back to 0');
    currentTime = 0;
    yield put(setVideoBookmark(currentTime));
  }

  yield call([VideoPlayer, 'setCurrentTime'], currentTime);
}

export function* setVideoSourceSaga(action) {
  debug('Saga called');
  const { payload: chapterId } = action;

  let videoMetadata = yield select(currentVideoMetadataSelector);

  try {
    if (!videoMetadata) {
      debug('Video metadata not found in cache');
      videoMetadata = yield* fetchVideoMetadata(chapterId);
      yield put(cacheVideoMetadata({ [chapterId]: videoMetadata }));
    } else {
      yield put(fetchVideoCredentials({ shouldReloadPlaylist: false }));
      yield call(awaitAsyncAction, FETCH_VIDEO_CREDENTIALS);
    }

    const isIOS = yield select(isIOSSelector);

    yield call(
      [VideoPlayer, 'setupVideo'],
      videoMetadata.manifest,
      videoMetadata.closedCaptions.urls,
      isIOS,
    );

    yield* setVideoPlayerCurrentTime();

    if (videoMetadata.persistentEvents.length) {
      yield put(fetchDataForVideoOverlays());
    }

    yield put(setVideoSource.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(setVideoSource.error(e, action));
  }
}

export default function* setVideoSourceDefaultSaga() {
  yield takeLatest(SET_VIDEO_SOURCE.PENDING, setVideoSourceSaga);
}
