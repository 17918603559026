import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Config from 'config'; // eslint-disable-line

import MenuPopup from 'styles/components/MenuPopup';
import ListDivider from 'styles/components/ListDivider';
import FocusTrap from 'components/shared/FocusTrap';
import chevronIcon from 'assets/chevron-right.svg';

import VideoResolutionList from './VideoResolutionList';
import {
  ChevronIcon,
  MenuHeader,
  MenuItem,
  MenuItemAnchor,
  SectionTitle,
} from './style';

const SettingsMenu = ({
  onTechIssueLinkClick,
  onVideoResolutionChange,
  selectedVideoResolution,
  shouldDisplayVideoResolutionPicker,
  videoResolutions,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  return (
    <FocusTrap>
      <MenuPopup
        arrowHorizontalLocation="right"
        arrowVerticalLocation="top"
        showArrow
      >
        <MenuHeader>{t('courseViewer:settingsMenu.title')}</MenuHeader>
        {shouldDisplayVideoResolutionPicker && (
          <>
            <ListDivider />
            <SectionTitle>{t('courseViewer:settingsMenu.videoQuality')}</SectionTitle>
            <ListDivider />
            <VideoResolutionList
              onItemClick={onVideoResolutionChange}
              resolutions={videoResolutions}
              selectedResolution={selectedVideoResolution}
              t={t}
            />
          </>
        )}
        <ListDivider />
        <MenuItemAnchor href={Config.techIssueUrl} target="_blank" onClick={onTechIssueLinkClick}>
          <MenuItem>
            {t('techIssueLinkLabel')}
            <ChevronIcon alt="" src={chevronIcon} />
          </MenuItem>
        </MenuItemAnchor>
      </MenuPopup>
    </FocusTrap>
  );
};

SettingsMenu.propTypes = {
  onTechIssueLinkClick: PropTypes.func.isRequired,
  onVideoResolutionChange: PropTypes.func.isRequired,
  selectedVideoResolution: PropTypes.string.isRequired,
  shouldDisplayVideoResolutionPicker: PropTypes.bool.isRequired,
  videoResolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SettingsMenu;
