import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import validator from 'lib/validation/validator';

import AuthenticationLayout from 'components/shared/AuthenticationLayout';
import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import StyledRouterLink from 'styles/components/StyledRouterLink';
import PageTitle from 'components/shared/PageTitle';
import RegistrationConflictRedirect from 'components/shared/RegistrationConflictRedirect';

import {
  CTAWrapper,
  Form,
  HelpText,
  LegalText,
  TextFieldWrapper,
  Title,
} from './style';

const Register = ({
  doPasswordsMatch,
  isLoading,
  isUserRegistrationConflictError,
  onChange,
  onLoginRedirectClick,
  onPasswordFieldBlur,
  onSubmit,
  values,
}) => {
  const { t } = useTranslation(['common', 'auth']);

  let content;
  if (isUserRegistrationConflictError) {
    content = (
      <RegistrationConflictRedirect
        message={t('auth:register.redirectToLoginMessage')}
        redirectPath="/login"
      />
    );
  } else {
    content = (
      <>
        <Title>{t('auth:register.title')}</Title>
        <Form onSubmit={onSubmit}>
          <TextFieldWrapper>
            <TextField
              labelText={t('auth:loginIdLabel')}
              name="loginId"
              onChange={onChange}
              tid="loginFieldId"
              tooltipText={t('auth:register.loginIdTooltip')}
              validate={validator('requiredField')}
              value={values.loginId}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              labelText={t('auth:newPasswordLabel')}
              name="password"
              onBlur={onPasswordFieldBlur}
              onChange={onChange}
              tid="passwordFieldId"
              tooltipText={t('auth:passwordTooltip')}
              type="password"
              validate={validator('password')}
              value={values.password}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              hasExternalError={!doPasswordsMatch}
              labelText={t('auth:confirmPasswordLabel')}
              name="confirmPassword"
              onBlur={onPasswordFieldBlur}
              onChange={onChange}
              tid="confirmPasswordFieldId"
              type="password"
              validate={validator('confirmPassword')}
              value={values.confirmPassword}
            />
          </TextFieldWrapper>
          <LegalText>
            <Trans i18nKey="auth:legalText">
              By continuing, you agree to BiasSync&apos;s <StyledRouterLink data-testid="privacyPolicyLinkId" target="_blank" to="/privacy">Privacy Policy</StyledRouterLink>
            </Trans>
          </LegalText>
          <CTAWrapper>
            <StyledButton tid="submitButtonId" full onClick={onSubmit} loading={isLoading}>
              {t('continue')}
            </StyledButton>
          </CTAWrapper>
          <HelpText>
            <Trans i18nKey="auth:register.helpText">
              Already have an account? <StyledRouterLink data-testid="loginLinkId" to="/login" onClick={onLoginRedirectClick}>Click here to login.</StyledRouterLink>
            </Trans>
          </HelpText>
        </Form>
      </>
    );
  }

  return (
    <>
      <PageTitle subPageTitle={t('auth:register.pageTitle')} />
      <AuthenticationLayout>
        {content}
      </AuthenticationLayout>
    </>
  );
};

Register.propTypes = {
  doPasswordsMatch: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onLoginRedirectClick: PropTypes.func.isRequired,
  onPasswordFieldBlur: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Register;
