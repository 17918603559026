import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { surveyPromptType } from 'constants/propTypes';

import { getAlphabetArrayWithLength } from 'lib/utils';

import CheckboxesOption from './CheckboxesOption';
import OtherOption from '../OtherOption';

import { Wrapper } from './style';

const CheckboxesPrompt = ({
  areKeyShortcutsEnabled,
  isActive,
  isDisabled,
  onChange,
  prompt: {
    id,
    promptOptions,
    promptType,
  },
  response,
}) => {
  const { current: keyShortcuts } = useRef(getAlphabetArrayWithLength(promptOptions.length));

  const handleChange = (optionId, isSelected) => {
    let nextResponse = response || [];

    if (isSelected) nextResponse = ([...nextResponse, optionId]);
    else nextResponse = nextResponse.filter(option => option !== optionId);

    onChange(id, nextResponse, true);
  };

  const handleOtherChoiceChange = (optionId, otherChoice) => {
    if (otherChoice.response) {
      onChange(optionId, otherChoice, true);
      handleChange(optionId, true);
    } else handleChange(optionId, false);
  };

  const handleKeyUp = event => {
    if (isDisabled || !areKeyShortcutsEnabled) return;

    const keyShortcutIndex = keyShortcuts.indexOf(event.key);
    if (keyShortcutIndex !== -1) handleChange(promptOptions[keyShortcutIndex].id);
  };

  useEffect(() => {
    if (isActive) window.addEventListener('keyup', handleKeyUp);
    return () => {
      if (isActive) window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isActive]);

  return (
    <Wrapper>
      {promptOptions.map((option, idx) => {
        const isSelected = !!response && response.includes(option.id);

        return (
          <React.Fragment key={option.id}>
            {option.isOther ? (
              <OtherOption
                isDisabled={isDisabled}
                isSelected={isSelected}
                keyShortcut={keyShortcuts[idx]}
                onChange={(otherChoice) => handleOtherChoiceChange(option.id, otherChoice)}
                option={option}
                promptId={id}
                promptType={promptType}
              />
            ) : (
              <CheckboxesOption
                id={option.id}
                isDisabled={isDisabled}
                isSelected={isSelected}
                keyShortcut={keyShortcuts[idx]}
                name={id}
                onChange={() => handleChange(option.id, !isSelected)}
              >
                {option.optionText}
              </CheckboxesOption>
            )}
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};

CheckboxesPrompt.propTypes = {
  areKeyShortcutsEnabled: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

CheckboxesPrompt.defaultProps = {
  areKeyShortcutsEnabled: false,
  response: null,
};

export default CheckboxesPrompt;
