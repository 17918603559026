import { css } from 'styled-components';

import playButtonSVG from 'assets/play-button.svg';
import playIcon from 'assets/vjs-play.svg';
import pauseIcon from 'assets/vjs-pause.svg';
import closedCaptionsIcon from 'assets/vjs-closed-captions.svg';
import fullScreenEnterIcon from 'assets/vjs-full-screen-enter.svg';
import fullScreenExitIcon from 'assets/vjs-full-screen-exit.svg';
import volumeMuteIcon from 'assets/vjs-volume-mute.svg';
import volumeLowIcon from 'assets/vjs-volume-low.svg';
import volumeMidIcon from 'assets/vjs-volume-mid.svg';
import volumeFullIcon from 'assets/vjs-volume-full.svg';

import media from '../media';
import colors from '../colors';

export default css`
  .video-js.vjs-fill {
    /* For mobile browser to hide address bar on scroll, video player must be larger than screen size */
    height: 100.5vh !important;

    /* Hides any whitespace that appears around video player (e.g. when playhead reaches end of video) */
    overflow: hidden;
  }

  .video-js .vjs-control-bar {
    font-size: 1.6rem;
    font-weight: 600;
    height: 5.4em;
    z-index: 3;
    padding-top: 2.1em;
    background: linear-gradient(
      to top,
      rgba(20,23,28,0.9) 0,
      rgba(20,23,28,0.738) 19%,
      rgba(20,23,28,0.541) 34%,
      rgba(20,23,28,0.382) 47%,
      rgba(20,23,28,0.278) 56.5%,
      rgba(20,23,28,0.194) 65%,
      rgba(20,23,28,0.126) 73%,
      rgba(20,23,28,0.075) 80.2%,
      rgba(20,23,28,0.042) 86.1%,
      rgba(20,23,28,0.021) 91%,
      rgba(20,23,28,0.008) 95.2%,
      rgba(20,23,28,0.002) 98.2%,
      rgba(20,23,28,0) 100%
    );

    ${media.twoDimMin.baseline`height: 6.2em;`}
  }

  .video-js .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    top: 1em;
    height: 2.5rem;
    background: transparent;
    z-index: 3;
  }

  .video-js .vjs-menu .vjs-menu-content {
    z-index: 3;
    bottom: 2.5em;
  }

  .video-js .vjs-subs-caps-button .vjs-menu .vjs-menu-content {
    transform: scale(.75);
    bottom: 0.5em;

    ${media.twoDimMin.baseline`
      transform: none;
      bottom: 1.5em;
    `}
  }

  // Video progress/slider bar extends full width of container
  .video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0;
  }

  .video-js .vjs-progress-holder {
    height: 0.6rem;
    background: rgba(247,248,250,0.5);

    ${media.twoDimMin.baseline`height: 0.8rem;`}
  }

  .video-js .vjs-play-progress:before {
    font-size: 2rem;
    color: ${colors.green.base};

    ${media.twoDimMin.baseline`font-size: 2.4rem;`}
  }

  .video-js .vjs-play-progress,
  .video-js .vjs-volume-level {
    background: ${colors.green.base};
  }

  .video-js .vjs-load-progress div {
    background: ${colors.white};
  }

  .video-js .vjs-custom-control-spacer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .vjs-button { outline: none; }
  .vjs-button.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    border-radius: 8px;
  }

  // Custom big play button
  .video-js .vjs-big-play-button {
    top: calc(50% - 62.5px);
    left: calc(50% - 62.5px);
    background-image: url(${playButtonSVG});
    background-repeat: no-repeat;
    width: 125px;
    height: 125px;
    border: 0;
    background-color: transparent;
    display: none;
    outline: none;

    ${media.twoDimMin.baseline`
      top: calc(50% - 75px);
      left: calc(50% - 75px);
      width: 150px;
      height: 150px;
    `}

    ${media.twoDimMin.desktop`
      top: calc(50% - 100px);
      left: calc(50% - 100px);
      width: 200px;
      height: 200px;
    `}]
  }

  .video-js .vjs-big-play-button.focus-visible {
    box-shadow: ${colors.blue.light} 0px 0px 0px 3px;
    border-radius: 9999px;
  }

  .video-js:hover .vjs-big-play-button {
    background-color: transparent;
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
    display: none;
  }

  .video-js.vjs-has-started.vjs-paused:not(.vjs-ended) .vjs-big-play-button {
    display: block;
  }

  .video-js.vjs-ended video, .video-js-blur video {
    filter: blur(11px);
  }

  .video-js .vjs-tech {
    background: ${colors.sierra.base};
  }

  .video-js .vjs-time-control {
    display: block;
    position: relative;
    bottom: -0.6em;
    padding: 0 0.25rem;
    font-family: PT Mono;
    color: ${colors.rocky.base};
  }

  .video-js .vjs-playback-rate {
    bottom: -0.6em;
    font-size: 1.2rem;

    ${media.twoDimMin.baseline`
      bottom: -0.5em;
      font-size: 1.6rem;
    `}
  }

  .video-js .vjs-remaining-time { display: none; }
  .video-js .vjs-time-divider { min-width: 0; }

  .video-js .vjs-custom-control {
    display: flex;
    align-items: center;
  }

  // CUSTOMIZING CONTROL BAR ICONS //

  .video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder:before {
    background-image: url(${playIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    background-image: url(${pauseIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js:lang(en) .vjs-subs-caps-button .vjs-icon-placeholder:before {
    background-image: url(${closedCaptionsIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-fullscreen-control:not(.vjs-fullscreen) .vjs-icon-placeholder:before {
    background-image: url(${fullScreenEnterIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "" !important;
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
    background-image: url(${fullScreenExitIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "" !important;
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-vol-0 .vjs-icon-placeholder:before,
  .vjs-icon-volume-mute:before {
    background-image: url(${volumeMuteIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "" !important;
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-vol-1 .vjs-icon-placeholder:before,
  .vjs-icon-volume-low:before {
    background-image: url(${volumeLowIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "" !important;
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-vol-2 .vjs-icon-placeholder:before,
  .vjs-icon-volume-mid:before {
    background-image: url(${volumeMidIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "" !important;
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-vol-3 .vjs-icon-placeholder:before {
    background-image: url(${volumeFullIcon});
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-js .vjs-time-control {
    transform: scale(.9);
    bottom: -0.2em;

    ${media.twoDimMin.baseline`
      transform: none;
      bottom: -0.6em;
    `}
  }

  .video-js .vjs-audio-button {
    transform: scale(.9);
    bottom: -0.2em;

    ${media.twoDimMin.baseline`
      transform: none;
      bottom: -0.6em;
    `}
  }

  .vjs-audio-button .vjs-menu {
    width: 20em;
    bottom: 0;
    left: -10em;

    ${media.twoDimMin.baseline`
      bottom: 1em;
      left: -14em;
    `}
  }

  .vjs-audio-button .vjs-menu .vjs-menu-content {
    bottom: 2.5em;

    ${media.twoDimMin.baseline`
      bottom: 1.2em;
    `}
  }

  .vjs-audio-button .vjs-menu .vjs-menu-content
  .vjs-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
    display: none;
  }

  .video-js .vjs-rewind-control {
    transform: scale(.75);

    ${media.twoDimMin.baseline`transform: none;`}
  }

  .video-player-portal {
    font-family: Poppins;
    line-height: 1.4;
  }

  .video-player-disable {
    pointer-events: none;
  }
`;
