import styled from 'styled-components';
import { media, type } from 'styles';

export const AddCommentButtonWrapper = styled.div`
  margin-top: 60px;
`;

export const YesNoPromptWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  width: 70%;
  margin: 0 auto;
  max-width: 375px;

  ${media.min.baseline`font-size: 2rem;`}
`;

export const RatingItemWrapper = styled.div`
  display: inline-block;
  width: 160px;
  margin-right: 18px;
  padding: 22px 0;
  font-weight: ${type.weight.semiBold};

  &:last-child { margin-right: 0; }

  ${media.min.baseline`
    width: 180px;
    margin-right: 20px;
    padding: 24px 0;
  `}
`;

export const RatingWrapper = styled.div`
  padding: 20px;
`;
