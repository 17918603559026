import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { surveyPromptType } from 'constants/propTypes';
import SimpleButton from 'components/shared/SimpleButton';

import ChevronRightIcon from 'components/svg/ChevronRightIcon';
import colors from 'styles/colors';

import {
  ButtonContent,
  ChevronIconWrapper,
  Content,
  Divider,
  PanelButtonWrapper,
  PanelWrapper,
  Title,
} from './style';

const StoryPanel = ({ content, onToggle }) => {
  const { t } = useTranslation('courseViewer');

  return (
    <PanelWrapper>
      <PanelButtonWrapper>
        <SimpleButton
          data-testid="hide-story-button"
          focusOutlineColor={colors.yellow.base}
          name="hide-story"
          onClick={onToggle}
          size="sm"
          variant="outline"
        >
          <ButtonContent>
            <ChevronIconWrapper>
              <ChevronRightIcon fill={colors.white} />
              <ChevronRightIcon fill={colors.white} />
            </ChevronIconWrapper>
            {t('courseViewer:survey.hideStory')}
          </ButtonContent>
        </SimpleButton>
      </PanelButtonWrapper>
      <Title>
        {content.promptText}
      </Title>
      <Divider />
      {content.subPrompts.map((subPrompt) => (
        <Content key={subPrompt.id}>
          {subPrompt.promptText}
        </Content>
      ))}
    </PanelWrapper>
  );
};

StoryPanel.propTypes = {
  content: surveyPromptType.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default StoryPanel;
