/* eslint-disable react/no-array-index-key */

import { useTranslation } from 'react-i18next';

import {
  Description,
  SectionContainer,
  SectionTitle,
  SubSectionTitle,
} from '../style';

import CCAChallengeIcon from './CCAChallengeIcon';
import CCAGrowthIcon from './CCAGrowthIcon';
import {
  BulletList,
  BulletPoint,
  ContentContainer,
  IconContainer,
  SubSection,
  SplitContainer,
} from './style';

type DescriptionProps = {
  challenge: string[];
  description: string;
  growth: string[];
};

export const DescriptionWrapper = ({
  challenge,
  description,
  growth,
}: DescriptionProps) => {
  const { t } = useTranslation('courseViewer');

  return (
    <SectionContainer>
      <SectionTitle>{t('courseViewer:ccaResults.profile.sections.aboutYouSection.heading')}</SectionTitle>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <SplitContainer>
        <SubSection>
          <IconContainer>
            <CCAGrowthIcon />
          </IconContainer>
          <ContentContainer>
            <SubSectionTitle>{t('courseViewer:ccaResults.profile.sections.opportunitySection.heading')}</SubSectionTitle>
            <BulletList>
              {growth.map((growthItem, index) => (
                <BulletPoint key={`growth-${index}`} dangerouslySetInnerHTML={{ __html: growthItem }} />
              ))}
            </BulletList>
          </ContentContainer>
        </SubSection>
        <SubSection>
          <IconContainer>
            <CCAChallengeIcon />
          </IconContainer>
          <ContentContainer>
            <SubSectionTitle>{t('courseViewer:ccaResults.profile.sections.challengeSection.heading')}</SubSectionTitle>
            <BulletList>
              {challenge.map((challengeItem, index) => (
                <BulletPoint key={`challengeItem-${index}`} dangerouslySetInnerHTML={{ __html: challengeItem }} />
              ))}
            </BulletList>
          </ContentContainer>
        </SubSection>
      </SplitContainer>
    </SectionContainer>
  );
};

export default DescriptionWrapper;
