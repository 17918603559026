import Debug from 'debug';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { setCourseCompleted } from 'store/entities/courses/actions';
import {
  currentCourseIdSelector,
  isCourseCompletedSelector,
} from 'store/courses/selectors';

import {
  fetchCourses,
  HANDLE_COURSE_END,
  handleCourseEnd,
} from '../actions';

const debug = Debug('biassync:lms:store:courses:sagas:handleCourseEnd');

export function* handleCourseEndSaga() {
  debug('Saga called');

  const courseId = yield select(currentCourseIdSelector);
  const wasCoursePreviouslyCompleted = yield select(isCourseCompletedSelector);

  yield put(setCourseCompleted({ courseId }));

  // Fetch courses to get fresh course statuses
  if (!wasCoursePreviouslyCompleted) yield put(fetchCourses());

  yield put(handleCourseEnd.success());
}

export default function* handleCourseEndDefaultSaga() {
  yield takeLatest(HANDLE_COURSE_END.PENDING, handleCourseEndSaga);
}
