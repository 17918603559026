import styled, { css } from 'styled-components';

import typeScale from 'styles/functions/typeScale';
import TransparentButton from 'styles/components/TransparentButton';
import { colors, type, media } from 'styles';

export const MenuHeader = styled.div`
  ${typeScale('hecto')}
  color: ${colors.sierra.base};
  font-weight: ${type.weight.bold};
  padding: 16px 20px;

  ${media.min.baseline`${typeScale('giga')}`}
`;

export const SectionTitle = styled.div`
  ${typeScale('milli')}
  color: ${colors.blue.base};
  font-weight: ${type.weight.extraBold};
  margin: 10px 20px;
  text-transform: uppercase;

  ${media.min.baseline`
    ${typeScale('centi')}
    margin: 12px 20px;
  `}
`;

export const ChevronIcon = styled.img`
  width: 9px;
  height: 15px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  color: ${colors.sierra.base};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  cursor: pointer;

  ${media.min.tablet`
    font-size: 1.6rem;
    padding: 16px 20px;
  `}
`;

const menuItemStyles = css`
  display: block;
  width: 100%;
  outline: none;

  &:hover { background-color: ${colors.rocky.background}; }

  &.focus-visible {
    box-shadow: inset ${colors.blue.light} 0px 0px 0px 2px;
    background-color: ${colors.rocky.background};
  }
`;

export const MenuItemAnchor = styled.a`
  ${menuItemStyles}
`;

// VideoResolutionList

export const VideoResolutionListWrapper = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
`;

export const MenuItemButton = styled(TransparentButton)`
  ${menuItemStyles}
`;

export const ResolutionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 20px;
  width: 100%;
  cursor: pointer;

  ${media.min.tablet`padding: 8px 20px;`}
`;

export const CheckmarkIconWrapper = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;

  ${media.min.tablet`
    width: 24px;
    height: 24px;
  `}
`;

export const ItemText = styled.span`
  ${typeScale('centi')}
  font-weight: ${type.weight.semiBold};
  color: ${colors.sierra.base};
  margin-left: 24px;

  ${media.min.tablet`${typeScale('uno')}`}
`;
