/* eslint-disable sort-keys */

import { camelizeKeys, decamelizeKeys } from 'humps';
import i18n from 'i18next';
import Config from 'config'; // eslint-disable-line

import Request from 'lib/request';

import fetchWithAuth from './fetchWithAuth';

const requestInstance = Request.createInstance({
  baseURL: Config.apiUrl,
  withCredentials: true,
});

export const request = async (config, token) => (
  requestInstance({
    ...config,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...i18n.language !== 'en' && { params: { lang: i18n.language } },
  })
);

const API = {
  * anonymizeUserCourseData(courseId, email = null) {
    return yield fetchWithAuth(request, {
      url: `/courses/${courseId}/results${email ? `?email=${encodeURIComponent(email)}` : ''}`,
      method: 'delete',
    });
  },
  * answerVideoTask(courseId, taskId, response) {
    return yield fetchWithAuth(request, {
      url: `/courses/${courseId}/events/${taskId}/task`,
      method: 'post',
      data: { response },
    });
  },
  * deleteUser() {
    return yield fetchWithAuth(request, {
      url: '/me',
      method: 'delete',
    });
  },
  * downloadUser() {
    return yield fetchWithAuth(
      request,
      {
        url: '/me/data_download',
        method: 'get',
        responseType: 'blob',
      },
      true,
    );
  },
  * editUser(data) {
    return yield fetchWithAuth(request, {
      url: '/me',
      method: 'put',
      data,
    });
  },
  generateMagicLink: (email, returnUrl) => request({
    url: '/generate_magic_link',
    method: 'post',
    data: {
      email,
      ...returnUrl && { return_url: returnUrl },
    },
  }),
  * getInformationCard(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/information_card`,
      method: 'get',
    });
  },
  * getCCAResults(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/cca_results`,
      method: 'get',
    });
  },
  * getCourse(courseId) {
    return yield fetchWithAuth(request, {
      url: `/courses/${courseId}`,
      method: 'get',
    });
  },
  * getCourses() {
    return yield fetchWithAuth(request, {
      url: '/me/courses',
      method: 'get',
    });
  },
  * getChildChapter(chapterId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${chapterId}/forward`,
      method: 'get',
    });
  },
  * getIAT(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/iat`,
      method: 'get',
    });
  },
  * getIATResult(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/iat/${learningObjectId}`,
      method: 'get',
    });
  },
  * getLikertTest(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/likert`,
      method: 'get',
    });
  },
  * getLikertTestResult(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/likert/${learningObjectId}`,
      method: 'get',
    });
  },
  getLoginFlowType: async companyCode => {
    const response = await request({
      url: `/login_flow_type?company_code=${companyCode}`,
      method: 'get',
    });

    return response.data.login_flow_type;
  },
  getSSOUrl: async returnUrl => {
    const response = await request({
      url: `/sso${returnUrl ? `?return_url=${returnUrl}` : ''}`,
      method: 'get',
    });

    return response.data.redirect_url;
  },
  * getSurveyBlock(learningObjectId, blockNumber) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/survey/blocks/${blockNumber}`,
      method: 'get',
    });
  },
  * getSurvey(learningObjectId, blockNumber) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/survey?block=${blockNumber}`,
      method: 'get',
    });
  },
  * getUser() {
    return yield fetchWithAuth(request, {
      url: '/me',
      method: 'get',
    });
  },
  * getVideoCredentials(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/video_credentials`,
      method: 'get',
    });
  },
  * getVideoMetadata(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/video`,
      method: 'get',
    });
  },
  * getVideoOverlayData(overlayId) {
    return yield fetchWithAuth(request, {
      url: `/event/${overlayId}/results_overlay`,
      method: 'get',
    });
  },
  login: async data => {
    const response = await request({
      url: '/login',
      method: 'post',
      data,
    });

    return camelizeKeys(response.data);
  },
  loginWithAuthCodeAnonymous: async authCode => {
    const response = await request({
      url: '/anonymous_login',
      method: 'post',
      data: {
        authorization_code: authCode,
      },
    });

    return camelizeKeys(response.data);
  },
  loginWithAuthCode: async authCode => {
    const response = await request({
      url: '/verify_authorization_code',
      method: 'post',
      data: {
        authorization_code: authCode,
      },
    });

    return camelizeKeys(response.data);
  },
  loginWithMagicLink: async token => {
    const response = await request({
      url: `/magic_link_login?token=${token}`,
      method: 'post',
    });

    return camelizeKeys(response.data);
  },
  refreshToken: async (token) => {
    const response = await request({
      url: '/token/refresh',
      method: 'post',
      data: {},
    }, token);

    return camelizeKeys(response.data);
  },
  register: async data => {
    const response = await request({
      url: '/register',
      method: 'post',
      data,
    });

    return camelizeKeys(response.data);
  },
  resetPassword: data => request({
    url: '/password_login/reset',
    method: 'post',
    data,
  }),
  sendPasswordRecovery: loginId => request({
    url: '/password_login/recovery',
    method: 'post',
    data: { login_id: loginId },
  }),
  * sendFileDownloadChoice(learningObjectId, data, options = {}) {
    return yield fetchWithAuth(
      request,
      {
        url: `/lo/${learningObjectId}/file_download`,
        method: 'post',
        data,
        ...options,
      },
      true,
    );
  },
  * sendBookmark(learningObjectId, data, shouldCreateIfNonExistent = false) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}${shouldCreateIfNonExistent ? '?create_nx=true' : ''}`,
      method: 'put',
      data,
    });
  },
  * sendIATBlockResults(learningObjectId, blockNumber, data) {
    return yield fetchWithAuth(request, {
      url: `/iat/${learningObjectId}?block=${blockNumber}`,
      method: 'post',
      data,
    });
  },
  * sendSurveyBlockResults(learningObjectId, blockNumber, data) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/survey/blocks/${blockNumber}`,
      method: 'post',
      data,
    });
  },
  * sendLikertTestResponses(learningObjectId, data) {
    return yield fetchWithAuth(request, {
      url: `/likert/${learningObjectId}`,
      method: 'post',
      data,
    });
  },
  * sendSurveyResponses(learningObjectId, data) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/survey`,
      method: 'post',
      data,
    });
  },
  * sendPolicyConsent(data) {
    return yield fetchWithAuth(request, {
      url: '/user/privacy',
      method: 'put',
      data,
    });
  },
  * sendAccessibilityCheck(data) {
    return yield fetchWithAuth(request, {
      url: '/user/accessibility',
      method: 'put',
      data,
    });
  },
  * skipChapterForAccessibility(learningObjectId) {
    return yield fetchWithAuth(request, {
      url: `/lo/${learningObjectId}/skip_for_accessibility`,
      method: 'post',
      data: {},
    });
  },
  * skipIAT(data) {
    return yield fetchWithAuth(request, {
      url: '/skip_iat',
      method: 'post',
      data: decamelizeKeys(data),
    });
  },
};

export default API;
