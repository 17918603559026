import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import SimpleButton from 'components/shared/SimpleButton';
import Modal from 'components/shared/Modal';
import { Content, Footer, Title } from './style';

const IATAssessmentTimerExceededModal = () => {
  const { trackEvent } = useTracking(
    {},
    {
      dispatchOnMount: () => ({ event: 'IAT Assessment Timer Exceeded Modal Shown' }),
    },
  );
  const { t } = useTranslation('courseViewer');

  const handleSubmit = () => {
    trackEvent({ event: 'IAT Assessment Timer Exceeded Modal Button Clicked' });
    window.location.reload();
  };

  return (
    <Modal isRequired>
      <Content>
        <Title>{t('iat.assessmentTimerExceeded.title')}</Title>
        <p>{t('iat.assessmentTimerExceeded.description')}</p>
        <Footer>
          <SimpleButton full type="button" onClick={handleSubmit} variant="brandedPrimary">
            {t('iat.assessmentTimerExceeded.buttonCTA')}
          </SimpleButton>
        </Footer>
      </Content>
    </Modal>
  );
};

export default IATAssessmentTimerExceededModal;
