/* eslint-disable @typescript-eslint/default-param-last */

import {
  getErrorMessage,
} from 'lib/errorHandler';

import {
  FETCH_CCA_RESULTS,
} from './actions';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  resultsData: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case FETCH_CCA_RESULTS.PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CCA_RESULTS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        resultsData: payload,
      };
    case FETCH_CCA_RESULTS.ERROR:
      return {
        ...state,
        error: { message: getErrorMessage(payload) },
        isFetching: false,
      };
    default:
      return state;
  }
};
