import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import { updateChapterUserMetadata } from 'store/entities/chapters/actions';
import { currentCourseIdSelector } from 'store/courses/selectors';
import { shouldOverrideLockSelector } from 'store/featureFlags/selectors';

import {
  SEND_CHAPTER_STARTED_BOOKMARK,
  sendChapterStartedBookmark,
} from '../actions';

const debug = Debug('biassync:lms:store:chapters:sagas:sendChapterStartedBookmark');

export function* sendChapterStartedBookmarkSaga(action) {
  const { payload } = action;
  debug('Saga called', payload);

  const { chapterId } = payload;
  const bookmarkData = { isStarted: true };

  const shouldCreateIfNonExistent = yield select(shouldOverrideLockSelector);
  const currentCourseId = yield select(currentCourseIdSelector);

  try {
    yield call(
      API.sendBookmark,
      chapterId,
      decamelizeKeys(bookmarkData),
      shouldCreateIfNonExistent,
    );

    yield put(updateChapterUserMetadata({
      chapterId,
      courseId: currentCourseId,
      userMetadata: bookmarkData,
    }));

    yield put(sendChapterStartedBookmark.success());
  } catch (e) {
    debug('Saga error', e);

    yield put(sendChapterStartedBookmark.error(e, action));
  }
}

export default function* sendChapterStartedBookmarkDefaultSaga() {
  yield takeLatest(SEND_CHAPTER_STARTED_BOOKMARK.PENDING, sendChapterStartedBookmarkSaga);
}
