import React from 'react';
import PropTypes from 'prop-types';

import { refType } from 'constants/propTypes';
import languageLabels from 'constants/languageLabels';

import CheckmarkIcon from 'components/svg/CheckmarkIcon';
import ListDivider from 'styles/components/ListDivider';
import MenuPopup from 'styles/components/MenuPopup';
import FocusTrap from 'components/shared/FocusTrap';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import {
  CheckmarkIconWrapper,
  LanguageMenuButton,
  LanguageMenuWrapper,
  LanguageOptionsWrapper,
  LoadingSpinnerWrapper,
  LanguageText,
  OptionWrapper,
  Input,
  Label,
  SectionTitle,
  Wrapper,
} from './style';

const LanguageMenu = ({
  currentLanguageCode,
  isMenuOpen,
  isUpdating,
  languageMenuRef,
  onLanguageChange,
  onLanguageMenuButtonClick,
  supportedLanguages,
  t,
}) => {
  const options = supportedLanguages.map(langId => ({
    id: langId,
    title: languageLabels[langId],
  }));

  return (
    <Wrapper ref={languageMenuRef}>
      <LanguageMenuButton data-testid="lang-menu-button" aria-expanded={isMenuOpen} aria-label={t('languageMenu.chooseLanguage')} onClick={onLanguageMenuButtonClick}>
        <LanguageText>{currentLanguageCode}</LanguageText>
      </LanguageMenuButton>
      {isMenuOpen && (
        <FocusTrap>
          <LanguageMenuWrapper>
            <MenuPopup role="menu">
              <SectionTitle>{t('languageMenu.chooseLanguage')}</SectionTitle>
              <ListDivider />
              <LanguageOptionsWrapper>
                {options.map(({ id, title }) => (
                  <OptionWrapper key={id}>
                    {(isUpdating && currentLanguageCode === id) ? (
                      <LoadingSpinnerWrapper>
                        <LoadingSpinner testId="loading-spinner" />
                      </LoadingSpinnerWrapper>
                    ) : (
                      <CheckmarkIconWrapper>
                        <CheckmarkIcon
                          titleText={currentLanguageCode === id ? `${t('languageMenu.selectedLanguage')}` : `${t('languageMenu.unselectedLanguage')}`}
                          variant={currentLanguageCode === id ? 'green' : 'grey'}
                        />
                      </CheckmarkIconWrapper>
                    )}
                    <Input
                      aria-checked={currentLanguageCode === id}
                      checked={currentLanguageCode === id}
                      id={id}
                      name={title}
                      onChange={() => onLanguageChange(id)}
                      type="radio"
                    />
                    <Label htmlFor={id} onClick={() => onLanguageChange(id)}>
                      {title}
                    </Label>
                  </OptionWrapper>
                ))}
              </LanguageOptionsWrapper>
            </MenuPopup>
          </LanguageMenuWrapper>
        </FocusTrap>
      )}
    </Wrapper>
  );
};

LanguageMenu.propTypes = {
  currentLanguageCode: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  languageMenuRef: refType,
  onLanguageChange: PropTypes.func.isRequired,
  onLanguageMenuButtonClick: PropTypes.func.isRequired,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
};

LanguageMenu.defaultProps = {
  languageMenuRef: null,
};

export default LanguageMenu;
