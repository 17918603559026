import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PlayButtonIcon from 'components/svg/PlayButtonIcon';
import LoadingSpinner from 'styles/components/DotSpinner';
import {
  NextChapterTitle,
  PlayButton,
  PressPlayText,
  Wrapper,
} from './style';

const EndCard = ({
  isLoading,
  nextChapterTitle,
  onContinue,
}) => {
  const { t } = useTranslation('courseViewer');
  const focusRef = useRef(null);

  useEffect(() => {
    if (!isLoading) focusRef.current.focus();
  }, [isLoading]);

  return (
    <Wrapper>
      {isLoading ? <LoadingSpinner /> : (
        <>
          <PressPlayText>
            {t('courseViewer:video.endCardLabel')}
          </PressPlayText>
          <NextChapterTitle>{nextChapterTitle}</NextChapterTitle>
          <PlayButton ref={focusRef} onClick={onContinue}>
            <PlayButtonIcon glowing />
          </PlayButton>
        </>
      )}
    </Wrapper>
  );
};

EndCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  nextChapterTitle: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default EndCard;
