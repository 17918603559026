import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import RadioButton from 'components/shared/RadioButton';
import Modal from 'components/shared/Modal';

import {
  DialogContent,
  DisclaimerContainer,
  Footer,
  OptionsWrapper,
  OptionWrapper,
  radioButtonTheme,
  Title,
} from './style';

const SkipIATOptionModal = ({
  isLoading,
  isSkippingIAT,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Modal isRequired>
      <DialogContent>
        <Title>{t('skipIATOptionModal.title')}</Title>
        <OptionsWrapper>
          <OptionWrapper>
            <RadioButton
              id="continue-iat"
              isChecked={!isSkippingIAT}
              name="continue-iat"
              onChange={() => onChange(false)}
              theme={radioButtonTheme}
            >
              <Trans i18nKey="skipIATOptionModal.options.yes">
                <strong>Yes</strong>, I would like to learn more about my unique bias profile
                by participating in the assessment.
              </Trans>
            </RadioButton>
          </OptionWrapper>
          <OptionWrapper>
            <RadioButton
              id="skip-iat"
              isChecked={isSkippingIAT}
              name="skip-iat"
              onChange={() => onChange(true)}
              theme={radioButtonTheme}
            >
              <Trans i18nKey="skipIATOptionModal.options.no">
                <strong>No</strong>, I do not wish to learn more about my unique bias profile
                and wish to skip the assessment.
              </Trans>
            </RadioButton>
          </OptionWrapper>
        </OptionsWrapper>
        <DisclaimerContainer>
          <Trans i18nKey="skipIATOptionModal.disclaimerText">
            <p>
              These unconscious bias assessments are voluntary; you are not required to take them. However, the trainings are mandatory.
            </p>
            <p>
              If you voluntarily take the assessments, your data:
              <ul>
                <li>will not be used for any employment-related decisions or purpose; and</li>
                <li>is only for your personal training purposes.</li>
              </ul>
            </p>
            <p>
              To ensure no one has access to your assessment data and to maintain the confidentiality of your data, all employees are encouraged to not share their results or maintain them in any way (e.g. no printing scores, downloading assessments, screenshots, etc.).
            </p>
          </Trans>
        </DisclaimerContainer>
        <Footer>
          <SimpleButton
            full
            loading={isLoading}
            onClick={onSubmit}
            type="button"
            variant="brandedPrimary"
          >
            {t('skipIATOptionModal.submitButton')}
          </SimpleButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

SkipIATOptionModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSkippingIAT: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SkipIATOptionModal;
