import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { useEventListener } from 'lib/customHooks';

import SimpleButton from 'components/shared/SimpleButton';
import StyledButton from 'components/shared/StyledButton';

import colors from 'styles/colors';

import {
  Keystroke,
  KeystrokeText,
  StyledButtonWrapper,
  Wrapper,
} from './style';

const SurveyItemCTA = ({
  buttonLabel,
  hasKeystroke,
  isActive,
  isLoading,
  isStyledButton,
  onClick,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  const handleKeyUp = useCallback(
    (event) => {
      if (
        hasKeystroke
        && isActive
        && event.key === 'Enter'
        && !event.shiftKey
        && event.target.name !== 'add-comment'
      ) {
        event.preventDefault();
        onClick();
      }
    },
    [isActive, hasKeystroke, onClick],
  );

  useEventListener('keyup', handleKeyUp);

  return (
    <Wrapper>
      {isStyledButton ? (
        <StyledButtonWrapper>
          <StyledButton onClick={onClick} full variant="brandedBlue">
            {buttonLabel || t('common:continue')}
          </StyledButton>
        </StyledButtonWrapper>
      ) : (
        <>
          <SimpleButton
            focusOutlineColor={colors.yellow.base}
            isFadedWhenLoading={false}
            loading={isLoading}
            onClick={onClick}
            variant="white"
          >
            {buttonLabel || t('courseViewer:survey.ok')}
          </SimpleButton>
          {hasKeystroke && (
            <KeystrokeText>
              <Trans i18nKey="courseViewer:survey.keystrokeText">
                or press <Keystroke>{{ keystrokeText: t('enter') }}</Keystroke>
              </Trans>
            </KeystrokeText>
          )}
        </>
      )}
    </Wrapper>
  );
};

SurveyItemCTA.propTypes = {
  buttonLabel: PropTypes.string,
  hasKeystroke: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isStyledButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SurveyItemCTA.defaultProps = {
  buttonLabel: null,
  isLoading: false,
  isStyledButton: false,
};

export default SurveyItemCTA;
