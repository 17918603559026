import Portal from './Portal';
import { ProgressBar } from './style';

type FurthestPointProgressBarProps = {
  progressPercentage: number;
};

const FurthestPointProgressBar = ({
  progressPercentage,
}: FurthestPointProgressBarProps) => (
  <Portal>
    <ProgressBar role="progressbar" percentage={progressPercentage} />
  </Portal>
);

export default FurthestPointProgressBar;
