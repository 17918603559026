import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import {
  TOAST_ERROR,
  TOAST_NOTIFICATION,
  TOAST_SUCCESS,
} from 'constants/toastTypes';

import AlertIcon from 'components/svg/AlertIcon';
import CheckmarkIcon from 'components/svg/CheckmarkIcon';

import {
  IconWrapper,
  ToastContent,
  ToastWrapper,
  TryAgainButton,
} from './style';

const iconTypes = {
  [TOAST_ERROR]: AlertIcon,
  [TOAST_NOTIFICATION]: AlertIcon,
  [TOAST_SUCCESS]: CheckmarkIcon,
};

const Toast = ({ message, onRetryError, type }) => {
  const { t } = useTranslation('common');
  const Icon = iconTypes[type];

  return (
    <ToastWrapper type={type}>
      <IconWrapper>
        <Icon variant={type !== TOAST_NOTIFICATION ? 'white' : 'black'} />
      </IconWrapper>
      <ToastContent role="alert" aria-live="assertive">
        {message || t('toast.defaultErrorMessage')}
        {onRetryError && (
          <>
            {' '}
            <Trans i18nKey="common:toast.tryAgain">
              Please
              {' '}
              <TryAgainButton onClick={onRetryError}>
                click here
              </TryAgainButton>
              {' '}
              to try again.
            </Trans>
          </>
        )}
      </ToastContent>
    </ToastWrapper>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  onRetryError: PropTypes.func,
  type: PropTypes.string.isRequired,
};

Toast.defaultProps = {
  message: null,
  onRetryError: null,
};

export default Toast;
