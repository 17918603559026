import styled from 'styled-components';

import Popup from 'styles/components/Popup';
import { colors, type } from 'styles';

const Tooltip = styled(Popup)`
  border-radius: 8px;
  padding: 18px;
  font-size: ${type.scale.centi.size};
  color: ${colors.ridge.base};
  text-align: left;
`;

export default Tooltip;
