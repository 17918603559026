import { useTranslation, Trans } from 'react-i18next';

import Plane from 'types/Plane';

import { InterpersonalAction } from '../types';
import ScoreGraph from '../ScoreGraph';

import {
  Description,
  SectionContainer,
  SectionTitle,
  SubSectionTitle,
} from '../style';

import {
  ContentContainer,
  Divider,
  GraphContainer,
  ScoreContainer,
  ScoreLabel,
  ScoreLabelColored,
  SubSectionContainer,
} from './style';

interface InterpersonalActionProps {
  content: InterpersonalAction;
}

const InterpersonalActionContainer = ({ content }: InterpersonalActionProps) => {
  const { t } = useTranslation('courseViewer');

  return (
    <SectionContainer>
      <SectionTitle>{t('courseViewer:ccaResults.interpersonalAction.heading')}</SectionTitle>
      <SubSectionContainer>
        <GraphContainer>
          <ScoreGraph
            scoreCategory={content.result.scoreCategory}
            orientation={Plane.Vertical}
            labels={t('courseViewer:ccaResults.graph.labels.interpersonal', { returnObjects: true })}
          />
        </GraphContainer>
        <ContentContainer>
          <Description dangerouslySetInnerHTML={{ __html: t('courseViewer:ccaResults.interpersonalAction.description') }} />
          <ScoreContainer>
            <Divider />
            <Trans i18nKey={`courseViewer:ccaResults.interpersonalAction.scores.${content.result.scoreCategory}`}>
              <ScoreLabel>
                Your results showed an
                <ScoreLabelColored>score category</ScoreLabelColored>
                of interpersonal action
              </ScoreLabel>
            </Trans>
            <Divider />
          </ScoreContainer>
          <SubSectionTitle>{t('courseViewer:ccaResults.interpersonalAction.meaningSection.heading')}</SubSectionTitle>
          <Description dangerouslySetInnerHTML={{ __html: content.result.resultText }} />
        </ContentContainer>
      </SubSectionContainer>
    </SectionContainer>
  );
};

export default InterpersonalActionContainer;
