import PropTypes from 'prop-types';

import getIATTutorial from 'constants/iatTutorial';
import {
  deviceTypeType,
  errorType,
} from 'constants/propTypes';

import AccessibilitySkipButton from 'components/shared/AccessibilitySkipButton';
import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import ScreenReaderOnly from 'styles/components/ScreenReaderOnly';

import IATTutorial from './IATTutorial';
import { ChapterWrapper } from './style';

const IATTutorialChapter = ({
  deviceType,
  error,
  isAnyModalOpen,
  isDemoCourse,
  isLoading,
  onBlockCompleted,
  onBlockStarted,
  onFinished,
  onRetryError,
  onSkipChapter,
}) => {
  let content;

  if (isLoading) content = <Loading />;
  else if (error) {
    content = (
      <ErrorView
        onRetryClick={error.retryAction ? onRetryError : null}
        text={error.message}
      />
    );
  } else {
    content = (
      <IATTutorial
        deviceType={deviceType}
        iat={getIATTutorial()}
        isAnyModalOpen={isAnyModalOpen}
        onBlockCompleted={onBlockCompleted}
        onBlockStarted={onBlockStarted}
        onFinished={onFinished}
      />
    );
  }

  return (
    <ChapterWrapper>
      {content}
      {!isDemoCourse && (
        <ScreenReaderOnly role="alert" aria-live="assertive">
          <AccessibilitySkipButton onClick={onSkipChapter} />
        </ScreenReaderOnly>
      )}
    </ChapterWrapper>
  );
};

IATTutorialChapter.propTypes = {
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  isAnyModalOpen: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onBlockCompleted: PropTypes.func.isRequired,
  onBlockStarted: PropTypes.func.isRequired,
  onFinished: PropTypes.func.isRequired,
  onRetryError: PropTypes.func.isRequired,
  onSkipChapter: PropTypes.func.isRequired,
};

IATTutorialChapter.defaultProps = {
  error: null,
};

export default IATTutorialChapter;
