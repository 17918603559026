/* eslint-disable sort-keys */

import { css } from 'styled-components';

export const widthSizes = {
  mobile: 320,
  mobileLandscape: 560,
  tablet: 700,
  baseline: 800,
  desktop: 1100,
  max: 1400,
};

export const heightSizes = {
  mobileLandscape: 375,
  tablet: 540,
  baseline: 600,
  desktop: 700,
  max: 850,
};

const media = {
  min: {
    mobile: (...args) => css`
      @media (min-width: ${widthSizes.mobile / 16}em) {
        ${css(...args)};
      }
    `,
    mobileLandscape: (...args) => css`
      @media (min-width: ${widthSizes.mobileLandscape / 16}em) {
        ${css(...args)};
      }
    `,
    tablet: (...args) => css`
      @media (min-width: ${widthSizes.tablet / 16}em) {
        ${css(...args)};
      }
    `,
    baseline: (...args) => css`
      @media (min-width: ${widthSizes.baseline / 16}em) {
        ${css(...args)};
      }
    `,
    desktop: (...args) => css`
      @media (min-width: ${widthSizes.desktop / 16}em) {
        ${css(...args)};
      }
    `,
    max: (...args) => css`
      @media (min-width: ${widthSizes.max / 16}em) {
        ${css(...args)};
      }
    `,
  },
  print: (...args) => css`
    @media print {
      ${css(...args)};
    }
  `,
  twoDimMin: {
    mobileLandscape: (...args) => css`
      @media (min-width: ${widthSizes.mobileLandscape / 16}em) and (min-height: ${heightSizes.mobileLandscape / 16}em) {
        ${css(...args)};
      }
    `,
    tablet: (...args) => css`
      @media (min-width: ${widthSizes.tablet / 16}em) and (min-height: ${heightSizes.tablet / 16}em) {
        ${css(...args)};
      }
    `,
    baseline: (...args) => css`
      @media (min-width: ${widthSizes.baseline / 16}em) and (min-height: ${heightSizes.baseline / 16}em) {
        ${css(...args)};
      }
    `,
    desktop: (...args) => css`
      @media (min-width: ${widthSizes.desktop / 16}em) and (min-height: ${heightSizes.desktop / 16}em) {
        ${css(...args)};
      }
    `,
    max: (...args) => css`
      @media (min-width: ${widthSizes.max / 16}em) and (min-height: ${heightSizes.max / 16}em) {
        ${css(...args)};
      }
    `,
  },
};

export default media;
