import { useEffect } from 'react';
import PropTypes from 'prop-types';

import * as Monitoring from 'lib/monitoring';
import { useSurveyContext } from 'components/shared/Survey/context';

import { refType, surveyPromptType, surveyResponseType } from 'constants/propTypes';
import * as PromptType from 'constants/surveyPromptTypes';
import { SurveySubTypes, NOT_APPLICABLE } from 'components/shared/Survey/constants';
import SurveyItemCTA from 'components/shared/Survey/SurveyItemCTA';

import MatrixPrompt from './MatrixPrompt';
import CheckboxesPrompt from './CheckboxesPrompt';
import YesNoPrompt from './YesNoPrompt';
import ScalePrompt from './ScalePrompt';
import ChooseOnePrompt from './ChooseOnePrompt';
import TextInputPrompt from './TextInputPrompt';
import DropdownPrompt from './DropdownPrompt';
import LikertPrompt from './LikertPrompt';
import StoryPanelFooter from './StoryPanelFooter';
import StandardFooter from './StandardFooter';

import {
  Image,
  ImageWrapper,
  PromptText,
  PromptWrapper,
} from './style';

const promptTypeComponentMapping = {
  [PromptType.Checkboxes]: CheckboxesPrompt,
  [PromptType.ChooseOne]: ChooseOnePrompt,
  [PromptType.Dropdown]: DropdownPrompt,
  [PromptType.Likert]: LikertPrompt,
  [PromptType.Matrix]: MatrixPrompt,
  [PromptType.Number]: TextInputPrompt,
  [PromptType.Scale]: ScalePrompt,
  [PromptType.TextArea]: TextInputPrompt,
  [PromptType.YesNo]: YesNoPrompt,
};

const PromptItem = ({
  innerRef,
  isActive,
  isTouchScreen,
  onAddCommentClick,
  onChange,
  onContinue,
  prompt,
  response,
  surveySubType,
}) => {
  const { itemsById } = useSurveyContext();

  const {
    hasNaOption,
    isOptional,
    promptText,
    promptType,
    referencePromptId,
  } = prompt;

  useEffect(() => {
    const isSlideOutContentError = referencePromptId && !itemsById[referencePromptId];

    if (isSlideOutContentError) {
      Monitoring.captureSimpleException(new Error('Survey slide out content is not available'));
    }
  }, []);

  const isPromptApplicable = response !== NOT_APPLICABLE;
  const isVignetteSubType = surveySubType === SurveySubTypes.Vignette;
  const isLikertImagePrompt = prompt.imageUrl && promptType === PromptType.Likert;

  const shouldHideCTA = (isVignetteSubType && promptType === PromptType.Matrix)
    || (isVignetteSubType && promptType === PromptType.ChooseOne)
    || isLikertImagePrompt;

  const PromptComponent = promptTypeComponentMapping[prompt.promptType];
  return (
    <>
      <fieldset ref={innerRef}>
        <PromptWrapper isPromptApplicable={isPromptApplicable}>
          {isLikertImagePrompt && (
            <ImageWrapper>
              <Image url={prompt.imageUrl} alt="" />
            </ImageWrapper>
          )}
          <PromptText dangerouslySetInnerHTML={{ __html: promptText }} isOptional={isOptional} />
          <PromptComponent
            hasKeystroke={!isTouchScreen}
            isActive={isActive}
            isDisabled={!isPromptApplicable}
            isTouchScreen={isTouchScreen}
            onChange={onChange}
            onContinue={onContinue}
            prompt={prompt}
            response={response}
          />
        </PromptWrapper>
        {referencePromptId && itemsById[referencePromptId] ? (
          <StoryPanelFooter content={itemsById[referencePromptId]} isActive={isActive} />
        ) : (
          <StandardFooter
            promptId={prompt.id}
            hasNACheckbox={hasNaOption && promptType !== PromptType.Matrix}
            isPromptApplicable={isPromptApplicable}
            onAddCommentClick={() => onAddCommentClick(prompt.id)}
            onChange={() => onChange(
              prompt.id,
              isPromptApplicable ? NOT_APPLICABLE : null,
              true,
            )}
          />
        )}
      </fieldset>
      {!shouldHideCTA && (
        <SurveyItemCTA
          buttonLabel={prompt.buttonLabel}
          hasKeystroke={!isTouchScreen}
          isActive={isActive}
          onClick={onContinue}
        />
      )}
    </>
  );
};

PromptItem.propTypes = {
  innerRef: refType,
  isActive: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  onAddCommentClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: surveyResponseType,
  surveySubType: PropTypes.string.isRequired,
};

PromptItem.defaultProps = {
  innerRef: null,
  response: null,
};

export default PromptItem;
