/*
 * Some code borrowed from https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
 */

const getFilename = response => {
  const disposition = response.headers['content-disposition'];
  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

  return (matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : 'BiasSync Download.pdf');
};

export default (response, isIOSChrome) => {
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const filename = getFilename(response);

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else if (isIOSChrome) {
    // iOS Chrome requires using FileReader to properly download file
    const reader = new FileReader();
    reader.onload = () => {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(blob);
  } else {
    const blobUrl = (window.URL ? window.URL : window.webkitURL).createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = blobUrl;
    link.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop Safari if pop up blocking
    // is enabled.
    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank');
    }

    document.body.appendChild(link);
    link.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(link);
      (window.URL ? window.URL : window.webkitURL).revokeObjectURL(blobUrl);
    });
  }
};
