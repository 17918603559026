const throttle = (delay, fn) => {
  let lastCall = 0;

  return function throttledFn(...args) {
    const now = (new Date()).getTime();

    if (now - lastCall < delay) return false;

    lastCall = now;

    return fn(...args);
  };
};

export default throttle;
