import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import StyledButton from 'components/shared/StyledButton';
import StyledExternalLink from 'styles/components/StyledExternalLink';

import {
  ButtonWrapper,
  CancelButton,
  HelpText,
  PolicyWrapper,
  Title,
  Wrapper,
} from './style';

const PolicyModal = ({
  hasCancelButton,
  isSubmitting,
  onSubmit,
  policyContent,
  titleTranslationKey,
}) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <Title tabIndex="0">
        {t(`policyModal.${titleTranslationKey}`)}
      </Title>
      <PolicyWrapper>
        {policyContent}
      </PolicyWrapper>
      <HelpText>
        <Trans i18nKey="policyModal.prompt">
          To continue, you must read the entire notice. If you have any questions,
          {' '}
          <StyledExternalLink href="mailto:help@biassync.com?subject=Question about policy">
            please email us
          </StyledExternalLink>.
        </Trans>
      </HelpText>
      <ButtonWrapper>
        {hasCancelButton && (
          <CancelButton
            disabled={isSubmitting}
            onClick={() => onSubmit(false)}
            type="button"
          >
            {t('policyModal.declineButton')}
          </CancelButton>
        )}
        <StyledButton
          full={!hasCancelButton}
          loading={isSubmitting}
          onClick={() => onSubmit(true)}
        >
          {t('policyModal.acceptButton')}
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

PolicyModal.propTypes = {
  hasCancelButton: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  policyContent: PropTypes.node.isRequired,
  titleTranslationKey: PropTypes.string.isRequired,
};

export default PolicyModal;
