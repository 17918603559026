import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styles';
import { useTranslation } from 'react-i18next';

const PlayIcon = ({ fill }) => {
  const { t } = useTranslation('courseOverview');

  return (
    <svg id="playIcon" aria-labelledby="playIconTitle" width="100%" height="100%" viewBox="0 0 16 18" role="img" focusable="false">
      <title id="playIconTitle">{t('courseOverview:status.currentChapter')}</title>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 16.001V2.021C0 .507 1.417-.466 2.604.226l12.004 6.98c1.283.756 1.283 2.83 0 3.565l-12.004 7C1.417 18.465 0 17.493 0 16.002z"
      />
    </svg>
  );
};

PlayIcon.propTypes = {
  fill: PropTypes.string,
};

PlayIcon.defaultProps = {
  fill: colors.white,
};

export default PlayIcon;
