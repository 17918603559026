import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const ForwardIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg
      id="forwardIcon"
      aria-labelledby="forwardIconTitle"
      width="100%"
      height="100%"
      viewBox="0 0 400 440"
      role="img"
      focusable="false"
    >
      <title id="forwardIconTitle">
        {t('tableOfContents.chapterType.forward')}
      </title>
      <path
        fill={fill}
        fillRule="nonzero"
        stroke={fill}
        strokeWidth="20"
        d="M56.6528 306.324C58.0029 307.649 59.3483 308.308 61.3758 308.308H159.163V453.86C159.163 457.829 161.858 460.478 165.909 460.478H201.652C205.697 460.478 208.398 457.834 208.398 453.86V308.308H311.581C315.627 308.308 318.327 305.664 318.327 301.691L318.317 198.482C318.317 194.513 315.622 191.864 311.571 191.864L209.066 191.869V158.129L312.922 158.124C314.944 158.124 316.294 157.464 317.645 156.139L372.27 104.535C373.62 103.21 374.293 101.89 374.293 99.9012C374.293 97.9168 373.62 96.5923 372.27 95.2678L317.645 43.663C316.294 42.3385 314.949 41.6785 312.922 41.6785L209.066 41.6832V23.1584C209.066 10.5868 198.275 0 185.46 0H182.764C169.95 0 159.158 10.5867 159.158 23.1584V41.6832H62.7211C58.6755 41.6832 55.9752 44.3275 55.9752 48.301V150.85C55.9752 154.819 58.6708 157.468 62.7211 157.468H159.836V191.209L61.3711 191.204C59.3483 191.204 57.9982 191.864 56.648 193.188L2.02284 244.793C0.672717 246.118 0 247.438 0 249.427C0 251.411 0.672705 252.736 2.02284 254.06L56.6528 306.324ZM195.579 447.245H173.324V308.309H195.579V447.245ZM172.651 23.1593C172.651 17.8659 177.374 13.2372 182.765 13.2372H185.46C190.856 13.2372 195.574 17.8706 195.574 23.1593V41.6841H173.319L173.324 23.1593H172.651ZM69.4627 55.5789H310.224L358.108 100.565L310.224 145.552H166.576L69.4615 145.556L69.4627 55.5789ZM172.651 158.123H194.906V191.864H172.651V158.123ZM64.0724 205.099H304.833V294.418H64.0724L16.1875 250.087L64.0724 205.099Z"
      />
    </svg>
  );
};

ForwardIcon.propTypes = {
  fill: PropTypes.string,
};

ForwardIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default ForwardIcon;
