import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { surveyPromptType } from 'constants/propTypes';

import RatingItem from '../../RatingItem';
import {
  KeyWrapper,
  RatingText,
  RatingItemWrapper,
  RatingWrapper,
  LikertScaleWrapper,
} from './style';

const LikertPrompt = ({
  isDisabled,
  onChange,
  prompt: {
    id,
    likertLabels,
  },
  response,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  const labels = likertLabels || t('likertScaleLabels', { returnObjects: true });
  return (
    <LikertScaleWrapper>
      {labels.map((rating, index) => {
        const selectedRating = index + 1;
        const isSelected = response === selectedRating;

        return (
          <RatingItemWrapper key={rating}>
            <RatingItem
              id={`${id}_${rating}_${selectedRating}`}
              isDisabled={isDisabled}
              isSelected={isSelected}
              name={id}
              onChange={() => onChange(id, selectedRating, true)}
              onClick={() => onChange(id, selectedRating)}
            >
              <RatingWrapper>
                <KeyWrapper isSelected={isSelected}>{selectedRating}</KeyWrapper>
                <RatingText isSelected={isSelected}>{rating}</RatingText>
              </RatingWrapper>
            </RatingItem>
          </RatingItemWrapper>
        );
      })}
    </LikertScaleWrapper>
  );
};

LikertPrompt.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LikertPrompt.defaultProps = {
  response: null,
};

export default LikertPrompt;
