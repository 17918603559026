import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import awaitAsyncAction from 'lib/awaitAsyncAction';
import AppStorage from 'lib/appStorage';
import { SELECTED_LANGUAGE } from 'lib/i18n';

import { isUserLoggedInSelector } from 'store/user/selectors';
import {
  EDIT_USER_LANGUAGE,
  editUserLanguage,
  LANGUAGE_CHANGED,
  languageChanged,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:languageChanged');

export function* languageChangedSaga(action) {
  const languageCode = action.payload;
  debug('Saga called', languageCode);

  const isUserLoggedIn = yield select(isUserLoggedInSelector);
  try {
    if (isUserLoggedIn) {
      yield put(editUserLanguage(languageCode));
      const [, errorAction] = yield call(awaitAsyncAction, EDIT_USER_LANGUAGE);

      if (errorAction) throw errorAction.payload;
      debug('User language successfully updated');
    }

    yield call(AppStorage.setItem, SELECTED_LANGUAGE, languageCode);
    window.location.reload();

    yield put(languageChanged.success());
  } catch (e) {
    debug('Saga error', e);

    yield put(languageChanged.error(e, action));
  }
}

export default function* languageChangedDefaultSaga() {
  yield takeLatest(LANGUAGE_CHANGED.PENDING, languageChangedSaga);
}
