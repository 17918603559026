import styled from 'styled-components';

import { colors, media, type } from 'styles';

import theme from '../../theme';

export const Title = styled.h5`
  margin: 0 0 20px 0;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};

  color: ${theme.accent.web};
  ${media.print`color: ${theme.accent.print};`}

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const ContentContainer = styled.div`
  display: flex;
  margin-bottom: 64px;
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0;

  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: none;
  margin-left: 18px;

  ${media.min.baseline`display: block;`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 375px;
  max-height: 400px;

  mask-image: linear-gradient(to right, transparent, black 30%);
  -webkit-mask-image: linear-gradient(to right, transparent, black 30%);
`;

export const GraphContainer = styled.div`
  margin-top: 62px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ScoreLabels = styled.div`
  margin: 40px 0 72px 0;
`;

export const ScoreCategoryLabel = styled.h6`
  font-size: 1.6rem;
  font-weight: ${type.weight.bold};
  margin: 0 0 6px 0;
  display: block;
  text-align: center;
  text-transform: uppercase;

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
`;

export const DimensionLabel = styled.h6`
  font-size: 1.4rem;
  color: ${colors.cascade.muted};
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 0;
  font-weight: ${type.weight.regular};

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;

export const RecommendationsContainer = styled.div`
  margin-top: -20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${media.min.baseline`
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 60px;
  `}
  ${media.min.desktop`margin-bottom: 80px;`}
`;

export const OverviewSectionContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
