import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { deviceTypeType } from 'constants/propTypes';
import { DESKTOP, MOBILE, TABLET } from 'constants/deviceTypes';

import SimpleButton from 'components/shared/SimpleButton';

import {
  DesktopCTATextWrapper,
  KeyText,
  TabletCTATextWrapper,
} from './style';

const IATPromptCTA = ({
  deviceType,
  onTap,
  shouldFlash,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);

  switch (deviceType) {
    case MOBILE:
      return (
        <SimpleButton onClick={onTap} size="sm" pulsing>
          {t('continue')}
        </SimpleButton>
      );
    case TABLET:
      return (
        <TabletCTATextWrapper flashing={shouldFlash}>
          {t('courseViewer:iat.tabletCTA')}
        </TabletCTATextWrapper>
      );
    case DESKTOP:
    default:
      return (
        <DesktopCTATextWrapper>
          <Trans i18nKey="courseViewer:iat.desktopCTA">
            Press <KeyText flashing={shouldFlash}>spacebar</KeyText> to continue
          </Trans>
        </DesktopCTATextWrapper>
      );
  }
};

IATPromptCTA.propTypes = {
  deviceType: deviceTypeType.isRequired,
  onTap: PropTypes.func,
  shouldFlash: PropTypes.bool,
};

IATPromptCTA.defaultProps = {
  onTap: undefined,
  shouldFlash: false,
};

export default IATPromptCTA;
