import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  appErrorSelector,
  isAppInitializingSelector,
  isPasswordLoginTypeSelector,
} from 'store/auth/selectors';
import { isUserLoggedInSelector } from 'store/user/selectors';
import {
  isAndroidSelector,
  isTouchScreenSelector,
} from 'store/userAgent/selectors';

import PageTitle from 'components/shared/PageTitle';

import LMS from '../LMS';
import Login from '../Login';
import Register from '../Register';
import ForgotPassword from '../ForgotPassword';
import ScrollToTop from '../ScrollToTop';
import ErrorView from '../shared/ErrorView';
import Loading, { LoadingVariant } from '../shared/Loading';
import TouchScreenSupport from '../shared/TouchScreenSupport';
import ConditionalRoute from '../shared/ConditionalRoute';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { LoadingWrapper } from './style';

const mapStateToProps = state => ({
  error: appErrorSelector(state),
  isAndroid: isAndroidSelector(state),
  isLoading: isAppInitializingSelector(state),
  isPasswordLoginType: isPasswordLoginTypeSelector(state),
  isTouchScreen: isTouchScreenSelector(state),
  isUserLoggedIn: isUserLoggedInSelector(state),
});

export const App = ({
  error,
  isAndroid,
  isLoading,
  isPasswordLoginType,
  isTouchScreen,
  isUserLoggedIn,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn && location.pathname === '/login') {
      if (location.state) navigate(location.state.from.pathname);
      else navigate('/');
    }
  }, [isUserLoggedIn]);

  let content = null;
  if (error) {
    content = <ErrorView hasBackground text={error} />;
  } else if (isLoading) {
    content = (
      <LoadingWrapper>
        <Loading fillVariant={LoadingVariant.Dark} />
      </LoadingWrapper>
    );
  } else {
    content = (
      <>
        <ScrollToTop />
        <Routes>
          <Route path="login" element={<Login />} />
          <Route
            path="/privacy"
            element={<PrivacyPolicyPage />}
          />
          <Route
            path="/register"
            element={(
              <ConditionalRoute
                shouldRender={isPasswordLoginType}
                redirectPath="/login"
              >
                <Register />
              </ConditionalRoute>
            )}
          />
          <Route
            path="/password_reset"
            element={(
              <ConditionalRoute
                shouldRender={isPasswordLoginType}
                redirectPath="/login"
              >
                <ForgotPassword />
              </ConditionalRoute>
            )}
          />
          <Route
            path="*"
            element={(
              <ConditionalRoute
                shouldRender={isUserLoggedIn}
                redirectPath="/login"
              >
                <LMS />
              </ConditionalRoute>
            )}
          />
        </Routes>
      </>
    );
  }

  return (
    <>
      <PageTitle />
      {isTouchScreen ? (
        <TouchScreenSupport isAndroid={isAndroid}>
          {content}
        </TouchScreenSupport>
      ) : content}
    </>
  );
};

App.propTypes = {
  error: PropTypes.string,
  isAndroid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPasswordLoginType: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
};

App.defaultProps = {
  error: null,
};

export default connect(mapStateToProps)(App);
