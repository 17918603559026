import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import track, { TrackingPropType } from 'react-tracking';

import { isDeletingUserSelector } from 'store/user/selectors';
import { deleteUser } from 'store/user/actions';

import ConfirmDeleteModal from './ConfirmDeleteModal';

const mapStateToProps = state => ({
  isDeleting: isDeletingUserSelector(state),
});

const mapDispatchToProps = {
  onDeleteUser: deleteUser,
};

export const ConfirmDeleteModalContainer = ({
  isDeleting,
  onClose,
  onDeleteUser,
  tracking,
}) => {
  const [textInput, setTextInput] = useState('');

  const handleChange = event => {
    const { value } = event.target;
    setTextInput(value);
  };

  const handleSubmit = () => {
    tracking.trackEvent({ event: 'Delete User Button Clicked' });
    onDeleteUser();
  };

  return (
    <ConfirmDeleteModal
      isDeleting={isDeleting}
      onChange={handleChange}
      onClose={onClose}
      onSubmit={handleSubmit}
      textInput={textInput}
    />
  );
};

ConfirmDeleteModalContainer.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
};

const trackedConfirmDeleteModal = track({ module: 'ConfirmDeleteModal' }, {
  dispatchOnMount: () => ({ event: 'Confirm Delete Modal Shown' }),
})(ConfirmDeleteModalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedConfirmDeleteModal);
