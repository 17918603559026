import Logo, { LogoVariant } from 'components/svg/Logo';
import VideoPlayerPortal from 'components/shared/VideoPlayerPortal';

import { Wrapper } from './style';

const Watermark = () => (
  <VideoPlayerPortal>
    <Wrapper>
      <Logo variant={LogoVariant.Light} />
    </Wrapper>
  </VideoPlayerPortal>
);

export default Watermark;
