import styled from 'styled-components';
import { colors, media, type } from 'styles';
import checkmarkIcon from 'assets/green-check.svg';

export default styled.div`
  position: relative;
  display: inline-block;
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 20px 36px;
  box-shadow: 0 3px 12px rgba(0,0,0,0.3), 0 3px 12px rgba(0,0,0,0.3);

  ${media.twoDimMin.baseline`
    font-size: 2.2rem;
    padding: 24px 40px;
  `}

  ${media.twoDimMin.desktop`
    font-size: 2.4rem;
    padding: 28px 44px;
  `}

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: ${colors.white} url(${checkmarkIcon}) no-repeat center;
    height: 40px;
    width: 40px;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px;

    ${media.twoDimMin.baseline`
      top: -28px;
      height: 44px;
      width: 44px;
    `}

    ${media.twoDimMin.desktop`
      top: -32px;
      height: 48px;
      width: 48px;
    `}
  }
`;
