/* eslint-disable @typescript-eslint/default-param-last */

import {
  getErrorMessage,
  getHttpStatusCode,
  getLoginRegisterErrorMessage,
  isHttpRequestError,
  isUserRegistrationConflictError,
} from 'lib/errorHandler';

import * as ModalTypes from 'constants/modalTypes';
import * as ToastTypes from 'constants/toastTypes';

import i18n from 'lib/i18n';

import { ANONYMIZE_USER_COURSE_DATA } from '../userDataAnonymization/actions';
import { SUBMIT_FILE_DOWNLOAD_CHOICE } from '../fileDownload/actions';
import { SUBMIT_LIKERT_TEST } from '../likertTest/actions';
import { SEND_SURVEY_BLOCK_RESULTS } from '../survey/actions';
import {
  DELETE_USER,
  DOWNLOAD_USER,
  EDIT_USER,
  FETCH_USER,
  LANGUAGE_CHANGED,
  SUBMIT_ACCESSIBILITY_CHECK,
  SUBMIT_POLICY_CONSENT,
} from '../user/actions';
import { SKIP_IAT } from '../iat/actions';
import { FETCH_COURSE } from '../courses/actions';
import { SKIP_CHAPTER_FOR_ACCESSIBILITY } from '../chapters/actions';
import {
  FETCH_SSO_URL,
  GENERATE_MAGIC_LINK,
  LOGIN_WITH_AUTH_CODE,
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  LOGIN_WITH_MAGIC_LINK,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_SSO,
  REGISTER_USER,
  RESET_PASSWORD,
  SUBMIT_PASSWORD_RECOVERY,
} from '../auth/actions';
import {
  MODAL_CLOSED,
  MODAL_OPENED,
  REQUEST_HEADER_VISIBILITY,
  TOGGLE_OPEN_TOAST,
  TOGGLE_OPEN_PANEL,
  TOGGLE_OPEN_MENU,
} from './actions';

export const INITIAL_STATE = {
  currentOpenMenu: null,
  currentOpenModals: [],
  currentOpenPanel: null,
  currentOpenToast: null,
  isHeaderVisibilityRequested: false,
};

const notificationToastMessages = {
  [FETCH_COURSE.SUCCESS]: 'common:toast.languageNotSupported',
};

const successToastMessages = {
  [EDIT_USER.SUCCESS]: 'accountDetails:accountUpdated',
  [DELETE_USER.SUCCESS]: 'accountDetails:accountDeleted',
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case ANONYMIZE_USER_COURSE_DATA.ERROR:
    case DELETE_USER.ERROR:
    case EDIT_USER.ERROR:
    case FETCH_SSO_URL.ERROR:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.ERROR:
    case LOGIN_WITH_AUTH_CODE.ERROR:
    case LOGIN_WITH_MAGIC_LINK.ERROR:
    case RESET_PASSWORD.ERROR:
    case SUBMIT_ACCESSIBILITY_CHECK.ERROR:
    case SUBMIT_POLICY_CONSENT.ERROR:
    case SKIP_IAT.ERROR:
      return {
        ...state,
        currentOpenToast: {
          message: isHttpRequestError(payload) ? getErrorMessage(payload) : payload,
          type: ToastTypes.TOAST_ERROR,
        },
      };
    case GENERATE_MAGIC_LINK.ERROR:
      return {
        ...state,
        currentOpenToast: {
          message: getErrorMessage(payload, {
            404: i18n.t('errors:unknownEmail'),
          }),
          type: ToastTypes.TOAST_ERROR,
        },
      };
    case LOGIN_WITH_PASSWORD.ERROR:
    case REGISTER_USER.ERROR: {
      if (isUserRegistrationConflictError(payload)) return state;

      return {
        ...state,
        currentOpenToast: {
          message: isHttpRequestError(payload) ? getLoginRegisterErrorMessage(payload) : payload,
          type: ToastTypes.TOAST_ERROR,
        },
      };
    }
    case DOWNLOAD_USER.ERROR:
    case SUBMIT_FILE_DOWNLOAD_CHOICE.ERROR:
    case SUBMIT_LIKERT_TEST.ERROR:
    case SEND_SURVEY_BLOCK_RESULTS.ERROR:
      return {
        ...state,
        currentOpenToast: {
          message: isHttpRequestError(payload) ? getErrorMessage(payload) : payload,
          retryAction: isHttpRequestError(payload) ? action.originalAction : undefined,
          type: ToastTypes.TOAST_ERROR,
        },
      };
    case LANGUAGE_CHANGED.ERROR:
      return {
        ...state,
        currentOpenModals: state.currentOpenModals.concat({
          modalProps: { languageCode: action.originalAction.payload },
          modalType: ModalTypes.LANGUAGE_CHANGE_ERROR,
        }),
      };
    case SUBMIT_PASSWORD_RECOVERY.ERROR: {
      const statusCode = getHttpStatusCode(payload);

      if (statusCode === 500) {
        return {
          ...state,
          currentOpenToast: {
            message: getErrorMessage(payload),
            type: ToastTypes.TOAST_ERROR,
          },
        };
      }

      if (statusCode === 412) {
        return {
          ...state,
          currentOpenToast: {
            message: i18n.t('errors:unableToResetPasswordMessage'),
            type: ToastTypes.TOAST_ERROR,
          },
        };
      }

      return state;
    }
    case EDIT_USER.SUCCESS:
      return {
        ...state,
        currentOpenToast: {
          message: i18n.t(successToastMessages[type]),
          type: ToastTypes.TOAST_SUCCESS,
        },
      };
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        currentOpenModals: state.currentOpenModals.slice(1),
        currentOpenToast: {
          message: i18n.t(successToastMessages[type]),
          type: ToastTypes.TOAST_SUCCESS,
        },
      };
    case FETCH_COURSE.SUCCESS: {
      const { courseId, courses } = payload;
      const { supportedLanguages } = courses[courseId];

      return supportedLanguages.includes(i18n.getCurrentLanguage()) ? state : {
        ...state,
        currentOpenToast: {
          autoDismiss: false,
          message: i18n.t(notificationToastMessages[type]),
          type: ToastTypes.TOAST_NOTIFICATION,
        },
      };
    }
    case MODAL_OPENED:
      return {
        ...state,
        currentOpenModals: state.currentOpenModals.concat(payload),
      };
    case MODAL_CLOSED:
    case SKIP_CHAPTER_FOR_ACCESSIBILITY.PENDING:
    case SUBMIT_ACCESSIBILITY_CHECK.SUCCESS:
    case SUBMIT_POLICY_CONSENT.SUCCESS:
    case SKIP_IAT.SUCCESS:
      return {
        ...state,
        currentOpenModals: state.currentOpenModals.slice(1),
      };
    case LOGIN_WITH_AUTH_CODE.SUCCESS:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS:
    case LOGIN_WITH_MAGIC_LINK.SUCCESS:
    case LOGIN_WITH_PASSWORD.SUCCESS:
    case LOGIN_WITH_SSO:
    case REGISTER_USER.SUCCESS: {
      const { privacyPolicyType, showAccessibilityCheck } = payload.user;
      const modals = [];

      if (showAccessibilityCheck) modals.push({ modalType: ModalTypes.ACCESSIBILITY_CHECK });
      if (privacyPolicyType) modals.push({ modalType: ModalTypes.POLICY_CONSENT });

      return modals.length ? {
        ...state,
        currentOpenModals: state.currentOpenModals.concat(modals),
      } : state;
    }
    case FETCH_USER.SUCCESS: {
      const { privacyPolicyType, showAccessibilityCheck } = payload;
      const modals = [];

      if (showAccessibilityCheck) modals.push({ modalType: ModalTypes.ACCESSIBILITY_CHECK });
      if (privacyPolicyType) modals.push({ modalType: ModalTypes.POLICY_CONSENT });

      return modals.length ? {
        ...state,
        currentOpenModals: state.currentOpenModals.concat(modals),
      } : state;
    }
    case REQUEST_HEADER_VISIBILITY:
      return {
        ...state,
        isHeaderVisibilityRequested: payload,
      };
    case TOGGLE_OPEN_PANEL:
      return {
        ...state,
        currentOpenPanel: state.currentOpenPanel !== payload ? payload : null,
      };
    case TOGGLE_OPEN_MENU:
      return {
        ...state,
        currentOpenMenu: state.currentOpenMenu !== payload ? payload : null,
      };
    case TOGGLE_OPEN_TOAST:
      return {
        ...state,
        currentOpenToast: payload,
      };
    default:
      return state;
  }
};
