import React, { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import {
  deviceTypeType,
  iatTestResultType,
} from 'constants/propTypes';
import { DESKTOP, MOBILE } from 'constants/deviceTypes';
import { normalizeKeyValue } from 'lib/utils';
import debounce from 'lib/debounce';

import IATTestResult from './IATTestResult';

const SPACEBAR = ' ';
const INTRO_SCREEN_DELAY = 3000;

const INTRO_STEP = 0;
const OVERALL_GRAPH_STEP = 1;
const USERS_GRAPH_STEP = 2;

@track({}, {
  dispatchOnMount: () => ({ event: 'IAT Test Result Shown' }),
})
export class IATTestResultContainer extends Component {
  state = {
    currentStep: this.props.showUserResultsFirst ? USERS_GRAPH_STEP : INTRO_STEP,
    isScrolledToBottom: false,
  };

  componentDidMount() {
    const { deviceType, showUserResultsFirst } = this.props;

    window.scrollTo(0, 0);

    if (!showUserResultsFirst) {
      setTimeout(() => {
        this.modifyStepOrder(1);
      }, INTRO_SCREEN_DELAY);
    }
    if (deviceType === DESKTOP) window.addEventListener('keyup', this.handleKeyUp);
    if (deviceType === MOBILE) window.addEventListener('scroll', debounce(this.handleUserScroll, 250));
  }

  componentDidUpdate(_, prevState) {
    const { currentStep } = this.state;

    if (prevState.currentStep !== currentStep) window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { deviceType } = this.props;

    if (deviceType === DESKTOP) window.removeEventListener('keyup', this.handleKeyUp);
    if (deviceType === MOBILE) window.removeEventListener('scroll', debounce(this.handleUserScroll, 250));
  }

  handleUserScroll = () => {
    if (!this.state.isScrolledToBottom) {
      const SCROLL_OFFSET = 50;

      const docHeight = document.body.scrollHeight;
      const scrollHeight = window.innerHeight + window.pageYOffset + SCROLL_OFFSET;

      if (scrollHeight >= docHeight) this.setState(({ isScrolledToBottom: true }));
    }
  };

  handleKeyUp = event => {
    const keyValue = normalizeKeyValue(event.key);

    if (keyValue === SPACEBAR) {
      event.preventDefault();

      this.handleStepChange();
    }
  };

  handleStepChange = () => {
    const { currentStep } = this.state;
    const { onContinue } = this.props;

    if (currentStep === OVERALL_GRAPH_STEP) this.modifyStepOrder(1);
    else if (currentStep === USERS_GRAPH_STEP) onContinue();
  };

  modifyStepOrder = stepIndexModifier => {
    this.setState(state => ({
      currentStep: state.currentStep + stepIndexModifier,
      isScrolledToBottom: false,
    }));
  };

  render() {
    const { currentStep, isScrolledToBottom } = this.state;
    const {
      deviceType,
      result,
    } = this.props;

    return (
      <IATTestResult
        deviceType={deviceType}
        isScrolledToBottom={isScrolledToBottom}
        onBackButton={() => this.modifyStepOrder(-1)}
        onContinue={this.handleStepChange}
        showIndividualResult={currentStep === USERS_GRAPH_STEP}
        showIntro={currentStep === INTRO_STEP}
        testResult={result}
      />
    );
  }
}

IATTestResultContainer.propTypes = {
  deviceType: deviceTypeType.isRequired,
  onContinue: PropTypes.func.isRequired,
  result: iatTestResultType.isRequired,
  showUserResultsFirst: PropTypes.bool.isRequired,
};

export default IATTestResultContainer;
