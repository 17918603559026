import styled from 'styled-components';
import { media, type } from 'styles';

export const Wrapper = styled.div`
  text-align: center;
  margin-top: 44px;
`;

export const KeystrokeText = styled.span`
  margin-left: 15px;
  font-size: 1.4rem;
  ${media.min.desktop`font-size: 2rem;`}
`;

export const Keystroke = styled.span`
  font-weight: ${type.weight.bold};
  text-transform: uppercase;
`;

export const StyledButtonWrapper = styled.div`
  width: 190px;
  margin: 0 auto;
`;
