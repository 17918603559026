/* eslint-disable @typescript-eslint/default-param-last */

import { getErrorMessage } from 'lib/errorHandler';

import { FETCH_INFORMATION_CARD } from './actions';

export const INITIAL_STATE = {
  error: null,
  informationCardData: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case FETCH_INFORMATION_CARD.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
        isLoading: false,
      };
    case FETCH_INFORMATION_CARD.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_INFORMATION_CARD.SUCCESS:
      return {
        ...state,
        informationCardData: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
