import styled from 'styled-components';
import { media, type } from 'styles';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const PromptText = styled.legend`
  width: 100%;
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  margin-bottom: 32px;
  text-align: center;
  padding: 0 22px;

  ${media.min.tablet`font-size: 2.2rem;`}
  ${media.min.baseline`
    font-size: 2.4rem;
    margin-bottom: 44px;
  `}
`;

export const HintText = styled.span`
  display: block;
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 12px;

  ${media.min.baseline`padding: 0 80px;`}
`;
