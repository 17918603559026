import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import VideoPlayer from 'lib/VideoPlayer';

import {
  HANDLE_VIDEO_TIME_UPDATE,
  handleVideoTimeUpdate,
  setVideoBookmark,
} from '../actions';
import { isVideoPlayableSelector } from '../selectors';

const debug = Debug('biassync:lms:store:video:sagas:handleVideoTimeUpdate');

export function* handleVideoTimeUpdateSaga() {
  debug('Saga called');

  try {
    const isSeeking = yield call([VideoPlayer, 'isSeeking']);
    const isPlayable = yield select(isVideoPlayableSelector);

    if (!isSeeking && isPlayable) {
      const currentTime = yield call([VideoPlayer, 'getCurrentTime']);

      debug('Updating video bookmark', currentTime);
      yield put(setVideoBookmark(currentTime));
    }

    yield put(handleVideoTimeUpdate.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(handleVideoTimeUpdate.error(e));
  }
}

export default function* handleVideoTimeUpdateDefaultSaga() {
  yield takeLatest(HANDLE_VIDEO_TIME_UPDATE.PENDING, handleVideoTimeUpdateSaga);
}
