/* eslint-disable @typescript-eslint/default-param-last */

import {
  SUBMIT_FILE_DOWNLOAD_CHOICE,
} from './actions';

export const INITIAL_STATE = {
  isPosting: false,
  isSubmitted: false,
  userChoice: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case SUBMIT_FILE_DOWNLOAD_CHOICE.PENDING:
      return {
        ...state,
        isPosting: true,
        isSubmitted: false,
        userChoice: payload,
      };
    case SUBMIT_FILE_DOWNLOAD_CHOICE.ERROR:
      return {
        ...state,
        isPosting: false,
      };
    case SUBMIT_FILE_DOWNLOAD_CHOICE.SUCCESS:
      return {
        ...state,
        isPosting: false,
        isSubmitted: true,
      };
    default:
      return state;
  }
};
