import styled from 'styled-components';

import colors from 'styles/colors';

const variantColors = {
  white: colors.white,
  yellow: colors.yellow.base,
};

type PopupProps = {
  arrowHorizontalLocation?: string;
  arrowVerticalLocation?: string;
  showArrow?: boolean;
  variantColor?: 'white' | 'yellow';
};

const Popup = styled.div<PopupProps>`
  position: relative;
  background-color: ${({ variantColor = 'white' }) => variantColors[variantColor]};
  border-radius: 20px;
  box-shadow: 0 3px 12px rgba(0,0,0,0.1), 0 3px 12px rgba(0,0,0,0.1);

  ${({
    arrowHorizontalLocation = 'left',
    arrowVerticalLocation = 'bottom',
    showArrow = false,
    variantColor = 'white',
  }) => showArrow && `
    &:before {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      background-color: ${variantColors[variantColor]};
      transform: rotate(45deg);

      ${arrowHorizontalLocation === 'left' ? 'left: 34px;' : 'right: 34px;'}
      ${arrowVerticalLocation === 'bottom' ? 'bottom: -10px;' : 'top: -10px;'}
    }
  `}
`;

export default Popup;
