/* eslint-disable react/require-default-props */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventListener } from 'lib/customHooks';
import { MultipleChoiceOptions } from 'types/VideoTaskEvent';
import FocusTrap from 'components/shared/FocusTrap';
import SimpleButton from 'components/shared/SimpleButton';
import Popup from 'styles/components/Popup';
import colors from 'styles/colors';

import { VideoTaskWrapper } from '../../style';
import { OptionStatus } from './types';
import Option from './Option';
import {
  ButtonContainer,
  Fieldset,
  InstructionText,
  Legend,
  OptionsContainer,
} from './style';

type MultipleChoiceTaskFormProps = {
  correctAnswer?: string;
  isSubmitted?: boolean;
  isSubmitting: boolean;
  onContinue?: () => void;
  onSubmit: (optionKey: string) => void;
  options: MultipleChoiceOptions;
  prompt: string;
};

const MultipleChoiceTaskForm = ({
  correctAnswer,
  isSubmitted = false,
  isSubmitting,
  onContinue,
  onSubmit,
  options,
  prompt,
}: MultipleChoiceTaskFormProps) => {
  const { t } = useTranslation('courseViewer');
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (options[event.key]) setSelectedOption(event.key);
    else if (options[event.key.toUpperCase()]) setSelectedOption(event.key.toUpperCase());
  };

  useEventListener('keyup', handleKeyUp);

  const handleSubmit = () => onSubmit(selectedOption!);

  const getOptionStatus = (optionKey: string): OptionStatus => {
    if (isSubmitted) {
      if (optionKey === correctAnswer) return OptionStatus.Correct;
      if (optionKey === selectedOption) return OptionStatus.Incorrect;
      return OptionStatus.Disabled;
    }

    if (!selectedOption) return OptionStatus.Default;
    if (optionKey === selectedOption) return OptionStatus.Selected;
    return OptionStatus.Unselected;
  };

  return (
    <FocusTrap>
      <VideoTaskWrapper>
        <Popup role="dialog" aria-labelledby="taskPrompt">
          <form>
            <Fieldset>
              <Legend id="taskPrompt">{prompt}</Legend>
              <InstructionText>
                {t('courseViewer:video.task.multipleChoiceInstruction')}
              </InstructionText>
              <OptionsContainer>
                {Object.keys(options).map(optionKey => (
                  <Option
                    key={optionKey}
                    onClick={() => setSelectedOption(optionKey)}
                    optionKey={optionKey}
                    optionText={options[optionKey]}
                    status={getOptionStatus(optionKey)}
                  />
                ))}
              </OptionsContainer>
            </Fieldset>
            <ButtonContainer>
              <SimpleButton
                disabled={!selectedOption && !isSubmitted}
                focusOutlineColor={colors.yellow.base}
                full
                isFadedWhenLoading={false}
                loading={isSubmitting}
                onClick={isSubmitted && onContinue ? onContinue : handleSubmit}
                responsivenessType="twoDim"
              >
                {!isSubmitted ? t('courseViewer:video.task.submitButtonText') : t('courseViewer:video.task.continueButtonText')}
              </SimpleButton>
            </ButtonContainer>
          </form>
        </Popup>
      </VideoTaskWrapper>
    </FocusTrap>
  );
};

export default MultipleChoiceTaskForm;
