import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const PromptText = styled.span`
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.2rem;`}
  ${media.twoDimMin.desktop`font-size: 2.8rem;`}
  ${media.twoDimMin.max`font-size: 3.2rem;`}
`;

export const SummaryWrapper = styled.div`
  display: flex;
  margin-top: 18px;

  ${media.twoDimMin.tablet`margin-top: 40px;`}
  ${media.twoDimMin.desktop`margin-top: 48px;`}
  ${media.twoDimMin.max`margin-top: 58px;`}
`;
