import { InformationCard, LMSError, ReduxAction } from 'types';

import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';

import InformationCardCarousel from './InformationCardCarousel';
import { ChapterWrapper } from './style';

type InformationCardChapterProps = {
  error: LMSError | null;
  informationCard: InformationCard;
  isLoading: boolean;
  onFinished: () => void;
  onRetryError: (action: ReduxAction) => void;
};

const InformationCardChapter = ({
  error,
  informationCard,
  isLoading,
  onFinished,
  onRetryError,
}: InformationCardChapterProps) => {
  let content = null;

  if (error) {
    content = (
      <ErrorView
        onRetryClick={error.retryAction ? () => onRetryError(error.retryAction!) : null}
        text={error.message}
      />
    );
  } else if (isLoading || !informationCard) {
    content = <Loading />;
  } else {
    content = (
      <InformationCardCarousel
        informationCard={informationCard}
        onFinished={onFinished}
      />
    );
  }

  return <ChapterWrapper>{content}</ChapterWrapper>;
};

export default InformationCardChapter;
