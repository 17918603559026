import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import SimpleButton from 'components/shared/SimpleButton';
import Modal from 'components/shared/Modal';
import TextField from 'components/shared/TextField';
import {
  CancelButton,
  ConfirmDeleteText,
  DialogContent,
  Footer,
  TextFieldWrapper,
  Title,
} from './style';

const ConfirmDeleteModal = ({
  isDeleting,
  onChange,
  onClose,
  onSubmit,
  textInput,
}) => {
  const { t } = useTranslation('accountDetails');

  const isDeleteTyped = textInput === t('accountDetails:confirmDeleteModal.lowercaseDelete')
    || textInput === t('accountDetails:confirmDeleteModal.uppercaseDelete');
  return (
    <Modal onClose={onClose}>
      <DialogContent>
        <Title>{t('accountDetails:confirmDeleteModal.title')}</Title>
        <p>{t('accountDetails:confirmDeleteModal.mainDescription')}</p>
        <p>{t('accountDetails:confirmDeleteModal.supplementalDescription')}</p>
        <Trans i18nKey="accountDetails:confirmDeleteModal.confirmDeleteText">
          <ConfirmDeleteText>
            Please confirm your account deletion by typing <em>DELETE</em> in the text box below.
          </ConfirmDeleteText>
        </Trans>
        <TextFieldWrapper>
          <TextField
            labelText={t('accountDetails:confirmDeleteModal.labelText')}
            name="deleteId"
            onChange={onChange}
            value={textInput}
          />
        </TextFieldWrapper>
        <Footer>
          <CancelButton onClick={onClose}>
            {t('accountDetails:confirmDeleteModal.cancelButton')}
          </CancelButton>
          <SimpleButton
            loading={isDeleting}
            type="button"
            onClick={onSubmit}
            disabled={!isDeleteTyped}
          >
            {t('accountDetails:confirmDeleteModal.submitButton')}
          </SimpleButton>
        </Footer>
      </DialogContent>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  textInput: PropTypes.string.isRequired,
};

export default ConfirmDeleteModal;
