import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  FETCH_LOGIN_FLOW_TYPE,
  fetchLoginFlowType,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:fetchLoginFlowType');

export function* fetchLoginFlowTypeSaga({ payload: subdomain }) {
  debug('Saga called', subdomain);

  try {
    const loginFlowType = yield call(API.getLoginFlowType, subdomain);

    debug('Received login flow type', loginFlowType);

    yield put(fetchLoginFlowType.success(loginFlowType));
  } catch (e) {
    debug('Saga error', e);

    yield put(fetchLoginFlowType.error(e));
  }
}

export default function* fetchLoginFlowTypeDefaultSaga() {
  yield takeLatest(FETCH_LOGIN_FLOW_TYPE.PENDING, fetchLoginFlowTypeSaga);
}
