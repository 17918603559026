import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  SUBMIT_ACCESSIBILITY_CHECK,
  submitAccessibilityCheck,
} from '../actions';

const debug = Debug('biassync:lms:store:users:sagas:submitAccessibilityCheck');

export function* submitAccessibilityConsentSaga({ payload: usesAssistiveSoftware }) {
  debug('Saga called', { usesAssistiveSoftware });

  try {
    yield call(
      API.sendAccessibilityCheck,
      decamelizeKeys({ usesAssistiveSoftware }),
    );

    yield put(submitAccessibilityCheck.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(submitAccessibilityCheck.error(e));
  }
}

export default function* submitAccessibilityConsentDefaultSaga() {
  yield takeLatest(SUBMIT_ACCESSIBILITY_CHECK.PENDING, submitAccessibilityConsentSaga);
}
