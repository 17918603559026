import { useTranslation } from 'react-i18next';

import { LMSError } from 'types';

import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';

import { ResultsData } from './CCAResults/types';
import { ChapterWrapper } from './style';
import CCAResults from './CCAResults';

type CCAResultsChapterProps = {
  error: LMSError | null;
  isFetchingResults: boolean;
  isFirstTimeView: boolean;
  isSendingCompletedBookmark: boolean;
  onContinue: () => void;
  results: ResultsData | null;
  userFullName: string;
};

const CCAResultsChapter = ({
  error,
  isFetchingResults,
  isFirstTimeView,
  isSendingCompletedBookmark,
  onContinue,
  results,
  userFullName,
}: CCAResultsChapterProps) => {
  const { t } = useTranslation('courseViewer');

  let content = null;

  if (isFetchingResults) {
    content = isFirstTimeView ? (
      <Loading
        bodyText={t('courseViewer:ccaResults.loadingBody')}
        headingText={t('courseViewer:ccaResults.loadingHeading')}
      />
    ) : <Loading />;
  } else if (isSendingCompletedBookmark) {
    content = <Loading />;
  } else if (error) {
    content = <ErrorView text={error.message} />;
  } else if (results) {
    content = (
      <CCAResults
        onContinue={onContinue}
        results={results}
        userFullName={userFullName}
      />
    );
  }

  return (
    <ChapterWrapper>
      {content}
    </ChapterWrapper>
  );
};

export default CCAResultsChapter;
