import PropTypes from 'prop-types';
import { refType, surveyResponseType } from 'constants/propTypes';

import { SurveySubTypes, SurveyItemTypes } from 'components/shared/Survey/constants';
import IntersectionObserver from 'components/shared/IntersectionObserver';

import MessageItem from './MessageItem';
import CommentItem from './CommentItem';
import PromptItem from './PromptItem';
import PercentageTracker from './PercentageTracker';
import SectionTracker from './SectionTracker';

import { SurveyItemList, SurveyItemWrapper, ProgressTrackerWrapper } from './style';

const Survey = ({
  activeItemId,
  currentBlock,
  furthestItemIndex,
  isPostingResponses,
  isTouchScreen,
  itemRefs,
  items,
  onAddCommentClick,
  onItemCTAClick,
  onItemFocus,
  onResponseChange,
  responses,
  surveySubType,
  totalBlocks,
  totalItems,
  viewedItemIds,
}) => {
  const renderItem = (item, itemRef, isActive) => {
    let renderedItem = null;

    if (item.type === SurveyItemTypes.Message) {
      renderedItem = (
        <MessageItem
          hasKeystroke={!isTouchScreen}
          innerRef={itemRef}
          isActive={isActive}
          isLoading={isPostingResponses}
          message={item}
          onContinue={() => onItemCTAClick(item.id)}
          surveySubType={surveySubType}
        />
      );
    } else if (item.type === SurveyItemTypes.Comment) {
      renderedItem = (
        <CommentItem
          comment={item}
          innerRef={itemRef}
          isActive={isActive}
          isTouchScreen={isTouchScreen}
          onChange={onResponseChange}
          onContinue={() => onItemCTAClick(item.id)}
        />
      );
    } else if (item.type === SurveyItemTypes.Prompt) {
      renderedItem = (
        <PromptItem
          innerRef={itemRef}
          isActive={isActive}
          isTouchScreen={isTouchScreen}
          onAddCommentClick={() => onAddCommentClick(item.id)}
          onChange={onResponseChange}
          onContinue={() => onItemCTAClick(item.id)}
          prompt={item}
          response={responses[item.id]}
          surveySubType={surveySubType}
        />
      );
    }

    return renderedItem;
  };

  return (
    <>
      <SurveyItemList>
        {items.map((item, idx) => {
          if (idx > furthestItemIndex) return null;

          const { id } = item;
          const isActive = activeItemId === id;

          return (
            <SurveyItemWrapper key={id} isActive={isActive}>
              <IntersectionObserver onEnterViewport={() => onItemFocus(id)}>
                {renderItem(
                  item,
                  itemRefs[idx],
                  isActive,
                )}
              </IntersectionObserver>
            </SurveyItemWrapper>
          );
        })}
      </SurveyItemList>
      <ProgressTrackerWrapper>
        {surveySubType === SurveySubTypes.Vignette ? (
          <SectionTracker
            currentBlock={currentBlock}
            totalBlocks={totalBlocks}
          />
        ) : (
          <PercentageTracker
            viewedItems={viewedItemIds.length}
            totalItems={totalItems}
          />
        )}
      </ProgressTrackerWrapper>
    </>
  );
};

Survey.propTypes = {
  activeItemId: PropTypes.string.isRequired,
  currentBlock: PropTypes.number.isRequired,
  furthestItemIndex: PropTypes.number.isRequired,
  isPostingResponses: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  itemRefs: PropTypes.arrayOf(refType).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddCommentClick: PropTypes.func.isRequired,
  onItemCTAClick: PropTypes.func.isRequired,
  onItemFocus: PropTypes.func.isRequired,
  onResponseChange: PropTypes.func.isRequired,
  responses: PropTypes.objectOf(surveyResponseType).isRequired,
  surveySubType: PropTypes.string.isRequired,
  totalBlocks: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  viewedItemIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default Survey;
