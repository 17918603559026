import { useTranslation } from 'react-i18next';

import Plane from 'types/Plane';

import { ScoreCategory } from '../types';
import {
  AxisLabel,
  BackgroundRect,
  BarContainer,
  Caption,
  Figure,
  GraphContainer,
  MarkerLine,
  ScoreRect,
} from './style';

const scoreValues = {
  [ScoreCategory.Low]: 30,
  [ScoreCategory.Average]: 55,
  [ScoreCategory.High]: 80,
};

type BarProps = {
  value: number;
};

type GraphProps = {
  labels: [string, string];
  orientation: Plane;
  scoreCategory: ScoreCategory;
};

const HorizontalBar = ({ value }: BarProps) => (
  <svg width="100%" height="100%">
    <g clipPath="rect(0px 100% 100% 0px round 16px)">
      <BackgroundRect width="100%" height="100%" />
      <ScoreRect width={`${value}%`} height="100%" />
      <MarkerLine x1="25%" y1="0" x2="25%" y2="100%" strokeWidth="2" />
      <MarkerLine x1="50%" y1="0" x2="50%" y2="100%" strokeWidth="2" />
      <MarkerLine x1="75%" y1="0" x2="75%" y2="100%" strokeWidth="2" />
    </g>
  </svg>
);

const VerticalBar = ({ value }: BarProps) => (
  <svg width="100%" height="100%">
    <g clipPath="rect(0px 100% 100% 0px round 16px)">
      <BackgroundRect width="100%" height="100%" />
      <ScoreRect y={`${100 - value}%`} width="100%" height={`${value}%`} />
      <MarkerLine x1="0" y1="25%" x2="100%" y2="25%" strokeWidth="2" />
      <MarkerLine x1="0" y1="50%" x2="100%" y2="50%" strokeWidth="2" />
      <MarkerLine x1="0" y1="75%" x2="100%" y2="75%" strokeWidth="2" />
    </g>
  </svg>
);

const ScoreGraph = ({
  labels,
  orientation,
  scoreCategory,
}: GraphProps) => {
  const { t } = useTranslation('courseViewer');

  const graphLabel = `${t('courseViewer:ccaResults.graph.caption')} ${scoreValues[scoreCategory]}%.`;
  return (
    <Figure>
      <GraphContainer orientation={orientation}>
        <AxisLabel orientation={orientation}>{labels[0]}</AxisLabel>
        <BarContainer orientation={orientation}>
          {orientation === Plane.Horizontal ? (
            <HorizontalBar value={scoreValues[scoreCategory]} />
          ) : (
            <VerticalBar value={scoreValues[scoreCategory]} />
          )}
        </BarContainer>
        <AxisLabel orientation={orientation}>{labels[1]}</AxisLabel>
      </GraphContainer>
      <Caption>{graphLabel}</Caption>
    </Figure>
  );
};

export default ScoreGraph;
