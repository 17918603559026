import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Config from 'config'; // eslint-disable-line

import useModalCloseEventListener from 'lib/customHooks/useModalCloseEventListener';

import FocusTrap from 'components/shared/FocusTrap';
import ListDivider from 'styles/components/ListDivider';
import MenuPopup from 'styles/components/MenuPopup';
import chevronIcon from 'assets/chevron-right.svg';

import {
  ChevronIcon,
  MenuItem,
  MenuItemAnchor,
  MenuItemButton,
  MenuItemLink,
} from './style';

const AccountMenu = ({
  hasAdminPortalAccess,
  isAccountDetailsOptionAvailable,
  isCoursesPageOptionAvailable,
  onAccountDetailsLinkClick,
  onAdminPortalLinkClick,
  onClose,
  onCoursesLinkClick,
  onLogoutClick,
  onPrivacyPolicyLinkClick,
  onTechIssueLinkClick,
}) => {
  const { t } = useTranslation('common');
  const menuRef = useRef();

  useModalCloseEventListener(menuRef, onClose);

  return (
    <FocusTrap>
      <MenuPopup ref={menuRef}>
        {hasAdminPortalAccess && (
          <>
            <MenuItemAnchor href={`${window.location.origin}/admin`} target="_blank" onClick={onAdminPortalLinkClick}>
              <MenuItem>
                {t('accountMenu.adminPortal')}
                <ChevronIcon alt="" src={chevronIcon} />
              </MenuItem>
            </MenuItemAnchor>
            <ListDivider />
          </>
        )}
        {isAccountDetailsOptionAvailable && (
          <>
            <MenuItemLink to="/account" onClick={onAccountDetailsLinkClick}>
              <MenuItem>{t('accountMenu.accountDetails')}</MenuItem>
            </MenuItemLink>
            <ListDivider />
          </>
        )}
        {isCoursesPageOptionAvailable && (
          <>
            <MenuItemLink to="/courses" onClick={onCoursesLinkClick}>
              <MenuItem>{t('myCourses')}</MenuItem>
            </MenuItemLink>
            <ListDivider />
          </>
        )}
        <MenuItemAnchor href={Config.techIssueUrl} target="_blank" onClick={onTechIssueLinkClick}>
          <MenuItem>
            {t('techIssueLinkLabel')}
            <ChevronIcon alt="" src={chevronIcon} />
          </MenuItem>
        </MenuItemAnchor>
        <ListDivider />
        <MenuItemLink to="/privacy" target="_blank" onClick={onPrivacyPolicyLinkClick}>
          <MenuItem>
            {t('accountMenu.privacyPolicy')}
            <ChevronIcon alt="" src={chevronIcon} />
          </MenuItem>
        </MenuItemLink>
        <ListDivider />
        <MenuItemButton onClick={onLogoutClick}>
          <MenuItem>{t('accountMenu.logOut')}</MenuItem>
        </MenuItemButton>
      </MenuPopup>
    </FocusTrap>
  );
};

AccountMenu.propTypes = {
  hasAdminPortalAccess: PropTypes.bool.isRequired,
  isAccountDetailsOptionAvailable: PropTypes.bool.isRequired,
  isCoursesPageOptionAvailable: PropTypes.bool.isRequired,
  onAccountDetailsLinkClick: PropTypes.func.isRequired,
  onAdminPortalLinkClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCoursesLinkClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onPrivacyPolicyLinkClick: PropTypes.func.isRequired,
  onTechIssueLinkClick: PropTypes.func.isRequired,
};

export default AccountMenu;
