import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  GENERATE_MAGIC_LINK,
  generateMagicLink,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:generateMagicLink');

export function* generateMagicLinkSaga({ payload }) {
  const { email, returnUrl } = payload;
  debug('Saga called', payload);

  try {
    yield call(API.generateMagicLink, email, returnUrl);

    yield put(generateMagicLink.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(generateMagicLink.error(e));
  }
}

export default function* generateMagicLinkDefaultSaga() {
  yield takeLatest(GENERATE_MAGIC_LINK.PENDING, generateMagicLinkSaga);
}
