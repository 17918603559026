import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastPropType } from 'constants/propTypes';
import { useToasts } from 'react-toast-notifications';
import { usePrevious } from 'lib/customHooks';

import { currentOpenToastSelector } from 'store/ui/selectors';
import Toast from './Toast';

import './react-toast-style.css';

const mapStateToProps = state => ({
  toast: currentOpenToastSelector(state),
});

const mapDispatchToProps = {
  onRetryError: action => action,
};

const REMOVE_TOAST_TIMEOUT_MS = 200;

export const ToastManager = ({ onRetryError, toast }) => {
  const { addToast, removeToast, toastStack } = useToasts();

  const handleAddToast = content => {
    const {
      autoDismiss = true,
      message,
      retryAction,
      type,
    } = content;

    const shouldAutoDismiss = retryAction ? false : autoDismiss;
    addToast(
      <Toast
        message={message}
        onRetryError={retryAction && (() => onRetryError(retryAction))}
        type={type}
      />,
      { appearance: type, autoDismiss: shouldAutoDismiss },
    );
  };

  const prevToast = usePrevious(toast);
  useEffect(() => {
    if (toast) {
      if (prevToast && prevToast.retryAction && toastStack[0]) {
        const { id } = toastStack[0];

        removeToast(id, () => setTimeout(() => handleAddToast(toast), REMOVE_TOAST_TIMEOUT_MS));
      } else handleAddToast(toast);
    }
  }, [toast]);

  return null;
};

ToastManager.propTypes = {
  onRetryError: PropTypes.func.isRequired,
  toast: toastPropType,
};

ToastManager.defaultProps = {
  toast: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastManager);
