import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { userType } from 'constants/propTypes';
import { ADMIN_ROLE, META_ADMIN_ROLE } from 'constants/roleTypes';

import ChevronDown from 'components/svg/ChevronDown';
import Logo, { LogoVariant } from 'components/svg/Logo';
import biassyncLogo from 'assets/biassync-logo.svg';

import LanguageMenu from 'components/shared/LanguageMenu';
import AccountMenu from './AccountMenu';
import {
  AccountMenuButton,
  AccountMenuChevronContainer,
  AccountMenuWrapper,
  BiasSyncLogoWrapper,
  ContentWrapper,
  CourseLayoutWrapper,
  Header,
  HeaderMenuOptions,
  HomeLogoLink,
  HomeLogoWrapper,
  LanguageMenuWrapper,
  LogoWrapper,
} from './style';

const CourseLayout = ({
  children,
  hasLogoFooter,
  isAccountDetailsPermitted,
  isAccountMenuOpen,
  isCoursesPageOptionAvailable,
  onAccountDetailsLinkClick,
  onAccountMenuClick,
  onAdminPortalLinkClick,
  onCoursesLinkClick,
  onLogoLinkClick,
  onLogout,
  onPrivacyPolicyLinkClick,
  onTechIssueLinkClick,
  user,
}) => {
  const { t } = useTranslation('common');

  return (
    <CourseLayoutWrapper>
      <ContentWrapper hasLogoFooter={hasLogoFooter}>
        <Header>
          {isCoursesPageOptionAvailable ? (
            <HomeLogoWrapper>
              <HomeLogoLink aria-label={t('myCourses')} to="/courses" onClick={onLogoLinkClick}>
                <BiasSyncLogoWrapper>
                  <Logo variant={LogoVariant.Light} />
                </BiasSyncLogoWrapper>
              </HomeLogoLink>
            </HomeLogoWrapper>
          ) : (
            <HomeLogoWrapper>
              <BiasSyncLogoWrapper>
                <Logo variant={LogoVariant.Light} />
              </BiasSyncLogoWrapper>
            </HomeLogoWrapper>
          )}
          <HeaderMenuOptions>
            <LanguageMenuWrapper>
              <LanguageMenu />
            </LanguageMenuWrapper>
            <AccountMenuButton
              disabled={isAccountMenuOpen}
              onClick={onAccountMenuClick}
              aria-expanded={isAccountMenuOpen}
              aria-label={t('accountMenu.buttonAriaLabel')}
            >
              {user && t('accountMenu.buttonText', { user })}
              <AccountMenuChevronContainer>
                <ChevronDown />
              </AccountMenuChevronContainer>
            </AccountMenuButton>
            {isAccountMenuOpen && (
              <AccountMenuWrapper role="menu">
                <AccountMenu
                  isAccountDetailsOptionAvailable={isAccountDetailsPermitted}
                  isCoursesPageOptionAvailable={isCoursesPageOptionAvailable}
                  hasAdminPortalAccess={!!user && (
                    user.role === META_ADMIN_ROLE || user.role === ADMIN_ROLE
                  )}
                  onAccountDetailsLinkClick={onAccountDetailsLinkClick}
                  onAdminPortalLinkClick={onAdminPortalLinkClick}
                  onClose={onAccountMenuClick}
                  onCoursesLinkClick={onCoursesLinkClick}
                  onLogoutClick={onLogout}
                  onPrivacyPolicyLinkClick={onPrivacyPolicyLinkClick}
                  onTechIssueLinkClick={onTechIssueLinkClick}
                />
              </AccountMenuWrapper>
            )}
          </HeaderMenuOptions>
        </Header>
        {children}
      </ContentWrapper>
      {hasLogoFooter && (
        <LogoWrapper>
          <img src={biassyncLogo} alt="" />
        </LogoWrapper>
      )}
    </CourseLayoutWrapper>
  );
};

CourseLayout.propTypes = {
  children: PropTypes.node,
  hasLogoFooter: PropTypes.bool.isRequired,
  isAccountDetailsPermitted: PropTypes.bool.isRequired,
  isAccountMenuOpen: PropTypes.bool.isRequired,
  isCoursesPageOptionAvailable: PropTypes.bool.isRequired,
  onAccountDetailsLinkClick: PropTypes.func.isRequired,
  onAccountMenuClick: PropTypes.func.isRequired,
  onAdminPortalLinkClick: PropTypes.func.isRequired,
  onCoursesLinkClick: PropTypes.func.isRequired,
  onLogoLinkClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onPrivacyPolicyLinkClick: PropTypes.func.isRequired,
  onTechIssueLinkClick: PropTypes.func.isRequired,
  user: userType,
};

CourseLayout.defaultProps = {
  children: null,
  user: null,
};

export default CourseLayout;
