import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import {
  chapterType,
  courseSectionType,
  courseType,
} from 'constants/propTypes';

import {
  chaptersByIdSelector,
  currentChapterIdSelector,
  currentCourseSelector,
  isUserDataAnonymizedSelector,
  sectionsByIdSelector,
} from 'store/courses/selectors';
import { shouldOverrideLockSelector } from 'store/featureFlags/selectors';

import TableOfContents from './TableOfContents';

const mapStateToProps = state => ({
  chapters: chaptersByIdSelector(state),
  course: currentCourseSelector(state),
  currentChapterId: currentChapterIdSelector(state),
  isChapterLockEnabled: !shouldOverrideLockSelector(state),
  isUserDataAnonymized: isUserDataAnonymizedSelector(state),
  sections: sectionsByIdSelector(state),
});

export const TableOfContentsContainer = ({
  chapters,
  course,
  currentChapterId,
  isChapterLockEnabled,
  isUserDataAnonymized,
  onChapterSelect,
  sections,
  tracking: { trackEvent },
}) => {
  const handleChapterClick = chapterId => {
    trackEvent({
      chapterId,
      chapterName: chapters[chapterId].title,
      event: 'Chapter Selected',
    });

    onChapterSelect(chapterId);
  };

  return (
    <TableOfContents
      chapters={chapters}
      course={course}
      currentChapterId={currentChapterId}
      isChapterLockEnabled={isChapterLockEnabled}
      isUserDataAnonymized={isUserDataAnonymized}
      numOfSections={course.sections.length - 1}
      onChapterClick={handleChapterClick}
      sections={sections}
    />
  );
};

TableOfContentsContainer.propTypes = {
  chapters: PropTypes.objectOf(chapterType).isRequired,
  course: courseType.isRequired,
  currentChapterId: PropTypes.string,
  isChapterLockEnabled: PropTypes.bool.isRequired,
  isUserDataAnonymized: PropTypes.bool.isRequired,
  onChapterSelect: PropTypes.func,
  sections: PropTypes.objectOf(courseSectionType).isRequired,
  tracking: TrackingPropType,
};

TableOfContentsContainer.defaultProps = {
  currentChapterId: null,
  onChapterSelect: null,
  tracking: null,
};

const trackedTableOfContentsContainer = track({
  module: 'TableOfContents',
})(TableOfContentsContainer);

export default connect(mapStateToProps)(trackedTableOfContentsContainer);
