import fetchLoginFlowType from './fetchLoginFlowType';
import fetchSSOUrl from './fetchSSOUrl';
import generateMagicLink from './generateMagicLink';
import loginWithAuthCode from './loginWithAuthCode';
import loginWithAuthCodeAnonymous from './loginWithAuthCodeAnonymous';
import loginWithMagicLink from './loginWithMagicLink';
import loginWithPassword from './loginWithPassword';
import refreshAccessToken from './refreshAccessToken';
import registerUser from './registerUser';
import resetPassword from './resetPassword';
import sessionManager from './sessionManager';
import submitPasswordRecovery from './submitPasswordRecovery';

export default [
  fetchLoginFlowType,
  fetchSSOUrl,
  generateMagicLink,
  loginWithAuthCode,
  loginWithAuthCodeAnonymous,
  loginWithMagicLink,
  loginWithPassword,
  refreshAccessToken,
  registerUser,
  resetPassword,
  sessionManager,
  submitPasswordRecovery,
];
