import styled from 'styled-components';

import { colors, media, type } from 'styles';
import TransparentButton from 'styles/components/TransparentButton';

export const DialogContent = styled.div`
  width: 100%;
  max-width: 400px;
  color: ${colors.ridge.base};
  padding: 36px 24px 24px;
  text-align: center;
  font-size: 1.4rem;

  ${media.min.tablet`
    width: 500px;
    max-width: none;
    font-size: 1.8rem;
    padding: 48px 32px 32px;
  `}
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.sierra.base};
  margin: 0 0 18px;

  ${media.min.tablet`
    font-size: 2.4rem;
    margin: 0 0 32px;
  `}
`;

export const Footer = styled.footer`
  margin-top: 36px;
  ${media.min.tablet`margin-top: 44px;`}
`;

export const ContinueButton = styled(TransparentButton)`
  color: ${colors.brandedBlue.base};
  margin-top: 18px;
  font-weight: ${type.weight.semiBold};
  font-size: 1.4rem;
  outline: none;

  &.focus-visible { border-bottom: 2px solid ${colors.brandedBlue.light}; }
  ${media.min.baseline`font-size: 1.8rem;`}
`;
