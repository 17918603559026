import styled, { keyframes } from 'styled-components';

import colors from 'styles/colors';
import backgroundSVG from 'assets/bgtexture.svg';

export const DeviceOrientationObserverWrapper = styled.div`
  position: relative;

  @media (orientation: portrait) {
    height: 100vh;
    overflow: hidden;
  }
`;

export const NotSupportedMessage = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: url(${backgroundSVG}) ${colors.sierra.base} center;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10%;

  color: ${colors.white};
  text-align: center;
  opacity: 1;

  & span {
    margin-top: 44px;
  }

  @media (orientation: landscape) {
    visibility: hidden;
    opacity: 0;
  }
`;

const rotateRight = keyframes`
  60%, 100% { transform: rotate(90deg); }
`;

export const CircleWrapper = styled.div`
  position: relative;
  border: 1px solid ${colors.ridge.light};
  border-radius: 50%;
  padding: 24px 48px;
  width: 180px;
  height: 180px;
`;

export const PhoneIcon = styled.div`
  width: 80px;
  height: 128px;
  border: 0 solid ${colors.white};
  border-width: 16px 8px;
  border-radius: 10px;
  background-color: ${colors.cascade.dark};
  animation: ${rotateRight} 2s ease infinite;
`;
