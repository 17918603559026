import deleteUser from './deleteUser';
import downloadUser from './downloadUser';
import editUser from './editUser';
import editUserLanguage from './editUserLanguage';
import fetchUser from './fetchUser';
import languageChanged from './languageChanged';
import submitAccessibilityCheck from './submitAccessibilityCheck';
import submitPolicyConsent from './submitPolicyConsent';

export default [
  deleteUser,
  downloadUser,
  editUser,
  editUserLanguage,
  fetchUser,
  languageChanged,
  submitAccessibilityCheck,
  submitPolicyConsent,
];
