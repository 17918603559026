import {
  createAction,
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Sync
export const RESET_LIKERT_STORE = 'likertTest/resetLikertStore';
export const resetLikertStore = createAction(RESET_LIKERT_STORE);

// Async
export const FETCH_LIKERT_TEST = createAsyncTypes('likertTest/fetchLikertTest');
export const fetchLikertTest = createAsyncAction(FETCH_LIKERT_TEST);

export const FETCH_LIKERT_TEST_RESULT = createAsyncTypes('likertTest/fetchLikertTestResult');
export const fetchLikertTestResult = createAsyncAction(FETCH_LIKERT_TEST_RESULT);

export const SUBMIT_LIKERT_TEST = createAsyncTypes('likertTest/submitLikertTest');
export const submitLikertTest = createAsyncAction(SUBMIT_LIKERT_TEST);
