import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { calculateProgressPercentage } from 'lib/utils';

import {
  PercentageLabel,
  ProgressBar,
  ProgressBarLabel,
  Wrapper,
} from './style';

const PercentageTracker = ({
  totalItems,
  viewedItems,
}) => {
  const { t } = useTranslation('courseViewer');

  const percentage = calculateProgressPercentage(viewedItems, totalItems);
  return (
    <Wrapper>
      <ProgressBarLabel id="progressBarLabel">{t('courseViewer:survey.progressBarLabel')}</ProgressBarLabel>
      <ProgressBar
        value={viewedItems}
        max={totalItems}
        aria-labelledby="progressBarLabel"
      />
      <PercentageLabel>{t('courseViewer:survey.progressPercentageLabel', { percentage })}</PercentageLabel>
    </Wrapper>
  );
};

PercentageTracker.propTypes = {
  totalItems: PropTypes.number.isRequired,
  viewedItems: PropTypes.number.isRequired,
};

export default PercentageTracker;
