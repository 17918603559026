import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'styles/colors';
import glowingAnimation from 'styles/animations/glowing';

const ButtonPath = styled.path`
  ${({ glowing }) => (glowing && glowingAnimation('blue'))}
`;

const PlayButtonIcon = ({ glowing }) => (
  <svg width="100%" height="100%" viewBox="0 0 200 200" focusable="false">
    <g fill="none" fillRule="evenodd">
      <path
        fill={colors.white}
        d="M63.333 50h110v93.333h-110z"
      />
      <ButtonPath
        fill={colors.blue.base}
        glowing={glowing}
        d="M100 200C44.772 200 0 155.228 0 100S44.772 0 100 0s100 44.772 100 100-44.772 100-100 100zm-20-76.663c0 4.97 4.723 8.21 8.68 5.906l40.013-23.337c4.276-2.45 4.276-9.364 0-11.885L88.68 70.756C84.723 68.451 80 71.693 80 76.734v46.603z"
      />
    </g>
  </svg>
);

PlayButtonIcon.propTypes = {
  glowing: PropTypes.bool,
};

PlayButtonIcon.defaultProps = {
  glowing: false,
};

export default PlayButtonIcon;
