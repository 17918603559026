import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { refType } from 'constants/propTypes';

import StyledButton from 'components/shared/StyledButton';

import SliderButton from './SliderButton';
import ProgressBar from './ProgressBar';
import {
  ButtonWrapper,
  Content,
  SliderWrapper,
  Title,
  Wrapper,
  Header,
} from './style';

const MultiPageMessageItem = ({
  currentStepIndex,
  innerRef,
  isContinueButtonVisible,
  isLastStep,
  items,
  onContinue,
  onStepChange,
  title,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Wrapper ref={innerRef}>
      <Header>
        <Title>{title}</Title>
        <ProgressBar currentStepIndex={currentStepIndex} items={items} />
      </Header>
      <SliderWrapper>
        <SliderButton
          isHidden={currentStepIndex === 0}
          label={t('previous')}
          onClick={() => onStepChange(-1)}
          orientation="left"
        />
        <Content>{items[currentStepIndex].promptText}</Content>
        <SliderButton
          isHidden={isLastStep}
          label={t('next')}
          onClick={() => onStepChange(1)}
          orientation="right"
        />
      </SliderWrapper>
      <ButtonWrapper isVisible={isContinueButtonVisible}>
        <StyledButton disabled={!isContinueButtonVisible} onClick={onContinue} full variant="brandedBlue">
          {t('continue')}
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

MultiPageMessageItem.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  innerRef: refType,
  isContinueButtonVisible: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  ).isRequired,
  onContinue: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

MultiPageMessageItem.defaultProps = {
  innerRef: null,
};

export default MultiPageMessageItem;
