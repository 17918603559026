import React from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType } from 'constants/propTypes';
import { TABLET } from 'constants/deviceTypes';

import IATPromptCTA from '../IATPromptCTA';
import {
  ContentWrapper,
  CTAWrapper,
  IATPromptLayoutWrapper,
} from './style';

const IATPromptLayout = ({
  children,
  deviceType,
  onCTATap,
  shouldCTAFlash,
}) => (
  <IATPromptLayoutWrapper onClick={deviceType === TABLET ? onCTATap : undefined}>
    <ContentWrapper>
      {children}
    </ContentWrapper>
    <CTAWrapper>
      <IATPromptCTA
        deviceType={deviceType}
        onTap={onCTATap}
        shouldFlash={shouldCTAFlash}
      />
    </CTAWrapper>
  </IATPromptLayoutWrapper>
);

IATPromptLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  deviceType: deviceTypeType.isRequired,
  onCTATap: PropTypes.func.isRequired,
  shouldCTAFlash: PropTypes.bool,
};

IATPromptLayout.defaultProps = {
  shouldCTAFlash: false,
};

export default IATPromptLayout;
