import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const Form = styled.form`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: ${type.weight.bold};
  margin-bottom: 20px;

  ${media.min.baseline`
    font-size: 2.8rem;
    margin-bottom: 32px;
  `}
`;

export const TextFieldWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 14px;
  }
`;

export const LegalText = styled.div`
  text-align: center;
  font-size: ${type.scale.centi.size};
  font-weight: ${type.weight.regular};
  color: ${colors.ridge.base};
  margin-top: 14px;
  line-height: normal;
  padding: 0 28px;
`;

export const CTAWrapper = styled.div`
  margin-top: 14px;
`;

export const HelpText = styled.div`
  text-align: center;
  font-size: 1.6rem;
  font-weight: ${type.weight.semiBold};
  color: ${colors.ridge.base};
  margin-top: 28px;
  line-height: normal;
  padding: 0 28px;

  ${media.min.baseline`font-size: 1.8rem;`}
`;
