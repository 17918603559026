import React from 'react';

import { persistentEventType } from 'constants/propTypes';

import { AnnotationWrapper } from './style';

const Annotation = ({ overlayEvent }) => (
  <AnnotationWrapper>
    {overlayEvent.prompt}
  </AnnotationWrapper>
);

Annotation.propTypes = {
  overlayEvent: persistentEventType.isRequired,
};

export default Annotation;
