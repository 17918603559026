import React from 'react';
import PropTypes from 'prop-types';

import { refType } from 'constants/propTypes';
import CloseIcon from 'components/svg/XIcon';

import { Button, IconWrapper } from './style';

const CloseButton = ({ innerRef, onClick }) => (
  <Button ref={innerRef} onClick={onClick}>
    <IconWrapper>
      <CloseIcon />
    </IconWrapper>
  </Button>
);

CloseButton.propTypes = {
  innerRef: refType,
  onClick: PropTypes.func.isRequired,
};

CloseButton.defaultProps = {
  innerRef: null,
};

export default CloseButton;
