import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';
import { withTranslation } from 'react-i18next';

import { chapterType, deviceTypeType, errorType, iatType, iatTestResultType } from 'constants/propTypes';
import * as ToastTypes from 'constants/toastTypes';
import { DESKTOP, TABLET } from 'constants/deviceTypes';
import * as ModalTypes from 'constants/modalTypes';
import preloadImage from 'lib/preloadImage';
import timerIcon from 'assets/timer-icon.svg';

import * as iatActions from 'store/iat/actions';
import * as uiActions from 'store/ui/actions';
import { uaDeviceTypeSelector } from 'store/userAgent/selectors';
import { isAnyModalOpenSelector } from 'store/ui/selectors';
import { accessibilitySkipErrorSelector, isRequestingAccessibilitySkipSelector } from 'store/chapters/selectors';
import { isDemoCourseSelector, isIATChapterCombinedResultsSelector } from 'store/courses/selectors';
import {
  iatErrorSelector,
  iatDataSelector,
  iatResultSelector,
  isIATFetchingSelector,
  isPostingIATBlockResponsesSelector,
} from 'store/iat/selectors';
import { doesUserCompanyAllowSkippingIATSelector } from 'store/user/selectors';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';
import WindowSizeObserver from 'components/shared/WindowSizeObserver';

import IATChapter from './IATChapter';

const mapStateToProps = (state) => ({
  accessibilitySkipError: accessibilitySkipErrorSelector(state),
  deviceType: uaDeviceTypeSelector(state),
  doesUserCompanyAllowSkippingIAT: doesUserCompanyAllowSkippingIATSelector(state),
  error: iatErrorSelector(state),
  iat: iatDataSelector(state),
  iatResult: iatResultSelector(state),
  isAnyModalOpen: isAnyModalOpenSelector(state),
  isCombinedResults: isIATChapterCombinedResultsSelector(state),
  isDemoCourse: isDemoCourseSelector(state),
  isFetching: isIATFetchingSelector(state),
  isPostingBlockResponses: isPostingIATBlockResponsesSelector(state),
  isRequestingAccessibilitySkip: isRequestingAccessibilitySkipSelector(state),
});

const mapDispatchToProps = {
  fetchIAT: iatActions.fetchIAT,
  fetchIATResult: iatActions.fetchIATResult,
  onRetryError: action => action,
  openModal: uiActions.modalOpened,
  resetIATStore: iatActions.resetIATStore,
  sendBlockResults: iatActions.sendIATBlockResults,
  setHeaderVisible: uiActions.requestHeaderVisibility,
  toggleToast: uiActions.toggleOpenToast,
};

@track({})
export class IATChapterContainer extends Component {
  componentDidMount() {
    this.initializeChapter();

    // Preloading SVG assets used in IAT for quicker render later
    preloadImage(timerIcon);
  }

  componentDidUpdate(prevProps) {
    const {
      chapter,
      iatResult,
      isCombinedResults,
      isDemoCourse,
      t,
      toggleToast,
    } = this.props;

    if (chapter.id !== prevProps.chapter.id) this.initializeChapter();

    if (isCombinedResults && isDemoCourse && iatResult !== prevProps.iatResult) {
      toggleToast({ message: t('courseViewer:iat.demoNotification'), type: ToastTypes.TOAST_NOTIFICATION });
    }
  }

  componentWillUnmount() {
    const { deviceType, setHeaderVisible } = this.props;
    if (deviceType === TABLET) setHeaderVisible(false);
  }

  handleRetryError = () => {
    this.props.onRetryError(this.props.error.retryAction);
  };

  initializeChapter = () => {
    const {
      chapter,
      deviceType,
      doesUserCompanyAllowSkippingIAT,
      fetchIAT,
      fetchIATResult,
      isCombinedResults,
      openModal,
      resetIATStore,
      setHeaderVisible,
    } = this.props;

    resetIATStore();

    if (doesUserCompanyAllowSkippingIAT && !chapter.userMetadata.isStarted) {
      openModal({ modalType: ModalTypes.SKIP_IAT_OPTION });
    }

    if (!chapter.userMetadata.isCompleted) fetchIAT(chapter.id);
    else if (isCombinedResults) fetchIATResult(chapter.id);

    if (deviceType === TABLET) setHeaderVisible(true);
  };

  handleIATBlockStarted = () => {
    const { deviceType, setHeaderVisible } = this.props;
    if (deviceType === TABLET) setHeaderVisible(false);
  };

  handleIATBlockCompleted = (blockResults) => {
    const { deviceType, sendBlockResults, setHeaderVisible } = this.props;

    sendBlockResults(blockResults);

    if (deviceType === TABLET) setHeaderVisible(true);
  };

  @track({ event: 'Skip IAT Button Clicked' })
  handleAccessibilitySkipButtonClick = () => {
    this.props.openModal({
      modalType: ModalTypes.CONFIRM_ACCESSIBILITY_SKIP,
    });
  };

  @track({ event: 'IAT Assessment Timer Exceeded' })
  handleIATAssessmentTimerExceeded = () => {
    this.props.openModal({
      modalType: ModalTypes.IAT_ASSESSMENT_TIMER_EXCEEDED,
    });
  };

  render() {
    const {
      accessibilitySkipError,
      chapter,
      deviceType,
      error,
      goToNextChapter,
      iat,
      iatResult,
      isAnyModalOpen,
      isCombinedResults,
      isDemoCourse,
      isFetching,
      isPostingBlockResponses,
      isRequestingAccessibilitySkip,
    } = this.props;

    const content = (
      <DeviceOrientationObserver>
        <IATChapter
          deviceType={deviceType}
          error={error || accessibilitySkipError}
          hasPrevSeenResults={!iat}
          iat={iat}
          iatResult={iatResult}
          isAnyModalOpen={isAnyModalOpen}
          isCompleted={!!chapter.userMetadata.isCompleted}
          isDemoCourse={isDemoCourse}
          isLoading={isFetching || isRequestingAccessibilitySkip}
          isPostingBlockResponses={isPostingBlockResponses}
          isSeparateResults={!isCombinedResults}
          onContinue={goToNextChapter}
          onIATAssessmentTimerExceeded={this.handleIATAssessmentTimerExceeded}
          onIATBlockCompleted={this.handleIATBlockCompleted}
          onIATBlockStarted={this.handleIATBlockStarted}
          onRetryError={this.handleRetryError}
          onSkipChapter={this.handleAccessibilitySkipButtonClick}
        />
      </DeviceOrientationObserver>
    );

    return deviceType === DESKTOP ? <WindowSizeObserver>{content}</WindowSizeObserver> : content;
  }
}

IATChapterContainer.propTypes = {
  accessibilitySkipError: errorType,
  chapter: chapterType.isRequired,
  deviceType: deviceTypeType.isRequired,
  doesUserCompanyAllowSkippingIAT: PropTypes.bool.isRequired,
  error: errorType,
  fetchIAT: PropTypes.func.isRequired,
  fetchIATResult: PropTypes.func.isRequired,
  goToNextChapter: PropTypes.func.isRequired,
  iat: iatType,
  iatResult: iatTestResultType,
  isAnyModalOpen: PropTypes.bool.isRequired,
  isCombinedResults: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPostingBlockResponses: PropTypes.bool.isRequired,
  isRequestingAccessibilitySkip: PropTypes.bool.isRequired,
  onRetryError: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  resetIATStore: PropTypes.func.isRequired,
  sendBlockResults: PropTypes.func.isRequired,
  setHeaderVisible: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
};

IATChapterContainer.defaultProps = {
  accessibilitySkipError: null,
  error: null,
  iat: null,
  iatResult: null,
};

const withTranslationIATChapterContainer = withTranslation('courseViewer')(IATChapterContainer);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslationIATChapterContainer);
