import { useEffect } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useLocation } from 'react-router-dom';

import { SSO_DATA_PARAM } from 'constants/auth';
import { excludeQueryParams } from 'lib/queryParams';

import SSOLogin from './SSOLogin';

const SSOLoginContainer = ({
  isLoading,
  onSubmit,
  ssoUrl,
  tracking: { trackEvent },
}) => {
  const location = useLocation();

  useEffect(() => {
    if (ssoUrl) window.location.assign(ssoUrl);
  }, [ssoUrl]);

  const handleSubmit = event => {
    event.preventDefault();
    trackEvent({ event: 'Login Button Clicked' });

    const locationState = location.state;
    let returnUrl = null;

    if (locationState) {
      const { pathname, search } = locationState.from;
      const processedQueryParams = excludeQueryParams(search, [SSO_DATA_PARAM]);

      returnUrl = `${window.location.origin}${pathname}`;
      if (processedQueryParams) returnUrl += processedQueryParams;

      returnUrl = encodeURIComponent(returnUrl);
    }

    onSubmit({ returnUrl });
  };

  return <SSOLogin isLoading={isLoading} onSubmit={handleSubmit} />;
};

SSOLoginContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  ssoUrl: PropTypes.string,
  tracking: TrackingPropType,
};

SSOLoginContainer.defaultProps = {
  ssoUrl: null,
  tracking: null,
};

const trackedSSOLoginContainer = track({ page: 'SSO Login' })(SSOLoginContainer);

export default trackedSSOLoginContainer;
