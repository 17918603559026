import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ErrorMessage,
  ErrorWrapper,
  TextInput,
} from './style';

const isValidInput = v => /^$|^[1-9][0-9]*$/.test(v);
const isValidAge = v => v >= 15 && v <= 120;

const StyledNumberField = React.forwardRef(({
  isDisabled,
  maxLength,
  name,
  onChange,
  value,
}, ref) => {
  const { t } = useTranslation('errors');
  const [error, setError] = useState(null);

  const validate = input => {
    if (input && !isValidAge(input)) {
      setError(t('errors:invalidAge'));
    } else {
      setError(null);
    }
  };

  const handleChange = event => {
    const { target } = event;

    if (isValidInput(target.value)) {
      onChange(event);

      if (target.value.length !== 1) validate(target.value);
    }
  };

  const handleBlur = event => validate(event.target.value);

  return (
    <>
      <TextInput
        disabled={isDisabled}
        hasError={!!error}
        maxLength={maxLength}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
        value={value}
      />
      <ErrorWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ErrorWrapper>
    </>
  );
});

StyledNumberField.propTypes = {
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

StyledNumberField.defaultProps = {
  isDisabled: false,
  value: '',
};

export default StyledNumberField;
