import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import StyledButton from 'components/shared/StyledButton';
import {
  ButtonWrapper,
  HeaderText,
  OptOutButton,
  PromptText,
  Wrapper,
} from './style';

const AccessibilityCheckModal = ({
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <HeaderText>{t('accessibilityCheckModal.title')}</HeaderText>
      <PromptText>{t('accessibilityCheckModal.body')}</PromptText>
      <PromptText bold>{t('accessibilityCheckModal.prompt')}</PromptText>
      <ButtonWrapper>
        <div>
          <OptOutButton
            disabled={isLoading}
            onClick={() => onSubmit(true)}
            type="button"
          >
            {t('accessibilityCheckModal.declineButton')}
          </OptOutButton>
          <StyledButton
            disabled={isLoading}
            loading={isLoading}
            onClick={() => onSubmit(false)}
          >
            {t('accessibilityCheckModal.acceptButton')}
          </StyledButton>
        </div>
      </ButtonWrapper>
    </Wrapper>
  );
};

AccessibilityCheckModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccessibilityCheckModal;
