import Debug from 'debug';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import VideoPlayer from 'lib/VideoPlayer';

import { chapterCompleted } from 'store/courses/actions';
import { currentCourseIdSelector } from 'store/courses/selectors';

import {
  SEND_VIDEO_END_BOOKMARK,
  sendVideoEndBookmark,
  setVideoCompleted,
  setVideoBookmark,
} from '../actions';

const debug = Debug('biassync:lms:store:video:sagas:sendVideoEndBookmark');

export function* sendVideoEndBookmarkSaga({ payload }) {
  debug('Saga called');
  const { chapterId, isFirstView } = payload;

  try {
    const videoDuration = yield call([VideoPlayer, 'getDuration']);

    const bookmarkData = { bookmark: videoDuration };
    if (isFirstView) bookmarkData.is_completed = true;

    yield call(API.sendBookmark, chapterId, bookmarkData);

    yield put(setVideoBookmark(videoDuration));
    if (isFirstView) {
      yield put(setVideoCompleted());

      const courseId = yield select(currentCourseIdSelector);
      yield put(chapterCompleted({ chapterId, courseId }));
    }

    yield put(sendVideoEndBookmark.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(sendVideoEndBookmark.error(e));
  }
}

export default function* sendVideoEndBookmarkDefaultSaga() {
  yield takeLatest(SEND_VIDEO_END_BOOKMARK.PENDING, sendVideoEndBookmarkSaga);
}
