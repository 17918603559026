import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useTracking } from 'react-tracking';
import Config from 'config'; // eslint-disable-line

import LanguageMenu from 'components/shared/LanguageMenu';
import Logo from 'components/svg/Logo';
import {
  AuthenticationLayoutWrapper,
  ContentWrapper,
  ExternalLink,
  LanguageMenuWrapper,
  LogoWrapper,
  TechIssueLinkText,
} from './style';

const AuthenticationLayout = ({
  children,
  isLegalText,
}) => {
  const { trackEvent } = useTracking();

  const handleTechIssueLinkClick = () => trackEvent({ event: 'Tech Issue Link Clicked' });

  return (
    <AuthenticationLayoutWrapper>
      <LanguageMenuWrapper>
        <LanguageMenu />
      </LanguageMenuWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ContentWrapper isLegal={isLegalText}>
        {children}
      </ContentWrapper>
      <TechIssueLinkText>
        <Trans i18nKey="errors:techIssueLinkMessage">
          Encountered a problem? Help us improve by
          <ExternalLink href={Config.techIssueUrl} target="_blank" onClick={handleTechIssueLinkClick}>
            reporting the issue
          </ExternalLink>
          .
        </Trans>
      </TechIssueLinkText>
    </AuthenticationLayoutWrapper>
  );
};

AuthenticationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isLegalText: PropTypes.bool,
};

AuthenticationLayout.defaultProps = {
  isLegalText: false,
};

export default AuthenticationLayout;
