import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chapter } from 'types/Chapter';
import ModalConfig from 'types/ModalConfig';
import { usePrevious } from 'lib/customHooks';
import * as ModalTypes from 'constants/modalTypes';

import { fetchCCAResults } from 'store/ccaResults/actions';
import { sendChapterCompletedBookmark } from 'store/chapters/actions';
import { modalOpened } from 'store/ui/actions';
import { currentOpenModalsSelector } from 'store/ui/selectors';
import { userFullNameSelector } from 'store/user/selectors';
import {
  ccaResultsDataSelector,
  ccaResultsErrorSelector,
  isFetchingCCAResultsSelector,
} from 'store/ccaResults/selectors';
import {
  chaptersErrorSelector,
  isSendingCompletedBookmarkSelector,
} from 'store/chapters/selectors';

import CCAResultsChapter from './CCAResultsChapter';

type CCAResultsChapterContainerProps = {
  chapter: Chapter;
  goToNextChapter: () => void;
};

const CCAResultsChapterContainer = ({
  chapter,
  goToNextChapter,
}: CCAResultsChapterContainerProps) => {
  const { id, userMetadata } = chapter;
  const dispatch = useDispatch();

  const bookmarkError = useSelector(chaptersErrorSelector);
  const currentOpenModals = useSelector(currentOpenModalsSelector);
  const fetchError = useSelector(ccaResultsErrorSelector);
  const isFetchingResults = useSelector(isFetchingCCAResultsSelector);
  const isSendingCompletedBookmark = useSelector(isSendingCompletedBookmarkSelector);
  const results = useSelector(ccaResultsDataSelector);
  const userFullName = useSelector(userFullNameSelector);

  const { current: isFirstTimeView } = useRef(!Object.keys(userMetadata).length || !userMetadata.isStarted);

  useEffect(() => {
    dispatch(fetchCCAResults({ chapterId: id, isFirstTimeView }));
  }, []);

  const isDownloadModalOpen = currentOpenModals.some((modal: ModalConfig) => modal.modalType === ModalTypes.DOWNLOAD_CCA_RESULTS);
  const prevIsDownloadModalOpen = usePrevious(isDownloadModalOpen);
  useEffect(() => {
    if (prevIsDownloadModalOpen && !isDownloadModalOpen) {
      const hasCompletedChapter = Object.keys(userMetadata).length > 0 && userMetadata.isCompleted;
      if (!hasCompletedChapter) dispatch(sendChapterCompletedBookmark({ chapterId: id }));
      else goToNextChapter();
    }
  }, [prevIsDownloadModalOpen, isDownloadModalOpen]);

  const prevIsSendingCompletedBookmark = usePrevious(isSendingCompletedBookmark);
  useEffect(() => {
    if (prevIsSendingCompletedBookmark && !isSendingCompletedBookmark && !bookmarkError) goToNextChapter();
  }, [prevIsSendingCompletedBookmark, isSendingCompletedBookmark, bookmarkError]);

  const handleContinue = () => {
    dispatch(modalOpened({ modalType: ModalTypes.DOWNLOAD_CCA_RESULTS }));
  };

  return (
    <CCAResultsChapter
      error={fetchError || bookmarkError}
      isFetchingResults={isFetchingResults}
      isFirstTimeView={isFirstTimeView}
      isSendingCompletedBookmark={isSendingCompletedBookmark}
      onContinue={handleContinue}
      results={results}
      userFullName={userFullName}
    />
  );
};

export default CCAResultsChapterContainer;
