import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import validator from 'lib/validation/validator';

import StyledRouterLink from 'styles/components/StyledRouterLink';
import {
  Form,
  PromptText,
  TextFieldWrapper,
  Title,
} from '../style';
import {
  CTAWrapper,
  LegalText,
} from './style';

const MagicLinkLogin = ({
  email,
  isLoading,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Title>{t('auth:magicLinkLogin.title')}</Title>
      <PromptText>{t('auth:magicLinkLogin.prompt')}</PromptText>
      <Form onSubmit={onSubmit}>
        <TextFieldWrapper>
          <TextField
            labelText={t('auth:magicLinkLogin.emailLabel')}
            name="email"
            onChange={onChange}
            tid="loginFieldId"
            validate={validator('email')}
            value={email}
          />
        </TextFieldWrapper>
        <LegalText>
          <Trans i18nKey="auth:legalText">
            By continuing, you agree to BiasSync&apos;s <StyledRouterLink data-testid="privacyPolicyLinkId" target="_blank" to="/privacy">Privacy Policy</StyledRouterLink>
          </Trans>
        </LegalText>
        <CTAWrapper>
          <StyledButton tid="submitButtonId" full onClick={onSubmit} loading={isLoading}>
            {t('auth:magicLinkLogin.cta')}
          </StyledButton>
        </CTAWrapper>
      </Form>
    </>
  );
};

MagicLinkLogin.propTypes = {
  email: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MagicLinkLogin;
