import React, { useState } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation } from 'react-i18next';

import * as ToastTypes from 'constants/toastTypes';
import { INVALID_PASSWORD } from 'lib/validation/errorTypes';
import { regexes } from 'lib/validation/validator';

import ResetPassword from './ResetPassword';

export const ResetPasswordContainer = ({
  didResetPassword,
  isLoading,
  onSubmit,
  toggleToast,
  token,
  tracking: { trackEvent },
}) => {
  const { t } = useTranslation(['auth', 'errors']);

  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const [values, setValues] = useState({});
  const { confirmPassword, password } = values;

  const handleChange = event => {
    const { name, value } = event.target;

    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));

    if (!doPasswordsMatch) setDoPasswordsMatch(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    trackEvent({ event: 'Reset Password Button Clicked' });

    if (!doPasswordsMatch) toggleToast({ message: t('errors:passwordMismatch'), type: ToastTypes.TOAST_ERROR });
    else if (password && regexes[INVALID_PASSWORD].test(password)) onSubmit({ password, token });
    else toggleToast({ message: t('errors:invalidPassword'), type: ToastTypes.TOAST_ERROR });
  };

  const checkPasswordsMatch = () => {
    if (!confirmPassword || !password) return;

    if (confirmPassword !== password) setDoPasswordsMatch(false);
    else if (!doPasswordsMatch) setDoPasswordsMatch(true);
  };

  return (
    <ResetPassword
      didResetPassword={didResetPassword}
      doPasswordsMatch={doPasswordsMatch}
      isLoading={isLoading}
      onChange={handleChange}
      onPasswordFieldBlur={checkPasswordsMatch}
      onSubmit={handleSubmit}
      t={t}
      values={values}
    />
  );
};

ResetPasswordContainer.propTypes = {
  didResetPassword: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleToast: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

ResetPasswordContainer.defaultProps = {
  tracking: null,
};

export default track({
  page: 'Reset Password',
})(ResetPasswordContainer);
