import {
  createAsyncAction,
  createAsyncTypes,
} from 'lib/reduxUtils';

// Async
export const FETCH_USER = createAsyncTypes('user/fetchUser');
export const fetchUser = createAsyncAction(FETCH_USER);

export const DELETE_USER = createAsyncTypes('user/deleteUser');
export const deleteUser = createAsyncAction(DELETE_USER);

export const DOWNLOAD_USER = createAsyncTypes('user/downloadUser');
export const downloadUser = createAsyncAction(DOWNLOAD_USER);

export const EDIT_USER = createAsyncTypes('user/editUser');
export const editUser = createAsyncAction(EDIT_USER);

export const EDIT_USER_LANGUAGE = createAsyncTypes('user/editUserLanguage');
export const editUserLanguage = createAsyncAction(EDIT_USER_LANGUAGE);

export const SUBMIT_ACCESSIBILITY_CHECK = createAsyncTypes('user/submitAccessibilityCheck');
export const submitAccessibilityCheck = createAsyncAction(SUBMIT_ACCESSIBILITY_CHECK);

export const SUBMIT_POLICY_CONSENT = createAsyncTypes('user/submitPolicyConsent');
export const submitPolicyConsent = createAsyncAction(SUBMIT_POLICY_CONSENT);

export const LANGUAGE_CHANGED = createAsyncTypes('user/languageChanged');
export const languageChanged = createAsyncAction(LANGUAGE_CHANGED);
