import styled from 'styled-components';

import { colors, media, type } from 'styles';

interface CompletionWrapperProps {
  readonly hasAnswers: Boolean;
}

export const CompletionWrapper = styled.div<CompletionWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${p => (p.hasAnswers ? '15% 20px' : '20px')};
  min-height: 100vh;
`;

export const ThankYouMessage = styled.div`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  text-align: center;
  ${media.min.baseline`font-size: 3rem;`}
`;

export const AnswerSection = styled.div`
  width: 85%;
  max-width: 900px;
  padding: 38px 0 48px;

  ${media.min.baseline`padding: 38px 0 66px;`}
`;

export const AnswerSectionMessage = styled.p`
  margin: 0 0 54px;
  font-size: 1.6rem;
  text-align: center;

  ${media.min.baseline`
    font-size: 1.8rem;
    margin: 0 0 66px;
  `}
`;

interface AnswerListItemProps {
  readonly isCorrect?: Boolean;
}

export const AnswerListItem = styled.li<AnswerListItemProps>`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 28px 34px 28px 26px;
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: ${p => (p.isCorrect ? colors.green.base : colors.red.base)};

  &:not(:first-child) { margin-top: 16px; }

  ${media.min.baseline`
    padding: 32px 38px 32px 28px;
    border-left-width: 10px;

    &:not(:first-child) { margin-top: 21px; }
  `}
`;

export const MatrixAnswerListItem = styled(AnswerListItem)`
  padding: 32px 38px;
  border-left: 0;
`;

export const AnswerList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const PromptText = styled.h3`
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  margin: 0 0 32px;

  ${media.min.baseline`
    font-size: 2rem;
    margin: 0 0 36px;
  `}

  ${MatrixAnswerListItem} & {
    font-size: 1.6rem;
    ${media.min.baseline`font-size: 1.8rem;`}
  }
  ${MatrixAnswerListItem} > & {
    font-size: 1.8rem;
    ${media.min.baseline`font-size: 2rem;`}
  }
`;

export const AnswerText = styled.p`
  font-size: 1.6rem;
  margin: 0;

  &:not(:first-child) { margin-top: 8px; }

  & > strong {
    font-weight: ${type.weight.bold};
  }

  ${media.min.baseline`
    font-size: 1.8rem;
    &:not(:first-child) { margin-top: 12px; }
  `}
`;

export const IconWrapper = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  vertical-align: text-top;

  ${media.min.baseline`
    margin-left: 12px;
    width: 22px;
    height: 22px;
  `}
`;

export const CTAWrapper = styled.div`
  margin-top: 40px;
`;
