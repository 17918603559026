const blurClassName = 'video-js-blur';

function getBigVideoButton() {
  return document.getElementsByClassName('vjs-big-play-button')[0];
}

export function hideVideoButton() {
  const bigVideoButton = getBigVideoButton();

  if (bigVideoButton) bigVideoButton.style.visibility = 'hidden';

  return null;
}

export function displayVideoButton() {
  // use visibility since using display will actually make the block show immediately
  const bigVideoButton = getBigVideoButton();

  if (bigVideoButton) bigVideoButton.style.visibility = 'visible';

  return null;
}

export function blurVideo(playerElement) {
  if (!playerElement) return null;

  playerElement.classList.add(blurClassName);
  return null;
}

export function disableBlurVideo(playerElement) {
  if (!playerElement) return null;

  playerElement.classList.remove(blurClassName);
  return null;
}
