import styled from 'styled-components';
import { colors, media } from 'styles';

export const TextInputPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.sierra.base};
  padding: 0 22px;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  ${media.min.baseline`
    padding: 0 36px;
    width: 70%;
  `}
`;
