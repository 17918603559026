import Debug from 'debug';
import {
  call,
  put,
} from 'redux-saga/effects';

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from 'constants/auth';

import {
  deleteCookie,
  getCookies,
  setCookie,
} from 'lib/cookies';
import { isValidTokens } from 'lib/jwt';
import * as Analytics from 'lib/analytics';
import * as Monitoring from 'lib/monitoring';

import { SUBMIT_POLICY_CONSENT } from 'store/user/actions';
import {
  REFRESH_ACCESS_TOKEN,
  restoreSession,
  terminateSession,
} from 'store/auth/actions';

const debug = Debug('biassync:lms:lib:sessionActions');

const SessionActions = {
  * checkPersistedSession() {
    debug('Checking for persisted session...');

    let session = null;
    let accessToken;
    let refreshToken;

    try {
      ({ accessToken, refreshToken } = yield call(
        getCookies,
        ACCESS_TOKEN_KEY,
        REFRESH_TOKEN_KEY,
      ));
    } catch (e) {
      debug('Error accessing session cookies', e);
    }

    if (accessToken && refreshToken) {
      if (isValidTokens(accessToken, refreshToken)) {
        session = { accessToken, refreshToken };
        debug('Persisted session found', session);

        yield put(restoreSession(session));
      } else {
        debug('Tokens are not valid or expired JWT token');
      }
    } else {
      debug('Persisted session not found');
    }

    return session;
  },
  * persistSession({ payload: session, type }) {
    debug('Persisting session', session);

    try {
      yield call(setCookie, ACCESS_TOKEN_KEY, session.accessToken);
      yield call(setCookie, REFRESH_TOKEN_KEY, session.refreshToken);
    } catch (e) {
      debug('Error persisting session', e);
    }

    if (type !== REFRESH_ACCESS_TOKEN.SUCCESS) {
      Analytics.trackUser(session.user);
      Monitoring.setUser(session.user);
    }
  },
  * terminateSession({ payload, type }) {
    if (type === SUBMIT_POLICY_CONSENT.SUCCESS && payload.isAccepted) return;

    debug('Terminating session');
    yield put(terminateSession());

    try {
      yield call(deleteCookie, ACCESS_TOKEN_KEY);
      yield call(deleteCookie, REFRESH_TOKEN_KEY);
    } catch (e) {
      debug('Error deleting session cookies', e);
    }

    Analytics.untrackUser();
    Monitoring.setUser(null);
  },
};

export default SessionActions;
