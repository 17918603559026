import styled from 'styled-components';

import { colors, media, type } from 'styles';

export const IATProgressIndicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.twoDimMin.tablet`flex-direction: column;`}
`;

export const BlockDot = styled.div`
  border-radius: 50%;
  font-size: .9rem;
  text-align: center;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  line-height: 17px;

  ${({ status }) => status === 'current' && `
    border: 1px solid ${colors.yellow.base};
    color: ${colors.yellow.base};
  `}

  ${({ status }) => status === 'default' && `
    border: 1px solid ${colors.ridge.muted};
    color: ${colors.ridge.muted};
  `}

  ${media.twoDimMin.tablet`
    font-size: 1rem;
    margin-top: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  `}

  ${media.twoDimMin.max`
    width: 24px;
    height: 24px;
    line-height: 24px;
  `}
`;

export const LabelText = styled.span`
  font-weight: ${type.weight.semiBold};
  color: ${colors.yellow.base};
  font-size: 1rem;
  letter-spacing: .2rem;
  text-transform: uppercase;

  ${media.twoDimMin.tablet`font-size: 1.1rem;`}
  ${media.twoDimMin.max`font-size: 1.2rem;`}
`;
