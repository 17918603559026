import Debug from 'debug';

import { DESKTOP, MOBILE, TABLET } from 'constants/deviceTypes';

const debug = Debug('biassync:lms:lib:deviceInformation');

export const getInputInterface = type => {
  switch (type) {
    case MOBILE:
    case TABLET:
      return 'tap';
    case DESKTOP:
      return 'keyboard';
    default:
      return null;
  }
};

export const getViewportSize = () => {
  try {
    return `${window.innerWidth}x${window.innerHeight}`;
  } catch (e) {
    debug('Error received from getViewportSize', e);
    return null;
  }
};
