import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CheckboxesLabel,
  ChooseOneLabel,
  Input,
  OtherChoiceOptionWrapper,
  SubmitButton,
  TextInput,
  TextInputPlaceholder,
  TextInputWrapper,
} from './style';

const OtherOption = ({
  defaultOptionText,
  id,
  isCheckboxesPromptType,
  isDisabled,
  isEditModeEnabled,
  isSelected,
  keyShortcut,
  name,
  onEditModeEnabled,
  onSubmit,
  onTextInputChange,
  textInput,
}) => {
  const { t } = useTranslation('courseViewer');
  const Label = isCheckboxesPromptType ? CheckboxesLabel : ChooseOneLabel;
  const inputType = isCheckboxesPromptType ? 'checkbox' : 'radio';

  return (
    <OtherChoiceOptionWrapper>
      {!isDisabled && isEditModeEnabled ? (
        <TextInputWrapper>
          <TextInput
            autoFocus
            id="editor-field-id"
            isValid={!!textInput}
            name="editor-field-id"
            onChange={onTextInputChange}
            type="text"
            value={textInput}
          />
          <TextInputPlaceholder>
            {t('courseViewer:survey.textAreaPlaceholder')}
          </TextInputPlaceholder>
          <SubmitButton onClick={onSubmit} />
        </TextInputWrapper>
      ) : (
        <>
          <Input
            checked={isSelected}
            id={id}
            disabled={isDisabled}
            name={name}
            onChange={onEditModeEnabled}
            type={inputType}
          />
          <Label
            htmlFor={id}
            isDisabled={isDisabled}
            isEditModeEnabled={isEditModeEnabled}
            isSelected={isSelected}
            keyShortcut={keyShortcut}
            onClick={onEditModeEnabled}
          >
            {textInput || defaultOptionText}
          </Label>
        </>
      )}
    </OtherChoiceOptionWrapper>
  );
};

OtherOption.propTypes = {
  defaultOptionText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isCheckboxesPromptType: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isEditModeEnabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  keyShortcut: PropTypes.string,
  name: PropTypes.string.isRequired,
  onEditModeEnabled: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTextInputChange: PropTypes.func.isRequired,
  textInput: PropTypes.string,
};

OtherOption.defaultProps = {
  keyShortcut: '',
  textInput: '',
};

export default OtherOption;
