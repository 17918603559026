import styled, { css } from 'styled-components';
import { colors, media, type } from 'styles';

import TransparentButton from 'styles/components/TransparentButton';

export const LEARN_MORE_TRANSITION_MS = 500;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 52px auto 20px;
  font-size: 1.6rem;
  padding: 24px 32px 14px;
  border-radius: 10px;
  text-align: center;
  background-color: ${colors.rocky.background};
  color: ${colors.sierra.base};

  ${media.twoDimMin.baseline`
    font-size: 1.8rem;
  `}
`;

export const ContentWrapper = styled.div`
  height: 0;
  overflow-y: scroll;
  transition: height 800ms;
  width: 80vw;
  margin: 20px auto;
  max-width: 1200px;

  ${p => p.isVisible && 'height: 500px;'}

  ${media.twoDimMin.desktop`width: 75vw;`}
  ${media.twoDimMin.max`width: 70vw;`}
`;

export const Button = styled(TransparentButton)`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${colors.blue.base};
  font-size: 1.6rem;
  padding: 8px 10px;
  margin: 6px auto 0;
  outline: none;

  &.focus-visible {
    border-radius: 9999px;
    box-shadow: ${colors.blue.light} 0px 0px 0px 2px;
  }

  ${media.twoDimMin.baseline`font-size: 1.8rem;`}
`;

export const CircleArrow = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  height: 40px;
  width: 40px;
  background-color: ${colors.blue.base};
  box-shadow: 0 40px 15px -15px ${colors.rocky.background},
    inset 0 -15px 0 -28px ${colors.rocky.background};
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-top: 3px solid ${colors.rocky.background};
    border-right: 3px solid ${colors.rocky.background};
    transition: ${LEARN_MORE_TRANSITION_MS}ms ease-in-out;

    ${media.twoDimMin.baseline`
      width: 14px;
      height: 14px;
    `}

    ${({ isArrowDown }) => (isArrowDown ? css`
        transform: rotate(-45deg);
        bottom: 12px;
        left: 14px;
        
        ${media.twoDimMin.baseline`
          bottom: 10px;
          left: 14px;
        `}
      ` : `
        transform: rotate(135deg);
        bottom: 15px;
        left: 14px;

        ${media.twoDimMin.baseline`
          bottom: 14px;
          left: 13px;
        `}
      `)}
    }
`;

export const ListDivider = styled.div`
  opacity: 0;
  border-bottom: 2px solid rgba(111, 125, 141, 0.2);
  transition: opacity ${LEARN_MORE_TRANSITION_MS}ms;

  ${p => p.isVisible && 'opacity: 1;'}
`;

// Learn More Content

export const Content = styled.div`
  font-size: 1.4rem;
  align-self: flex-start;
  line-height: 1.5;

  ${media.min.desktop`font-size: 1.6rem;`}
`;

export const SectionTitle = styled.span`
  display: block;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};
  margin-top: 24px;
  color: ${colors.yellow.light};

  ${media.min.desktop`
    margin-top: 36px;
    font-size: 2rem;
  `}
`;

export const SectionContent = styled.p`
  margin: 12px 0;
`;
