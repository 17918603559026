import styled, { css, keyframes } from 'styled-components';

import { colors, media, type } from 'styles';

import { CarouselDirection } from './types';

export const InformationCardCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.twoDimMin.tablet`
    justify-content: center;
    align-items: center;
  `}
`;

export const Carousel = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;

  ${media.twoDimMin.tablet`flex-grow: 0;`}
`;

type CarouselTrackProps = {
  xOffset: number;
};

export const CarouselTrack = styled.ol<CarouselTrackProps>`
  display: flex;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;

  transition: transform 0.5s ease-in-out;
  ${({ xOffset }) => `transform: translateX(${xOffset}%);`}

  ${media.twoDimMin.mobileLandscape`width: 85%;`}
  ${media.twoDimMin.baseline`width: 75%;`}
  ${media.twoDimMin.desktop`
    width: 60%;
    max-width: 850px;
  `}
`;

type CarouselItemProps = {
  isCurrent: boolean;
};

export const CarouselItem = styled.li<CarouselItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  text-align: center;
  padding: 32px 32px 0;
  opacity: ${({ isCurrent }) => isCurrent ? 1 : 0};
  transition: opacity 0.4s ease-in-out;

  ${media.twoDimMin.tablet`padding: 0;`}
`;

export const Heading = styled.h3`
  font-size: 2rem;
  font-weight: ${type.weight.bold};
  color: ${colors.yellow.base};
  margin: 0;

  ${media.twoDimMin.mobileLandscape`font-size: 2.4rem;`}
  ${media.twoDimMin.tablet`font-size: 2.8rem;`}
  ${media.twoDimMin.baseline`font-size: 3.2rem;`}
  ${media.twoDimMin.desktop`font-size: 3.6rem;`}
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  margin-top: 24px;
  line-height: 1.6;

  a {
    color: ${colors.blue.base};
    font-weight: 600;
    outline: none;
  }

  a:hover, a.focus-visible { text-decoration: underline; }

  ${media.twoDimMin.mobileLandscape`
    font-size: 1.6rem;
    margin-top: 24px;
  `}
  ${media.twoDimMin.tablet`
    font-size: 1.8rem;
    line-height: 1.8;
    margin-top: 32px;
  `}
  ${media.twoDimMin.baseline`
    font-size: 2.0rem;
    margin-top: 44px;
  `}
  ${media.twoDimMin.desktop`
    font-size: 2.2rem;
    margin-top: 48px;
  `}
`;

export const NavContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px;

  ${media.twoDimMin.tablet`
    width: 85%;
    margin-top: 88px;
    padding: 0;
  `}
  ${media.twoDimMin.baseline`
    width: 75%;
  `}
  ${media.twoDimMin.desktop`
    width: 60%;
    max-width: 850px;
  `}
`;

type ButtonProps = {
  direction: CarouselDirection;
  isCTA?: boolean;
};

const pointing = keyframes`
  0% {transform: translateX(0%)}
  50% {transform: translateX(25%)}
  100% {transform: translateX(0%)}
`;

export const Button = styled.button<ButtonProps>`
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  padding: 14px 18px;
  border-radius: 32px;
  background-color: ${colors.brandedGreen.focus};
  border: 0;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:disabled { visibility: hidden; }

  &.focus-visible {
    border-radius: 9999px;
    box-shadow: ${colors.blue.light} 0px 0px 0px 3px;
  }

  &:before, &:after {
    position: relative;
    color: ${({ isCTA }) => isCTA ? colors.sierra.base : colors.yellow.base};
    font-size: 2.4rem;
    line-height: 0;
    top: 2px;
  }

  ${({ isCTA }) => isCTA ? css`
    font-weight: ${type.weight.bold};
    background-color: ${colors.white};
    color: ${colors.sierra.base};

    &:after { animation: ${pointing} 1.5s infinite; }
  ` : `
    &:hover { background-color: ${colors.brandedGreen.dark}; }
  `}

  ${({ direction }) => direction === CarouselDirection.Previous && css`
    &:before {
      content: '←';
      margin-right: 10px;
    }
  `}

  ${({ direction }) => direction === CarouselDirection.Next && css`
    &:after {
      content: '→';
      display: inline-block;
      margin-left: 10px;
    }
  `}

  ${media.twoDimMin.mobileLandscape`
    font-size: 1.4rem;
    padding: 18px 24px;
    &:before, &:after { font-size: 2.8rem; }
  `}
  ${media.twoDimMin.baseline`
    font-size: 1.6rem;
    &:before, &:after { font-size: 3.2rem; }
  `}
  ${media.twoDimMin.desktop`font-size: 1.8rem;`}
`;

export const DotNavigationContainer = styled.ol`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${media.twoDimMin.tablet`bottom: 0;`}
`;

export const DotNavigationItem = styled.li`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  color: ${colors.ridge.muted};
  cursor: pointer;

  &:not(:first-child) { margin-left: 6px; }

  background-color: ${colors.cascade.base};
  &[aria-current="step"] { background-color: ${colors.yellow.base}; }

  ${media.twoDimMin.mobileLandscape`
    width: 8px;
    height: 8px;
    &:not(:first-child) { margin-left: 12px; }
  `}
  ${media.twoDimMin.baseline`
    width: 10px;
    height: 10px;
  `}
  ${media.twoDimMin.desktop`
    width: 12px;
    height: 12px;
  `}
`;
