import StyledExternalLink from 'styles/components/StyledExternalLink';

import {
  SectionContent,
  SectionTitle,
  StyledRedContent,
} from './style';

const MerckPolicy = () => (
  <>
    <SectionTitle firstChild>
      Description of the BiasSync, Bias Awareness Experience
    </SectionTitle>
    <SectionContent>
      As part of Merck & Co., Inc.’s commitment to driving cultural change through diversity and
      inclusion, the Company has contracted with a new partner BiasSync, to offer a new learning
      experience focused on increasing awareness of and mitigating biases. BiasSync will offer
      digital, risk-free assessment and learning experiences that enable participants to understand
      their behaviors, and how to initiate and measure behavioral changes. External research has
      shown the need to experience and practice diverse thinking and inclusive behaviors as being
      paramount to driving behaviors and cultural change.
    </SectionContent>
    <SectionContent>
      You have been invited to experience BiasSync’s experience. The experiences offered through
      BiasSync’s platform are completely voluntary for Merck employees with interest in these
      important topics.
    </SectionContent>

    <SectionTitle>What will my participation involve?</SectionTitle>
    <SectionContent>
      The experience should take between 1 hour and 45 minutes and 2 hours to complete. You will be
      presented with an introduction to bias, a bias assessment and learning experiences to follow.
      You may go through it at your own pace. At the end of your experience, you will be asked to
      respond to a short set of questions to help you express your opinions and provide valuable
      insights about this experience.
    </SectionContent>

    <SectionTitle>Are there any benefits to me?</SectionTitle>
    <SectionContent>
      Should you choose to participate, this learning experience will offer you a private and safe
      opportunity to self-reflect on your attitudes and behaviors towards bias, and how you can
      initiate change from within, and champion cultural change around you through a stronger
      commitment and demonstration of diversity and inclusion.
    </SectionContent>

    <SectionTitle>How will my confidentiality be protected?</SectionTitle>
    <SectionContent>
      We are fully committed to protecting your privacy and as such, this learning experience is
      completely voluntary. If you decline to participate in the experience, your decision will NOT
      affect your role or performance in any way. Any reports that are prepared using these results
      will use only aggregated data.
    </SectionContent>
    <SectionContent>
      While Merck will collect an initial list of employee names and emails as participants, your
      participation in the experience will be completely anonymous. BiasSync will partner with Merck
      to use Merck emails for communication purposes only so that your personal information is not
      collected or processed by their platform. Therefore, any work you do in the simulation will
      not be identifiable or include personal information. No individual data, names or feedback are
      ever reported or shared. For open text responses, take care not to identify yourself or
      others in your comments.
    </SectionContent>

    <SectionTitle>Who should I contact if I have questions?</SectionTitle>
    <SectionContent>
      For more information about Merck’s diversity and inclusion strategy, and this learning
      opportunity, please contact:
      {' '}
      <StyledRedContent>Christopher Cardarelli</StyledRedContent>
      , {' '}
      <StyledExternalLink href="mailto:christopher_cardarelli@merck.com">
        christopher_cardarelli@merck.com
      </StyledExternalLink>
      .
    </SectionContent>
    <SectionContent>
      For more information about how Merck uses personal information for employee-related purposes,
      including how to raise privacy questions or to understand more about how we support your data
      subject requests, please visit the
      {' '}
      <StyledExternalLink
        target="_blank"
        href="https://www.msdprivacy.com/wp-content/uploads/sites/4/2020/05/employment-notice.pdf"
      >
        Global Employment Privacy Notice
      </StyledExternalLink>
      {' '} and {' '}
      <StyledExternalLink
        target="_blank"
        href="https://www.contentcentralprod.com/dr/_RMUEDH6V6ZJUPMTLOX5AOUHX7Y/lastfinal/Global%20Privacy%20and%20Data%20Protection%20Policy%2013.2.pdf"
      >
        Corporate Policy 13.2, Global Privacy & Data Protection
      </StyledExternalLink>
      .
    </SectionContent>
  </>
);

export default MerckPolicy;
