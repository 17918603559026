import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  SUBMIT_POLICY_CONSENT,
  submitPolicyConsent,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:submitPolicyConsent');

export function* submitPolicyConsentSaga({ payload }) {
  const { isAccepted } = payload;
  debug('Saga called', isAccepted);

  try {
    yield call(API.sendPolicyConsent, decamelizeKeys({ isAccepted }));

    yield put(submitPolicyConsent.success({ isAccepted }));
  } catch (e) {
    debug('Saga error', e);
    yield put(submitPolicyConsent.error(e));
  }
}

export default function* submitPolicyConsentDefaultSaga() {
  yield takeLatest(SUBMIT_POLICY_CONSENT.PENDING, submitPolicyConsentSaga);
}
