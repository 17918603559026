import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import PageTitle from 'components/shared/PageTitle';
import RegistrationConflictRedirect from 'components/shared/RegistrationConflictRedirect';

import {
  CTAWrapper,
  Form,
  PromptText,
  TextFieldWrapper,
  Title,
} from '../style';

const RecoveryEmail = ({
  didSubmit,
  isLoading,
  isUserRegistrationConflictError,
  loginId,
  onChange,
  onSubmit,
  t,
}) => {
  let content;
  if (isUserRegistrationConflictError) {
    content = (
      <RegistrationConflictRedirect
        message={t('auth:recoveryEmail.redirectToRegisterMessage')}
        redirectPath="/register"
      />
    );
  } else {
    content = (
      <>
        <PageTitle subPageTitle={t('auth:recoveryEmail.title')} />
        {didSubmit ? (
          <>
            <Title>{t('auth:recoveryEmail.submitted.title')}</Title>
            <PromptText>{t('auth:recoveryEmail.submitted.body')}</PromptText>
          </>
        ) : (
          <>
            <Title>{t('auth:recoveryEmail.title')}</Title>
            <PromptText>{t('auth:recoveryEmail.prompt')}</PromptText>
            <Form onSubmit={onSubmit}>
              <TextFieldWrapper>
                <TextField
                  labelText={t('auth:loginIdLabel')}
                  name="loginId"
                  onChange={onChange}
                  tid="loginFieldId"
                  value={loginId}
                />
              </TextFieldWrapper>
              <CTAWrapper>
                <StyledButton tid="submitButtonId" full loading={isLoading} onClick={onSubmit}>
                  {t('continue')}
                </StyledButton>
              </CTAWrapper>
            </Form>
          </>
        )}
      </>
    );
  }

  return content;
};

RecoveryEmail.propTypes = {
  didSubmit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUserRegistrationConflictError: PropTypes.bool.isRequired,
  loginId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default RecoveryEmail;
