import { useTranslation } from 'react-i18next';

import FocusTrap from 'components/shared/FocusTrap';
import Popup from 'styles/components/Popup';
import SimpleButton from 'components/shared/SimpleButton';
import colors from 'styles/colors';

import SuccessMessage from '../SuccessMessage';
import {
  SuccessMessageWrapper,
  VideoTaskWrapper,
} from '../style';
import {
  CharacterCount,
  Footer,
  Label,
  TextArea,
} from './style';

const CHARACTER_LIMIT = 250;

type TextTaskProps = {
  isSubmitted: boolean;
  isSubmitting: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  prompt: string;
  userInput: string;
};

const TextTask = ({
  isSubmitted,
  isSubmitting,
  onInputChange,
  onSubmit,
  prompt,
  userInput,
}: TextTaskProps) => {
  const { t } = useTranslation('courseViewer');

  return !isSubmitted ? (
    <FocusTrap>
      <VideoTaskWrapper>
        <Popup role="dialog" aria-labelledby="taskPrompt">
          <form>
            <Label id="taskPrompt" htmlFor="taskTextArea">{prompt}</Label>
            <TextArea
              id="taskTextArea"
              maxLength={CHARACTER_LIMIT}
              minLength={1}
              onChange={onInputChange}
              placeholder={t('courseViewer:video.task.textPlaceholder')}
              value={userInput}
            />
            <Footer>
              <CharacterCount>{userInput.length}/{CHARACTER_LIMIT}</CharacterCount>
              <SimpleButton
                disabled={!userInput}
                focusOutlineColor={colors.yellow.base}
                isFadedWhenLoading={false}
                loading={isSubmitting}
                onClick={onSubmit}
              >
                {t('courseViewer:video.task.submitButtonText')}
              </SimpleButton>
            </Footer>
          </form>
        </Popup>
      </VideoTaskWrapper>
    </FocusTrap>
  ) : (
    <SuccessMessageWrapper>
      <SuccessMessage>
        {t('courseViewer:video.task.successMessage')}
      </SuccessMessage>
    </SuccessMessageWrapper>
  );
};

export default TextTask;
