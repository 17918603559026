import fetchDataForVideoOverlays from './fetchDataForVideoOverlays';
import fetchVideoCredentials from './fetchVideoCredentials';
import saveVideoUserMetadata from './saveVideoUserMetadata';
import handleVideoBookmarkInterval from './handleVideoBookmarkInterval';
import handleVideoSeeking from './handleVideoSeeking';
import handleVideoTimeUpdate from './handleVideoTimeUpdate';
import sendVideoEndBookmark from './sendVideoEndBookmark';
import sendVideoTaskAnswer from './sendVideoTaskAnswer';
import setVideoSource from './setVideoSource';
import videoTaskCompleted from './videoTaskCompleted';

export default [
  fetchDataForVideoOverlays,
  fetchVideoCredentials,
  handleVideoBookmarkInterval,
  handleVideoSeeking,
  handleVideoTimeUpdate,
  saveVideoUserMetadata,
  sendVideoEndBookmark,
  sendVideoTaskAnswer,
  setVideoSource,
  videoTaskCompleted,
];
