import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { surveyPromptType } from 'constants/propTypes';
import DropdownPrompt from './DropdownPrompt';

const DropdownPromptContainer = ({
  isActive,
  isDisabled,
  onChange,
  prompt,
}) => {
  const focusRef = useRef();

  let { promptOptions } = prompt;
  const lastItemIndex = promptOptions.length - 1;

  const lastItem = promptOptions[lastItemIndex];
  if (lastItem.isOther) promptOptions = promptOptions.slice(0, lastItemIndex);

  const options = promptOptions.map(option => ({
    label: option.optionText,
    value: option.id,
  }));

  useEffect(() => {
    if (!isDisabled && isActive && focusRef.current) focusRef.current.focus();
  }, [isActive]);

  const handleChange = optionId => {
    onChange(prompt.id, optionId);
  };

  const handleCreateChange = response => {
    const { id, subPromptId } = lastItem;

    onChange(id, { response, subPromptId });
    onChange(prompt.id, id);
  };

  return (
    <DropdownPrompt
      focusRef={focusRef}
      isCreatableDropdown={!!lastItem.isOther}
      isDisabled={isDisabled}
      onChange={handleChange}
      onCreateChange={handleCreateChange}
      options={options}
    />
  );
};

DropdownPromptContainer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
};

export default DropdownPromptContainer;
