import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';

import {
  SUBMIT_PASSWORD_RECOVERY,
  submitPasswordRecovery,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:submitPasswordRecovery');

export function* submitPasswordRecoverySaga({ payload: loginId }) {
  debug('Saga called', loginId);

  try {
    yield call(API.sendPasswordRecovery, loginId);

    yield put(submitPasswordRecovery.success());
  } catch (e) {
    debug('Saga error', e);

    yield put(submitPasswordRecovery.error(e));
  }
}

export default function* submitPasswordRecoveryDefaultSaga() {
  yield takeLatest(SUBMIT_PASSWORD_RECOVERY.PENDING, submitPasswordRecoverySaga);
}
