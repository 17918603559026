import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { textFieldThemeType } from 'constants/propTypes';
import mergeDeep from 'lib/mergeDeep';
import colors from 'styles/colors';

import TextField from './TextField';

const DEFAULT_THEME = {
  container: {
    background: 'transparent',
    border: {
      default: colors.rocky.base,
      error: colors.red.base,
    },
  },
  input: {
    color: {
      default: colors.sierra.base,
    },
  },
  label: {
    color: {
      default: colors.shasta.base,
      error: colors.red.base,
    },
  },
};

class TextFieldContainer extends Component {
  state = { hasError: false };

  constructor(props) {
    super(props);

    if (props.theme) this.theme = mergeDeep({}, DEFAULT_THEME, props.theme);
    else this.theme = DEFAULT_THEME;
  }

  handleBlur = event => {
    const { value } = event.target;

    if (this.props.validate) this.updateValidity(value);
    if (this.props.onBlur) this.props.onBlur(event);
  };

  handleChange = event => {
    if (this.state.hasError) this.setState({ hasError: false });

    this.props.onChange(event);
  };

  updateValidity = value => {
    const errorType = this.props.validate(value) || null;

    this.setState({ hasError: !!errorType });
  };

  render() {
    const {
      disabled,
      hasExternalError,
      labelText,
      name,
      tid,
      tooltipText,
      type,
      value,
    } = this.props;
    const { hasError } = this.state;

    return (
      <TextField
        disabled={disabled}
        hasError={hasError || hasExternalError}
        id={name}
        labelText={labelText}
        name={name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        theme={this.theme}
        tid={tid}
        tooltipText={tooltipText}
        type={type}
        value={value}
      />
    );
  }
}

TextFieldContainer.propTypes = {
  disabled: PropTypes.bool,
  hasExternalError: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  theme: textFieldThemeType,
  tid: PropTypes.string,
  tooltipText: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func,
  value: PropTypes.string.isRequired,
};

TextFieldContainer.defaultProps = {
  disabled: false,
  hasExternalError: false,
  onBlur: null,
  theme: null,
  tid: '',
  tooltipText: '',
  type: 'text',
  validate: null,
};

export default TextFieldContainer;
