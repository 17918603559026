import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  SectionContent,
  SectionList,
  SectionListItem,
  SectionTitle,
  SubSectionTitle,
} from './style';

const BiasSyncPrivacyPolicy = () => {
  useTranslation('privacyPolicy');

  return (
    <Trans i18nKey="privacyPolicy:content">
      <SubSectionTitle>Last updated: May 13, 2020</SubSectionTitle>
      <SectionContent>
        BiasSync is currently only available for sale to companies, nonprofits, governments or other
        employers—we’ll call them the “Sponsoring Organization”. We make strong privacy guarantees
        in our contract with your Sponsoring Organization. However, because BiasSync is committed to
        privacy and transparency, we’re also sharing this privacy policy for each individual
        participating in BiasSync. This Privacy Policy describes BiasSync’s policies and procedures
        on the collection, use, and disclosure of information.
      </SectionContent>

      <SectionTitle>We collect the following information</SectionTitle>
      <SectionContent>
        BiasSync attempts to minimize the personally identifiable information we collect. However,
        we do need to collect certain information to allow you to participate in BiasSync.
      </SectionContent>

      <SectionList>
        <SectionListItem>
          <SubSectionTitle>User Information. </SubSectionTitle>
          We need to collect a basic identity for you to use the system. Typically this is your name
          and the (work) email address associated with your account, but at some organizations this
          can include an employee id number or “tags” for things like department or office.
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Credentials & Security. </SubSectionTitle>
          We collect user credentials for you to log in. The exact credentials depend on the
          integration with your company’s system but are usually either a password or Single-Sign On
          (SSO) token. We may also log security information such as failed accesses and IP
          addresses.
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Usage data. </SubSectionTitle>
          As you complete the BiasSync program, we track your progress throughout the course. We
          store which parts you have completed and not completed. We may also collect general usage
          data such as IP address, browser type, browser version, the pages of our service that you
          visit, the time and date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data in order to better understand and improve the site.
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Assessments & Surveys. </SubSectionTitle>
          The BiasSync program contains assessments and surveys from which we collect the results.
          Upon completion, your results are anonymized (see Anonymization).
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Demographic Data. </SubSectionTitle>
          We ask for certain optional demographic information associated with racial identity,
          gender identity, and age. This information is dissociated with the personally identifiable
          information similarly to the assessments & surveys.
        </SectionListItem>
      </SectionList>

      <SectionTitle>Anonymization</SectionTitle>
      <SectionContent>
        When you reach the end of the program, we give you the option to send a copy of your results
        to a personal email address. After that, we ask you to press a button to complete the
        course. At that point, your personally identifiable information is separated from your
        assessment and survey results. This anonymizes them to a degree where even BiasSync
        employees can not discover your individual results.
      </SectionContent>
      <SectionContent>
        The anonymized, aggregated results can be shared with your company (in addition to
        BiasSync’s research partners and client success team responsible for helping your company
        analyze the anonymized, aggregated results).
      </SectionContent>

      <SectionTitle>How Information is Collected and Used </SectionTitle>
      <SectionContent>We collect this information through three mechanisms:</SectionContent>
      <SectionList>
        <SectionListItem>
          <SubSectionTitle>Information The Sponsoring Organization Provides. </SubSectionTitle>
          As part of onboarding the organization, they provide a list of participants along with
          user information.
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Information You Give Us. </SubSectionTitle>
          By going through BiasSync, you explicitly give us various information, such as survey
          answers.
        </SectionListItem>
        <SectionListItem>
          <SubSectionTitle>Automatic Information. </SubSectionTitle>
          But moving through the course, we collect data about how far you’ve made it. By
          participating in assessments you also generate data.
        </SectionListItem>
      </SectionList>

      <SectionTitle>Cookies</SectionTitle>
      <SectionContent>
        We use cookies to keep you logged in to the site so that you can stay authenticated and
        secure. This allows us to track your progress through the BiasSync course.
      </SectionContent>
      <SectionContent>
        We may also use a cookie from Segment to collect data to feed our internal analytics in some
        deployments.
      </SectionContent>
      <SectionContent>We currently have no other 3rd-party cookies in BiasSync.</SectionContent>

      <SectionTitle>Third-Party Access to Information</SectionTitle>
      <SectionContent>
        We try to minimize third-party access to any data collected by BiasSync. We do not share
        your data with any third party except for BiasSync’s research partners where we share only
        anonymized data with established research institutions and BiasSync’s service providers who
        manage data on our behalf.
      </SectionContent>
      <SectionContent>
        We use Segment as our internal service provider for logging product analytics data.
      </SectionContent>

      <SectionTitle>Retention of Data</SectionTitle>
      <SectionContent>
        Our retention of data is governed by our contract with your Sponsoring Organization.
        However, the only personally identifiable information we typically keep associated with a
        user after they complete the course is their work email, name, and completion status.
      </SectionContent>

      <SectionTitle>Business Transfers</SectionTitle>
      <SectionContent>
        If we are involved in a reorganization, merger, acquisition or sale of our assets, your
        information may be transferred as a part of that deal. We will notify your Sponsoring
        Organization of any such deal and manage data as outlined in our contract with them.
      </SectionContent>

      <SectionTitle>Children’s Privacy</SectionTitle>
      <SectionContent>
        Our services are not designed for and not marketed to people under 18. We do not knowingly
        collect or ask for information from minors. We do not knowingly allow minors to use our
        services. If you are a minor, please do not use our service or send us your information.
      </SectionContent>

      <SectionTitle>Updates</SectionTitle>
      <SectionContent>
        We may update this Privacy Policy from time to time by posting an updated Privacy Policy on
        the BiasSync website. You or your Sponsoring Organization’s continued use of BiasSync
        constitutes your agreement to the updated Privacy Policy on a prospective basis.
      </SectionContent>
    </Trans>
  );
};

export default BiasSyncPrivacyPolicy;
