import styled from 'styled-components';
import i18n from 'lib/i18n';
import checkmarkIcon from 'assets/checkmark-icon.svg';
import { colors, type, media } from 'styles';

export const SectionStatus = {
  COMPLETED: 'completed',
  CURRENT: 'current',
  DEFAULT: 'default',
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  background-color: ${colors.white};

  ${media.min.desktop`padding: 16px 0;`}
  ${media.min.max`padding: 18px 0;`}
`;

export const SectionList = styled.ol`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;

  &::before {
    position: absolute;
    content: "${() => i18n.t('courseViewer:survey.section')}";
    font-weight: ${type.weight.semiBold};
    color: ${colors.shasta.light};
    left: -80px;
  }
`;

export const SectionItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    display: block;
    flex-grow: 1;
    height: 2px;
    background-color: rgba(111, 125, 141, 0.2);
    margin: 0 8px;
    min-width: 30px;
    font-size: 1.2rem;

    ${media.min.tablet`
      min-width: 40px;
      font-size: 1.4rem;
    `}
    ${media.min.desktop`
      min-width: 50px;
      font-size: 1.6rem;
    `}
  }
`;

export const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${type.weight.semiBold};
  font-size: 1.2rem;
  position: relative;

  ${media.min.tablet`
    width: 30px;
    height: 30px;
    font-size: 1.4rem;
  `}

  ${p => p.status === SectionStatus.DEFAULT && `
    background-color: ${colors.white};
    border: 2px solid ${colors.rocky.base};
    color: ${colors.shasta.base};

    &::before {
      content: "${p.sectionNumber}";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}

  ${p => p.status === SectionStatus.CURRENT && `
    background-color: ${colors.brandedBlue.base};
    border: 2px solid ${colors.brandedBlue.base};
    color: ${colors.white};

    &::before {
      content: "${p.sectionNumber}";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}

  ${p => p.status === SectionStatus.COMPLETED && `
    background: #353F47 url(${checkmarkIcon}) center / 45% no-repeat;
  `}
`;
