import Config from 'config'; // eslint-disable-line

const parseSubdomain = (hostname, baseDomain) => {
  const subdomainRegex = new RegExp(`(?:([^.]+).)?${baseDomain}`);
  const subdomainMatch = hostname.match(subdomainRegex);

  return subdomainMatch && subdomainMatch[1];
};

export const getSubdomain = () => parseSubdomain(window.location.hostname, Config.baseDomain);
