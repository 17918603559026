const baseConfig = require('./base');

module.exports = {
  ...baseConfig,
  apiUrl: 'https://api.biassync.com',
  baseDomain: 'biassync.com',
  environment: 'prod',
  segmentKey: 'lN68TUBYVaDIYQQNUmhSi87v3xsiXWqH',
  supportedLanguages: [
    'en',
    'es',
  ],
};
