import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';

import { submitAccessibilityCheck } from 'store/user/actions';
import {
  isSubmittingAccessibilityCheckSelector,
} from 'store/user/selectors';

import Modal from 'components/shared/Modal';
import AccessibilityCheckModal from './AccessibilityCheckModal';

const mapStateToProps = state => ({
  isLoading: isSubmittingAccessibilityCheckSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitAccessibilityCheck,
};

export const AccessibilityCheckModalContainer = ({
  isLoading,
  onSubmit,
  tracking,
}) => {
  const handleSubmit = value => {
    onSubmit(value);
    tracking.trackEvent({ event: 'Accessibility Check Button Clicked' });
  };

  return (
    <Modal isRequired>
      <AccessibilityCheckModal
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

AccessibilityCheckModalContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
};

const trackedAccessibilityCheckModal = track({ module: 'AccessibilityCheckModal' }, {
  dispatchOnMount: () => ({ event: 'Accessibility Check Required View Shown' }),
})(AccessibilityCheckModalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedAccessibilityCheckModal);
