import PropTypes from 'prop-types';

import {
  Button,
  ButtonArrow,
  ButtonWrapper,
} from './style';

const SliderButton = ({
  isHidden,
  label,
  onClick,
  orientation,
}) => (
  <ButtonWrapper isHidden={isHidden}>
    <Button onClick={onClick}>
      <ButtonArrow direction={orientation} />
      <span>{label}</span>
    </Button>
  </ButtonWrapper>
);

SliderButton.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
};

export default SliderButton;
