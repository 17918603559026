import { VideoEventType } from './VideoEventType';

export enum VideoTaskSubType {
  MultipleChoiceFact = 'multiple_choice_fact',
  MultipleChoiceOpinion = 'multiple_choice_opinion',
  Text = 'text',
}

interface VideoTaskResponse {
  correctAnswer: string;
  isCorrect: boolean;
}

export interface VideoTaskEvent {
  id: string;
  isSubmitted?: boolean;
  eventSubtype: VideoTaskSubType;
  eventType: VideoEventType;
  prompt: string;
  stopPoint: number;
  taskResponse?: VideoTaskResponse | null;
}

export type MultipleChoiceOptions = {
  [key: string]: string;
};

export interface MultipleChoiceTaskEvent extends VideoTaskEvent {
  eventSubtype: VideoTaskSubType.MultipleChoiceFact | VideoTaskSubType.MultipleChoiceOpinion;
  options: MultipleChoiceOptions;
}

export interface MultipleChoiceFactTaskEvent extends MultipleChoiceTaskEvent {
  eventSubtype: VideoTaskSubType.MultipleChoiceFact;
}

export interface MultipleChoiceOpinionTaskEvent extends MultipleChoiceTaskEvent {
  eventSubtype: VideoTaskSubType.MultipleChoiceOpinion;
}

export const isMultipleChoiceTaskEvent = (
  taskEvent: VideoTaskEvent,
): taskEvent is MultipleChoiceTaskEvent => (
  taskEvent.eventSubtype === VideoTaskSubType.MultipleChoiceFact
  || taskEvent.eventSubtype === VideoTaskSubType.MultipleChoiceOpinion
);

export const isMultipleChoiceFactTaskEvent = (
  taskEvent: VideoTaskEvent,
): taskEvent is MultipleChoiceFactTaskEvent => (
  taskEvent.eventSubtype === VideoTaskSubType.MultipleChoiceFact
);

export const isMultipleChoiceOpinionTaskEvent = (
  taskEvent: VideoTaskEvent,
): taskEvent is MultipleChoiceOpinionTaskEvent => (
  taskEvent.eventSubtype === VideoTaskSubType.MultipleChoiceOpinion
);

export interface TextTaskEvent extends VideoTaskEvent {
  eventSubtype: VideoTaskSubType.Text;
}

export const isTextTaskEvent = (
  taskEvent: VideoTaskEvent,
): taskEvent is TextTaskEvent => (
  taskEvent.eventSubtype === VideoTaskSubType.Text
);
