import { SurveyPromptType } from './SurveyPromptType';

interface BaseSurveyPrompt {
  id: string;
  promptText: string;
  promptType: SurveyPromptType;
  hasNaOption?: boolean;
  isOptional?: boolean;
}

export type SurveySubPrompt = {
  id: string;
  promptText: string;
};

type SurveyPromptOption = {
  id: string;
  optionText: string;
};

export interface CheckboxesPrompt extends BaseSurveyPrompt {
  promptOptions: SurveyPromptOption[];
}

export interface ChooseOnePrompt extends BaseSurveyPrompt {
  promptOptions: SurveyPromptOption[];
}

export interface ConclusionPrompt extends BaseSurveyPrompt {
  buttonLabel: string;
  subPrompts: SurveySubPrompt[];
}

export interface DropdownPrompt extends BaseSurveyPrompt {
  promptOptions: SurveyPromptOption[];
}

export interface LikertPrompt extends BaseSurveyPrompt {
  imageUrl?: string;
  likertLabels?: string[];
}

export const isMatrixPrompt = (prompt: SurveyPrompt): prompt is MatrixPrompt => (
  prompt.promptType === SurveyPromptType.Matrix
);

export interface MatrixPrompt extends BaseSurveyPrompt {
  subPromptType: SurveyPromptType.Likert | SurveyPromptType.YesNo;
  subPrompts: SurveySubPrompt[];
}

export interface MessagePrompt extends BaseSurveyPrompt {
  buttonLabel: string;
  subPrompts: SurveySubPrompt[];
  imageUrl?: string;
}

export interface NumberPrompt extends BaseSurveyPrompt {
  characterLimit: number | null;
}

export interface ScalePrompt extends BaseSurveyPrompt {
  scaleMaxLabel: string | null,
  scaleMinLabel: string | null,
  scaleMax: number,
}

export interface TextAreaPrompt extends BaseSurveyPrompt {
  characterLimit: number | null;
}

export interface YesNoPrompt extends BaseSurveyPrompt {}

export type SurveyPrompt = (
  CheckboxesPrompt
  | ChooseOnePrompt
  | ConclusionPrompt
  | DropdownPrompt
  | LikertPrompt
  | MatrixPrompt
  | MessagePrompt
  | NumberPrompt
  | ScalePrompt
  | TextAreaPrompt
  | YesNoPrompt
);
