import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TouchScreenSupport extends Component {
  state = {
    innerHeight: null,
  };

  componentDidMount() {
    // For Android, we need to listen to the resize event to dynamically set the div height
    // when the browser nav bar goes away so that white space isn't shown
    if (this.props.isAndroid) window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    if (this.props.isAndroid) window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => this.setState({ innerHeight: window.innerHeight });

  render() {
    const { innerHeight } = this.state;
    const style = {
      height: (innerHeight && `${innerHeight}px`) || '100%',
    };

    return (
      <div style={style}>
        {this.props.children}
      </div>
    );
  }
}

TouchScreenSupport.propTypes = {
  children: PropTypes.node.isRequired,
  isAndroid: PropTypes.bool.isRequired,
};

export default TouchScreenSupport;
