import colors from 'styles/colors';

export enum ChevronDownVariant {
  Yellow = 'yellow',
  White = 'white',
}

type ChevronDownProps = {
  titleText?: string;
  variant?: ChevronDownVariant;
};

const variantFillColor = {
  [ChevronDownVariant.White]: colors.white,
  [ChevronDownVariant.Yellow]: colors.yellow.base,
};

const ChevronDown = ({
  titleText,
  variant = ChevronDownVariant.White,
}: ChevronDownProps) => (
  <svg width="100%" height="100%" viewBox="0 0 20 12" aria-labelledby="chevronDownArrowTitle">
    {titleText && <title id="chevronDownArrowTitle">{titleText}</title>}
    <path fill={variantFillColor[variant]} fillRule="evenodd" d="M10 8.077L18.077 0 20 1.923 10 11.927 0 1.923 1.923 0z" />
  </svg>
);

export default ChevronDown;
