import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { COMPLETED_STATUS, REPLAY_STATUS } from 'constants/courseStatusTypes';

import CourseStatusButton from 'components/shared/CourseStatusButton';

import TableOfContents from '../TableOfContents';

import {
  CourseLongDescription,
  CTAButtonWrapper,
  TabButton,
  TabContentWrapper,
  Tabs,
  TOCWrapper,
} from './style';

const OVERVIEW_TAB = 'OVERVIEW_TAB';
const DESCRIPTION_TAB = 'DESCRIPTION_TAB';

const CourseMenu = ({
  courseDescription,
  courseStatus,
  onChapterPosterStatusButtonClick,
  onChapterSelect,
}) => {
  const { t } = useTranslation('courseOverview');
  const [selectedTab, setSelectedTab] = useState(OVERVIEW_TAB);

  const handleTabSelect = tab => {
    if (selectedTab !== tab) setSelectedTab(tab);
  };

  return (
    <>
      <Tabs>
        <TabButton
          isSelected={selectedTab === OVERVIEW_TAB}
          onClick={() => handleTabSelect(OVERVIEW_TAB)}
        >
          {t('courseOverview:overviewTab')}
        </TabButton>
        <TabButton
          isSelected={selectedTab === DESCRIPTION_TAB}
          onClick={() => handleTabSelect(DESCRIPTION_TAB)}
        >
          {t('courseOverview:descriptionTab')}
        </TabButton>
      </Tabs>
      <TabContentWrapper>
        {selectedTab === OVERVIEW_TAB ? (
          <TOCWrapper>
            <TableOfContents onChapterSelect={onChapterSelect} />
          </TOCWrapper>
        ) : (
          <CourseLongDescription>
            {courseDescription}
          </CourseLongDescription>
        )}
      </TabContentWrapper>
      <CTAButtonWrapper>
        <CourseStatusButton
          onClick={onChapterPosterStatusButtonClick}
          status={courseStatus === COMPLETED_STATUS ? REPLAY_STATUS : courseStatus}
        />
      </CTAButtonWrapper>
    </>
  );
};

CourseMenu.propTypes = {
  courseDescription: PropTypes.string.isRequired,
  courseStatus: PropTypes.string.isRequired,
  onChapterPosterStatusButtonClick: PropTypes.func.isRequired,
  onChapterSelect: PropTypes.func.isRequired,
};

export default CourseMenu;
