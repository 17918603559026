import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import API from 'services/API';

import {
  REGISTER_USER,
  registerUser,
} from '../actions';

const debug = Debug('biassync:lms:store:auth:sagas:registerUser');

export function* registerUserSaga({ payload: registrationData }) {
  debug('Saga called');

  try {
    const response = yield call(API.register, decamelizeKeys(registrationData));

    debug('Successful registration', response);

    yield put(registerUser.success(response));
  } catch (e) {
    debug('Saga error', e);
    yield put(registerUser.error(e));
  }
}

export default function* registerUserDefaultSaga() {
  yield takeLatest(REGISTER_USER.PENDING, registerUserSaga);
}
