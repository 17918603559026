import { useTranslation, Trans } from 'react-i18next';

import adapterImage from 'assets/cca-profile-adapter.jpg';
import collaboratorImage from 'assets/cca-profile-collaborator.jpeg';
import explorerImage from 'assets/cca-profile-explorer.jpg';
import guardImage from 'assets/cca-profile-guard.jpg';
import maintainerImage from 'assets/cca-profile-maintainer.jpg';

import { Profile } from '../types';

import {
  AssessmentLabel,
  BadgeIcon,
  Container,
  ContentContainer,
  DetailsContainer,
  Image,
  ImageContainer,
  ProfileName,
  ProfileNameContainer,
  ProfileNameLabel,
  StyledDivider,
  UserFullName,
} from './style';

type ProfileHeroProps = {
  badgeUrl: string;
  profileName: Profile;
  userFullName: string;
};

const getImage = (name: Profile, imageAttribute: string) => {
  switch (name) {
    case Profile.Adapter:
      return <Image src={adapterImage} alt={imageAttribute} />;
    case Profile.Collaborator:
      return <Image src={collaboratorImage} alt={imageAttribute} />;
    case Profile.Explorer:
      return <Image src={explorerImage} alt={imageAttribute} />;
    case Profile.Guard:
      return <Image src={guardImage} alt={imageAttribute} />;
    case Profile.Maintainer:
      return <Image src={maintainerImage} alt={imageAttribute} />;
    default:
      return <Image src={collaboratorImage} alt={imageAttribute} />;
  }
};

export const ProfileHero = ({
  badgeUrl,
  profileName,
  userFullName,
}: ProfileHeroProps) => {
  const { t } = useTranslation('courseViewer');

  return (
    <Container>
      <StyledDivider />
      <ContentContainer>
        <div>
          <ProfileNameContainer>
            <Trans i18nKey={`courseViewer:ccaResults.profile.heading.${profileName}`}>
              <ProfileNameLabel>You are a</ProfileNameLabel>
              <ProfileName>Profile Type</ProfileName>
            </Trans>
          </ProfileNameContainer>
          <DetailsContainer>
            <BadgeIcon src={badgeUrl} alt={t(`courseViewer:ccaResults.profile.badgeAttribute.${profileName}`)} />
            <div>
              <Trans i18nKey="courseViewer:ccaResults.profile.headingLabel">
                <AssessmentLabel>Results for BiasSync</AssessmentLabel>
                <AssessmentLabel>CultureConnect Assessment™</AssessmentLabel>
              </Trans>
              <UserFullName>{userFullName}</UserFullName>
            </div>
          </DetailsContainer>
        </div>
      </ContentContainer>
      <ImageContainer>{getImage(profileName, t(`courseViewer:ccaResults.profile.imageAttribute.${profileName}`))}</ImageContainer>
    </Container>
  );
};

export default ProfileHero;
