import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';

import { skipIAT } from 'store/iat/actions';
import { isSubmittingSkipIATSelector } from 'store/iat/selectors';

import SkipIATOptionModal from './SkipIATOptionModal';

const mapStateToProps = state => ({
  isLoading: isSubmittingSkipIATSelector(state),
});

const mapDispatchToProps = {
  onSubmit: skipIAT,
};

export const SkipIATOptionModalContainer = ({
  isLoading,
  onClose,
  onSubmit,
  tracking,
}) => {
  const [isSkippingIAT, setIsSkippingIAT] = useState(false);

  const handleChange = (value) => {
    setIsSkippingIAT(value);
  };

  const handleSubmit = () => {
    tracking.trackEvent({ event: 'Confirm Skip IAT Submit Button Clicked', isSkippingIAT });

    if (isSkippingIAT) onSubmit();
    else onClose();
  };

  return (
    <SkipIATOptionModal
      isLoading={isLoading}
      isSkippingIAT={isSkippingIAT}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

SkipIATOptionModalContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tracking: TrackingPropType.isRequired,
};

const trackedSkipIATOptionModal = track({ module: 'SkipIATOptionModal' }, {
  dispatchOnMount: () => ({ event: 'Confirm Skip IAT Modal Shown' }),
})(SkipIATOptionModalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(trackedSkipIATOptionModal);
