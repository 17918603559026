import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  deviceTypeType,
  errorType,
  iatType,
  iatTestResultType,
} from 'constants/propTypes';

import SimpleMessageButtonView from 'components/shared/SimpleMessageButtonView';
import AccessibilitySkipButton from 'components/shared/AccessibilitySkipButton';
import ErrorView from 'components/shared/ErrorView';
import Loading from 'components/shared/Loading';
import ScreenReaderOnly from 'styles/components/ScreenReaderOnly';

import IATTestResult from './IATTestResult';
import IAT from './IAT';
import { ChapterWrapper } from './style';

const IATChapter = ({
  deviceType,
  error,
  hasPrevSeenResults,
  iat,
  iatResult,
  isAnyModalOpen,
  isCompleted,
  isDemoCourse,
  isLoading,
  isPostingBlockResponses,
  isSeparateResults,
  onContinue,
  onIATAssessmentTimerExceeded,
  onIATBlockCompleted,
  onIATBlockStarted,
  onRetryError,
  onSkipChapter,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);
  let content = null;

  if (isLoading) content = <Loading />;
  else if (isCompleted && isSeparateResults) {
    content = (
      <SimpleMessageButtonView
        buttonText={t('continue')}
        title={t('courseViewer:continueToNextChapter.testCompleted')}
        onContinue={onContinue}
      />
    );
  } else if (error) {
    content = (
      <ErrorView
        onRetryClick={error.retryAction ? onRetryError : null}
        text={error.message}
      />
    );
  } else if (iatResult) {
    content = (
      <IATTestResult
        deviceType={deviceType}
        result={iatResult}
        showUserResultsFirst={hasPrevSeenResults}
        onContinue={onContinue}
      />
    );
  } else if (iat) {
    content = (
      <IAT
        deviceType={deviceType}
        iat={iat}
        isAnyModalOpen={isAnyModalOpen}
        isPostingBlockResponses={isPostingBlockResponses}
        onBlockCompleted={onIATBlockCompleted}
        onIATAssessmentTimerExceeded={onIATAssessmentTimerExceeded}
        onBlockStarted={onIATBlockStarted}
      />
    );
  }

  return (
    <ChapterWrapper>
      {content}
      {!isDemoCourse && (
        <ScreenReaderOnly role="alert" aria-live="assertive">
          <AccessibilitySkipButton onClick={onSkipChapter} />
        </ScreenReaderOnly>
      )}
    </ChapterWrapper>
  );
};

IATChapter.propTypes = {
  deviceType: deviceTypeType.isRequired,
  error: errorType,
  hasPrevSeenResults: PropTypes.bool,
  iat: iatType,
  iatResult: iatTestResultType,
  isAnyModalOpen: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isDemoCourse: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPostingBlockResponses: PropTypes.bool.isRequired,
  isSeparateResults: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onIATAssessmentTimerExceeded: PropTypes.func.isRequired,
  onIATBlockCompleted: PropTypes.func.isRequired,
  onIATBlockStarted: PropTypes.func.isRequired,
  onRetryError: PropTypes.func,
  onSkipChapter: PropTypes.func.isRequired,
};

IATChapter.defaultProps = {
  error: null,
  hasPrevSeenResults: false,
  iat: null,
  iatResult: null,
  onRetryError: null,
};

export default IATChapter;
