import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PageTitle from 'components/shared/PageTitle';
import { courseType, errorType, userType } from 'constants/propTypes';
import { BASELINE_COURSE } from 'constants/courseTypes';
import { COMPLETED_STATUS } from 'constants/courseStatusTypes';

import CourseLayout from 'components/shared/CourseLayout';
import ErrorView from 'components/shared/ErrorView';
import chevronIcon from 'assets/breadcrumb-chevron.svg';

import CourseContent from './CourseContent';
import CourseMenu from './CourseMenu';
import CoursePreview from './CoursePreview';
import {
  BottomContent,
  BreadcrumbLink,
  Breadcrumbs,
  BreadcrumbsChevron,
  CourseContentWrapper,
  CourseOverviewWrapper,
  CoursePreviewWrapper,
  TopContent,
} from './style';

const CourseOverview = ({
  course,
  courseStatus,
  error,
  isBreadcrumbVisible,
  isCourseRepeatable,
  isLoading,
  onBreadcrumbClick,
  onChapterPosterClick,
  onChapterPosterStatusButtonClick,
  onChapterSelect,
  posterImageLink,
  progressPercentage,
  user,
}) => {
  const { t } = useTranslation(['common', 'courseOverview']);
  const isBaselineCourse = !!course && course.courseType === BASELINE_COURSE;
  const courseTitle = course && course.title;

  let content;
  if (error) content = <ErrorView text={error.message} />;
  else {
    content = (
      <CourseOverviewWrapper>
        <Breadcrumbs isVisible={isBreadcrumbVisible}>
          <BreadcrumbLink to="/courses" onClick={onBreadcrumbClick}>
            {t('myCourses')}
          </BreadcrumbLink>
          <BreadcrumbsChevron src={chevronIcon} alt="" />
          {course && course.title}
        </Breadcrumbs>
        <TopContent>
          <CoursePreviewWrapper>
            <CoursePreview
              imageUrl={posterImageLink}
              isAccessDisabled={!isCourseRepeatable && courseStatus === COMPLETED_STATUS}
              isLoading={isLoading || !course}
              onClick={onChapterPosterClick}
              status={courseStatus}
            />
          </CoursePreviewWrapper>
          <CourseContentWrapper>
            <CourseContent
              companyName={user && user.company.name}
              courseStatus={courseStatus}
              isLoading={isLoading || !course}
              progressPercentage={progressPercentage}
              shortDescription={course && course.shortDescription}
              shouldShowCompanyInfo={isBaselineCourse}
              title={courseTitle}
            />
          </CourseContentWrapper>
        </TopContent>
        {course && isBaselineCourse && (
          <BottomContent>
            <CourseMenu
              courseDescription={course.longDescription}
              courseStatus={courseStatus}
              onChapterSelect={onChapterSelect}
              onChapterPosterStatusButtonClick={onChapterPosterStatusButtonClick}
            />
          </BottomContent>
        )}
      </CourseOverviewWrapper>
    );
  }

  return (
    <>
      <PageTitle subPageTitle={courseTitle} />
      <CourseLayout hasLogoFooter={!isBaselineCourse && !error}>
        {content}
      </CourseLayout>
    </>
  );
};

CourseOverview.propTypes = {
  course: courseType,
  courseStatus: PropTypes.string.isRequired,
  error: errorType,
  isBreadcrumbVisible: PropTypes.bool.isRequired,
  isCourseRepeatable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onBreadcrumbClick: PropTypes.func.isRequired,
  onChapterPosterClick: PropTypes.func.isRequired,
  onChapterPosterStatusButtonClick: PropTypes.func.isRequired,
  onChapterSelect: PropTypes.func.isRequired,
  posterImageLink: PropTypes.string,
  progressPercentage: PropTypes.number,
  user: userType,
};

CourseOverview.defaultProps = {
  course: null,
  error: null,
  posterImageLink: null,
  progressPercentage: null,
  user: null,
};

export default CourseOverview;
