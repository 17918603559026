import React from 'react';
import PropTypes from 'prop-types';

import { textFieldThemeType } from 'constants/propTypes';

import Tooltip from 'styles/components/Tooltip';

import {
  Input,
  Label,
  TooltipButton,
  TooltipWrapper,
  Wrapper,
} from './style';

const TextField = ({
  disabled,
  hasError,
  labelText,
  name,
  onBlur,
  onChange,
  theme,
  tid,
  tooltipText,
  type,
  value,
}) => (
  <Wrapper hasError={hasError} theme={theme}>
    <Input
      id={name}
      disabled={disabled}
      data-testid={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      theme={theme}
      type={type}
      value={value}
      required
    />
    <Label
      data-testid={tid}
      htmlFor={name}
      hasError={hasError}
      theme={theme}
    >
      {labelText}
    </Label>
    {tooltipText && (
      <>
        <TooltipButton type="button">?</TooltipButton>
        <TooltipWrapper>
          <Tooltip
            arrowHorizontalLocation="right"
            arrowVerticalLocation="top"
            showArrow
            variantColor="yellow"
          >
            {tooltipText}
          </Tooltip>
        </TooltipWrapper>
      </>
    )}
  </Wrapper>
);

TextField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: textFieldThemeType.isRequired,
  tid: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TextField;
