import i18n from 'lib/i18n';

export const GENERIC_ERROR_MESSAGE_KEY = 'errors:defaultErrorMessage';
const CONNECTIVITY_ERROR_MESSAGE_KEY = 'errors:connectivityErrorMessage';
const USER_CHECK_EMAIL_ADDRESS_MESSAGE_KEY = 'errors:doubleCheckEmailMessage';

export const isHttpRequestError = error => !!error && !!error.config;

export const getHttpStatusCode = error => (
  (error.response && error.response.status) ?? null
);

export const is4XXError = error => (
  !!error
  && !!error.response
  && error.response.status >= 400
  && error.response.status < 500
);

export const is5XXError = error => (
  !!error
  && !!error.response
  && error.response.status >= 500
  && error.response.status < 600
);

export const isUserRegistrationConflictError = error => (
  !!error
  && !!error.response
  && error.response.status === 409
);

export const isUserAutoAnonymizedError = error => (
  !!error
  && !!error.response
  && error.response.status === 410
);

export const getErrorMessage = (error, customMessagesByStatusCode = {}) => {
  let errorMessage = i18n.t(GENERIC_ERROR_MESSAGE_KEY);

  if (error.isConnectivityError) {
    errorMessage = i18n.t(CONNECTIVITY_ERROR_MESSAGE_KEY);
  } else if (error.response) {
    const { data, status } = error.response;

    if (customMessagesByStatusCode[status]) errorMessage = customMessagesByStatusCode[status];
    else if (status !== 500 && data.message) errorMessage = data.message;
  }

  return errorMessage;
};

export const getLoginRegisterErrorMessage = error => getErrorMessage(error, {
  404: i18n.t(USER_CHECK_EMAIL_ADDRESS_MESSAGE_KEY),
});

export const getSendIATBlockErrorMessage = error => getErrorMessage(error, {
  409: i18n.t(GENERIC_ERROR_MESSAGE_KEY),
});
