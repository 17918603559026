import styled from 'styled-components';

import { media, type } from 'styles';

import theme from './theme';

export const Container = styled.div`
  max-width: 1200px;
  padding: 0 40px 0 40px;

  color: ${theme.primary.web};
  ${media.print`color: ${theme.primary.print};`}

  ${media.min.tablet`padding: 0 50px;`}
  ${media.min.baseline`padding: 0 50px;`}
  ${media.min.desktop`padding: 0 80px;`}
  ${media.min.max`padding: 0 100px;`}
`;

export const Footer = styled.div`
  margin-top: 80px;
  width: 100%;
  position: relative;

  ${media.min.tablet`margin-top: 100px;`}
  ${media.min.baseline`margin-top: 120px;`}
  ${media.min.desktop`margin-top: 140px;`}
`;

export const SectionTitle = styled.h3`
  margin: 0 0 20px 0;
  font-size: 2.2rem;
  font-weight: ${type.weight.bold};
  text-transform: uppercase;

  color: ${theme.accent.web};
  ${media.print`color: ${theme.accent.print};`}

  ${media.min.tablet`font-size: 2.2rem;`}
  ${media.min.baseline`font-size: 2.4rem;`}
  ${media.min.desktop`font-size: 2.6rem;`}
  ${media.min.max`font-size: 2.8rem;`}
`;

export const SubSectionTitle = styled.h4`
  margin: 0 0 20px 0;
  font-size: 1.8rem;
  font-weight: ${type.weight.bold};

  color: ${theme.accent.web};
  ${media.print`color: ${theme.accent.print};`}

  ${media.min.tablet`font-size: 1.8rem;`}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.2rem;`}
  ${media.min.max`font-size: 2.4rem;`}
`;

export const Description = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0;

  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
`;

export const SectionContainer = styled.div`
  margin-top: 60px;

  ${media.min.baseline`margin-top: 72px;`}
  ${media.min.desktop`margin-top: 80px;`}
  ${media.min.max`margin-top: 88px;`}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  ${media.min.tablet`margin-top: 120px;`}
  ${media.min.baseline`margin-top: 140px;`}
  ${media.min.desktop`margin-top: 160px;`}
  ${media.print`display: none;`}
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 30px;
  ${media.min.desktop`height: 40px;`}
  ${media.min.max`height: 50px;`}
  ${media.print`& path.dynamic-fill { fill: ${theme.primary.print}; }`}
`;

