import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import authLockSVG from 'assets/auth-lock.svg';
import TextField from 'components/shared/TextField';
import StyledButton from 'components/shared/StyledButton';
import StyledRouterLink from 'styles/components/StyledRouterLink';

import {
  Form,
  LockIcon,
  PromptText,
  TextFieldWrapper,
} from '../style';
import {
  CTAWrapper,
  Heading,
  LegalText,
} from './style';

const AuthCodeLogin = ({
  authCode,
  isLoading,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <>
      <LockIcon src={authLockSVG} alt="" />
      <Heading>{t('auth:authCodeLogin.title')}</Heading>
      <PromptText>{t('auth:authCodeLogin.prompt')}</PromptText>
      <Form onSubmit={onSubmit}>
        <TextFieldWrapper>
          <TextField
            labelText={t('auth:authCodeLogin.authCodeLabel')}
            name="authCode"
            onChange={onChange}
            value={authCode}
          />
        </TextFieldWrapper>
        <LegalText>
          <Trans i18nKey="auth:legalText">
            By continuing, you agree to BiasSync&apos;s <StyledRouterLink target="_blank" to="/privacy">Privacy Policy</StyledRouterLink>
          </Trans>
        </LegalText>
        <CTAWrapper>
          <StyledButton full onClick={onSubmit} loading={isLoading}>
            {t('continue')}
          </StyledButton>
        </CTAWrapper>
      </Form>
    </>
  );
};

AuthCodeLogin.propTypes = {
  authCode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AuthCodeLogin;
