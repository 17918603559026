import PropTypes from 'prop-types';

import CheckmarkIcon from 'components/svg/CheckmarkIcon';

import {
  CheckmarkIconWrapper,
  ItemText,
  MenuItemButton,
  ResolutionItem,
  VideoResolutionListWrapper,
} from './style';

const VideoResolutionList = ({
  onItemClick,
  resolutions,
  selectedResolution,
  t,
}) => (
  <VideoResolutionListWrapper>
    {resolutions.map(resolution => {
      const currentResolution = selectedResolution === resolution;

      return (
        <MenuItemButton key={resolution} onClick={() => onItemClick(resolution)}>
          <ResolutionItem>
            <CheckmarkIconWrapper>
              <CheckmarkIcon
                titleText={currentResolution
                  ? t('courseViewer:settingsMenu.selectedResolution')
                  : t('courseViewer:settingsMenu.unselectedResolution')}
                variant={currentResolution ? 'green' : 'grey'}
              />
            </CheckmarkIconWrapper>
            <ItemText>{resolution}</ItemText>
          </ResolutionItem>
        </MenuItemButton>
      );
    })}
  </VideoResolutionListWrapper>
);

VideoResolutionList.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  resolutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedResolution: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default VideoResolutionList;
