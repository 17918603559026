/* eslint-disable @typescript-eslint/default-param-last */

import { getErrorMessage } from 'lib/errorHandler';

import {
  LOGIN_WITH_AUTH_CODE_ANONYMOUS,
  LOGIN_WITH_AUTH_CODE,
  LOGIN_WITH_MAGIC_LINK,
  LOGIN_WITH_PASSWORD,
  LOGIN_WITH_SSO,
  REGISTER_USER,
} from '../auth/actions';
import {
  DELETE_USER,
  DOWNLOAD_USER,
  EDIT_USER_LANGUAGE,
  EDIT_USER,
  FETCH_USER,
  LANGUAGE_CHANGED,
  SUBMIT_ACCESSIBILITY_CHECK,
  SUBMIT_POLICY_CONSENT,
} from './actions';

export const INITIAL_STATE = {
  isDeleting: false,
  isDownloading: false,
  isSubmittingAccessibilityCheck: false,
  isSubmittingPolicyConsent: false,
  isUpdating: false,
  isUpdatingLanguage: false,
  languageError: null,
  userData: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOGIN_WITH_AUTH_CODE.SUCCESS:
    case LOGIN_WITH_AUTH_CODE_ANONYMOUS.SUCCESS:
    case LOGIN_WITH_MAGIC_LINK.SUCCESS:
    case LOGIN_WITH_PASSWORD.SUCCESS:
    case LOGIN_WITH_SSO:
    case REGISTER_USER.SUCCESS:
      return {
        ...state,
        userData: payload.user,
      };
    case FETCH_USER.SUCCESS:
      return {
        ...state,
        userData: payload,
      };
    case SUBMIT_POLICY_CONSENT.PENDING:
      return {
        ...state,
        isSubmittingPolicyConsent: true,
      };
    case SUBMIT_POLICY_CONSENT.SUCCESS:
      return {
        ...state,
        isSubmittingPolicyConsent: false,
        userData: {
          ...state.userData,
          privacyPolicyType: null,
        },
      };
    case SUBMIT_POLICY_CONSENT.ERROR:
      return {
        ...state,
        isSubmittingPolicyConsent: false,
      };
    case SUBMIT_ACCESSIBILITY_CHECK.PENDING:
      return {
        ...state,
        isSubmittingAccessibilityCheck: true,
      };
    case SUBMIT_ACCESSIBILITY_CHECK.SUCCESS:
      return {
        ...state,
        isSubmittingAccessibilityCheck: false,
        userData: {
          ...state.userData,
          showAccessibilityCheck: false,
        },
      };
    case SUBMIT_ACCESSIBILITY_CHECK.ERROR:
      return {
        ...state,
        isSubmittingAccessibilityCheck: false,
      };
    case LANGUAGE_CHANGED.PENDING:
      return {
        ...state,
        isUpdatingLanguage: true,
        languageError: null,
      };
    case LANGUAGE_CHANGED.ERROR:
      return {
        ...state,
        isUpdatingLanguage: false,
        languageError: {
          message: getErrorMessage(payload),
          retryAction: action.originalAction,
        },
      };
    case LANGUAGE_CHANGED.SUCCESS:
      return {
        ...state,
        isUpdatingLanguage: false,
      };
    case EDIT_USER_LANGUAGE.SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...payload,
        },
      };
    case EDIT_USER.PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case EDIT_USER.ERROR:
      return {
        ...state,
        isUpdating: false,
      };
    case EDIT_USER.SUCCESS:
      return {
        ...state,
        isUpdating: false,
        userData: {
          ...state.userData,
          ...payload,
        },
      };
    case DELETE_USER.PENDING:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_USER.ERROR:
    case DELETE_USER.SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case DOWNLOAD_USER.PENDING:
      return {
        ...state,
        isDownloading: true,
      };
    case DOWNLOAD_USER.ERROR:
    case DOWNLOAD_USER.SUCCESS:
      return {
        ...state,
        isDownloading: false,
      };
    default:
      return state;
  }
};
