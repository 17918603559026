import Debug from 'debug';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Config from 'config'; // eslint-disable-line

const debug = Debug('biassync:lms:lib:monitoring');

if (Config.isMonitoringEnabled) {
  Sentry.init({
    dsn: Config.sentryDSNUrl,
    environment: Config.environment,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
}

export const captureSimpleException = (error) => {
  if (!Config.isMonitoringEnabled) return;
  Sentry.captureException(error);
};

export const captureException = (error, errorInfo) => {
  if (!Config.isMonitoringEnabled) return;

  try {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  } catch (e) {
    debug('Error attempting to capture exception', e);
  }
};

export const setUser = user => {
  if (!Config.isMonitoringEnabled) return;

  try {
    if (user) {
      Sentry.setUser({
        company_code: user.company.companyCode,
        company_id: user.company.id,
        email: user.email,
        id: user.id,
      });
    } else Sentry.setUser(null);
  } catch (e) {
    debug('Error attempting to set user', e);
  }
};
