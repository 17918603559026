export interface RawSurveyAnswer {
  id: string;
  response: boolean | string;
  correctAnswer: boolean | string;
}

export interface SimpleSurveyAnswer {
  id: string;
  promptText: string;
  responseText: string;
  correctAnswerText: string;
}

export const isMatrixSurveyAnswer = (answer: SurveyAnswer): answer is MatrixSurveyAnswer => (
  'subPrompts' in answer
);

export interface MatrixSurveyAnswer {
  id: string;
  promptText: string;
  subPrompts: SimpleSurveyAnswer[];
}

export type SurveyAnswer = SimpleSurveyAnswer | MatrixSurveyAnswer;
