import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const FileDownloadIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg id="fileDownloadIcon" aria-labelledby="fileDownloadIconTitle" width="100%" height="100%" viewBox="0 0 21 30" role="img" focusable="false">
      <title id="fileDownloadIconTitle">{t('tableOfContents.chapterType.fileDownload')}</title>
      <g fill="none" fillRule="evenodd" stroke={fill} strokeLinecap="round" strokeWidth="3">
        <path
          strokeLinejoin="bevel"
          d="M7.5 0C9.985 0 12 1.235 12 2.759V17.24C12 18.765 10.985 20 8.5 20 6.015 20 5 18.765 5 17.241V10"
          transform="translate(2 2)"
        />
        <path
          d="M7.286 0c-3.557 0-4.25 0-6.072 1.345C.7 1.725.325 2.505 0 3.586v17.792C0 25.552 4.082 26 8.5 26c2.862 0 5.296-.126 6.679-1.345.751-.662 1.821-1.559 1.821-3.138V9.862"
          transform="translate(2 2)"
        />
      </g>
    </svg>
  );
};

FileDownloadIcon.propTypes = {
  fill: PropTypes.string,
};

FileDownloadIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default FileDownloadIcon;
