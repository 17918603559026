import { createAction } from 'lib/reduxUtils';

export const CREATE_VIDEO_EVENT_ENTITIES = 'entities/videoEvents/createVideoEventEntities';
export const createVideoEventEntities = createAction(CREATE_VIDEO_EVENT_ENTITIES);

export const SET_VIDEO_EVENT_ENTITY_LOADING = 'entities/videoEvents/setVideoEventEntityLoading';
export const setVideoEventEntityLoading = createAction(SET_VIDEO_EVENT_ENTITY_LOADING);

export const SET_VIDEO_EVENT_ENTITY_NOT_LOADING = 'entities/videoEvents/setVideoEventEntityNotLoading';
export const setVideoEventEntityNotLoading = createAction(SET_VIDEO_EVENT_ENTITY_NOT_LOADING);

export const UPDATE_VIDEO_EVENT_ENTITY = 'entities/videoEvents/updateVideoEventEntity';
export const updateVideoEventEntity = createAction(UPDATE_VIDEO_EVENT_ENTITY);
