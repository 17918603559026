import styled from 'styled-components';
import flashingAnimation from 'styles/animations/flashing';
import { colors, media, type } from 'styles';

export const DesktopCTATextWrapper = styled.div`
  font-size: 1.6rem;

  ${media.twoDimMin.desktop`font-size: 1.8rem;`}
`;

export const TabletCTATextWrapper = styled.div`
  font-size: 1.2rem;
  font-weight: ${type.weight.semiBold};
  letter-spacing: 2px;
  text-transform: uppercase;

  ${({ flashing }) => flashing && flashingAnimation}
`;

export const KeyText = styled.span`
  display: inline-block;
  color: ${colors.white};
  background-color: ${colors.blue.base};
  font-weight: ${type.weight.bold};
  text-align: center;

  line-height: 30px;
  font-size: 1.6rem;
  border-radius: 4px;
  padding: 0 8px;

  ${({ flashing }) => flashing && flashingAnimation}

  ${media.twoDimMin.desktop`
    line-height: 36px;
    font-size: 1.8rem;
    border-radius: 4px;
    padding: 0 10px;
  `}
`;
