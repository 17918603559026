/* eslint-disable @typescript-eslint/default-param-last */

import {
  AICC_SESSION_DETECTED,
} from './actions';

export const INITIAL_STATE = {
  isAICCSession: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type } = action;

  switch (type) {
    case AICC_SESSION_DETECTED:
      return {
        ...state,
        isAICCSession: true,
      };
    default:
      return state;
  }
};
