import fetchIAT from './fetchIAT';
import fetchIATResult from './fetchIATResult';
import sendIATBlockResults from './sendIATBlockResults';
import skipIAT from './skipIAT';

export default [
  fetchIAT,
  fetchIATResult,
  sendIATBlockResults,
  skipIAT,
];
