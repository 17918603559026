import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import * as Analytics from 'lib/analytics';
import * as Monitoring from 'lib/monitoring';

import {
  FETCH_USER,
  fetchUser,
} from '../actions';

const debug = Debug('biassync:lms:store:user:sagas:fetchUser');

export function* fetchUserSaga() {
  debug('Saga called');

  try {
    const { user } = yield call(API.getUser);

    debug('User received', user);

    yield put(fetchUser.success(user));

    Analytics.trackUser(user);
    Monitoring.setUser(user);
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchUser.error(e));
  }
}

export default function* fetchUserDefaultSaga() {
  yield takeLatest(FETCH_USER.PENDING, fetchUserSaga);
}
