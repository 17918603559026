import styled from 'styled-components';
import { media, type } from 'styles';

export const Form = styled.form`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: ${type.weight.bold};
  margin-bottom: 20px;

  ${media.min.baseline`
    font-size: 2.8rem;
    margin-bottom: 32px;
  `}
`;

export const TextFieldWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 14px;
  }
`;

export const PromptText = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 22px;
  max-width: 100%;

  ${media.min.tablet`padding: 0 24px;`}
  ${media.min.baseline`font-size: 1.8rem;`}
`;

export const CTAWrapper = styled.div`
  margin-top: 24px;

  ${media.min.baseline`margin-top: 36px;`}
`;
