import { useState } from 'react';
import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useLocation } from 'react-router-dom';

import { MAGIC_LINK_TOKEN_PARAM } from 'constants/auth';
import { excludeQueryParams } from 'lib/queryParams';

import MagicLinkGenerated from './MagicLinkGenerated';
import MagicLinkLogin from './MagicLinkLogin';

export const MagicLinkLoginContainer = ({
  didGenerateMagicLink,
  isLoading,
  onSubmit,
  tracking: { trackEvent },
}) => {
  const [email, setEmail] = useState('');

  const location = useLocation();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    trackEvent({ event: 'Login Button Clicked' });

    const locationState = location.state;
    let returnUrl = null;

    if (locationState) {
      const { pathname, search } = locationState.from;
      const processedQueryParams = excludeQueryParams(search, [MAGIC_LINK_TOKEN_PARAM]);

      returnUrl = `${window.location.origin}${pathname}`;
      if (processedQueryParams) returnUrl += processedQueryParams;
    }

    onSubmit({
      email: email.trim(),
      returnUrl,
    });
  };

  return didGenerateMagicLink ? (
    <MagicLinkGenerated email={email} />
  ) : (
    <MagicLinkLogin
      email={email}
      isLoading={isLoading}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

MagicLinkLoginContainer.propTypes = {
  didGenerateMagicLink: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tracking: TrackingPropType,
};

MagicLinkLoginContainer.defaultProps = {
  tracking: null,
};

const trackedMagicLinkLoginContainer = track({ page: 'Magic Link Login' })(MagicLinkLoginContainer);

export default trackedMagicLinkLoginContainer;
