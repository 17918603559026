import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import { preloadImages } from '../utils';

import {
  FETCH_SURVEY,
  fetchSurvey,
} from '../actions';

const debug = Debug('biassync:lms:store:survey:sagas:fetchSurvey');

export function* fetchSurveySaga(action) {
  const { payload: { blockNumber, chapterId } } = action;
  debug('Saga called', blockNumber);

  try {
    const { survey } = yield call(API.getSurvey, chapterId, blockNumber);

    debug('Survey received', survey);

    const {
      previousItems,
      prompts,
      totalBlocks,
      totalItems,
    } = survey;

    yield call(preloadImages, prompts);

    yield put(fetchSurvey.success({
      metadata: {
        previousItems,
        totalBlocks,
        totalItems,
      },
      prompts,
    }));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchSurvey.error(e, action));
  }
}

export default function* fetchSurveyDefaultSaga() {
  yield takeLatest(FETCH_SURVEY.PENDING, fetchSurveySaga);
}
