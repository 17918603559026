import { createSelector } from 'reselect';

import * as ChapterType from 'constants/chapterTypes';
import { BASELINE_COURSE } from 'constants/courseTypes';
import {
  COMPLETED_STATUS,
  STARTED_STATUS,
  UNSTARTED_STATUS,
} from 'constants/courseStatusTypes';
import {
  calculateProgressPercentage,
  doesChapterShowAssessmentData,
} from 'lib/utils';

import { chaptersByCourseIdSelector } from 'store/entities/chapters/selectors';
import { courseEntitiesSelector } from 'store/entities/courses/selectors';
import { sectionsByCourseIdSelector } from 'store/entities/sections/selectors';

// Base selectors

export const coursePreviewsSelector = state => state.courses.coursePreviews;
export const currentCourseIdSelector = state => state.courses.currentCourseId;
export const isCoursesLoadingSelector = state => state.courses.isLoading;
export const coursesErrorSelector = state => state.courses.error;

// Composted selectors

const allChapterIdsSelector = createSelector(
  chaptersByCourseIdSelector,
  currentCourseIdSelector,
  (chaptersByCourseId, currentCourseId) => (
    (chaptersByCourseId[currentCourseId] && chaptersByCourseId[currentCourseId].allIds) || []
  ),
);

export const chaptersByIdSelector = createSelector(
  chaptersByCourseIdSelector,
  currentCourseIdSelector,
  (chaptersByCourseId, currentCourseId) => (
    (chaptersByCourseId[currentCourseId] && chaptersByCourseId[currentCourseId].byId) || {}
  ),
);

export const sectionsByIdSelector = createSelector(
  sectionsByCourseIdSelector,
  currentCourseIdSelector,
  (sectionsByCourseId, currentCourseId) => (
    (sectionsByCourseId[currentCourseId] && sectionsByCourseId[currentCourseId].byId) || {}
  ),
);

// Current course selectors

export const currentCourseSelector = createSelector(
  currentCourseIdSelector,
  courseEntitiesSelector,
  (currentCourseId, courseEntities) => courseEntities[currentCourseId],
);

export const currentCourseTitleSelector = createSelector(
  currentCourseSelector,
  currentCourse => currentCourse && currentCourse.title,
);

export const currentCourseUserMetadataSelector = createSelector(
  currentCourseSelector,
  currentCourse => currentCourse && currentCourse.userMetadata,
);

export const currentCourseTypeSelector = createSelector(
  currentCourseSelector,
  currentCourse => currentCourse && currentCourse.courseType,
);

export const isUserDataAnonymizedSelector = createSelector(
  currentCourseUserMetadataSelector,
  userMetadata => !!userMetadata && !!userMetadata.areResultsAnonymized,
);

export const currentCoursePosterLinkSelector = createSelector(
  currentCourseSelector,
  currentCourse => currentCourse && currentCourse.posterImageLink,
);

const currentCourseFeatureFlagsSelector = createSelector(
  currentCourseSelector,
  currentCourse => currentCourse && currentCourse.featureFlags,
);

// Current chapter selectors

export const currentChapterIdSelector = createSelector(
  currentCourseUserMetadataSelector,
  userMetadata => userMetadata && userMetadata.currentChapterId,
);

export const currentChapterSelector = createSelector(
  chaptersByIdSelector,
  currentChapterIdSelector,
  (chaptersById, currentChapterId) => currentChapterId && chaptersById[currentChapterId],
);

export const currentChapterPosterLinkSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.posterImageLink,
);

export const currentChapterTitleSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.title,
);

export const currentChapterNumberSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.absoluteIndex + 1,
);

export const currentChapterTypeSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.loType,
);

export const currentChapterFeatureFlagsSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.featureFlags,
);

export const currentChapterSubTypeSelector = createSelector(
  currentChapterSelector,
  currentChapter => currentChapter && currentChapter.subType,
);

const getUserMetadataByType = ({
  loType,
  userMetadata: {
    bookmark,
    furthestPoint,
    isCompleted,
  },
}) => {
  switch (loType) {
    case ChapterType.Video:
      return { bookmark, furthestPoint, isCompleted };
    default:
      return { isCompleted };
  }
};

export const currentChapterUserMetadataSelector = createSelector(
  currentChapterSelector,
  currentChapter => {
    if (!currentChapter) return null;

    return Object.keys(currentChapter.userMetadata).length > 0
      ? getUserMetadataByType(currentChapter)
      : null;
  },
);

// First chapter selectors

export const firstChapterIdSelector = createSelector(
  allChapterIdsSelector,
  allChapterIds => allChapterIds[0],
);

export const firstChapterSelector = createSelector(
  chaptersByIdSelector,
  firstChapterIdSelector,
  (chaptersById, firstChapterId) => chaptersById[firstChapterId],
);

export const firstChapterPosterLinkSelector = createSelector(
  firstChapterSelector,
  firstChapter => firstChapter && firstChapter.posterImageLink,
);

// Next chapter selectors

function findNextChapterId(
  chapterId,
  chapterIds,
  chaptersById,
  isUserDataAnonymized,
) {
  const chapterIndex = chapterIds.indexOf(chapterId);
  if (chapterIndex === -1 || chapterIndex === chapterIds.length - 1) {
    return null;
  }

  const nextChapterId = chapterIds[chapterIndex + 1];
  const nextChapter = chaptersById[nextChapterId];

  if (isUserDataAnonymized && doesChapterShowAssessmentData(nextChapter)) {
    return findNextChapterId(nextChapterId, chapterIds, chaptersById, isUserDataAnonymized);
  }

  return nextChapterId;
}

export const nextChapterIdSelector = createSelector(
  currentChapterIdSelector,
  allChapterIdsSelector,
  chaptersByIdSelector,
  isUserDataAnonymizedSelector,
  findNextChapterId,
);

export const nextChapterSelector = createSelector(
  chaptersByIdSelector,
  nextChapterIdSelector,
  (chaptersById, nextChapterId) => nextChapterId && chaptersById[nextChapterId],
);

export const nextChapterTitleSelector = createSelector(
  nextChapterSelector,
  nextChapter => nextChapter && nextChapter.title,
);

// Boolean selectors

export const isCourseCompletedSelector = createSelector(
  currentCourseUserMetadataSelector,
  userMetadata => !!userMetadata && userMetadata.isCompleted,
);

export const isBaselineCourseSelector = createSelector(
  currentCourseTypeSelector,
  courseType => courseType === BASELINE_COURSE,
);

export const isDemoCourseSelector = createSelector(
  currentCourseSelector,
  course => !!course && course.isDemo,
);

export const isCourseRepeatableSelector = createSelector(
  currentCourseSelector,
  course => !!course && course.isRepeatable,
);

export const doesCourseRepeatEphemeralEventsSelector = createSelector(
  currentCourseFeatureFlagsSelector,
  featureFlags => featureFlags?.ephemeralEventsRepeated ?? false,
);

export const hasUserStartedCourseSelector = createSelector(
  firstChapterSelector,
  firstChapter => !!firstChapter && firstChapter.userMetadata.isStarted,
);

export const isCurrentChapterCompletedSelector = createSelector(
  currentChapterUserMetadataSelector,
  userMetadata => (!!userMetadata && userMetadata.isCompleted) || false,
);

export const isCurrentChapterVideoSelector = createSelector(
  currentChapterSelector,
  currentChapter => !!currentChapter && currentChapter.loType === ChapterType.Video,
);

export const isCurrentChapterIATSelector = createSelector(
  currentChapterSelector,
  currentChapter => !!currentChapter && currentChapter.loType === ChapterType.IAT,
);

export const isIATChapterCombinedResultsSelector = createSelector(
  currentChapterFeatureFlagsSelector,
  isCurrentChapterIATSelector,
  (featureFlags, isIATChapter) => (
    isIATChapter && featureFlags && featureFlags.combinedIatResults),
);

//

export const currentCourseStatusSelector = createSelector(
  hasUserStartedCourseSelector,
  isCourseCompletedSelector,
  (hasUserStartedCourse, isCourseCompleted) => {
    let status = hasUserStartedCourse ? STARTED_STATUS : UNSTARTED_STATUS;
    if (isCourseCompleted) status = COMPLETED_STATUS;

    return status;
  },
);

export const processedCoursePreviewsSelector = createSelector(
  coursePreviewsSelector,
  chaptersByCourseIdSelector,
  (coursePreviews, chaptersByCourseId) => (coursePreviews ? coursePreviews.map(coursePreview => {
    const courseChapters = chaptersByCourseId[coursePreview.id];

    if (courseChapters && coursePreview.status !== COMPLETED_STATUS) {
      const firstChapterId = courseChapters.allIds[0];
      const firstChapter = courseChapters.byId[firstChapterId];
      const hasUserStartedCourse = Object.keys(firstChapter.userMetadata).length > 0;

      if (hasUserStartedCourse) {
        return {
          ...coursePreview,
          status: STARTED_STATUS,
        };
      }
    }

    return coursePreview;
  }) : null),
);

// Course overview selectors

export const courseOverviewPosterImageLinkSelector = createSelector(
  firstChapterPosterLinkSelector,
  currentChapterPosterLinkSelector,
  currentCoursePosterLinkSelector,
  currentCourseStatusSelector,
  currentChapterIdSelector,
  (
    firstChapterPosterLink,
    currentChapterPosterLink,
    currentCoursePosterLink,
    currentCourseStatus,
    currentChapterId,
  ) => {
    if (currentCourseStatus === UNSTARTED_STATUS) return currentCoursePosterLink;

    const hasRestartedCourse = currentCourseStatus === COMPLETED_STATUS && !!currentChapterId;
    if (currentCourseStatus === STARTED_STATUS || hasRestartedCourse) {
      return currentChapterPosterLink || firstChapterPosterLink;
    }

    return currentCoursePosterLink;
  },
);

export const courseOverviewProgressPercentageSelector = createSelector(
  currentCourseStatusSelector,
  currentChapterIdSelector,
  chaptersByIdSelector,
  (currentCourseStatus, currentChapterId, chaptersById) => {
    if (currentCourseStatus !== STARTED_STATUS || !currentChapterId) return null;

    const chapters = Object.values(chaptersById);
    const totalChapters = chapters.length;

    const completedChaptersCount = chapters
      .filter(chapter => (chapter.userMetadata && chapter.userMetadata.isCompleted === true))
      .length;

    return calculateProgressPercentage(completedChaptersCount, totalChapters);
  },
);
