import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const LikertItemWrapper = styled.div`
  opacity: ${({ isActive }) => (isActive ? '1' : '0.2')};
  transition: opacity 0.5s ease;
`;

export const Statement = styled.legend`
  width: 100%;
  font-size: 1.8rem;
  margin-bottom: 28px;
  font-weight: ${type.weight.bold};
  padding: 0 44px;
  text-align: center;

  ${media.min.tablet`
    margin-bottom: 32px;
  `}
  ${media.min.baseline`font-size: 2rem;`}
  ${media.min.desktop`font-size: 2.4rem;`}
`;

export const RatingWrapper = styled.div`
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  height: 100%;
  padding: 10px 10px 18px;

  ${media.min.desktop`font-size: 1.6rem;`}
  ${media.min.max`font-size: 1.8rem;`}
`;

export const KeyWrapper = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: ${colors.blue.base};
  border-radius: 6px;
  color: ${colors.white};
  background-color ${({ isSelected }) => (isSelected ? colors.ridge.base : colors.blue.base)};

  ${media.min.tablet`
    width: 35px;
    height: 35px;
    line-height: 35px;
  `}

  ${media.min.desktop`
    width: 40px;
    height: 40px;
    line-height: 40px;
  `}
`;

export const RatingText = styled.div`
  margin-top: 12px;
  color ${({ isSelected }) => (isSelected ? colors.sierra.base : colors.white)};

  ${media.min.tablet`margin-top: 18px;`}
`;

export const LikertScaleWrapper = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
`;

export const RatingItemWrapper = styled.li`
  list-style-type: none;
  display: inline-block;
  flex: 1;

  & + & { margin-left: 10px; }
`;
