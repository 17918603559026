/* eslint-disable react/no-did-update-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { withTranslation } from 'react-i18next';

import { normalizeKeyValue } from 'lib/utils';
import { deviceTypeType, iatType } from 'constants/propTypes';
import { MOBILE } from 'constants/deviceTypes';
import { ATTRIBUTES_INTRO, BLOCK_INTRO, CATEGORIES_INTRO, IAT_INTRO, TRIAL, getNextState } from 'constants/iatStates';

import IATIntroView from 'components/shared/IATIntroView';
import IATBlockSummary from 'components/shared/IATBlockSummary';
import IATCategorySummary from 'components/shared/IATCategorySummary';
import IATTrial from 'components/shared/IATTrial';
import IATMobileContainer from 'components/shared/IATMobileContainer';

import IATLoading from './IATLoading';

const SPACEBAR = ' ';
export const IAT_TIMEOUT_DURATION_MS = 15 * 60 * 1000;

@track({})
export class IAT extends Component {
  state = {
    currentBlockIndex: 0,
    currentTrialIndex: 0,
    error: null,
    iatState: IAT_INTRO,
    isBetweenTrials: false,
    isCountdownActive: false,
  };

  assessmentTimer = null;

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(_, prevState) {
    const { isBetweenTrials } = this.state;
    const { intertrialPause } = this.props.iat;

    if (!prevState.isBetweenTrials && isBetweenTrials) {
      setTimeout(() => {
        this.setState({ isBetweenTrials: false });
      }, intertrialPause);
    }

    if (prevState.isBetweenTrials && !isBetweenTrials) {
      this.handleTrialStart();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);

    if (this.assessmentTimer) clearTimeout(this.assessmentTimer);
  }

  @track((_, state) => ({
    blockNumber: state.currentBlockIndex + 1,
    event: 'IAT Block Started',
  }))
  handleBlockStart = () => {
    this.props.onBlockStarted();
    this.setState({ iatState: TRIAL, isCountdownActive: true });
    this.currentBlockResults = [];

    if (!this.assessmentTimer) {
      this.assessmentTimer = setTimeout(this.props.onIATAssessmentTimerExceeded, IAT_TIMEOUT_DURATION_MS);
    }
  };

  handleBlockCountdownDone = () => {
    this.setState({ isCountdownActive: false });
    this.handleTrialStart();
  };

  handleTrialStart = () => {
    this.startMarker = Date.now();
    this.currentTrialMarkers = [];
  };

  handleKeyUp = (event) => {
    const { iat, isAnyModalOpen, isPostingBlockResponses } = this.props;
    if (isAnyModalOpen) return;

    const { iatState } = this.state;
    const { left, right } = iat.keysConfig;
    const keyValue = normalizeKeyValue(event.key);

    switch (iatState) {
      case BLOCK_INTRO: {
        if (keyValue === SPACEBAR && !isPostingBlockResponses) {
          event.preventDefault();
          this.handleBlockStart();
        }
        break;
      }
      case TRIAL: {
        if ((keyValue === left || keyValue === right) && !isPostingBlockResponses) {
          event.preventDefault();
          this.handleUserChoice(keyValue);
        }
        break;
      }
      default: {
        if (keyValue === SPACEBAR) {
          event.preventDefault();
          this.advancePrompt();
        }
      }
    }
  };

  advancePrompt = () => {
    this.setState((state) => ({ iatState: getNextState(state.iatState) }));
  };

  handleUserChoice = (keyPressed) => {
    const { currentBlockIndex, currentTrialIndex, isCountdownActive } = this.state;
    const { blocks, keysConfig } = this.props.iat;

    if (isCountdownActive) return;

    const currentBlock = blocks[currentBlockIndex];
    const { correctKey, id: trialId, stimulusType } = currentBlock.trials[currentTrialIndex];

    this.currentTrialMarkers.push(Date.now() - this.startMarker);

    if (keyPressed === keysConfig[correctKey]) {
      this.handleCorrectUserChoice(trialId);
    } else {
      const incorrectKey = correctKey === 'left' ? 'right' : 'left';
      this.setState({
        error: {
          correctText: currentBlock[correctKey][stimulusType],
          incorrectKey,
          incorrectText: currentBlock[incorrectKey][stimulusType],
        },
      });
    }
  };

  handleCorrectUserChoice = (trialId) => {
    this.setState({ error: null });

    this.currentBlockResults.push({
      errors: this.currentTrialMarkers.length - 1,
      timeMarkers: this.currentTrialMarkers,
      totalTimeElapsed: this.currentTrialMarkers[this.currentTrialMarkers.length - 1],
      trialId,
    });

    this.advanceTrial();
  };

  advanceTrial = () => {
    const { currentBlockIndex, currentTrialIndex } = this.state;
    const { blocks } = this.props.iat;
    const currentBlock = blocks[currentBlockIndex];

    // Not the last trial in the current block
    if (currentTrialIndex < currentBlock.trials.length - 1) {
      this.setState((state) => ({
        currentTrialIndex: state.currentTrialIndex + 1,
        isBetweenTrials: true,
      }));
    } else {
      this.handleBlockCompleted();
    }
  };

  handleBlockCompleted = () => {
    const { currentBlockIndex } = this.state;
    const { iat } = this.props;

    this.props.onBlockCompleted({
      blockNumber: currentBlockIndex + 1,
      blockResults: this.currentBlockResults,
    });

    // Not the last block in the IAT
    if (currentBlockIndex < iat.blocks.length - 1) {
      this.setState((state) => ({
        currentBlockIndex: state.currentBlockIndex + 1,
        currentTrialIndex: 0,
        iatState: BLOCK_INTRO,
      }));
    }
  };

  render() {
    const { deviceType, iat, isPostingBlockResponses, t } = this.props;
    const { currentBlockIndex, currentTrialIndex, error, iatState, isBetweenTrials, isCountdownActive } = this.state;

    const { left, right, trials } = iat.blocks[currentBlockIndex];
    const { correctKey, stimulusContent, stimulusType } = trials[currentTrialIndex];
    let content;

    if (isPostingBlockResponses) {
      content = <IATLoading text={t('courseViewer:iat.submittingResponses')} />;
    } else if (iatState === IAT_INTRO) {
      content = (
        <IATIntroView
          description={iat.description}
          deviceType={deviceType}
          onContinue={this.advancePrompt}
          subtitle={iat.title}
          title={t('courseViewer:iat.introTitle')}
        />
      );
    } else if (iatState === CATEGORIES_INTRO || iatState === ATTRIBUTES_INTRO) {
      const key = iatState === CATEGORIES_INTRO ? 'categories' : 'attributes';
      content = (
        <IATCategorySummary
          deviceType={deviceType}
          onContinue={this.advancePrompt}
          stimuli={iat[key].stimuli}
          stimulusType={iat[key].stimulusType}
        />
      );
    } else if (iatState === BLOCK_INTRO) {
      content = (
        <IATBlockSummary
          currentBlock={currentBlockIndex + 1}
          deviceType={deviceType}
          keysConfig={iat.keysConfig}
          leftCategories={left}
          numOfBlocks={iat.blocks.length}
          onCTATap={this.handleBlockStart}
          rightCategories={right}
        />
      );
    } else {
      content = (
        <IATTrial
          correctKey={correctKey}
          deviceType={deviceType}
          error={error}
          isCountdownActive={isCountdownActive}
          isFirstBlock={currentBlockIndex === 0}
          keysConfig={iat.keysConfig}
          leftCategories={Object.values(left)}
          onCategoryClick={this.handleUserChoice}
          onCountdownDone={this.handleBlockCountdownDone}
          rightCategories={Object.values(right)}
          stimulusContent={!isBetweenTrials ? stimulusContent : ''}
          stimulusType={stimulusType}
        />
      );
    }

    return deviceType === MOBILE ? <IATMobileContainer>{content}</IATMobileContainer> : content;
  }
}

IAT.propTypes = {
  deviceType: deviceTypeType.isRequired,
  iat: iatType.isRequired,
  isAnyModalOpen: PropTypes.bool.isRequired,
  isPostingBlockResponses: PropTypes.bool.isRequired,
  onBlockCompleted: PropTypes.func.isRequired,
  onBlockStarted: PropTypes.func.isRequired,
  onIATAssessmentTimerExceeded: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('courseViewer')(IAT);
