import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  IATCategoryWrapper,
  Instruction,
  Key,
  KeyWrapper,
  Subtext,
  Value,
  ValuesWrapper,
} from './style';

const IATCategory = ({
  categoryValues,
  isErrored,
  isKeyHidden,
  keyValue,
  orientation,
}) => {
  const { t } = useTranslation('courseViewer');
  const instructionText = orientation === 'left'
    ? t('iat.category.leftInstruction', { keyValue })
    : t('iat.category.rightInstruction', { keyValue });

  return (
    <IATCategoryWrapper>
      <ValuesWrapper isErrored={isErrored} orientation={orientation}>
        <Value>{categoryValues[0]}</Value>
        {!!categoryValues[1] && (
          <>
            <Subtext>{t('iat.or')}</Subtext>
            <Value>{categoryValues[1]}</Value>
          </>
        )}
      </ValuesWrapper>
      {!isKeyHidden && (
        <KeyWrapper orientation={orientation}>
          <Key isErrored={isErrored}>{keyValue}</Key>
          <Instruction orientation={orientation}>
            {instructionText}
          </Instruction>
        </KeyWrapper>
      )}
    </IATCategoryWrapper>
  );
};

IATCategory.propTypes = {
  categoryValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  isErrored: PropTypes.bool.isRequired,
  isKeyHidden: PropTypes.bool,
  keyValue: PropTypes.string,
  orientation: PropTypes.oneOf(['left', 'right']).isRequired,
};

IATCategory.defaultProps = {
  isKeyHidden: false,
  keyValue: null,
};

export default IATCategory;
