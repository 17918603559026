import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { getNumberArrayWithLength } from 'lib/utils';
import { surveyPromptType } from 'constants/propTypes';

import RatingItem from '../../RatingItem';
import {
  BottomLegend,
  BottomLegendLabel,
  RatingItemWrapper,
  RatingWrapper,
  ScaleItemOptionsWrapper,
  Wrapper,
} from './style';

const ScalePrompt = ({
  isDisabled,
  onChange,
  prompt,
  response,
}) => {
  const { t } = useTranslation('courseViewer');

  const scaleMinLabel = prompt.scaleMinLabel || t('courseViewer:survey.scaleLegendLower');
  const scaleMaxLabel = prompt.scaleMaxLabel || t('courseViewer:survey.scaleLegendUpper');
  const scaleRatings = getNumberArrayWithLength(prompt.scaleMax);
  return (
    <Wrapper>
      <ScaleItemOptionsWrapper>
        {scaleRatings.map(rating => (
          <RatingItemWrapper key={rating}>
            <RatingItem
              id={`${prompt.id}_${rating}`}
              isDisabled={isDisabled}
              isSelected={response === rating}
              name={prompt.id}
              onChange={() => onChange(prompt.id, rating, true)}
              onClick={() => onChange(prompt.id, rating)}
            >
              <RatingWrapper>{rating}</RatingWrapper>
            </RatingItem>
          </RatingItemWrapper>
        ))}
      </ScaleItemOptionsWrapper>
      <BottomLegend>
        <BottomLegendLabel>
          {scaleMinLabel}
        </BottomLegendLabel>
        <BottomLegendLabel>
          {scaleMaxLabel}
        </BottomLegendLabel>
      </BottomLegend>
    </Wrapper>
  );
};

ScalePrompt.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  prompt: surveyPromptType.isRequired,
  response: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ScalePrompt.defaultProps = {
  response: null,
};

export default ScalePrompt;
