import styled from 'styled-components';
import { media, type } from 'styles';

export const Wrapper = styled.div`
  outline: 0; /* to remove blue outline when we focus on the multiselect wrapper */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  min-width: 324px;
  padding: 0 12px;

  ${media.min.baseline`width: max-content;`}
`;

export const ChooseOneOptionWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 14px 18px 14px 70px;
  background: ${p => p.theme.chooseOne.unselected.background};
  color: ${p => p.theme.chooseOne.unselected.color};
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.isDisabled ? 'none' : 'auto')};
  user-select: ${p => (p.isDisabled ? 'none' : 'auto')};
  font-size: 1.4rem;
  font-weight: ${type.weight.semiBold};
  outline: none;

  ${media.min.mobileLandscape`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.8rem;`}

  ${Input}:checked + & {
    background: ${p => p.theme.chooseOne.selected.background};
    color: ${p => p.theme.chooseOne.selected.color};
  }

  &:before {
    content: '';
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${p => p.theme.chooseOne.unselected.outerCircle.background};
  
    margin-right: 14px;
    ${media.min.mobileLandscape`margin-right: 16px;`}
    ${media.min.baseline`margin-right: 22px;`}

    ${Input}:checked + & {
      background: ${p => p.theme.chooseOne.selected.outerCircle.background};
    }
  }

  ${Input}.focus-visible + & {
    box-shadow: ${p => p.theme.chooseOne.focused.color} 0 0 0 3px;
  }

  ${Input}:checked + & {
    &:after {
      position: absolute;
      content: '';
      left: 24px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${p => p.theme.chooseOne.selected.innerCircle.background};
  }
`;
