import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { deviceTypeType } from 'constants/propTypes';
import tutorialGIF from 'assets/iat-tutorial.gif';

import IATPromptLayout from 'components/shared/IATPromptLayout';

import {
  Image,
  Paragraph,
} from './style';

const IATTutorialOutro = ({
  deviceType,
  onContinue,
}) => {
  const { t } = useTranslation('courseViewer');

  return (
    <IATPromptLayout
      deviceType={deviceType}
      onCTATap={onContinue}
      shouldCTAFlash
    >
      <Paragraph>
        {t('courseViewer:iatTutorial.outro.part1')}
      </Paragraph>
      <Image src={tutorialGIF} alt="" />
      <Paragraph>
        {t('courseViewer:iatTutorial.outro.part2')}
      </Paragraph>
    </IATPromptLayout>
  );
};

IATTutorialOutro.propTypes = {
  deviceType: deviceTypeType.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default IATTutorialOutro;
