import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
} from 'constants/accountDetailFields';

// Shared

export const isFieldFilled = value => typeof value !== 'undefined' && value !== '';

export const getEditedFields = (fields, initialFields) => {
  const editedFields = Object.keys(fields).reduce((acc, key) => {
    if (fields[key] !== initialFields[key]) acc[key] = fields[key];
    return acc;
  }, {});

  return Object.keys(editedFields).length ? editedFields : null;
};

// Account Details

export const requiredEditAccountDetailFields = [
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
];
