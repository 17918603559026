import React from 'react';
import PropTypes from 'prop-types';

import { deviceTypeType } from 'constants/propTypes';

import IATPromptLayout from '../IATPromptLayout';
import {
  Description,
  Subtitle,
  Title,
} from './style';

const IATIntroView = ({
  description,
  deviceType,
  onContinue,
  shouldCTAFlash,
  subtitle,
  title,
}) => (
  <IATPromptLayout
    deviceType={deviceType}
    onCTATap={onContinue}
    shouldCTAFlash={shouldCTAFlash}
  >
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <Description>{description}</Description>
  </IATPromptLayout>
);

IATIntroView.propTypes = {
  description: PropTypes.string.isRequired,
  deviceType: deviceTypeType.isRequired,
  onContinue: PropTypes.func.isRequired,
  shouldCTAFlash: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

IATIntroView.defaultProps = {
  shouldCTAFlash: false,
  subtitle: null,
};

export default IATIntroView;
