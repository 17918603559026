import styled, { css } from 'styled-components';

import { colors, media, type } from 'styles';
import Plane from 'types/Plane';

import theme from '../theme';

type OrientationProps = {
  orientation: Plane;
};

export const Figure = styled.figure`
  width: 100%;
  height: 100%;
  max-width: 850px;
  max-height: 600px;
  margin: 0;
`;

export const GraphContainer = styled.div<OrientationProps>`
  position: relative;
  display: flex;
  flex-direction: ${({ orientation }) => orientation === Plane.Horizontal ? 'row' : 'column'};
  align-items: center;
  height: 100%;
`;

export const AxisLabel = styled.label<OrientationProps>`
  position: absolute;
  color: ${colors.cascade.muted};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: visible;
  font-weight: ${type.weight.semiBold};

  font-size: 1.2rem;
  ${media.min.desktop`font-size: 1.4rem;`}
  ${media.min.max`font-size: 1.6rem;`}

  &:first-child {
    ${({ orientation }) => orientation === Plane.Horizontal ? css`
      left: 0;
      top: -28px;
      ${media.min.tablet`top: -30px;`}
      ${media.min.desktop`top: -33px;`}
    ` : css`
      bottom: -36px;
      ${media.min.tablet`bottom: -38px;`}
      ${media.min.desktop`bottom: -40px;`}
    `};
  }

  &:last-child {
    ${({ orientation }) => orientation === Plane.Horizontal ? css`
      right: 0;
      top: -28px;
      ${media.min.tablet`top: -30px;`}
      ${media.min.desktop`top: -33px;`}
    ` : css`
      top: -36px;
      ${media.min.tablet`top: -38px;`}
      ${media.min.desktop`top: -40px;`}
    `};
  }
`;

export const BarContainer = styled.div<OrientationProps>`
  ${({ orientation }) => orientation === Plane.Horizontal ? css`
    width: 100%;
    height: 18px;
    ${media.min.tablet`height: 20px;`}
    ${media.min.desktop`height: 26px;`}
  ` : css`
    height: 100%;
    width: 18px;
    ${media.min.tablet`width: 20px;`}
    ${media.min.desktop`width: 26px;`}
  `}
`;

export const BackgroundRect = styled.rect`
  fill: ${theme.graph.background.web};
  ${media.print`fill: ${theme.graph.background.print};`}
`;

export const ScoreRect = styled.rect`
  fill: ${theme.graph.foreground.web};
  ${media.print`fill: ${theme.graph.foreground.print};`}
`;

export const MarkerLine = styled.line`
  stroke: ${theme.graph.marker.web};
  ${media.print`stroke: ${theme.graph.marker.print};`}
`;

export const Caption = styled.figcaption`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
