export const asyncSuffixes = {
  ERROR: 'Error',
  PENDING: 'Pending',
  SUCCESS: 'Success',
};

export const actionTypeToString = type => {
  try {
    const w = type
      .split('/')
      .pop()
      .replace(/([A-Z][a-z]+|[A-Z]*(?![a-z]))/g, ' $1')
      .trim();

    return w.charAt(0).toUpperCase() + w.substr(1);
  } catch (e) {
    return type;
  }
};

export const createAsyncTypes = typeString => (
  Object.keys(asyncSuffixes).reduce((acc, suffixKey) => {
    const suffix = asyncSuffixes[suffixKey];

    return {
      [suffixKey]: `${typeString}${suffix}`,
      ...acc,
    };
  }, {})
);

export const createAction = (
  type,
  {
    descriptor,
    log = false,
  } = {},
) => (
  payload,
  originalAction,
) => ({
  descriptor: descriptor || actionTypeToString(type),
  log,
  payload,
  type,
  ...(originalAction && { originalAction }),
});

const defaultLogOptions = {
  ERROR: true,
  PENDING: false,
  SUCCESS: false,
};

export const createAsyncAction = (
  type,
  options = {},
) => {
  const logOptions = {
    ...defaultLogOptions,
    ...(options.log || {}),
  };

  return Object.assign(
    createAction(type.PENDING, { ...options, log: logOptions.PENDING }),
    {
      error: createAction(type.ERROR, { ...options, log: logOptions.ERROR }),
      success: createAction(type.SUCCESS, { ...options, log: logOptions.SUCCESS }),
    },
  );
};
