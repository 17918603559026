import { createSelector } from 'reselect';
import { currentCourseIdSelector } from 'store/courses/selectors';

export const videoEventsByCourseIdSelector = state => state.entities.videoEvents.byCourseId;

export const videoEventEntitiesSelector = createSelector(
  currentCourseIdSelector,
  videoEventsByCourseIdSelector,
  (currentCourseId, videoEventsByCourseId) => videoEventsByCourseId[currentCourseId] || {},
);
