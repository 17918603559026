import Debug from 'debug';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

import API from 'services/API';
import preloadImage from 'lib/preloadImage';

import {
  FETCH_IAT_RESULT,
  fetchIATResult,
} from '../actions';

const debug = Debug('biassync:lms:store:iat:sagas:fetchIATResult');

export function* fetchIATResultSaga(action) {
  debug('Saga called');
  const { payload: chapterId } = action;

  try {
    const { results: iatResult } = yield call(API.getIATResult, chapterId);

    debug('IAT result received', iatResult);

    try {
      yield Promise.all(Object.values(iatResult).map(result => preloadImage(result.graphUrl)));

      yield put(fetchIATResult.success(iatResult));
    } catch (e) {
      debug('Image preloading error', e);
      yield put(fetchIATResult.error(new Error('Invalid or missing IAT result images')));
    }
  } catch (e) {
    debug('API error', e);
    yield put(fetchIATResult.error(e, action));
  }
}

export default function* fetchIATResultDefaultSaga() {
  yield takeLatest(FETCH_IAT_RESULT.PENDING, fetchIATResultSaga);
}
