import PropTypes from 'prop-types';
import track, { TrackingPropType } from 'react-tracking';
import { useTranslation, Trans } from 'react-i18next';
import Config from 'config'; // eslint-disable-line

import AlertIcon from 'components/svg/AlertIcon';
import SimpleButton from 'components/shared/SimpleButton';

import {
  AlertIconWrapper,
  CTAWrapper,
  ErrorText,
  ErrorViewWrapper,
  ExternalLink,
  TechIssueLinkText,
} from './style';

const ErrorView = ({
  hasBackground,
  onRetryClick,
  text,
  tracking,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  const handleTechIssueLinkClick = () => tracking.trackEvent({ event: 'Tech Issue Link Clicked' });

  return (
    <ErrorViewWrapper hasBackground={hasBackground}>
      <AlertIconWrapper>
        <AlertIcon variant="yellow" />
      </AlertIconWrapper>
      <ErrorText>{text || t('errors:defaultErrorMessage')}</ErrorText>
      {onRetryClick && (
        <CTAWrapper>
          <SimpleButton onClick={onRetryClick} variant="white">
            {t('tryAgain')}
          </SimpleButton>
        </CTAWrapper>
      )}
      <TechIssueLinkText>
        <Trans i18nKey="errors:techIssueLinkMessage">
          Encountered a problem? Help us improve by
          <ExternalLink href={Config.techIssueUrl} target="_blank" onClick={handleTechIssueLinkClick}>
            reporting the issue
          </ExternalLink>
          .
        </Trans>
      </TechIssueLinkText>
    </ErrorViewWrapper>
  );
};

ErrorView.propTypes = {
  hasBackground: PropTypes.bool,
  onRetryClick: PropTypes.func,
  text: PropTypes.string,
  tracking: TrackingPropType,
};

ErrorView.defaultProps = {
  hasBackground: false,
  onRetryClick: null,
  text: null,
  tracking: null,
};

export default track(({ text }) => ({ errorMessage: text }), {
  dispatchOnMount: () => ({ event: 'Error View Shown' }),
})(ErrorView);
