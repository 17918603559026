import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'styles/colors';

const ResultsIcon = ({ fill }) => {
  const { t } = useTranslation('common');

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 95 95"
      role="img"
      focusable="false"
    >
      <title>{t('tableOfContents.chapterType.results')}</title>
      <path
        fill={fill}
        stroke={fill}
        strokeWidth="4"
        d="M85.534,23.618l-18.3-18.3A1.072,1.072,0,0,0,66.471,5H15.227a1.076,1.076,0,0,0-1.076,1.076V93.924A1.076,1.076,0,0,0,15.227,95H84.773a1.076,1.076,0,0,0,1.076-1.076V24.378A1.072,1.072,0,0,0,85.534,23.618ZM67.547,8.673,82.176,23.3H67.547ZM16.3,92.849V7.151H65.4V24.378a1.076,1.076,0,0,0,1.076,1.076H83.7v67.4Zm21.4-54A14.216,14.216,0,1,0,23.487,24.628,14.232,14.232,0,0,0,37.7,38.844Zm6.967-4.375-5.1-8.765H49.718A12.067,12.067,0,0,1,44.67,34.469Zm5.048-10.917H38.779V12.613A12.084,12.084,0,0,1,49.718,23.552ZM36.627,12.613V24.628a1.081,1.081,0,0,0,.146.541l6.039,10.385a12.063,12.063,0,1,1-6.185-22.941Zm-3.442,56.7H24.562a1.075,1.075,0,0,0-1.075,1.076V88.061a1.075,1.075,0,0,0,1.075,1.076h8.623a1.076,1.076,0,0,0,1.076-1.076V70.384A1.076,1.076,0,0,0,33.185,69.308ZM32.11,86.985H25.638V71.459H32.11ZM47.269,58.817H38.646a1.075,1.075,0,0,0-1.075,1.076V88.061a1.075,1.075,0,0,0,1.075,1.076h8.623a1.076,1.076,0,0,0,1.076-1.076V59.893A1.076,1.076,0,0,0,47.269,58.817ZM46.194,86.985H39.722V60.968h6.472ZM61.353,63.272H52.731a1.076,1.076,0,0,0-1.076,1.076V88.061a1.076,1.076,0,0,0,1.076,1.076h8.622a1.076,1.076,0,0,0,1.076-1.076V64.348A1.076,1.076,0,0,0,61.353,63.272ZM60.278,86.985H53.806V65.424h6.472ZM75.438,51.35H66.815a1.076,1.076,0,0,0-1.076,1.076V88.061a1.076,1.076,0,0,0,1.076,1.076h8.623a1.075,1.075,0,0,0,1.075-1.076V52.426A1.075,1.075,0,0,0,75.438,51.35ZM74.362,86.985H67.89V53.5h6.472ZM47.747,50.734c.01-.128.017-.257.017-.387a4.806,4.806,0,1,0-9.2,1.935l-6.575,4.9A4.8,4.8,0,1,0,33.272,58.9l6.575-4.9a4.8,4.8,0,0,0,7.25-1.221l5.156,1.631c-.01.127-.017.256-.017.386a4.806,4.806,0,1,0,9.075-2.2l6.936-5.872a4.819,4.819,0,1,0-1.389-1.642l-6.937,5.872A4.8,4.8,0,0,0,52.9,52.365ZM28.874,63.493a2.655,2.655,0,1,1,2.654-2.654A2.658,2.658,0,0,1,28.874,63.493ZM42.958,53a2.655,2.655,0,1,1,2.654-2.655A2.658,2.658,0,0,1,42.958,53ZM71.126,40.226a2.655,2.655,0,1,1-2.654,2.654A2.657,2.657,0,0,1,71.126,40.226ZM57.042,52.148A2.655,2.655,0,1,1,54.387,54.8,2.658,2.658,0,0,1,57.042,52.148Z"
      />
    </svg>
  );
};

ResultsIcon.propTypes = {
  fill: PropTypes.string,
};

ResultsIcon.defaultProps = {
  fill: colors.shasta.base,
};

export default ResultsIcon;
