import styled from 'styled-components';
import { media } from 'styles';

export const ItemList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;

  &:before, &:after {
    content: '';
    display: block;
    height: 35vh;
  }
`;

export const ItemWrapper = styled.li`
  width: 85%;
  max-width: 1000px;
  margin: 80px auto;

  &:last-child { margin: 0 auto; }

  ${media.min.tablet`margin: 160px auto;`}
`;

export const IntroItemWrapper = styled.li`
  margin: 0 auto 135px;
  width: 75%;
  max-width: 800px;
`;

export const OutroItemWrapper = styled.li`
  margin-top: 135px;

  ${media.min.max`margin: 80px 0;`}
`;
