/* eslint-disable @typescript-eslint/default-param-last */

import i18n from 'lib/i18n';

import {
  getErrorMessage,
  getHttpStatusCode,
} from 'lib/errorHandler';

import {
  FETCH_COURSES,
  FETCH_COURSE,
  SET_CURRENT_COURSE_ID,
} from './actions';

export const INITIAL_STATE = {
  coursePreviews: null,
  currentCourseId: null,
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_CURRENT_COURSE_ID:
      return {
        ...state,
        currentCourseId: payload,
      };
    case FETCH_COURSE.PENDING:
    case FETCH_COURSES.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_COURSE.ERROR: {
      const is403Error = getHttpStatusCode(payload) === 403;
      return {
        ...state,
        error: {
          message: is403Error ? i18n.t('errors:faultyCourse') : getErrorMessage(payload),
          shouldRedirect: is403Error,
        },
        isLoading: false,
      };
    }
    case FETCH_COURSES.ERROR:
      return {
        ...state,
        error: {
          isUserInitiated: false,
          message: getErrorMessage(payload),
          retryAction: payload.config ? action.originalAction : undefined,
        },
        isLoading: false,
      };
    case FETCH_COURSE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_COURSES.SUCCESS:
      return {
        ...state,
        coursePreviews: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
