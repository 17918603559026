import styled from 'styled-components';

import { colors, media, type } from 'styles';

import theme from '../theme';

export const Container = styled.div`
  position: relative;
  height: 360px;
  width: 100%;
  background-color: #374149;
  display: flex;
  max-width: 1500px;

  ${media.min.tablet`height: 380px;`}
  ${media.min.baseline`height: 400px;`}
  ${media.min.desktop`height: 450px;`}
  ${media.min.max`height: 500px;`}
`;

export const StyledDivider = styled.div`
  width: 10px;
  height: 100%;
  background-color: #a4d541;

  ${media.min.tablet`width: 14px;`}
  ${media.min.baseline`width: 16px;`}
  ${media.min.desktop`width: 18px;`}
  ${media.min.max`width: 20px;`}
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: none;

  ${media.min.tablet`display: block;`}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 15% 100%);
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-left: 30px;

  ${media.min.tablet`
    margin-left: 40px;
    padding: 30px 0;
  `}
  ${media.min.baseline`padding: 40px 0;`}
  ${media.min.desktop`padding: 50px 0;`}
  ${media.min.max`padding: 60px 0;`}
`;

export const ProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileName = styled.h2`
  margin: 0;
  font-size: 5rem;
  font-weight: ${type.weight.extraBold};

  color: ${theme.primary.web};
  ${media.print`color: ${theme.primary.print};`}

  ${media.min.tablet`font-size: 5rem;`}
  ${media.min.baseline`font-size: 5rem;`}
  ${media.min.desktop`font-size: 6.8rem;`}
  ${media.min.max`font-size: 7.8rem;`}
`;

export const ProfileNameLabel = styled.h2`
  font-size: 1.6rem;
  color: ${colors.cascade.muted};
  margin: 0;
  text-transform: uppercase;
  font-weight: ${type.weight.bold};

  ${media.min.tablet`font-size: 1.6rem;`}
  ${media.min.baseline`font-size: 1.6rem;`}
  ${media.min.desktop`font-size: 1.8rem;`}
  ${media.min.max`font-size: 2rem;`}
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;

  ${media.min.tablet`
    gap: 12px;
    margin-top: 30px;
  `}
  ${media.min.baseline`
    gap: 16px;
    margin-top: 36px;
  `}
  ${media.min.desktop`
    gap: 16px;
    margin-top: 36px;
  `}
  ${media.min.max`
    gap: 28px;
  `}
`;

export const BadgeIcon = styled.img`
  width: 140px;
  height: 100px;

  ${media.min.tablet`
    width: 120px;
    height: 90px;
  `}
  ${media.min.baseline`
    width: 130px;
    height: 100px;
  `}
  ${media.min.desktop`
    width: 150px;
    height: 110px;
  `}
  ${media.min.max`
    width: 200px;
    height: 150px;
  `}
`;

export const AssessmentLabel = styled.h1`
  color: ${colors.cascade.muted};
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: ${type.weight.bold};

  ${media.min.tablet`font-size: 1.4rem;`}
  ${media.min.baseline`font-size: 1.4rem;`}
  ${media.min.desktop`font-size: 1.6rem;`}
  ${media.min.max`font-size: 1.8rem;`}
`;

export const UserFullName = styled.h1`
  font-weight: ${type.weight.bold};
  font-size: 1.8rem;
  margin: 14px 0 0 0;

  color: ${theme.primary.web};
  ${media.print`color: ${theme.primary.print};`}

  ${media.min.tablet`font-size: 2rem;`}
  ${media.min.baseline`font-size: 2.2rem;`}
  ${media.min.desktop`font-size: 2.4rem;`}
`;
