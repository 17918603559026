import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { useTranslation } from 'react-i18next';

import { testResultType } from 'constants/propTypes';

import SimpleButton from 'components/shared/SimpleButton';

import {
  CTAWrapper,
  ResultText,
  ResultTitle,
  ResultVisualization,
  TextWrapper,
  Wrapper,
} from './style';

export const TestResult = ({
  onContinue,
  result,
}) => {
  const { t } = useTranslation(['common', 'courseViewer']);
  const scrollToRef = React.useRef();

  useEffect(() => {
    scrollToRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  return (
    <Wrapper>
      <ResultTitle ref={scrollToRef}>{t('courseViewer:testResult.title')}</ResultTitle>
      <ResultVisualization url={result.graphUrl} />
      <TextWrapper>
        <ResultText>{result.resultText}</ResultText>
      </TextWrapper>
      <CTAWrapper>
        <SimpleButton onClick={onContinue} variant="white">
          {t('continue')}
        </SimpleButton>
      </CTAWrapper>
    </Wrapper>
  );
};

TestResult.propTypes = {
  onContinue: PropTypes.func.isRequired,
  result: testResultType.isRequired,
};

export default track({ module: 'TestResult' }, {
  dispatchOnMount: () => ({ event: 'Test Result Shown' }),
})(TestResult);
